import { cloneDeep } from 'lodash';
import { parse, stringify } from 'query-string';
import * as React from 'react';
import { Link, LinkProps, Path } from 'react-router-dom';
import { EventLocation } from 'utils/analytics';

interface InternalLinkProps
  extends Partial<LinkProps>,
    Partial<React.AnchorHTMLAttributes<HTMLAnchorElement>> {
  to?: Partial<Path>;
  openedFromLocation?: EventLocation;
  openInNewTab?: boolean;
}

const InternalLink: React.FC<InternalLinkProps> = (props) => {
  const to = cloneDeep(props.to);
  if (!to) {
    /**
     * HACK: This scenario is only added for retrocompatibility with the way
     * GenericSearch is implemented. That component tries to render a link
     * without navigation capabilities in some cases.
     */
    return <a {...props}>{props.children}</a>;
  }

  const openedFromLocation = props.openedFromLocation;
  if (openedFromLocation) {
    // Adding the origin that opened this internal link as a UTM parameter.
    // This make it compatible with most analytics tools.
    // https://en.wikipedia.org/wiki/UTM_parameters
    const parsedSearch = to.search ? parse(to.search) : {};
    parsedSearch.utm_source = 'harmonic';
    parsedSearch.utm_content = openedFromLocation;
    to.search = `?${stringify(parsedSearch)}`;
  }

  return (
    <Link {...props} to={to} target={props.openInNewTab ? '_blank' : undefined}>
      {props.children}
    </Link>
  );
};

export default InternalLink;
