import React from 'react';
import { IconProps } from './type';

const GoogleIcon: React.FC<IconProps> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_8580_54678)">
        <path
          d="M13.388 7.15005C13.388 6.71635 13.3521 6.2803 13.2755 5.85364H7.13391V8.31049H10.6509C10.505 9.10287 10.036 9.80382 9.34939 10.2492V11.8434H11.4476C12.6798 10.7322 13.388 9.09115 13.388 7.15005Z"
          fill="#4285F4"
          vectorEffect="non-scaling-stroke"
        />
        <path
          d="M7.13392 13.3836C8.89004 13.3836 10.371 12.8186 11.45 11.8434L9.35179 10.2492C8.76802 10.6384 8.01437 10.8587 7.13632 10.8587C5.43763 10.8587 3.99733 9.73582 3.48054 8.22607H1.31531V9.86944C2.42065 12.0239 4.67202 13.3836 7.13392 13.3836Z"
          fill="#34A853"
          vectorEffect="non-scaling-stroke"
        />
        <path
          d="M3.47816 8.22608C3.20541 7.4337 3.20541 6.57567 3.47816 5.78329V4.13992H1.31532C0.391802 5.94271 0.391802 8.06666 1.31532 9.86945L3.47816 8.22608Z"
          fill="#FBBC04"
          vectorEffect="non-scaling-stroke"
        />
        <path
          d="M7.13392 3.14828C8.06222 3.13422 8.95942 3.47649 9.63172 4.10477L11.4907 2.28323C10.3136 1.20015 8.75127 0.604692 7.13392 0.623446C4.67202 0.623446 2.42065 1.98315 1.31531 4.13993L3.47815 5.7833C3.99254 4.27121 5.43523 3.14828 7.13392 3.14828Z"
          fill="#EA4335"
          vectorEffect="non-scaling-stroke"
        />
      </g>
      <defs>
        <clipPath id="clip0_8580_54678">
          <rect
            width="12.8"
            height="12.8"
            fill="white"
            transform="translate(0.599976 0.600006)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GoogleIcon;
