import React from 'react';

const AddCompanytoWatchlistButtonContentLoader: React.FC = () => {
  return (
    <div
      className="w-9 h-9 rounded-full bg-slate-200 animate-pulse"
      data-testid="AddCompanyToWatchlistButtonContentLoader"
    ></div>
  );
};

export default AddCompanytoWatchlistButtonContentLoader;
