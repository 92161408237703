import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
  split
} from '@apollo/client';
import { KeySpecifier } from '@apollo/client/cache/inmemory/policies';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import {
  getMainDefinition,
  relayStylePagination
} from '@apollo/client/utilities';
import ApolloLinkTimeout from 'apollo-link-timeout';
import { getApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { createClient } from 'graphql-ws';
import { merge } from 'lodash';
import { listKeyArgs, paginationMerge, paginationRead } from 'utils/apollo';
import { IS_PROD_LIKE_ENV, LOCAL_AGAINST_PROD } from 'utils/constants';
import { logger } from 'utils/logger';
import { getIdFromUrn } from 'utils/urn';
import { config } from './config';

const timeoutLink = new ApolloLinkTimeout(
  process.env.NODE_ENV === 'production'
    ? // 90 second timeout in prod
      90 * 1000
    : // 5 minutes everywhere else, we need a long timeout
      // because search is slow in staging
      5 * 60 * 1000
);

export const cache = new InMemoryCache({
  typePolicies: {
    Company: {
      keyFields: (args: Record<string, any> | null): KeySpecifier => {
        if (args?.id > 0) {
          return ['id'];
        } else {
          return ['id', 'name'];
        }
      },
      fields: {
        socials: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          }
        },
        funding: {
          keyArgs: ['fundingTotal', 'numFundingRounds'], // since these objects dont have traditional ids, we use surrogate keys
          merge: true
        },
        website: {
          keyArgs: ['url'],
          merge: true
        },
        foundingDate: {
          merge: true,
          keyArgs: ['date']
        },
        location: {
          merge: true,
          keyArgs: ['location']
        },
        tractionMetrics: {
          merge(existing, incoming) {
            // Traction metrics have different aliases in codebases. That causes cache to be reset.
            // Lodash merge will combine everything and various aliases will exist side by side
            return merge({}, existing, incoming);
          }
        },
        userConnectionsOverview: {
          merge: true
        },
        userConnections: {
          merge(existing, incoming) {
            return merge([], existing, incoming);
          }
        }
      }
    },
    Person: {
      merge: true
    },
    SavedSearch: {
      merge: true,
      fields: {
        results: relayStylePagination([
          'state',
          'highlightNew',
          'sortField',
          'sortDescending'
        ]),
        creator: {
          merge: true,
          keyArgs: ['entityUrn']
        }
      }
    },
    CompanyWatchlist: {
      merge: true,
      fields: {
        customFields: {
          merge(existing, incoming) {
            return merge([], existing, incoming);
          }
        },
        companyEntries: relayStylePagination(listKeyArgs)
      }
    },
    PeopleWatchlist: {
      merge: true,
      fields: {
        customFields: {
          merge(existing, incoming) {
            return merge([], existing, incoming);
          }
        },
        personEntries: relayStylePagination(listKeyArgs),
        namedViews: {
          merge(existing, incoming) {
            return merge([], existing, incoming);
          }
        }
      }
    },
    PeopleListCustomField: {
      keyFields: ['urn'],
      merge: true
    },
    PeopleListCustomFieldValue: {
      keyFields: ['urn'],
      merge: true
    },
    PeopleWatchlistEntryNode: {
      keyFields: ['entryUrn'],
      merge: true
    },
    CompanyListCustomField: {
      keyFields: ['urn'],
      merge: true
    },
    CompanyListCustomFieldValue: {
      keyFields: ['urn'],
      merge: true
    },
    CompanyListNamedView: {
      merge: true,
      fields: {
        localSearchQuery() {
          return null;
        },
        localVisibleColumns() {
          return null;
        }
      }
    },
    PersonListNamedView: {
      merge: true,
      fields: {
        localSearchQuery() {
          return null;
        },
        localVisibleColumns() {
          return null;
        }
      }
    },
    CompanyWatchlistEntryNode: {
      keyFields: ['entryUrn'],
      merge: true
    },
    Customer: {
      merge: true
    },
    User: {
      merge: true
    },
    NetNewCount: {
      keyFields: ['savedSearchUrn']
    },

    Query: {
      fields: {
        searchPeople: relayStylePagination((args) => {
          const keyWithoutPage = {
            controlledFilterGroup: args?.query?.controlledFilterGroup,
            filterGroup: args?.query?.filterGroup,
            sort: args?.query?.sort,
            state: args?.state
          };
          return JSON.stringify(keyWithoutPage);
        }),
        searchCompanies: relayStylePagination((args) => {
          const keyWithoutPage = {
            controlledFilterGroup: args?.query?.controlledFilterGroup,
            filterGroup: args?.query?.filterGroup,
            sort: args?.query?.sort,
            state: args?.state
          };
          return JSON.stringify(keyWithoutPage);
        }),
        getPeopleInWatchlistByIdOrUrn: {
          keyArgs: ['idOrUrn', 'sortField', 'sortDescending'],
          merge: (existing, incoming, { args }) =>
            paginationMerge(existing, incoming, args?.page, args?.size),
          read: (existing, { args }) =>
            paginationRead(existing, args?.page, args?.size)
        },
        getSavedSearch: {
          read(existing, { args, toReference }) {
            // used to check cache before making a network request.
            // this tactic is useful when you have two resolvers that return the same entity,
            // one resolver returns a list, and the other a single.
            // when the response of the list is in the cache, and a single request is made,
            // ideally, we shouldnt make another network request, but rather pull from apollo cache.
            // https://www.apollographql.com/docs/react/caching/advanced-topics#cache-redirects
            return toReference({
              __typename: 'SavedSearch',
              id: args?.idOrUrn
            });
          }
        },
        getCompanyWatchlistByIdOrUrn: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'CompanyWatchlist',
              id: args?.idOrUrn
            });
          }
        },

        getPersonById: {
          read(_, { args, toReference }) {
            return toReference({ __typename: 'Person', id: args?.id });
          }
        },
        getPersonsByIds: {
          read(_, { args, toReference }) {
            return args?.ids.map((id: number) =>
              toReference({
                __typename: 'Person',
                id: id
              })
            );
          }
        },
        getCompanyById: {
          read(_, { args, toReference }) {
            return toReference({ __typename: 'Company', id: args?.id });
          }
        },
        getCompanyByUrn: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'Company',
              id: getIdFromUrn(args?.urn)
            });
          }
        },
        getSavedSearchesByUrns: {
          read(_, { args, toReference }) {
            return args?.urns?.map((urn: string) =>
              toReference({
                __typename: 'SavedSearch',
                id: getIdFromUrn(urn)
              })
            );
          }
        },

        getCompaniesByIds: {
          read(_, { args, toReference }) {
            return args?.ids.map((id: number) =>
              toReference({
                __typename: 'Company',
                id: id
              })
            );
          }
        }
      }
    }
  },
  possibleTypes: {
    InvestorUnion: ['Company', 'Person']
  }
});

const errorLink = onError(
  ({ graphQLErrors, networkError, response, ...otherErrorParameters }) => {
    // Used for logging now, but can also be used to retry failed requests.
    if (graphQLErrors) {
      graphQLErrors.map((graphQLError) =>
        logger.error(
          `[GraphQL error]: Message: ${graphQLError.message}, Path: ${graphQLError.path}`,
          { error: { graphQLError, ...otherErrorParameters } }
        )
      );
    }
    if (networkError) {
      logger.error(`[Network error]: ${networkError.message}`, {
        error: { networkError, ...otherErrorParameters }
      });
    }
  }
);

const getRequestHeaders = async (
  operationName?: string,
  previousHeaders?: Record<string, unknown>
) => {
  const auth = getAuth(getApp());
  const authorizationToken = await auth.currentUser?.getIdToken();
  let authorization;
  if (!authorizationToken) {
    authorization = 'Public';
  } else if (
    operationName === 'GetCurrentUser' ||
    operationName === 'GetCustomerSlackIntegrations' ||
    operationName === 'GetCurrentUserConnectionsIntegrationStatus'
  ) {
    authorization = authorizationToken;
  } else {
    authorization = `Bearer ${authorizationToken}`;
  }

  const newHeaders = {
    ...previousHeaders,
    authorization,
    version: 'FE',
    'x-harmonic-request-source': 'frontend'
  };

  return newHeaders;
};

const authLink = setContext(async (operation, { headers }) => {
  const newHeaders = await getRequestHeaders(operation.operationName, headers);
  return {
    headers: newHeaders
  };
});

const httpLink = new HttpLink({
  uri: (operation) =>
    `${config.BASE_GRAPHQL_API_URL}?${operation.operationName}`
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: async () => {
      const auth = getAuth(getApp());
      const authorizationToken = await auth.currentUser?.getIdToken();
      return `${config.BASE_GRAPHQL_WEBSOCKET_URL}?jwt=${authorizationToken}`;
    },
    connectionParams: getRequestHeaders
  })
);

const loggingLink = new ApolloLink((operation, forward) => {
  operation.setContext({ start: new Date() });

  return forward(operation).map((data) => {
    const time: number = new Date().getTime() - operation.getContext().start;

    const context = {
      time,
      operationName: operation.operationName,
      variables: operation.variables
    };

    logger.info('Apollo operation complete', context);

    return data;
  });
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  from([loggingLink, errorLink, authLink, timeoutLink, httpLink])
);

const client = new ApolloClient({
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all'
    }
  },
  cache,
  assumeImmutableResults: true, // improves perf by avoiding cloneDeep
  connectToDevTools: !IS_PROD_LIKE_ENV || LOCAL_AGAINST_PROD, // turns on apollo dev tools in production env
  link: splitLink
});

export default client;
