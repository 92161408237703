import { PeopleAltOutlined } from '@material-ui/icons';
import {
  GetCompanyUserConnectionsV2_UserFragment,
  Person,
  UserConnectionFragment
} from '__generated__/graphql';
import { LinkedInIcon } from 'assets/harmonic-icons';
import { IconProps } from 'assets/harmonic-icons/type';
import InternalLink from 'components/common/InternalLink';
import Paper from 'components/common/Paper';
import PersonUserConnections from 'components/common/PersonUserConnections';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { compact, uniq } from 'lodash';
import { useState } from 'react';
import AvatarWithHighlightBadge from '../../../common/Highlights/AvatarWithHighlightBadge';
import UserConnectionsDegree from './UserConnectionsDegree';

export interface PersonWithConnections {
  person?: Maybe<Person>;
  personEmail: string;
  userConnections: GetCompanyUserConnectionsV2_UserFragment[];
  latestCalendarEvent?: UserConnectionFragment['latestCalendarEvent'] | null;
  latestEmail?: UserConnectionFragment['latestEmail'] | null;
}

interface UserConnectionsListProps {
  loading: boolean;
  peopleWithConnections: PersonWithConnections[];
  currentCompanyId: number;
}

interface LoadMoreProps {
  onClick: () => void;
}

const LoadMore: React.FC<LoadMoreProps> = ({ onClick }) => {
  return (
    <button className="text-content-headerGrayAlt text-base" onClick={onClick}>
      Load More
    </button>
  );
};

interface PersonWithConnectionsCardProps {
  personWithConnections: PersonWithConnections;
  currentCompanyId: number;
}

const PersonWithConnectionsCard: React.FC<PersonWithConnectionsCardProps> = ({
  personWithConnections,
  currentCompanyId
}) => {
  const person = personWithConnections.person;
  const personEmail = personWithConnections.personEmail;
  const userConnections = personWithConnections.userConnections;
  const id = person?.id;
  const current = person?.experience?.find((exp) => {
    return exp?.isCurrentPosition && exp.company.id === currentCompanyId;
  });
  const currentTitle = current?.title;

  const highlights =
    uniq(
      compact(person?.highlights?.map((highlight) => highlight?.category))
    ) ?? [];

  return (
    <Paper key={`person-${person?.id}-${personEmail}`}>
      <div
        data-testid="UserConnectionsList-PersonWithConnectionsCard"
        className="flex flex-row items-start gap-x-4 min-h-18"
      >
        {id && (
          <AvatarWithHighlightBadge
            href={`/dashboard/person/${id}`}
            src={person?.profilePictureUrl ?? ''}
            highlights={highlights}
            personId={id}
          />
        )}
        <div className="gap-y-1 w-full">
          <div className="flex flex-row items center justify-start gap-x-g20">
            <InternalLink to={{ pathname: `/dashboard/person/${id}` }}>
              <p className="text-content-standaloneGray typography-label-large-strong line-clamp-1">
                {person?.fullName}
              </p>
            </InternalLink>
            {person?.socials?.linkedin?.url && (
              <IconButton
                icon={(props: IconProps) => (
                  <LinkedInIcon {...props} applyCurrentColor={false} />
                )}
                onClick={() =>
                  window.open(person.socials?.linkedin?.url as string)
                }
                size="tiny"
                type="secondary"
                emphasis="low"
              />
            )}
            <div
              data-testid="LinkedinOutlink"
              className="flex flex-row items-center gap-x-g10"
            >
              <span className="text-content-weak">•</span>
              <p className="text-content-headerGray">
                <UserConnectionsDegree userConnections={userConnections} />
              </p>
            </div>
          </div>
          <p className="line-clamp-1 text-content-weak typography-label">
            {currentTitle}
          </p>
          <p>
            <PersonUserConnections
              target={{ name: person?.fullName ?? '' }}
              userConnections={userConnections}
              iconFill="weak"
            />
          </p>
        </div>
      </div>
    </Paper>
  );
};

const UserConnectionsListContentLoader: React.FC = () => {
  const rows = [...Array(8)];
  return (
    <div data-testid="UserConnectionsListContentLoader">
      {rows.map((_, index) => {
        return (
          <div
            key={index}
            className="flex w-full animated-box-pulse h-24 py-1.5"
          ></div>
        );
      })}
    </div>
  );
};

const UserConnectionsList: React.FC<UserConnectionsListProps> = ({
  peopleWithConnections,
  loading,
  currentCompanyId
}) => {
  const showContentLoader = loading && PeopleAltOutlined.length === 0;
  const [numShowing, setNumShowing] = useState(5);

  if (showContentLoader) {
    return <UserConnectionsListContentLoader />;
  }

  return (
    <div data-testid="UserConnectionsList" className="w-full">
      <div className="flex flex-col space-y-4">
        {peopleWithConnections
          .slice(0, numShowing)
          .map((personWithConnections) => (
            <PersonWithConnectionsCard
              key={`person-${personWithConnections.person?.id}-${personWithConnections.personEmail}`}
              personWithConnections={personWithConnections}
              currentCompanyId={currentCompanyId}
            />
          ))}
      </div>
      <div className="flex justify-center items-center mt-4">
        {numShowing < peopleWithConnections.length && (
          <LoadMore onClick={() => setNumShowing(numShowing + 5)} />
        )}
      </div>
    </div>
  );
};

export default UserConnectionsList;
