import classNames from 'classnames';
import { IconProps } from './type';

const CircleXIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="#DD2619"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        vectorEffect="non-scaling-stroke"
        d="M1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6ZM4.76517 4.23483C4.61872 4.08839 4.38128 4.08839 4.23483 4.23483C4.08839 4.38128 4.08839 4.61872 4.23483 4.76517L5.11612 5.64645C5.31138 5.84171 5.31138 6.15829 5.11612 6.35355L4.23483 7.23483C4.08839 7.38128 4.08839 7.61872 4.23483 7.76517C4.38128 7.91161 4.61872 7.91161 4.76517 7.76517L5.64645 6.88388C5.84171 6.68862 6.15829 6.68862 6.35355 6.88388L7.23483 7.76517C7.38128 7.91161 7.61872 7.91161 7.76517 7.76517C7.91161 7.61872 7.91161 7.38128 7.76517 7.23483L6.88388 6.35355C6.68862 6.15829 6.68862 5.84171 6.88388 5.64645L7.76517 4.76517C7.91161 4.61872 7.91161 4.38128 7.76517 4.23483C7.61872 4.08839 7.38128 4.08839 7.23483 4.23483L6.35355 5.11612C6.15829 5.31138 5.84171 5.31138 5.64645 5.11612L4.76517 4.23483Z"
      />
    </svg>
  );
};
export default CircleXIcon;
