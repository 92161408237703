import { ReactComponent as CheckMark } from 'assets/harmonic-icons/checkmark.svg';
import { HarmonicIcon } from 'assets/harmonic-icons/type';
import classNames from 'classnames';
import { Tooltip } from 'common/components';
import Avatar from 'harmonic-components/Avatar/Avatar';
import Checkbox from 'harmonic-components/Checkbox/Checkbox';
import Tag from 'harmonic-components/Tag/Tag';
import { ReactElement, useState } from 'react';
import { ColorShorthand } from 'utils/design';

export type ButtonEvent = React.MouseEvent<HTMLButtonElement>;

type BaseListItemProps = {
  id?: string;
  label: string;
  value: string;
  disabled?: boolean;
  testId?: string;
  type?: ListType;
  secondaryIcon?: ReactElement;
  focused?: boolean;
  showSecondaryIcon?: (isHovering: boolean) => boolean;
  loading?: boolean;
  onHover?: () => void;
};

type DefaultListItemProps = BaseListItemProps & {
  primaryIcon?: HarmonicIcon;
  onClick: (e?: ButtonEvent) => void;
  primaryIconTooltip?: string;
  destructive?: boolean;
};

type DefaultNestedListItemProps = BaseListItemProps & {
  primaryIcon?: HarmonicIcon;
  onClick: (e?: ButtonEvent) => void;
  primaryIconTooltip?: string;
  nestedPrimaryIcon?: HarmonicIcon;
  nestedSecondaryIcon?: HarmonicIcon;
  nestedLabel?: string;
  nestedElement?: ReactElement;
};

type MultiSelectListItemProps = BaseListItemProps & {
  primaryIcon?: HarmonicIcon;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onExclude?: () => void;
  primaryIconTooltip?: string;
  excludeMode?: boolean;
};

type AddCustomListItemProps = DefaultListItemProps & {
  onExclude?: () => void;
};

type EntityListItemProps = BaseListItemProps & {
  subtitle1?: string;
  subtitle2?: string;
  subtitle3?: string;
  src: string;
  logo: Parameters<typeof Avatar>[0]['kind'];
  onClick: (e?: ButtonEvent) => void;
};

type TwoLinedListItemProps = BaseListItemProps & {
  subtitle: string;
  onClick: (e?: ButtonEvent) => void;
};

type TagListItemProps = BaseListItemProps & {
  color: ColorShorthand;
  onClick: (e?: ButtonEvent) => void;
};

export enum ListVariant {
  default = 'default',
  nested = 'nested',
  wrap = 'wrap',
  checkboxes = 'checkboxes',
  entity = 'entity',
  twoLined = 'twoLined',
  addCustom = 'addCustom', // custom add (freeSolo) list item
  tag = 'tag'
}

export enum ListType {
  default = 'default',
  inverted = 'inverted'
}

type ListItemDisplayProps =
  | ({ variant: ListVariant.default | ListVariant.wrap } & DefaultListItemProps)
  | ({
      variant: ListVariant.nested | ListVariant.wrap;
    } & DefaultNestedListItemProps)
  | ({ variant: ListVariant.checkboxes } & MultiSelectListItemProps)
  | ({ variant: ListVariant.entity } & EntityListItemProps)
  | ({ variant: ListVariant.twoLined } & TwoLinedListItemProps)
  | ({ variant: ListVariant.addCustom } & AddCustomListItemProps)
  | ({ variant: ListVariant.tag } & TagListItemProps);

type SelectableListItemProps = ListItemDisplayProps & {
  selected?: boolean;
};

export type ListItemProps = ListItemDisplayProps & SelectableListItemProps;

const ListItem: React.FC<ListItemProps> = ({
  type = ListType.default,
  showSecondaryIcon = (isHovering: boolean) => isHovering,
  ...props
}) => {
  const [isHovering, setIsHovering] = useState(false);

  const primaryClasses = classNames(
    'flex items-center justify-between py-p40 px-p50 gap-g-40 bg-int-overlay-secondary-enabled cursor-pointer rounded-br20',
    'disabled:bg-int-overlay-secondary-disabled disabled:cursor-default',
    //label uses aria-disabled. Hence, repeating it
    'aria-disabled:bg-int-overlay-secondary-disabled aria-disabled:cursor-default',
    props.variant !== 'wrap' && 'w-full',
    type === 'inverted'
      ? 'hover:bg-int-overlay-inverted-hover'
      : 'hover:bg-int-overlay-secondary-hover',
    type === 'inverted'
      ? 'data-[focused=true]:bg-int-overlay-inverted-hover'
      : 'data-[focused=true]:bg-int-overlay-secondary-hover',
    'active:bg-int-overlay-secondary-pressed',
    'focus-visible:bg-int-overlay-secondary-enabled focus-visible:shadow-int-focus-outside',
    'text-content-weak'
  );

  if (props.variant === 'checkboxes') {
    return (
      <label
        data-testid={props.testId || 'list-item'}
        data-focused={props.focused}
        aria-disabled={props.disabled}
        className={primaryClasses}
        onMouseEnter={() => {
          setIsHovering(true);
          props.onHover && props.onHover();
        }}
        onMouseLeave={() => {
          setIsHovering(false);
          props.onHover && props.onHover();
        }}
        id={props.id}
      >
        <MultiSelectListItem {...props} onChange={props.onChange} type={type} />
      </label>
    );
  }

  // addCustom variant is used for freeSolo list items
  if (props.variant === 'addCustom') {
    return (
      <div
        data-testid={props.testId || 'list-item'}
        data-focused={props.focused}
        aria-selected={props.selected}
        onMouseEnter={() => {
          setIsHovering(true);
          props.onHover && props.onHover();
        }}
        onMouseLeave={() => {
          setIsHovering(false);
          props.onHover && props.onHover();
        }}
        id={props.id}
        className={primaryClasses + ' w-full'}
      >
        <button
          aria-disabled={props.disabled}
          data-testid="default-list-item"
          className="flex text-left items-center p-p00 gap-g40 text-content-default typography-label-default-default aria-disabled:text-content-weak w-full"
          onClick={props.onClick}
        >
          <p>{props.label}</p>
        </button>
        {props.onExclude && (
          <button
            className={classNames(
              'typography-label rounded-br20 px-p20 hover:bg-int-overlay-secondary-hover',
              'text-int-overlay-secondary-onEnabled active:bg-int-overlay-secondary-pressed',
              'data-[excluded=true]:text-content-sentiment-negative flex ml-auto z-100'
            )}
            onClick={() => {
              props?.onExclude?.();
            }}
          >
            Exclude
          </button>
        )}
      </div>
    );
  }

  return (
    <button
      data-testid={props.testId || 'list-item'}
      data-focused={props.focused}
      disabled={props.disabled}
      aria-selected={props.selected}
      onClick={props.onClick}
      onMouseEnter={() => {
        setIsHovering(true);
        props.onHover && props.onHover();
      }}
      onMouseLeave={() => {
        setIsHovering(false);
        props.onHover && props.onHover();
      }}
      id={props.id}
      className={primaryClasses}
    >
      {(props.variant === 'default' || props.variant === 'wrap') && (
        <DefaultListItem {...props} type={type} />
      )}
      {props.variant === 'nested' && <DefaultNestedListItem {...props} />}
      {props.variant === 'entity' && <EntityListItem {...props} type={type} />}
      {props.variant === 'twoLined' && (
        <TwoLinedListItem {...props} type={type} />
      )}
      {props.variant === 'tag' && <TagListItem {...props} type={type} />}

      {props.selected && <CheckMark className="w-3 h-3" />}
      {props.secondaryIcon && (
        <div
          className={classNames(
            showSecondaryIcon(isHovering || props.focused || false)
              ? 'flex'
              : 'hidden'
          )}
        >
          {props.secondaryIcon}
        </div>
      )}
    </button>
  );
};

const DefaultListItem: React.FC<DefaultListItemProps> = (props) => {
  const Icon = props.primaryIcon;
  const destructive = props.destructive;
  return (
    <div
      aria-disabled={props.disabled}
      data-testid="default-list-item"
      className={classNames(
        'flex text-left items-center p-p00 gap-g40 typography-label-default-default',
        {
          'text-content-inverted-strong': props.type === 'inverted',
          'text-content-default aria-disabled:text-content-weak': !destructive,
          'text-content-sentiment-negative': destructive
        }
      )}
    >
      {Icon && !props.primaryIconTooltip && (
        <Icon
          className={classNames('w-4 h-4', {
            'text-white': props.type === 'inverted',
            'text-content-weak': !destructive,
            'text-content-sentiment-negative': destructive
          })}
        />
      )}
      {Icon && props.primaryIconTooltip && (
        <Tooltip title={props.primaryIconTooltip}>
          <Icon className="w-4 h-4 fill-current" />
        </Tooltip>
      )}
      <p>{props.label}</p>
    </div>
  );
};
const DefaultNestedListItem: React.FC<DefaultNestedListItemProps> = (props) => {
  const Icon = props.primaryIcon;
  const NestedPrimaryIcon = props.nestedPrimaryIcon;
  const NestedSecondaryIcon = props.nestedSecondaryIcon;
  const NestedElement = props.nestedElement;

  return (
    <div
      aria-disabled={props.disabled}
      data-testid="default-list-item"
      className={classNames(
        'flex text-left w-full items-center justify-between p-p00 gap-g40 typography-label-default-default text-content-default aria-disabled:text-content-weak',
        {
          'text-content-inverted-strong': props.type === 'inverted'
        }
      )}
    >
      <div className="flex gap-g40 items-center">
        {Icon && !props.primaryIconTooltip && (
          <Icon
            className={classNames('w-4 h-4 text-content-weak', {
              'text-white': props.type === 'inverted'
            })}
          />
        )}
        {Icon && props.primaryIconTooltip && (
          <Tooltip title={props.primaryIconTooltip}>
            <Icon className="w-4 h-4 fill-current" />
          </Tooltip>
        )}
        <p>{props.label}</p>
      </div>
      <div className="flex gap-g40 items-center">
        {NestedPrimaryIcon && (
          <NestedPrimaryIcon
            className={classNames('w-4 h-4 text-content-weak', {
              'text-white': props.type === 'inverted'
            })}
          />
        )}
        <p>{props.nestedLabel}</p>
        {NestedSecondaryIcon && (
          <NestedSecondaryIcon
            className={classNames('w-4 h-4 text-content-weak', {
              'text-white': props.type === 'inverted'
            })}
          />
        )}
        {NestedElement}
      </div>
    </div>
  );
};

const TagListItem: React.FC<TagListItemProps> = (props) => {
  return (
    <div
      aria-disabled={props.disabled}
      data-testid="default-list-item"
      className={classNames(
        'flex text-left items-center p-p00 gap-g40 typography-label-default-default'
      )}
    >
      <Tag color={props.color} label={props.label} />
    </div>
  );
};

const MultiSelectListItem: React.FC<
  MultiSelectListItemProps & SelectableListItemProps
> = (props) => {
  const Icon = props.primaryIcon;
  return (
    <div
      data-testid="multi-select-list-item"
      className="flex items-center justify-between w-full p-p00 gap-g40"
    >
      <Checkbox
        loading={props.loading}
        onChange={props.onChange}
        checked={props.selected}
        disabled={props.disabled}
        renderLabelAsDiv
        label={props.label}
        onExclude={props.onExclude}
        showExcludeButton={
          // Do not show exclude button if item is currently selected (unless it's excluded)
          Boolean(props.onExclude) && !(props.selected && !props.excludeMode)
        }
        variant={props.excludeMode ? 'exclude' : 'default'}
        fullWidth={true}
        inverted={props.type === 'inverted'}
      />

      {Icon && !props.primaryIconTooltip && (
        <Icon className="w-4 h-4 fill-current" />
      )}
      {Icon && props.primaryIconTooltip && (
        <Tooltip title={props.primaryIconTooltip}>
          <Icon className="w-4 h-4 fill-current" />
        </Tooltip>
      )}
    </div>
  );
};

const EntityListItem: React.FC<EntityListItemProps> = (props) => {
  return (
    <div
      data-testid="entity-list-item"
      className={`flex items-center w-11/12 pl-p10 py-p10 gap-g50`}
    >
      <Avatar
        alt="entity-list-item-image"
        src={props.src}
        size="large"
        kind={props.logo}
      />
      <div className="flex flex-col justify-center items-start gap-g00 text-start">
        <p
          className={classNames(
            `typography-label-default-default line-clamp-1`,
            props.type === 'inverted' &&
              classNames('text-content-inverted-strong'),
            props.type === 'default' && 'text-content-weak'
          )}
        >
          {props.label}
        </p>
        {(props.subtitle1 || props.subtitle2 || props.subtitle3) && (
          <div className="flex gap-g20 items-center">
            <p
              className={classNames(
                `typography-label-small-default h-4 whitespace-nowrap`,
                props.type === 'inverted' && 'text-content-inverted-strong',
                props.type === 'default' && classNames('text-content-weak')
              )}
            >
              {props.subtitle1}
            </p>
            <span className="text-content-weak typography-label-small-default">
              {props.subtitle1 && '•'}
            </span>
            {props.subtitle2 && (
              <p
                className={classNames(
                  `typography-label-small-default line-clamp-1`,
                  props.type === 'inverted' &&
                    classNames('text-content-inverted-strong'),
                  props.type === 'default' && 'text-content-weak'
                )}
              >
                {props.subtitle2}
              </p>
            )}
            {props.subtitle3 && (
              <>
                <span className="text-content-weak">•</span>
                <p className="typography-label-small-default text-content-weak">
                  {props.subtitle3}
                </p>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const TwoLinedListItem: React.FC<TwoLinedListItemProps> = (props) => {
  return (
    <div
      data-testid="two-lined-list-item"
      className="flex flex-col justify-center items-start gap-g00"
    >
      <p className="typography-label-default-default text-content-default">
        {props.label}
      </p>
      <p className="typography-label-small-default text-content-weak">
        {props.subtitle}
      </p>
    </div>
  );
};

export default ListItem;
