import { ICompany } from './Company';

export enum EventType {
  NEW_COMPANY_DETECTED = 'NEW_COMPANY_DETECTED',
  ANGEL_LIST_LAUNCH_DETECTED = 'ANGEL_LIST_LAUNCH_DETECTED',
  NEW_PRODUCT_HUNT_LAUNCH_DETECTED = 'NEW_PRODUCT_HUNT_LAUNCH_DETECTED',
  NEW_FINANCING_ANNOUNCED = 'NEW_FINANCING_ANNOUNCED',
  NEW_FINANCING_DETECTED = 'NEW_FINANCING_DETECTED',
  FINANCING_DETECTED = 'FINANCING_DETECTED',
  MAJOR_HIRE_DETECTED = 'MAJOR_HIRE_DETECTED',
  THREE_MONTHS_SINCE_FINANCING = 'THREE_MONTHS_SINCE_FINANCING',
  SIX_MONTHS_SINCE_FINANCING = 'SIX_MONTHS_SINCE_FINANCING',
  NINE_MONTHS_SINCE_FINANCING = 'NINE_MONTHS_SINCE_FINANCING'
}

export const EventTypeToDescriptionMapping = {
  [EventType.NEW_COMPANY_DETECTED]: 'New Company Detected',
  [EventType.ANGEL_LIST_LAUNCH_DETECTED]: 'New Angel List Launch Detected',
  [EventType.NEW_PRODUCT_HUNT_LAUNCH_DETECTED]:
    'New Product Hunt Launch Detected',
  [EventType.NEW_FINANCING_ANNOUNCED]: 'New Financing Announced',
  [EventType.NEW_FINANCING_DETECTED]: 'New Financing Detected',
  [EventType.FINANCING_DETECTED]: 'Financing Detected',
  [EventType.MAJOR_HIRE_DETECTED]: 'Major Hire Detected',
  [EventType.THREE_MONTHS_SINCE_FINANCING]: '3 Months Since Financing',
  [EventType.SIX_MONTHS_SINCE_FINANCING]: '6 Months Since Financing',
  [EventType.NINE_MONTHS_SINCE_FINANCING]: '9 Months Since Financing'
};

export interface IEvent {
  id: number;
  description: string;
  event_type: string;
  observed_at: Date;
  company: ICompany;
}
