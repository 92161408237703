import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const ThumbDownIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
      fill="none"
      stroke="#1A1D23"
    >
      <path
        d="M11.5015 8.83333H13.1668C13.7191 8.83333 14.1668 8.38562 14.1668 7.83333V3.5C14.1668 2.94772 13.7191 2.5 13.1668 2.5H11.5015M11.5015 2.5V8.66667L8.85745 13.802C8.74214 14.0259 8.51136 14.1667 8.25947 14.1667V14.1667C7.44163 14.1667 6.81674 13.4364 6.94274 12.6279L7.32628 10.1667H3.83288C2.60906 10.1667 1.67255 9.0763 1.85667 7.86578L2.41435 4.19912C2.56297 3.22198 3.40269 2.5 4.39056 2.5H11.5015Z"
        strokeWidth="1.5"
        strokeLinejoin="round"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default ThumbDownIcon;
