import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const InfoCircle: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      fill="#7C8598"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        vectorEffect="non-scaling-stroke"
        d="M8.82292 1.33334C5.14102 1.33334 2.15625 4.31811 2.15625 8.00001C2.15625 11.6819 5.14102 14.6667 8.82292 14.6667C12.5048 14.6667 15.4896 11.6819 15.4896 8.00001C15.4896 4.31811 12.5048 1.33334 8.82292 1.33334ZM7.48958 7.33334C7.48958 7.0572 7.71344 6.83334 7.98958 6.83334H8.82291C9.09905 6.83334 9.32291 7.0572 9.32291 7.33334L9.32292 10.8333C9.32292 11.1095 9.09906 11.3333 8.82292 11.3333C8.54678 11.3333 8.32292 11.1095 8.32292 10.8333L8.32291 7.83334H7.98958C7.71344 7.83334 7.48958 7.60949 7.48958 7.33334ZM8.82292 4.83334C8.54677 4.83334 8.32292 5.0572 8.32292 5.33334C8.32292 5.60949 8.54677 5.83334 8.82292 5.83334C9.09906 5.83334 9.32292 5.60949 9.32292 5.33334C9.32292 5.0572 9.09906 4.83334 8.82292 4.83334Z"
      />
    </svg>
  );
};

export default InfoCircle;
