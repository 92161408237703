import {
  ADD_FILTER_OPTION_TO_GRID_DASHBOARD,
  RESET_DASHBOARD,
  SET_DASHBOARD_ERROR,
  SET_GRID_ERROR,
  SET_GRID_PAGINATION_PAGE,
  SET_GRID_PAGINATION_ROWS_PER_PAGE,
  SET_SORT_FIELD,
  SET_SORT_IS_DESCENDING,
  UPDATE_SEARCH_MODEL
} from 'actions/types/actionTypes';

import { IDashboardAction, IDashboardState } from 'interfaces/Dashboard';
import {
  INITIAL_COMPANY_SORT,
  INITIAL_SEARCH_MODEL
} from 'interfaces/SearchModel/Search';

const initialDashboardState: IDashboardState = {
  dashboardError: '',
  searchModel: INITIAL_SEARCH_MODEL,
  sortField: INITIAL_COMPANY_SORT.sortField,
  sortIsDescending: false,
  gridError: {}
};

const dashboardReducer = (
  state = initialDashboardState,
  action: IDashboardAction
): IDashboardState => {
  switch (action.type) {
    case SET_DASHBOARD_ERROR:
      return {
        ...state,
        dashboardError: action.payload
      };

    case SET_GRID_ERROR:
      return {
        ...state,
        gridError: action.payload
      };

    case SET_GRID_PAGINATION_PAGE:
      return {
        ...state,
        searchModel: {
          ...state.searchModel,
          pagination: {
            ...state.searchModel.pagination,
            start: state.searchModel.pagination.page_size * action.payload
          }
        }
      };
    case SET_GRID_PAGINATION_ROWS_PER_PAGE:
      return {
        ...state,
        searchModel: {
          ...state.searchModel,
          pagination: {
            ...state.searchModel.pagination,
            page_size: action.payload
          }
        }
      };
    case UPDATE_SEARCH_MODEL:
      return {
        ...state,
        searchModel:
          action.payload === null
            ? INITIAL_SEARCH_MODEL
            : {
                ...INITIAL_SEARCH_MODEL,
                ...action.payload
              }
      };
    case SET_SORT_FIELD:
      return {
        ...state,
        sortField:
          action.payload === ''
            ? INITIAL_COMPANY_SORT.sortField
            : action.payload
      };
    case SET_SORT_IS_DESCENDING:
      return {
        ...state,
        sortIsDescending: action.payload
      };
    case ADD_FILTER_OPTION_TO_GRID_DASHBOARD:
      return {
        ...state,
        searchModel: { ...state.searchModel, ...action.payload }
      };
    case RESET_DASHBOARD:
      return {
        ...initialDashboardState
      };
    default:
      return state;
  }
};

export default dashboardReducer;
