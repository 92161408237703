import ListItem, { ListItemProps } from 'harmonic-components/ListItem/ListItem';
import { useContext } from 'react';
import SelectContext from './SelectContext';

export type SelectListItemProps = ListItemProps;
const SelectListItem: React.FC<SelectListItemProps> = (props) => {
  const { listItems, keyboardFocusIndex } = useContext(SelectContext);

  const isFocused = listItems?.[keyboardFocusIndex]?.value === props.value;

  return <ListItem {...props} focused={isFocused} />;
};
SelectListItem.displayName = 'SelectListItem';

export default SelectListItem;
