import classNames from 'classnames';
import { HarmonicIcon } from './type';
const ChevronLeft: HarmonicIcon = ({ applyCurrentColor = true, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke="#98A0AE"
      strokeWidth="1.5"
      strokeLinecap="square"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M9.33317 10.6666L6.6665 7.99998L9.33317 5.33331"
      />
    </svg>
  );
};
export default ChevronLeft;
