import defaultCompanyLogo from 'assets/company-profile/default-logo.svg';
import defaultPersonLogo from 'assets/company-profile/default-person-logo.svg';
import classNames from 'classnames';
import { Badge, Tooltip } from 'common/components';
import Avatar from 'harmonic-components/Avatar/Avatar';
import { useState } from 'react';
import { shouldLinkCompanyId } from 'utils/company';
import {
  COMPANY_ROUTE,
  DASHBOARD_ROUTE,
  INVESTOR_ROUTE,
  PERSON_ROUTE
} from 'utils/constants';
import {
  InvestorFundingRoundMapValue,
  getFundingStageBadgeColor
} from 'utils/funding';
import { useOutsideClick } from 'utils/hooks';
import { getIdFromUrn } from 'utils/urn';
import { isSafeHostedAsset } from 'utils/utilities';
import InternalLink from '../InternalLink';
import Paper from '../Paper';

const InvestorRoundMap: Record<string, string> = {
  'Series A': 'A',
  'Series B': 'B',
  'Series C': 'C',
  'Series D': 'D',
  'Series E': 'E',
  'Series F': 'F',
  'Series G': 'G',
  'Series H': 'H',
  'Series I': 'I',
  'Series J': 'J',
  'Series 1': '1',
  'Series A1': 'A1',
  'Series A2': 'A2',
  'Series 2': '2',
  'Series A3': 'A3',
  'Series C1': 'C1',
  'Series B2': 'B2',
  'Series AA': 'AA',
  'Series 3': '3',
  'Series D1': 'D1',
  'Series C2': 'C2',
  'Series B3': 'B3',
  'Series BB': 'BB',
  'Series E1': 'E1',
  'Series D2': 'D2',
  'Series C3': 'C3',
  'Series AAA': 'AAA',
  'Series CC': 'CC',
  'Series E2': 'E2',
  'Series D3': 'D3'
};
interface InvestorRoundProps {
  round: InvestorFundingRoundMapValue;
  differentColorForLead?: boolean;
}
const InvestorRound: React.FC<InvestorRoundProps> = ({
  round,
  differentColorForLead
}) => {
  const mappedValue = InvestorRoundMap?.[round.name] ?? round.name;
  return (
    <Tooltip title={`${round.name} ${round.isLead ? '(Lead)' : ''}`}>
      <Badge
        label={mappedValue}
        color={
          round.isLead && differentColorForLead
            ? 'expressive-2'
            : getFundingStageBadgeColor(round.name)
        }
        intensity="subtle"
        dataTestId="InvestorRound-Chip"
      />
    </Tooltip>
  );
};

interface InvestorCardProps {
  investorName: string;
  entityUrn: string;
  dataTestId?: string;
  logoUrl?: string;
  showLeadChip?: boolean;
  limit?: number;
  currentRound?: string;
  rounds?: InvestorFundingRoundMapValue[];
}
const InvestorCard: React.FC<InvestorCardProps> = ({
  investorName,
  entityUrn = '',
  logoUrl,
  dataTestId,
  showLeadChip,
  currentRound = '',
  limit = 3,
  rounds = []
}) => {
  const [isMoreRoundsChipDropdownOpen, setIsMoreRoundsChipDropdownOpen] =
    useState(false);
  const id = getIdFromUrn(entityUrn);

  const isCompanyInvestor = entityUrn?.includes('urn:harmonic:company');
  const isPersonInvestor = entityUrn?.includes('urn:harmonic:person');
  const filteredRounds = rounds.filter((round) => round.name !== currentRound);
  const url = shouldLinkCompanyId(id)
    ? `${DASHBOARD_ROUTE}${
        isCompanyInvestor
          ? COMPANY_ROUTE
          : isPersonInvestor
          ? PERSON_ROUTE
          : INVESTOR_ROUTE
      }/${id}`
    : undefined;
  const toLink = url ? { pathname: url } : undefined;

  const defaultLogo = isCompanyInvestor
    ? defaultCompanyLogo
    : defaultPersonLogo;
  const logoExist = logoUrl && isSafeHostedAsset(logoUrl);
  const safeLogoUrl = logoExist ? logoUrl : defaultLogo;

  const roundsDropdownRef = useOutsideClick(() =>
    setIsMoreRoundsChipDropdownOpen(false)
  );

  const isGreaterThanLimit = filteredRounds.length > limit;

  return (
    <InternalLink
      data-testid="InvestorCard-Link"
      key={investorName}
      to={toLink}
    >
      <Paper isInteractive>
        <div
          data-testid={dataTestId}
          className="flex items-center justify-between gap-g50"
        >
          <div className="flex gap-g50 items-center">
            <Avatar
              src={safeLogoUrl}
              alt="company logo"
              kind={isCompanyInvestor ? 'logo' : 'face'}
              dataTestId="InvestorCard-Logo"
            />
            <div className="flex gap-g50">
              <p
                className={classNames(
                  'typography-label-default-strong text-content-default line-clamp-1'
                )}
              >
                {investorName}
              </p>
            </div>
          </div>

          <div className="flex gap-g30">
            {showLeadChip && (
              <Badge label="Lead" color="expressive-32" intensity="highlight" />
            )}
            {filteredRounds.length > 0 && (
              <>
                {filteredRounds.slice(0, limit).map((round) => (
                  <InvestorRound key={round.name} round={round} />
                ))}
                {isGreaterThanLimit && (
                  <div className="relative">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setIsMoreRoundsChipDropdownOpen(true);
                      }}
                      data-testid="MoreRounds-Toggle-Button"
                      className="text-content-headerGray text-content-core px-2 -ml-2"
                    >
                      +{filteredRounds.length - limit}
                    </button>
                    {isMoreRoundsChipDropdownOpen && (
                      <div
                        ref={roundsDropdownRef}
                        data-testid="MoreRounds-Dropdown"
                        className="absolute bg-white flex-nowrap p-2 min-w-20 border border-solid border-modal-mainStroke shadow-search rounded-md flex flex-col gap-1.5"
                      >
                        {filteredRounds.slice(limit).map((round) => (
                          <div key={round.name}>
                            <InvestorRound
                              differentColorForLead={false}
                              round={round}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </Paper>
    </InternalLink>
  );
};

export default InvestorCard;
