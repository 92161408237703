import { TriangleDownIcon, TriangleUpIcon } from 'assets/harmonic-icons';
import { Badge } from 'common/components';
import { isNil } from 'lodash';
import { addCommasToNumber } from 'utils/utilities';
export type TractionMetrics = {
  percentChange?: number | null;
  value?: number | null;
  change?: number | null;
};

interface PercentChangeBadgeProps {
  tractionMetrics?: TractionMetrics | null;
}

const PercentChangeBadge: React.FC<PercentChangeBadgeProps> = ({
  tractionMetrics = {}
}) => {
  const bothPercentAndValueExist =
    !isNil(tractionMetrics?.percentChange) && !isNil(tractionMetrics?.value);
  if (!bothPercentAndValueExist) return null;

  const isPositiveGrowth = (tractionMetrics?.percentChange as number) > 0;
  const isNegativeGrowth = (tractionMetrics?.percentChange as number) < 0;
  const isZeroGrowth = (tractionMetrics?.percentChange as number) === 0;

  if (isZeroGrowth) return null;
  return (
    <Badge
      color={isPositiveGrowth ? 'positive' : 'negative'}
      leadingIcon={
        isPositiveGrowth && !isZeroGrowth ? TriangleUpIcon : TriangleDownIcon
      }
      label={`${tractionMetrics?.percentChange?.toFixed(
        0
      )}% (${addCommasToNumber(tractionMetrics?.change?.toFixed(0) ?? '0')})`}
      size="large"
      dataTestId="PercentChangeBadge"
    />
  );
};

export default PercentChangeBadge;
