import dayjs from 'dayjs';
import { SingleDatePickerProps } from 'harmonic-components/DatePicker/DatePicker';
import DatePickerHeader from 'harmonic-components/DatePicker/DatePickerHeader';
import DatePickerInput from 'harmonic-components/DatePicker/DatePickerInput';
import ReactDatePicker from 'react-datepicker';
import { DateTypeEnum, InternalModeType } from './DateModal';

export const getDateFormat = (dateType: DateTypeEnum | undefined) => {
  switch (dateType) {
    case DateTypeEnum.YEAR:
      return 'yyyy';
    default:
      return 'yyyy-MM-dd';
  }
};

const SingleInlineDatePicker: React.FC<SingleDatePickerProps> = ({
  selectedDate,
  onChange,
  dateType = DateTypeEnum.DAY,
  internalModeType
}) => {
  const isYear = dateType === DateTypeEnum.YEAR;

  const onDateChange = (date: Date | null) => {
    if (internalModeType === InternalModeType.BEFORE && isYear) {
      date = date ? new Date(date.getFullYear(), 11, 31) : null;
    }
    onChange(date ? dayjs(date).format('YYYY-MM-DD') : null);
  };

  const dateObj = selectedDate
    ? dayjs(selectedDate, 'YYYY-MM-DD').toDate()
    : null;

  if (isYear) {
    return (
      <div>
        {/* Library does not support to show input and date picker together without being a popover.
      Two date picker is a workaround.
      One date picker to show input and another to show inline calendar.
      https://github.com/Hacker0x01/react-datepicker/issues/1724
      */}
        <div className="pb-p50 border-b border-solid border-border">
          <ReactDatePicker
            open={false}
            selected={dateObj}
            onChange={(date) => onDateChange(date)}
            dateFormat={getDateFormat(dateType)}
            showPopperArrow={false}
            calendarClassName="hidden"
            wrapperClassName="w-full"
            className="w-full"
            customInput={
              //eslint-disable-next-line
              //@ts-ignore
              <DatePickerInput
                native={false}
                inputPlaceholder={'Select a year'}
              />
            }
            showYearPicker={dateType === DateTypeEnum.YEAR}
            showYearDropdown={dateType === DateTypeEnum.YEAR}
          />
        </div>
        <ReactDatePicker
          selected={dateObj}
          onChange={(date) => onDateChange(date)}
          dateFormat={getDateFormat(dateType)}
          calendarClassName="flex flex-col border-none"
          showPopperArrow={false}
          renderCustomHeader={({ ...props }) => {
            return (
              <DatePickerHeader
                {...props}
                selectedDate={selectedDate}
                dateType={dateType}
                onChange={(date) => onDateChange(date)}
              />
            );
          }}
          inline
          showYearPicker={dateType === DateTypeEnum.YEAR}
          showYearDropdown={dateType === DateTypeEnum.YEAR}
        />
      </div>
    );
  }

  return (
    <div>
      {/* Library does not support to show input and date picker together without being a popover.
        Two date picker is a workaround.
        One date picker to show input and another to show inline calendar.
        https://github.com/Hacker0x01/react-datepicker/issues/1724
        */}
      <div className="pb-p50 border-b border-solid border-border">
        <ReactDatePicker
          open={false}
          selected={dateObj}
          onChange={(date) => onDateChange(date)}
          dateFormat="yyyy-MM-dd"
          showPopperArrow={false}
          calendarClassName="hidden"
          wrapperClassName="w-full"
          className="w-full"
          //eslint-disable-next-line
          //@ts-ignore
          customInput={<DatePickerInput />}
        />
      </div>
      <ReactDatePicker
        selected={dateObj}
        onChange={(date) => onDateChange(date)}
        dateFormat="yyyy-MM-dd"
        calendarClassName="flex flex-col border-none"
        showPopperArrow={false}
        renderCustomHeader={({ ...props }) => {
          return <DatePickerHeader {...props} selectedDate={selectedDate} />;
        }}
        inline
      />
    </div>
  );
};

export default SingleInlineDatePicker;
