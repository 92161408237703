import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const TargetIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className,
  ...props
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
      fill="#7C8598"
      {...props}
    >
      <path
        vectorEffect="non-scaling-stroke"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99967 1.33325C11.6816 1.33325 14.6663 4.31802 14.6663 7.99992C14.6663 11.6818 11.6816 14.6666 7.99967 14.6666C4.31778 14.6666 1.33301 11.6818 1.33301 7.99992C1.33301 4.31802 4.31778 1.33325 7.99967 1.33325ZM7.99967 3.99992C8.27582 3.99992 8.49967 4.22378 8.49967 4.49992V6.49992C8.49967 6.77606 8.27582 6.99992 7.99967 6.99992C7.72353 6.99992 7.49967 6.77606 7.49967 6.49992V4.49992C7.49967 4.22378 7.72353 3.99992 7.99967 3.99992ZM9.49967 7.49992C9.22353 7.49992 8.99967 7.72378 8.99967 7.99992C8.99967 8.27606 9.22353 8.49992 9.49967 8.49992H11.4997C11.7758 8.49992 11.9997 8.27606 11.9997 7.99992C11.9997 7.72378 11.7758 7.49992 11.4997 7.49992H9.49967ZM7.99967 8.99992C8.27582 8.99992 8.49967 9.22378 8.49967 9.49992V11.4999C8.49967 11.7761 8.27582 11.9999 7.99967 11.9999C7.72353 11.9999 7.49967 11.7761 7.49967 11.4999V9.49992C7.49967 9.22378 7.72353 8.99992 7.99967 8.99992ZM4.49967 7.49992C4.22353 7.49992 3.99967 7.72378 3.99967 7.99992C3.99967 8.27606 4.22353 8.49992 4.49967 8.49992H6.49967C6.77582 8.49992 6.99967 8.27606 6.99967 7.99992C6.99967 7.72378 6.77582 7.49992 6.49967 7.49992H4.49967Z"
      />
    </svg>
  );
};

export default TargetIcon;
