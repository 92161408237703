import classNames from 'classnames';
import { IconProps } from './type';

const LinkedInColorless: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="#7C8598"
      fillRule="evenodd"
      clipRule="evenodd"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M14.6667 0.5H1.33333C0.8725 0.5 0.5 0.873333 0.5 1.33333V14.6667C0.5 15.1267 0.8725 15.5 1.33333 15.5H14.6667C15.1275 15.5 15.5 15.1267 15.5 14.6667V1.33333C15.5 0.873333 15.1275 0.5 14.6667 0.5ZM4.94917 13.2808H2.7225V6.1225H4.94917V13.2808ZM3.83583 5.145C3.1225 5.145 2.54583 4.56667 2.54583 3.855C2.54583 3.14333 3.12167 2.565 3.83583 2.565C4.5475 2.565 5.12583 3.1425 5.12583 3.855C5.12583 4.5675 4.5475 5.145 3.83583 5.145ZM13.2817 13.2808H11.0575V9.8C11.0575 8.97 11.0425 7.9025 9.90083 7.9025C8.7425 7.9025 8.56667 8.8075 8.56667 9.74167V13.2817H6.34417V6.12333H8.4775V7.10167H8.50833C8.80417 6.53917 9.53083 5.94583 10.6117 5.94583C12.865 5.94583 13.2808 7.4275 13.2808 9.35583V13.2808H13.2817Z"
      />
    </svg>
  );
};

export default LinkedInColorless;
