import classNames from 'classnames';
import { IconProps } from './type';

const ArrowTopRightCircle: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="#7C8598"
      fillRule="evenodd"
      clipRule="evenodd"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M15.8925 15.8926C12.6382 19.147 7.3618 19.147 4.10743 15.8926C0.853064 12.6383 0.853064 7.36189 4.10743 4.10752C7.3618 0.853156 12.6382 0.853156 15.8925 4.10752C19.1469 7.36189 19.1469 12.6383 15.8925 15.8926ZM6.84972 13.1504C6.60564 12.9063 6.60564 12.5106 6.84972 12.2665L11.1994 7.91675H8.12499C7.77981 7.91675 7.49999 7.63693 7.49999 7.29175C7.49999 6.94657 7.77981 6.66675 8.12499 6.66675H12.7083C13.0535 6.66675 13.3333 6.94657 13.3333 7.29175V11.8751C13.3333 12.2203 13.0535 12.5001 12.7083 12.5001C12.3631 12.5001 12.0833 12.2203 12.0833 11.8751V8.80063L7.7336 13.1504C7.48952 13.3944 7.09379 13.3944 6.84972 13.1504Z"
      />
    </svg>
  );
};

export default ArrowTopRightCircle;
