import { createContext } from 'react';
import { SelectListItemProps } from './SelectListItem';

interface SelectContext {
  open: boolean;
  close: () => void;
  keyboardFocusIndex: number;
  listItems: SelectListItemProps[];
}

const SelectContext = createContext<SelectContext>({
  open: false,
  //eslint-disable-next-line
  close: () => {},
  keyboardFocusIndex: 0,
  listItems: []
});

export default SelectContext;
