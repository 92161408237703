import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const EmailFilledIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      fill="#B8BECB"
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M1.00328 2.8963C0.999989 2.97661 0.999995 3.06634 1 3.16184V8.83811C0.999992 8.96684 0.999985 9.08511 1.00806 9.18394C1.01677 9.2905 1.0367 9.40709 1.09537 9.52224C1.17926 9.68689 1.31312 9.82074 1.47776 9.90463C1.59291 9.96331 1.7095 9.98324 1.81607 9.99194C1.91491 10 2.03315 10 2.16189 10H9.83812C9.96686 10 10.0851 10 10.1839 9.99194C10.2905 9.98324 10.4071 9.96331 10.5222 9.90463C10.6869 9.82074 10.8207 9.68689 10.9046 9.52224C10.9633 9.40709 10.9832 9.2905 10.9919 9.18394C11 9.0851 11 8.96686 11 8.83812V3.16189C11 3.06637 11 2.97663 10.9967 2.8963L6.55409 6.53118C6.23177 6.7949 5.76824 6.7949 5.44592 6.53118L1.00328 2.8963Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.6725 2.19249C10.6261 2.15527 10.5758 2.12267 10.5222 2.09537C10.4071 2.0367 10.2905 2.01677 10.1839 2.00806C10.0851 1.99999 9.96689 1.99999 9.83816 2H2.16189C2.03317 1.99999 1.9149 1.99999 1.81607 2.00806C1.7095 2.01677 1.59291 2.0367 1.47776 2.09537C1.42418 2.12267 1.37386 2.15527 1.32746 2.19249L5.92085 5.95072C5.96689 5.98839 6.03311 5.98839 6.07916 5.95072L10.6725 2.19249Z"
      />
    </svg>
  );
};

export default EmailFilledIcon;
