import BellIcon from 'assets/harmonic-icons/bell';
import CheckIcon from 'assets/harmonic-icons/check';
import harmonicLogo from 'assets/harmonic-icons/harmonic-gradient-logo.svg';
import Button from 'harmonic-components/Button/Button';

interface ComingSoonModalProps {
  comingSoonText: string;
  joinedText: string;
  submitAction: () => void;
  hasJoined: boolean;
  isLoading: boolean;
}
const ComingSoonModal: React.FC<ComingSoonModalProps> = ({
  comingSoonText,
  joinedText,
  submitAction,
  hasJoined,
  isLoading
}) => {
  const dummyRow = (opacity: string, sizes: string[][]) => {
    return (
      <div className="grid grid-cols-8" style={{ opacity: opacity + '%' }}>
        {sizes.map((pair, index) => (
          <div className="px-p60 pt-p60" key={`fake-row-${index}}`}>
            <div
              className="bg-slate-500 rounded-br20"
              style={{ width: pair[0] + '%', height: pair[1] + '%' }}
            >
              &nbsp;
            </div>
          </div>
        ))}
      </div>
    );
  };

  const rectangleSizes = [
    [
      ['50', '50'],
      ['80', '50'],
      ['30', '50'],
      ['50', '50'],
      ['60', '50'],
      ['70', '50'],
      ['30', '50'],
      ['60', '50']
    ],
    [
      ['70', '70'],
      ['70', '50'],
      ['50', '50'],
      ['40', '50'],
      ['50', '50'],
      ['30', '50'],
      ['40', '50'],
      ['70', '50']
    ],
    [
      ['70', '70'],
      ['30', '50'],
      ['60', '50'],
      ['30', '50'],
      ['40', '50'],
      ['30', '50'],
      ['30', '50'],
      ['80', '50']
    ],
    [
      ['80', '70'],
      ['70', '50'],
      ['30', '50'],
      ['50', '50'],
      ['50', '50'],
      ['30', '50'],
      ['30', '50'],
      ['70', '50']
    ]
  ];

  return (
    <div className="grid my-g80">
      <div className="col-start-1 row-start-1 opacity-25 md:hidden lg:block">
        {dummyRow('35', rectangleSizes[0])}
        {dummyRow('25', rectangleSizes[1])}
        {dummyRow('15', rectangleSizes[2])}
        {dummyRow('5', rectangleSizes[3])}
      </div>
      <div className="col-start-1 row-start-1 opacity-1000">
        <div className="flex justify-center border-border border border-solid p-p80 rounded-br30">
          <div className="max-w-[415px]">
            <div className="flex justify-center">
              <img src={harmonicLogo} className="mb-p60 w-[60px] h-[60px]" />
            </div>
            <div className="typography-title-extra-small text-content-strong text-center mb-g40">
              {hasJoined ? joinedText : comingSoonText}
            </div>
            <div className="flex justify-center ">
              <Button
                dataTestId="JoinCapWaitlist-Submit-Button"
                type="secondary"
                emphasis="medium"
                leadingIcon={hasJoined ? CheckIcon : BellIcon}
                label={hasJoined ? 'Joined' : 'Join the waitlist'}
                loading={isLoading}
                isDisabled={hasJoined}
                onClick={submitAction}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoonModal;
