import {
  BarController,
  BarElement,
  CategoryScale,
  ChartOptions,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  ScatterDataPoint,
  Tooltip
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import dayjs from 'dayjs';
import { ILineChartDataPoint } from 'interfaces/Charts';
import { maxBy } from 'lodash';
import React from 'react';
import Chart, { Chart as ChartJS } from 'react-chartjs-2';
import {
  addInterpolatedDatePoint,
  CHART_DEFAULT_MINIMUM_START_DATE,
  chartAreaRoundedBorderPlugin,
  findYBetweenTwoPointsWhenXIsKnown
} from 'utils/chart';
import { getFundingStageGraphColor } from 'utils/funding';
import { truncateMoneyValue } from 'utils/utilities';
import { dayjsExt } from '../../config/dayjs';

ChartJS.register(annotationPlugin);
ChartJS.register(chartAreaRoundedBorderPlugin);

const CURRENT_EMPLOYMENT_COLOUR = '#3A57A6';
export interface BarChartData extends ILineChartDataPoint {
  label: string;
}
export const getMinimumDateBetweenLineAndBar = (
  lineChartData: ILineChartDataPoint[],
  barChartData: BarChartData[]
) => {
  let minimumDate: string | undefined = CHART_DEFAULT_MINIMUM_START_DATE;

  const sortedLineChartData = [...lineChartData].sort(
    (a, b) =>
      new Date(a?.x as string).getTime() - new Date(b?.x as string).getTime()
  );
  const sortedBarChartData = [...barChartData].sort(
    (a, b) =>
      new Date(a?.x as string).getTime() - new Date(b?.x as string).getTime()
  );
  const lineChartMinimumDate = sortedLineChartData?.[0]?.x;
  const barChartMinimumDate = sortedBarChartData?.[0]?.x;
  if (lineChartMinimumDate) minimumDate = lineChartMinimumDate;
  if (barChartMinimumDate) minimumDate = barChartMinimumDate;
  if (lineChartMinimumDate && barChartMinimumDate)
    minimumDate = dayjsExt
      .min(dayjs(lineChartMinimumDate), dayjs(barChartMinimumDate))
      ?.format('YYYY-MM-DD');
  if (dayjs(minimumDate).isBefore(CHART_DEFAULT_MINIMUM_START_DATE, 'day')) {
    minimumDate = CHART_DEFAULT_MINIMUM_START_DATE;
  }

  return minimumDate;
};

// Imaginary dotted line drawn to the very first dataset.
export const getDashedLineDataset = (
  lineChartData: ILineChartDataPoint[],
  minimumDate: string,
  employmentDuration?: EmploymentDuration
) => {
  const dashedData = [];
  const dashedLineMaximumDate = dayjs(lineChartData?.[0]?.x).format(
    'YYYY-MM-DD'
  );
  if (!dayjs(minimumDate).isBefore(dashedLineMaximumDate)) return [];

  const dashedLineMinimumDate = dayjs(minimumDate).subtract(30, 'day');
  const dashedLineMaximumYValue = lineChartData?.[0]?.y;

  dashedData.push({
    x: dashedLineMinimumDate.format('YYYY-MM-DD'),
    y: 0
  });
  //Adding a extra point in dashed line where user started the job in the company
  if (
    employmentDuration?.startDate &&
    dayjs(dashedLineMaximumDate).isAfter(employmentDuration.startDate)
  ) {
    dashedData.push({
      x: employmentDuration.startDate,
      y: findYBetweenTwoPointsWhenXIsKnown(
        dashedLineMinimumDate.format('YYYY-MM-DD'),
        dashedLineMaximumDate,
        0,
        dashedLineMaximumYValue,
        employmentDuration.startDate
      )
    });
  }
  //Adding a extra point in dashed line where user started the job in the company
  if (
    employmentDuration?.startDate &&
    employmentDuration?.endDate &&
    dayjs(dashedLineMaximumDate).isAfter(employmentDuration.endDate)
  ) {
    dashedData.push({
      x: employmentDuration.endDate,
      y: findYBetweenTwoPointsWhenXIsKnown(
        employmentDuration.startDate,
        dashedLineMaximumDate,
        dashedData?.[1].y,
        dashedLineMaximumYValue,
        employmentDuration.endDate
      )
    });
  }

  dashedData.push({
    x: dashedLineMaximumDate,
    y: lineChartData?.[0]?.y
  });

  return dashedData;
};

export const getChartCutoffMinimumDate = (
  defaultMinimumStartDate: string,
  foundingDate?: string,
  employmentDuration?: EmploymentDuration
) => {
  let chartCutoffDate = defaultMinimumStartDate;

  if (
    employmentDuration?.startDate &&
    dayjs(employmentDuration?.startDate).isBefore('2021-01-1')
  ) {
    chartCutoffDate = dayjs(employmentDuration?.startDate)
      .subtract(1, 'year')
      .format('YYYY-MM-DD');
  }

  if (foundingDate && dayjs(chartCutoffDate).isBefore(foundingDate)) {
    chartCutoffDate = dayjs(foundingDate)
      .subtract(1.5, 'months')
      .format('YYYY-MM-DD');
  }

  if (
    employmentDuration?.startDate &&
    foundingDate &&
    dayjs(employmentDuration?.startDate).isBefore(foundingDate)
  ) {
    chartCutoffDate = dayjs(employmentDuration?.startDate)
      .subtract(1.5, 'months')
      .format('YYYY-MM-DD');
  }

  return chartCutoffDate;
};

export const isDateWithinEmploymentDuration = (
  currentDate: string,
  employmentDuration?: EmploymentDuration
) => {
  if (
    employmentDuration?.startDate &&
    !employmentDuration?.endDate &&
    dayjsExt(currentDate).isSameOrAfter(
      dayjs(employmentDuration?.startDate).format('YYYY-MM-DD')
    )
  ) {
    return true;
  }

  if (
    !employmentDuration?.startDate &&
    employmentDuration?.endDate &&
    dayjsExt(currentDate).isSameOrBefore(
      dayjs(employmentDuration?.endDate).format('YYYY-MM-DD')
    )
  ) {
    return true;
  }

  if (
    employmentDuration?.startDate &&
    employmentDuration?.endDate &&
    dayjsExt(currentDate).isSameOrAfter(
      dayjs(employmentDuration?.startDate).format('YYYY-MM-DD')
    ) &&
    dayjs(currentDate).isBefore(
      dayjs(employmentDuration?.endDate).format('YYYY-MM-DD')
    )
  ) {
    return true;
  }
  return false;
};

// Chart won't have data points for exact date of employment start date and end date. That will make green line that we draw for employment slightly off. Manually adding those missing points
export const getInterpolatedLineChartDataWithEmployment = (
  lineChartData: ILineChartDataPoint[],
  employmentDuration?: EmploymentDuration
) => {
  if (
    !employmentDuration ||
    !(employmentDuration?.startDate || employmentDuration?.endDate) ||
    lineChartData.length === 0
  )
    return lineChartData;

  if (
    employmentDuration?.startDate &&
    employmentDuration?.endDate &&
    dayjs(employmentDuration?.startDate).isBefore(lineChartData?.[0]?.x) &&
    dayjs(employmentDuration?.endDate).isBefore(lineChartData?.[0]?.x)
  )
    return lineChartData;

  let interpolatedLineChartData = lineChartData;
  const lineChartDateSets = new Set(
    lineChartData.map((data) => dayjs(data.x).format('YYYY-MM-DD'))
  );
  if (
    employmentDuration?.startDate &&
    dayjs(employmentDuration?.startDate).isAfter(lineChartData?.[0]?.x) &&
    !lineChartDateSets.has(
      dayjs(employmentDuration?.startDate).format('YYYY-MM-DD')
    )
  ) {
    interpolatedLineChartData = addInterpolatedDatePoint(
      interpolatedLineChartData,
      employmentDuration?.startDate
    );
  }

  if (
    employmentDuration.endDate &&
    dayjs(employmentDuration?.endDate).isBefore(
      lineChartData?.[lineChartData.length - 1]?.x
    ) &&
    !lineChartDateSets.has(
      dayjs(employmentDuration?.endDate).format('YYYY-MM-DD')
    )
  ) {
    interpolatedLineChartData = addInterpolatedDatePoint(
      interpolatedLineChartData,
      employmentDuration?.endDate
    );
  }

  return interpolatedLineChartData;
};

const generateChartData = (
  lineChartData: ILineChartDataPoint[],
  barChartData: BarChartData[],
  theme: BarLineChartTheme,
  foundingDate?: string,
  employmentDuration?: EmploymentDuration
) => {
  const extraDatasets = [];
  let dashedData = [] as ScatterDataPoint[];
  if (foundingDate && dayjs(foundingDate).isValid()) {
    let minimumDateForDashedLine = foundingDate;
    if (
      employmentDuration?.startDate &&
      dayjs(employmentDuration?.startDate).isBefore(foundingDate)
    ) {
      minimumDateForDashedLine = employmentDuration?.startDate;
    }
    dashedData = getDashedLineDataset(
      lineChartData,
      minimumDateForDashedLine,
      employmentDuration
    ) as unknown as ScatterDataPoint[];
  }

  const segmentBackgroundColorFunction = (ctx: any) => {
    const blueBackgroundColor = 'rgba(58, 145, 166, 0.1)';
    const defaultColor = 'transparent';
    if (theme === BarLineChartTheme.COMPANY) return defaultColor;
    const currentDate = ctx?.p0?.raw?.x;

    if (
      !currentDate ||
      (!dayjs(employmentDuration?.startDate).isValid() &&
        !dayjs(employmentDuration?.endDate).isValid())
    )
      return defaultColor;

    if (
      isDateWithinEmploymentDuration(
        currentDate,
        employmentDuration as EmploymentDuration
      )
    )
      return blueBackgroundColor;
    return defaultColor;
  };

  if (dashedData.length > 0) {
    extraDatasets.push({
      type: 'line' as const,
      yAxisId: 'y1',
      data: dashedData,
      pointHitRadius: 0,
      borderWidth: 2,
      fill: theme === BarLineChartTheme.PERSON,
      pointRadius: 0,
      borderDash: theme === BarLineChartTheme.PERSON ? [] : [4, 3],
      segment: {
        borderColor: (ctx: any) => {
          if (theme === BarLineChartTheme.COMPANY) return '#B8BECB';
          const currentDate = ctx?.p0?.raw?.x;
          const defaultColour = '#B8BECB';
          if (
            !currentDate ||
            (!dayjs(employmentDuration?.startDate).isValid() &&
              !dayjs(employmentDuration?.endDate).isValid())
          )
            return defaultColour;
          if (
            isDateWithinEmploymentDuration(
              currentDate,
              employmentDuration as EmploymentDuration
            )
          )
            return CURRENT_EMPLOYMENT_COLOUR;
        },
        backgroundColor: segmentBackgroundColorFunction
      }
    });
  }

  const barChartDataSets = [];
  if (barChartData.length > 0) {
    barChartDataSets.push({
      type: 'bar' as const,
      labels: barChartData.map((d) => d.label),
      yAxisID: 'y2',
      borderRadius: 4,
      order: 1,
      backgroundColor:
        theme === BarLineChartTheme.PERSON
          ? '#74A63A'
          : barChartData.map((d) => getFundingStageGraphColor(d.label)),
      offsetGridLines: false,
      data: barChartData as unknown as ScatterDataPoint[],
      borderWidth: 0,
      barThickness: theme === BarLineChartTheme.COMPANY ? 20 : 24
    });
  }

  const interpolatedLineChartData = getInterpolatedLineChartDataWithEmployment(
    lineChartData,
    employmentDuration
  );
  return {
    datasets: [
      ...extraDatasets,
      {
        type: 'line' as const,
        label: 'Headcount',
        yAxisID: 'y1',
        pointHitRadius: 10,
        borderWidth: 2,
        fill: theme === BarLineChartTheme.PERSON,
        pointRadius: 0,
        data: interpolatedLineChartData as unknown as ScatterDataPoint[],
        order: 0,
        lineTension: 0.4,
        segment: {
          borderColor: (ctx: any) => {
            if (theme === BarLineChartTheme.COMPANY) return '#3A57A6';
            const currentDate = ctx?.p0?.raw?.x;
            const defaultColor = '#B8BECB';
            if (!currentDate || !employmentDuration) return defaultColor;
            if (isDateWithinEmploymentDuration(currentDate, employmentDuration))
              return CURRENT_EMPLOYMENT_COLOUR;
          },
          backgroundColor: segmentBackgroundColorFunction
        }
      },
      ...barChartDataSets
    ]
  };
};
// For drawing the icon between startData and endDate of person experience and boxes.
const generateAnnotations = (employmentDuration?: EmploymentDuration) => {
  const annotations: Record<string, any> = {};

  if (
    !dayjs(employmentDuration?.startDate).isValid() &&
    !dayjs(employmentDuration?.endDate).isValid()
  )
    return;

  if (!employmentDuration) return;

  if (employmentDuration.startDate) {
    annotations.lineMin = {
      type: 'line',
      borderColor: '#D0D4DD',
      yMin: 0,
      borderWidth: 1,
      yMax: undefined,
      xMin: employmentDuration.startDate,
      xMax: employmentDuration.startDate
    };
  }
  if (employmentDuration.endDate) {
    annotations.lineMax = {
      type: 'line',
      borderColor: '#D0D4DD',
      yMin: 0,
      borderWidth: 1,
      yMax: undefined,
      xMin: employmentDuration.endDate,
      xMax: employmentDuration.endDate
    };
  }

  return annotations;
};
const generateChartOptions = (
  lineChartData: ILineChartDataPoint[],
  barChartData: BarChartData[],
  foundingDate?: string,
  employmentDuration?: EmploymentDuration
): ChartOptions => {
  const chartCutOffDate = getChartCutoffMinimumDate(
    CHART_DEFAULT_MINIMUM_START_DATE,
    foundingDate,
    employmentDuration
  );
  const maxYOfLineChart = maxBy(lineChartData, (d) => d.y)?.y;
  const maxYOfBarChart = maxBy(barChartData, (d) => d.y)?.y;
  let suggestedMaxForY1 = 0;

  // Making y-axis maximum label 20% more than value so that line does not reach edge of the chart
  if (maxYOfLineChart) {
    suggestedMaxForY1 = maxYOfLineChart + 0.2 * maxYOfLineChart;
  }
  const annotations = generateAnnotations(employmentDuration);
  return {
    maintainAspectRatio: false,

    interaction: {
      intersect: true,
      mode: 'nearest'
    },
    scales: {
      xAxes: {
        type: 'time',
        offset: false,

        grid: {
          lineWidth: 1,
          display: false,
          borderDash: [8, 4],
          drawBorder: false
        },
        min: chartCutOffDate,
        time: {
          tooltipFormat: 'MMM DD, YYYY',
          unit: 'month',
          displayFormats: {
            year: 'MMM YYYY',
            quarter: 'MMM YYYY',
            month: 'MMM YYYY'
          }
        },
        ticks: {
          maxTicksLimit: 5,
          autoSkip: true,
          source: 'auto',
          maxRotation: 0
        }
      },
      y1: {
        min: 0,
        position: 'left',
        grid: {
          display: false,
          borderDash: [4, 4],
          drawBorder: false
        },
        stacked: false,
        suggestedMax: suggestedMaxForY1,
        ticks: {
          maxTicksLimit: 3,
          callback: (label) => {
            return label;
          }
        }
      },

      y2:
        barChartData.length > 0
          ? {
              position: 'right',
              min: 0,
              stacked: true,
              grid: {
                display: false,
                borderDash: [4, 7],
                drawBorder: false
              },

              ticks: {
                maxTicksLimit: 3,
                backdropPadding: 25,

                callback: (label) => {
                  return truncateMoneyValue(label as number, 0);
                }
              }
            }
          : undefined,
      // This is a hidden axis to just draw the dashed line in the grid. Chartjs dashed lines are tied to the number of ticks.
      // We have to do this trick to show dashed lines independent of ticks
      y3: {
        position: 'right',
        min: 0,
        max: barChartData.length > 0 ? maxYOfBarChart : maxYOfLineChart,
        stacked: true,
        grid: {
          display: true,
          borderDash: [4, 7],
          tickLength: 0,
          drawBorder: false
        },

        ticks: {
          maxTicksLimit: 7,
          backdropPadding: 0,
          display: false,
          includeBounds: false,
          callback: (label, index, allTicks) => {
            if (
              allTicks[index].value === 0 ||
              allTicks[index].value === maxYOfBarChart ||
              allTicks[index].value === maxYOfLineChart
            )
              return undefined;

            return '';
          }
        }
      }
    },

    plugins: {
      legend: {
        position: 'bottom',
        display: false
      },
      tooltip: {
        displayColors: false,

        callbacks: {
          title: (ctx) => {
            if (ctx?.[0]?.dataset?.type === 'line')
              return ctx?.[0]?.dataset?.label?.toUpperCase() ?? '';
            const datasetIndex = ctx?.[0]?.dataIndex;
            //eslint-disable-next-line
            //@ts-ignore
            return ctx?.[0]?.dataset?.labels?.[datasetIndex];
          },
          label: (ctx) => {
            const value = ctx?.parsed?.y;
            if (ctx.dataset.type === 'line') return [`${value}`, ctx.label];
            return [`Raised: ${truncateMoneyValue(value, 0)}`, ctx.label];
          }
        }
      },

      annotation: {
        annotations
      }
    }
  };
};

export enum BarLineChartTheme {
  COMPANY = 'COMPANY',
  PERSON = 'PERSON'
}

export interface EmploymentDuration {
  startDate?: string | null;
  endDate?: string | null;
}
interface BarLineChartProps {
  lineChartData: ILineChartDataPoint[];
  barChartData: BarChartData[];
  theme?: BarLineChartTheme;
  foundingDate?: string;
  employmentDuration?: EmploymentDuration;
}

const BarLineChart: React.FC<BarLineChartProps> = ({
  lineChartData,
  barChartData,
  theme = BarLineChartTheme.COMPANY,
  foundingDate,
  employmentDuration
}) => {
  ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
  );
  const data = generateChartData(
    lineChartData,
    barChartData,
    theme,
    foundingDate,
    employmentDuration
  );
  const chartOptions = generateChartOptions(
    lineChartData,
    barChartData,
    foundingDate,
    employmentDuration
  );

  return <Chart type="bar" data={data} options={chartOptions} />;
};

export default BarLineChart;
