import classNames from 'classnames';
import { IconProps } from './type';

const GlassesIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#493AA6"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M4 4.66675C2.99878 4.66675 2.101 5.10841 1.49047 5.80614C1.38692 5.92448 1.24062 6.00008 1.08337 6.00008H0.5C0.223858 6.00008 0 6.22394 0 6.50008C0 6.77622 0.223858 7.00008 0.5 7.00008C0.658321 7.00008 0.771199 7.15404 0.738509 7.30894C0.691426 7.53206 0.666667 7.76328 0.666667 8.00008C0.666667 9.84103 2.15905 11.3334 4 11.3334C5.84095 11.3334 7.33333 9.84103 7.33333 8.00008C7.33333 7.6608 7.28252 7.33305 7.18808 7.02422C7.1069 6.75877 7.2093 6.45302 7.47998 6.39147C7.6471 6.35347 7.82112 6.33341 8 6.33341C8.17888 6.33341 8.3529 6.35347 8.52002 6.39147C8.7907 6.45302 8.8931 6.75877 8.81192 7.02422C8.71749 7.33305 8.66667 7.6608 8.66667 8.00008C8.66667 9.84103 10.1591 11.3334 12 11.3334C13.8409 11.3334 15.3333 9.84103 15.3333 8.00008C15.3333 7.76328 15.3086 7.53206 15.2615 7.30894C15.2288 7.15404 15.3417 7.00008 15.5 7.00008C15.7761 7.00008 16 6.77622 16 6.50008C16 6.22394 15.7761 6.00008 15.5 6.00008H14.9166C14.7594 6.00008 14.6131 5.92448 14.5095 5.80614C13.899 5.10841 13.0012 4.66675 12 4.66675C11.1771 4.66675 10.4239 4.96521 9.84276 5.45892C9.67043 5.60534 9.42802 5.64555 9.21752 5.56292C8.84017 5.41479 8.42934 5.33341 8 5.33341C7.57066 5.33341 7.15983 5.41478 6.78248 5.56292C6.57198 5.64555 6.32957 5.60534 6.15724 5.45892C5.57613 4.96521 4.82294 4.66675 4 4.66675Z"
      />
    </svg>
  );
};

export default GlassesIcon;
