import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const ThumbUpFillIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      fill="#484E5B"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        vectorEffect="non-scaling-stroke"
        d="M11.611 2C10.9567 2 10.3546 2.36515 10.054 2.94858L6.42148 10H3.75C2.7835 10 2 10.7835 2 11.75V19.25C2 20.2165 2.7835 21 3.75 21H17.422C19.2752 21 20.8505 19.6463 21.1293 17.8142L21.9663 12.3142C22.3117 10.0444 20.5548 8 18.2589 8H13.8921L14.3328 5.17364C14.5932 3.50371 13.2962 2 11.611 2ZM6 11.5V19.5H3.75C3.61193 19.5 3.5 19.3881 3.5 19.25V11.75C3.5 11.6119 3.61193 11.5 3.75 11.5H6Z"
      />
    </svg>
  );
};

export default ThumbUpFillIcon;
