import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const EarthIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke="#1A1D23"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M9.49601 2.01629L8.76468 4.94575C8.70511 5.18436 8.51901 5.3708 8.28051 5.4308L6.30019 5.92898C6.11247 5.9762 5.95454 6.10269 5.86745 6.27557L5.6928 6.62227C5.50606 6.99295 5.02963 7.10472 4.69752 6.85575L2.49938 5.20787M9.49601 2.01629C9.0168 1.8968 8.5154 1.83337 7.99919 1.83337C5.59843 1.83337 3.5181 3.20527 2.49938 5.20787M9.49601 2.01629C12.1785 2.68514 14.1659 5.1105 14.1659 8.00004C14.1659 11.4058 11.4049 14.1667 7.99919 14.1667C4.59343 14.1667 1.83252 11.4058 1.83252 8.00004C1.83252 6.99504 2.07293 6.04619 2.49938 5.20787M7.80323 11.0365L6.97153 9.78897C6.795 9.52418 6.83024 9.17154 7.05568 8.94692L7.19417 8.80893C7.32958 8.674 7.5164 8.60369 7.70718 8.61587L8.52265 8.66791C8.64042 8.67543 8.75408 8.71407 8.85203 8.77989L10.1811 9.67302C10.4594 9.86002 10.5555 10.2246 10.4056 10.5245L10.1854 10.9649C10.0725 11.1907 9.84163 11.3334 9.58912 11.3334H8.35793C8.13503 11.3334 7.92687 11.222 7.80323 11.0365Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EarthIcon;
