import { useQuery } from '@apollo/client';
import { ReactComponent as BoxBarChart } from 'assets/bxs-bar-chart-alt.svg';
import { ReactComponent as BoxHandHoldingUsd } from 'assets/bxs-hand-holding-usd.svg';

import {
  GetCompanyFinancingV2Query,
  GetCompanyFinancingV2QueryVariables,
  InvestorFragment
} from '__generated__/graphql';
import InvestorCard from 'components/common/CompanyCard/InvestorCard';
import MetricCard from 'components/common/CompanyCard/MetricCard';
import ReportDataIssue from 'components/common/ReportDataIssue';
import useFlags from 'hooks/useFlags';
import { ICapTableEntry } from 'interfaces/DataModel/ApiResource';
import {
  ReportSourceSection,
  ReportSourceView
} from 'interfaces/UserReportedDataIssue';
import { isNil } from 'lodash';
import getCompanyCapData from 'mocks/mock-data/get_company_cap_table';
import { getCompanyFinancing } from 'queries/getCompanyFinancingV2';
import { useEffect, useMemo, useState } from 'react';
import { getCapTableForCompanyId } from 'utils/api';
import { SPLITS } from 'utils/constants';
import { getInvestorFundingRoundsMap } from 'utils/funding';
import FundingTotal from '../Cards/FundingTotal';
import CapTable from './CapTable';
import CapTableWaitlistForm from './CapTableWaitlistForm';
import CompanyFinancingContentLoader from './CompanyFinancingContentLoader';
import CompanySingleRbr from './CompanySingleRbr';

interface CompanyFinancingProps {
  companyId: number;
}

const CompanyFinancing: React.FC<CompanyFinancingProps> = ({ companyId }) => {
  const { data } = useQuery<
    GetCompanyFinancingV2Query,
    GetCompanyFinancingV2QueryVariables
  >(getCompanyFinancing, {
    variables: { id: companyId },
    fetchPolicy: 'cache-only'
  });
  const investorsMap = useMemo(() => {
    const investors = data?.getCompanyById?.funding?.investors ?? [];
    return investors.reduce((acc, investor) => {
      if (!investor?.name) return acc;
      acc[investor.name] = investor;
      return acc;
    }, {} as Record<string, InvestorFragment>);
  }, [data?.getCompanyById?.funding?.investors]);
  const { enabled: showCapTable } = useFlags(SPLITS.showCapTable);
  const { enabled: showCapTableMock } = useFlags(SPLITS.showCapTableMock);
  const { enabled: showCapTableWaitlist } = useFlags(
    SPLITS.showCapTableWaitlist
  );
  const loading = isNil(data);
  const companyFunding = data?.getCompanyById?.funding;
  const companyName = data?.getCompanyById?.name;
  const investors = companyFunding?.investors || [];
  const filteredInvestors = investors?.filter(
    (investor) => investor?.name !== ''
  );
  const totalFundingRounds = companyFunding?.numFundingRounds || 'Unknown';
  const fundingRounds = companyFunding?.fundingRounds ?? [];
  const rbrExist = fundingRounds.length > 0;
  const noInvestorsExist =
    fundingRounds?.length === 0 && filteredInvestors?.length === 0;
  const [capTableData, setCapTableData] = useState<ICapTableEntry[]>();

  useEffect(() => {
    if (!showCapTable) return;

    getCapTableForCompanyId(companyId).then((resp) => {
      setCapTableData(resp);
    });
  }, [companyId, showCapTable]);

  const investorsFundingRoundMap = useMemo(
    () => getInvestorFundingRoundsMap(fundingRounds),
    [fundingRounds]
  );

  const renderNoInvestorsFound = () => {
    return (
      <div className="mt-6">
        <p className="text-content-title typography-title-medium">Investors</p>
        <p className="text-content-default typography-label">
          No investors found
        </p>
      </div>
    );
  };

  const renderInvestorsWithoutRbr = () => {
    return (
      <div data-testid="CompanyFinancing-Investors-Without-Rbr">
        <div>
          <p className="text-clickables-buttonHover typography-title-small">
            Investors
          </p>
        </div>
        <div className="mt-6 grid sm:grid-cols-2 gap-4">
          {filteredInvestors.map((investor) => {
            const investorName = investor?.name;
            const logoUrl = investorName
              ? investorsMap[investorName]?.logoUrl
              : '';
            const entityUrn =
              investor?.__typename === 'Company'
                ? investor?.companyUrn
                : investor?.personUrn;
            return (
              <InvestorCard
                key={investorName}
                investorName={investorName as string}
                logoUrl={logoUrl as string}
                entityUrn={entityUrn as string}
                dataTestId="CompanyFinancing-NoRbr-Investor"
              />
            );
          })}
        </div>
      </div>
    );
  };

  const renderInvestorsWithRbr = () => {
    if (!rbrExist) return null;
    return (
      <div
        data-testid="CompanyFinacing-Round-By-Round"
        className="flex flex-col gap-g90"
      >
        {fundingRounds.map((fundingRound, index) => (
          <CompanySingleRbr
            fundingRound={fundingRound}
            investorsFundingRoundMap={investorsFundingRoundMap}
            investorsMap={investorsMap}
            key={index}
          />
        ))}
      </div>
    );
  };

  const renderFundingCards = () => {
    return (
      <div className="grid sm:grid-cols-3 gap-4 py-p80">
        <FundingTotal companyId={companyId} />
        <MetricCard
          icon={BoxBarChart}
          value={totalFundingRounds}
          label="Funding rounds"
        />
        <MetricCard
          icon={BoxHandHoldingUsd}
          value={investors?.length}
          label="Investors"
        />
      </div>
    );
  };

  return (
    <div className="min-h-screen">
      <div className="flex justify-between py-p60">
        <p className="text-content-title typography-title-medium">Financing</p>
      </div>

      {loading ? (
        <CompanyFinancingContentLoader />
      ) : (
        <>
          <div className="w-full">
            {renderFundingCards()}

            {showCapTable && (
              <div className="py-p80">
                <div className="flex justify-between py-p60 border-b mb-g80 border-solid border-border">
                  <div className="text-content-title typography-title-small">
                    Cap table
                  </div>
                  <ReportDataIssue
                    reportParams={{
                      companyUrn: 'urn:company:harmonic:' + companyId,
                      reportSourceView: ReportSourceView.COMPANY,
                      reportSourceSection: ReportSourceSection.CAP_TABLE
                    }}
                    placeholderText="This company's captable is missing an entry - Series A-1 ..."
                  />
                </div>
                {capTableData && (
                  <CapTable companyId={companyId} capTableData={capTableData} />
                )}
                {showCapTableWaitlist && !capTableData && (
                  <CapTableWaitlistForm
                    companyId={companyId}
                    companyName={companyName}
                  />
                )}
                {showCapTableMock && !capTableData && (
                  <CapTable
                    companyId={companyId}
                    capTableData={getCompanyCapData.capTable}
                  />
                )}
              </div>
            )}

            <div className="flex justify-between py-p60 border-b mb-p80 border-solid border-border">
              <p className="text-content-title typography-title-small">
                Funding rounds
              </p>
              <ReportDataIssue
                reportParams={{
                  companyUrn: 'urn:company:harmonic:' + companyId,
                  reportSourceView: ReportSourceView.COMPANY,
                  reportSourceSection: ReportSourceSection.FINANCING
                }}
                placeholderText="This company shows 2 funding rounds, but there are actually 3"
              />
            </div>

            {noInvestorsExist && renderNoInvestorsFound()}

            {!noInvestorsExist && (
              <div>
                {rbrExist
                  ? renderInvestorsWithRbr()
                  : renderInvestorsWithoutRbr()}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CompanyFinancing;
