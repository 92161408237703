import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const NoteTextIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="#677183"
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.9889 13.5V10.8333C10.9889 10.4651 11.2874 10.1667 11.6556 10.1667H14.3223M6.6556 5.83333H10.9889M6.6556 8.5H8.32227M14.3223 10.3905V3.16667C14.3223 2.79848 14.0238 2.5 13.6556 2.5H3.98893C3.62074 2.5 3.32227 2.79848 3.32227 3.16667V12.8333C3.32227 13.2015 3.62074 13.5 3.98893 13.5H11.2128C11.3896 13.5 11.5592 13.4298 11.6842 13.3047L14.127 10.8619C14.252 10.7369 14.3223 10.5673 14.3223 10.3905Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NoteTextIcon;
