import { PaperPlaneIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import dayjs from 'dayjs';
import TruncatedList from 'harmonic-components/TruncatedList/TruncatedList';
import SingleParticipant, { EventParticipant } from './SingleParticipant';

const RoundedPaperPlaneIcon = () => (
  <div
    data-testid="RoundedPaperPlaneIcon"
    className="flex items-center p-p20 bg-surface-sentiment-neutral rounded-br20"
  >
    <PaperPlaneIcon />
  </div>
);

interface EmailMessageCardProps {
  name?: string | null;
  description?: string | null;
  startTime?: string | null;
  sender?: string | null;
  participants?: EventParticipant[];
}

const EmailMessageCard: React.FC<EmailMessageCardProps> = ({
  name,
  startTime,
  participants
}) => {
  const formattedTime = startTime
    ? dayjs(startTime).format('MM/DD/YYYY')
    : 'NA';

  return (
    <div className="border border-solid border-border rounded-br30 bg-surface-default">
      <div
        className={classNames(
          'flex items-center justify-between p-p40',
          name && 'border-b border-solid border-border'
        )}
      >
        <div className="flex mr-24 h-5 gap-g30">
          {!name && <RoundedPaperPlaneIcon />}
          <TruncatedList height={20} wrapperClassName="items-center">
            {participants?.map((participant) => (
              <SingleParticipant
                key={participant.emailAddress}
                {...participant}
              />
            ))}
          </TruncatedList>
        </div>
        <p className="text-content-weak typography-label-small-default">
          {formattedTime}
        </p>
      </div>
      {name && (
        <div data-testid="EmailSubject" className="flex flex-row gap-g30 p-p40">
          <RoundedPaperPlaneIcon />
          <div>
            <p className="text-content-default typography-label-default-default">
              {name}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmailMessageCard;
