import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const PersonIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      fill="#A63A74"
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M8.00016 1.33334C6.34331 1.33334 5.00016 2.67648 5.00016 4.33334C5.00016 5.99019 6.34331 7.33334 8.00016 7.33334C9.65702 7.33334 11.0002 5.99019 11.0002 4.33334C11.0002 2.67648 9.65702 1.33334 8.00016 1.33334Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M8.00043 8.33334C5.21823 8.33334 3.18246 10.1957 2.62696 12.6675C2.46054 13.408 3.06692 14 3.73271 14H12.2682C12.9339 14 13.5403 13.408 13.3739 12.6675C12.8184 10.1957 10.7826 8.33334 8.00043 8.33334Z"
      />
    </svg>
  );
};

export default PersonIcon;
