/* eslint-disable react/no-unescaped-entities */

import { CircularProgress } from '@material-ui/core';
import { logout, setUserStatus } from 'actions/authActions';
import { ReactComponent as HarmonicLogo } from 'assets/harmonic-text-logo-new.svg';
import classnames from 'classnames';
import Button from 'components/common/lib/Button';
import { LogoutReason, UserStatusType } from 'interfaces/Auth';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppState } from 'store';
import {
  GENERIC_ACTION_ERROR_MESSAGE,
  ONBOARDING_ROUTE
} from 'utils/constants';
import { acceptPilotAgreement, authenticate } from 'utils/midtierApi';
import { displayToast } from 'utils/toasts';
import AgreementText from './AgreementText';

const PilotContractAgreement: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector<AppState, AppState['auth']>((state) => state.auth);

  useEffect(() => {
    const handleScroll = () => {
      const div = divRef.current;
      if (!div) return;
      //If user scrolls through 80% of agreement, button is visible
      if (div.scrollTop >= div.scrollHeight * 0.8 - div.clientHeight) {
        setIsAtBottom(true);
      } else {
        setIsAtBottom(false);
      }
    };

    const div = divRef.current;
    div?.addEventListener('scroll', handleScroll);

    return () => {
      div?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const onAcceptAgreement = async () => {
    try {
      if (loading) return;
      setLoading(true);
      await acceptPilotAgreement();
      dispatch(setUserStatus(UserStatusType.ACTIVE));
      navigate(ONBOARDING_ROUTE);
    } catch (err) {
      displayToast({
        primaryText: GENERIC_ACTION_ERROR_MESSAGE,
        mode: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!auth.isAuthenticated) navigate('/login');
  }, [auth.isAuthenticated]);

  const verifyUserIsAPilotUser = async () => {
    try {
      const user = await authenticate();
      if (user.status !== UserStatusType.PENDING_PILOT_AGREEMENT_ACCEPTANCE) {
        dispatch(logout(LogoutReason.SessionExpired));
      }
    } catch (err) {
      dispatch(logout(LogoutReason.SessionExpired));
    }
  };

  useEffect(() => {
    verifyUserIsAPilotUser();
  }, []);
  return (
    <div className="font-beausite">
      <a href="https://harmonic.ai" className="head flex justify-center py-2">
        <HarmonicLogo className="w-52" />
      </a>
      <div
        ref={divRef}
        className={classnames(
          'rounded-lg border border-solid border-clickables-lightStroke max-w-px-750 mx-auto py-7 px-10',
          'overflow-scroll max-h-vh-80'
        )}
      >
        <AgreementText />
      </div>
      <div className="mt-5 flex justify-center">
        <Button
          onClick={onAcceptAgreement}
          disabled={!isAtBottom}
          fontSize="medium"
          height="default"
          width={10}
        >
          {loading ? (
            //eslint-disable-next-line
            //@ts-ignore
            <CircularProgress color="white" size={20} />
          ) : !isAtBottom ? (
            'Scroll to bottom to accept'
          ) : (
            'Accept agreement'
          )}
        </Button>
      </div>
    </div>
  );
};

export default PilotContractAgreement;
