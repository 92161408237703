import dropdownAnimationData from 'assets/dropdown-loader-animation-lottie.json';
import classNames from 'classnames';
import SearchInput from 'harmonic-components/SearchInput/SearchInput';
import Lottie from 'lottie-react';
import { PropsWithChildren, useEffect, useRef } from 'react';

const DropdownLoader: React.FC = () => {
  return (
    <div className="h-[204px] flex justify-center items-center w-full">
      <Lottie animationData={dropdownAnimationData} />
    </div>
  );
};

type DropdownProps = {
  filterPlaceholder?: string;
  filterTerm?: string;
  onFilterTermChange?: (value: string) => void;
  dataTestId?: string;
  loading?: boolean;
  inverted?: boolean;
  maxHeight?: string;
  scrollIntoView?: boolean;
};

const Dropdown: React.FC<PropsWithChildren<DropdownProps>> = ({
  filterTerm,
  onFilterTermChange,
  filterPlaceholder = 'Filter',
  children,
  dataTestId,
  loading,
  inverted,
  maxHeight = '500px',
  scrollIntoView = true
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Scroll into view if dropdown isn't fully visible
    if (scrollIntoView) {
      setTimeout(() => {
        if (dropdownRef?.current) {
          const elementRect = dropdownRef.current.getBoundingClientRect();
          if (
            elementRect.top < 0 ||
            elementRect.bottom > window.innerHeight ||
            elementRect.left < 0 ||
            elementRect.right > window.innerWidth
          ) {
            dropdownRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start'
            });
          }
        }
      }, 50);
    }
  }, [dropdownRef, scrollIntoView]);

  return (
    <div
      ref={dropdownRef}
      data-testid={dataTestId}
      style={{ maxHeight: maxHeight }}
      className={classNames(
        'flex flex-col px-p20 pb-p20 items-start self-stretch rounded-br40 border border-solid border-border',
        `shadow-static-elevation-floating overflow-y-auto`,
        {
          'bg-surface-default': !inverted,
          'bg-surface-inverted-raised': inverted,
          'pt-p20': !onFilterTermChange
        }
      )}
    >
      {onFilterTermChange && (
        <div
          data-testid="dropdown-filter"
          className={classNames(
            'w-full py-p20 sticky top-0 left-0 right-0 z-10',
            !inverted && 'bg-white',
            inverted && 'bg-surface-inverted-raised'
          )}
        >
          <SearchInput
            fullWidth={true}
            value={filterTerm ?? ''}
            onChange={onFilterTermChange}
            placeholder={filterPlaceholder}
            inverted={inverted}
          />
        </div>
      )}
      {loading ? <DropdownLoader /> : children}
    </div>
  );
};

export default Dropdown;
