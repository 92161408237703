import classNames from 'classnames';
import { IconProps } from './type';

const StarOutlineIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      stroke="#484E5B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M11.5498 2.03292C11.7307 1.65569 12.2693 1.65569 12.4503 2.03292L15.0189 7.38732C15.0918 7.53919 15.2367 7.64404 15.4041 7.66599L21.3154 8.44106C21.732 8.49569 21.8986 9.0078 21.5935 9.29588L17.2708 13.3775C17.1479 13.4935 17.0924 13.6637 17.1233 13.8297L18.2087 19.6603C18.2853 20.0717 17.8494 20.388 17.4803 20.1888L12.2376 17.3585C12.0894 17.2785 11.9106 17.2785 11.7624 17.3585L6.5197 20.1888C6.15064 20.388 5.71473 20.0717 5.79133 19.6603L6.87676 13.8297C6.90765 13.6637 6.85211 13.4935 6.72921 13.3775L2.40652 9.29588C2.10142 9.0078 2.26804 8.49569 2.68463 8.44106L8.59591 7.66599C8.76331 7.64404 8.90823 7.53919 8.98109 7.38732L11.5498 2.03292Z"
      />
    </svg>
  );
};

export default StarOutlineIcon;
