import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const NotificationBellFilledIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className,
  ...props
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
      fill="#7C8598"
      {...props}
    >
      <path
        vectorEffect="non-scaling-stroke"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.33325C5.42437 1.33325 3.29855 3.34645 3.16964 5.91401L3.03915 8.4975C3.03796 8.5212 3.03171 8.54438 3.02083 8.56547L2.12973 10.2937C2.04415 10.4597 2 10.644 2 10.8293C2 11.4698 2.5185 11.9999 3.16667 11.9999H4.87258C5.11231 13.5112 6.42123 14.6666 8 14.6666C9.57877 14.6666 10.8877 13.5112 11.1274 11.9999H12.8333C13.4815 11.9999 14 11.4698 14 10.8293C14 10.644 13.9559 10.4597 13.8703 10.2937L12.9792 8.56547C12.9683 8.54438 12.962 8.5212 12.9608 8.4975L12.8304 5.91418C12.7015 3.34662 10.5756 1.33325 8 1.33325ZM8 13.6666C6.97547 13.6666 6.11703 12.9555 5.89131 11.9999H10.1087C9.88297 12.9555 9.02453 13.6666 8 13.6666Z"
      />
    </svg>
  );
};

export default NotificationBellFilledIcon;
