import { Popover } from '@headlessui/react';
import { ChevronDown } from 'assets/harmonic-icons';
import { HarmonicIcon } from 'assets/harmonic-icons/type';
import classNames from 'classnames';
import Tag from 'harmonic-components/Tag/Tag';
import React, { useEffect, useRef } from 'react';
import { ColorShorthand } from 'utils/design';

interface MultiSelectProps {
  selected?: string[];
  open?: boolean;
  close: () => void;
  onRemove?: (value: string) => void;
  onAdd?: (value: string) => void;
  filterable?: boolean;
  filterTerm?: string;
  setFilterTerm?: (term: string) => void;
  getLabelFromValue?: (value?: string) => string;
  getTagIconFromValue?: (value?: string) => HarmonicIcon | undefined;
  getTagColorFromValue?: (value?: string) => ColorShorthand | undefined;
  onArrowDownPress: () => void;
  onArrowUpPress: () => void;
  onEnterPress: () => void;
  labelPrefix?: string;
  initialFocus?: boolean;
  dataTestId?: string;
  freeSolo?: boolean;
  placeholder?: string;
  ref: React.MutableRefObject<HTMLButtonElement>;
  hideChevronDown?: boolean;
  disabled?: boolean;
  minHeight?: number;
  borderOverrideClasses?: string;
  clampValues?: boolean;
}

export const MultiSelect = React.forwardRef<
  HTMLButtonElement,
  MultiSelectProps
>(
  (
    {
      selected,
      open,
      close,
      onRemove,
      onAdd,
      filterable,
      filterTerm,
      setFilterTerm,
      getLabelFromValue,
      getTagIconFromValue,
      getTagColorFromValue,
      onArrowDownPress,
      onArrowUpPress,
      onEnterPress,
      labelPrefix,
      dataTestId,
      freeSolo,
      placeholder,
      hideChevronDown,
      disabled,
      minHeight,
      clampValues,
      borderOverrideClasses
    },
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [prevOpenState, setPrevOpenState] = React.useState(open);
    const [tagIndexFocused, setTagIndexFocused] = React.useState<number | null>(
      null
    );
    const [inDeletionMode, setInDeletionMode] = React.useState(false);

    // When in freeSolo mode, add the filter term to the selected list when the dropdown closes
    useEffect(() => {
      const didClose = !open && prevOpenState;
      if (freeSolo && didClose && filterTerm) {
        onAdd?.(filterTerm);
      }
      setPrevOpenState(open);
    }, [filterTerm, freeSolo, onAdd, open, prevOpenState]);

    // When in freeSolo mode, add the filter term if it ends with a ', '
    useEffect(() => {
      if (freeSolo && filterTerm?.endsWith(', ')) {
        onAdd?.(filterTerm.slice(0, -2));
      }
    }, [filterTerm, freeSolo, onAdd, setFilterTerm]);

    // Reset deletion mode when the dropdown closes
    useEffect(() => {
      const didClose = !open && prevOpenState;
      if (didClose) {
        setTagIndexFocused(null);
      }
    }, [open, prevOpenState]);

    let selectedValues = selected;
    if (!open && clampValues) {
      selectedValues = selected?.slice(0, 1);
    }

    useEffect(() => {
      if (clampValues) {
        setTimeout(() => {
          inputRef.current?.scrollIntoView();
          inputRef.current?.focus();
        }, 0);
      }
    }, [selectedValues]);

    const showHiddenElementCount =
      !open && clampValues && (selected?.length ?? 0) > 1;
    return (
      <Popover.Button
        data-open={open}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          if (filterable) {
            if (tagIndexFocused !== null) {
              setTagIndexFocused(null);
            }
            inputRef.current?.focus();
            setTimeout(() => {
              inputRef.current?.select();
              inputRef.current?.scrollIntoView();
            }, 0);
          }
          if (open) {
            e.stopPropagation();
            e.preventDefault();
          }
        }}
        disabled={disabled}
        data-testid={dataTestId}
        ref={ref}
        className={classNames(
          // Base classes
          'w-full flex border-[1.5px] border-solid rounded-br30 bg-surface-default px-p50 py-p40',
          'typography-label-default-default text-input-value-default hover:text-input-value-hover cursor-pointer justify-between',
          !borderOverrideClasses &&
            classNames(
              'border-int-outline-secondary-enabled',
              // Hover state
              'hover:border-int-outline-secondary-hover hover:bg-int-overlay-secondary-hover',
              // Active state
              'active:border-int-outline-secondary-pressed',
              // Focus state
              'focus-visible:outline-none focus-visible:border-int-outline-secondary-selected-enabled',
              'data-[open=true]:outline-none data-[open=true]:border-int-outline-secondary-selected-enabled',
              // Disabled state
              'disabled:border-int-outline-secondary-disabled'
            ),
          // Hover state
          'hover:text-input-value-hover',
          // Active state
          'active:border-int-outline-secondary-pressed active:bg-int-overlay-secondary-pressed active:text-input-value-pressed',
          // Focus state
          'focus-visible:bg-surface-default',
          'data-[open=true]:bg-surface-default',
          // Disabled state
          'disabled:bg-int-overlay-secondary-disabled disabled:text-input-value-disabled',
          clampValues && 'max-h-[100px] overflow-y-auto border-box',
          {
            'caret-transparent': tagIndexFocused !== null,
            'items-center': !minHeight && !clampValues,
            'py-p50': minHeight
          },
          borderOverrideClasses
        )}
        style={{ minHeight }}
      >
        <div
          className={classNames(
            'flex flex-wrap gap-g20 items-center w-full',
            filterable && 'cursor-text',
            clampValues && 'truncate'
          )}
        >
          {labelPrefix && (
            <p className="typography-label-default-default text-content-weak">
              {labelPrefix}
            </p>
          )}
          {selectedValues?.map((selectedItem, index) => {
            return (
              <Tag
                onRemove={onRemove ? () => onRemove(selectedItem) : undefined}
                label={getLabelFromValue?.(selectedItem) || selectedItem}
                color={getTagColorFromValue?.(selectedItem) as ColorShorthand}
                leadingIcon={getTagIconFromValue?.(selectedItem)}
                key={selectedItem}
                intensity={
                  tagIndexFocused !== null && index === tagIndexFocused
                    ? 'highlight'
                    : 'subtle'
                }
                dataTestId="MultiSelect-Value"
                truncate
                size="compact"
              />
            );
          })}
          {showHiddenElementCount && (
            <Tag
              label={`+${(selected?.length as number) - 1}`}
              size="compact"
              intensity="subtle"
            />
          )}

          {(!selected || selected.length === 0) && !filterTerm && (
            <p className="typography-label-default-default text-content-weak absolute">
              {placeholder}
            </p>
          )}
          {filterable && (
            <input
              data-testid={`${dataTestId}-input`}
              ref={inputRef}
              className={classNames(
                'outline-none bg-inherit',
                !open && !filterTerm && 'w-0'
              )}
              value={filterTerm}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => {
                setFilterTerm?.(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.key === 'Backspace') {
                  if (inDeletionMode) {
                    setInDeletionMode(false);
                  }
                }
              }}
              onKeyDownCapture={(e) => {
                // Shift tagIndexInDeletionMode to the previous tag if the left arrow key is pressed
                if (e.key === 'ArrowLeft' && selected) {
                  if (tagIndexFocused !== null) {
                    setTagIndexFocused((prev) => {
                      if (prev === null) {
                        return selected.length - 1;
                      }
                      if (prev === 0) {
                        return prev;
                      }
                      return prev - 1;
                    });
                  } else {
                    setTagIndexFocused(selected.length - 1);
                  }
                  return;
                }

                // Shift tagIndexInDeletionMode to the next tag if the right arrow key is pressed, or set to null if the last tag is reached
                if (
                  e.key === 'ArrowRight' &&
                  tagIndexFocused !== null &&
                  selected
                ) {
                  setTagIndexFocused((prev) => {
                    if (prev === null) {
                      return null;
                    }
                    if (prev === selected.length - 1) {
                      return null;
                    }
                    return prev + 1;
                  });
                  return;
                }

                if (e.key === 'ArrowDown') {
                  onArrowDownPress();
                }

                if (e.key === 'ArrowUp') {
                  onArrowUpPress();
                }

                // Call onRemove on the last tag when the backspace key is pressed and the filter term is empty
                if (
                  e.key === 'Backspace' &&
                  filterTerm === '' &&
                  selected &&
                  selected.length > 0
                ) {
                  if (tagIndexFocused !== null && !inDeletionMode) {
                    const isLastSelected =
                      tagIndexFocused === selected.length - 1;
                    onRemove?.(selected[tagIndexFocused]);
                    setTagIndexFocused((prev) =>
                      !isLastSelected ? prev : null
                    );
                  } else {
                    setTagIndexFocused(selected.length - 1);
                    setInDeletionMode(true);
                  }
                  return;
                }

                // Enter key should add the filter term to the selected list when in freeSolo mode
                if (e.key === 'Enter') {
                  onEnterPress();
                  return;
                }

                // All other keys should remove the deletion mode
                if (tagIndexFocused !== null) {
                  setTagIndexFocused(null);
                }
              }}
            />
          )}
        </div>

        {!hideChevronDown && (
          <div onClick={close} className="w-4 flex-shrink-0">
            <ChevronDown width={16} />
          </div>
        )}
      </Popover.Button>
    );
  }
);
