import classNames from 'classnames';
import { IconProps } from './type';

const HourglassIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#225DD3"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        vectorEffect="non-scaling-stroke"
        d="M3.33333 2.33325H2.5C2.22386 2.33325 2 2.10939 2 1.83325C2 1.55711 2.22386 1.33325 2.5 1.33325H13.5C13.7761 1.33325 14 1.55711 14 1.83325C14 2.10939 13.7761 2.33325 13.5 2.33325H12.6667V4.96841C12.6667 5.36622 12.464 5.73662 12.1289 5.95106L8.92755 7.99992L12.1289 10.0488C12.464 10.2632 12.6667 10.6336 12.6667 11.0314V13.6666H13.5C13.7761 13.6666 14 13.8904 14 14.1666C14 14.4427 13.7761 14.6666 13.5 14.6666H2.5C2.22386 14.6666 2 14.4427 2 14.1666C2 13.8904 2.22386 13.6666 2.5 13.6666H3.33333V11.0314C3.33333 10.6336 3.53604 10.2632 3.8711 10.0488L7.07245 7.99992L3.8711 5.95106C3.53604 5.73662 3.33333 5.36622 3.33333 4.96841V2.33325ZM4.33333 4.66659H11.6667V2.33325H4.33333V4.66659ZM11.6667 11.0314V11.9999H4.33333V11.0314C4.33333 10.9746 4.36229 10.9217 4.41016 10.8911L8 8.59355L11.5898 10.8911C11.6377 10.9217 11.6667 10.9746 11.6667 11.0314Z"
      />
    </svg>
  );
};

export default HourglassIcon;
