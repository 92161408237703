import { useQuery } from '@apollo/client';
import {
  GetCompaniesNamesQuery,
  GetCompaniesNamesQueryVariables
} from '__generated__/graphql';
import { compact } from 'lodash';
import { getCompaniesNames } from 'queries/getCompaniesNames';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  getCompanyLineageRelationshipsByChild,
  getCompanyLineageRelationshipsByParent
} from 'utils/hasuraApi';

interface CompanyLineageProps {
  companyId: number;
}

interface CompanyLineageRelationship {
  created_at: string;
  updated_at: string;
  id: string;
  parent_company_id: number;
  child_company_id: number;
  relationship_type: string;
  is_surfacable: boolean;
  acquisition_date: string;
  acquisition_amount: number;
  is_merged_after_acquisition: boolean;
}

/**
 * !!!!!
 * This component is a PLACEHOLDER - meant only to be
 * exposed to a small set of users behind a feature flag
 * and only to be used for internal QA
 * !!!!!
 */
const CompanyLineage = (props: CompanyLineageProps) => {
  const { companyId } = props;
  const [companyLineageByParentData, setCompanyLineageByParentData] = useState<
    CompanyLineageRelationship[]
  >([]);
  const [companyLineageByChildData, setCompanyLineageByChildData] = useState<
    CompanyLineageRelationship[]
  >([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCompanyLineage = async () => {
      const companyLineageByParentData =
        await getCompanyLineageRelationshipsByParent(companyId);
      setCompanyLineageByParentData(companyLineageByParentData);
      const companyLineageByChildData =
        await getCompanyLineageRelationshipsByChild(companyId);
      setCompanyLineageByChildData(companyLineageByChildData);

      setLoading(false);
    };

    fetchCompanyLineage();
  }, [companyId]);

  const { data: companyNameData, loading: companyNameDataLoading } = useQuery<
    GetCompaniesNamesQuery,
    GetCompaniesNamesQueryVariables
  >(getCompaniesNames, {
    variables: {
      ids: compact(
        companyLineageByParentData
          .map((relationship) => relationship.child_company_id)
          .concat(
            companyLineageByChildData.map(
              (relationship) => relationship.parent_company_id
            )
          )
      )
    },
    skip: loading,
    fetchPolicy: 'network-only'
  });

  const subsidiaryOfRelationships = companyLineageByChildData.filter(
    (relationship) => relationship.relationship_type === 'Subsidiary'
  );

  const acquisitionByRelationships = companyLineageByChildData.filter(
    (relationship) => relationship.relationship_type === 'Acquisition'
  );

  const subsidiaryRelationships = companyLineageByParentData.filter(
    (relationship) => relationship.relationship_type === 'Subsidiary'
  );

  const acquisitionRelationships = companyLineageByParentData.filter(
    (relationship) => relationship.relationship_type === 'Acquisition'
  );

  if (loading || companyNameDataLoading) {
    return <div>Loading...</div>;
  }

  const Subsidiaries = (props: {
    relationships: CompanyLineageRelationship[];
    showChildOrParent: 'child' | 'parent';
  }) => {
    const { relationships, showChildOrParent } = props;
    return (
      <table className="gap-4">
        <thead>
          <tr>
            <th className="px-4 py-2 text-left font-bold">Company</th>
            <th className="px-4 py-2 text-left font-bold">Company ID</th>
            <th className="px-4 py-2 text-left font-bold">Is Surfacable</th>
          </tr>
        </thead>
        <tbody>
          {relationships.map((relationship) => {
            const companyId =
              showChildOrParent == 'child'
                ? relationship.child_company_id
                : relationship.parent_company_id;
            return (
              <tr key={relationship.id}>
                <td className="border px-4 py-2">
                  <Link
                    to={`/dashboard/company/${companyId}`}
                    className="text-blue-500 underline"
                  >
                    {
                      companyNameData?.getCompaniesByIds?.find(
                        (company) => company?.id === companyId
                      )?.name
                    }
                  </Link>
                </td>
                <td className="border px-4 py-2">{companyId}</td>
                <td className="border px-4 py-2">
                  {relationship.is_surfacable ? 'Yes' : 'No'}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const Acquisitions = (props: {
    relationships: CompanyLineageRelationship[];
    showChildOrParent: 'child' | 'parent';
  }) => {
    const { relationships, showChildOrParent } = props;
    return (
      <table className="gap-4">
        <thead>
          <tr>
            <th className="px-4 py-2 text-left font-bold">Company</th>
            <th className="px-4 py-2 text-left font-bold">Company ID</th>
            <th className="px-4 py-2 text-left font-bold">Acquisition Date</th>
            <th className="px-4 py-2 text-left font-bold">
              Acquisition Amount
            </th>
            <th className="px-4 py-2 text-left font-bold">
              Is Merged After Acquisition
            </th>
            <th className="px-4 py-2 text-left font-bold">Is Surfacable</th>
          </tr>
        </thead>
        <tbody>
          {relationships.map((relationship) => {
            const companyId =
              showChildOrParent == 'child'
                ? relationship.child_company_id
                : relationship.parent_company_id;
            return (
              <tr key={relationship.id}>
                <td className="border px-4 py-2">
                  <Link
                    to={`/dashboard/company/${companyId}`}
                    className="text-blue-500 underline"
                  >
                    {
                      companyNameData?.getCompaniesByIds?.find(
                        (company) => company?.id === companyId
                      )?.name
                    }
                  </Link>
                </td>
                <td className="border px-4 py-2">{companyId}</td>
                <td className="border px-4 py-2">
                  {relationship.acquisition_date}
                </td>
                <td className="border px-4 py-2">
                  {relationship.acquisition_amount}
                </td>
                <td className="border px-4 py-2">
                  {relationship.is_merged_after_acquisition ? 'Yes' : 'No'}
                </td>
                <td className="border px-4 py-2">
                  {relationship.is_surfacable ? 'Yes' : 'No'}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <div className="flex flex-col gap-8">
      <div>
        <h2 className="mb-4 text-xl font-bold">Subsidiary of</h2>
        {subsidiaryOfRelationships.length > 0 ? (
          <Subsidiaries
            relationships={subsidiaryOfRelationships}
            showChildOrParent="parent"
          />
        ) : (
          <div className="mx-4">No parents to show</div>
        )}
      </div>
      <div>
        <h2 className="mb-4 text-xl font-bold">Acquired by</h2>
        {acquisitionByRelationships.length > 0 ? (
          <Acquisitions
            relationships={acquisitionByRelationships}
            showChildOrParent="parent"
          />
        ) : (
          <div className="mx-4">No acquirers to show</div>
        )}
      </div>
      <div>
        <h2 className="mb-4 text-xl font-bold">Subsidiaries</h2>
        {subsidiaryRelationships.length > 0 ? (
          <Subsidiaries
            relationships={subsidiaryRelationships}
            showChildOrParent="child"
          />
        ) : (
          <div className="mx-4">No subsidiaries to show</div>
        )}
      </div>
      <div>
        <h2 className="mb-4 text-xl font-bold">Acquisitions</h2>
        {acquisitionRelationships.length > 0 ? (
          <Acquisitions
            relationships={acquisitionRelationships}
            showChildOrParent="child"
          />
        ) : (
          <div className="mx-4">No acquisitions to show</div>
        )}
      </div>
    </div>
  );
};

export default CompanyLineage;
