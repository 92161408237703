import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const SavedSearchIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#484E5B"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.71799 2.59202C1.5 3.01984 1.5 3.5799 1.5 4.7V11.3C1.5 12.4201 1.5 12.9802 1.71799 13.408C1.90973 13.7843 2.21569 14.0903 2.59202 14.282C3.01984 14.5 3.5799 14.5 4.7 14.5H11.3C12.4201 14.5 12.9802 14.5 13.408 14.282C13.7843 14.0903 14.0903 13.7843 14.282 13.408C14.5 12.9802 14.5 12.4201 14.5 11.3V4.7C14.5 3.5799 14.5 3.01984 14.282 2.59202C14.0903 2.21569 13.7843 1.90973 13.408 1.71799C12.9802 1.5 12.4201 1.5 11.3 1.5H4.7C3.5799 1.5 3.01984 1.5 2.59202 1.71799C2.21569 1.90973 1.90973 2.21569 1.71799 2.59202ZM4.75 7.24475C4.75 5.86694 5.86694 4.75 7.24475 4.75C8.62257 4.75 9.73951 5.86694 9.73951 7.24475C9.73951 7.93387 9.46109 8.55653 9.00881 9.00881C8.55653 9.46109 7.93387 9.73951 7.24475 9.73951C5.86694 9.73951 4.75 8.62257 4.75 7.24475ZM7.24475 3.25C5.03851 3.25 3.25 5.03851 3.25 7.24475C3.25 9.451 5.03851 11.2395 7.24475 11.2395C8.0765 11.2395 8.84956 10.9847 9.48912 10.5498L11.4697 12.5303C11.7626 12.8232 12.2374 12.8232 12.5303 12.5303C12.8232 12.2374 12.8232 11.7626 12.5303 11.4697L10.5498 9.48912C10.9847 8.84956 11.2395 8.0765 11.2395 7.24475C11.2395 5.03851 9.451 3.25 7.24475 3.25Z"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default SavedSearchIcon;
