import { PlusIcon } from 'assets/harmonic-icons';
import Button from 'harmonic-components/Button/Button';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { MouseEvent, useState } from 'react';
import TogglePersonListsDropdown from './EntityActions/TogglePersonListDropdown';

type AddToWatchlistButtonType = 'icon' | 'button' | 'inline';
const AddPeopleToWatchlist = ({
  type = 'icon',
  personIds
}: {
  type?: AddToWatchlistButtonType;
  personIds: number[];
}) => {
  const [watchlistActionsPopoverAnchorEl, setWatchlistActionsPopoverAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const WatchlistActionsPopoverOpen = Boolean(watchlistActionsPopoverAnchorEl);

  const handleWatchlistActionsPopoverClose = () => {
    setWatchlistActionsPopoverAnchorEl(null);
  };

  const handleWatchlistActionPopoverOpen = (
    event: MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setWatchlistActionsPopoverAnchorEl(event.currentTarget);
  };
  return (
    <>
      {type === 'button' && (
        <Button
          leadingIcon={PlusIcon}
          onClick={handleWatchlistActionPopoverOpen}
          type="primary"
          emphasis="high"
          label="Add to ..."
          size="default"
          dataTestId="AddPeopleToWatchlist-Btn"
        />
      )}
      {type === 'icon' && (
        <div>
          <IconButton
            icon={PlusIcon}
            size="compact"
            emphasis="high"
            onClick={handleWatchlistActionPopoverOpen}
            dataTestId="AddPeopleToWatchlist-Btn"
          />
        </div>
      )}
      {type === 'inline' && (
        <IconButton
          icon={PlusIcon}
          onClick={handleWatchlistActionPopoverOpen}
          type="inline"
          dataTestId="AddPeopleToWatchlist-Btn"
        />
      )}

      <TogglePersonListsDropdown
        open={WatchlistActionsPopoverOpen}
        anchorEl={watchlistActionsPopoverAnchorEl}
        onClose={handleWatchlistActionsPopoverClose}
        personIds={personIds}
      />
    </>
  );
};

export default AddPeopleToWatchlist;
