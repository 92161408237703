import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const UsersFilledIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className,
  ...props
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
      fill="#7C8598"
      {...props}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M2.33301 4.66667C2.33301 3.19391 3.52692 2 4.99967 2C6.47243 2 7.66634 3.19391 7.66634 4.66667C7.66634 6.13943 6.47243 7.33333 4.99967 7.33333C3.52692 7.33333 2.33301 6.13943 2.33301 4.66667Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.6381 12.5516C10.3795 10.9236 9.71032 9.59771 8.78874 8.65041C9.47859 8.22076 10.2544 8 11.0452 8C13.2072 8 15.258 9.65033 15.7438 12.7085C15.8571 13.4218 15.2809 14 14.6156 14H10.3453C10.6017 13.5985 10.7253 13.1004 10.6381 12.5516Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.9997 2C9.52692 2 8.33301 3.19391 8.33301 4.66667C8.33301 6.13943 9.52692 7.33333 10.9997 7.33333C12.4724 7.33333 13.6663 6.13943 13.6663 4.66667C13.6663 3.19391 12.4724 2 10.9997 2Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M4.95217 8C7.11422 8 9.16497 9.65033 9.65077 12.7085C9.76408 13.4218 9.18784 14 8.52257 14H1.38177C0.716513 14 0.140266 13.4218 0.253574 12.7085C0.739379 9.65033 2.79013 8 4.95217 8Z"
      />
    </svg>
  );
};

export default UsersFilledIcon;
