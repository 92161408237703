import { CrossIcon, SearchIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import { useEffect, useRef } from 'react';

interface SearchInputProps {
  value: string;
  onChange: (newValue: string) => void;
  placeholder?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  inverted?: boolean;
  onCancel?: () => void;
  dataTestId?: string;
  variant?: 'compact' | 'default';
}
const SearchInput: React.FC<SearchInputProps> = ({
  value,
  onChange,
  placeholder,
  disabled,
  fullWidth = false,
  inverted,
  onCancel,
  variant = 'default'
}) => {
  const inputEl = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (!inputEl.current) {
      return;
    }

    inputEl.current.focus();
  }, []);

  return (
    <div
      className={classNames('relative flex items-center', {
        'w-full': fullWidth
      })}
    >
      <input
        ref={inputEl}
        data-testid="search-input"
        style={{
          paddingLeft: `calc(var(--spacing-p40) + 16px + var(--gap-g40))`,
          paddingRight: `calc(var(--spacing-p40) + 16px + var(--gap-g40))`
        }}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={classNames(
          'border-[1.5px] focus:border-[2px] border-solid rounded-br30 typography-label',
          variant === 'default' && 'py-p30',
          variant === 'compact' && 'py-p20',
          fullWidth && 'w-full',
          !disabled &&
            !inverted &&
            classNames(
              'border-int-outline-secondary-enabled text-input-value-default',
              'hover:bg-int-overlay-secondary-hover hover:border-int-outline-secondary-hover hover:text-input-value-hover hover:placeholder-input-placeholder-hover',
              'active:bg-int-overlay-secondary-pressed active:text-input-value-pressed active:placeholder-input-placeholder-pressed',
              'focus:bg-int-overlay-secondary-enabled focus:text-input-value-pressed focus:border-int-outline-secondary-selected-enabled focus:placeholder-input-placeholder-active'
            ),
          !disabled &&
            inverted &&
            classNames(
              'bg-surface-inverted-raised border-int-outline-inverted-enabled text-content-inverted-strong',
              'hover:bg-int-overlay-secondary-hover hover:border-int-outline-secondary-hover hover:text-content-inverted-strong hover:placeholder-input-placeholder-hover',
              'active:bg-int-overlay-secondary-pressed active:text-content-inverted-strong active:placeholder-input-placeholder-pressed',
              'focus:bg-int-overlay-secondary-enabled focus:text-content-inverted-strong focus:border-int-outline-secondary-selected-enabled focus:placeholder-input-placeholder-active'
            ),

          disabled &&
            classNames(
              'border-int-outline-secondary-disabled placeholder-input-placeholder-disabled text-input-value-disabled'
            )
        )}
      />
      <div className="absolute top-0 bottom-0 pl-p40 flex items-center pointer-events-none">
        <SearchIcon
          className={classNames(
            'w-4 h-4',
            inverted && 'text-content-inverted-muted'
          )}
        />
      </div>
      {(value || onCancel) && (
        <button
          disabled={disabled}
          onClick={onCancel ? onCancel : () => onChange('')}
          className={classNames(
            'absolute top-0 bottom-0 right-0 pr-p40 flex items-center',
            inverted && 'text-content-inverted-default'
          )}
        >
          <CrossIcon className="w-4 h-4" />
        </button>
      )}
    </div>
  );
};

export default SearchInput;
