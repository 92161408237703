import { useCallback, useEffect, useState } from 'react';

export const useIntersectionObserver = ({
  contentRef
}: {
  contentRef: React.RefObject<HTMLDivElement>;
}) => {
  const [visibilityMap, setVisibilityMap] = useState<{
    [key: string]: boolean;
  }>({});

  const handleIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const updatedEntries: Map<string, boolean> = new Map();
      entries.forEach((entry) => {
        if (entry.target instanceof HTMLElement) {
          const targetid = entry.target.dataset.targetid;
          if (targetid)
            if (entry.isIntersecting) {
              updatedEntries.set(targetid || '', true);
            } else {
              updatedEntries.set(targetid || '', false);
            }
        }
      });
      setVisibilityMap((prev) => ({
        ...prev,
        ...Object.fromEntries(updatedEntries)
      }));
    },
    []
  );

  // Attach observers for each chip. They will be used to deteremine which ones are intersecting with
  // the contentRef. These are the "unclamped" chips.
  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: contentRef.current,
      threshold: 0
    });
    if (contentRef?.current?.children) {
      Array.from(contentRef.current.children).forEach((item) => {
        if (item instanceof HTMLElement && item.dataset.targetid) {
          observer.observe(item);
        }
      });
    }
    return () => observer.disconnect();
  }, [contentRef]);

  return { visibilityMap };
};
