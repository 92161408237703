import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const ApiConnectionIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className,
  ...props
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
      fill="#7C8598"
      {...props}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M3.00033 8C3.00033 5.23858 5.2389 3 8.00033 3C10.1195 3 11.932 4.31859 12.6596 6.18189C12.7601 6.43911 13.05 6.5662 13.3073 6.46574C13.5645 6.36529 13.6916 6.07534 13.5911 5.81811C12.7187 3.58405 10.5451 2 8.00033 2C4.85499 2 2.27494 4.42023 2.02086 7.5H1.16699C0.89085 7.5 0.666992 7.72386 0.666992 8C0.666992 8.27614 0.89085 8.5 1.16699 8.5H2.02086C2.27494 11.5798 4.85499 14 8.00033 14C10.5451 14 12.7187 12.416 13.5911 10.1819C13.6916 9.92467 13.5645 9.63471 13.3073 9.53426C13.05 9.43381 12.7601 9.56089 12.6596 9.81812C11.932 11.6814 10.1195 13 8.00033 13C5.2389 13 3.00033 10.7614 3.00033 8Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M8.00033 4C5.79119 4 4.00033 5.79086 4.00033 8C4.00033 10.2091 5.79119 12 8.00033 12C10.0401 12 11.7233 10.4732 11.9694 8.5H14.8337C15.1098 8.5 15.3337 8.27614 15.3337 8C15.3337 7.72386 15.1098 7.5 14.8337 7.5H11.9694C11.7233 5.52684 10.0401 4 8.00033 4Z"
      />
    </svg>
  );
};

export default ApiConnectionIcon;
