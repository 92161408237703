import classNames from 'classnames';
import React from 'react';
import {
  ColorIntensity,
  ColorShorthand,
  getBackgroundColorClassFromShorthandAndIntensity,
  getContentColorClassFromShorthandAndIntensity,
  getIconColorClassFromShorthandAndIntensity
} from 'utils/design';

import { ReactComponent as CloseIcon } from 'assets/close.svg';
import { HarmonicIcon } from 'assets/harmonic-icons/type';

interface TagProps {
  label: string;
  leadingIcon?: HarmonicIcon;
  size?: 'comfortable' | 'compact';
  color?: ColorShorthand;
  intensity?: ColorIntensity;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onRemove?: () => void;
  disabled?: boolean;
  truncate?: boolean;
  dataTestId?: string;
}

const Tag: React.FC<TagProps> = ({
  size = 'comfortable',
  label,
  leadingIcon,
  color = 'neutral',
  intensity = 'subtle',
  onClick,
  onRemove,
  truncate,
  dataTestId
}) => {
  const LeadingIcon = leadingIcon;
  const backgroundColorClass = getBackgroundColorClassFromShorthandAndIntensity(
    color,
    intensity
  );
  const contentColorClass = getContentColorClassFromShorthandAndIntensity(
    color,
    intensity
  );
  const iconColorClass = getIconColorClassFromShorthandAndIntensity(
    color,
    intensity
  );

  return (
    <div
      onClick={onClick}
      data-testid={dataTestId}
      className={classNames(
        'inline-flex rounded-br20 gap-g10 items-center justify-center',
        truncate && 'truncate',
        {
          'py-p10 px-p30 typography-label-default-default':
            size == 'comfortable',
          'py-p00 px-p20 typography-label-default-default': size == 'compact'
        },
        onClick ? 'cursor-pointer' : 'cursor-default', //currently cursor pointer gets passed down from parent anyways so this is redundant
        onRemove && 'pr-p10',
        backgroundColorClass,
        contentColorClass
      )}
    >
      {LeadingIcon && (
        <div className={iconColorClass}>
          <LeadingIcon className="w-4 h-4" />
        </div>
      )}
      <p
        className={classNames(
          !truncate && 'line-clamp-1 whitespace-pre',
          truncate && 'truncate'
        )}
      >
        {label}
      </p>
      {onRemove && (
        <button
          className={classNames(
            {
              'w-5 h-5': size == 'comfortable',
              'w-4 h-4': size == 'compact'
            },
            'flex items-center justify-center',
            'hover:bg-int-overlay-secondary-hover rounded-br20',
            'focus-visible:shadow-int-focus-outside',
            iconColorClass
          )}
          data-testid="tag-remove-icon"
          onClick={(e) => {
            e.stopPropagation();
            onRemove();
          }}
        >
          <CloseIcon className="fill-current" />
        </button>
      )}
    </div>
  );
};

export default Tag;
