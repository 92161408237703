import { get } from 'lodash';
import React from 'react';
import Tag from '../../../../../harmonic-components/Tag/Tag';
import TruncatedList from '../../../../../harmonic-components/TruncatedList/TruncatedList';
import {
  ColorShorthand,
  stringToExpressiveNumber
} from '../../../../../utils/design';

export const Tags: React.FC<{
  value: string[];
}> = (props) => {
  const tags: string[] = get(props, 'value');

  if (!tags || tags.length === 0) return null;

  return (
    <div className="inline-block">
      <TruncatedList height={56}>
        {tags.map((tag, index) => {
          return (
            <div key={index}>
              <Tag
                label={tag}
                color={
                  `expressive-${stringToExpressiveNumber(
                    tag
                  )}` as ColorShorthand
                }
              />
            </div>
          );
        })}
      </TruncatedList>
    </div>
  );
};
