import { ReactComponent as EmailUnlockImage } from 'assets/email-unlock.svg';
import Email from 'harmonic-components/Email/Email';
import ModalTitle from 'harmonic-components/ModalTitle/ModalTitle';
import React from 'react';

interface EmailListProps {
  email: string;
}

const EmailList: React.FC<EmailListProps> = ({ email }) => {
  return (
    <div>
      <p className="flex justify-center typography-label-default-strong pt-p50">
        Click to copy the email address
      </p>
      <div
        data-testid="EmailList"
        className="flex flex-row items-start justify-center p-4"
      >
        <div className="flex flex-col pl-2 gap-g20">
          <div
            className="flex flex-col items-center justify-center"
            key={email}
          >
            <Email key={email} email={email} />
          </div>
        </div>
      </div>
    </div>
  );
};

interface PersonEmailStaticModalProps {
  name: string;
  emailAddress: string;
}

const PersonEmailStaticModal: React.FC<PersonEmailStaticModalProps> = ({
  name,
  emailAddress
}) => {
  return (
    <div className="w-px-480">
      {/* HEADER */}
      <ModalTitle title={`${name}'s email address`} />

      {/* BODY */}
      <div className="px-p50 py-p80">
        <div className="flex w-full justify-center">
          <EmailUnlockImage />
        </div>
        <EmailList email={emailAddress} />
      </div>
    </div>
  );
};

export default PersonEmailStaticModal;
