import classNames from 'classnames';
import { IconProps } from './type';

const TriangleExclamationMarkIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#DD2619"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        vectorEffect="non-scaling-stroke"
        d="M6.99345 1.89612C7.44409 1.12675 8.55622 1.12675 9.00685 1.89612L14.6771 11.577C15.1326 12.3548 14.5718 13.3333 13.6704 13.3333H2.3299C1.42855 13.3333 0.86766 12.3548 1.3232 11.577L6.99345 1.89612ZM8.00001 6.00008C8.27615 6.00008 8.50001 6.22394 8.50001 6.50008V8.83341C8.50001 9.10956 8.27615 9.33341 8.00001 9.33341C7.72387 9.33341 7.50001 9.10956 7.50001 8.83341V6.50008C7.50001 6.22394 7.72387 6.00008 8.00001 6.00008ZM8.66668 10.5001C8.66668 10.8683 8.3682 11.1667 8.00001 11.1667C7.63182 11.1667 7.33334 10.8683 7.33334 10.5001C7.33334 10.1319 7.63182 9.83341 8.00001 9.83341C8.3682 9.83341 8.66668 10.1319 8.66668 10.5001Z"
      />
    </svg>
  );
};

export default TriangleExclamationMarkIcon;
