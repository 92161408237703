import { useLocation } from 'react-router-dom';
import { usePrevious } from './usePrevious';

interface HasChangedRouteProps {
  excludeSearchParams?: boolean;
}

export const useHasChangedRoute = ({
  excludeSearchParams = false
}: HasChangedRouteProps) => {
  const location = useLocation();
  const previousLocation = usePrevious(location);

  const hasChangedPathname =
    (previousLocation && location.pathname !== previousLocation.pathname) ||
    false;

  if (!excludeSearchParams) {
    return (
      (previousLocation &&
        (hasChangedPathname || location.search !== previousLocation.search)) ||
      false
    );
  }

  return hasChangedPathname;
};
