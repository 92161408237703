import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const PersonCircleIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className,
  ...props
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
      fill="#484E5B"
      {...props}
    >
      <path
        vectorEffect="non-scaling-stroke"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99967 1.33325C4.31778 1.33325 1.33301 4.31802 1.33301 7.99992C1.33301 9.93127 2.15429 11.6708 3.46666 12.8883C4.65617 13.9919 6.24913 14.6666 7.99967 14.6666C9.75022 14.6666 11.3432 13.9919 12.5327 12.8883C13.8451 11.6708 14.6663 9.93127 14.6663 7.99992C14.6663 4.31802 11.6816 1.33325 7.99967 1.33325ZM3.98371 11.9978C4.8658 10.7826 6.27377 9.99992 7.99967 9.99992C9.72558 9.99992 11.1335 10.7826 12.0156 11.9978C10.9895 13.0286 9.56908 13.6666 7.99967 13.6666C6.43027 13.6666 5.00988 13.0286 3.98371 11.9978ZM9.99967 6.66659C9.99967 7.77116 9.10424 8.66659 7.99967 8.66659C6.8951 8.66659 5.99967 7.77116 5.99967 6.66659C5.99967 5.56202 6.8951 4.66659 7.99967 4.66659C9.10424 4.66659 9.99967 5.56202 9.99967 6.66659Z"
      />
    </svg>
  );
};

export default PersonCircleIcon;
