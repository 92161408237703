import {
  FilterComparator,
  FilterStringComparator,
  INITIAL_SEARCH_MODEL,
  ISearchFilterGroup,
  ISearchModel,
  ISearchSort
} from 'interfaces/SearchModel/Search';
import {
  cloneDeep,
  difference,
  find,
  findIndex,
  isArray,
  isNil,
  isString,
  union
} from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { logger } from './logger';
import { transformSearchQueryWithValidSortField } from './midtierApi';
import { deepOmitFromObject, snakelize } from './utilities';

// Remove invalid values from a filter group
export const removeInvalidValuesFromAFilterGroup = (
  filterGroup: ISearchFilterGroup | undefined | null
): ISearchFilterGroup => {
  if (filterGroup === undefined || filterGroup === null) {
    logger.error(
      'FilterGroup is undefined in removeInvalidValuesFromAFilterGroup'
    );
    //eslint-disable-next-line
    //@ts-ignore
    return;
  }
  filterGroup.filters =
    filterGroup.filters?.filter((f) => {
      const isEmptyComparators = [
        FilterStringComparator.IS_EMPTY,
        FilterStringComparator.IS_NOT_EMPTY
      ].includes(f?.comparator as FilterStringComparator);
      if (f?.filter_value === '' && !isEmptyComparators) return false;
      if (isNil(f?.filter_value) && !isEmptyComparators) return false;
      if (Array.isArray(f?.filter_value) && f?.filter_value.length === 0)
        return false;
      // Filter out invalid range filters. If lower range is missing, filter will look like "~rightValue"
      // If upper range is missing, filter will look like "leftValue~"
      if (
        (f?.comparator === 'inRange' || f?.comparator === 'inDaysAgoRange') &&
        isString(f?.filter_value) &&
        (f?.filter_value?.[0] === '~' ||
          f?.filter_value?.[f?.filter_value?.length - 1] === '~')
      )
        return false;
      return true;
    }) || [];

  filterGroup.filter_groups = filterGroup.filter_groups?.map((filter_group) =>
    removeInvalidValuesFromAFilterGroup(filter_group)
  );

  filterGroup.filter_groups = filterGroup.filter_groups?.filter(
    (group) => group.filters.length > 0 || group.filter_groups.length > 0
  );
  return filterGroup;
};

export const hasNoActiveFilters = (
  filterGroup: ISearchFilterGroup
): boolean => {
  return (
    filterGroup.filters.length === 0 &&
    filterGroup.filter_groups.every((group) => hasNoActiveFilters(group))
  );
};

// Transform function for our new beta search filter
// It removes uuid throughout object
export const transformSearchModelForApiv2 = (
  searchModel: ISearchModel
): ISearchModel => {
  // Remove uuid,key,name and noOperation throughout all nested object
  const rootFilterGroup = deepOmitFromObject(
    searchModel.filter_group as unknown as Record<string, unknown>,
    ['uuid', 'key', 'name', 'noOperation']
  ) as unknown as ISearchFilterGroup;

  // Recursively remove the empty filter and invalid filters
  const transformedSearchModel = {
    ...searchModel,
    filter_group: removeInvalidValuesFromAFilterGroup(rootFilterGroup)
  };

  return transformedSearchModel;
};

// Recursively add uuid to both filter and filterGroup
export const addKeyToFilterGroupAndFilterDeep = (
  filterGroup: ISearchFilterGroup
): ISearchFilterGroup => {
  const formattedFilterGroup: ISearchFilterGroup = {
    filters: [],
    join_operator: filterGroup.join_operator,
    filter_groups: [],
    key: uuidv4()
  };

  // Format filters
  formattedFilterGroup.filters =
    filterGroup.filters?.map((filter) => ({
      ...filter,
      key: uuidv4()
    })) || [];

  formattedFilterGroup.filter_groups =
    filterGroup.filter_groups?.map((filter_group) =>
      addKeyToFilterGroupAndFilterDeep(filter_group)
    ) || [];
  return formattedFilterGroup;
};

// Recursively adds noOperation property to filter.
export const addNoOperationToFilterDeep = (
  filterGroup: ISearchFilterGroup
): ISearchFilterGroup => {
  const formattedFilterGroup: ISearchFilterGroup = cloneDeep(filterGroup);
  formattedFilterGroup.filters =
    filterGroup.filters?.map((filter) => ({
      ...filter,
      noOperation: true
    })) || [];

  formattedFilterGroup.filter_groups =
    filterGroup.filter_groups?.map((filter_group) =>
      addKeyToFilterGroupAndFilterDeep(filter_group)
    ) || [];
  return formattedFilterGroup;
};

// Adds uuid to the saved search received from server
export const transformSearchModelFromApiv2 = (
  searchModel: ISearchModel | null,
  isPeopleSearch = false
): { searchModel: ISearchModel } => {
  if (searchModel === null) {
    logger.warning('Search model is null');
    return { searchModel: INITIAL_SEARCH_MODEL };
  }

  searchModel = transformSearchQueryWithValidSortField(
    searchModel,
    isPeopleSearch
  );

  if (searchModel.filter_group === undefined && searchModel.filterGroup) {
    searchModel.filter_group = snakelize(
      searchModel.filterGroup
    ) as ISearchFilterGroup;
    delete searchModel.filterGroup;
    // if missing a join operator, set it to AND
    if (!searchModel.filter_group.join_operator) {
      searchModel.filter_group.join_operator = 'and';
    }
  }
  if (
    searchModel.controlled_filter_group === undefined &&
    searchModel.controlledFilterGroup
  ) {
    searchModel.controlled_filter_group = snakelize(
      searchModel.controlledFilterGroup
    ) as ISearchFilterGroup;

    // if missing a join operator, set it to AND
    if (!searchModel.controlled_filter_group.join_operator) {
      searchModel.controlled_filter_group.join_operator = 'and';
    }
  }
  delete searchModel.controlledFilterGroup;
  return {
    searchModel: {
      ...searchModel,
      filter_group: addKeyToFilterGroupAndFilterDeep(
        searchModel.filter_group || []
      ),
      sort:
        searchModel.sort?.map((sort) => snakelize(sort) as ISearchSort) ?? []
    }
  };
};

// Recursively search filters for field key
export const findKeyInSearchFilters = (
  filterGroup: ISearchFilterGroup,
  key: string,
  comparatorType: FilterComparator | '*'
): boolean => {
  let keyFound = false;

  const filterExists = find(
    filterGroup.filters,
    (item) =>
      item.field === key &&
      (comparatorType === '*' ? true : item.comparator === comparatorType)
  );

  if (filterExists) {
    return true;
  }

  filterGroup.filter_groups.forEach((group) => {
    keyFound = findKeyInSearchFilters(group, key, comparatorType);
  });

  return keyFound;
};

/*
 * Function to insert or update the filter group if field is present
 * NOTE: ONLY USE IF YOU KNOW THE FIELD KEY IS PRESENT ANYWHERE IN THIS FILTER GROUP
 */
export const upsertValueofKeyInSearchFilters = (
  filterGroup: ISearchFilterGroup,
  fieldKey: string,
  comparatorType: FilterComparator,
  ids: (number | string)[],
  insertValueToKeyInSearchFilters = true
): ISearchFilterGroup => {
  const clonedFilterGroup = cloneDeep(filterGroup);

  const filterIndex = findIndex(
    clonedFilterGroup.filters,
    (item) => item.field === fieldKey && item.comparator === comparatorType
  );

  if (filterIndex !== -1) {
    let resultingIdList = [];
    const existingFilterValues = filterGroup.filters[filterIndex].filter_value;

    const existingFilterValuesAsArray = isArray(existingFilterValues)
      ? existingFilterValues
      : [existingFilterValues];

    if (insertValueToKeyInSearchFilters) {
      resultingIdList = union(
        existingFilterValuesAsArray,
        ids.map((id) => id.toString())
      ) as string[];
    } else {
      resultingIdList = difference(
        existingFilterValuesAsArray,
        ids.map((id) => id.toString())
      ) as string[];
    }
    clonedFilterGroup.filters[filterIndex].filter_value = resultingIdList;

    return clonedFilterGroup;
  }

  filterGroup.filter_groups.forEach((group) => {
    upsertValueofKeyInSearchFilters(group, fieldKey, comparatorType, ids);
  });

  return filterGroup;
};

export const removeArtifactsFromSearchQuery = (
  searchQuery: ISearchModel
): ISearchModel => {
  const query = cloneDeep(searchQuery);

  if (
    query.filterGroup !== undefined ||
    query.controlledFilterGroup !== undefined
  ) {
    logger.info('Malformed SavedSearch query found', { query });
  }
  if (query.filter_group === undefined && query.filterGroup) {
    query.filter_group = snakelize(query.filterGroup) as ISearchFilterGroup;
    delete query.filterGroup;
  }
  query.filter_group = removeInvalidValuesFromAFilterGroup(query.filter_group);
  if (
    query.controlled_filter_group === undefined &&
    query.controlledFilterGroup
  ) {
    query.controlled_filter_group = snakelize(
      query.controlledFilterGroup
    ) as ISearchFilterGroup;
    delete query.controlledFilterGroup;
  }

  const omittedFilterGroup: ISearchFilterGroup = deepOmitFromObject(
    query.filter_group,
    ['key', 'name']
  ) as ISearchFilterGroup;
  query.filter_group = omittedFilterGroup;

  return query;
};
