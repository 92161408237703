import { TrashcanIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import Dropdown from 'harmonic-components/Dropdown/Dropdown';
import ListItem, { ListVariant } from 'harmonic-components/ListItem/ListItem';
import TextInput from 'harmonic-components/TextInput/TextInput';
import { useState } from 'react';
import {
  ColorShorthand,
  getBackgroundColorClassFromShorthandAndIntensity
} from 'utils/design';
import { useOutsideClick } from 'utils/hooks';

interface ColorOptionValue {
  label: string;
  color: ColorShorthand;
}
export const colorOptions: ColorOptionValue[] = [
  {
    label: 'Grey',
    color: 'neutral'
  },
  {
    label: 'Red',
    color: 'expressive-45'
  },
  {
    label: 'Orange',
    color: 'expressive-5'
  },
  {
    label: 'Yellow',
    color: 'expressive-8'
  },
  {
    label: 'Green',
    color: 'expressive-11'
  },
  {
    label: 'Teal',
    color: 'expressive-22'
  },
  {
    label: 'Blue',
    color: 'expressive-27'
  },
  {
    label: 'Purple',
    color: 'expressive-31'
  },
  {
    label: 'Lavender',
    color: 'expressive-35'
  },
  {
    label: 'Pink',
    color: 'expressive-39'
  }
];
interface EditOptionItemProps {
  name: string;
  color: ColorShorthand;
  onEdit: (newValue: string, newColor: ColorShorthand) => void;
  onDelete: () => void;
  handleClose: () => void;
}
const EditOptionItem: React.FC<EditOptionItemProps> = ({
  name,
  color,
  onEdit,
  onDelete,
  handleClose
}) => {
  const [inputValue, setInputValue] = useState(name);

  const saveEdit = () => {
    onEdit(inputValue, color);
  };
  const outsideClickRef = useOutsideClick(() => {
    saveEdit();
    handleClose();
  });

  return (
    <div ref={outsideClickRef}>
      <Dropdown>
        <TextInput
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          autoFocus
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              saveEdit();
            }
          }}
        />
        <div className="w-full">
          {colorOptions.map((option) => {
            return (
              <ListItem
                key={option.color}
                label={option.label}
                value={option.color}
                primaryIcon={() => (
                  <svg
                    className={classNames(
                      `w-4 h-4 ${getBackgroundColorClassFromShorthandAndIntensity(
                        option.color,
                        'subtle'
                      )}`
                    )}
                  />
                )}
                selected={color === option.color}
                onClick={(e) => {
                  e?.stopPropagation();
                  onEdit(inputValue, option.color);
                  handleClose();
                }}
                variant={ListVariant.default}
              />
            );
          })}
        </div>
        <div className="border-t border-solid border-border w-full">
          <ListItem
            label="Delete option"
            variant={ListVariant.default}
            value="Delete option"
            onClick={onDelete}
            destructive
            primaryIcon={TrashcanIcon}
          />
        </div>
      </Dropdown>
    </div>
  );
};

export default EditOptionItem;
