import classNames from 'classnames';
import { HarmonicIcon } from './type';
const ChevronRight: HarmonicIcon = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke="#677183"
      strokeWidth="1.5"
      strokeLinecap="square"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M6.6665 10.6666L9.33317 7.99998L6.6665 5.33331"
      />
    </svg>
  );
};
export default ChevronRight;
