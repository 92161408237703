import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const FlagIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
      fill="none"
      stroke="#677183"
    >
      <path
        d="M3.16602 10.0555V2.93273C3.16602 2.67907 3.30981 2.44766 3.54527 2.35331C4.05123 2.15055 4.99236 1.83331 5.92792 1.83331C7.30286 1.83331 8.69584 3.20368 10.0708 3.20368C10.7004 3.20368 11.3325 3.06001 11.8328 2.90424C12.3047 2.75734 12.8327 3.09723 12.8327 3.59142V9.6413C12.8327 9.89497 12.6889 10.1264 12.4534 10.2207C11.9475 10.4235 11.0063 10.7407 10.0708 10.7407C8.69584 10.7407 7.30286 9.37035 5.92792 9.37035C4.55298 9.37035 3.16602 10.0555 3.16602 10.0555ZM3.16602 10.0555V14.1666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default FlagIcon;
