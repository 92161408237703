import classNames from 'classnames';
import { IconProps } from './type';

const URL: React.FC<IconProps> = ({ applyCurrentColor = true, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#7C8598"
      fillRule="evenodd"
      clipRule="evenodd"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M3.13311 5.8H5.99977C6.33114 5.8 6.59977 5.53137 6.59977 5.2C6.59977 4.86863 6.33114 4.6 5.99977 4.6H3.06644C2.44052 4.6 1.93311 5.10741 1.93311 5.73333V10.2667C1.93311 10.8926 2.44052 11.4 3.06644 11.4H5.99977C6.33114 11.4 6.59977 11.1314 6.59977 10.8C6.59977 10.4686 6.33114 10.2 5.99977 10.2H3.13311V5.8ZM9.39977 5.2C9.39977 4.86863 9.6684 4.6 9.99977 4.6H12.9331C13.559 4.6 14.0664 5.10741 14.0664 5.73333V10.2667C14.0664 10.8926 13.559 11.4 12.9331 11.4H9.99977C9.6684 11.4 9.39977 11.1314 9.39977 10.8C9.39977 10.4686 9.6684 10.2 9.99977 10.2H12.8664V5.8H9.99977C9.6684 5.8 9.39977 5.53137 9.39977 5.2ZM4.59977 8C4.59977 7.66863 4.8684 7.4 5.19977 7.4H10.7998C11.1311 7.4 11.3998 7.66863 11.3998 8C11.3998 8.33137 11.1311 8.6 10.7998 8.6H5.19977C4.8684 8.6 4.59977 8.33137 4.59977 8Z"
      />
    </svg>
  );
};

export default URL;
