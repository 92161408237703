import classNames from 'classnames';
import { HarmonicIcon } from './type';

const CircleCheckFilledIcon: HarmonicIcon = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#25A73F"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        vectorEffect="non-scaling-stroke"
        d="M8.00004 1.33334C4.31814 1.33334 1.33337 4.31811 1.33337 8.00001C1.33337 11.6819 4.31814 14.6667 8.00004 14.6667C11.6819 14.6667 14.6667 11.6819 14.6667 8.00001C14.6667 4.31811 11.6819 1.33334 8.00004 1.33334ZM10.387 6.64996C10.5619 6.43624 10.5304 6.12123 10.3167 5.94636C10.1029 5.7715 9.78793 5.803 9.61306 6.01672L6.96292 9.25578L6.02026 8.31312C5.825 8.11786 5.50842 8.11786 5.31315 8.31312C5.11789 8.50839 5.11789 8.82497 5.31315 9.02023L6.64649 10.3536C6.74638 10.4535 6.88386 10.5064 7.02495 10.4994C7.16604 10.4924 7.29757 10.426 7.38702 10.3166L10.387 6.64996Z"
      />
    </svg>
  );
};

export default CircleCheckFilledIcon;
