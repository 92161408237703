import { useQuery } from '@apollo/client';
import {
  EmployeeGroupType,
  GetCompanyEmployeesQuery,
  GetCompanyEmployeesQueryVariables,
  GetCompanyFoundersQuery,
  GetCompanyFoundersQueryVariables
} from '__generated__/graphql';
import { getCompanyEmployees } from 'queries/getCompanyEmployees';
import React from 'react';
import { EMPLOYEE_LIST_PAGE_SIZE } from 'utils/constants';
import { CompanyNavbarOptions } from '../CompanyHeader/CompanyHeader';
import CompanyExecutives from '../CompanyTeam/CompanyExecutives';
import CompanyFoundersCeo, {
  CompanyFoundersContentLoader,
  FETCH_FOUNDERS_CEO_COUNT,
  getCompanyFounders
} from '../CompanyTeam/CompanyFoundersCeo';

interface CompanyOverviewTeamProps {
  companyId: number;
  onTabChange?: (newTabOption: CompanyNavbarOptions) => void;
}

const CompanyOverviewTeam: React.FC<CompanyOverviewTeamProps> = ({
  companyId,
  onTabChange
}) => {
  const { data: founderCeoData } = useQuery<
    GetCompanyFoundersQuery,
    GetCompanyFoundersQueryVariables
  >(getCompanyFounders, {
    variables: {
      companyUrn: `urn:harmonic:company:${companyId}`,
      page: 0,
      size: FETCH_FOUNDERS_CEO_COUNT,
      employeeGroupType: EmployeeGroupType.FOUNDERS_AND_CEO
    },
    fetchPolicy: 'cache-only'
  });

  const { data: executivesData } = useQuery<
    GetCompanyEmployeesQuery,
    GetCompanyEmployeesQueryVariables
  >(getCompanyEmployees, {
    variables: {
      companyUrn: `urn:harmonic:company:${companyId}`,
      page: 0,
      size: EMPLOYEE_LIST_PAGE_SIZE,
      employeeGroupType: EmployeeGroupType.EXECUTIVES,
      userConnectionStatus: null
    },
    fetchPolicy: 'cache-only'
  });

  const showFounderAndCeo =
    founderCeoData?.getEmployeesByCompanyId?.edges &&
    founderCeoData.getEmployeesByCompanyId.edges.length > 0;
  const showExecutives =
    !showFounderAndCeo &&
    executivesData?.getEmployeesByCompanyId?.edges &&
    executivesData.getEmployeesByCompanyId.edges.length > 0;

  if (!founderCeoData || !executivesData) {
    return <CompanyFoundersContentLoader />;
  }

  return (
    <>
      {showFounderAndCeo && (
        <CompanyFoundersCeo onTabChange={onTabChange} companyId={companyId} />
      )}
      {showExecutives && (
        <CompanyExecutives
          companyId={companyId}
          onTabChange={onTabChange}
          disableLoadMore={true}
        />
      )}
    </>
  );
};

export default CompanyOverviewTeam;
