import { DashboardThunkAction, IGridError } from 'interfaces/Dashboard';
import { ISearchModel } from 'interfaces/SearchModel/Search';
import { AppDispatch } from 'store';
import { getCurrentDashboardCacheId } from 'utils/utilities';
import {
  RESET_SELECTED_ROW_IDS,
  SET_DASHBOARD_ERROR,
  SET_DATA_LOADING,
  SET_GRID_ERROR,
  SET_SORT_FIELD,
  SET_SORT_IS_DESCENDING,
  UPDATE_SEARCH_MODEL
} from './types/actionTypes';

const resetSelectedRowIds = (): DashboardThunkAction => {
  return (dispatch: AppDispatch) => {
    dispatch({ type: RESET_SELECTED_ROW_IDS });
  };
};

export const setDashboardError = (
  dashboardErrorString: string
): DashboardThunkAction => {
  return (dispatch: AppDispatch) => {
    dispatch({ type: SET_DASHBOARD_ERROR, payload: dashboardErrorString });
  };
};

export const setDataLoading = (loading: boolean): DashboardThunkAction => {
  return (dispatch: AppDispatch) => {
    dispatch({ type: SET_DATA_LOADING, payload: loading });
  };
};

export const setGridError = (
  gridErrorState: IGridError
): DashboardThunkAction => {
  return (dispatch: AppDispatch) => {
    dispatch({ type: SET_GRID_ERROR, payload: gridErrorState });
  };
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const resetGridState = (dashboardCacheId: string): DashboardThunkAction => {
  return (dispatch: AppDispatch) => {
    dispatch(resetSelectedRowIds());
  };
};

export const updateSearchModel = (
  partialSearchModel: Partial<ISearchModel>,
  reloadGrid = true
): DashboardThunkAction => {
  return (dispatch: AppDispatch) => {
    dispatch({ type: UPDATE_SEARCH_MODEL, payload: partialSearchModel });
    if (partialSearchModel.sort) {
      dispatch({
        type: SET_SORT_FIELD,
        payload: partialSearchModel.sort[0]?.sort_field
      });
      dispatch({
        type: SET_SORT_IS_DESCENDING,
        payload: partialSearchModel.sort[0]?.descending
      });
    }
    // Reload ag-grid data when search model updates
    if (reloadGrid) {
      dispatch(resetGridState(getCurrentDashboardCacheId()));
    }
  };
};
