import { IconProps } from './type';

const BxsUserIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H32C34.2091 0 36 1.79086 36 4V32C36 34.2091 34.2091 36 32 36H4C1.79086 36 0 34.2091 0 32V4Z"
        fill="#F0F4E1"
        vectorEffect="non-scaling-stroke"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0003 9.66669C13.398 9.66669 9.66699 13.3976 9.66699 18C9.66699 20.4142 10.6936 22.5886 12.3341 24.1105C13.8209 25.49 15.8121 26.3334 18.0003 26.3334C20.1885 26.3334 22.1797 25.49 23.6666 24.1105C25.3071 22.5886 26.3337 20.4142 26.3337 18C26.3337 13.3976 22.6027 9.66669 18.0003 9.66669ZM12.9804 22.9974C14.083 21.4783 15.8429 20.5 18.0003 20.5C20.1577 20.5 21.9177 21.4783 23.0203 22.9974C21.7376 24.2859 19.9621 25.0834 18.0003 25.0834C16.0386 25.0834 14.2631 24.2859 12.9804 22.9974ZM20.5003 16.3334C20.5003 17.7141 19.381 18.8334 18.0003 18.8334C16.6196 18.8334 15.5003 17.7141 15.5003 16.3334C15.5003 14.9526 16.6196 13.8334 18.0003 13.8334C19.381 13.8334 20.5003 14.9526 20.5003 16.3334Z"
        fill="#272D10"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default BxsUserIcon;
