import { useMutation } from '@apollo/client';
import {
  CreatePeopleWatchlistMutation,
  CreatePeopleWatchlistMutationVariables,
  GetPeopleWatchlistsQuery
} from '__generated__/graphql';
import { CREATE_PEOPLE_WATCHLIST } from 'queries/createPeopleWatchlist';
import { GET_PEOPLE_WATCHLISTS } from 'queries/getPeopleWatchlists';
import { useNavigate } from 'react-router-dom';
import useStore from 'stores/zustandStore';
import { ADD_PEOPLE_WATCHLIST_ERROR_MESSAGE } from '../../utils/constants';
import { logger } from '../../utils/logger';
import { displayToast } from '../../utils/toasts';
import { getIdFromUrn } from '../../utils/urn';
import { UseAddEntityListModalOnSuccessProps } from '../useAddEntityListModal';

interface UseCreatePeopleListResult {
  createPeopleList: (
    name: string,
    disableNavigation?: boolean,
    disableToast?: boolean
  ) => Promise<void>;
}

const useCreatePeopleList = ({
  onSuccess
}: {
  onSuccess?: (props: UseAddEntityListModalOnSuccessProps) => void;
}): UseCreatePeopleListResult => {
  const [createPeopleWatchlist] = useMutation<
    CreatePeopleWatchlistMutation,
    CreatePeopleWatchlistMutationVariables
  >(CREATE_PEOPLE_WATCHLIST);
  const navigate = useNavigate();
  const editStoreData = useStore((state) => state.editStoreData);

  const createPeopleList = async (
    name: string,
    disableNavigation = false,
    disableToast = false
  ) => {
    const payload: CreatePeopleWatchlistMutationVariables = {
      watchlistInput: {
        name,
        sharedWithTeam: false,
        people: []
      }
    };

    try {
      const { data } = await createPeopleWatchlist({
        variables: payload,
        // Add new watchlist to watchlists in cache
        update: (cache, { data }) => {
          const res = cache.readQuery({ query: GET_PEOPLE_WATCHLISTS }) as
            | GetPeopleWatchlistsQuery
            | undefined;
          const existingWatchlists = res?.getPeopleWatchlistsForTeam ?? [];
          cache.writeQuery({
            query: GET_PEOPLE_WATCHLISTS,
            data: {
              getPeopleWatchlistsForTeam: [
                ...existingWatchlists,
                { ...data?.createPeopleWatchlist }
              ]
            }
          });
        }
      });

      const watchlistUrn = data?.createPeopleWatchlist?.entityUrn;

      if (onSuccess) {
        await onSuccess({
          entityListId: getIdFromUrn(watchlistUrn) ?? '',
          entityListName: name
        });
      }

      editStoreData('pageTitle', name);

      const notificationMessage = 'Successfully created new list';
      if (disableNavigation) {
        if (!disableToast) {
          displayToast({
            primaryText: notificationMessage,
            secondaryText: 'Go to list',
            link: `/dashboard/people_watchlist/${watchlistUrn}`
          });
        }
      } else if (!disableToast) {
        displayToast({
          primaryText: notificationMessage,
          mode: 'success'
        });
        const toNavigateRoute = `/dashboard/people_watchlist/${watchlistUrn}`;
        navigate(toNavigateRoute);
      }
    } catch (error) {
      logger.error('People watchlist create error', {
        error
      });
      displayToast({
        primaryText: ADD_PEOPLE_WATCHLIST_ERROR_MESSAGE,
        mode: 'error'
      });
    }
  };

  return {
    createPeopleList
  };
};

export default useCreatePeopleList;
