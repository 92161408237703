import CompanyAvatarPlaceholder from 'assets/harmonic-icons/company-avatar-placeholder.png';
import PersonAvatarPlaceholder from 'assets/harmonic-icons/person-avatar-placeholder.png';
import classNames from 'classnames';
import { useState } from 'react';
import { isSafeHostedAsset } from 'utils/utilities';

export type AvatarSizes = 'small' | 'medium' | 'large' | 'x-large' | 'huge';

interface AvatarProps {
  src: string;
  alt: string;
  size?: AvatarSizes;
  kind?: 'logo' | 'face';
  className?: string;
  dataTestId?: string;
}

const Avatar: React.FC<AvatarProps> = ({
  src,
  size = 'huge',
  kind = 'logo',
  dataTestId
}) => {
  const [error, setError] = useState(false);
  const imgSrc = src && isSafeHostedAsset(src) && !error ? src : undefined;
  const placeholderSrc =
    kind === 'logo' ? CompanyAvatarPlaceholder : PersonAvatarPlaceholder;

  return (
    <>
      {imgSrc ? (
        <img
          onError={() => setError(true)}
          className={classNames('bg-surface-sentiment-neutral object-cover', {
            'w-4 h-4': size === 'small',
            'w-5 h-5': size === 'medium',
            'w-6 h-6': size === 'large',
            'w-8 h-8': size === 'x-large',
            'w-10 h-10': size === 'huge',
            'rounded-full': kind === 'face',
            'rounded-br20 border border-solid border-border-weak':
              kind === 'logo'
          })}
          data-testid={dataTestId}
          src={imgSrc}
        />
      ) : (
        <div
          className={classNames(
            'bg-surface-sentiment-neutral flex justify-center items-center shrink-0 overflow-hidden',
            {
              'w-4 h-4': size === 'small',
              'w-5 h-5': size === 'medium',
              'w-6 h-6': size === 'large',
              'w-8 h-8': size === 'x-large',
              'w-10 h-10': size === 'huge',
              'rounded-full': kind === 'face',
              'rounded-br20 border border-solid border-border-weak':
                kind === 'logo'
            }
          )}
        >
          <img
            data-testid={dataTestId}
            className={classNames('object-cover', {
              'w-2 h-2': size === 'small' && kind === 'logo',
              'w-2.5 h-2.5': size === 'medium' && kind === 'logo',
              'w-3 h-3': size === 'large' && kind === 'logo',
              'w-5 h-5':
                (size === 'huge' && kind === 'logo') ||
                (size === 'medium' && kind === 'face'),
              'w-4 h-4': size === 'small' && kind === 'face',
              'w-6 h-6': size === 'large' && kind === 'face',
              'w-8 h-8': size === 'x-large' && kind === 'face',
              'w-10 h-10': size === 'huge' && kind === 'face',
              'pt-p20':
                kind === 'face' &&
                (size === 'medium' || size === 'large' || size === 'small'),
              'pt-p40': kind === 'face' && size === 'huge'
            })}
            src={placeholderSrc}
          />
        </div>
      )}
    </>
  );
};

export default Avatar;
