import { ChartData, ChartOptions, ScatterDataPoint } from 'chart.js';
import { ILineChartDataPoint } from 'interfaces/Charts';
import { maxBy } from 'lodash';
import * as React from 'react';
import { Chart as ChartJS, Line } from 'react-chartjs-2';
import { chartAreaRoundedBorderPlugin } from 'utils/chart';
import { numberSuffixFormatter } from 'utils/utilities';

ChartJS.register(chartAreaRoundedBorderPlugin);

type LineChartProps = {
  yAxisPrefix: string;
  backgroundColor?: string;
  label?: string;
  data: ILineChartDataPoint[];
};

const borderMainColor = '#67C7F0';
const pointBackgroundColor = '#67C7F0';

const LineChartV2: React.FC<LineChartProps> = ({
  yAxisPrefix = '',
  backgroundColor = 'rgba(58, 145, 166, 0.1)',
  data,
  label
}) => {
  const maxYOfData = maxBy(data, (d) => d.y)?.y;
  const lineChartData: ChartData = {
    datasets: [
      {
        label: label ?? '',
        fill: true,
        tension: 0.1,
        borderWidth: 1.5,
        backgroundColor, //this is color under the line
        borderColor: borderMainColor, //this is the color of the line itself
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'round',
        pointBorderColor: borderMainColor,
        pointBackgroundColor: pointBackgroundColor, //color of the point itself
        pointBorderWidth: 0,
        pointHoverRadius: 2,
        pointRadius: 0,
        pointHitRadius: 5, //area that triggers the hover
        spanGaps: true,
        data: data as unknown as ScatterDataPoint[]
      }
    ]
  };

  const lineOptions: ChartOptions = {
    maintainAspectRatio: false,
    backgroundColor,
    layout: {
      padding: {
        right: 10
      }
    },
    scales: {
      xAxes: {
        type: 'time',

        grid: {
          lineWidth: 1,
          display: false,
          drawBorder: false
        },
        time: {
          tooltipFormat: 'MMM DD, YYYY',
          unit: 'month',
          displayFormats: {
            year: 'MMM YYYY',
            quarter: 'MMM YYYY',
            month: 'MMM YYYY'
          }
        },
        ticks: {
          maxTicksLimit: 7,
          autoSkip: true,
          source: 'auto',
          maxRotation: 0
        }
      },
      yAxes: {
        min: 0,
        stacked: false,
        grid: {
          display: false,
          drawBorder: false
        },
        ticks: {
          maxTicksLimit: 5,
          callback: (value: number | string): string => {
            return `${yAxisPrefix}${numberSuffixFormatter(value as number)}`;
          }
        }
      },
      //eslint-disable-next-line
      //@ts-ignore
      y2: {
        position: 'right',
        min: 0,
        max: maxYOfData,
        stacked: true,
        grid: {
          display: true,
          borderDash: [4, 7],
          tickLength: 0,
          drawBorder: false,
          borderColor: '#E8EAEE'
        },

        ticks: {
          maxTicksLimit: 7,
          backdropPadding: 0,
          display: false,
          includeBounds: false,
          callback: (label, index, allTicks) => {
            if (
              allTicks[index].value === 0 ||
              allTicks[index].value === maxYOfData
            )
              return undefined;
            return '';
          }
        }
      }
    },

    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          title: (ctx) => {
            return ctx?.[0]?.dataset?.label?.toUpperCase() ?? '';
          },
          label: (ctx) => {
            return [ctx.formattedValue, ctx.label];
          }
        }
      }
    }
  };

  return (
    <>
      {/* //eslint-disable-next-line
      //@ts-ignore:next-line */}
      <Line type="line" data={lineChartData} options={lineOptions} />
    </>
  );
};

export default LineChartV2;
