import classNames from 'classnames';
import { IconProps } from './type';

const HorizontalMenuIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={classNames(
        applyCurrentColor && 'fill-current',
        applyCurrentColor && 'stroke-current',
        className
      )}
      fill="#484E5B"
      stroke="#7C8598"
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M8.00001 8.66667C8.3682 8.66667 8.66668 8.36819 8.66668 8C8.66668 7.63181 8.3682 7.33334 8.00001 7.33334C7.63182 7.33334 7.33334 7.63181 7.33334 8C7.33334 8.36819 7.63182 8.66667 8.00001 8.66667Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M13.5 8.66667C13.8682 8.66667 14.1667 8.36819 14.1667 8C14.1667 7.63181 13.8682 7.33334 13.5 7.33334C13.1318 7.33334 12.8333 7.63181 12.8333 8C12.8333 8.36819 13.1318 8.66667 13.5 8.66667Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M2.50001 8.66667C2.8682 8.66667 3.16668 8.36819 3.16668 8C3.16668 7.63181 2.8682 7.33334 2.50001 7.33334C2.13182 7.33334 1.83334 7.63181 1.83334 8C1.83334 8.36819 2.13182 8.66667 2.50001 8.66667Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M8.00001 8.66667C8.3682 8.66667 8.66668 8.36819 8.66668 8C8.66668 7.63181 8.3682 7.33334 8.00001 7.33334C7.63182 7.33334 7.33334 7.63181 7.33334 8C7.33334 8.36819 7.63182 8.66667 8.00001 8.66667Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M13.5 8.66667C13.8682 8.66667 14.1667 8.36819 14.1667 8C14.1667 7.63181 13.8682 7.33334 13.5 7.33334C13.1318 7.33334 12.8333 7.63181 12.8333 8C12.8333 8.36819 13.1318 8.66667 13.5 8.66667Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M2.50001 8.66667C2.8682 8.66667 3.16668 8.36819 3.16668 8C3.16668 7.63181 2.8682 7.33334 2.50001 7.33334C2.13182 7.33334 1.83334 7.63181 1.83334 8C1.83334 8.36819 2.13182 8.66667 2.50001 8.66667Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default HorizontalMenuIcon;
