import {
  EntityListModalType,
  EntityListType,
  ISearchModel
} from 'interfaces/SearchModel/Search';
import useCreatePeopleList from './Watchlists/useCreatePeopleList';
import { useCreateCompanyWatchlist } from './useCreateCompanyWatchlist';
import { useCreateSavedSearchList } from './useCreateSavedSearchList';
import { useUpsertEntityListModal } from './useUpsertEntityListModal';

export interface UseAddEntityListModalOnSuccessProps {
  entityListId: string;
  entityListName: string;
}

interface UseAddEntityListModalProps {
  searchQuery: ISearchModel;
  entityType: EntityListType;
  disableNavigation?: boolean;
  onSuccess?: (props: UseAddEntityListModalOnSuccessProps) => void;
  disableToast?: boolean;
}

export function useAddEntityListModal({
  searchQuery,
  entityType,
  disableNavigation,
  onSuccess,
  disableToast
}: UseAddEntityListModalProps) {
  const {
    isUpsertEntityListModalOpen,
    showUpsertEntityListModal,
    closeUpsertEntityListModal
  } = useUpsertEntityListModal();

  const { createSavedSearchList } = useCreateSavedSearchList();
  const { createPeopleList } = useCreatePeopleList({ onSuccess });
  const { createCompanyWatchlist } = useCreateCompanyWatchlist({ onSuccess });

  const createEntityList = async (entityName: string) => {
    if (
      entityType === EntityListType.COMPANY_SAVED_SEARCH ||
      entityType === EntityListType.PEOPLE_SAVED_SEARCH
    ) {
      await createSavedSearchList(
        entityName,
        entityType,
        searchQuery,
        disableNavigation
      );
    }

    if (entityType === EntityListType.COMPANY_WATCHLIST) {
      await createCompanyWatchlist(entityName, disableNavigation, disableToast);
    }

    if (entityType === EntityListType.PEOPLE_WATCHLIST) {
      await createPeopleList(entityName, disableNavigation, disableToast);
    }

    closeUpsertEntityListModal();
  };

  const showAddEntityListModal = () => {
    showUpsertEntityListModal({
      entityType,
      modalType: EntityListModalType.CreateList,
      onSubmit: createEntityList
    });
  };

  return {
    isAddEntityListModalOpen: isUpsertEntityListModalOpen,
    showAddEntityListModal
  };
}
