import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const CrossLargeIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
      fill="none"
      stroke="#677183"
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M3.16666 3.16663L12.8333 12.8333M12.8333 3.16663L3.16666 12.8333"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CrossLargeIcon;
