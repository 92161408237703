import classNames from 'classnames';

interface MetricCardProps {
  dataTestId?: string;
  value: string | number | null;
  label: string;
  icon: React.FC;
  titleSuffixComponent?: React.ReactNode;
}

const MetricCard: React.FC<MetricCardProps> = ({
  dataTestId,
  value,
  label,
  icon,
  titleSuffixComponent
}) => {
  const Icon = icon;
  return (
    <div className="bg-surface-nested rounded-br40 p-p60">
      <div data-testid={dataTestId} className="flex gap-g60 items-center">
        <Icon />
        <div>
          <div className="flex gap-2 items-center">
            <p
              className={classNames(
                'typography-label-large text-content-strong'
              )}
            >
              {value}
            </p>
            {titleSuffixComponent}
          </div>

          <p className={classNames('text-content-weak typography-label')}>
            {label}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MetricCard;
