import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const BoardIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        d="M3.07342 -0.000325039H16.4259C16.6833 -0.000342235 16.9198 -0.000358025 17.1175 0.0157924C17.3306 0.0332061 17.5638 0.0730688 17.7941 0.190416C18.1234 0.358194 18.3911 0.62591 18.5589 0.955195C18.6762 1.1855 18.7161 1.41867 18.7335 1.63181C18.7497 1.82949 18.7497 2.06596 18.7496 2.32345L18.7496 4.99968L0.749639 4.99967L0.749639 6.49967L18.7496 6.49968V11.4997L0.749639 11.4997L0.749639 12.9997L18.7496 12.9997L18.7496 15.6759C18.7497 15.9334 18.7497 16.1699 18.7335 16.3675C18.7161 16.5807 18.6762 16.8139 18.5589 17.0442C18.3911 17.3734 18.1234 17.6412 17.7941 17.8089C17.5638 17.9263 17.3306 17.9661 17.1175 17.9836C16.9198 17.9997 16.6834 17.9997 16.4259 17.9997H3.07341C2.81592 17.9997 2.57945 17.9997 2.38177 17.9836C2.16863 17.9661 1.93546 17.9263 1.70516 17.8089C1.37587 17.6412 1.10815 17.3734 0.940376 17.0442C0.823029 16.8139 0.783166 16.5807 0.765752 16.3675C0.749602 16.1699 0.749618 15.9334 0.749635 15.6759V2.32346C0.749618 2.066 0.749602 1.82948 0.765752 1.63181C0.783166 1.41867 0.823029 1.1855 0.940376 0.955194C1.10815 0.62591 1.37587 0.358194 1.70515 0.190416C1.93546 0.0730689 2.16863 0.0332061 2.38177 0.0157924C2.57944 -0.000358023 2.81595 -0.000342235 3.07342 -0.000325039Z"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default BoardIcon;
