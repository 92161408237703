import { FlagIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import Button from 'harmonic-components/Button/Button';
import IconButton from 'harmonic-components/IconButton/IconButton';
import Modal from 'harmonic-components/Modal/Modal';
import ModalFooter from 'harmonic-components/ModalFooter/ModalFooter';
import ModalTitle from 'harmonic-components/ModalTitle/ModalTitle';
import TextArea from 'harmonic-components/TextArea/TextArea';
import { UserReportedDataIssueInput } from 'interfaces/UserReportedDataIssue';
import { useState } from 'react';
import { submitDataIssue } from 'utils/hasuraApi';
import { displayToast } from 'utils/toasts';

export const ReportDataIssueLegalDisclaimer = (props: {
  className?: string;
}) => {
  const { className } = props;
  return (
    <p
      className={classNames(
        className,
        'typography-label-small-default text-content-weak'
      )}
    >
      This feedback may be used to correct or verify our data.
    </p>
  );
};

interface ReportDataIssueProps {
  reportParams: Omit<UserReportedDataIssueInput, 'reportText'>;
  placeholderText: string;
  showCircularBorder?: boolean;
}
const ReportDataIssue = (props: ReportDataIssueProps) => {
  const { reportParams } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [userInput, setUserInput] = useState('');
  const onClose = () => {
    setDialogOpen(false);
    setUserInput('');
  };

  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    try {
      submitDataIssue({ ...reportParams, reportText: userInput });
      displayToast({
        primaryText: 'Report submitted successfully',
        mode: 'success'
      });
    } catch (e) {
      displayToast({
        primaryText: 'There was an error submitting report',
        mode: 'error'
      });
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <div data-testid="ReportDataIssue">
      <IconButton
        icon={FlagIcon}
        onClick={() => setDialogOpen(true)}
        type="secondary"
        emphasis="high"
        size="default"
        dataTestId="ReportDataIssue-Button"
      />

      <Modal isOpen={dialogOpen} onClose={onClose}>
        <ModalTitle title="Suggest a correction" />
        <div data-testid="ReportDataIssue-Popover" className="p-p40">
          <TextArea
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            placeholder={props.placeholderText}
          />
          <ReportDataIssueLegalDisclaimer className="pl-p10 pt-p20" />
        </div>

        <ModalFooter>
          <Button
            dataTestId="ReportDataIssue-Submit-Button"
            type="primary"
            label="Submit"
            isDisabled={loading}
            loading={loading}
            onClick={onSubmit}
          />
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ReportDataIssue;
