import classNames from 'classnames';
import { ListVariant } from 'harmonic-components/ListItem/ListItem';
import Select from 'harmonic-components/Select/Select';
import SelectListItem from 'harmonic-components/Select/SelectListItem';
import TextInput from 'harmonic-components/TextInput/TextInput';
import { toString } from 'lodash';
import { ChangeEvent, FC } from 'react';

export interface SelectorOption {
  label: string;
  value: string;
}

interface SelectorProps {
  options: SelectorOption[];
  value: string;
  onChange: (value: string) => void;
}

interface SelectorWithValueProps {
  selector: SelectorProps;
  hideValueInput?: boolean;
  value?: number;
  onValueChange: (event: ChangeEvent<HTMLInputElement>) => void;
  fullWidth?: boolean;
}

const SelectorWithValue: FC<SelectorWithValueProps> = ({
  selector,
  hideValueInput = false,
  value,
  onValueChange,
  fullWidth = false
}) => {
  return (
    <div className="flex flex-row items-center gap-2">
      {!hideValueInput && (
        <div className="w-16">
          <TextInput
            dataTestId="selector-with-value-input"
            value={toString(value) ?? ''}
            placeholder="0"
            onChange={onValueChange}
          />
        </div>
      )}

      <div
        className={classNames(!fullWidth && 'min-w-36', fullWidth && 'w-full')}
      >
        <Select
          multiple={false}
          getLabelFromValue={(value) => {
            return (
              selector.options.find((option) => option.value === value)
                ?.label ?? ''
            );
          }}
          selected={selector.value}
        >
          {selector.options.map((listItem) => (
            <SelectListItem
              key={listItem.value}
              label={listItem.label}
              value={listItem.value}
              onClick={() => selector.onChange(listItem.value)}
              variant={ListVariant.default}
            />
          ))}
        </Select>
      </div>
    </div>
  );
};

export default SelectorWithValue;
