import { ITableHeader } from 'interfaces/Grid';
import { growthMetricsDisplayNames } from 'utils/constants';
import { CustomColumnTextCellEditor } from '../CellRenderers/CustomColumnCellRenderers';

export const COMPANY_HEADER_LEGAL_NAME = {
  field: 'company.legal_name',
  headerName: 'Legal Name',
  toggleable: true,
  hide: true,
  sortable: false,
  searchModelFilterable: false,
  searchModelIdentifier: 'company_legal_name'
};

export const COMPANY_HEADER_DESCRIPTION = {
  field: 'company.description',
  headerName: 'Description',
  toggleable: true,
  width: 400,
  sortable: false,
  cellRenderer: 'LongTextCellRenderer',
  searchModelFilterable: false,
  searchModelIdentifier: 'company_description'
};

export const COMPANY_HEADER_EXTERNAL_DESCRIPTION = {
  field: 'company.external_description',
  headerName: 'External Description',
  toggleable: true,
  width: 400,
  sortable: false,
  cellRenderer: 'LongTextCellRenderer',
  searchModelFilterable: false,
  searchModelIdentifier: 'company_external_description'
};

export const CommonCompanyDetailHeaders: Array<ITableHeader> = [
  {
    field: 'company.user_custom_fields.custom_text',
    headerName: 'My notes',
    toggleable: true,
    hide: false,
    width: 240,
    searchModelSortable: false,
    searchModelFilterable: false,
    editable: true,
    customTooltip: 'Only you can see and edit this field',
    cellEditor: CustomColumnTextCellEditor,
    cellEditorPopup: true
  },
  {
    field: 'company.team_custom_fields.custom_text',
    headerName: 'Team notes',
    toggleable: true,
    hide: false,
    width: 240,
    searchModelSortable: false,
    searchModelFilterable: false,
    editable: true,
    customTooltip: 'All users in your organization can see and edit this field',
    cellEditor: CustomColumnTextCellEditor,
    cellEditorPopup: true
  },

  /**
   * * * * * * * * * * * *
   * Core Details        *
   * * * * * * * * * * * *
   */
  COMPANY_HEADER_LEGAL_NAME,
  {
    field: 'company.highlights',
    headerName: 'Company Highlights',
    width: 500,
    toggleable: true,
    sortable: false,
    wrapText: true,
    minWidth: 400,
    cellStyle: { whiteSpace: 'normal' },
    searchModelFilterable: true,
    searchModelIdentifier: 'company_highlights',
    cellRenderer: 'CompanyHighlightsCellRenderer'
  },
  COMPANY_HEADER_DESCRIPTION,
  COMPANY_HEADER_EXTERNAL_DESCRIPTION,
  {
    field: 'company.person_relationships',
    headerName: 'Team',
    toggleable: true,
    sortable: false,
    width: 500,
    minWidth: 400,
    cellRenderer: 'PeopleListCellRenderer'
  },
  {
    field:
      'company.user_connections_overview.correspondence_summary.last_meeting_at',
    headerName: 'Last meeting date',
    sortable: false,
    toggleable: true,
    width: 235,
    cellRenderer: 'CorrespondenceDateCellRenderer'
  },
  {
    field:
      'company.user_connections_overview.correspondence_summary.last_meeting_contact_person_email',
    headerName: 'Last meeting with',
    sortable: false,
    toggleable: true,
    width: 235,
    cellRenderer: 'CorrespondencePersonCellRenderer'
  },
  {
    field:
      'company.user_connections_overview.correspondence_summary.last_email_at',
    headerName: 'Last email date',
    sortable: false,
    toggleable: true,
    width: 235,
    cellRenderer: 'CorrespondenceDateCellRenderer'
  },
  {
    field:
      'company.user_connections_overview.correspondence_summary.last_email_contact_person_email',
    headerName: 'Last email with',
    sortable: false,
    toggleable: true,
    width: 235,
    cellRenderer: 'CorrespondencePersonCellRenderer'
  },
  {
    field: 'company.user_connections',
    headerName: 'Team network',
    toggleable: true,
    sortable: false,
    width: 200,
    cellRenderer: 'UserConnectionsCellRenderer',
    headerComponent: 'TooltipHeaderRenderer',
    customTooltip: 'See who on your team has the best path into this company.'
  },
  {
    field: 'company.affinity_lists',
    headerName: 'Affinity lists',
    toggleable: true,
    sortable: false,
    width: 200,
    cellRenderer: 'AffinityListsCellRenderer',
    headerComponent: 'TooltipHeaderRenderer',
    customTooltip:
      'See which of your integrated Affinity lists include this company.'
  },
  {
    field: 'company.lists',
    headerName: 'Lists',
    toggleable: true,
    sortable: false,
    width: 400,
    cellRenderer: 'ListsCellRenderer'
  },
  {
    field: 'company.person_linkedin_profiles',
    headerName: 'Team LinkedIn Profiles',
    toggleable: true,
    sortable: false,
    width: 500,
    minWidth: 400,
    cellRenderer: 'PeopleListCellRenderer'
  },
  {
    field: 'company.headcount',
    headerName: 'Headcount',
    toggleable: true,
    hide: false,
    sortable: false,
    width: 130,
    cellRenderer: 'NumberCellRenderer',
    searchModelFilterable: true,
    searchModelSortable: true,
    searchModelIdentifier: 'company_headcount'
  },
  {
    field: 'company.external_headcount',
    headerName: 'External headcount',
    toggleable: true,
    hide: false,
    sortable: false,
    width: 130,
    cellRenderer: 'NumberCellRenderer',
    searchModelFilterable: true,
    searchModelSortable: true
  },
  {
    field: 'company.founding_date',
    headerName: 'Founding Date',
    toggleable: true,
    width: 150,
    sortable: false,
    cellRenderer: 'DateCellRenderer',
    searchModelFilterable: true,
    searchModelSortable: true,
    searchModelIdentifier: 'company_founding_date'
  },
  {
    field: 'company.typed_tags',
    headerName: 'Tags',
    width: 500,
    toggleable: true,
    sortable: false,
    wrapText: true,
    cellStyle: { whiteSpace: 'normal' },
    searchModelFilterable: false,
    searchModelIdentifier: 'typed_tags',
    cellRenderer: 'TypedTagsCellRenderer',
    minWidth: 400
  },
  {
    field: 'company.company_type',
    headerName: 'Company Type',
    toggleable: true,
    hide: false,
    sortable: false,
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: {
      values: ['UNKNOWN', 'STARTUP', null]
    },
    cellRenderer: 'CompanyTypeCellRenderer',
    searchModelFilterable: true,
    searchModelIdentifier: 'company_type'
  },
  {
    field: 'company.stage',
    headerName: 'Stage',
    toggleable: true,
    cellRenderer: 'StageCellRenderer',
    sortable: false,
    searchModelFilterable: true,
    searchModelIdentifier: 'company_stage',
    searchModelSortable: true,
    sortType: 'text'
  },
  /**
   * * * * * * * * * * * *
   * Location            *
   * * * * * * * * * * * *
   */
  {
    field: 'company.city',
    headerName: 'City',
    toggleable: true,
    width: 150,
    sortable: false,
    hide: false,
    searchModelFilterable: true,
    searchModelIdentifier: 'company_city',
    searchModelSortable: true,
    sortType: 'text',
    cellRenderer: 'LocationCellRenderer'
  },
  {
    field: 'company.state',
    headerName: 'State',
    toggleable: true,
    width: 150,
    sortable: false,
    hide: false,
    searchModelFilterable: true,
    searchModelIdentifier: 'company_state',
    searchModelSortable: true,
    sortType: 'text',
    cellRenderer: 'LocationCellRenderer'
  },
  {
    field: 'company.country',
    headerName: 'Country',
    toggleable: true,
    width: 150,
    sortable: false,
    hide: false,
    searchModelFilterable: true,
    searchModelIdentifier: 'company_country',
    searchModelSortable: true,
    sortType: 'text',
    cellRenderer: 'LocationCellRenderer'
  },
  /**
   * * * * * * * * * * * *
   * Links               *
   * * * * * * * * * * * *
   */
  {
    field: 'company.website_url',
    headerName: 'Website',
    toggleable: true,
    sortable: false,
    hide: false,
    width: 300,
    cellRenderer: 'LinkCellRenderer',
    searchModelFilterable: true,
    searchModelIdentifier: 'company_website_url'
  },
  {
    field: 'company.linkedin_url',
    headerName: 'LinkedIn',
    toggleable: true,
    sortable: false,
    hide: false,
    width: 300,
    cellRenderer: 'LinkCellRenderer',
    searchModelFilterable: true,
    searchModelIdentifier: 'company_linkedin_url'
  },
  /**
   * * * * * * * * * * * *
   * Funding             *
   * * * * * * * * * * * *
   */
  {
    field: 'company.num_funding_rounds',
    headerName: '# Funding Rounds',
    toggleable: true,
    sortable: false,
    cellRenderer: 'FundingCellRenderer',
    searchModelFilterable: true,
    searchModelIdentifier: 'company_num_funding_rounds',
    sortType: 'number'
  },
  {
    field: 'company.last_funding_at',
    headerName: 'Last Funding Date',
    toggleable: true,
    width: 210,
    sortable: false,
    searchModelSortable: true,
    searchModelFilterable: true,
    searchModelIdentifier: 'company_last_funding_date',
    cellRenderer: 'FundingCellRenderer',
    sortType: 'date'
  },
  {
    field: 'company.last_funding_type',
    headerName: 'Last Funding Type',
    toggleable: true,
    cellRenderer: 'StageCellRenderer',
    sortable: false,
    searchModelFilterable: true,
    searchModelIdentifier: 'company_last_funding_type',
    searchModelSortable: true,
    sortType: 'text'
  },
  {
    field: 'company.last_funding_total',
    headerName: 'Last Funding Total',
    toggleable: true,
    sortable: false,
    cellRenderer: 'FundingCellRenderer',
    searchModelFilterable: true,
    searchModelIdentifier: 'company_last_funding_total',
    searchModelSortable: true,
    sortType: 'number'
  },
  {
    field: 'company.funding_total',
    headerName: 'Funding Total',
    toggleable: true,
    sortable: false,
    cellRenderer: 'FundingCellRenderer',
    searchModelFilterable: true,
    searchModelIdentifier: 'company_funding_total',
    sortType: 'number'
  },
  {
    field: 'company.funding_per_employee',
    headerName: 'Funding per Employee',
    toggleable: true,
    sortable: false,
    cellRenderer: 'FundingCellRenderer',
    searchModelFilterable: true,
    searchModelIdentifier: 'funding_per_employee',
    sortType: 'number'
  },
  {
    field: 'company.investors',
    headerName: 'Investors',
    toggleable: true,
    sortable: false,
    width: 375,
    searchModelFilterable: false,
    searchModelIdentifier: 'company_investors',
    cellRenderer: 'InvestorsCellRenderer'
  },
  /**
   * * * * * * * * * * * *
   * Tags                *
   * * * * * * * * * * * *
   */
  {
    field: 'company.customer_type',
    headerName: 'Customer Type',
    toggleable: true,
    sortable: false,
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: {
      values: ['B2B', 'B2C', 'B2B & B2C', null]
    },
    searchModelFilterable: true,
    searchModelIdentifier: 'company_customer_type',
    cellRenderer: 'CustomerTypeCellRenderer'
  },
  {
    field: 'company.industry_tags',
    headerName: 'Industry Tags',
    toggleable: true,
    sortable: false,
    hide: true,
    searchModelFilterable: true,
    minWidth: 400,
    searchModelIdentifier: 'company_industry_tags',
    cellRenderer: 'TagsCellRenderer'
  },
  {
    field: 'company.technology_tags',
    headerName: 'Technology Tags',
    toggleable: true,
    sortable: false,
    hide: true,
    searchModelFilterable: true,
    searchModelIdentifier: 'company_technology_tags',
    minWidth: 200,
    cellRenderer: 'TagsCellRenderer'
  },
  {
    field: 'company.accelerators',
    headerName: 'Accelerators',
    toggleable: true,
    sortable: false,
    hide: true,
    searchModelFilterable: false,
    searchModelIdentifier: 'company_accelerator_tags',
    cellRenderer: 'AcceleratorsCellRenderer'
  },
  /**
   * * * * * * * * * * * *
   * Contact             *
   * * * * * * * * * * * *
   */
  {
    field: 'company.company_emails',
    headerName: 'Company Emails',
    toggleable: true,
    width: 270,
    sortable: false,
    cellRenderer: 'EmailsCellRenderer'
  },
  {
    field: 'company.team_emails',
    headerName: 'Team Emails',
    toggleable: true,
    width: 270,
    sortable: false,
    cellRenderer: 'EmailsCellRenderer'
  },
  {
    field: 'company.contact_numbers',
    headerName: 'Phone Number',
    toggleable: true,
    width: 270,
    sortable: false,
    cellRenderer: 'PhoneNumbersCellRenderer',
    hide: true
  },
  /**
   * * * * * * * * * * * *
   * Social Links        *
   * * * * * * * * * * * *
   */
  {
    field: 'company.twitter_url',
    headerName: 'Twitter',
    toggleable: true,
    sortable: false,
    hide: false,
    width: 300,
    cellRenderer: 'LinkCellRenderer',
    searchModelFilterable: true,
    searchModelIdentifier: 'company_twitter_url'
  },
  {
    field: 'company.twitter_handle',
    headerName: 'Twitter Handle',
    toggleable: true,
    sortable: false,
    hide: false,
    width: 155,
    cellRenderer: 'LongTextCellRenderer'
  },
  {
    field: 'company.crunchbase_url',
    headerName: 'Crunchbase',
    toggleable: true,
    sortable: false,
    hide: false,
    width: 400,
    cellRenderer: 'LinkCellRenderer',
    searchModelFilterable: true,
    searchModelIdentifier: 'company_crunchbase_url'
  },
  {
    field: 'company.pitchbook_url',
    headerName: 'Pitchbook',
    toggleable: true,
    sortable: false,
    hide: false,
    width: 400,
    cellRenderer: 'LinkCellRenderer',
    searchModelFilterable: true,
    searchModelIdentifier: 'company_pitchbook_url'
  },
  {
    field: 'company.instagram_url',
    headerName: 'Instagram',
    toggleable: true,
    sortable: false,
    hide: false,
    width: 135,
    cellRenderer: 'LinkCellRenderer'
  },
  {
    field: 'company.facebook_url',
    headerName: 'Facebook',
    toggleable: true,
    sortable: false,
    hide: false,
    width: 300,
    cellRenderer: 'LinkCellRenderer'
  },
  {
    field: 'company.angellist_url',
    headerName: 'AngelList',
    toggleable: true,
    sortable: false,
    hide: false,
    width: 300,
    cellRenderer: 'LinkCellRenderer'
  },
  {
    field: 'company.indeed_url',
    headerName: 'Indeed',
    toggleable: true,
    sortable: false,
    hide: true,
    width: 300,
    cellRenderer: 'LinkCellRenderer'
  },
  {
    field: 'company.youtube_url',
    headerName: 'YouTube',
    toggleable: true,
    sortable: false,
    hide: true,
    width: 300,
    cellRenderer: 'LinkCellRenderer'
  },
  {
    field: 'company.monster_url',
    headerName: 'Monster',
    toggleable: true,
    sortable: false,
    hide: true,
    width: 300,
    cellRenderer: 'LinkCellRenderer'
  },
  {
    field: 'company.stackoverflow_url',
    headerName: 'StackOverflow',
    toggleable: true,
    sortable: false,
    hide: true,
    width: 300,
    cellRenderer: 'LinkCellRenderer'
  },
  /**
   * * * * * * * * * * * *
   * Social Data         *
   * * * * * * * * * * * *
   */
  {
    field: 'company.linkedin_follower_count',
    headerName: 'LinkedIn Followers',
    toggleable: true,
    sortable: false,
    hide: true,
    searchModelFilterable: true,
    searchModelIdentifier: 'company_linkedin_follower_count',
    cellRenderer: 'NumberCellRenderer'
  },
  {
    field: 'company.twitter_total_followers',
    headerName: 'Twitter Followers',
    toggleable: true,
    sortable: false,
    hide: true,
    cellRenderer: 'NumberCellRenderer'
  },
  {
    field: 'company.instagram_num_followers',
    headerName: 'Instagram Followers',
    toggleable: true,
    sortable: false,
    hide: true,
    cellRenderer: 'NumberCellRenderer'
  },
  {
    field: 'company.facebook_total_page_likes',
    headerName: 'Facebook Page Likes',
    toggleable: true,
    sortable: false,
    hide: true,
    cellRenderer: 'NumberCellRenderer'
  }
];

const GrowthMetricsHeaderMetadata = [
  {
    id: 'headcount',
    displayName: growthMetricsDisplayNames['headcount'],
    showByDefault: true,
    widthPadding: -20,
    sortType: 'number'
  },
  {
    id: 'headcount_advisor',
    displayName: growthMetricsDisplayNames['headcount_advisor'],
    showByDefault: false,
    widthPadding: -20,
    sortType: 'number'
  },
  {
    id: 'headcount_customer_success',
    displayName: growthMetricsDisplayNames['headcount_customer_success'],
    showByDefault: false,
    widthPadding: -20,
    sortType: 'number'
  },
  {
    id: 'headcount_data',
    displayName: growthMetricsDisplayNames['headcount_data'],
    showByDefault: false,
    isCurrentMetricValueMissing: true,
    widthPadding: -20,
    sortType: 'number'
  },
  {
    id: 'headcount_design',
    displayName: growthMetricsDisplayNames['headcount_design'],
    showByDefault: false,
    isCurrentMetricValueMissing: true,
    widthPadding: -20,
    sortType: 'number'
  },
  {
    id: 'headcount_engineering',
    displayName: growthMetricsDisplayNames['headcount_engineering'],
    showByDefault: false,
    isCurrentMetricValueMissing: true,
    widthPadding: -20,
    sortType: 'number'
  },
  {
    id: 'headcount_finance',
    displayName: growthMetricsDisplayNames['headcount_finance'],
    showByDefault: false,
    isCurrentMetricValueMissing: true,
    widthPadding: -20,
    sortType: 'number'
  },
  {
    id: 'headcount_legal',
    displayName: growthMetricsDisplayNames['headcount_legal'],
    showByDefault: false,
    isCurrentMetricValueMissing: true,
    widthPadding: -20,
    sortType: 'number'
  },
  {
    id: 'web_traffic',
    displayName: growthMetricsDisplayNames['web_traffic'],
    showByDefault: false,
    isCurrentMetricValueMissing: true,
    widthPadding: -20,
    sortType: 'number'
  },
  {
    id: 'headcount_marketing',
    displayName: growthMetricsDisplayNames['headcount_marketing'],
    showByDefault: false,
    isCurrentMetricValueMissing: true,
    widthPadding: -20,
    sortType: 'number'
  },
  {
    id: 'headcount_operations',
    displayName: growthMetricsDisplayNames['headcount_operations'],
    showByDefault: false,
    isCurrentMetricValueMissing: true,
    widthPadding: -20,
    sortType: 'number'
  },
  {
    id: 'headcount_other',
    displayName: growthMetricsDisplayNames['headcount_other'],
    showByDefault: false,
    isCurrentMetricValueMissing: true,
    widthPadding: -20,
    sortType: 'number'
  },
  {
    id: 'headcount_people',
    displayName: growthMetricsDisplayNames['headcount_people'],
    showByDefault: false,
    isCurrentMetricValueMissing: true,
    widthPadding: -20,
    sortType: 'number'
  },
  {
    id: 'headcount_product',
    displayName: growthMetricsDisplayNames['headcount_product'],
    showByDefault: false,
    isCurrentMetricValueMissing: true,
    widthPadding: -20,
    sortType: 'number'
  },
  {
    id: 'headcount_sales',
    displayName: growthMetricsDisplayNames['headcount_sales'],
    showByDefault: false,
    isCurrentMetricValueMissing: true,
    widthPadding: -20,
    sortType: 'number'
  },
  {
    id: 'headcount_support',
    displayName: growthMetricsDisplayNames['headcount_support'],
    showByDefault: false,
    isCurrentMetricValueMissing: true,
    widthPadding: -20,
    sortType: 'number'
  },
  {
    id: 'funding_total',
    displayName: growthMetricsDisplayNames['funding_total'],
    showByDefault: true,
    cellRenderer: 'FundingCellRenderer',
    sortType: 'number'
  },
  {
    id: 'funding_per_employee',
    displayName: growthMetricsDisplayNames['funding_per_employee'],
    showByDefault: true,
    cellRenderer: 'FundingCellRenderer',
    sortType: 'number'
  },
  {
    id: 'linkedin_follower_count',
    displayName: growthMetricsDisplayNames['linkedin_follower_count'],
    showByDefault: false,
    widthPadding: 20,
    sortType: 'number'
  },
  {
    id: 'facebook_page_like_count',
    displayName: growthMetricsDisplayNames['facebook_total_page_likes'],
    showByDefault: false,
    widthPadding: 20,
    sortType: 'number'
  }
];

export const GrowthMetricsHeaders = GrowthMetricsHeaderMetadata.flatMap(
  (growthMetric) => [
    ...(growthMetric.isCurrentMetricValueMissing
      ? []
      : [
          {
            field: `company.${growthMetric.id}`,
            headerName: `${growthMetric.displayName}`,
            toggleable: true,
            hide: !growthMetric.showByDefault,
            width: 150 + (growthMetric.widthPadding || 0),
            searchModelSortable: true,
            searchModelFilterable: true,
            searchModelIdentifier: `company_${growthMetric.id}`,
            cellRenderer:
              growthMetric.cellRenderer || 'DerivedMetricCellRenderer'
          }
        ]),
    {
      field: `company.traction_metrics.${growthMetric.id}.value_14d_ago`,
      headerName: `${growthMetric.displayName} (14d)`,
      toggleable: true,
      hide: true,
      width: 200 + (growthMetric.widthPadding || 0),
      searchModelSortable: false,
      searchModelFilterable: false,
      cellRenderer: growthMetric.cellRenderer || 'DerivedMetricCellRenderer',
      searchModelIdentifier: `company_${growthMetric.id}_value_14d_ago`
    },
    {
      field: `company.traction_metrics.${growthMetric.id}.real_change_14d_ago`,
      headerName: `${growthMetric.displayName} ∆ (14d)`,
      toggleable: true,
      hide: !growthMetric.showByDefault,
      width: 200 + (growthMetric.widthPadding || 0),
      searchModelSortable: true,
      searchModelFilterable: true,
      searchModelIdentifier: `company_${growthMetric.id}_real_change_14d_ago`,
      cellRenderer: 'DerivedMetricCellRenderer'
    },
    {
      field: `company.traction_metrics.${growthMetric.id}.percent_change_14d_ago`,
      headerName: `${growthMetric.displayName} % (14d)`,
      toggleable: true,
      hide: !growthMetric.showByDefault,
      width: 200 + (growthMetric.widthPadding || 0),
      searchModelSortable: true,
      searchModelFilterable: true,
      searchModelIdentifier: `company_${growthMetric.id}_percent_change_14d_ago`,
      cellRenderer: 'DerivedMetricCellRenderer'
    },
    {
      field: `company.traction_metrics.${growthMetric.id}.value_30d_ago`,
      headerName: `${growthMetric.displayName} (30d)`,
      toggleable: true,
      hide: true,
      width: 200 + (growthMetric.widthPadding || 0),
      searchModelSortable: false,
      searchModelFilterable: false,
      searchModelIdentifier: `company_${growthMetric.id}_value_30d_ago`,
      cellRenderer: growthMetric.cellRenderer || 'DerivedMetricCellRenderer'
    },
    {
      field: `company.traction_metrics.${growthMetric.id}.real_change_30d_ago`,
      headerName: `${growthMetric.displayName} ∆ (30d)`,
      toggleable: true,
      hide: !growthMetric.showByDefault,
      width: 200 + (growthMetric.widthPadding || 0),
      searchModelSortable: true,
      searchModelFilterable: true,
      searchModelIdentifier: `company_${growthMetric.id}_real_change_30d_ago`,
      cellRenderer: 'DerivedMetricCellRenderer'
    },
    {
      field: `company.traction_metrics.${growthMetric.id}.percent_change_30d_ago`,
      headerName: `${growthMetric.displayName} % (30d)`,
      toggleable: true,
      hide: !growthMetric.showByDefault,
      width: 200 + (growthMetric.widthPadding || 0),
      searchModelSortable: true,
      searchModelFilterable: true,
      searchModelIdentifier: `company_${growthMetric.id}_percent_change_30d_ago`,
      cellRenderer: 'DerivedMetricCellRenderer'
    },
    {
      field: `company.traction_metrics.${growthMetric.id}.value_90d_ago`,
      headerName: `${growthMetric.displayName} (90d)`,
      toggleable: true,
      hide: true,
      width: 200 + (growthMetric.widthPadding || 0),
      searchModelSortable: false,
      searchModelFilterable: false,
      searchModelIdentifier: `company_${growthMetric.id}_value_90d_ago`,
      cellRenderer: growthMetric.cellRenderer || 'DerivedMetricCellRenderer'
    },
    {
      field: `company.traction_metrics.${growthMetric.id}.real_change_90d_ago`,
      headerName: `${growthMetric.displayName} ∆ (90d)`,
      toggleable: true,
      hide: !growthMetric.showByDefault,
      width: 200 + (growthMetric.widthPadding || 0),
      searchModelSortable: true,
      searchModelFilterable: true,
      searchModelIdentifier: `company_${growthMetric.id}_real_change_90d_ago`,
      cellRenderer: 'DerivedMetricCellRenderer'
    },
    {
      field: `company.traction_metrics.${growthMetric.id}.percent_change_90d_ago`,
      headerName: `${growthMetric.displayName} % (90d)`,
      toggleable: true,
      hide: !growthMetric.showByDefault,
      width: 200 + (growthMetric.widthPadding || 0),
      searchModelSortable: true,
      searchModelFilterable: true,
      searchModelIdentifier: `company_${growthMetric.id}_percent_change_90d_ago`,
      cellRenderer: 'DerivedMetricCellRenderer'
    },
    {
      field: `company.traction_metrics.${growthMetric.id}.value_180d_ago`,
      headerName: `${growthMetric.displayName} (180d)`,
      toggleable: true,
      hide: true,
      width: 200 + (growthMetric.widthPadding || 0),
      searchModelSortable: false,
      searchModelFilterable: false,
      searchModelIdentifier: `company_${growthMetric.id}_value_180d_ago`,
      cellRenderer: growthMetric.cellRenderer || 'DerivedMetricCellRenderer'
    },
    {
      field: `company.traction_metrics.${growthMetric.id}.real_change_180d_ago`,
      headerName: `${growthMetric.displayName} ∆ (180d)`,
      toggleable: true,
      hide: !growthMetric.showByDefault,
      width: 200 + (growthMetric.widthPadding || 0),
      searchModelSortable: true,
      searchModelFilterable: true,
      searchModelIdentifier: `company_${growthMetric.id}_real_change_180d_ago`,
      cellRenderer: 'DerivedMetricCellRenderer'
    },
    {
      field: `company.traction_metrics.${growthMetric.id}.percent_change_180d_ago`,
      headerName: `${growthMetric.displayName} % (180d)`,
      toggleable: true,
      hide: !growthMetric.showByDefault,
      width: 200 + (growthMetric.widthPadding || 0),
      searchModelSortable: true,
      searchModelFilterable: true,
      searchModelIdentifier: `company_${growthMetric.id}_percent_change_180d_ago`,
      cellRenderer: 'DerivedMetricCellRenderer'
    },
    {
      field: `company.traction_metrics.${growthMetric.id}.value_365d_ago`,
      headerName: `${growthMetric.displayName} (365d)`,
      toggleable: true,
      hide: true,
      width: 200 + (growthMetric.widthPadding || 0),
      searchModelSortable: false,
      searchModelFilterable: false,
      searchModelIdentifier: `company_${growthMetric.id}_value_365d_ago`,
      cellRenderer: growthMetric.cellRenderer || 'DerivedMetricCellRenderer'
    },
    {
      field: `company.traction_metrics.${growthMetric.id}.real_change_365d_ago`,
      headerName: `${growthMetric.displayName} ∆ (365d)`,
      toggleable: true,
      hide: true,
      width: 200 + (growthMetric.widthPadding || 0),
      searchModelSortable: true,
      searchModelFilterable: true,
      searchModelIdentifier: `company_${growthMetric.id}_real_change_365d_ago`,
      cellRenderer: 'DerivedMetricCellRenderer'
    },
    {
      field: `company.traction_metrics.${growthMetric.id}.latest_metric_value`,
      headerName: `${growthMetric.displayName}`,
      toggleable: true,
      hide: true,
      width: 200 + (growthMetric.widthPadding || 0),
      searchModelSortable: true,
      searchModelFilterable: true,
      cellRenderer: 'DerivedMetricCellRenderer'
    },
    {
      field: `company.traction_metrics.${growthMetric.id}.percent_change_365d_ago`,
      headerName: `${growthMetric.displayName} % (365d)`,
      toggleable: true,
      hide: true,
      width: 200 + (growthMetric.widthPadding || 0),
      searchModelSortable: true,
      searchModelFilterable: true,
      searchModelIdentifier: `company_${growthMetric.id}_percent_change_365d_ago`,
      cellRenderer: 'DerivedMetricCellRenderer'
    }
  ]
);
