import { gql } from '@apollo/client';

export const getCompanyEmployees = gql`
  fragment GetCompanyEmployees_EmployeeEdges_Person on Person {
    id
    fullName
    firstName
    profilePictureUrl
    socials {
      linkedin {
        url
      }
    }
    highlights {
      text
      category
    }
    experience {
      title
      company {
        id
        name
        logoUrl
      }
      isCurrentPosition
      startDate
      endDate
    }
    education {
      school {
        name
      }
    }
    userConnections {
      user {
        name
        email
        profilePicUrl
      }
    }
  }

  fragment GetCompanyEmployees_EmployeeEdges on SearchResultNode {
    cursor
    node {
      ...GetCompanyEmployees_EmployeeEdges_Person
    }
  }

  query GetCompanyEmployees(
    $companyUrn: CompanyUrn!
    $page: Int!
    $size: Int!
    $employeeGroupType: EmployeeGroupType!
    $userConnectionStatus: UserConnectionStatus
  ) {
    getEmployeesByCompanyId(
      companyUrn: $companyUrn
      page: $page
      size: $size
      employeeGroupType: $employeeGroupType
      userConnectionStatus: $userConnectionStatus
    ) {
      totalCount
      edges {
        ...GetCompanyEmployees_EmployeeEdges
      }
    }
  }
`;
