import { ReactComponent as InfoIcon } from 'assets/bx-info-circle.svg';
import { ReactComponent as HarmonicLogo } from 'assets/harmonic-text-logo-new.svg';
import TrustedByCustomerLogos from 'components/common/TrustedByCustomerLogos';
import { FC, ReactNode } from 'react';
import styled from 'styled-components';

const AuthActionPageStyles = styled.div`
  font-family: 'Beausite', 'Helvetica Neue', 'Arial', 'sans-serif';
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;

  .heading {
    margin-top: 50px;
    width: 450px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 17px;

    @media only screen and (max-width: 530px) {
      width: 95vw;
    }

    .text {
      margin-right: 15px;
    }

    .link {
      color: var(--color-text);
      font-weight: 700;
      margin-right: 5px;
    }

    .arrow-forward-icon {
      font-size: 20px;
    }
  }

  .form-wrapper {
    box-sizing: border-box;
    border: 1px solid var(--color-border);
    width: 450px;
    padding: 20px 30px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    @media only screen and (max-width: 530px) {
      width: 95vw;
    }

    .avatar {
      margin-bottom: 20px;
      height: 50px;
      width: 50px;
    }

    .heading-text {
      margin-bottom: 35px;
      font-weight: 700;
      font-size: 25px;
      letter-spacing: 1px;
      color: var(--color-text-dark);

      @media only screen and (max-width: 530px) {
        font-size: 20px;
        margin-bottom: 20px;
      }
    }

    .heading-text--small {
      margin-bottom: 35px;
    }

    .heading-title--normal {
      font-weight: 700;
    }

    .heading-text--small {
      margin-bottom: 35px;
    }

    .form-input {
      margin-bottom: 20px;
      width: 100%;
      background-color: var(--color-grey);
      border-radius: 4px;

      &--error {
        border: 1px solid var(--color-red);

        label {
          color: var(--color-red);
        }

        .MuiFilledInput-root {
          background-color: var(--color-light-pink) !important;
        }
      }

      label {
        font-weight: 500;
      }

      input {
        font-size: 17px;
        font-family: var(--font-primary);
      }

      .MuiFilledInput-root {
        background-color: transparent;
        border-radius: 4px;
      }

      .MuiFilledInput-underline::after,
      .MuiFilledInput-underline::before {
        border-bottom: none;
      }
    }

    .privacy-desc {
      margin-top: 20px;
      font-size: 14px;
      line-height: 23px;

      span {
        font-weight: 700;
      }
    }

    .box-error {
      width: 100%;
      display: flex;
    }

    .alert-error {
      width: 100%;
      margin-bottom: 20px;
    }

    .report-problem-outlined-icon {
      font-size: 17px;
    }

    .circular-progress {
      color: white;
      margin-right: 10px;
    }

    .box-link {
      width: 100%;
      text-align: right;
    }

    .button-wrapper {
      margin-top: 10px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .MuiButtonBase-root.Mui-disabled {
        opacity: 0.5;
      }

      .form-button {
        padding: 8px 60px;
        color: white;
        background-color: var(--color-text-dark);
        text-transform: none;
        box-shadow: none;
      }
    }

    .privacy-desc {
      margin-top: 20px;
      font-size: 14px;
      line-height: 23px;

      span {
        font-weight: 700;
      }
    }

    .more-dec {
      margin-top: 20px;
      font-size: 12px;
      color: var(--color-text-grey);
    }

    /* validation */
    .validation-image {
      margin-top: 20px;

      img {
        height: 100px;
      }
    }

    .validation-text {
      font-size: 16px;
      text-align: center;
      line-height: 25px;
      margin: 25px 30px 10px 30px;

      span {
        text-decoration: underline;
        margin-right: 3px;
        margin-left: 3px;
      }
    }
  }

  .formHelperText {
    margin-left: 15px;
    margin-bottom: 15px;
    color: red;
  }

  .link-color {
    color: var(--color-text);
  }

  .padding-grid-link {
    margin-top: 8px;
    line-height: 23px;
  }

  .custom-link-dev {
    margin-right: 6px;
  }

  .margin-auto {
    margin: auto;
  }

  .m20 {
    margin-top: 20px;
  }

  .w100 {
    width: 100%;
  }
  .w-450 {
    width: 450px;
  }
`;

const AuthActionPageWrapper: FC<{
  className?: string;
  children: ReactNode;
  hideLogo?: boolean;
  description?: string;
}> = ({ children, className, description, hideLogo = false }) => {
  return (
    <AuthActionPageStyles className={`py-4 ${className}`}>
      {description && (
        <div className="px-8 w-450 bg-white rounded py-6 flex flex-row gap-1 justify-center">
          <InfoIcon width={15} />
          <div className="typography-label text-center text-ink-lighter  ">
            {description}
          </div>
        </div>
      )}
      {!hideLogo && (
        <a
          href="https://harmonic.ai/"
          className="heading flex justify-center py-2"
        >
          <HarmonicLogo className="w-52" />
        </a>
      )}
      {children}

      <div className="max-w-px-1000 mx-auto">
        <TrustedByCustomerLogos />
      </div>

      <div className="mx-auto text-center text-content-weak typography-label-default-strong leading-6">
        &copy; {new Date().getFullYear()} Harmonic AI, Inc · All rights reserved
      </div>
    </AuthActionPageStyles>
  );
};

export default AuthActionPageWrapper;
