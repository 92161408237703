import classNames from 'classnames';
import { HarmonicIcon } from './type';

const TextIcon: HarmonicIcon = ({ applyCurrentColor = true, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke="#7C8598"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M2.5 4.16667V2.5H8M13.5 4.16667V2.5H8M8 2.5V13.5M8 13.5H6.5M8 13.5H9.5"
      />
    </svg>
  );
};

export default TextIcon;
