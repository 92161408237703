import {
  FilterArrayComparator,
  FilterBooleanComparator,
  FilterComparator,
  FilterDateComparator,
  FilterListComparator,
  FilterNumberComparator,
  FilterStringComparator
} from '../interfaces/SearchModel/Search';
import { IExportHeaderField } from './grid';

export const FILTER_SESSION_STORE_VERSION_PREFIX = '';

export enum FrontendEnvironments {
  Production = 'PRODUCTION',
  Canary = 'CANARY',
  Staging = 'STAGING'
}

// HTTP Constants
export const HARMONIC_ASSETS_URL = 'https://assets.harmonic.ai';
export const GRAPHQL_DOCUMENT =
  'https://support.harmonic.ai/en/articles/5867759-graphql-endpoints';
export const HARMONIC_LINKEDIN_SYNC_DOC =
  'https://support.harmonic.ai/en/articles/8370461-linkedin-connections';
export const HARMONIC_PLAN_TIER_DOC = `https://support.harmonic.ai/en/articles/7993222-harmonic-tiers`;
export const HARMONIC_PEOPLE_ENRICHMENT_DOC = `https://support.harmonic.ai/en/articles/9028451-people-enrichment-new-fields`;
export const DOWNLOAD_EXTENSION_URL = 'https://harmonic.ai/extension/download';
export const HARMONIC_CORRECTED_HEADCOUNT_DOC =
  'https://support.harmonic.ai/en/articles/8567598-corrected-headcount';
export const KEYBOARD_SHORTCUT_SUPPORT_DOC =
  'https://support.harmonic.ai/en/articles/7178441-are-there-keyboard-shortcuts';
export const NETWORK_MAPPING_SUPPORT_DOC =
  'https://support.harmonic.ai/en/articles/9493265-network-mapping';

// V2 API
export const API_V2_SEARCH_FIELD_SPEC = 'search/searchFieldSpec';
export const API_V2_BULK_UPLOAD = 'bulkUpload';
export const API_V2_DATASTORE_USER_ROUTE = 'datastore/user';

// ROUTE CONSTANTS
export const DASHBOARD_ROUTE = '/dashboard';
export const COMPANIES_ROUTE = '/companies';
export const PEOPLE_ROUTE = '/people';
export const COMPANY_ROUTE = '/company';
export const PERSON_ROUTE = '/person';
export const PERSONS_ROUTE = '/persons';
export const WATCHLIST_ROUTE = '/watchlist';
export const PEOPLE_WATCHLIST_ROUTE = '/people_watchlist';
export const INVESTOR_ROUTE = '/investor';

export const RELEVANCE_SETTINGS_ROUTE = '/settings?t=relevance';
export const ONBOARDING_ROUTE = '/onboarding';
export const DEFAULT_DASHBOARD_ROUTE = '/dashboard/companies';
export const DEFAULT_PEOPLE_DASHBOARD_ROUTE = '/dashboard/people';
export const ACCEPT_PILOT_AGREEMENT_ROUTE = '/accept-pilot-agreement';
// FORMATS
export const DATE_FORMAT = 'MM/DD/YYYY';
export const GRAPHQL_EXPLORER_DOC_ROUTE = '/docs/graphql-reference/explorer';
export const COMPANY_FIELD_HEADER_SPEC: IExportHeaderField = {
  label: 'Company name',
  value: 'urn:harmonic:company_field:company_name'
};

// Screen size breakpoints.
export const SCREEN_SIZES = {
  XXL: 1440,
  XL: 1200,
  LG: 992,
  MD: 768,
  SM: 576,
  XS: 0
};

export const ComparatorToLabelMap: Record<FilterComparator, string> = {
  [FilterStringComparator.EXACT_MATCH]: 'Is exactly',
  [FilterArrayComparator.SUBSTRING_MATCH]: 'Contains',
  [FilterArrayComparator.NOT_SUBSTRING_MATCH]: 'Does not contain',
  [FilterStringComparator.TEXT_MATCH]: 'Contains',
  [FilterStringComparator.NOT_TEXT_MATCH]: 'Does not contain',
  [FilterStringComparator.IS_EMPTY]: 'Is empty',
  [FilterStringComparator.IS_NOT_EMPTY]: 'Is not empty',
  [FilterDateComparator.AFTER_DATE]: 'After date',
  [FilterDateComparator.BEFORE_DATE]: 'Before date',
  [FilterDateComparator.EXACT_DATE]: 'Exact date',
  [FilterDateComparator.IN_RANGE]: 'In range',
  [FilterDateComparator.IN_DAYS_AGO_RANGE]: 'In rolling range',
  [FilterDateComparator.NOT_IN_DAYS_AGO_RANGE]: 'Not in rolling range',
  [FilterNumberComparator.GREATER_THAN]: 'Greater than',
  [FilterNumberComparator.GREATER_THAN_OR_EQUALS]: 'Greater than or equals',
  [FilterNumberComparator.LESS_THAN]: 'Less than',
  [FilterNumberComparator.LESS_THAN_OR_EQUALS]: 'Less than or equals',
  [FilterNumberComparator.EQUALS]: 'Equals',
  [FilterNumberComparator.NOT_EQUALS]: 'Not equals',
  [FilterArrayComparator.ANY_OF]: 'Is any of',
  [FilterArrayComparator.NOT_ANY_OF]: 'Is none of',
  [FilterArrayComparator.ANY_OF_ARRAY]: 'Contains any of',
  [FilterArrayComparator.NOT_ANY_OF_ARRAY]: 'Contains none of',
  [FilterListComparator.IS_IN_LIST]: 'Is in list',
  [FilterListComparator.IS_NOT_IN_LIST]: 'Is not in list',
  [FilterArrayComparator.ALL_OF_ARRAY]: 'Contains all of',
  [FilterArrayComparator.ANY_MATCH]: 'Contains any of',
  [FilterArrayComparator.NONE_MATCH]: 'Contains none of',
  [FilterArrayComparator.ALL_MATCH]: 'Contains all of',
  [FilterBooleanComparator.IS]: 'Is',
  [FilterBooleanComparator.IS_NOT]: 'Is not'
};

export const YearComparatorToLabelMap: Record<
  FilterNumberComparator | FilterStringComparator.IS_EMPTY,
  string
> = {
  [FilterNumberComparator.EQUALS]: 'Is in',
  [FilterNumberComparator.NOT_EQUALS]: 'Not in',
  [FilterNumberComparator.LESS_THAN]: 'Before',
  [FilterNumberComparator.LESS_THAN_OR_EQUALS]: 'In or before',
  [FilterNumberComparator.GREATER_THAN]: 'After',
  [FilterNumberComparator.GREATER_THAN_OR_EQUALS]: 'In or after',
  [FilterDateComparator.IN_RANGE]: 'In between',
  [FilterStringComparator.IS_EMPTY]: 'Is empty'
};

export const COMPANY_GRID_EXPORT_MAX_COUNT = 10000; // max number of records in a file export
export const PEOPLE_GRID_EXPORT_MAX_COUNT = 10000; // max number of records in a file export for people data.
export const GENERIC_SEARCH_MAX = 25; // max count returned by generic search endpoint
export const COMPANIES_CSV_IMPORT_MAX = 1000; // max number of records in a csv import
export const PEOPLE_CSV_IMPORT_MAX = 5000; // max number of records in a csv import
export const NOT_FOUND_ERROR_MESSAGE =
  'The requested page was not found. Please try a different URL.';
export const SESSION_EXPIRED_ERROR_MESSAGE =
  'Your session has expired. Please log in again.';
export const ADD_SAVED_SEARCH_ERROR_MESSAGE =
  'An error occurred while creating this saved search. Please try again.';
export const ADD_SAVED_SEARCH_CONFLICT_ERROR_MESSAGE =
  'A saved search with this name already exists for your user. Please select a different name.';
export const ADD_COMPANY_WATCHLIST_ERROR_MESSAGE =
  'An error occurred while creating this company watchlist. Please try again.';
export const ADD_PEOPLE_WATCHLIST_ERROR_MESSAGE =
  'An error occurred while creating this people watchlist. Please try again.';
export const GENERIC_ACTION_ERROR_MESSAGE =
  'An error occurred while performing this action. Please try again later.';
export const NOTE_SAVE_ERROR =
  'An error occurred while saving this note. Please try again later.';

export const AUTOCOMPLETE_CHIP_ENTRY_PLACEHOLDER = "'Enter' after each term";

export const QUERY_PARAM_AUTO_EXPORT_CSV = 'autoExportCsv';

export const growthMetricsDisplayNames: Record<string, string> = {
  headcount: 'Headcount',
  headcount_total: 'All departments',
  department_headcounts: 'Headcount by Department',
  headcount_advisor: 'Advisor',
  headcount_customer_success: 'Customer Success',
  headcount_data: 'Data',
  headcount_design: 'Design',
  headcount_engineering: 'Engineering',
  headcount_finance: 'Finance',
  headcount_legal: 'Legal',
  headcount_marketing: 'Marketing',
  headcount_operations: 'Operations',
  headcount_other: 'Other',
  headcount_people: 'People',
  headcount_product: 'Product',
  headcount_sales: 'Sales',
  headcount_support: 'Support',
  funding_total: 'Funding Total',
  web_traffic: 'Web Traffic',
  funding_per_employee: 'Funding per Employee',
  twitter_total_followers: 'Twitter Followers',
  linkedin_follower_count: 'LinkedIn Followers',
  facebook_total_page_likes: 'Facebook Likes',
  instagram_num_followers: 'Instagram Followers'
};

export enum NotFoundMode {
  SAVED_SEARCH_FORBIDDEN = 'SAVED_SEARCH_FORBIDDEN',
  WATCHLIST_FORBIDDEN = 'WATCHLIST_FORBIDDEN',
  WATCHLIST_NOT_EXIST = 'WATCHLIST_NOT_EXIST',
  SAVED_SEARCH_ERROR = 'SAVED_SEARCH_ERROR',
  DEFAULT = 'DEFAULT',
  NO_RESULTS_SAVED_SEARCH = 'NO_RESULTS_SAVED_SEARCH',
  NO_RESULTS_WATCHLIST = 'NO_RESULTS_WATCHLIST',
  NO_RESULTS_PEOPLE_WATCHLIST = 'NO_RESULTS_PEOPLE_WATCHLIST',
  NO_RESULTS_CONNECTIONS = 'NO_RESULTS_CONNECTIONS',
  NO_CUSTOMER_INVESTOR_FOUND = 'NO_CUSTOMER_INVESTOR_FOUND'
}

export enum CompanyNotificationFieldType {
  FOUNDING_DATE = 'FOUNDING_DATE',
  FUNDING_STAGE = 'FUNDING_STAGE',
  LAST_FUNDING_DATE = 'LAST_FUNDING_DATE',
  LAST_FUNDING_TOTAL = 'LAST_FUNDING_TOTAL',
  FUNDING_TOTAL = 'FUNDING_TOTAL',
  INVESTORS = 'INVESTORS',
  LOCATION = 'LOCATION',
  HEADCOUNT = 'HEADCOUNT',
  HEADCOUNT_GROWTH_14D = 'HEADCOUNT_GROWTH_14D',
  HEADCOUNT_GROWTH_30D = 'HEADCOUNT_GROWTH_30D',
  HEADCOUNT_GROWTH_90D = 'HEADCOUNT_GROWTH_90D',
  HEADCOUNT_GROWTH_180D = 'HEADCOUNT_GROWTH_180D',
  WEBSITE = 'WEBSITE',
  LINKEDIN = 'LINKEDIN',
  INDUSTRY_TAGS = 'INDUSTRY_TAGS',
  TECHNOLOGY_TAGS = 'TECHNOLOGY_TAGS',
  CUSTOMER_TAGS = 'CUSTOMER_TAGS'
}

export enum PeopleNotificationFieldType {
  LINKEDIN_URL = 'LINKEDIN_URL',
  LOCATION = 'LOCATION',
  CURRENT_TITLE = 'CURRENT_TITLE',
  HEADLINE = 'HEADLINE',
  CURRENT_COMPANY = 'CURRENT_COMPANY'
}

export const CompanyNotificationFields = {
  [CompanyNotificationFieldType.FOUNDING_DATE]: 'Founding Date',
  [CompanyNotificationFieldType.FUNDING_STAGE]: 'Funding Stage',
  [CompanyNotificationFieldType.LAST_FUNDING_DATE]: 'Last Funding Date',
  [CompanyNotificationFieldType.LAST_FUNDING_TOTAL]: 'Last Funding Total',
  [CompanyNotificationFieldType.FUNDING_TOTAL]: 'Funding Total',
  [CompanyNotificationFieldType.INVESTORS]: 'Investors',
  [CompanyNotificationFieldType.LOCATION]: 'Location',
  [CompanyNotificationFieldType.HEADCOUNT]: 'Headcount',
  [CompanyNotificationFieldType.HEADCOUNT_GROWTH_14D]: 'Headcount Growth 14D',
  [CompanyNotificationFieldType.HEADCOUNT_GROWTH_30D]: 'Headcount Growth 30D',
  [CompanyNotificationFieldType.HEADCOUNT_GROWTH_90D]: 'Headcount Growth 90D',
  [CompanyNotificationFieldType.HEADCOUNT_GROWTH_180D]: 'Headcount Growth 180D',
  [CompanyNotificationFieldType.WEBSITE]: 'Website',
  [CompanyNotificationFieldType.LINKEDIN]: 'LinkedIn',
  [CompanyNotificationFieldType.INDUSTRY_TAGS]: 'Industry Tags',
  [CompanyNotificationFieldType.TECHNOLOGY_TAGS]: 'Technology Tags',
  [CompanyNotificationFieldType.CUSTOMER_TAGS]: 'Customer Tags'
};

export const PeopleNotificationFields = {
  [PeopleNotificationFieldType.LINKEDIN_URL]: 'LinkedIn URL',
  [PeopleNotificationFieldType.LOCATION]: 'Location',
  [PeopleNotificationFieldType.CURRENT_TITLE]: 'Current Title',
  [PeopleNotificationFieldType.HEADLINE]: 'Headline',
  [PeopleNotificationFieldType.CURRENT_COMPANY]: 'Current Company'
};

export enum HighlightCategory {
  DEEP_TECHNICAL_BACKGROUND = 'Deep Technical Background',
  TOP_COMPANY_ALUM = 'Top Company Alum',
  TOP_UNIVERSITY = 'Top University',
  HBCU_ALUM = 'HBCU Alum',
  VENTURE_BACKGROUND = 'Venture Background',
  CURRENT_STUDENT = 'Current Student',
  PRIOR_EXIT = 'Prior Exit',
  STRONG_TEAM_GROWTH = 'Strong Team Growth',
  ANGEL_BACKED = 'Angel Backed',
  VENTURE_BACKED = 'Venture Backed',
  MAJOR_HIRE = 'Major Hire',
  MAJOR_TECH_COMPANY_EXPERIENCE = 'Major Tech Company Experience',
  MAJOR_RESEARCH_INSTITUTION_EXPERIENCE = 'Major Research Institution Experience',
  PRIOR_VC_BACKED_FOUNDER = 'Prior VC Backed Founder',
  PRIOR_VC_BACKED_EXECUTIVE = 'Prior VC Backed Executive',
  FIVE_M_CLUB = '$5M Club',
  ELITE_INDUSTRY_EXPERIENCE = 'Elite Industry Experience',
  TEN_M_CLUB = '$10M Club',
  SEASONED_OPERATOR = 'Seasoned Operator',
  SEASONED_EXECUTIVE = 'Seasoned Executive',
  SEASONED_FOUNDER = 'Seasoned Founder',
  FIFTY_M_PLUS_CLUB = '$50M+ Club',
  YC_BACKED_FOUNDER = 'YC Backed Founder'
}

enum HighlightCategoryPrefix {
  SEASONED = 'Seasoned',
  MAJOR = 'Major',
  LEGACY = 'Legacy',
  ELITE = 'Elite',
  UNICORN = 'Unicorn',
  HIGH_GROWTH_STARTUP = 'High Growth Startup',
  PRIOR_VC_BACKED = 'Prior VC Backed',
  MONEY_CLUB = '$'
}

export const HighlightCategoryMap: Record<string, string> = {
  DEEP_TECHNICAL_BACKGROUND: 'Deep Technical Background',
  TOP_COMPANY_ALUM: 'Top Company Alum',
  TOP_UNIVERSITY: 'Top University',
  VENTURE_BACKGROUND: 'Venture Background',
  CURRENT_STUDENT: 'Current Student',
  PRIOR_EXIT: 'Prior Exit',
  SOONICORN: 'Soonicorn',
  STRONG_TEAM_GROWTH: 'Strong Team Growth',
  ANGEL_BACKED: 'Angel Backed',
  VENTURE_BACKED: 'Venture Backed',
  MAJOR_HIRE: 'Major Hire',
  SEASONED_OPERATOR: 'Seasoned Operator',
  SEASONED_EXECUTIVE: 'Seasoned Executive',
  SEASONED_FOUNDER: 'Seasoned Founder',
  SEASONED_ADVISER: 'Seasoned Adviser',
  UNICORN_EXECUTIVE: 'Unicorn Executive',
  UNICORN_OPERATOR: 'Unicorn Operator',
  UNICORN_FOUNDER: 'Unicorn Founder',
  UNICORN_ADVISER: 'Unicorn Adviser',
  HIGH_GROWTH_STARTUP_EXECUTIVE: 'High Growth Startup Executive',
  HIGH_GROWTH_STARTUP_OPERATOR: 'High Growth Startup Operator',
  HIGH_GROWTH_STARTUP_FOUNDER: 'High Growth Startup Founder',
  HIGH_GROWTH_STARTUP_ADVISER: 'High Growth Startup Adviser',
  PRIOR_VC_BACKED_EXECUTIVE: 'Prior VC Backed Executive',
  PRIOR_VC_BACKED_OPERATOR: 'Prior VC Backed Operator',
  PRIOR_VC_BACKED_FOUNDER: 'Prior VC Backed Founder',
  PRIOR_VC_BACKED_ADVISER: 'Prior VC Backed Adviser',
  HBCU_ALUM: 'HBCU Alum',
  HBCU_UNIVERSITY: 'HBCU University',
  UNICORN_ALUM: 'Unicorn Alum',
  MAJOR_TECH_COMPANY_EXPERIENCE: 'Major Tech Company Experience',
  LEGACY_TECH_COMPANY_EXPERIENCE: 'Legacy Tech Company Experience',
  ELITE_INDUSTRY_EXPERIENCE: 'Elite Industry Experience',
  MAJOR_RESEARCH_INSTITUTION_EXPERIENCE:
    'Major Research Institution Experience',
  MAJOR_GOVERNMENT_EXPERIENCE: 'Major Government Experience',
  MAJOR_FINANCIAL_INSTITUTION_EXPERIENCE:
    'Major Financial Institution Experience',
  '5_M_CLUB': '$5M Club',
  '10_M_CLUB': '$10M Club',
  '15_M_CLUB': '$15M Club',
  '20_M_CLUB': '$20M Club',
  '25_M_CLUB': '$25M Club',
  '30_M_CLUB': '$30M Club',
  '30_M_RAISED': '$30M Raised',
  '35_M_CLUB': '$35M Club',
  '40_M_CLUB': '$40M Club',
  '45_M_CLUB': '$45M Club',
  '50_M_CLUB': '$50M Club',
  '50_M_PLUS_CLUB': '$50M+ Club',
  '55_M_CLUB': '$55M Club',
  '60_M_CLUB': '$60M Club',
  '65_M_CLUB': '$65M Club',
  '70_M_CLUB': '$70M Club',
  '75_M_CLUB': '$75M Club',
  '80_M_CLUB': '$80M Club',
  '85_M_CLUB': '$85M Club',
  '90_M_CLUB': '$90M Club',
  '95_M_CLUB': '$95M Club',
  '100_M_CLUB': '$100M Club',
  '105_M_CLUB': '$105M Club',
  '110_M_CLUB': '$110M Club',
  '115_M_CLUB': '$115M Club',
  '120_M_CLUB': '$120M Club',
  '125_M_CLUB': '$125M Club',
  '130_M_CLUB': '$130M Club',
  '135_M_CLUB': '$135M Club',
  '140_M_CLUB': '$140M Club',
  '145_M_CLUB': '$145M Club',
  '150_M_CLUB': '$150M Club',
  '155_M_CLUB': '$155M Club',
  '160_M_CLUB': '$160M Club',
  '165_M_CLUB': '$165M Club',
  '170_M_CLUB': '$170M Club',
  '175_M_CLUB': '$175M Club',
  '180_M_CLUB': '$180M Club',
  '185_M_CLUB': '$185M Club',
  '190_M_CLUB': '$190M Club',
  '195_M_CLUB': '$195M Club',
  '200_M_CLUB': '$200M Club',
  '205_M_CLUB': '$205M Club',
  '210_M_CLUB': '$210M Club',
  '215_M_CLUB': '$215M Club',
  '220_M_CLUB': '$220M Club',
  '225_M_CLUB': '$225M Club',
  '230_M_CLUB': '$230M Club',
  '235_M_CLUB': '$235M Club',
  '240_M_CLUB': '$240M Club',
  '245_M_CLUB': '$245M Club',
  '250_M_CLUB': '$250M Club',
  '255_M_CLUB': '$255M Club',
  '260_M_CLUB': '$260M Club',
  '265_M_CLUB': '$265M Club',
  '270_M_CLUB': '$270M Club',
  '275_M_CLUB': '$275M Club',
  '280_M_CLUB': '$280M Club',
  '285_M_CLUB': '$285M Club',
  '290_M_CLUB': '$290M Club',
  '295_M_CLUB': '$295M Club',
  '300_M_CLUB': '$300M Club',
  FREE_AGENT: 'Free Agent',
  FOUNDER_TURNED_OPERATOR: 'Founder Turned Operator',
  STEALTH_FOUNDER: 'Stealth Founder',
  VESTED_FORMER_FOUNDER: 'Vested Former Founder',
  JACK_OF_ALL_TRADES: 'Jack of All Trades',
  YC_BACKED_FOUNDER: 'YC Backed Founder'
  // NOTE: New highlights should add a new entry here, update tests and add icon support if applicable.
};

export const highlightColors = {
  [HighlightCategory.DEEP_TECHNICAL_BACKGROUND]: '#FEE2D5',
  [HighlightCategory.TOP_COMPANY_ALUM]: '#EDE2FE',
  [HighlightCategory.TOP_UNIVERSITY]: '#EDE2FE',
  [HighlightCategory.VENTURE_BACKGROUND]: '#FEEBB6',
  [HighlightCategory.CURRENT_STUDENT]: '#EEEEEE',
  [HighlightCategory.PRIOR_EXIT]: '#C1F5E8',
  [HighlightCategory.STRONG_TEAM_GROWTH]: '#D0F0FC',
  [HighlightCategory.ANGEL_BACKED]: '#EDE2FE',
  [HighlightCategory.VENTURE_BACKED]: '#FEEBB6',
  [HighlightCategory.MAJOR_HIRE]: '#FEE2D5'
};

export const highlightPrefixColors = {
  [HighlightCategoryPrefix.SEASONED]: '#FEE2D5',
  [HighlightCategoryPrefix.MAJOR]: '#FEE2D5',
  [HighlightCategoryPrefix.LEGACY]: '#FEE2D5',
  [HighlightCategoryPrefix.ELITE]: '#FEE2D5',
  [HighlightCategoryPrefix.UNICORN]: '#EDE2FE',
  [HighlightCategoryPrefix.HIGH_GROWTH_STARTUP]: '#D0F0FC',
  [HighlightCategoryPrefix.PRIOR_VC_BACKED]: '#FEEBB6',
  [HighlightCategoryPrefix.MONEY_CLUB]: '#D0F8C3'
};

type IWeekDays = {
  day: string;
  value: number;
};

export const weekDays: IWeekDays[] = [
  { day: 'Sunday', value: 0 },
  { day: 'Monday', value: 1 },
  { day: 'Tuesday', value: 2 },
  { day: 'Wednesday', value: 3 },
  { day: 'Thursday', value: 4 },
  { day: 'Friday', value: 5 },
  { day: 'Saturday', value: 6 }
];

export const SUPPORT_EMAIL_ADDRESS = 'support@harmonic.ai';

export const SITE_STATUS_URL = 'https://status.harmonic.ai';

export const dummySearchData = [
  {
    title: 'Lorem ipsum dolor sit amet, consectetur',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus blandit euismod neque, dignissim. Non tristique donec',
    type: 'newTab'
  },
  {
    title: 'Lorem ipsum dolor sit amet, consectetur',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus blandit euismod neque, dignissim. Non tristique donec',
    type: 'section'
  },
  {
    title: 'Lorem ipsum dolor sit amet, consectetur',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus blandit euismod neque, dignissim. Non tristique donec',
    type: 'section'
  }
];

export enum PageTitle {
  COMPANY_PAGE_TITLE = 'Company Profile',
  PERSON_PAGE_TITLE = 'Person Profile',
  SAVED_SEARCH_PAGE_TITLE = 'Saved Search',
  WATCHLIST_PAGE_TITLE = 'Company Watchlist',
  COMPANIES_PAGE_TITLE = 'Explore Companies',
  PEOPLE_PAGE_TITLE = 'Explore People',
  API_REFERENCE_PAGE_TITLE = 'API Reference',
  API_USAGE_PAGE_TITLE = 'API Usage Dashboard',
  PERSON_WATCHLIST_PAGE_TITLE = 'Person Watchlist',
  INVESTOR_PAGE_TITLE = 'Investor Profile',
  LIST_PAGE_TITLE = 'Lists',
  SEARCH_PAGE_TITLE = 'Searches'
}

export const ENVIRONMENT = process.env.REACT_APP_DEPLOY_ENV;

export const GRID_INITIAL_PAGE_SIZE = 25;
export const GRID_MAX_SELECT_ALL_ROWS = 1000;
export const GRID_MAX_SELECT_ALL_ROWS_HIGHER_LIMIT = 10_000;

export const IS_PROD_LIKE_ENV =
  ENVIRONMENT === FrontendEnvironments.Production ||
  ENVIRONMENT === FrontendEnvironments.Canary;

export const LOCAL_AGAINST_PROD =
  process.env.REACT_APP_LOCAL_AGAINST_PROD === 'true';

export const PRODUCTION_ENV_BASE_URL = 'https://console.harmonic.ai';
export const CANARY_ENV_BASE_URL = 'https://console.canary.harmonic.ai';
const STAGING_ENV_BASE_URL = 'https://console.staging.harmonic.ai';

export const HASURA_API_URL = 'https://hasura.harmonic.ai';
export const HASURA_API_REST_PATH = '/api/rest';

export const BASE_URL =
  ENVIRONMENT === FrontendEnvironments.Production
    ? PRODUCTION_ENV_BASE_URL
    : ENVIRONMENT === FrontendEnvironments.Canary
    ? CANARY_ENV_BASE_URL
    : ENVIRONMENT === FrontendEnvironments.Staging
    ? STAGING_ENV_BASE_URL
    : 'https://localhost:3000';

export const ALL_SEARCHES_HIDDEN = 'hideAllSearches';

export const MAX_FILTER_DEPTH = 2;

// TODO: make this a configurable value from the backend.
// TODO: Figure out how to not need a code change whenever a customer changes from pilot to paid.
export const PILOT_LIST = ['urn:harmonic:user:2881'];

export const HARMONIC_CUSTOMER_URN = 'urn:harmonic:customer:1';

/**
 * List of known active Splits
 */
export enum SPLITS {
  showCopyQueryButton = 'show-copy-query-button',
  showBetaPersonHighlights = 'beta-person-highlights-enabled',
  showCompaniesViewToggle = 'show-companies-view-toggle',
  showUserConnectionsOnCompanyPage = 'show-user-connections-on-company-page',
  enableCompanyEngagementTab = 'enable-company-engagement-tab',
  enableCrmIntegration = 'enable-crm-tab-settings',
  enableNewStageGenerators = 'enable-new-stage-generators',
  enableAffinityWebhookSetup = 'enable-affinity-webhook-setup',
  enablePushToAffinity = 'enable-push-to-affinity',
  searchCopyJsonToClipboard = 'search-copy-json-to-clipboard',
  mockAffinityFrontend = 'mock-affinity-frontend',
  enableEditColumnsV2 = 'enable-edit-columns-v2',
  showCorrespondencesInSearch = 'show-correspondences-in-search',
  showCapTable = 'show-cap-table',
  showCapTableMock = 'show-cap-table-mock',
  showCapTableWaitlist = 'show-cap-table-waitlist',
  enableCustomColumns = 'enable-edit-column-field',
  enableInvestorProfilePage = 'investor-profile-page-enabled',
  showLineageOnCompanyPage = 'show-lineage-on-company-page',
  confirmOnDeleteFromList = 'confirm-on-delete-from-list',
  enableRevampedFoundingDateFilter = 'enable-revamped-founding-date-filters',
  enableVisibleColumnsGridLoading = 'enable-visible-columns-grid-loading',
  restrictNetworkMappingMetadataOnly = 'restrict-network-mapping-metadata-only',
  enableSelectAll = 'enable-select-all',
  enableFilterInList = 'enable-filter-in-list',
  enableLocalSearchInList = 'enable-local-search-in-list',
  enableKanbanView = 'enable-kanban-view',
  enableKanbanViewRefetch = 'enable-kanban-view-refetch',
  showReviewInvestorProfileBanner = 'show-review-investor-profile-banner',
  enableNetNew = 'enable-net-new',
  peopleStreamingExport = 'people-streaming-export',
  enableListColumnInSearch = 'enable-list-column-in-search',
  enablePeopleSort = 'enable-people-sort',
  selectAllUseHigherLimit = 'select-all-use-higher-limit',
  enableSortSaveInList = 'enable-sort-save-in-list',
  enableTruncatedListV2 = 'enable-truncated-list-v2'
}

export const API_MOCKING_ENABLED =
  process.env.REACT_APP_API_MOCKING === 'enabled';

export const EMPLOYEE_LIST_PAGE_SIZE = 8;

export const TAB_QUERY_PARAM_KEY = 'selectedTab';
export const COMPANY_TAB_INITIAL_SCROLL_TO_PARAM_KEY = 'initialScrollTo';

export const LOADING_ROW_ID = 'loading-row';
