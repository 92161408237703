import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const BookmarkIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
      fill="none"
      stroke="#7C8598"
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M12.8334 12.8748V3.83333C12.8334 2.72876 11.938 1.83333 10.8334 1.83333H5.16675C4.06218 1.83333 3.16675 2.72876 3.16675 3.83333V12.8748C3.16675 13.6755 4.06089 14.1515 4.72514 13.7044L6.8833 12.2517C7.55847 11.7972 8.44169 11.7972 9.11686 12.2517L11.275 13.7044C11.9393 14.1515 12.8334 13.6755 12.8334 12.8748Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BookmarkIcon;
