import classNames from 'classnames';
import { IconProps } from './type';

const GroupPeople: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="#7C8598"
      fillRule="evenodd"
      clipRule="evenodd"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M6.66632 10C5.03174 10 3.70542 10.6528 2.73556 11.7376C1.77841 12.8081 1.19023 14.272 0.940792 15.8887C0.803232 16.7803 1.52282 17.5 2.35447 17.5H10.9782C11.8098 17.5 12.5294 16.7803 12.3918 15.8887C12.1424 14.272 11.5542 12.8081 10.5971 11.7376C9.62722 10.6528 8.3009 10 6.66632 10Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M3.33309 5.83333C3.33309 3.99238 4.82547 2.5 6.66642 2.5C8.50737 2.5 9.99976 3.99238 9.99976 5.83333C9.99976 7.67428 8.50737 9.16667 6.66642 9.16667C4.82547 9.16667 3.33309 7.67428 3.33309 5.83333Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M11.2498 6.25C11.2498 4.63917 12.5556 3.33333 14.1664 3.33333C15.7773 3.33333 17.0831 4.63917 17.0831 6.25C17.0831 7.86083 15.7773 9.16667 14.1664 9.16667C12.5556 9.16667 11.2498 7.86083 11.2498 6.25Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M11.8396 10.6267C13.0692 12.002 13.7555 13.7967 14.0391 15.6346C14.0925 15.9809 14.0883 16.3128 14.0358 16.625H17.8073C18.6591 16.625 19.4125 15.8836 19.2497 14.9566C19.0086 13.5839 18.4769 12.3405 17.6248 11.4307C16.7608 10.5082 15.5926 9.95833 14.1663 9.95833C13.2671 9.95833 12.4705 10.1769 11.788 10.5696C11.8053 10.5885 11.8225 10.6076 11.8396 10.6267Z"
      />
    </svg>
  );
};

export default GroupPeople;
