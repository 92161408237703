import Modal, { ModalProps } from 'harmonic-components/Modal/Modal';
import React, { createContext, FC, ReactNode, useContext } from 'react';

interface ModalProviderProps {
  children: ReactNode;
}

interface ModalContextState {
  options: ModalOptions;
  isOpen: boolean;
  show: (component: ReactNode, options?: ModalOptions) => void;
  close: () => void;
  updateOptions: (newOptions: ModalOptions) => void;
}

interface ModalOptions
  extends Omit<ModalProps, 'onClose' | 'isOpen' | 'children'> {
  onClose?: () => void;
  showCloseBtn?: boolean;
}

const ModalContext = createContext<ModalContextState | undefined>(undefined);

const ModalComponent: FC<{ children: ReactNode }> = ({ children }) => {
  const { close, options, isOpen } = useModal();
  return (
    <Modal {...options} isOpen={isOpen} onClose={options.onClose || close}>
      {children}
    </Modal>
  );
};

const ModalProvider: FC<ModalProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [options, setOptions] = React.useState<ModalOptions>({});
  const [currentModalComponent, setCurrentModalComponent] = React.useState<
    ReactNode | undefined
  >();

  const show = (component: ReactNode, options?: ModalOptions) => {
    setCurrentModalComponent(component);
    setOptions(options ?? {});
    setIsOpen(true);
  };
  const close = () => {
    setIsOpen(false);
    setTimeout(() => {
      setCurrentModalComponent(undefined);
    }, 500);
  };

  // If you need to render modal options dyncamically in the body of the modal
  const updateOptions = (newOptions: ModalOptions) => {
    setOptions({ ...options, ...newOptions });
  };

  const state = {
    options,
    isOpen,
    show,
    close,
    updateOptions
  };

  return (
    <ModalContext.Provider value={state}>
      {children}
      <ModalComponent>{currentModalComponent}</ModalComponent>
    </ModalContext.Provider>
  );
};

export function useModal() {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within UseModalProvider');
  }
  return context;
}

export default ModalProvider;
