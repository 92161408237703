import { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import EmailVerifyHandler from '../../../components/Auth/EmailVerifyHandler';
import PasswordResetHandler from '../../../components/Auth/PasswordResetHandler';
import SignInFromEmailLinkHandler from '../../../components/Auth/SignInFromEmailLinkHandler';

/**
 * Page to receive and handle email verification, password reset, and email change links generated by Firebase
 * https://firebase.google.com/docs/auth/custom-email-handler
 */
const AuthActionHandler: FC = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  // Get the action to complete.
  const mode = urlParams.get('mode');
  // Get the one-time code from the query parameter.
  const actionCode = urlParams.get('oobCode') || '';

  if (actionCode == '') {
    return <Navigate to="/login" />;
  }

  // TODO: Implement case 'recoverEmail
  switch (mode) {
    case 'resetPassword':
      // Display reset password handler and UI.
      return <PasswordResetHandler actionCode={actionCode} />;

    case 'verifyEmail':
      // Display email verification handler and UI.
      return <EmailVerifyHandler actionCode={actionCode} />;

    case 'signIn':
      return <SignInFromEmailLinkHandler actionCode={actionCode} />;

    default:
      // Invalid mode, return to login
      return <Navigate to="/login" />;
  }
};
export default AuthActionHandler;
