import { gql } from '@apollo/client';

export const getCompanyProfileHeader = gql`
  query GetCompanyProfileHeader($id: Int!) {
    getCompanyById(id: $id) {
      id
      name
      logoUrl
      stage
      location {
        state
        city
        country
      }
      website {
        url
      }
      foundingDate {
        date
      }
      description
      tagsV2 {
        type
        displayValue
      }
      socials {
        linkedin {
          status
          url
          followerCount
        }
        crunchbase {
          status
          url
          followerCount
        }
        pitchbook {
          status
          url
          followerCount
        }
        angellist {
          status
          url
          followerCount
        }
        twitter {
          status
          url
          followerCount
        }
        facebook {
          status
          url
          likeCount
        }
        instagram {
          url
          followerCount
          status
        }
        indeed {
          url
          followerCount
          status
        }
        youtube {
          url
          followerCount
          status
        }
        monster {
          url
          followerCount
          status
        }
        stackoverflow {
          url
          followerCount
          status
        }
      }
      relevanceScore
      investorUrn
    }
  }
`;
