import { GetCompanyUserConnectionsV2_UserFragment } from '__generated__/graphql';
import { uniqBy } from 'lodash';
import { useSelector } from 'react-redux';
import { AppState } from 'store';

export interface ConnectionsRenderable {
  userName: string;
  person: GetCompanyUserConnectionsV2_UserFragment;
}

const MAX_NUM_USERS_TO_SHOW = 2;

export const useGroupedUserConnections = ({
  userConnections
}: {
  userConnections?: GetCompanyUserConnectionsV2_UserFragment[];
}) => {
  const currentUserEmail = useSelector<AppState, string>(
    (state) => state.auth.user?.email ?? ''
  );
  const userConnectionsToRender: ConnectionsRenderable[] = uniqBy(
    userConnections,
    (userConnection) => userConnection.email
  )
    .map((userConnection) => ({
      person: userConnection,
      userName:
        userConnection.email === currentUserEmail
          ? 'You'
          : userConnection.name ?? userConnection.email ?? ''
    }))
    .sort((a) => (a.userName === 'You' ? -1 : 1));

  if (userConnectionsToRender.length == 0) {
    return;
  }

  const groupedUserConnectionsMap: Record<string, ConnectionsRenderable[]> = {};
  userConnectionsToRender.forEach((connection) => {
    if (!(connection.userName in groupedUserConnectionsMap)) {
      groupedUserConnectionsMap[connection.userName] = [];
    }
    groupedUserConnectionsMap[connection.userName].push(connection);
  });

  const groupedUserConnectionsToRender: ConnectionsRenderable[][] =
    Object.values(groupedUserConnectionsMap);

  const connectionsToShowWithoutHover = groupedUserConnectionsToRender.slice(
    0,
    MAX_NUM_USERS_TO_SHOW
  );
  const connectionsToShowBehindHover = groupedUserConnectionsToRender.slice(
    MAX_NUM_USERS_TO_SHOW
  );

  return {
    userConnectionsToRender,
    groupedUserConnectionsToRender,
    connectionsToShowWithoutHover,
    connectionsToShowBehindHover
  };
};
