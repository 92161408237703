import { CalendarCheckIcon } from 'assets/harmonic-icons';
import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import './datepicker.css';

import classNames from 'classnames';
import dayjs from 'dayjs';
import ListItem, { ListVariant } from 'harmonic-components/ListItem/ListItem';
import { useOutsideClick } from 'utils/hooks';
import DatePickerHeader from './DatePickerHeader';
import DatePickerInput from './DatePickerInput';

export enum InputDateFormat {
  DD_MM_YYYY = 'DD_MM_YYYY',
  MM_DD_YYYY = 'MM_DD_YYYY',
  YYYY_MM_DD = 'YYYY_MM_DD',
  'YYYY-MM-DD' = 'YYYY-MM-DD'
}
const ListDateFormatMap = {
  [InputDateFormat.DD_MM_YYYY]: 'dd/MM/yyyy',
  [InputDateFormat.MM_DD_YYYY]: 'MM/dd/yyyy',
  [InputDateFormat.YYYY_MM_DD]: 'yyyy/MM/dd',
  [InputDateFormat['YYYY-MM-DD']]: 'yyyy-MM-dd' // if we are using input type='date', this is the only acceptable format
};

const ListDateFormatPlaceholderMap = {
  [InputDateFormat.DD_MM_YYYY]: 'DD/MM/YYYY',
  [InputDateFormat.MM_DD_YYYY]: 'MM/DD/YYYY',
  [InputDateFormat.YYYY_MM_DD]: 'YYYY/MM/DD',
  [InputDateFormat['YYYY-MM-DD']]: 'YYYY-MM-DD'
};

export interface SingleDatePickerProps {
  selectedDate: string | null | undefined;
  fullWidth?: boolean;
  onChange: (date: string | null) => void;
  minHeight?: number;
  initiallyOpen?: boolean;
  alwaysOpen?: boolean;
  autoFocus?: boolean;
  dateFormat?: InputDateFormat;
  native?: boolean;
}

const SingleDatePicker: React.FC<SingleDatePickerProps> = ({
  selectedDate,
  onChange,
  fullWidth,
  minHeight,
  initiallyOpen = false,
  alwaysOpen = false,
  autoFocus = false,
  native = true,
  dateFormat = InputDateFormat['YYYY-MM-DD']
}) => {
  const [isOpen, setIsOpen] = useState(initiallyOpen);

  const calendarRef = useOutsideClick(() => {
    setIsOpen(false);
  });

  const onDateChange = (date: Date | null) => {
    onChange(date ? dayjs(date).format('YYYY-MM-DD') : null);
    setIsOpen(false);
  };

  selectedDate = selectedDate ? dayjs(selectedDate).toString() : null;

  const isOpenState = alwaysOpen || isOpen;
  return (
    <div className={classNames(fullWidth && 'w-full')} ref={calendarRef}>
      <ReactDatePicker
        //eslint-disable-next-line
        //@ts-ignore
        selected={selectedDate as Date | null}
        open={isOpenState}
        onChange={(date) => onDateChange(date)}
        onInputClick={() => setIsOpen(true)}
        onClickOutside={() => setIsOpen(false)}
        dateFormat={ListDateFormatMap[dateFormat]}
        calendarClassName="shadow-static-elevation-floating flex flex-col datepicker-calendar"
        showPopperArrow={false}
        wrapperClassName={classNames(fullWidth && 'w-full')}
        renderCustomHeader={({ ...props }) => {
          return <DatePickerHeader {...props} selectedDate={selectedDate} />;
        }}
        customInput={
          //eslint-disable-next-line
          //@ts-ignore
          <DatePickerInput
            autoFocus={autoFocus}
            initialFocus={initiallyOpen || alwaysOpen}
            minHeight={minHeight}
            native={native}
            inputPlaceholder={ListDateFormatPlaceholderMap[dateFormat]}
          />
        }
      >
        <div
          data-testid="Calendar-Action-Btn"
          className="border-t border-solid border-border p-p30"
        >
          <ListItem
            variant={ListVariant.default}
            primaryIcon={CalendarCheckIcon}
            value="Today"
            label="Today"
            onClick={() => {
              onDateChange(new Date());
            }}
          />
        </div>
      </ReactDatePicker>
    </div>
  );
};

export default SingleDatePicker;
