import { IconProps } from './type';

const BxsCalculatorIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H32C34.2091 0 36 1.79086 36 4V32C36 34.2091 34.2091 36 32 36H4C1.79086 36 0 34.2091 0 32V4Z"
        fill="#F0F4E1"
        vectorEffect="non-scaling-stroke"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        vectorEffect="non-scaling-stroke"
        d="M13.6247 9.66669C12.359 9.66669 11.333 10.6927 11.333 11.9584V24.0417C11.333 25.3073 12.359 26.3334 13.6247 26.3334H22.3747C23.6403 26.3334 24.6663 25.3073 24.6663 24.0417V11.9584C24.6663 10.6927 23.6403 9.66669 22.3747 9.66669H13.6247ZM23.4163 13.8334V11.9584C23.4163 11.3831 22.95 10.9167 22.3747 10.9167H13.6247C13.0494 10.9167 12.583 11.3831 12.583 11.9584V13.8334H23.4163ZM15.9163 19.0417C16.3766 19.0417 16.7497 18.6686 16.7497 18.2084C16.7497 17.7481 16.3766 17.375 15.9163 17.375C15.4561 17.375 15.083 17.7481 15.083 18.2084C15.083 18.6686 15.4561 19.0417 15.9163 19.0417ZM15.9163 22.7917C16.3766 22.7917 16.7497 22.4186 16.7497 21.9584C16.7497 21.4981 16.3766 21.125 15.9163 21.125C15.4561 21.125 15.083 21.4981 15.083 21.9584C15.083 22.4186 15.4561 22.7917 15.9163 22.7917ZM20.9163 18.2084C20.9163 18.6686 20.5432 19.0417 20.083 19.0417C19.6228 19.0417 19.2497 18.6686 19.2497 18.2084C19.2497 17.7481 19.6228 17.375 20.083 17.375C20.5432 17.375 20.9163 17.7481 20.9163 18.2084ZM20.083 22.7917C20.5432 22.7917 20.9163 22.4186 20.9163 21.9584C20.9163 21.4981 20.5432 21.125 20.083 21.125C19.6228 21.125 19.2497 21.4981 19.2497 21.9584C19.2497 22.4186 19.6228 22.7917 20.083 22.7917Z"
        fill="#272D10"
      />
    </svg>
  );
};

export default BxsCalculatorIcon;
