import classNames from 'classnames';
import { HarmonicIcon } from './type';

const FacebookIcon: HarmonicIcon = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#7C8598"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M8.93127 13.998V8.53401H10.7746L11.0486 6.39468H8.93127V5.03201C8.93127 4.41467 9.10327 3.99201 9.98927 3.99201H11.1119V2.08468C10.5659 2.02601 10.0166 1.99801 9.46727 2.00001C7.83794 2.00001 6.71927 2.99467 6.71927 4.82067V6.39068H4.88794V8.53001H6.72327V13.998H8.93127Z"
      />
    </svg>
  );
};

export default FacebookIcon;
