import { gql } from '@apollo/client';

export const LIKE_COMPANIES = gql`
  mutation LikeCompanies($companies: [CompanyUrn]!) {
    likeCompanies(companies: $companies) {
      isLikedByCurrentUser
      id
    }
  }
`;

export const UNLIKE_COMPANIES = gql`
  mutation UnlikeCompanies($companies: [CompanyUrn]!) {
    unlikeCompanies(companies: $companies) {
      isLikedByCurrentUser
      id
    }
  }
`;

export const LIKE_COMPANIES_MOCKED_RESPONSE = {
  data: {
    likeCompanies: [
      {
        isLikedByCurrentUser: true,
        id: 1,
        __typename: 'Company'
      }
    ]
  }
};

export const UNLIKE_COMPANIES_MOCKED_RESPONSE = {
  data: {
    unlikeCompanies: [
      {
        isLikedByCurrentUser: false,
        id: 1,
        __typename: 'Company'
      }
    ]
  }
};
