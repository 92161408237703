import classNames from 'classnames';
import { IconProps } from './type';

const DollarIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#493AA6"
      fillRule="evenodd"
      clipRule="evenodd"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M1.3335 7.99992C1.3335 4.31802 4.31826 1.33325 8.00016 1.33325C11.6821 1.33325 14.6668 4.31802 14.6668 7.99992C14.6668 11.6818 11.6821 14.6666 8.00016 14.6666C4.31826 14.6666 1.3335 11.6818 1.3335 7.99992ZM8.66683 3.99927C8.66683 3.63108 8.36835 3.3326 8.00016 3.3326C7.63197 3.3326 7.3335 3.63108 7.3335 3.99927C7.3335 4.23119 7.16801 4.42556 6.95081 4.50687C6.75075 4.58177 6.56207 4.68367 6.39196 4.81778C5.917 5.19224 5.67558 5.74789 5.67558 6.4005C5.67558 7.1633 5.98948 7.69448 6.47076 8.04894C6.84687 8.32594 7.32111 8.48418 7.67627 8.6027C7.71434 8.6154 7.75106 8.62765 7.78613 8.63954L7.85444 8.66267C8.26732 8.80242 8.54051 8.89488 8.73888 9.04098C8.88316 9.14724 8.99142 9.28199 8.99142 9.61583C8.99142 9.92044 8.89254 10.065 8.78286 10.1515C8.64865 10.2573 8.40042 10.3496 8.00016 10.3496C7.52141 10.3496 7.23591 10.135 7.09608 9.88395C6.91689 9.5623 6.51088 9.44682 6.18924 9.626C5.86759 9.80519 5.75211 10.2112 5.93129 10.5328C6.16211 10.9472 6.51943 11.2756 6.96815 11.4737C7.17662 11.5658 7.3335 11.7595 7.3335 11.9874V11.9999C7.3335 12.3681 7.63197 12.6666 8.00016 12.6666C8.36835 12.6666 8.66683 12.3681 8.66683 11.9999C8.66683 11.778 8.82502 11.5917 9.03344 11.5154C9.23947 11.44 9.43371 11.3362 9.60836 11.1985C10.0833 10.8241 10.3248 10.2684 10.3248 9.61583C10.3248 8.85303 10.0108 8.32185 9.52957 7.96739C9.15345 7.69039 8.67921 7.53214 8.32405 7.41363C8.28598 7.40093 8.24927 7.38868 8.2142 7.37679L8.14588 7.35366C7.733 7.21391 7.45982 7.12145 7.26145 6.97535C7.11716 6.86909 7.00891 6.73434 7.00891 6.4005C7.00891 6.09589 7.10779 5.9513 7.21747 5.86484C7.35168 5.75902 7.59991 5.6667 8.00016 5.6667C8.47892 5.6667 8.76442 5.88138 8.90425 6.13238C9.08344 6.45403 9.48944 6.56951 9.81109 6.39033C10.1327 6.21114 10.2482 5.80514 10.069 5.48349C9.83822 5.06917 9.4809 4.74069 9.03218 4.5426C8.82371 4.45057 8.66683 4.25681 8.66683 4.02893V3.99927Z"
      />
    </svg>
  );
};

export default DollarIcon;
