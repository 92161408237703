import classNames from 'classnames';
import { IconProps } from './type';

const PeopleCopyIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#493AA6"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M2.50016 1.33325C1.85583 1.33325 1.3335 1.85559 1.3335 2.49992V10.8333C1.3335 11.4776 1.85583 11.9999 2.50016 11.9999H3.50016C3.77631 11.9999 4.00016 12.2238 4.00016 12.4999V13.4999C4.00016 14.1443 4.5225 14.6666 5.16683 14.6666H13.5002C14.1445 14.6666 14.6668 14.1443 14.6668 13.4999V5.16659C14.6668 4.52225 14.1445 3.99992 13.5002 3.99992H12.5002C12.224 3.99992 12.0002 3.77606 12.0002 3.49992V2.49992C12.0002 1.85559 11.4778 1.33325 10.8335 1.33325H2.50016ZM5.16683 3.99992H10.5002C10.7763 3.99992 11.0002 3.77606 11.0002 3.49992V2.49992C11.0002 2.40787 10.9255 2.33325 10.8335 2.33325H2.50016C2.40812 2.33325 2.3335 2.40787 2.3335 2.49992V10.8333C2.3335 10.9253 2.40812 10.9999 2.50016 10.9999H3.50016C3.77631 10.9999 4.00016 10.7761 4.00016 10.4999V5.16659C4.00016 4.52225 4.5225 3.99992 5.16683 3.99992ZM9.3335 10.9999C7.95647 10.9999 6.7616 11.9076 6.16647 13.237C6.0737 13.4442 6.23394 13.6666 6.46098 13.6666H12.206C12.4331 13.6666 12.5933 13.4442 12.5005 13.237C11.9054 11.9076 10.7105 10.9999 9.3335 10.9999ZM9.3335 9.99992C10.254 9.99992 11.0002 9.25373 11.0002 8.33325C11.0002 7.41278 10.254 6.66659 9.3335 6.66659C8.41302 6.66659 7.66683 7.41278 7.66683 8.33325C7.66683 9.25373 8.41302 9.99992 9.3335 9.99992Z"
      />
    </svg>
  );
};

export default PeopleCopyIcon;
