import classNames from 'classnames';
import { HarmonicIcon } from './type';

const Teamicon: HarmonicIcon = ({ applyCurrentColor = true, className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#7C8598"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M7.99967 1.33333C6.34282 1.33333 4.99967 2.67647 4.99967 4.33333C4.99967 5.99018 6.34282 7.33333 7.99967 7.33333C9.65653 7.33333 10.9997 5.99018 10.9997 4.33333C10.9997 2.67647 9.65653 1.33333 7.99967 1.33333Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M7.99994 8.33333C5.21774 8.33333 3.18197 10.1957 2.62648 12.6675C2.46005 13.408 3.06643 14 3.73222 14H12.2677C12.9334 14 13.5398 13.408 13.3734 12.6675C12.8179 10.1957 10.7821 8.33333 7.99994 8.33333Z"
      />
    </svg>
  );
};

export default Teamicon;
