import { FC } from 'react';

const PersonOutlineIcon: FC = () => {
  return (
    <svg width="17" height="16" fill="none" viewBox="0 0 17 16">
      <path
        fill="currentColor"
        d="M8.2 1.333a3.337 3.337 0 00-3.333 3.334A3.337 3.337 0 008.2 8a3.337 3.337 0 003.333-3.333A3.337 3.337 0 008.2 1.333zm0 5.334c-1.103 0-2-.898-2-2 0-1.103.897-2 2-2s2 .897 2 2c0 1.102-.897 2-2 2zm6 7.333v-.667a4.673 4.673 0 00-4.667-4.666H6.867A4.672 4.672 0 002.2 13.333V14h1.333v-.667A3.337 3.337 0 016.867 10h2.666a3.337 3.337 0 013.334 3.333V14H14.2z"
      ></path>
    </svg>
  );
};

export default PersonOutlineIcon;
