import classNames from 'classnames';
import { IconProps } from './type';

const CircleQuestionmarkIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke="#484E5B"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
    >
      <circle cx="8.00004" cy="8.00001" r="5.91667" strokeWidth="1.5" />
      <path
        vectorEffect="non-scaling-stroke"
        d="M6.66663 6.42425V6.33334C6.66663 5.78106 7.11434 5.33334 7.66663 5.33334H8.33329C8.88558 5.33334 9.33329 5.78106 9.33329 6.33334V6.60258C9.33329 6.94448 9.15862 7.26268 8.87017 7.44624L8.46308 7.70529C8.17464 7.88885 7.99996 8.20706 7.99996 8.54896V9.00001"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M7.83337 10.6667C7.83337 10.5746 7.90799 10.5 8.00004 10.5C8.09209 10.5 8.16671 10.5746 8.16671 10.6667C8.16671 10.7587 8.09209 10.8333 8.00004 10.8333C7.90799 10.8333 7.83337 10.7587 7.83337 10.6667Z"
      />
    </svg>
  );
};

export default CircleQuestionmarkIcon;
