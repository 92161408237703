import classNames from 'classnames';
import { IconProps } from './type';

const ArrowsRepeatCircleIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#7C8598"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.61772 0.489665C6.89957 0.186132 7.37412 0.168556 7.67766 0.450408L10.011 2.61707C10.1638 2.75898 10.2507 2.95812 10.2507 3.16667C10.2507 3.37522 10.1638 3.57436 10.011 3.71626L7.67766 5.88293C7.37412 6.16478 6.89957 6.14721 6.61772 5.84368C6.33587 5.54014 6.35345 5.06559 6.65698 4.78374L7.59075 3.91667H6.66732C4.41215 3.91667 2.58398 5.74484 2.58398 8C2.58398 9.23073 3.12753 10.3336 3.99022 11.0834C4.30286 11.3551 4.33603 11.8288 4.06432 12.1415C3.7926 12.4541 3.31889 12.4873 3.00625 12.2156C1.82971 11.193 1.08398 9.68317 1.08398 8C1.08398 4.91641 3.58373 2.41667 6.66732 2.41667H7.59075L6.65698 1.5496C6.35345 1.26775 6.33587 0.793198 6.61772 0.489665ZM11.9377 3.85899C12.2095 3.5464 12.6832 3.51331 12.9958 3.78508C14.1719 4.80761 14.9173 6.31719 14.9173 8C14.9173 11.0836 12.4176 13.5833 9.33398 13.5833H8.41055L9.34432 14.4504C9.64786 14.7323 9.66543 15.2068 9.38358 15.5103C9.10173 15.8139 8.62718 15.8315 8.32365 15.5496L5.99031 13.3829C5.83749 13.241 5.75065 13.0419 5.75065 12.8333C5.75065 12.6248 5.83749 12.4257 5.99031 12.2837L8.32365 10.1171C8.62718 9.83522 9.10173 9.8528 9.38358 10.1563C9.66543 10.4599 9.64786 10.9344 9.34432 11.2163L8.41055 12.0833H9.33398C11.5891 12.0833 13.4173 10.2552 13.4173 8C13.4173 6.76954 12.874 5.66684 12.0116 4.91707C11.699 4.6453 11.6659 4.17158 11.9377 3.85899Z"
        fill=""
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default ArrowsRepeatCircleIcon;
