export enum ReportSourceView {
  COMPANY = 'COMPANY',
  PERSON = 'PERSON',
  INVESTOR = 'INVESTOR',
  COMPANY_SEARCH = 'COMPANY_SEARCH',
  PERSON_SEARCH = 'PERSON_SEARCH',
  COMPANY_LIST = 'COMPANY_LIST',
  PERSON_LIST = 'PERSON_LIST'
}
export enum ReportSourceSection {
  FINANCING = 'FINANCING',
  CAP_TABLE = 'CAP_TABLE',
  OVERVIEW = 'OVERVIEW',
  TEAM = 'TEAM',
  SIMILAR_COMPANIES = 'SIMILAR_COMPANIES',
  TRACTION_METRICS = 'TRACTION_METRICS',
  CONTACT = 'CONTACT',
  CONNECTIONS = 'CONNECTIONS',
  LINEAGE = 'LINEAGE',
  INVESTMENTS = 'INVESTMENTS',
  CO_INVESTORS = 'CO_INVESTORS',
  DETAILS = 'DETAILS'
}

export interface UserReportedDataIssueInput {
  companyUrn?: string;
  personUrn?: string;
  reportSourceView: ReportSourceView;
  reportSourceSection: ReportSourceSection;
  reportText: string;
  reportDetails?: Record<string, any>;
}

export interface InvestorFundingRoundCorrectionReportDetails {
  fundingRoundUrn?: string;
  companyName?: string;
  companyUrn?: string;
  stageInvested?: string;
  dealDate?: string;
  roundSize?: string;
  postMoneyValuation?: string;
  otherNotes?: string;
  isRoundLead?: boolean;
}

export interface NewInvestorFundingRoundReportDetails
  extends InvestorFundingRoundCorrectionReportDetails {
  uniqueId: string;
  companyDomain: string;
}

export interface InvestorFundingRoundCorrectionReportDetailsPayload {
  existing: InvestorFundingRoundCorrectionReportDetails;
  corrected: InvestorFundingRoundCorrectionReportDetails;
}

export interface InvestorProfileCorrectionReportDetails {
  aum?: string;
  checkSizeRangeStart?: string;
  checkSizeRangeEnd?: string;
  otherNotes?: string;
}

export interface InvestorProfileCorrectionReportDetailsPayload {
  existing: InvestorProfileCorrectionReportDetails;
  corrected: InvestorProfileCorrectionReportDetails;
}
