import React from 'react';

const CompanyIconButtonActionLoader: React.FC = () => {
  return (
    <div
      className="w-9 h-9 rounded-br20 bg-slate-200 animate-pulse"
      data-testid="CompanyIconButtonActionLoader"
    ></div>
  );
};

export default CompanyIconButtonActionLoader;
