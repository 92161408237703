import { CircularProgress, Link } from '@material-ui/core';
import AuthActionPageWrapper from 'components/Auth/AuthActionPageWrapper';
import { FirebaseError, getApp } from 'firebase/app';
import { applyActionCode, getAuth } from 'firebase/auth';
import * as React from 'react';
import { logger } from 'utils/logger';

enum VerificationStates {
  VERIFIED,
  EXPIRED,
  ERROR
}

interface IProps {
  actionCode: string;
}

const EmailVerifyHandler: React.FC<IProps> = (props: IProps) => {
  const { actionCode } = props;
  const auth = getAuth(getApp());

  const [loading, setLoading] = React.useState(true);
  const [verificationState, setVerificationState] = React.useState<
    VerificationStates | undefined
  >(undefined);

  const processEmailVerificationCode = React.useCallback(async () => {
    try {
      // Attempt to apply email verification using Firebase
      await applyActionCode(auth, actionCode);
      // Email address has been verified.
      setVerificationState(VerificationStates.VERIFIED);
    } catch (error) {
      const firebaseError = error as FirebaseError | undefined;
      switch (firebaseError && firebaseError.code) {
        case 'auth/expired-action-code':
          setVerificationState(VerificationStates.EXPIRED);
          break;
        case 'auth/invalid-action-code':
          // This error from Firebase can mean two things:
          //   1. The code is malformed
          //   2. The code was already verified
          // Case #2 is way more probably to happen than #1, that is why we treat it
          // as such so the user have valid feedback in the majority of the cases.
          setVerificationState(VerificationStates.VERIFIED);
          break;
        default:
          // Any other case, something went wrong
          setVerificationState(VerificationStates.ERROR);
      }
      logger.error(
        `Error verifying email: ${firebaseError && firebaseError.code}: `,
        {
          error,
          code_area: 'email_verification'
        }
      );
    } finally {
      setLoading(false);
    }
  }, [actionCode, auth]);

  React.useEffect(() => {
    processEmailVerificationCode();
  }, [processEmailVerificationCode]);

  // prettier-ignore
  return (
    <AuthActionPageWrapper>
      <form className="form-wrapper">
        {loading && <CircularProgress />}
        {!loading && (
          <>
            {verificationState === VerificationStates.ERROR && (
              <>
                <div className="heading-text">Invalid Action Code</div>
                <div className="validation-text">
                There was a problem verifying your account. Please contact <Link href="mailto:support@harmonic.ai">support@harmonic.ai</Link>.
                </div>
              </>
            )}
            {verificationState === VerificationStates.VERIFIED && (
              <>
                <div className="heading-text">Email Verified!</div>
                <div className="validation-text">
                  Your email address has been successfully verified. You may now proceed to the app.
                </div>
              </>
            )}
            {verificationState === VerificationStates.EXPIRED && (
              <>
                <div className="heading-text">Email verification expired</div>
                <div className="validation-text">
                  Your verification code has expired. Please login to receive another verification email.
                </div>
              </>
            )}

            <Link href="/login" className="back-link">
              Back to login
            </Link>
          </>
        )}
      </form>
    </AuthActionPageWrapper>
  );
};

export default EmailVerifyHandler;
