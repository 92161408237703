import classNames from 'classnames';
import { HarmonicIcon } from './type';

const PinLocationIcon: HarmonicIcon = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#7C8598"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M2.66675 6.66665C2.66675 3.72113 5.05456 1.33331 8.00008 1.33331C10.9456 1.33331 13.3334 3.72113 13.3334 6.66665C13.3334 8.50121 12.4321 10.1972 11.4349 11.5205C10.4314 12.8522 9.28529 13.8665 8.69882 14.3478C8.28946 14.6838 7.7107 14.6838 7.30134 14.3478C6.71488 13.8665 5.56876 12.8522 4.56526 11.5205C3.56805 10.1972 2.66675 8.50121 2.66675 6.66665ZM5.91675 6.66665C5.91675 5.51605 6.84949 4.58331 8.00008 4.58331C9.15067 4.58331 10.0834 5.51605 10.0834 6.66665C10.0834 7.81724 9.15067 8.74998 8.00008 8.74998C6.84949 8.74998 5.91675 7.81724 5.91675 6.66665Z"
      />
    </svg>
  );
};

export default PinLocationIcon;
