import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const ThumbDownFill: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      fill="#484E5B"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        vectorEffect="non-scaling-stroke"
        d="M18.0022 4.5V12.5H20.2502C20.3882 12.5 20.5002 12.3881 20.5002 12.25V4.75C20.5002 4.61193 20.3882 4.5 20.2502 4.5H18.0022ZM17.5809 14H20.2502C21.2167 14 22.0002 13.2165 22.0002 12.25V4.75C22.0002 3.7835 21.2167 3 20.2502 3H6.58575C4.733 3 3.15858 4.35403 2.87996 6.1859L2.04344 11.6859C1.69829 13.9552 3.45391 16 5.74923 16H10.1134L9.67296 18.8264C9.41317 20.4935 10.7016 22 12.3891 22C13.0399 22 13.6483 21.6378 13.9504 21.0511L17.5809 14Z"
      />
    </svg>
  );
};

export default ThumbDownFill;
