import { SearchFilterGroup } from '__generated__/graphql';
import { get } from 'lodash';
import { EventTypeToDescriptionMapping } from '../DataModel/Event';
import { IUser } from '../DataModel/User';
import { SearchData } from '../SearchV2';

export enum FieldGrouping {
  EMPLOYEE_CURRENT_EXPERIENCE = 'EMPLOYEE_CURRENT_EXPERIENCE',
  EMPLOYEE_PAST_EXPERIENCE = 'EMPLOYEE_PAST_EXPERIENCE',
  EMPLOYEE_EDUCATION_EXPERIENCE = 'EMPLOYEE_EDUCATION_EXPERIENCE',
  TAG = 'TAG',
  NO_GROUPING = 'NO_GROUPING',
  PERSON_EDUCATION_EXPERIENCE = 'PERSON_EDUCATION_EXPERIENCE',
  PERSON_PAST_EXPERIENCE = 'PERSON_PAST_EXPERIENCE',
  PERSON_CURRENT_EXPERIENCE = 'PERSON_CURRENT_EXPERIENCE',
  PERSON_LAST_EXPERIENCE = 'PERSON_LAST_EXPERIENCE',
  PERSON_HIGHLIGHTS = 'PERSON_HIGHLIGHTS',
  INSTAGRAM = 'INSTAGRAM',
  HEADCOUNT = 'HEADCOUNT',
  HEADCOUNT_ADVISOR = 'HEADCOUNT_ADVISOR',
  HEADCOUNT_CUSTOMER_SUCCESS = 'HEADCOUNT_CUSTOMER_SUCCESS',
  HEADCOUNT_DATA = 'HEADCOUNT_DATA',
  HEADCOUNT_DESIGN = 'HEADCOUNT_DESIGN',
  HEADCOUNT_ENGINEERING = 'HEADCOUNT_ENGINEERING',
  HEADCOUNT_FINANCE = 'HEADCOUNT_FINANCE',
  HEADCOUNT_LEGAL = 'HEADCOUNT_LEGAL',
  HEADCOUNT_MARKETING = 'HEADCOUNT_MARKETING',
  HEADCOUNT_OPERATIONS = 'HEADCOUNT_OPERATIONS',
  HEADCOUNT_OTHER = 'HEADCOUNT_OTHER',
  HEADCOUNT_PEOPLE = 'HEADCOUNT_PEOPLE',
  HEADCOUNT_PRODUCT = 'HEADCOUNT_PRODUCT',
  HEADCOUNT_SALES = 'HEADCOUNT_SALES',
  HEADCOUNT_SUPPORT = 'HEADCOUNT_SUPPORT',
  FACEBOOK = 'FACEBOOK',
  LINKEDIN = 'LINKEDIN',
  FUNDING = 'FUNDING',
  TWITTER = 'TWITTER',
  WEB_TRAFFIC = 'WEB_TRAFFIC'
}

export enum FilterDisplayFormatType {
  PARAGRAPH = 'PARAGRAPH',
  DATE = 'DATE',
  YEAR = 'YEAR',
  MONEY = 'MONEY',
  PERCENTAGE = 'PERCENTAGE'
}

export enum TableFilterType {
  STRING = 'string',
  DATE = 'date',
  INTEGER = 'integer',
  ARRAY = 'array',
  BOOLEAN = 'boolean',
  NULL = 'null'
}

export enum FilterNumberComparator {
  EQUALS = 'equals',
  NOT_EQUALS = 'notEquals',
  LESS_THAN = 'lessThan',
  LESS_THAN_OR_EQUALS = 'lessThanOrEquals',
  GREATER_THAN = 'greaterThan',
  GREATER_THAN_OR_EQUALS = 'greaterThanOrEquals',
  IN_RANGE = 'inRange'
}
export enum FilterDateComparator {
  AFTER_DATE = 'afterDate',
  BEFORE_DATE = 'beforeDate',
  EXACT_DATE = 'exactDate',
  IN_RANGE = 'inRange',
  IN_DAYS_AGO_RANGE = 'inDaysAgoRange',
  NOT_IN_DAYS_AGO_RANGE = 'notInDaysAgoRange'
}
export enum FilterStringComparator {
  EXACT_MATCH = 'exactMatch',
  TEXT_MATCH = 'match',
  NOT_TEXT_MATCH = 'notMatch',
  IS_EMPTY = 'isEmpty',
  IS_NOT_EMPTY = 'isNotEmpty'
}
export enum FilterArrayComparator {
  ANY_OF = 'anyOf',
  NOT_ANY_OF = 'notAnyOf',
  ANY_OF_ARRAY = 'anyOfArray',
  NOT_ANY_OF_ARRAY = 'notAnyOfArray',
  ALL_OF_ARRAY = 'allOfArray',
  ANY_MATCH = 'anyMatch',
  NONE_MATCH = 'notAnyMatch',
  ALL_MATCH = 'allMatch',
  SUBSTRING_MATCH = 'substringMatch',
  NOT_SUBSTRING_MATCH = 'notSubstringMatch',
  IS_EMPTY = 'isEmpty'
}

export enum FilterListComparator {
  IS_IN_LIST = 'isInList',
  IS_NOT_IN_LIST = 'isNotInList'
}

export enum FilterBooleanComparator {
  IS = 'is',
  IS_NOT = 'isNot'
}
export type FilterComparator =
  | FilterStringComparator
  | FilterArrayComparator
  | FilterNumberComparator
  | FilterDateComparator
  | FilterBooleanComparator
  | FilterListComparator;

export interface ISearchFilter {
  field: string;
  field_urn?: string;
  comparator: FilterComparator;
  filter_value: string | number | string[] | boolean | null;
  key?: string;
  // If this is set to true, operation wont be rendered
  noOperation?: boolean;
}
export type JoinOperatorType = 'and' | 'or';

export enum FilterPlaceholder {
  FIELD = '$FIELD$',
  COMPARATOR = '$COMPARATOR$',
  FILTER_VALUE = '$FILTER_VALUE$'
}

export interface ISearchFilterOperation {
  id: string;
  label: string;
  filter_group: ISearchFilterGroup;
}

export interface ISearchFilterGroupGenerator {
  generator_id: keyof SearchData;
  arguments: SearchData[ISearchFilterGroupGenerator['generator_id']];
}

export type ISearchFilterGroup = {
  join_operator: JoinOperatorType;
  filters: ISearchFilter[];
  filter_groups: ISearchFilterGroup[];
  filter_group_generators?: ISearchFilterGroupGenerator[];
  // Below fields only exist on frontend side and should be filtered out while sending to backend
  // Filter group name like Person Current Experience.
  // If it is provided, we cant create nested group inside them.
  name?: string;
  key?: string;
};

interface ISearchPagination {
  start: number;
  page_size: number;
  pageSize?: number;
}

interface SortContextValues {
  savedSearchId: number;
  dateAddedAfter: string;
}

export interface ISearchSort {
  sortField?: string;
  sort_field: string;
  descending: boolean;
  sortContextValues?: SortContextValues;
}

export interface ISearchModel {
  // TODO: Remove this once we no longer have an issue with incorrect saved searches.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterGroup?: any;
  controlledFilterGroup?: SearchFilterGroup | null;

  filter_group: ISearchFilterGroup;
  controlled_filter_group?: ISearchFilterGroup;
  pagination: ISearchPagination;
  sort?: ISearchSort[];
}

export interface SearchFilterGroupQuery {
  filter_group: ISearchFilterGroup;
  controlled_filter_group: ISearchFilterGroup;
  sort?: ISearchSort[];
}

// The keys and values for the enum are different because
// the `type` field values used in the backend couldn't be changed without changing the
// API and as a result, would take months to make the shift (See issue DISCO-928 on Linear.app).
// So, when compiled, this enum's values are the same as the API `type` field values, but we could
// reference them with the more descriptive typescript enum keys, without having to do any manual mapping.
// Awaiting: backend migration to use & match the new enum values.
export enum EntityListType {
  COMPANY_SAVED_SEARCH = 'COMPANIES_LIST',
  PEOPLE_SAVED_SEARCH = 'PERSONS',
  COMPANY_WATCHLIST = 'COMPANY_WATCHLIST',
  PEOPLE_WATCHLIST = 'PEOPLE_WATCHLIST'
}

export type EntitySearchListType =
  | EntityListType.COMPANY_SAVED_SEARCH
  | EntityListType.PEOPLE_SAVED_SEARCH;

export enum SearchType {
  COMPANIES_LIST = 'COMPANIES_LIST',
  EVENT_FEED = 'EVENT_FEED',
  PERSONS = 'PERSONS'
}

export enum ResultsView {
  GRID = 'GRID',
  KANBAN = 'KANBAN'
}

export const EntityListTypeToDashboardPath = {
  [EntityListType.COMPANY_SAVED_SEARCH]: 'companies',
  [EntityListType.PEOPLE_SAVED_SEARCH]: 'people',
  [EntityListType.COMPANY_WATCHLIST]: 'company_watchlist',
  [EntityListType.PEOPLE_WATCHLIST]: 'people_watchlist'
};

export const EntityURNTypeToDashboardPath = {
  [EntityListType.COMPANY_SAVED_SEARCH]: 'saved_search',
  [EntityListType.PEOPLE_SAVED_SEARCH]: 'saved_search',
  [EntityListType.COMPANY_WATCHLIST]: 'company_watchlist',
  [EntityListType.PEOPLE_WATCHLIST]: 'people_watchlist'
};

export interface ISavedSearch {
  id: number;
  name: string;
  search_type: EntityListType;
  creator: IUser;
  search_query: ISearchModel;
  search_query_v2: ISearchModel;
  last_served: Date;
  is_private: boolean;
  title: string;
}

export interface SavedSearchInput {
  name: string;
  isPrivate: boolean;
  type: SearchType;
  query: ISearchModel;
  keywords?: string | null;
}

export enum EntityListModalType {
  UpdateList,
  DuplicateList,
  CreateList
}

/*Company watchlist */
interface ICompanyWatchlistIndividualCompany {
  company_id: number;
  user_inputs: Record<string, unknown>;
}

interface ICompanyWatchlistCreator {
  id: number;
  name: string;
  email: string;
}

export interface ICompanyWatchlist {
  id: string;
  name: string;
  companies: ICompanyWatchlistIndividualCompany[];
  creator: ICompanyWatchlistCreator;
  shared_with_team: boolean;
}

export enum FieldCategory {
  COMPANY = 'COMPANY',
  PERSON = 'PERSON',
  FINANCING = 'FINANCING',
  TRACTION = 'TRACTION',
  OTHER = 'OTHER',
  EDUCATION = 'EDUCATION',
  ROLE = 'ROLE',
  SOCIAL = 'SOCIAL',
  CUSTOM = 'CUSTOM'
}

export const FieldCategoryLabel = {
  [FieldCategory.COMPANY]: 'Company',
  [FieldCategory.PERSON]: 'Team',
  [FieldCategory.FINANCING]: 'Financing',
  [FieldCategory.TRACTION]: 'Traction',
  [FieldCategory.OTHER]: 'Other',
  [FieldCategory.EDUCATION]: 'Education',
  [FieldCategory.ROLE]: 'Role',
  [FieldCategory.SOCIAL]: 'Social',
  [FieldCategory.CUSTOM]: 'Custom'
};

export enum FieldEntityType {
  COMPANY = 'COMPANY',
  PERSON = 'PERSON',
  SCHOOL = 'SCHOOL'
}

export interface SearchFieldSpecSelectValue {
  label: string;
  value: string;
  color?: string;
}
/**
 * Field headers for the search model
 */
export interface ISearchFieldSpec {
  category: FieldCategory;
  allowed_comparators: FilterComparator[];
  value_restricted_to: string[] | null;
  // Existing select only takes a string for the value.
  // This is to allow separate label and value.
  select_values?: SearchFieldSpecSelectValue[];
  display_name: string;
  urn: string;
  unique_name: string;
  type: TableFilterType[] | TableFilterType;
  format?: 'date' | null;
  description: string;
  grouping?: FieldGrouping;
  display_format?: FilterDisplayFormatType;
  beta?: boolean;
  operations?: ISearchFilterOperation[];
  entity_type?: FieldEntityType;
  deprecated?: boolean;
  deprecated_use_instead?: string;
  visible_to_splits?: string[];
}

/**
 * Initial search model values
 */

export const INITIAL_COMPANY_SORT = {
  sortField: 'featured',
  sortDescending: true
};
export const INITIAL_PEOPLE_SORT = {
  sortField: 'person_id',
  sortDescending: false
};
export const INITIAL_PEOPLE_WATCHLIST_SORT = {
  sortField: 'person_last_update_event_date',
  sortDescending: true
};

export const INITIAL_SEARCH_FILTER: ISearchFilter = {
  field: 'company_name',
  filter_value: '',
  comparator: FilterArrayComparator.SUBSTRING_MATCH
};

export const INITIAL_SEARCH_FILTER_GROUP: ISearchFilterGroup = {
  join_operator: 'and',
  filter_groups: [],
  filters: []
};

export const INITIAL_COMPANY_SEARCH_SORT_QUERY: ISearchSort[] = [
  {
    sort_field: INITIAL_COMPANY_SORT.sortField,
    descending: INITIAL_COMPANY_SORT.sortDescending
  }
];

export const INITIAL_PEOPLE_SEARCH_SORT_QUERY: ISearchSort[] = [
  {
    sort_field: INITIAL_PEOPLE_SORT.sortField,
    descending: INITIAL_PEOPLE_SORT.sortDescending
  }
];

export const INITIAL_SEARCH_CONTROLLED_FILTER_GROUP: ISearchFilterGroup = {
  filter_group_generators: [],
  join_operator: 'and',
  filter_groups: [],
  filters: []
};
export const INITIAL_SEARCH_COMPANY_FILTER_GROUP_QUERY: SearchFilterGroupQuery =
  {
    filter_group: INITIAL_SEARCH_FILTER_GROUP,
    controlled_filter_group: INITIAL_SEARCH_CONTROLLED_FILTER_GROUP,
    sort: INITIAL_COMPANY_SEARCH_SORT_QUERY
  };
export const INITIAL_SEARCH_PEOPLE_FILTER_GROUP_QUERY: SearchFilterGroupQuery =
  {
    filter_group: INITIAL_SEARCH_FILTER_GROUP,
    controlled_filter_group: INITIAL_SEARCH_CONTROLLED_FILTER_GROUP,
    sort: []
  };

export const INITIAL_SEARCH_PAGINATION = {
  start: 0,
  page_size: process.env.NODE_ENV === 'production' ? 25 : 10
};

export const INITIAL_SEARCH_SORT_BY_COMPANY_INITIALIZED = [
  {
    sort_field: 'company_created_at',
    descending: true
  },
  {
    sort_field: INITIAL_COMPANY_SORT.sortField,
    descending: INITIAL_COMPANY_SORT.sortDescending
  }
];

// Used to set default sort options for the company search
export const INITIAL_PEOPLE_SORT_VALUE = 'person_id';

export const INITIAL_SEARCH_MODEL = {
  filter_group: INITIAL_SEARCH_FILTER_GROUP,
  pagination: INITIAL_SEARCH_PAGINATION,
  sort: [
    {
      sort_field: INITIAL_COMPANY_SORT.sortField,
      descending: INITIAL_COMPANY_SORT.sortDescending
    }
  ]
};
export const INITIAL_PEOPLE_SEARCH_MODEL = {
  filter_group: INITIAL_SEARCH_FILTER_GROUP,
  pagination: INITIAL_SEARCH_PAGINATION,
  sort: [
    {
      sort_field: INITIAL_PEOPLE_SORT_VALUE,
      descending: false
    }
  ]
};

const EMPTY_FILTER_VALUE_STRING = '$EMPTY$';
const FundingTypeEnums = {
  ANGEL: 'Angel',
  CONVERTIBLE_NOTE: 'Convertible Note',
  CORPORATE_ROUND: 'Corporate Round',
  DEBT_FINANCING: 'Debt Financing',
  EQUITY_CROWDFUNDING: 'Equity Crowdfunding',
  OUT_OF_BUSINESS: 'Out of Business',
  GRANT: 'Grant',
  INITIAL_COIN_OFFERING: 'Initial Coin Offering',
  NON_EQUITY_ASSISTANCE: 'Non-equity Assistance',
  POST_IPO_DEBT: 'Post-IPO Debt',
  POST_IPO_EQUITY: 'Post-IPO Equity',
  POST_IPO_SECONDARY: 'Post-IPO Secondary',
  PRE_SEED: 'Preseed',
  PRIVATE_EQUITY: 'Private Equity',
  PRODUCT_CROWDFUNDING: 'Product Crowdfunding',
  SECONDARY_MARKET: 'Secondary Market',
  SEED: 'Seed',
  SERIES_A: 'Series A',
  SERIES_B: 'Series B',
  SERIES_C: 'Series C',
  SERIES_D: 'Series D',
  SERIES_E: 'Series E',
  SERIES_F: 'Series F',
  SERIES_G: 'Series G',
  SERIES_H: 'Series H',
  SERIES_I: 'Series I',
  SERIES_J: 'Series J',
  SERIES_K_PLUS: 'Series K+',
  SERIES_UNKNOWN: 'Venture Unknown',
  UNDISCLOSED: 'Undisclosed',
  EARLY_STAGE_VC: 'Early Stage VC',
  ACCELERATOR_INC: 'Accelerator/​Inc',
  DEBT_PP: 'Debt - PPP',
  LATER_STAGE_VC: 'Later Stage VC',
  SERIES_1: 'Series 1',
  SERIES_A1: 'Series A1',
  VENTURE_DEBT: 'Venture Debt',
  EARLY_STAGE_VENTURE: 'Early Stage Venture',
  LATER_STAGE_VENTURE: 'Late Stage Venture',
  PRE__SEED: 'Pre-Seed',
  FUNDING_ROUND: 'Funding Round',
  PIPE: 'PIPE',
  M_AND_A: 'M&A',
  PE_GROWTH: 'PE Growth',
  ANGEL_INDIVIDUAL: 'Angel (individual)',
  ACCELERATOR_INCUBATOR: 'Accelerator/Incubator',
  SEED_ROUND: 'Seed Round',
  EARLY_STAGE_SERIES_A: 'Early Stage VC (Series A)',
  EARLY_STAGE_SERIES_A1: 'Early Stage VC (Series A1)',
  EARLY_STAGE_SERIES_B: 'Early Stage VC (Series B)',
  EARLY_STAGE_SERIES_B1: 'Early Stage VC (Series B1)',
  LATER_STAGE_SERIES_C: 'Later Stage VC (Series C)',
  LATER_STAGE_SERIES_C1: 'Later Stage VC (Series C1)',
  CORPORATE_SERIES_A: 'Corporate (Series A)',
  LATER_STAGE_SERIES_E: 'Later Stage VC (Series E)',
  LATER_STAGE_SERIES_B: 'Later Stage VC (Series B)',
  LATER_STAGE_SERIES_D: 'Later Stage VC (Series D)',
  IPO: 'IPO',
  BUYOUT_LBO: 'Buyout/LBO',
  SECONDARY_PRIVATE: '2ndary - Private',
  DEBT: 'Debt',
  CORPORATE: 'Corporate',
  CROWDFUNDING: 'Crowdfunding',
  LIQUIDATION: 'Liquidation',
  DEBT_REFIN: 'Debt Refin.',
  UNDERTERMINED: 'Undetermined',
  BANK_ADMIN: 'Bankr. Admin/Reorg',
  MERGER_OF_EQ: 'Merger of Eq.',
  SERIES_B1: 'Series B1',
  CONV_DEBT: 'Conv. Debt',
  JOINT_VENTURE: 'Joint Venture',
  IBO: 'IBO',
  MEZZANINE: 'Mezzanine',
  SERIES_A2: 'Series A2',
  ASSET_PURCH: 'Asset Purch.',
  SERIES_2: 'Series 2',
  PLATFORM: 'Platform',
  DEBT_REPAY: 'Debt Repay.',
  REVERSE_MERGER: 'Reverse Merger',
  SERIES_C1: 'Series C1',
  DIV_RECAP: 'Div. Recap.',
  LEV_RECAP: 'Lev. Recap.',
  SERIES_A3: 'Series A3',
  SERIES_B2: 'Series B2',
  SERIES_AA: 'Series AA',
  SECONDARY_OPEN: '2ndary - Open',
  CAPITALIZATION: 'Capitalization',
  SERIES_3: 'Series 3',
  SERIES_D1: 'Series D1',
  REPURCHASE: 'Repurchase',
  SERIES_C2: 'Series C2',
  DEBT_CONV: 'Debt Conv.',
  BRIDGE: 'Bridge',
  SERIES_B3: 'Series B3',
  RESTART_ANGEL: 'Restart - Angel',
  SERIES_BB: 'Series BB',
  SERIES_E1: 'Series E1',
  SERIES_D2: 'Series D2',
  SERIES_C3: 'Series C3',
  TWO_PO: '2PO',
  EQUITY_FOR_SVC: 'Equity for Svc.',
  SECONDARY_DISTRIB: '2ndary - Distrib.',
  DIVIDEND: 'Dividend',
  VENTURE_LEASING: 'Venture Leasing',
  RESTART_LATER: 'Restart - Later',
  SERIES_AAA: 'Series AAA',
  SERIES_CC: 'Series CC',
  SERIES_E2: 'Series E2',
  SALE_LEASE_BACK: 'Sale-Lease back',
  SPIN_OFF: 'Spin-Off',
  SERIES_D3: 'Series D3',
  RESTART_EARLY: 'Restart - Early',
  GP_STAKES: 'GP Stakes',
  SECONDARY_TRANSACTION_PRIVATE: 'Secondary Transaction - Private',
  LATER_STAGE_SERIES_G: 'Later Stage VC (Series G)',
  DEBT_GENERAL: 'Debt - General',
  LATER_STAGE_SERIES_F: 'Later Stage VC (Series F)',
  STEALTH: 'Stealth',
  VENTURE_UNKNOWN: 'Venture Unknown',
  EXITED: 'Exited'
};
export const SearchValueToDisplayNameMap = {
  // Event Types
  ...EventTypeToDescriptionMapping,
  // Selectivity / Likelihood of Backing
  one: '1',
  two: '2',
  three: '3',
  four: '4',
  major_hire: 'Major Hire',
  backed: 'Backed',
  // Funding Stage
  // new mappings for FundingType ENUM
  ...FundingTypeEnums,
  // old mappings for FundingType
  angel: 'Angel',
  convertible_note: 'Convertible Note',
  corporate_round: 'Corporate Round',
  debt_financing: 'Debt Financing',
  equity_crowdfunding: 'Equity Crowdfunding',
  grant: 'Grant',
  initial_coin_offering: 'Initial Coin Offering',
  non_equity_assistance: 'Non-equity Assistance',
  post_ipo_debt: 'Post-IPO Debt',
  post_ipo_equity: 'Post-IPO Equity',
  post_ipo_secondary: 'Post-IPO Secondary',
  pre_seed: 'Preseed',
  private_equity: 'Private Equity',
  product_crowdfunding: 'Product Crowdfunding',
  secondary_market: 'Secondary Market',
  seed: 'Seed',
  series_a: 'Series A',
  series_b: 'Series B',
  series_c: 'Series C',
  series_d: 'Series D',
  series_e: 'Series E',
  series_f: 'Series F',
  series_g: 'Series G',
  series_h: 'Series H',
  series_i: 'Series I',
  series_j: 'Series J',
  SERIES_A_EXTENSION: 'Series A (Ext)',
  SERIES_B_EXTENSION: 'Series B (Ext)',
  SERIES_C_EXTENSION: 'Series C (Ext)',
  SERIES_D_EXTENSION: 'Series D (Ext)',
  SERIES_E_EXTENSION: 'Series E (Ext)',
  SERIES_F_EXTENSION: 'Series F (Ext)',
  SERIES_G_EXTENSION: 'Series G (Ext)',
  VENTURE_OTHER: 'Venture (Other)',
  series_unknown: 'Venture Unknown',
  undisclosed: 'Undisclosed',
  early_stage_vc: 'Early Stage VC',
  accelerator_inc: 'Accelerator/​Inc',
  debt_pp: 'Debt - PPP',
  later_stage_vc: 'Later Stage VC',
  series_1: 'Series 1',
  series_a1: 'Series A1',
  venture_debt: 'Venture Debt',
  early_stage_venture: 'Early Stage Venture',
  later_stage_venture: 'Late Stage Venture',
  pre__seed: 'Pre-Seed',
  funding_round: 'Funding Round',
  pipe: 'PIPE',
  'Venture - Series Unknown': 'Venture Unknown',
  // Funding Attribute Null Status
  EXISTS_BUT_UNDISCLOSED: 'Undisclosed',
  NONE_ANNOUNCED: 'None Announced',
  // Company Type
  UNKNOWN: 'Unknown',
  STARTUP: 'Startup',
  // Typed Tags
  PRODUCT_HUNT: 'Product Hunt',
  YC_BATCH: 'YC Batch',
  INDUSTRY: 'Industry',
  EXTERNAL_INDUSTRY: 'External Industry',
  TECHNOLOGY: 'Technology',
  // Role Type
  FOUNDER: 'Founder',
  EXECUTIVE: 'Executive',
  OPERATOR: 'Operator',
  INTERN: 'Intern',
  ALUMNI: 'Alumni',
  ADVISER: 'Adviser',
  EMPLOYEE: 'Employee',
  // Empty
  [EMPTY_FILTER_VALUE_STRING]: '(Empty)'
};

export const getFundingTypeDisplayName = (
  value: string | null | undefined
): string => {
  if (!value) return '';
  return get(SearchValueToDisplayNameMap, value, value);
};

export enum EditFundingRoundType {
  PRE_SEED = 'PRE_SEED',
  SEED = 'SEED',
  SERIES_A = 'SERIES_A',
  SERIES_A_EXTENSION = 'SERIES_A_EXTENSION',
  SERIES_B = 'SERIES_B',
  SERIES_B_EXTENSION = 'SERIES_B_EXTENSION',
  SERIES_C = 'SERIES_C',
  SERIES_C_EXTENSION = 'SERIES_C_EXTENSION',
  SERIES_D = 'SERIES_D',
  SERIES_D_EXTENSION = 'SERIES_D_EXTENSION',
  SERIES_E = 'SERIES_E',
  SERIES_E_EXTENSION = 'SERIES_E_EXTENSION',
  SERIES_F = 'SERIES_F',
  SERIES_F_EXTENSION = 'SERIES_F_EXTENSION',
  SERIES_G = 'SERIES_G',
  SERIES_G_EXTENSION = 'SERIES_G_EXTENSION',
  SERIES_H = 'SERIES_H',
  SERIES_I = 'SERIES_I',
  SERIES_J = 'SERIES_J',
  SERIES_K_PLUS = 'SERIES_K_PLUS',
  PRIVATE_EQUITY = 'PRIVATE_EQUITY',
  VENTURE_DEBT = 'VENTURE_DEBT',
  VENTURE_OTHER = 'VENTURE_OTHER'
}
