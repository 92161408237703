import { IconProps } from './type';

const BxsDollarIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H32C34.2091 0 36 1.79086 36 4V32C36 34.2091 34.2091 36 32 36H4C1.79086 36 0 34.2091 0 32V4Z"
        fill="#F0F4E1"
        vectorEffect="non-scaling-stroke"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.66699 18C9.66699 13.3976 13.398 9.66669 18.0003 9.66669C22.6027 9.66669 26.3337 13.3976 26.3337 18C26.3337 22.6024 22.6027 26.3334 18.0003 26.3334C13.398 26.3334 9.66699 22.6024 9.66699 18ZM18.6253 12.7917C18.6253 12.4465 18.3455 12.1667 18.0003 12.1667C17.6551 12.1667 17.3753 12.4465 17.3753 12.7917V13.6712C16.9106 13.7433 16.4761 13.9044 16.1191 14.1859C15.5824 14.609 15.3029 15.2393 15.3029 16.0007C15.3029 16.8872 15.6632 17.4892 16.2121 17.8935C16.6539 18.2189 17.2141 18.4062 17.6625 18.5561C17.7095 18.5719 17.7554 18.5872 17.7997 18.6022L17.903 18.6372C18.4009 18.8055 18.7722 18.931 19.0473 19.1336C19.2803 19.3052 19.4477 19.5355 19.4477 20.0199C19.4477 20.455 19.3019 20.7 19.1077 20.853C18.8905 21.0243 18.5283 21.1454 18.0003 21.1454C17.3305 21.1454 16.9018 20.8397 16.6882 20.4564C16.5202 20.1549 16.1396 20.0466 15.8381 20.2146C15.5365 20.3826 15.4282 20.7632 15.5962 21.0647C15.9537 21.7064 16.5767 22.1742 17.3753 22.3347V23.2084C17.3753 23.5535 17.6551 23.8334 18.0003 23.8334C18.3455 23.8334 18.6253 23.5535 18.6253 23.2084V22.3493C19.0901 22.2773 19.5246 22.1161 19.8816 21.8347C20.4182 21.4116 20.6977 20.7813 20.6977 20.0199C20.6977 19.1334 20.3375 18.5314 19.7885 18.1271C19.3467 17.8017 18.7865 17.6144 18.3381 17.4644C18.2911 17.4487 18.2453 17.4334 18.201 17.4184L18.0976 17.3834C17.5997 17.2151 17.2285 17.0896 16.9534 16.887C16.7204 16.7154 16.5529 16.485 16.5529 16.0007C16.5529 15.5656 16.6988 15.3206 16.893 15.1675C17.1101 14.9963 17.4724 14.8751 18.0003 14.8751C18.6702 14.8751 19.0989 15.1808 19.3124 15.5642C19.4804 15.8657 19.8611 15.974 20.1626 15.806C20.4641 15.638 20.5724 15.2574 20.4044 14.9558C20.0469 14.3142 19.4239 13.8464 18.6253 13.6858V12.7917Z"
        fill="#272D10"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default BxsDollarIcon;
