import {
  GetCompanyUserConnectionsV2_UserFragment,
  ParticipantResponseStatus,
  ParticipantResponseStatusType,
  RoleType,
  UserConnection
} from '__generated__/graphql';
import { PersonWithConnections } from 'components/Dashboard/CompanyV2/CompanyContact/UserConnectionsList';
import { compact, find, groupBy, sortBy } from 'lodash';
export const getGroupedCompanyUserConnections = (
  userConnections: UserConnection[],
  companyId: number
): PersonWithConnections[] => {
  const groupedPeople = groupBy(
    userConnections,
    (connection) =>
      connection?.targetPerson?.id ?? connection?.targetPersonEmailAddress
  );
  return compact(
    sortBy(Object.values(groupedPeople), (value) => {
      // Sort by role type
      const person = value[0]?.targetPerson;
      if (!person) return 0;
      const current = person.experience?.find((exp) => {
        return exp?.isCurrentPosition && exp.company.id === companyId;
      });
      return current?.roleType === RoleType.FOUNDER
        ? -6
        : current?.roleType === RoleType.EXECUTIVE
        ? -5
        : current?.roleType === RoleType.OPERATOR
        ? -4
        : current?.roleType === RoleType.ADVISER
        ? -3
        : current?.roleType === RoleType.EMPLOYEE
        ? -2
        : -1;
    }).map((value) => {
      if (!value[0]?.user) return null;
      return {
        person: value[0].targetPerson ?? undefined,
        personEmail:
          find<UserConnection>(value, (item) => !!item.targetPersonEmailAddress)
            ?.targetPersonEmailAddress ?? '',
        userConnections: value.map(
          (connection) =>
            connection?.user as GetCompanyUserConnectionsV2_UserFragment
        ),
        latestCalendarEvent: value[0]?.latestCalendarEvent,
        latestEmail: value[0]?.latestEmail
      };
    })
  );
};

export const getParticipantResponseStatusMap = (
  participantResponseStatuses: ParticipantResponseStatus[]
) => {
  const statusMap: Record<string, ParticipantResponseStatusType> = {};
  participantResponseStatuses.forEach((participant) => {
    const person = participant.person;
    const status = participant.status;

    const key = person?.emailAddress.emailAddress;

    if (!key) return;

    if (person && status) statusMap[key] = status;
  });
  return statusMap;
};

export const getDisplayNameFromParticipantList = (
  emailAddress: string,
  participants: { name: string; emailAddress: string }[]
) => {
  const participant = participants.find(
    (participant) => participant.emailAddress === emailAddress
  );
  return participant?.name ? participant.name : emailAddress;
};
