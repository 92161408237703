import { OperationVariables, QueryResult, useQuery } from '@apollo/client';
import {
  GetUseFetchWatchlistsQuery,
  WatchlistFragment
} from '__generated__/graphql';
import { compact } from 'lodash';
import uniq from 'lodash/uniq';
import { getCompanyWatchlists } from 'queries/getWatchlists';
import { useMemo } from 'react';
import { UserWatchlistType, getSingletonWatchlist } from 'utils/watchlist';

interface IUseFetchWatchlistsResult
  extends QueryResult<GetUseFetchWatchlistsQuery, OperationVariables> {
  watchlists: (WatchlistFragment | null)[];
  likedCompaniesWatchlist: WatchlistFragment | null;
  hiddenCompaniesWatchlist: WatchlistFragment | null;
  affinityLists: WatchlistFragment[];
}

const useFetchWatchlists = (
  options?: OperationVariables
): IUseFetchWatchlistsResult => {
  const { data, ...rest } =
    useQuery<GetUseFetchWatchlistsQuery>(getCompanyWatchlists);

  const watchlists = useMemo(
    () => uniq(data?.getCompanyWatchlistsForTeam ?? []),
    [data]
  );

  const likedCompaniesWatchlist: WatchlistFragment | null = useMemo(
    () =>
      getSingletonWatchlist(
        compact(watchlists),
        UserWatchlistType.USER_FAVORITED_ENTITIES
      ),
    [watchlists]
  );

  const hiddenCompaniesWatchlist: WatchlistFragment | null = useMemo(
    () =>
      getSingletonWatchlist(
        compact(watchlists),
        UserWatchlistType.USER_HIDDEN_ENTITIES
      ),
    [watchlists]
  );

  const affinityLists: WatchlistFragment[] = useMemo(
    () =>
      compact(watchlists).filter(
        (watchlist) =>
          watchlist.userWatchlistType ===
          UserWatchlistType.CUSTOMER_AFFINITY_SYNCED_LIST
      ),
    [watchlists]
  );

  return {
    watchlists,
    likedCompaniesWatchlist,
    hiddenCompaniesWatchlist,
    affinityLists,
    data,
    ...rest
  };
};

export default useFetchWatchlists;
