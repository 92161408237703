import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const ProcessorIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      fill="#7C8598"
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M6.33253 7.99919C6.33253 7.07871 7.07872 6.33252 7.9992 6.33252C8.91967 6.33252 9.66586 7.07871 9.66586 7.99919C9.66586 8.91966 8.91967 9.66585 7.9992 9.66585C7.07872 9.66585 6.33253 8.91966 6.33253 7.99919Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00001 1.33057C8.27615 1.33057 8.50001 1.55442 8.50001 1.83057V2.6665H10.1667V1.83317C10.1667 1.55703 10.3905 1.33317 10.6667 1.33317C10.9428 1.33317 11.1667 1.55703 11.1667 1.83317V2.6665H12.1667C12.811 2.6665 13.3333 3.18884 13.3333 3.83317V4.83317H14.1667C14.4428 4.83317 14.6667 5.05703 14.6667 5.33317C14.6667 5.60931 14.4428 5.83317 14.1667 5.83317H13.3333V7.49984H14.1667C14.4428 7.49984 14.6667 7.7237 14.6667 7.99984C14.6667 8.27598 14.4428 8.49984 14.1667 8.49984H13.3333V10.1665H14.1667C14.4428 10.1665 14.6667 10.3904 14.6667 10.6665C14.6667 10.9426 14.4428 11.1665 14.1667 11.1665H13.3333V12.1665C13.3333 12.8108 12.811 13.3332 12.1667 13.3332H11.1667V14.1665C11.1667 14.4426 10.9428 14.6665 10.6667 14.6665C10.3905 14.6665 10.1667 14.4426 10.1667 14.1665V13.3332H8.50001V14.1665C8.50001 14.4426 8.27615 14.6665 8.00001 14.6665C7.72387 14.6665 7.50001 14.4426 7.50001 14.1665V13.3332H5.83334V14.1665C5.83334 14.4426 5.60949 14.6665 5.33334 14.6665C5.0572 14.6665 4.83334 14.4426 4.83334 14.1665V13.3332H3.83334C3.18901 13.3332 2.66668 12.8108 2.66668 12.1665V11.1665H1.83334C1.5572 11.1665 1.33334 10.9426 1.33334 10.6665C1.33334 10.3904 1.5572 10.1665 1.83334 10.1665H2.66668V8.49984H1.83334C1.5572 8.49984 1.33334 8.27598 1.33334 7.99984C1.33334 7.7237 1.5572 7.49984 1.83334 7.49984H2.66668V5.83317H1.83334C1.5572 5.83317 1.33334 5.60931 1.33334 5.33317C1.33334 5.05703 1.5572 4.83317 1.83334 4.83317H2.66668V3.83317C2.66668 3.18884 3.18901 2.6665 3.83334 2.6665H4.83334V1.83057C4.83334 1.55442 5.0572 1.33057 5.33334 1.33057C5.60949 1.33057 5.83334 1.55442 5.83334 1.83057V2.6665H7.50001V1.83057C7.50001 1.55442 7.72387 1.33057 8.00001 1.33057ZM7.9992 5.33252C6.52644 5.33252 5.33253 6.52643 5.33253 7.99919C5.33253 9.47195 6.52644 10.6659 7.9992 10.6659C9.47196 10.6659 10.6659 9.47195 10.6659 7.99919C10.6659 6.52643 9.47196 5.33252 7.9992 5.33252Z"
      />
    </svg>
  );
};

export default ProcessorIcon;
