import classNames from 'classnames';
import { IconProps } from './type';

const Stack: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        d="M14.9069 3.71327C14.3354 3.42891 13.6644 3.42891 13.0929 3.71327L3.4689 8.50203C1.9547 9.25547 1.95471 11.424 3.4689 12.1775L13.0929 16.9662C13.6644 17.2506 14.3354 17.2506 14.9069 16.9662L24.5309 12.1775C26.0451 11.424 26.0451 9.25547 24.5309 8.50203L14.9069 3.71327Z"
        fill="#7C8598"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M5.3732 14.875L3.4689 15.8225C1.95471 16.576 1.9547 18.7445 3.4689 19.498L13.0929 24.2867C13.6644 24.5711 14.3354 24.5711 14.9069 24.2867L24.5309 19.498C26.0451 18.7445 26.0451 16.576 24.5309 15.8225L22.6266 14.875L14.9069 18.7162C14.3354 19.0006 13.6644 19.0006 13.0929 18.7162L5.3732 14.875Z"
        fill="#7C8598"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default Stack;
