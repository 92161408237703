import { ChartData, ChartOptions, ScatterDataPoint } from 'chart.js';
import { ILineChartDataPoint } from 'interfaces/Charts';
import { Chart as ChartJS, Line } from 'react-chartjs-2';
import { chartAreaRoundedBorderPlugin } from 'utils/chart';
import { numberSuffixFormatter } from 'utils/utilities';

ChartJS.register(chartAreaRoundedBorderPlugin);

type LineChartProps = {
  yAxisPrefix: string;
  datasets: {
    label?: string;
    data: ILineChartDataPoint[];
    backgroundColor: string;
    borderColor: string;
  }[];
};

const StackedLineChart: React.FC<LineChartProps> = ({
  yAxisPrefix = '',
  datasets
}) => {
  const lineChartData: ChartData = {
    datasets: datasets.map((dataset) => ({
      label: dataset.label,
      fill: true,
      tension: 0.1,
      borderWidth: 0.75,
      backgroundColor: dataset.backgroundColor,
      borderColor: dataset.borderColor,
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'round',
      pointBorderColor: dataset.borderColor,
      pointBackgroundColor: dataset.backgroundColor,
      pointBorderWidth: 0,
      pointHoverRadius: 2,
      pointRadius: 0,
      pointHitRadius: 5,
      spanGaps: true,
      data: dataset.data as unknown as ScatterDataPoint[],
      stack: 'combined' // Stack all datasets
    }))
  };

  const lineOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        time: {
          tooltipFormat: 'MMM DD, YYYY',
          unit: 'month'
        },
        ticks: {
          maxTicksLimit: 7
        }
      },
      y: {
        stacked: true,
        min: 0,
        ticks: {
          callback: (value: number | string): string =>
            `${yAxisPrefix}${numberSuffixFormatter(value as number)}`
        }
      }
    },
    plugins: {
      legend: {
        display: true, // Enable legend
        position: 'top'
      },
      tooltip: {
        callbacks: {
          title: (ctx) => ctx[0]?.dataset?.label?.toUpperCase() || '',
          label: (ctx) => [ctx.formattedValue, ctx.label]
        }
      }
    }
  };

  //eslint-disable-next-line
  //@ts-ignore:next-line
  return <Line type="line" data={lineChartData} options={lineOptions} />;
};

export default StackedLineChart;
