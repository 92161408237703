import classNames from 'classnames';
import { IconProps } from './type';

const PencilIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#7C8598"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M8.83398 4.16722L10.8626 2.13862C11.1229 1.87827 11.545 1.87827 11.8054 2.13862L13.8626 4.19581C14.1229 4.45616 14.1229 4.87827 13.8626 5.13862L11.834 7.16722M8.83398 4.16722L2.02925 10.972C1.90422 11.097 1.83398 11.2665 1.83398 11.4434V14.1672H4.55784C4.73465 14.1672 4.90422 14.097 5.02925 13.972L11.834 7.16722M8.83398 4.16722L11.834 7.16722"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PencilIcon;
