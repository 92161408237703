import {
  LOGOUT_USER,
  NEED_VERIFICATION,
  SET_USER,
  SET_USER_API_KEY,
  SET_USER_ERROR,
  SET_USER_LOADING,
  SET_USER_METADATA,
  SET_USER_ROLE,
  SET_USER_STATUS,
  SET_USER_SUCCESS
} from '../actions/types/actionTypes';
import { IAuthAction, IAuthState } from '../interfaces/Auth';

const initializeState: IAuthState = {
  user: null,
  isAuthenticated: false,
  loading: false,
  error: '',
  needVerification: false,
  success: '',
  status: undefined,
  role: undefined,
  apiKey: ''
};

const authReducer = (
  state = initializeState,
  action: IAuthAction
): IAuthState => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
        loading: false
      };
    case SET_USER_METADATA:
      return {
        ...state,
        userMetadata: action.payload
      };
    case SET_USER_STATUS:
      return {
        ...state,
        status: action.payload
      };
    case SET_USER_ROLE:
      return {
        ...state,
        role: action.payload
      };
    case SET_USER_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case SET_USER_API_KEY:
      return {
        ...state,
        apiKey: action.payload
      };
    case LOGOUT_USER:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        loading: false,
        apiKey: ''
      };
    case SET_USER_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case NEED_VERIFICATION:
      return {
        ...state,
        needVerification: true
      };
    case SET_USER_SUCCESS:
      return {
        ...state,
        success: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

export default authReducer;
