import { getAuth, onAuthStateChanged, onIdTokenChanged } from 'firebase/auth';
import { FC, useEffect, useState } from 'react';

import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
  setLogVerbosity
} from '@apollo/client';

import { logout } from 'actions/authActions';
import { getFirebaseToken } from 'actions/fetchActions';
import { getApp } from 'firebase/app';
import { LogoutReason } from 'interfaces/Auth';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import useStore from 'stores/zustandStore';
import analytics from 'utils/analytics';
import { IS_PROD_LIKE_ENV, LOCAL_AGAINST_PROD } from 'utils/constants';
import { LoggerEvent, logger } from 'utils/logger';
import { getUser } from 'utils/midtierApi';
import client from './client';
// import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist'; // Removing for now until we use an apollo subscribe query to update when a change occurs

// TODO: I tried to refactor this component to make it simpler, but it turned out to be too much effort.
// We shouldn't need to call getFirebaseToken so many times, but if we do not do this,
// a race condition is triggered and the application fails at startup with no error logged.
// When the time is right, we should get deep on how and where Firebase token is used and save it in the app store.
// This way, anywhere in the application we can wait till the token is available and mitigate the race condition.
// Issue to track this effort in the future: DISCO-644

const ApolloWrapper: FC<{ children: React.ReactNode }> = ({ children }) => {
  const authState = useSelector<AppState, AppState['auth']>(
    (state) => state.auth
  );
  const [tokenFetchInProgress, setTokenFetchInProgress] = useState(false);
  const [firebaseAuthFinished, setFirebaseAuthFinished] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      onAuthStateChanged(auth, () => {
        setFirebaseAuthFinished(true);
      });
    } catch (err) {
      setError(true);
      logger.error(`Auth state changed error: ${err}`, {
        code_area: 'app',
        err
      });
    }
  }, []);

  useEffect(() => {
    if (error) {
      dispatch(logout(LogoutReason.SessionExpired));
    }
  }, [error]);
  const auth = getAuth(getApp());

  const editStoreData = useStore((state) => state.editStoreData);

  const [jwtToken, setJwtToken] = useState<string | undefined>();
  const [localClient, setClient] = useState<
    ApolloClient<NormalizedCacheObject> | undefined
  >();

  const createApolloClient = async () => {
    setClient(client);
    if (LOCAL_AGAINST_PROD) {
      setLogVerbosity('debug');
    }
  };

  const fetchAndSetFirebaseToken = async () => {
    if (!authState.isAuthenticated || !firebaseAuthFinished) return;

    try {
      setTokenFetchInProgress(true);

      // Fetch token and log timing
      logger.sendTiming(LoggerEvent.TOKEN_LOADING_STARTED);
      const firebaseToken = await getFirebaseToken();
      logger.sendTiming(LoggerEvent.TOKEN_LOADING_COMPLETED);

      // Fetch user data and log timing
      logger.sendTiming(LoggerEvent.USER_LOADING_STARTED);
      const { email, entity_urn, name, settings, customer } = await getUser();
      logger.sendTiming(LoggerEvent.USER_LOADING_COMPLETED);

      editStoreData('userUrn', entity_urn);
      editStoreData('customerUrn', customer);
      editStoreData('userSettings', settings);
      if (IS_PROD_LIKE_ENV) {
        analytics.initializeAnalytics({ email, name, entityUrn: entity_urn });
        logger.identifyUser({ email, name, entityUrn: entity_urn });
      }

      setJwtToken(firebaseToken);
    } catch (err) {
      logger.error('Redux store is authenticated but firebase is failing', {
        err
      });
      dispatch(logout(LogoutReason.SessionExpired));
    } finally {
      setTokenFetchInProgress(false);
    }
  };
  useEffect(() => {
    onIdTokenChanged(auth, () => fetchAndSetFirebaseToken());
  }, []);

  useEffect(() => {
    fetchAndSetFirebaseToken();
  }, [authState.isAuthenticated, firebaseAuthFinished]);

  useEffect(() => {
    // We should only create one instance of ApolloClient per session.
    // https://github.com/apollographql/apollo-client-devtools/issues/822#issuecomment-1059166308
    if (localClient) {
      return;
    }

    if (jwtToken) {
      createApolloClient();
    }
  }, [jwtToken]);

  // If we do not have a Firebase token and the user is not authenticated, it means the user is logged out
  // none of the children should expect a ApolloClient in this case. We do not render ApolloProvider and
  // we render the children as is
  if (isEmpty(jwtToken) && !authState.isAuthenticated) {
    return <>{children}</>;
  }

  // If the user is authenticated, but the Firebase token is not yet available, we can't instantiate the
  // Apollo client and we can't render the children, as they may expect an Apollo client.
  // This is a temporal state while the Firebase token is retrieved.
  if (!localClient) {
    return <></>;
  }

  // At this point we have an ApolloClient, render the ApolloProvider
  return <ApolloProvider client={localClient}>{children}</ApolloProvider>;
};

export default ApolloWrapper;
