import classNames from 'classnames';
import { IconProps } from './type';

const StarFilledIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#484E5B"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M13.1257 1.70853C12.6725 0.763828 11.3259 0.763822 10.8727 1.70852L8.36302 6.94L2.5863 7.69743C1.54624 7.8338 1.12383 9.11702 1.89079 9.8412L6.11332 13.8282L5.05317 19.523C4.86026 20.5593 5.95592 21.345 6.87516 20.8488L11.9992 18.0825L17.1232 20.8488C18.0424 21.345 19.1381 20.5593 18.9452 19.523L17.885 13.8282L22.1076 9.8412C22.8745 9.11702 22.4521 7.8338 21.4121 7.69743L15.6353 6.94L13.1257 1.70853Z"
      />
    </svg>
  );
};

export default StarFilledIcon;
