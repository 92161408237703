import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const SmartFieldIcon: React.FC<IconProps> = ({
  applyCurrentColor = false,
  className
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.16666 6.00002C4.52314 6.00002 5.99999 4.52317 5.99999 1.16669C5.99999 4.52317 7.47684 6.00002 10.8333 6.00002C7.47684 6.00002 5.99999 7.47687 5.99999 10.8334C5.99999 7.47687 4.52314 6.00002 1.16666 6.00002Z"
        fill="#913AA6"
        vectorEffect="non-scaling-stroke"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.49999 11.6667C10.6991 11.6667 11.6667 10.6991 11.6667 8.50002C11.6667 10.6991 12.6342 11.6667 14.8333 11.6667C12.6342 11.6667 11.6667 12.6343 11.6667 14.8334C11.6667 12.6343 10.6991 11.6667 8.49999 11.6667Z"
        fill="#913AA6"
        vectorEffect="non-scaling-stroke"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.16666 6.00002C4.52314 6.00002 5.99999 4.52317 5.99999 1.16669C5.99999 4.52317 7.47684 6.00002 10.8333 6.00002C7.47684 6.00002 5.99999 7.47687 5.99999 10.8334C5.99999 7.47687 4.52314 6.00002 1.16666 6.00002Z"
        stroke="#913AA6"
        strokeWidth="1.5"
        strokeLinejoin="round"
        vectorEffect="non-scaling-stroke"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.49999 11.6667C10.6991 11.6667 11.6667 10.6991 11.6667 8.50002C11.6667 10.6991 12.6342 11.6667 14.8333 11.6667C12.6342 11.6667 11.6667 12.6343 11.6667 14.8334C11.6667 12.6343 10.6991 11.6667 8.49999 11.6667Z"
        stroke="#913AA6"
        strokeWidth="1.5"
        strokeLinejoin="round"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default SmartFieldIcon;
