import classNames from 'classnames';
import { IconProps } from './type';

const BellIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      className={classNames(applyCurrentColor && 'stroke-current', className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#111318"
      strokeWidth="1.5"
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M16.0001 18.25C15.3268 20.0159 13.7892 21.25 12.0001 21.25C10.211 21.25 8.67336 20.0159 8.00009 18.25M4.67431 18.25H19.3259C19.9417 18.25 20.4112 17.6987 20.3131 17.0908L18.9555 8.67345C18.4049 5.2596 15.4581 2.75 12.0001 2.75C8.54213 2.75 5.59532 5.2596 5.0447 8.67345L3.68706 17.0908C3.58901 17.6987 4.05848 18.25 4.67431 18.25Z"
      />
    </svg>
  );
};

export default BellIcon;
