import { useApolloClient } from '@apollo/client';
import {
  ConnectionIntegrationType,
  GetCurrentUserQuery
} from '__generated__/graphql';
import { GET_CURRENT_USER } from 'queries/getCurrentUser';

interface UseUserResponse {
  setIntegrationStatus: (
    type: ConnectionIntegrationType,
    isEnabled: boolean
  ) => void;
}
const useUser = (): UseUserResponse => {
  const client = useApolloClient(); // Get access to the Apollo Client instance

  const setIntegrationStatus = (
    type: ConnectionIntegrationType,
    isEnabled: boolean
  ) => {
    const currentUser = client.cache.readQuery<GetCurrentUserQuery>({
      query: GET_CURRENT_USER
    });

    if (currentUser) {
      const updatedIntegrationStatus =
        currentUser?.getCurrentUser?.connectionsIntegrationStatus?.map(
          (integration) => {
            if (integration?.type === type) {
              return {
                ...integration,
                isEnabled
              };
            }
            return integration;
          }
        );

      const updatedCurrentUser = {
        ...currentUser.getCurrentUser,
        connectionsIntegrationStatus: updatedIntegrationStatus
      };

      client.cache.writeQuery({
        query: GET_CURRENT_USER,
        data: {
          getCurrentUser: updatedCurrentUser
        }
      });
    }
  };

  return { setIntegrationStatus };
};

export default useUser;
