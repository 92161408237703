import { StoreApi, UseBoundStore } from 'zustand';
import { useShallow } from 'zustand/react/shallow';

export const useShallowStoreGeneric = <T>(
  useStore: UseBoundStore<StoreApi<T>>,
  keys: (keyof T)[]
): T => {
  const selector = (state: T) =>
    keys.reduce((selectorObject, key) => {
      selectorObject[key] = state[key];
      return selectorObject;
    }, {} as Partial<Record<keyof T, T[keyof T]>>);

  return useStore(useShallow(selector)) as T;
};
