import { lazy, Suspense, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import ErrorPage from 'components/common/ErrorPage';
import LoadingOverlay from 'components/common/ResultsWrapper/LoadingOverlay';
import UpgradeToProModal from 'components/common/UpgradeToProModal';
import Toast from 'harmonic-components/Toast/Toast';
import { useHasChangedRoute } from 'hooks/useHasChangedRoute';
import ModalProvider from 'hooks/useModal';
import AuthActionHandler from 'screens/Auth/AuthAction';
import PilotContractAgreement from 'screens/Auth/PilotContractAgreement/PilotContractAgreement';
import { RequireAuth } from 'screens/Auth/RequireAuth';
import useStore from 'stores/zustandStore';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import './styles/App.css';
import { lazyRetry } from './utils/lazyRetry';

const PAGES = {
  'dashboard/*': {
    Component: lazy(() =>
      lazyRetry(() => import('screens/Dashboard'), 'dashboard')
    ),
    preload: () => import('screens/Dashboard')
  },
  'settings/*': {
    Component: lazy(() =>
      lazyRetry(
        () => import('components/Dashboard/UserSettings'),
        'user-settings'
      )
    ),
    preload: () => import('components/Dashboard/UserSettings')
  },
  'docs/*': {
    Component: lazy(() => lazyRetry(() => import('screens/Docs'), 'docs')),
    preload: () => import('screens/Docs')
  },
  onboarding: {
    Component: lazy(() =>
      lazyRetry(
        () => import('components/Dashboard/Onboarding/Onboarding'),
        'onboarding'
      )
    ),
    preload: () => import('components/Dashboard/Onboarding/Onboarding')
  },
  login: {
    Component: lazy(() =>
      lazyRetry(() => import('screens/Auth/Login/LoginV2'), 'login')
    ),
    preload: () => import('screens/Auth/Login/LoginV2')
  },
  'pw-login': {
    Component: lazy(() =>
      lazyRetry(() => import('screens/Auth/Login/Login'), 'pw-login')
    ),
    preload: () => import('screens/Auth/Login/Login')
  },
  'forgot-password': {
    Component: lazy(() =>
      lazyRetry(() => import('screens/Auth/ForgotPassword'), 'forgot-password')
    ),
    preload: () => import('screens/Auth/ForgotPassword')
  },
  'user-status': {
    Component: lazy(() =>
      lazyRetry(() => import('screens/Auth/UserStatus'), 'user-status')
    ),
    preload: () => import('screens/Auth/UserStatus')
  },
  'email-verification': {
    Component: lazy(() =>
      lazyRetry(
        () => import('screens/Auth/EmailVerification'),
        'email-verification'
      )
    ),
    preload: () => import('screens/Auth/EmailVerification')
  }
};

Object.entries(PAGES).forEach(([, { preload }]) => {
  preload();
});

const Login = PAGES['pw-login'].Component;
const LoginV2 = PAGES['login'].Component;
const ForgotPasswordPage = PAGES['forgot-password'].Component;
const UserStatus = PAGES['user-status'].Component;
const EmailVerification = PAGES['email-verification'].Component;

const Dashboard = PAGES['dashboard/*'].Component;
const UserSettings = PAGES['settings/*'].Component;
const Docs = PAGES['docs/*'].Component;
const Onboarding = PAGES['onboarding'].Component;

const AppLayout: React.FC = () => {
  const editStoreData = useStore((state) => state.editStoreData);
  const isUpgradeToProModalOpen = useStore(
    (state) => state.isUpgradeToProModalOpen
  );

  const hasChangedRoute = useHasChangedRoute({
    excludeSearchParams: false
  });

  useEffect(() => {
    if (hasChangedRoute) {
      editStoreData('didChangeRoute', true);
    }
  }, [hasChangedRoute, editStoreData]);

  const upgradeModalMode = useStore((state) => state.upgradeModalMode);

  return (
    <ModalProvider>
      {/*
              TODO: We shouldn't be using MUI dialog here, we should be using the useModal hook
              and headlessui to create a modal component that is more accessible and customizable.
              However, this dialog is opened from different places and required a global state to
              be opened. We do not currently have a good mechanism for that, so leaving it as is for now.
      */}
      <UpgradeToProModal
        isOpen={isUpgradeToProModalOpen}
        mode={upgradeModalMode}
        handleClose={() => editStoreData('isUpgradeToProModalOpen', false)}
      />
      <Toast />

      <Suspense fallback={<LoadingOverlay />}>
        <Routes>
          <Route path="login" element={<LoginV2 />} />
          <Route path="signup" element={<Navigate to="login" />} />
          <Route path="pw-login" element={<Login />} />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="user-status" element={<UserStatus />} />
          <Route path="email-verification" element={<EmailVerification />} />
          <Route path="auth-action" element={<AuthActionHandler />} />
          <Route
            path="accept-pilot-agreement"
            element={<PilotContractAgreement />}
          />
          <Route
            path="onboarding"
            errorElement={<ErrorPage />}
            element={
              <RequireAuth>
                <Onboarding />
              </RequireAuth>
            }
          />
          <Route
            path="dashboard/*"
            errorElement={<ErrorPage />}
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route
            path="settings/*"
            errorElement={<ErrorPage />}
            element={
              <RequireAuth>
                <QueryParamProvider adapter={ReactRouter6Adapter}>
                  <UserSettings />
                </QueryParamProvider>
              </RequireAuth>
            }
          />
          <Route
            path="docs/*"
            errorElement={<ErrorPage />}
            element={<Docs />}
          />
          <Route path="*" element={<Navigate to="login" />} />
        </Routes>
      </Suspense>
    </ModalProvider>
  );
};

export default AppLayout;
