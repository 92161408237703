import classNames from 'classnames';
import { IconProps } from './type';

const ArrowBoxLeft: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      stroke="#7C8598"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M16.875 9.99999L7.5 9.99999M16.875 9.99999L13.125 13.75M16.875 9.99999L13.125 6.25M9.375 16.875H3.95833C3.4981 16.875 3.125 16.5019 3.125 16.0417L3.125 3.95833C3.125 3.4981 3.4981 3.125 3.95833 3.125L9.375 3.125"
      />
    </svg>
  );
};

export default ArrowBoxLeft;
