import { gql } from '@apollo/client';
import { SavedSearchFragment } from './savedSearchFragment';

export const CREATE_SAVED_SEARCH = gql`
  mutation CreateSavedSearch($savedSearchInput: SavedSearchInput!) {
    createSavedSearch(savedSearchInput: $savedSearchInput) {
      ...FullSavedSearch
    }
  }
  ${SavedSearchFragment}
`;
