import { useQuery } from '@apollo/client';
import { Avatar as OldAvatar, TextField, makeStyles } from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import PersonIcon from '@material-ui/icons/Person';
import SecurityIcon from '@material-ui/icons/Security';
import { Autocomplete } from '@material-ui/lab';
import {
  GetAllTeamMembersWithIntegratedEmailsByCustomerQuery,
  GetAllTeamMembersWithIntegratedEmailsByCustomerQueryVariables,
  GetCompaniesViewRecordsQuery,
  GetCompaniesViewRecordsQueryVariables,
  GetPersonContactInformationQuery,
  GetPersonContactInformationQueryVariables,
  PeopleSearchExperienceFragment,
  PersonUpdateEvent,
  SearchType
} from '__generated__/graphql';
import { ICellRendererParams } from 'ag-grid-community';
import { AffinityIcon } from 'assets/harmonic-icons';
import CalendarDaysIcon from 'assets/harmonic-icons/calendar-days';
import classNames from 'classnames';
import { Badge, Tooltip } from 'common/components';
import useNetNewCounts from 'components/Dashboard/Collections/Searches/NetNew/useNetNewCounts';
import { CompanyNavbarOptions } from 'components/Dashboard/CompanyV2/CompanyHeader/CompanyHeader';
import PersonEmailUnlockModal from 'components/common/ContactData/PersonEmailUnlockModal';
import { getHighlightIcon } from 'components/common/Highlights/HighlightsChip';
import InternalLink from 'components/common/InternalLink';
import {
  CompanySearchGridContext,
  CompanyWatchlistGridContext
} from 'components/common/ResultsWrapper/GridResultsView';
import Avatar from 'harmonic-components/Avatar/Avatar';
import Checkbox from 'harmonic-components/Checkbox/Checkbox';
import CompanyAttribute from 'harmonic-components/CompanyAttribute/CompanyAttribute';
import Email from 'harmonic-components/Email/Email';
import LinkAttribute from 'harmonic-components/LinkAttribute/LinkAttribute';
import PersonAttribute from 'harmonic-components/PersonAttribute/PersonAttribute';
import Tag from 'harmonic-components/Tag/Tag';
import TruncatedList from 'harmonic-components/TruncatedList/TruncatedList';
import useDashboardLocation, {
  DashboardLocation
} from 'hooks/useDashboardLocation';
import useFlags from 'hooks/useFlags';
import { useModal } from 'hooks/useModal';
import { ICompanyViewRecord } from 'interfaces/DataModel/ApiResource';
import { ICompany, IInvestor } from 'interfaces/DataModel/Company';
import { ISchool } from 'interfaces/DataModel/Education';
import { EventTypeToDescriptionMapping } from 'interfaces/DataModel/Event';
import {
  PersonExperienceDataTimeTypes,
  PersonExperienceDataTypes
} from 'interfaces/DataModel/Person';
import { ITypedTag } from 'interfaces/DataModel/TypedTag';
import { DerivedMetricType, ITableHeader } from 'interfaces/Grid';
import { SearchValueToDisplayNameMap } from 'interfaces/SearchModel/Search';
import {
  compact,
  defaultTo,
  get,
  isArray,
  toInteger,
  toNumber,
  uniq
} from 'lodash';
import { GET_ALL_TEAM_MEMBERS_WITH_INTEGRATED_EMAILS } from 'queries/getAllTeamMembersWithIntegratedEmails';
import { getCompaniesViewRecords } from 'queries/getCompanyViewRecords';
import getPersonContactInformation from 'queries/getPersonContactInformation';
import * as React from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { AppState } from 'store';
import useTableStore, { useShallowTableStore } from 'stores/tableStore';
import useStore from 'stores/zustandStore';
import styled from 'styled-components';
import analytics, { CustomTrackEvent, EventLocations } from 'utils/analytics';
import { shouldLinkCompanyId } from 'utils/company';
import {
  DASHBOARD_ROUTE,
  PEOPLE_WATCHLIST_ROUTE,
  SPLITS,
  WATCHLIST_ROUTE
} from 'utils/constants';
import { ColorShorthand, stringToExpressiveNumber } from 'utils/design';
import { getIdFromUrn } from 'utils/urn';
import {
  addCommasToNumber,
  isSafeHostedAsset,
  truncateMoneyValue
} from 'utils/utilities';
import { dayjsExt } from '../../../../config/dayjs';
import { getPeopleHighlights } from '../../../../utils/cellRendererUtils';
import BlankCellRenderer from './BlankCellRenderer';
import { CompanyHighlights } from './components/CompanyHighlights';
import { DateCell } from './components/DateCell';
import { LongText } from './components/LongText';
import { PeopleList } from './components/PeopleList';
import { RelevanceScore } from './components/RelevanceScore';
import { Tags } from './components/Tags';
import { UserConnections } from './components/UserConnections';

export const useStyles = makeStyles((theme) => ({
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  'internal-link': {
    cursor: 'pointer',
    textDecoration: 'none'
  },
  link: {
    cursor: 'pointer'
  },
  longText: {
    whiteSpace: 'pre-wrap',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    display: '-webkit-box'
  },
  rowHeightSmall: {
    whiteSpace: 'pre-wrap',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1
  },
  rowHeightMedium: {
    whiteSpace: 'pre-wrap',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3
  },
  rowHeightTall: {
    whiteSpace: 'pre-wrap',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 6
  },
  popover: {
    pointerEvents: 'none'
  },
  paper: {
    padding: theme.spacing(1),
    maxWidth: '50%'
  },
  logo: {
    height: '30px',
    width: '30px'
  },
  logoLarge: {
    height: '50px',
    width: '50px'
  },
  profilePicture: {
    height: '30px',
    width: '30px',
    borderRadius: '30px',
    marginRight: '10px'
  },
  breakWord: {
    wordBreak: 'break-word'
  },
  empty: {
    color: '#cccbca',
    display: 'flex',
    alignItems: 'center',
    height: '100%'
  },
  checkbox: {
    width: '20px',
    height: '20px'
  },
  'derived-metric-real-change-positive': {
    color: '#2DA72E'
  },
  'derived-metric-real-change-negative': {
    color: '#E01E5A'
  },
  'derived-metric-sparkline-container': {
    width: 100
  }
}));

const CompanyLogoCellWrapper = styled.div`
  margin-right: 10px;
`;

const textColorContentClass = 'text-content-default typography-label';

export const LoadingCellRenderer = () => {
  return (
    <div
      data-testid="LoadingCellRenderer"
      className="animate-pulse h-8 w-full rounded-br20 bg-gray-200"
    />
  );
};

export type ICellLoadingRendererProps = ICellRendererParams & {
  timeFrame?: PersonExperienceDataTimeTypes;
  dataToFetch?: PersonExperienceDataTypes;
};

export interface ListCellRendererItem {
  text: string;
  tooltip?: NonNullable<React.ReactNode>;
  logoUrl?: string;
  href?: string;
}

export const TagsCellRenderer: React.FC<ICellRendererParams> = (props) => {
  return <Tags {...props} />;
};

export const TypedTagsCellRenderer: React.FC<ICellRendererParams> = (props) => {
  const typedTags: ITypedTag[] = defaultTo(props.value, []);
  const tags: string[] = typedTags.map((tag) => tag.tag_value);
  return (
    <div className="inline-block">
      <TruncatedList height={54}>
        {tags.map((tag, index) => {
          return (
            <div key={index}>
              <Tag
                label={tag}
                color={
                  `expressive-${stringToExpressiveNumber(
                    tag
                  )}` as ColorShorthand
                }
              />
            </div>
          );
        })}
      </TruncatedList>
    </div>
  );
};

export const CompanyHighlightsCellRenderer: React.FC<ICellRendererParams> = (
  props: ICellRendererParams
) => {
  return <CompanyHighlights {...props} />;
};

export const PeopleListCellRenderer: React.FC<ICellRendererParams> = (
  props: ICellRendererParams
) => {
  return <PeopleList {...props} />;
};

export const CustomerTypeCellRenderer: React.FC<ICellRendererParams> = (
  props: ICellRendererParams
) => {
  const customerType: string[] = defaultTo(props.value, []);

  if (!customerType) {
    return <NoDataSubRenderer />;
  }

  return (
    <div className="inline-block">
      <TruncatedList height={54}>
        {customerType.map((type, index) => {
          return (
            <div key={index}>
              <Tag
                label={type}
                color={
                  `expressive-${stringToExpressiveNumber(
                    type
                  )}` as ColorShorthand
                }
              />
            </div>
          );
        })}
      </TruncatedList>
    </div>
  );
};

export const EmailsCellRenderer: React.FC<ICellRendererParams> = (
  props: ICellRendererParams
) => {
  const emails: string[] = defaultTo(props.value, []);
  if (emails.length === 0) {
    return <NoDataSubRenderer />;
  }

  return (
    <div className="inline-block">
      <TruncatedList height={54}>
        {emails.map((email, index) => (
          <div key={index}>
            <Email email={email} collapsedInward />
          </div>
        ))}
      </TruncatedList>
    </div>
  );
};

export const InvestorsCellRenderer: React.FC<ICellRendererParams> = (
  props: ICellRendererParams
) => {
  const investors: IInvestor[] = defaultTo(props.value, []).filter(
    (investor: IInvestor) => investor?.name
  );

  if (investors.length === 0) {
    return <NoDataSubRenderer />;
  }

  return (
    <div className="inline-block">
      <TruncatedList height={54}>
        {investors.map((investor, index) => {
          const investorId = getIdFromUrn(investor?.investor_urn);
          const typeToUse = (investor.type || investor.typename).toLowerCase();
          let url;
          if (investorId) {
            url = `/dashboard/investor/${investorId}`;
          } else {
            url = typeToUse ? `/dashboard/${typeToUse}/${investor.id}` : '';
          }

          return (
            <div key={index} data-testid="investor-item">
              {typeToUse === 'person' ? (
                <PersonAttribute
                  src={investor.logo_url ?? ''}
                  href={url}
                  name={investor.name}
                />
              ) : (
                <CompanyAttribute
                  src={investor.logo_url ?? ''}
                  href={url}
                  name={investor.name}
                />
              )}
            </div>
          );
        })}
      </TruncatedList>
    </div>
  );
};

export const PeopleHighlightsCellRenderer: React.FC<ICellRendererParams> = (
  props: ICellRendererParams
) => {
  const highlights = get(props, 'value');
  const allHighlights = getPeopleHighlights(highlights);

  if (allHighlights.length === 0) {
    return <NoDataSubRenderer />;
  }
  return (
    <div className="inline-block">
      <TruncatedList height={54}>
        {allHighlights.map((highlight, index: number) => {
          return (
            <Badge
              key={index}
              leadingIcon={getHighlightIcon(highlight.category)}
              label={highlight.category}
              color="expressive-32"
              size="large"
              suffix={highlight.count > 1 ? `${highlight.count}` : ''}
            />
          );
        })}
      </TruncatedList>
    </div>
  );
};

export const EducationCellRenderer: React.FC<ICellRendererParams> = (
  props: ICellRendererParams
) => {
  const education = defaultTo(props.value, []);
  const res: string[] = education
    .map(
      (educationExperience: { school: ISchool }) =>
        educationExperience?.school?.name
    )
    .filter((item: string) => Boolean(item));

  if (education.length === 0) {
    return <NoDataSubRenderer />;
  }

  return (
    <div className="inline-block">
      <TruncatedList height={54}>
        {res.map((item, index) => (
          <div key={index}>
            <Tag label={item} />
          </div>
        ))}
      </TruncatedList>
    </div>
  );
};

export const CompanyNameWithCheckboxCellRenderer: React.FC<
  ICellRendererParams
> = (props: ICellRendererParams) => {
  const { enabled } = useFlags(SPLITS.enableNetNew);
  const pageTitle = useStore((state) => state.pageTitle);
  const editStoreData = useStore((state) => state.editStoreData);
  const company: ICompany = get(props.node.data, 'company');
  const currentUrl: string = window.location.pathname;
  const companyId = get(company, 'id', undefined);
  const companyName = get(company, 'name', '');
  const { urn, location: dashboardLocation } = useDashboardLocation();
  const isSearchPage = dashboardLocation == DashboardLocation.COMPANY_SEARCH;
  const classes = useStyles();
  const location = useLocation();
  const addSelectedRowIds = useTableStore((state) => state.addSelectedRowIds);
  const removeSelectedRowIds = useTableStore(
    (state) => state.removeSelectedRowIds
  );
  const selectedRowIds = useTableStore((state) => state.selectedRowIds);
  const selectAllStateEnabled = useTableStore(
    (state) => state.selectAllStateEnabled
  );

  const params = new URLSearchParams(window.location.search || '');

  const logoUrl = get(company, 'logo_url');

  // net new stuff
  const { getNetNewCountForSearch, onClearNetNewById } = useNetNewCounts();
  const countExists = !!getNetNewCountForSearch(urn || '');
  const netNewId: number | undefined = get(props.data, 'netNewId');
  const isNetNew: boolean = useMemo(() => {
    return !!(enabled && countExists && netNewId);
  }, [enabled, countExists, netNewId]);

  const context = props.context as
    | CompanyWatchlistGridContext
    | CompanySearchGridContext
    | undefined;
  const companyViewRecordResponse = useQuery<
    GetCompaniesViewRecordsQuery,
    GetCompaniesViewRecordsQueryVariables
  >(getCompaniesViewRecords, {
    variables: { ids: [companyId as number], extended: false },
    fetchPolicy: 'cache-only',
    skip: !companyId
  });

  if (!companyId) return <LoadingCellRenderer />;

  const isCheckboxChecked =
    selectedRowIds.includes(companyId) || selectAllStateEnabled;
  const isExplorePage = isSearchPage && (urn === '' || !urn);

  // Since we don't have a boolean to check viewed state, we need to differentiate
  // between "data isn't ready yet in cache" and "no view record exists". If the data
  // response isn't available at all, we can assume it hasn't loaded in cache yet.
  // Unfortuntately companyViewRecordResponse.loading will return false before it's actually
  // done loading, so we can't depend on that.
  const viewRecordLoading = !companyViewRecordResponse.data;
  const companyViewRecord =
    companyViewRecordResponse.data?.getCompaniesByIds?.[0]?.viewRecord;

  const onCheckboxClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.node.setSelected(event.target.checked);
    if (event.target.checked) {
      addSelectedRowIds([companyId]);
    } else {
      removeSelectedRowIds([companyId]);
    }
  };
  return (
    <div className="w-full flex flex-col">
      <div className="flex items-center gap-g50 w-full">
        {!currentUrl.includes('/person/') && (
          <CheckboxSubRenderer
            onCheckboxClick={onCheckboxClick}
            isChecked={isCheckboxChecked}
          />
        )}
        <div className="flex w-full flex-row justify-between items-center">
          {shouldLinkCompanyId(companyId) ? (
            <InternalLink
              onContextMenu={() => {
                // Mark as viewed if they right click (such as to open in new tab)
                if (!viewRecordLoading && !companyViewRecord) {
                  context?.setViewed([companyId]);
                }
              }}
              onClick={(e) => {
                e.stopPropagation();
                editStoreData(
                  'previousPageTitle',
                  isExplorePage ? 'Explore' : pageTitle
                );
                if (isNetNew && urn && netNewId)
                  onClearNetNewById({
                    id: netNewId,
                    savedSearchUrn: urn,
                    type: SearchType.COMPANIES_LIST
                  });
                // We explicitly mark viewed so that people opening in new tab
                // will immediately see that company marked as viewed.
                if (!viewRecordLoading && !companyViewRecord) {
                  context?.setViewed([companyId]);
                }
                analytics.trackCustomEvent({
                  event: CustomTrackEvent.COMPANY_PROFILE_CLICK,
                  properties: {
                    companyId,
                    openedFromLocation: EventLocations.GRID_TABLE
                  }
                });
              }}
              className={`${classes['internal-link']} typography-label text-content-strong`}
              to={{
                pathname: `/dashboard/company/${companyId}`
              }}
              data-testid="CompanyNameCellRenderer-Name"
              state={{
                from: location.pathname,
                params: params.toString()
              }}
              openedFromLocation={
                EventLocations.GRID_TABLE_COMPANY_NAME_COMPANY_SEARCH
              }
            >
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <Avatar
                    src={logoUrl}
                    kind="logo"
                    size="medium"
                    alt="company-logo"
                  />
                </div>
                <div
                  data-testid="CompanyNameCellRenderer-Name"
                  className="line-clamp-1 pl-p30"
                >
                  <p
                    className={classNames(
                      'typography-label text-content-strong'
                    )}
                  >
                    {companyName}
                  </p>
                </div>
              </div>
            </InternalLink>
          ) : (
            <span>{companyName}</span>
          )}
        </div>
        <div
          onClick={() =>
            !viewRecordLoading && !companyViewRecord
              ? context?.setViewed([companyId])
              : context?.setUnviewed([companyId])
          }
          className={classNames('w-2 h-2 mr-2 rounded-full flex-shrink-0', {
            'bg-surface-sentiment-highlight-informative':
              !viewRecordLoading && !companyViewRecord
          })}
        />
      </div>
      {isNetNew && (
        <div className="ml-[28px] flex mt-g30">
          <Badge label="New" color="expressive-37" intensity="subtle" />
        </div>
      )}
    </div>
  );
};

export const PersonNameWithCheckboxCellRenderer: React.FC<
  ICellRendererParams
> = (props: ICellRendererParams) => {
  const { enabled } = useFlags(SPLITS.enableNetNew);
  const pageTitle = useStore((state) => state.pageTitle);
  const editStoreData = useStore((state) => state.editStoreData);
  const personId = get(props, 'data.person.id');
  const personUpdates: PersonUpdateEvent[] = compact(
    get(props, 'data.person.updates') || []
  );
  const personName = get(props, 'value');
  const { urn, location } = useDashboardLocation();
  const {
    addSelectedRowIds,
    selectedRowIds,
    selectAllStateEnabled,
    removeSelectedRowIds
  } = useShallowTableStore([
    'addSelectedRowIds',
    'selectedRowIds',
    'selectAllStateEnabled',
    'removeSelectedRowIds'
  ]);
  const isCheckboxChecked =
    selectedRowIds.includes(personId) || selectAllStateEnabled;
  const isSearchPage = location == DashboardLocation.PEOPLE_SEARCH;
  const isExplorePage = isSearchPage && (urn === '' || !urn);

  // net new stuff
  const { getNetNewCountForSearch, onClearNetNewById } = useNetNewCounts();
  const countExists = !!getNetNewCountForSearch(urn || '');
  const netNewId: number | undefined = get(props.data, 'netNewId');
  const isNetNew: boolean = useMemo(() => {
    return !!(enabled && countExists && netNewId);
  }, [enabled, countExists, netNewId]);

  const showPersonUpdateChip =
    location === DashboardLocation.PERSON_LIST && personUpdates.length > 0;
  const jobUpdatedChip = (
    <Badge color="informative" label="New update" size="large" />
  );
  const tooltipText = (
    <>
      {personUpdates.map((update, index) => (
        <>
          <span key={index} className="typography-label-small-default">
            {update.helperText ?? ''}
          </span>
          <br />
        </>
      ))}
    </>
  );

  const onCheckboxClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.node.setSelected(event.target.checked);
    if (event.target.checked) {
      addSelectedRowIds([personId]);
    } else {
      removeSelectedRowIds([personId]);
    }
  };

  const logoUrl = get(props, 'data.person.profile_picture_url');
  const logoUrlGql = get(props, 'data.profilePictureUrl');
  const profilePictureUrl = logoUrl || logoUrlGql;

  return (
    <div>
      <div className="flex justify-start items-start gap-g50 w-full">
        <CheckboxSubRenderer
          isChecked={isCheckboxChecked}
          onCheckboxClick={onCheckboxClick}
        />
        <div className="flex flex-col gap-g30">
          <div>
            {personId ? (
              <Link
                onClick={() => {
                  editStoreData(
                    'previousPageTitle',
                    isExplorePage ? 'Explore' : pageTitle
                  );
                  if (isNetNew && urn && netNewId)
                    onClearNetNewById({
                      id: netNewId,
                      savedSearchUrn: urn,
                      type: SearchType.PERSONS
                    });
                  analytics.trackCustomEvent({
                    event: CustomTrackEvent.PERSON_PROFILE_CLICK,
                    properties: {
                      personId,
                      openedFromLocation: EventLocations.GRID_TABLE
                    }
                  });
                }}
                to={`/dashboard/person/${personId}`}
              >
                <div className="flex items-start">
                  <Avatar
                    kind="face"
                    size="medium"
                    src={profilePictureUrl}
                    alt="person-profile-picture"
                  />
                  <span className="typography-label text-content-strong line-clamp-1 pl-p30">
                    {personName}
                  </span>
                </div>
              </Link>
            ) : (
              <span className="typography-label">{personName}</span>
            )}
          </div>
          <div>
            {showPersonUpdateChip && (
              <Tooltip body={tooltipText} alignment="right">
                <div className="inline-block">{jobUpdatedChip}</div>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      {isNetNew && (
        <div className="ml-[28px] flex mt-g30">
          <Badge label="New" color="expressive-37" intensity="subtle" />
        </div>
      )}
    </div>
  );
};

export const LogoCellRenderer: React.FC<ICellRendererParams> = (
  props: ICellRendererParams
) => {
  const gridRowHeight = useTableStore((state) => state.gridRowHeight);

  const logoUrl = get(props, 'data.company.logo_url');
  const isStealthCompany = get(props, 'node.data.company.name')?.startsWith(
    'Stealth Company'
  );
  const [imageError, setImageError] = React.useState(false);

  const classes = useStyles();
  const logoClassName = `${
    gridRowHeight > 65 ? classes.logoLarge : classes.logo
  }`;

  return logoUrl && isSafeHostedAsset(logoUrl) && !imageError ? (
    <div>
      <img
        onError={() => setImageError(true)}
        className={logoClassName}
        src={logoUrl}
      />
    </div>
  ) : (
    <div>
      {
        // Do not render until data is loaded
        props.node.data && (
          <OldAvatar className={logoClassName} variant="rounded">
            {isStealthCompany ? <SecurityIcon /> : <BusinessIcon />}
          </OldAvatar>
        )
      }
    </div>
  );
};

export const ProfilePictureCellRenderer: React.FC<ICellRendererParams> = (
  props: ICellRendererParams
) => {
  const logoUrl = get(props, 'data.person.profile_picture_url');
  const logoUrlGql = get(props, 'data.profilePictureUrl');
  const profilePictureUrl = logoUrl || logoUrlGql;
  const [imageError, setImageError] = React.useState(false);

  const classes = useStyles();

  return profilePictureUrl &&
    isSafeHostedAsset(profilePictureUrl) &&
    !imageError ? (
    <div>
      <img
        onError={() => setImageError(true)}
        className={classes.profilePicture}
        src={profilePictureUrl}
      />
    </div>
  ) : (
    <div>
      {
        // Do not render until data is loaded
        props.node.data && (
          <OldAvatar className={classes.profilePicture} variant="circular">
            {<PersonIcon />}
          </OldAvatar>
        )
      }
    </div>
  );
};

export const CompanyNameCellRenderer: React.FC<ICellRendererParams> = (
  props: ICellRendererParams
) => {
  const companyId = get(props, 'data.company.id');
  const companyName = get(props, 'data.company.name');
  const classes = useStyles();
  const location = useLocation();

  const params = new URLSearchParams(window.location.search || '');

  return (
    <div className={`${classes.longText} ${classes.ellipsis}`}>
      {shouldLinkCompanyId(companyId) ? (
        <Link
          className={classes.link}
          to={`/dashboard/company/${companyId}`}
          state={{
            from: location.pathname,
            params: params.toString(),
            openedFromLocation:
              EventLocations.GRID_TABLE_COMPANY_NAME_PERSON_SEARCH
          }}
        >
          <div className="flex items-center">
            <CompanyLogoCellWrapper>
              <LogoCellRenderer {...props} />
            </CompanyLogoCellWrapper>
            {companyName}
          </div>
        </Link>
      ) : (
        <span>{companyName}</span>
      )}
    </div>
  );
};

export const StageCellRenderer: React.FC<ICellRendererParams> = (props) => {
  const fundingFieldValue = props.value;
  const stage = get(SearchValueToDisplayNameMap, fundingFieldValue || '');

  if (!stage) {
    return <NoDataSubRenderer />;
  }

  return (
    <div className="inline-block">
      <Badge
        label={stage}
        color={
          `expressive-${stringToExpressiveNumber(stage)}` as ColorShorthand
        }
        size="large"
      />
    </div>
  );
};

export const AffinityListsCellRenderer: React.FC<ICellRendererParams> = (
  props: ICellRendererParams
) => {
  const company: ICompany = get(props, 'node.data.company');
  const affinityLists: { name: string }[] = defaultTo(props.value, []);

  const { enabled: affinityIntegrationMocked } = useFlags(
    SPLITS.mockAffinityFrontend
  );

  const displayAffinityFieldsDrawer = useStore(
    (state) => state.displayAffinityFieldsDrawer
  );

  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    displayAffinityFieldsDrawer(company?.id);
  };

  if (affinityIntegrationMocked) {
    return (
      <TruncatedList height={54}>
        <Tag label="Affinity List 1" onClick={onClick} />
      </TruncatedList>
    );
  }

  if (affinityLists.length === 0) {
    return <NoDataSubRenderer />;
  }

  return (
    <div className="inline-block">
      <TruncatedList height={54}>
        {affinityLists.map((list, index) => {
          return (
            <div key={index}>
              <Tag label={list.name} onClick={onClick} />
            </div>
          );
        })}
      </TruncatedList>
    </div>
  );
};

export const ListsCellRenderer: React.FC<ICellRendererParams> = (
  props: ICellRendererParams
) => {
  const company: ICompany = get(props, 'node.data.company');
  const allLists: {
    name: string;
    isAffinityList: boolean;
    entityUrn: string;
  }[] = defaultTo(props.value, []);
  const affinityLists = allLists.filter((list) => list?.isAffinityList);
  const otherLists = allLists.filter((list) => !list?.isAffinityList);
  const { enabled: affinityIntegrationMocked } = useFlags(
    SPLITS.mockAffinityFrontend
  );

  const displayAffinityFieldsDrawer = useStore(
    (state) => state.displayAffinityFieldsDrawer
  );

  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    displayAffinityFieldsDrawer(company?.id);
  };

  if (allLists.length === 0) {
    return <NoDataSubRenderer />;
  }

  return (
    <div className="inline-block">
      <TruncatedList height={54}>
        {otherLists.map((list, index) => {
          return (
            <Link
              to={`${DASHBOARD_ROUTE}${WATCHLIST_ROUTE}/${list.entityUrn}`}
              key={index}
            >
              <Badge size="large" borderRadius="square" label={list.name} />
            </Link>
          );
        })}
        {affinityLists.map((list, index) => {
          return (
            <div key={index} onClick={onClick}>
              <Badge
                size="large"
                label={list.name}
                trailingIcon={AffinityIcon}
              />
            </div>
          );
        })}
      </TruncatedList>
    </div>
  );
};

export const PeopleListsCellRenderer: React.FC<ICellRendererParams> = (
  props: ICellRendererParams
) => {
  const person: ICompany = get(props, 'node.data.person');
  const allLists: {
    name: string;
    isAffinityList: boolean;
    entityUrn: string;
  }[] = defaultTo(props.value, []);

  if (allLists.length === 0) {
    return <NoDataSubRenderer />;
  }

  return (
    <div className="inline-block">
      <TruncatedList height={54}>
        {allLists.map((list, index) => {
          return (
            <Link
              to={`${DASHBOARD_ROUTE}${PEOPLE_WATCHLIST_ROUTE}/${list.entityUrn}`}
              key={index}
            >
              <Badge size="large" borderRadius="square" label={list.name} />
            </Link>
          );
        })}
      </TruncatedList>
    </div>
  );
};

export type PersonExperienceDataCellRendererProps = ICellRendererParams & {
  timeFrame: PersonExperienceDataTimeTypes;
  dataToFetch: PersonExperienceDataTypes;
};

export const PersonExperienceDataCellRenderer: React.FC<
  PersonExperienceDataCellRendererProps
> = (
  props: ICellRendererParams & {
    timeFrame?: PersonExperienceDataTimeTypes;
    dataToFetch?: PersonExperienceDataTypes;
  }
) => {
  const experience = get(props, 'node.data.person.experience');
  if (!experience || experience.length === 0) {
    return <NoDataSubRenderer />;
  }
  let filteredExperiences = experience
    .filter((experienceObj: PeopleSearchExperienceFragment) => experienceObj)
    .filter((experienceObj: PeopleSearchExperienceFragment) =>
      props.timeFrame === PersonExperienceDataTimeTypes.CURRENT
        ? experienceObj?.isCurrentPosition
        : !experienceObj?.isCurrentPosition
    );
  if (props.timeFrame === PersonExperienceDataTimeTypes.PREVIOUS) {
    const lastExperience = filteredExperiences.sort(
      (
        left: PeopleSearchExperienceFragment,
        right: PeopleSearchExperienceFragment
      ) => {
        dayjsExt.utc(left.startDate).diff(dayjsExt.utc(right.startDate));
      }
    );
    filteredExperiences = lastExperience[0] ? [lastExperience[0]] : [];
  }

  const currentDepartments: string[] = uniq(
    compact(
      filteredExperiences.map(
        (experience: PeopleSearchExperienceFragment) => experience.department
      )
    )
  );

  switch (props.dataToFetch) {
    case PersonExperienceDataTypes.NAME: {
      const filteredExperiencesWithUniqueCompanies = Array.from(
        filteredExperiences.reduce(
          (
            idMap: Map<number, PeopleSearchExperienceFragment>,
            current: PeopleSearchExperienceFragment
          ) => {
            if (idMap.has(current.company.id)) {
              return idMap;
            }
            idMap.set(current.company.id, current);
            return idMap;
          },
          new Map()
        ),
        ([, value]: [number, PeopleSearchExperienceFragment]) => value
      );
      const data = filteredExperiencesWithUniqueCompanies.map(
        (experience: PeopleSearchExperienceFragment) => {
          return {
            text: experience.company.name ?? 'Unknown company',
            imageUrl: experience.company.logoUrl,
            id: experience.company.id
          };
        }
      );
      if (data.length === 0) {
        return <NoDataSubRenderer />;
      }
      return (
        <div className="inline-block">
          <TruncatedList height={54}>
            {data.map((item, index) => (
              <div key={index}>
                <CompanyAttribute
                  href={`/dashboard/company/${item.id}`}
                  disabled={!shouldLinkCompanyId(item.id)}
                  name={item.text}
                  src={item.imageUrl ?? ''}
                />
              </div>
            ))}
          </TruncatedList>
        </div>
      );
    }
    case PersonExperienceDataTypes.TITLE:
      return (
        <div className="inline-block">
          <TruncatedList height={54}>
            {filteredExperiences.map(
              (experience: PeopleSearchExperienceFragment, index: number) => (
                <div key={index}>
                  <Tag label={experience?.title ?? 'Unknown'} />
                </div>
              )
            )}
          </TruncatedList>
        </div>
      );
    case PersonExperienceDataTypes.DEPARTMENT:
      return (
        <div className="inline-block">
          <TruncatedList height={54}>
            {currentDepartments.map((department, index) => (
              <div key={index}>
                <Tag
                  label={department}
                  color={
                    `expressive-${stringToExpressiveNumber(
                      department
                    )}` as ColorShorthand
                  }
                />
              </div>
            ))}
          </TruncatedList>
        </div>
      );
    case PersonExperienceDataTypes.TAGS: {
      const tags: ListCellRendererItem[] = filteredExperiences.reduce(
        (
          acc: ListCellRendererItem[],
          experience: PeopleSearchExperienceFragment
        ) => {
          if (!experience || !experience.company) {
            return acc;
          }
          if (
            !experience.company.tags ||
            experience.company.tags?.length === 0
          ) {
            return acc;
          }

          const companyTags = experience.company.tags
            .map((x) => {
              if (x) return x.displayValue;
            })
            .filter((x) => x);

          return [
            ...acc,
            ...companyTags
              .filter((tag) => Boolean(tag))
              .map((tag) => ({ text: tag }))
          ];
        },
        []
      );
      if (tags.length === 0) {
        return <NoDataSubRenderer />;
      }
      return (
        <div className="inline-block">
          <TruncatedList height={54}>
            {tags.map((tag, index) => (
              <div key={index}>
                <Tag
                  label={tag.text}
                  color={
                    `expressive-${stringToExpressiveNumber(
                      tag.text
                    )}` as ColorShorthand
                  }
                />
              </div>
            ))}
          </TruncatedList>
        </div>
      );
    }
    default:
      return <NoDataSubRenderer />;
  }
};

export const PersonNameCellRenderer: React.FC<ICellRendererParams> = (
  props: ICellRendererParams
) => {
  const personId = get(props, 'data.person.id') || get(props, 'data.id');
  const personName = get(props, 'value');
  const classes = useStyles();
  return (
    <div
      className={`${classes.longText} ${classes.ellipsis} ${textColorContentClass}`}
    >
      {personId ? (
        <Link
          onClick={() => {
            analytics.trackCustomEvent({
              event: CustomTrackEvent.PERSON_PROFILE_CLICK,
              properties: {
                personId,
                openedFromLocation: EventLocations.GRID_TABLE
              }
            });
          }}
          className={classNames(classes.link, 'no-underline')}
          to={`/dashboard/person/${personId}`}
        >
          <div className="flex items-center">
            <ProfilePictureCellRenderer {...props} />
            <div>
              <span className="underline typography-label"> {personName}</span>
            </div>
          </div>
        </Link>
      ) : (
        <span className="typography-label">{personName}</span>
      )}
    </div>
  );
};

export const DateCellRenderer: React.FC<ICellRendererParams> = (
  props: ICellRendererParams
) => {
  return <DateCell {...props} />;
};

export const NumberCellRenderer: React.FC<ICellRendererParams> = (
  props: ICellRendererParams
) => {
  const number = get(props, 'value');

  if (number !== undefined && number !== null) {
    return (
      <div className="inline-block">
        <span className="typography-label-default-number text-content-default">
          {addCommasToNumber(number.toFixed(0))}
        </span>
      </div>
    );
  }
  return <NoDataSubRenderer />;
};

export const PhoneNumbersCellRenderer: React.FC<ICellRendererParams> = (
  props: ICellRendererParams
) => {
  const phoneNumbers: string[] = defaultTo(props.value, []);
  if (phoneNumbers.length === 0) {
    return <NoDataSubRenderer />;
  }

  return (
    <div className="inline-block">
      <TruncatedList height={54}>
        {phoneNumbers.map((phoneNumber, index) => (
          <div key={index}>
            <span className="typography-label-default-number text-content-default">
              {phoneNumber}
            </span>
          </div>
        ))}
      </TruncatedList>
    </div>
  );
};

interface LongTextCellRendererParams {
  hidePlaceholder?: boolean;
}
export const LongTextCellRenderer: React.FC<ICellRendererParams> = (
  props: ICellRendererParams & LongTextCellRendererParams
) => {
  return <LongText {...props} />;
};

export const LinkCellRenderer: React.FC<ICellRendererParams> = (
  props: ICellRendererParams
) => {
  const url = get(props, 'value');
  const isCompany = Boolean(props.data?.company?.id);
  const context = props.context as
    | CompanyWatchlistGridContext
    | CompanySearchGridContext
    | undefined;

  const onLinkClicked = () => {
    const field = (props.column?.getDefinition() as ITableHeader).field;
    analytics.trackCustomEvent({
      event: CustomTrackEvent.EXTERNAL_LINK_CLICKED,
      properties: {
        entityField: field,
        url
      }
    });

    if (isCompany) {
      context?.setViewed([props.data.company.id]);
    }
  };

  if (url) {
    return (
      <LinkAttribute
        onClick={onLinkClicked}
        link={url}
        dataTestId="LinkCellRenderer-Link"
      />
    );
  }
  return <NoDataSubRenderer />;
};

export const CompanyTypeCellRenderer: React.FC<ICellRendererParams> = (
  props: ICellRendererParams
) => {
  const companyType = props.value;

  return companyType ? (
    <Tag
      label={get(SearchValueToDisplayNameMap, companyType, companyType)}
      color={
        `expressive-${stringToExpressiveNumber(companyType)}` as ColorShorthand
      }
    />
  ) : (
    <NoDataSubRenderer />
  );
};

export const EmailForPeopleSearchCellRenderer: React.FC<ICellRendererParams> = (
  props: ICellRendererParams
) => {
  const personId = props.node.data?.person?.id as number;
  const { data: personContactInformation } = useQuery<
    GetPersonContactInformationQuery,
    GetPersonContactInformationQueryVariables
  >(getPersonContactInformation, {
    variables: {
      id: personId
    },
    fetchPolicy: 'cache-only'
  });
  const emails = compact(
    personContactInformation?.getPersonById?.contact?.emails || []
  );
  const emailsEnrichedAt =
    personContactInformation?.getPersonById?.emailEnrichmentStatus?.enrichedAt;
  const isRequestable = Boolean(emails.length === 0 && !emailsEnrichedAt);
  const isUnavailable = Boolean(emails.length === 0 && emailsEnrichedAt);
  const { show } = useModal();

  if (isUnavailable) {
    return <NoDataSubRenderer />;
  }

  if (isRequestable) {
    return (
      <Tag
        label="Request"
        onClick={() => show(<PersonEmailUnlockModal personId={personId} />)}
      />
    );
  }

  return (
    <div className="inline-flex">
      <TruncatedList height={54}>
        {emails.map((email, index) => (
          <div key={index}>
            <Email email={email} collapsedInward />
          </div>
        ))}
      </TruncatedList>
    </div>
  );
};

export const EventTypeCellRenderer: React.FC<ICellRendererParams> = (
  props: ICellRendererParams
) => {
  const event_type_str = get(props, 'value');
  const eventDate = new Date(get(props, 'data.observed_at'));

  const classes = useStyles();

  if (event_type_str) {
    return (
      <div className={`${classes.ellipsis} flex items-center`}>
        {eventDate.toLocaleString('en-US', {
          timeZone: 'UTC',
          month: 'short',
          day: 'numeric'
        })}
        : {get(EventTypeToDescriptionMapping, event_type_str)}
      </div>
    );
  }
  return <React.Fragment />;
};

export const FundingCellRenderer: React.FC<ICellRendererParams> = (
  props: ICellRendererParams
) => {
  const fundingField = props.colDef?.field;
  const fundingFieldValue = props.value;
  const fundingAttributeNullStatus =
    props.node.data?.company?.funding_attribute_null_status;
  if (!fundingFieldValue && fundingAttributeNullStatus) {
    return (
      <div className="typography-label">
        {get(SearchValueToDisplayNameMap, fundingAttributeNullStatus)}
      </div>
    );
  }
  if (fundingField === 'company.last_funding_at') {
    return <DateCellRenderer {...props} />;
  }
  if (
    fundingField === 'company.funding_total' ||
    fundingField === 'company.last_funding_total' ||
    fundingField === 'company.funding_per_employee' ||
    fundingField?.includes('funding_total')
  ) {
    return <MoneyCellRenderer {...props} />;
  }
  if (
    fundingField === 'company.investors' &&
    (!fundingFieldValue ||
      (isArray(fundingFieldValue) && fundingFieldValue.length === 0))
  ) {
    return (
      <div className="typography-label">
        {get(SearchValueToDisplayNameMap, fundingAttributeNullStatus)}
      </div>
    );
  }

  return <LongTextCellRenderer {...props} />;
};

export const AcceleratorsCellRenderer: React.FC<ICellRendererParams> = (
  props: ICellRendererParams
) => {
  const accelerators: string[] = defaultTo(props.value, []);
  if (accelerators.length === 0) {
    return <NoDataSubRenderer />;
  }

  return (
    <div className="inline-block">
      <TruncatedList height={54}>
        {accelerators.map((accelerator, index) => {
          return (
            <div key={index}>
              <Tag label={accelerator} />
            </div>
          );
        })}
      </TruncatedList>
    </div>
  );
};

export const MoneyCellRenderer: React.FC<ICellRendererParams> = (
  props: ICellRendererParams
) => {
  const amount = toInteger(get(props, 'value'));

  if (amount !== undefined) {
    return (
      <div className="inline-block">
        <div className="flex items-center gap-g10">
          <span className="typography-label text-content-weak">$</span>
          <p className="typography-label-default-number text-content-default">
            {truncateMoneyValue(amount, undefined, false)}
          </p>
        </div>
      </div>
    );
  }
  return <NoDataSubRenderer />;
};

interface NoDataSubRendererProps {
  type?: 'default' | 'connections';
}

export const NoDataSubRenderer: React.FC<NoDataSubRendererProps> = ({
  type
}: NoDataSubRendererProps) => {
  return (
    <div className="inline-block">
      <span className="typography-paragraph text-content-muted">
        {type === 'connections' ? 'Not in network' : 'No data'}
      </span>
    </div>
  );
};

export const DerivedMetricCellRenderer: React.FC<ICellRendererParams> = (
  props: ICellRendererParams
) => {
  const field = props.column?.getColDef().field;
  const value = toNumber(props.value);

  const metricType = field?.includes('.real_') // the type of metric, e.g. real growth, percent growth
    ? DerivedMetricType.RealChange
    : field?.includes('.percent_')
    ? DerivedMetricType.PercentChange
    : DerivedMetricType.Snapshot;

  if (
    (props.value === null || props.value === undefined || isNaN(props.value)) &&
    metricType !== DerivedMetricType.Snapshot
  ) {
    return <NoDataSubRenderer />;
  }

  if (metricType === DerivedMetricType.RealChange) {
    if (value > 0) {
      return (
        <div className="inline-block">
          <Badge
            color="positive"
            size="large"
            intensity="subtle"
            label={`▲ ${addCommasToNumber(value.toFixed(0))}`}
          />
        </div>
      );
    }
    if (value < 0) {
      return (
        <div className="inline-block">
          <Badge
            color="negative"
            size="large"
            intensity="subtle"
            label={`▼ ${addCommasToNumber((value * -1).toFixed(0))}`}
          />
        </div>
      );
    }

    return <NumberCellRenderer {...props} />;
  }

  if (metricType === DerivedMetricType.PercentChange) {
    if (value > 0) {
      return (
        <div className="inline-block">
          <Badge
            color="positive"
            size="large"
            intensity="subtle"
            label={`▲ ${addCommasToNumber(value.toFixed(2))}%`}
          />
        </div>
      );
    }
    if (value < 0) {
      return (
        <div className="inline-block">
          <Badge
            color="negative"
            size="large"
            intensity="subtle"
            label={`▼ ${(value * -1).toFixed(2)}%`}
          />
        </div>
      );
    }
    if (value === 0) {
      return (
        <div className="inline-block">
          <Badge
            color="neutral"
            size="large"
            intensity="subtle"
            label={`${value.toFixed(0)}%`}
          />
        </div>
      );
    }
  }

  return (
    <span className="typography-label-default-number text-content-default">
      {isNaN(value) ? '0' : addCommasToNumber(value.toFixed(0))}
    </span>
  );
};

export const RelevanceScoreCellRenderer: React.FC<ICellRendererParams> = (
  props: ICellRendererParams
) => {
  return <RelevanceScore {...props} />;
};

interface CheckboxSubRendererProps {
  isChecked: boolean;
  onCheckboxClick: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const CheckboxSubRenderer: React.FC<CheckboxSubRendererProps> = ({
  isChecked,
  onCheckboxClick
}) => {
  const { selectAllStateEnabled } = useShallowTableStore([
    'selectAllStateEnabled'
  ]);

  return (
    <Checkbox
      dataTestId="company-select-checkbox"
      checked={isChecked}
      disabled={selectAllStateEnabled}
      onChange={onCheckboxClick}
    />
  );
};

export const UserConnectionsCellRenderer: React.FC<ICellRendererParams> = (
  props: ICellRendererParams
) => {
  return <UserConnections {...props} />;
};

export const CorrespondencePersonCellRenderer: React.FC<ICellRendererParams> = (
  props: ICellRendererParams
) => {
  const emailAddress = props.value;

  const authState = useSelector<AppState, AppState['auth']>(
    (state) => state.auth
  );
  const customerUrn = authState.userMetadata?.customer_urn ?? '';

  const companyId = get(props, 'data.company.id');
  const personId = get(props, 'data.person.id');

  const { data: usersData } = useQuery<
    GetAllTeamMembersWithIntegratedEmailsByCustomerQuery,
    GetAllTeamMembersWithIntegratedEmailsByCustomerQueryVariables
  >(GET_ALL_TEAM_MEMBERS_WITH_INTEGRATED_EMAILS, {
    variables: { customer_urn: customerUrn },
    fetchPolicy: 'cache-only'
  });

  if (!emailAddress) {
    return <NoDataSubRenderer />;
  }

  const displayName =
    usersData?.getAllTeamMembersWithIntegratedEmailsByCustomer?.find((user) =>
      user?.integratedEmails?.some(
        (integratedEmail) => integratedEmail === emailAddress
      )
    )?.name;

  return (
    <div className="flex">
      <span title={emailAddress}>
        <PersonAttribute
          src=""
          href={
            companyId
              ? `/dashboard/company/${companyId}?selectedTab=${CompanyNavbarOptions.CONNECTIONS}`
              : `/dashboard/person/${personId}`
          }
          name={displayName ?? emailAddress}
        />
      </span>
    </div>
  );
};

export const CorrespondenceDateCellRenderer: React.FC<ICellRendererParams> = (
  props: ICellRendererParams
) => {
  const date = props.value;
  const companyId = get(props, 'data.company.id');

  return date ? (
    <a
      href={`/dashboard/company/${companyId}?selectedTab=${CompanyNavbarOptions.CONNECTIONS}`}
    >
      <Tag
        leadingIcon={CalendarDaysIcon}
        onClick={() => null}
        label={dayjsExt.utc(date).format('MM/DD/YYYY')}
      />
    </a>
  ) : (
    <NoDataSubRenderer />
  );
};

export const ViewRecordCellRenderer: React.FC<ICellRendererParams> = (
  props: ICellRendererParams
) => {
  const viewRecord: ICompanyViewRecord = props.value;
  return !viewRecord ? (
    <div>Unviewed</div>
  ) : (
    <div className="text-gray-400">
      {dayjsExt.utc(viewRecord.updated_at).format('MM/DD/YYYY')}
    </div>
  );
};

export const LocationCellRenderer: React.FC<ICellRendererParams> = (
  props: ICellRendererParams
) => {
  const location = props.value;
  if (!location) {
    return <NoDataSubRenderer />;
  }

  return (
    <div className="inline-block">
      <Tag label={location} />
    </div>
  );
};

type SelectOption = {
  label: string;
  value: string;
};

export type SelectEditCellRendererProps = {
  value: string;
  options: SelectOption[];
  placeholder?: string;
};

type SelectEditCellRendererRef = {
  getValue(): string;
  isCancelBeforeStart(): boolean;
};

const AutoCompleteWrapper = styled.div`
  width: 150%;
  border-width: 0px;
  .MuiInput-underline:before,
  .MuiInput-underline:hover,
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-width: 0px;
  }
`;
export const SelectEditCellRenderer = React.forwardRef<
  SelectEditCellRendererRef,
  SelectEditCellRendererProps
>((props, ref) => {
  const [value, setValue] = React.useState<string>(props.value);
  const [inputValue, setInputValue] = React.useState('');

  const [open, setOpen] = React.useState(true);

  const onChangeHandler = (
    _: React.ChangeEvent<unknown>,
    newValue: SelectOption
  ) => {
    setValue(newValue.value);
  };

  const onInputChangeHandler = (
    _: React.ChangeEvent<unknown>,
    inputValue: string
  ) => {
    setInputValue(inputValue);
  };

  /* Component Editor Lifecycle methods */
  React.useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },

      // Gets called once before editing starts, to give editor a chance to
      // cancel the editing before it even starts.
      isCancelBeforeStart() {
        return false;
      }
    };
  });

  return (
    <AutoCompleteWrapper>
      <Autocomplete
        open={open}
        style={{ padding: '0 10px', width: '150%' }}
        classes={{
          input: 'typography-label-small',
          option: 'typography-label'
        }}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        options={props.options}
        getOptionLabel={(option) => option.label}
        //eslint-disable-next-line
        //@ts-ignore
        value={value}
        onChange={onChangeHandler}
        inputValue={inputValue}
        onInputChange={onInputChangeHandler}
        disableClearable
        renderInput={(params) => (
          <TextField
            {...params}
            InputLabelProps={{
              className: 'text-xs'
            }}
            variant="standard"
            inputProps={{
              ...params.inputProps,
              className: 'typography-label',
              disableUnderline: true
            }}
            placeholder={props.placeholder || 'Select options'}
          />
        )}
      />
    </AutoCompleteWrapper>
  );
});

export const CellRenderers = {
  LogoCellRenderer: LogoCellRenderer,
  CompanyNameCellRenderer: CompanyNameCellRenderer,
  PersonNameCellRenderer: PersonNameCellRenderer,
  DateCellRenderer: DateCellRenderer,
  CompanyTypeCellRenderer: CompanyTypeCellRenderer,
  LinkCellRenderer: LinkCellRenderer,
  LongTextCellRenderer: LongTextCellRenderer,
  CompanyNameWithCheckboxCellRenderer: CompanyNameWithCheckboxCellRenderer,
  PersonNameWithCheckboxCellRenderer: PersonNameWithCheckboxCellRenderer,
  EventTypeCellRenderer: EventTypeCellRenderer,
  FundingCellRenderer: FundingCellRenderer,
  MoneyCellRenderer: MoneyCellRenderer,
  ProfilePictureCellRenderer: ProfilePictureCellRenderer,
  DerivedMetricCellRenderer: DerivedMetricCellRenderer,
  PersonExperienceDataCellRenderer: PersonExperienceDataCellRenderer,
  UserConnectionsCellRenderer: UserConnectionsCellRenderer,
  ViewRecordCellRenderer: ViewRecordCellRenderer,
  SelectEditCellRenderer: SelectEditCellRenderer,
  RelevanceScoreCellRenderer: RelevanceScoreCellRenderer,
  LocationCellRenderer: LocationCellRenderer,
  PeopleListCellRenderer: PeopleListCellRenderer,
  CompanyHighlightsCellRenderer: CompanyHighlightsCellRenderer,
  TypedTagsCellRenderer: TypedTagsCellRenderer,
  TagsCellRenderer: TagsCellRenderer,
  StageCellRenderer: StageCellRenderer,
  AffinityListsCellRenderer: AffinityListsCellRenderer,
  ListsCellRenderer: ListsCellRenderer,
  CustomerTypeCellRenderer: CustomerTypeCellRenderer,
  InvestorsCellRenderer: InvestorsCellRenderer,
  NumberCellRenderer: NumberCellRenderer,
  AcceleratorsCellRenderer: AcceleratorsCellRenderer,
  PhoneNumbersCellRenderer: PhoneNumbersCellRenderer,
  PeopleHighlightsCellRenderer: PeopleHighlightsCellRenderer,
  EducationCellRenderer: EducationCellRenderer,
  CorrespondenceDateCellRenderer: CorrespondenceDateCellRenderer,
  CorrespondencePersonCellRenderer: CorrespondencePersonCellRenderer,
  BlankCellRenderer: BlankCellRenderer,
  EmailsCellRenderer: EmailsCellRenderer,
  EmailForPeopleSearchCellRenderer: EmailForPeopleSearchCellRenderer,
  LoadingCellRenderer: LoadingCellRenderer,
  PeopleListsCellRenderer: PeopleListsCellRenderer
};
