import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const MoneyIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      fill="#493AA6"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        vectorEffect="non-scaling-stroke"
        d="M0.666504 4.16667C0.666504 3.52233 1.18884 3 1.83317 3H14.1665C14.8108 3 15.3332 3.52233 15.3332 4.16667V11.8333C15.3332 12.4777 14.8108 13 14.1665 13H1.83317C1.18884 13 0.666504 12.4777 0.666504 11.8333V4.16667ZM2.49984 4.66667C2.22369 4.66667 1.99984 4.89052 1.99984 5.16667C1.99984 5.44281 2.22369 5.66667 2.49984 5.66667H3.1665C3.44265 5.66667 3.6665 5.44281 3.6665 5.16667C3.6665 4.89052 3.44265 4.66667 3.1665 4.66667H2.49984ZM12.3332 10.8333C12.3332 10.5572 12.557 10.3333 12.8332 10.3333H13.4998C13.776 10.3333 13.9998 10.5572 13.9998 10.8333C13.9998 11.1095 13.776 11.3333 13.4998 11.3333H12.8332C12.557 11.3333 12.3332 11.1095 12.3332 10.8333ZM6.33317 8C6.33317 7.07953 7.07936 6.33333 7.99984 6.33333C8.92031 6.33333 9.6665 7.07953 9.6665 8C9.6665 8.92047 8.92031 9.66667 7.99984 9.66667C7.07936 9.66667 6.33317 8.92047 6.33317 8Z"
      />
    </svg>
  );
};

export default MoneyIcon;
