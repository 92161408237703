import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import {
  AuthErrorCodes,
  AuthThunkAction,
  LoginResponse,
  LogoutReason,
  SigninData,
  UserStatusType
} from 'interfaces/Auth';

import { TeamRole } from '__generated__/graphql';
import { getApp } from 'firebase/app';
import {
  getAuth,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  UserCredential
} from 'firebase/auth';
import { AppDispatch, AppState } from 'store';
import usePersistedZustandStore from 'stores/persistedZustandStore';
import {
  HARMONIC_CUSTOMER_URN,
  LOCAL_AGAINST_PROD,
  SESSION_EXPIRED_ERROR_MESSAGE
} from 'utils/constants';
import { logger } from 'utils/logger';
import { authenticate } from 'utils/midtierApi';
import { getIdFromUrn } from 'utils/urn';
import {
  LOGOUT_USER,
  RESET_DASHBOARD,
  SET_USER,
  SET_USER_API_KEY,
  SET_USER_ERROR,
  SET_USER_LOADING,
  SET_USER_METADATA,
  SET_USER_ROLE,
  SET_USER_STATUS,
  SET_USER_SUCCESS
} from './types/actionTypes';

export const setLoading = (value: boolean): AuthThunkAction => {
  return (dispatch: AppDispatch) => {
    dispatch({ type: SET_USER_LOADING, payload: value });
  };
};

interface ILoginErrorProps {
  functionName: string;
  error: AxiosError;
  onError: () => void;
  dispatch: AppDispatch;
}

const loginError = ({ error, onError, dispatch }: ILoginErrorProps) => {
  logger.error(`Error login user: ${error && (error as Error).message}`, {
    error,
    code_area: 'login'
  });
  onError();
  if (error.code === AuthErrorCodes.USER_NOT_FOUND) {
    return dispatch(
      setError(
        'This email isn’t connected to an account. <a class="text-blue-dark focus:underline" href="/signup">Sign up?</a>'
      )
    );
  }
  if (error.code === AuthErrorCodes.INVALID_PASSWORD) {
    return dispatch(
      setError('The password is incorrect for this email address.')
    );
  }
  dispatch(setError(error.message));
};

export const logout = (
  reason: LogoutReason = LogoutReason.UserLoggedOut
): AuthThunkAction => {
  return async (dispatch: AppDispatch, getState: () => AppState) => {
    if (!getState().auth.isAuthenticated) {
      return;
    }
    dispatch({ type: LOGOUT_USER });
    dispatch({ type: RESET_DASHBOARD });
    try {
      const auth = getAuth(getApp());

      await signOut(auth);
      switch (reason) {
        case LogoutReason.SessionExpired:
          toast.dark(SESSION_EXPIRED_ERROR_MESSAGE, {
            autoClose: false
          });
          logger.error(`User session expired`, {
            code_area: 'logout'
          });
          break;
        case LogoutReason.UserLoggedOut:
        default:
          toast.info('You have been successfully logged out.');
          break;
      }
      usePersistedZustandStore.getState().reset();
    } catch (error) {
      logger.error(
        `Error logging user out: ${error && (error as Error).message}`,
        {
          error,
          code_area: 'logout'
        }
      );
    }
  };
};

export const setError = (msg: string): AuthThunkAction => {
  return (dispatch: AppDispatch) => {
    dispatch({ type: SET_USER_ERROR, payload: msg });
  };
};

const setSuccess = (msg: string): AuthThunkAction => {
  return (dispatch: AppDispatch) => {
    dispatch({ type: SET_USER_SUCCESS, payload: msg });
  };
};

export const setUserApiKey = (token: string): AuthThunkAction => {
  return (dispatch: AppDispatch) => {
    dispatch({ type: SET_USER_API_KEY, payload: token });
  };
};

export const setUserStatus = (status: UserStatusType): AuthThunkAction => {
  return (dispatch: AppDispatch) => {
    dispatch({ type: SET_USER_STATUS, payload: status });
  };
};

export const setUserRole = (role: TeamRole): AuthThunkAction => {
  return (dispatch: AppDispatch) => {
    dispatch({ type: SET_USER_ROLE, payload: role });
  };
};

export const sendPasswordResetEmailHandler = (
  email: string,
  successMsg: string,
  onSuccess: () => void,
  onError: () => void
): AuthThunkAction => {
  const auth = getAuth(getApp());

  return async (dispatch: AppDispatch) => {
    dispatch({ type: SET_USER_LOADING, payload: true });
    try {
      await sendPasswordResetEmail(auth, email);
      dispatch(setSuccess(successMsg));
      onSuccess();
    } catch (error: unknown) {
      onError();
      logger.error(
        `Error resetting user password: ${error && (error as Error).message}`,
        {
          error,
          code_area: 'reset_password'
        }
      );
      dispatch(setError((error as Error).message));
    }
  };
};

// ========= V2 AUTH ACTIONS =============

const signInToHarmonic = async (dispatch: AppDispatch) => {
  const authenticateResponse = await authenticate();
  const firebaseAuth = getAuth(getApp());
  const firebaseUser = firebaseAuth.currentUser;

  if (LOCAL_AGAINST_PROD) {
    if (authenticateResponse.customer !== HARMONIC_CUSTOMER_URN) {
      throw new Error('Can only login to Harmonic users in local environment.');
    }
  }

  if (firebaseUser && authenticateResponse.apikey) {
    dispatch(setUserApiKey(authenticateResponse.apikey));
    dispatch(setUserStatus(authenticateResponse.status as UserStatusType));
    dispatch(setUserRole(authenticateResponse.role as TeamRole));
    dispatch({
      type: SET_USER,
      payload: {
        email: firebaseUser.email,
        emailVerified: firebaseUser.emailVerified,
        user_id: firebaseUser.uid,
        displayName: authenticateResponse.name || firebaseUser.displayName
      }
    });

    dispatch({
      type: SET_USER_METADATA,
      payload: {
        is_admin: false,
        user_id: getIdFromUrn(authenticateResponse.entity_urn),
        user_urn: authenticateResponse.entity_urn,
        customer_urn: authenticateResponse.customer,
        customer_name: authenticateResponse.customer_name,
        settings: authenticateResponse.settings
      }
    });
  }

  return { authenticateResponse };
};

export const signin = (
  data: SigninData,
  onSuccess: (harmonicLoginResult: LoginResponse) => void,
  onError: (error?: AxiosError) => void
): AuthThunkAction => {
  return async (dispatch: AppDispatch) => {
    dispatch({ type: SET_USER_ERROR, payload: '' });
    dispatch({ type: SET_USER_LOADING, payload: true });
    const { email, password } = data;
    const auth = getAuth(getApp());
    try {
      const response = await signInWithEmailAndPassword(auth, email, password);
      if (!response?.user?.emailVerified) {
        sendEmailVerification(response.user);
        dispatch(
          setError(
            'Email verification required. Check your inbox for a new link.'
          )
        );
        onError();
      }
    } catch (error) {
      const functionName = 'authActions/signin';
      loginError({
        functionName,
        error: error as AxiosError,
        onError,
        dispatch
      });
      return;
    }

    const { authenticateResponse } = await signInToHarmonic(dispatch);

    try {
      onSuccess({
        status: 'ok',
        apiKey: authenticateResponse.apikey
      });
    } catch (error) {
      onError(error as AxiosError);
    }

    dispatch({ type: SET_USER_LOADING, payload: false });
  };
};

export const loginWithSSO = (
  response: UserCredential,
  onSuccess: (harmonicLoginResult: LoginResponse) => void,
  onError: (error?: AxiosError) => void
): AuthThunkAction => {
  return async (dispatch: AppDispatch) => {
    dispatch({ type: SET_USER_ERROR, payload: '' });
    dispatch({ type: SET_USER_LOADING, payload: true });
    if (!response?.user?.emailVerified) {
      sendEmailVerification(response.user);
      dispatch(
        setError(
          'Email verification required. Check your inbox for a new link.'
        )
      );
      onError();
    }

    try {
      const { authenticateResponse } = await signInToHarmonic(dispatch);
      onSuccess({
        status: 'ok',
        apiKey: authenticateResponse.apikey
      });
    } catch (error) {
      onError(error as AxiosError);
    }

    dispatch({ type: SET_USER_LOADING, payload: false });
  };
};

export const setOnboardingSuccess = (successState = true): AuthThunkAction => {
  return async (dispatch: AppDispatch, getState: () => AppState) => {
    const existingUserMetadata = getState().auth.userMetadata;
    dispatch({
      type: SET_USER_METADATA,
      payload: {
        ...existingUserMetadata,
        settings: {
          ...existingUserMetadata?.settings,
          has_completed_onboarding: successState
        }
      }
    });
  };
};
