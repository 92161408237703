import { CalendarCheckIcon } from 'assets/harmonic-icons';
import dayjs from 'dayjs';
import ListItem, { ListVariant } from 'harmonic-components/ListItem/ListItem';
import { dayjsExt } from '../../../../config/dayjs';
import {
  DateTypeEnum,
  InternalModeType,
  OneSidedRangeValue,
  TwoSidedRangeValue
} from './DateModal';

interface SideActionsProps {
  onChange: (value: OneSidedRangeValue | TwoSidedRangeValue) => void;
  mode: InternalModeType;
  dateType: DateTypeEnum;
}
const SideActions: React.FC<SideActionsProps> = ({
  onChange,
  mode,
  dateType
}) => {
  const onSingleDateChange = (date: Date) => {
    onChange(dayjs(date).format('YYYY-MM-DD'));
  };

  const onRangeDateChange = (startDate: Date, endDate: Date) => {
    onChange([
      dayjs(startDate).format('YYYY-MM-DD'),
      dayjs(endDate).format('YYYY-MM-DD')
    ]);
  };
  const beforeOrAfterActions = (
    <>
      <ListItem
        variant={ListVariant.default}
        primaryIcon={CalendarCheckIcon}
        value="Today"
        label="Today"
        onClick={() => {
          onSingleDateChange(dayjs().toDate());
        }}
      />
      <ListItem
        variant={ListVariant.default}
        primaryIcon={CalendarCheckIcon}
        value="Yesterday"
        label="Yesterday"
        onClick={() => {
          onSingleDateChange(dayjs().subtract(1, 'day').toDate());
        }}
      />
      <ListItem
        variant={ListVariant.default}
        primaryIcon={CalendarCheckIcon}
        value="Last week"
        label="Last week"
        onClick={() => {
          const oneWeekAgo = dayjsExt().subtract(1, 'week').startOf('isoWeek'); // default to monday as start of week
          onSingleDateChange(oneWeekAgo.toDate());
        }}
      />
      <ListItem
        variant={ListVariant.default}
        primaryIcon={CalendarCheckIcon}
        value="Last month"
        label="Last month"
        onClick={() => {
          const oneMonthAgo = dayjs().subtract(1, 'month').startOf('month');
          onSingleDateChange(oneMonthAgo.toDate());
        }}
      />
      <ListItem
        variant={ListVariant.default}
        primaryIcon={CalendarCheckIcon}
        value="Last year"
        label="Last year"
        onClick={() => {
          const firstDayOfLastYear = dayjs()
            .subtract(1, 'year')
            .startOf('year');
          onSingleDateChange(firstDayOfLastYear.toDate());
        }}
      />
    </>
  );

  const rangeActions = (
    <>
      <ListItem
        variant={ListVariant.default}
        primaryIcon={CalendarCheckIcon}
        value="This week"
        label="This week"
        onClick={() => {
          const today = dayjs();
          const startOfWeek = today.clone().startOf('week');
          onRangeDateChange(startOfWeek.toDate(), today.toDate());
        }}
      />
      <ListItem
        variant={ListVariant.default}
        primaryIcon={CalendarCheckIcon}
        value="This month"
        label="This month"
        onClick={() => {
          const today = dayjs();
          const startOfWeek = today.clone().startOf('month');
          onRangeDateChange(startOfWeek.toDate(), today.toDate());
        }}
      />
      <ListItem
        variant={ListVariant.default}
        primaryIcon={CalendarCheckIcon}
        value="This quarter"
        label="This quarter"
        onClick={() => {
          const startOfWeek = dayjs().clone().startOf('quarter');
          onRangeDateChange(startOfWeek.toDate(), dayjs().toDate());
        }}
      />
      <ListItem
        variant={ListVariant.default}
        primaryIcon={CalendarCheckIcon}
        value="This year"
        label="This year"
        onClick={() => {
          const startOfWeek = dayjs().startOf('year');
          onRangeDateChange(startOfWeek.toDate(), dayjs().toDate());
        }}
      />
    </>
  );

  const yearBeforeOrAfterActions = (isBefore: boolean) => {
    const currentYear = dayjs().year();

    // create a list of years from current year to 5 years ago
    const years = Array.from({ length: 5 }, (_, i) => currentYear - i);

    return (
      <>
        {years.map((year) => (
          <ListItem
            key={year}
            variant={ListVariant.default}
            primaryIcon={CalendarCheckIcon}
            value={year.toString()}
            label={year.toString()}
            onClick={() => {
              isBefore
                ? onSingleDateChange(dayjs().year(year).endOf('year').toDate())
                : onSingleDateChange(
                    dayjs().year(year).startOf('year').toDate()
                  );
            }}
          />
        ))}
      </>
    );
  };

  const yearRangeActions = (
    <>
      {[2, 3, 4, 5, 10].map((years) => {
        const label = `Last ${years} years`;
        return (
          <ListItem
            key={years}
            variant={ListVariant.default}
            primaryIcon={CalendarCheckIcon}
            value={label}
            label={label}
            onClick={() => {
              const today = dayjs();
              const startOfYear = today
                .clone()
                .subtract(years, 'year')
                .startOf('year');
              const endOfYear = today.clone().endOf('year');
              onRangeDateChange(startOfYear.toDate(), endOfYear.toDate());
            }}
          />
        );
      })}
    </>
  );

  if (dateType === DateTypeEnum.YEAR) {
    return (
      <div>
        {mode === 'range' && yearRangeActions}
        {(mode === 'before' || mode === 'after') &&
          yearBeforeOrAfterActions(mode === 'before')}
      </div>
    );
  }

  return (
    <div>
      {mode === 'range' && rangeActions}
      {(mode === 'before' || mode === 'after') && beforeOrAfterActions}
    </div>
  );
};

export default SideActions;
