import { useQuery } from '@apollo/client';
import {
  GetCompanyFinancingV2Query,
  GetCompanyFinancingV2QueryVariables
} from '__generated__/graphql';
import { ReactComponent as BoxDollarCircle } from 'assets/bxs-dollar-circle.svg';
import MetricCard from 'components/common/CompanyCard/MetricCard';
import { getCompanyFinancing } from 'queries/getCompanyFinancingV2';

import { getFormattedFundingTotal } from 'utils/funding';

interface FundingTotalProps {
  companyId: number;
}
const FundingTotal: React.FC<FundingTotalProps> = ({ companyId }) => {
  const { data } = useQuery<
    GetCompanyFinancingV2Query,
    GetCompanyFinancingV2QueryVariables
  >(getCompanyFinancing, {
    variables: { id: companyId },
    fetchPolicy: 'cache-only'
  });
  const companyFunding = data?.getCompanyById?.funding;
  const fundingTotal = getFormattedFundingTotal(
    companyFunding?.fundingTotal,
    null
  );
  return (
    <MetricCard
      icon={BoxDollarCircle}
      value={fundingTotal}
      label="Funding total"
    />
  );
};

export default FundingTotal;
