import classNames from 'classnames';
import { HarmonicIcon } from './type';

const CrossIcon: HarmonicIcon = ({ applyCurrentColor = true, className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#484E5B"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M4.75 4.75L19.25 19.25M19.25 4.75L4.75 19.25"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CrossIcon;
