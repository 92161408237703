import { gql } from '@apollo/client';

export const getCompanyNotes = gql`
  query GetCompanyNotes($id: Int!) {
    getCompanyById(id: $id) {
      id
      userNotes
      teamNotes
    }
  }
`;
