import { useQuery } from '@apollo/client';
import {
  GetCompaniesAllEntityActionsQuery,
  GetCompaniesAllEntityActionsQueryVariables
} from '__generated__/graphql';
import { HeartIcon } from 'assets/harmonic-icons';
import HeartFilledIcon from 'assets/harmonic-icons/heart-filled';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { useCompanyEntityActions } from 'hooks/useCompanyEntityActions';
import { getCompaniesAllEntityActions } from 'queries/getCompaniesAllEntityActions';
import React, { useMemo } from 'react';
import CompanyIconButtonActionLoader from './CompanyIconButtonActionLoader';

interface LikeCompanyButtonProps {
  companyId: number;
}

const LikeCompanyButton: React.FC<LikeCompanyButtonProps> = ({ companyId }) => {
  const { likeCompanies, unlikeCompanies } = useCompanyEntityActions();

  const { data } = useQuery<
    GetCompaniesAllEntityActionsQuery,
    GetCompaniesAllEntityActionsQueryVariables
  >(getCompaniesAllEntityActions, {
    variables: { ids: [companyId], extended: false },
    fetchPolicy: 'cache-only'
  });

  const isLiked = useMemo(
    () =>
      data?.getCompaniesByIds?.map(
        (company) => company?.isLikedByCurrentUser === true
      )?.[0],
    [data?.getCompaniesByIds]
  );

  const handleClick = () => {
    if (isLiked) {
      unlikeCompanies([companyId]);
      return;
    }
    likeCompanies([companyId]);
  };

  if (isLiked === undefined) return <CompanyIconButtonActionLoader />;

  return (
    <IconButton
      icon={isLiked ? HeartFilledIcon : HeartIcon}
      onClick={handleClick}
      type="secondary"
      emphasis="high"
      size="default"
      dataTestId="LikeCompanyButton"
    />
  );
};

export default LikeCompanyButton;
