import { ParticipantResponseStatus } from '__generated__/graphql';
import { ReactComponent as AnonymousPersonLogo } from 'assets/anonymous-person-logo.svg';
import defaultPersonLogo from 'assets/default-person-logo.svg';
import { LinkedInIcon } from 'assets/harmonic-icons';
import { IconProps } from 'assets/harmonic-icons/type';
import classNames from 'classnames';
import AddPeopleToWatchlist from 'components/common/AddPeopleToWatchlist';
import ContactButton from 'components/common/ContactData/ContactButton';
import GetPersonEmailButton from 'components/common/ContactData/GetEmailButton';
import AvatarWithHighlightBadge from 'components/common/Highlights/AvatarWithHighlightBadge';
import InternalLink from 'components/common/InternalLink';
import PersonUserConnections from 'components/common/PersonUserConnections';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { compact, trim } from 'lodash';
import {
  getDisplayNameFromParticipantList,
  getParticipantResponseStatusMap
} from 'utils/userConnections';
import { isSafeHostedAsset } from 'utils/utilities';
import { PersonWithConnections } from '../CompanyContact/UserConnectionsList';
import CalendarEventCard from './CalendarEventCard';
import EmailMessageCard from './EmailMessageCard';

interface PersonConnectionCardProps {
  personWithConnections: PersonWithConnections;
  currentCompanyId: number;
}

const PersonConnectionCard: React.FC<PersonConnectionCardProps> = ({
  personWithConnections,
  currentCompanyId
}) => {
  const person = personWithConnections.person;
  const personEmail = personWithConnections.personEmail;
  const userConnections = personWithConnections.userConnections;
  const current = person?.experience?.find((exp) => {
    return exp?.isCurrentPosition && exp.company.id === currentCompanyId;
  });
  const currentTitle = current?.title;

  const latestCalendarEvent = personWithConnections?.latestCalendarEvent;
  const latestEmail = personWithConnections?.latestEmail;

  const calendarEventParticipants =
    latestCalendarEvent?.participants?.map((participant) => ({
      name: participant?.emailAddress.name ?? '',
      emailAddress: participant?.emailAddress.emailAddress ?? ''
    })) ?? [];

  const calendarParticipantResponseStatusMap = getParticipantResponseStatusMap(
    (latestCalendarEvent?.participantResponseStatuses as ParticipantResponseStatus[]) ??
      []
  );

  const latestEmailReceivers =
    latestEmail?.receivers?.map((participant) => ({
      name: participant?.emailAddress.name ?? '',
      emailAddress: participant?.emailAddress.emailAddress ?? ''
    })) ?? [];

  const emailCombinedParticipants = latestEmailReceivers;
  emailCombinedParticipants.unshift({
    name: latestEmail?.sender?.emailAddress.name ?? '',
    emailAddress: latestEmail?.sender?.emailAddress.emailAddress ?? ''
  });

  const personLogo = isSafeHostedAsset(person?.profilePictureUrl ?? '')
    ? person?.profilePictureUrl
    : defaultPersonLogo;
  const personHighlights =
    compact(person?.highlights?.map((highlight) => highlight?.category)) ?? [];

  let targetConnectionName =
    person?.fullName ??
    getDisplayNameFromParticipantList(
      personEmail ?? '',
      emailCombinedParticipants.concat(calendarEventParticipants)
    );
  targetConnectionName = trim(targetConnectionName, '"');

  return (
    <div
      data-testid="UserConnectionsList-PersonWithConnectionsCard"
      className={classNames(
        'flex flex-col',
        'p-p10 rounded-br40 bg-surface-background'
      )}
    >
      <div className="px-p30 py-p20">
        <PersonUserConnections
          target={{ name: targetConnectionName }}
          userConnections={userConnections}
          emphasizedStyle={true}
          showIcon={false}
        />
      </div>
      <div
        className={classNames(
          'flex flex-col gap-2',
          'p-p50',
          'rounded-br40 bg-surface-default border border-border border-solid',
          'h-full'
        )}
      >
        <div className="flex-1">
          <div className="flex flex-col gap-g50">
            {person ? (
              <div className="flex flex-col gap-g10">
                <div className="flex justify-between">
                  <div className="flex gap-g40">
                    <AvatarWithHighlightBadge
                      href={`/dashboard/person/${person.id}`}
                      src={personLogo as string}
                      highlights={personHighlights}
                      personId={person.id}
                      size="x-large"
                    />
                    <div>
                      <div className="flex flex-row items-center gap-g20">
                        <InternalLink
                          to={{ pathname: `/dashboard/person/${person.id}` }}
                        >
                          <p className="text-content-strong typography-label-default-strong line-clamp-1">
                            {targetConnectionName}
                          </p>
                        </InternalLink>
                        {person.socials?.linkedin?.url && (
                          <IconButton
                            icon={(props: IconProps) => (
                              <LinkedInIcon
                                {...props}
                                applyCurrentColor={false}
                              />
                            )}
                            type="secondary"
                            emphasis="low"
                            size="tiny"
                            onClick={() => {
                              person.socials?.linkedin?.url &&
                                window.open(
                                  person.socials?.linkedin?.url,
                                  '_blank'
                                );
                            }}
                          />
                        )}
                      </div>
                      <p className="line-clamp-1 text-content-weak typography-label-default-default">
                        {currentTitle}
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-g20 max-h-[28px]">
                    <AddPeopleToWatchlist type="icon" personIds={[person.id]} />
                    {personEmail ? (
                      <ContactButton
                        name={targetConnectionName}
                        emailAddress={personEmail}
                      />
                    ) : (
                      <GetPersonEmailButton
                        personId={person.id}
                        size="compact"
                      />
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex flex-col gap-g10">
                <div className="flex gap-g40">
                  <AnonymousPersonLogo />

                  <div className="flex justify-between w-full">
                    <div>
                      <p className="text-content-strong typography-label-default-strong line-clamp-1">
                        {targetConnectionName}
                      </p>
                      <p className="typography-label-default-default text-content-weak">
                        Imported from emails
                      </p>
                    </div>
                    <div className="flex gap-g20 max-h-[28px]">
                      {personEmail && (
                        <ContactButton
                          name={targetConnectionName}
                          emailAddress={personEmail}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="flex flex-col gap-g40">
              {latestEmail && (
                <EmailMessageCard
                  sender={latestEmail?.sender?.emailAddress.name ?? ''}
                  participants={emailCombinedParticipants}
                  name={latestEmail?.title || ''}
                  startTime={latestEmail?.timestamp}
                />
              )}
              {latestCalendarEvent && (
                <div className="">
                  <CalendarEventCard
                    participants={calendarEventParticipants}
                    name={latestCalendarEvent?.name || ''}
                    startTime={latestCalendarEvent?.startTime}
                    participantsResponseStatusMap={
                      calendarParticipantResponseStatusMap
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonConnectionCard;
