import { LocationIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import React from 'react';
import { formatLocation } from 'utils/utilities';

interface LocationProps {
  city?: string | null;
  state?: string | null;
  country?: string | null;
  formattedLocation?: string | null;
  fontSize?: 'small' | 'medium';
}

const Location: React.FC<LocationProps> = ({
  city,
  state,
  country,
  formattedLocation,
  fontSize = 'medium'
}) => {
  const addressFormatted = formattedLocation
    ? formattedLocation
    : formatLocation(city, state, country);
  if (!addressFormatted) return null;
  return (
    <div className="flex flex-row items-center gap-x-1">
      <LocationIcon className="text-content-default" />
      <p
        className={classNames('text-content-default', {
          'typography-label-large': fontSize === 'medium',
          'typography-label-default-default': fontSize === 'small'
        })}
      >
        {addressFormatted}
      </p>
    </div>
  );
};

export default Location;
