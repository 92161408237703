import { CrossIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { DEFAULT_DASHBOARD_ROUTE } from 'utils/constants';

interface TopNavigationBarProps {
  logoUrl?: string;
  name?: string;
}

const TopNavigationBar: React.FC<TopNavigationBarProps> = ({ name }) => {
  const navigationLocation = useLocation();
  const navigate = useNavigate();
  const onGoBackRoute = () => {
    const historyState: Record<string, unknown> =
      navigationLocation.state as Record<string, unknown>;
    if (historyState && historyState.from && historyState.params) {
      const lastURL = `${historyState.from}?${historyState.params}`;
      navigate(lastURL);
      return;
    }
    if (history.length > 1) {
      navigate(-1);
    } else {
      navigate(DEFAULT_DASHBOARD_ROUTE);
    }
  };

  return (
    <div
      className={classNames(
        'ml-0 sm:ml-60', // Drawer width
        'fixed left-0 right-0 bg-white z-10 flex items-center',
        'justify-between py-p50 border-b border-solid px-p70 border-border'
      )}
    >
      <div className="flex items-center gap-g60">
        <IconButton icon={CrossIcon} onClick={onGoBackRoute} size="compact" />
        {name && (
          <p
            data-testid="TopNavigationbar-Name"
            className={classNames('typography-label-small text-content-weak')}
          >
            &nbsp;&nbsp;{name}
          </p>
        )}
      </div>
    </div>
  );
};

export default TopNavigationBar;
