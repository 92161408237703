import classNames from 'classnames';
import { Tooltip } from 'common/components';
import React, { SVGProps } from 'react';
import {
  ColorIntensity,
  ColorShorthand,
  getBackgroundColorClassFromShorthandAndIntensity,
  getContentColorClassFromShorthandAndIntensity,
  getIconColorClassFromShorthandAndIntensity
} from 'utils/design';

export interface BadgeProps {
  label?: string;
  tooltipTitle?: string;
  size?: 'small' | 'medium' | 'large';
  color?: ColorShorthand;
  intensity?: ColorIntensity;
  leadingIcon?: React.FC<SVGProps<SVGSVGElement>>;
  trailingIcon?: React.FC<SVGProps<SVGSVGElement>>;
  dataTestId?: string;
  suffix?: string;
  borderRadius?: 'default' | 'square';
}

export const Badge: React.FC<BadgeProps> = ({
  size = 'medium',
  label,
  tooltipTitle,
  color = 'neutral',
  intensity = 'subtle',
  leadingIcon,
  trailingIcon,
  dataTestId,
  suffix,
  borderRadius = 'default'
}) => {
  const LeadingIcon = leadingIcon as React.FC<SVGProps<SVGSVGElement>>;
  const TrailingIcon = trailingIcon as React.FC<SVGProps<SVGSVGElement>>;

  const badgeContent = () => (
    <div
      data-testid={dataTestId}
      className={classNames(
        'inline-flex gap-g10 items-center justify-center',
        {
          'rounded-br90': borderRadius == 'default',
          'rounded-br20': borderRadius == 'square'
        },
        {
          'py-p10 px-p40 typography-label-default-default min-w-[25px]':
            size == 'large',
          'py-p10 px-p40 typography-label-small-default': size == 'medium',
          'py-p00 px-p30 typography-label-small-default': size == 'small'
        },

        getBackgroundColorClassFromShorthandAndIntensity(color, intensity),
        getContentColorClassFromShorthandAndIntensity(color, intensity)
      )}
    >
      {leadingIcon && (
        <div className="min-w-4">
          <LeadingIcon
            className={classNames(
              { 'h-4 w-4': size == 'large' },
              { 'h-3 w-3': size == 'medium' },
              { 'h-3 w-3': size == 'small' },
              getIconColorClassFromShorthandAndIntensity(color, intensity)
            )}
          />
        </div>
      )}

      {label && (
        <p className="line-clamp-1">
          {label}
          {suffix && (
            <span
              className={classNames(
                getIconColorClassFromShorthandAndIntensity(color, intensity)
              )}
            >
              {' '}
              {suffix}
            </span>
          )}
        </p>
      )}
      {trailingIcon && (
        <TrailingIcon
          className={classNames(
            { 'h-4 w-4': size == 'large' },
            { 'h-3 w-3': size == 'medium' },
            { 'h-3 w-3': size == 'small' },
            getIconColorClassFromShorthandAndIntensity(color, intensity)
          )}
        />
      )}
    </div>
  );

  return tooltipTitle ? (
    <Tooltip body={tooltipTitle} enterDelayMs={400}>
      {badgeContent()}
    </Tooltip>
  ) : (
    badgeContent()
  );
};
