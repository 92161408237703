import classNames from 'classnames';
import { IconProps } from './type';

const TriangleDownIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="#484E5B"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M3.37988 4H20.616L11.9979 21.2361L3.37988 4Z"
      />
    </svg>
  );
};

export default TriangleDownIcon;
