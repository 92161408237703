import { DateListCustomFieldValueFormat } from '__generated__/graphql';
import dayjs from 'dayjs';

export const formatCustomFieldDate = (
  format: DateListCustomFieldValueFormat,
  date?: string
) => {
  let formattedDate = date;
  if (!date) return '';
  switch (format) {
    case DateListCustomFieldValueFormat.DD_MM_YYYY:
      formattedDate = dayjs(date).format('DD/MM/YYYY');
      break;
    case DateListCustomFieldValueFormat.MM_DD_YYYY:
      formattedDate = dayjs(date).format('MM/DD/YYYY');
      break;
    case DateListCustomFieldValueFormat.YYYY_MM_DD:
      formattedDate = dayjs(date).format('YYYY/MM/DD');
      break;
    default:
      break;
  }
  return formattedDate;
};
