import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const ToastCautionIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      fill="none"
    >
      <path
        vectorEffect="non-scaling-stroke"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.7418 2.37128C9.30509 1.40956 10.6952 1.40957 11.2585 2.37128L18.3464 14.4724C18.9158 15.4446 18.2147 16.6678 17.088 16.6678H2.91235C1.78567 16.6678 1.08455 15.4446 1.65398 14.4724L8.7418 2.37128Z"
        fill="#E17414"
      />
      <path
        vectorEffect="non-scaling-stroke"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99999 7.50124C10.3452 7.50124 10.625 7.78106 10.625 8.12624V11.0429C10.625 11.3881 10.3452 11.6679 9.99999 11.6679C9.65481 11.6679 9.37499 11.3881 9.37499 11.0429V8.12624C9.37499 7.78106 9.65481 7.50124 9.99999 7.50124ZM10.8333 13.1262C10.8333 13.5865 10.4602 13.9596 9.99999 13.9596C9.53975 13.9596 9.16666 13.5865 9.16666 13.1262C9.16666 12.666 9.53975 12.2929 9.99999 12.2929C10.4602 12.2929 10.8333 12.666 10.8333 13.1262Z"
        fill="#F9FAFB"
      />
    </svg>
  );
};

export default ToastCautionIcon;
