import { ColumnHeaderOrder } from 'interfaces/Grid';
import { ResultsView } from 'interfaces/SearchModel/Search';
import { difference, union } from 'lodash';
import { create } from 'zustand';
import { ListCustomFieldType } from '../__generated__/graphql';
import { useShallowStoreGeneric } from './util';

type CustomizeFieldModalPayload = {
  open: boolean;
  mode?: 'add' | 'edit' | 'duplicate';
  colId?: string;
  type?: ListCustomFieldType;
};
interface TableStoreState {
  customizeFieldModal: CustomizeFieldModalPayload;
  displayMenuSidebarOpen: boolean;
  gridRowHeight: number;
  originalColumns: ColumnHeaderOrder[];
  selectedRowIds: number[];
  actionsBarOpen: boolean;
  addToListOpenInActionsBar: boolean;
  allRowsSelected: boolean | null;
  tableColumns: ColumnHeaderOrder[];
  resultsLoading: boolean;
  totalCount: number;
  companyListLocalSearchTerm: { [key: string]: string };
  personListLocalSearchTerm: { [key: string]: string };
  addToListHoverActionOpenForCompanyId: number | null; // @deprecated
  view: ResultsView;
  lastGridLoadingTime: number | null;
  editTableStoreData<Key extends keyof TableStoreState>(
    key: Key,
    payload: TableStoreState[Key]
  ): void;
  setAddToListHoverActionOpenForCompanyId(id: number | null): void; // @deprecated
  addSelectedRowIds(rowIds: number[]): void;
  removeSelectedRowIds(rowIds: number[]): void;
  visibleVirtualColIds: { [key: string]: boolean };
  loadedExtendedByStartRow: { [key: number]: boolean };
  loadedExtendedUrns: { [key: string]: boolean };
  selectAllStateEnabled: boolean;
  groupByCustomFieldUrnByWatchlistId: { [key: string]: string };
  addToLoadedExtendedUrns: (urns: string[]) => void;
  gridResultsLoading: boolean;
}

const useTableStore = create<TableStoreState>((set) => ({
  customizeFieldModal: {
    open: false
  },
  gridRowHeight: 78,
  originalColumns: [],
  selectedRowIds: [],
  actionsBarOpen: false,
  addToListOpenInActionsBar: false,
  allRowsSelected: false,
  displayMenuSidebarOpen: false,
  tableColumns: [],
  totalCount: 0,
  resultsLoading: false,
  addToListHoverActionOpenForCompanyId: null,
  view: ResultsView.GRID,
  lastGridLoadingTime: null,
  companyListLocalSearchTerm: {},
  personListLocalSearchTerm: {},
  visibleVirtualColIds: {},
  loadedExtendedByStartRow: {},
  selectAllStateEnabled: false,
  groupByCustomFieldUrnByWatchlistId: {},
  loadedExtendedUrns: {},
  gridResultsLoading: false,
  setAddToListHoverActionOpenForCompanyId: (id: number | null) => {
    set(() => ({ addToListHoverActionOpenForCompanyId: id }));
  },
  addToLoadedExtendedUrns: (ids: string[]) => {
    set((state) => ({
      ...state,
      loadedExtendedUrns: ids.reduce(
        (acc, urn) => ({ ...acc, [urn]: true }),
        state.loadedExtendedUrns
      )
    }));
  },
  editTableStoreData: (key, payload) =>
    set((state) => ({
      ...state,
      [key]: payload
    })),
  addSelectedRowIds: (rowIds: number[]) => {
    set((state) => {
      const currentlySelectedRows = state.selectedRowIds;
      const updatedRowIds = union(currentlySelectedRows, rowIds);
      return {
        selectedRowIds: updatedRowIds
      };
    });
  },
  removeSelectedRowIds: (rowIds: number[]) => {
    set((state) => {
      const currentlySelectedRows = state.selectedRowIds;
      const updatedRowIds = difference(currentlySelectedRows, rowIds);
      return {
        selectedRowIds: updatedRowIds
      };
    });
  }
}));

// Only re-render the component when the selected keys change
export const useShallowTableStore = (keys: (keyof TableStoreState)[]) =>
  useShallowStoreGeneric(useTableStore, keys);

export default useTableStore;
