import useFlags from 'hooks/useFlags';
import React from 'react';
import { SPLITS } from 'utils/constants';
import CompanyContactEmailsPhone from './CompanyContactEmailsPhone';
import CompanyInNetwork from './CompanyInNetwork';

type CompanyContactProps = {
  companyId: number;
};

const CompanyContact: React.FC<CompanyContactProps> = ({ companyId }) => {
  const { enabled: showUserConnectionsOnCompanyPage } = useFlags(
    SPLITS.showUserConnectionsOnCompanyPage
  );
  return (
    <div
      data-testid="CompanyContact"
      className="flex flex-col justify-start items-start gap-g110"
    >
      {showUserConnectionsOnCompanyPage && (
        <CompanyInNetwork companyId={companyId} />
      )}
      <CompanyContactEmailsPhone companyId={companyId} />
    </div>
  );
};

export default CompanyContact;
