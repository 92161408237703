import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const LinkedInIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 25 25"
      fill="none"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="15" height="15" transform="translate(5 5)" fill="white" />
      <path
        d="M20.5 3.5H4.5C3.947 3.5 3.5 3.948 3.5 4.5V20.5C3.5 21.052 3.947 21.5 4.5 21.5H20.5C21.053 21.5 21.5 21.052 21.5 20.5V4.5C21.5 3.948 21.053 3.5 20.5 3.5ZM8.839 18.837H6.167V10.247H8.839V18.837ZM7.503 9.074C6.647 9.074 5.955 8.38 5.955 7.526C5.955 6.672 6.646 5.978 7.503 5.978C8.357 5.978 9.051 6.671 9.051 7.526C9.051 8.381 8.357 9.074 7.503 9.074ZM18.838 18.837H16.169V14.66C16.169 13.664 16.151 12.383 14.781 12.383C13.391 12.383 13.18 13.469 13.18 14.59V18.838H10.513V10.248H13.073V11.422H13.11C13.465 10.747 14.337 10.035 15.634 10.035C18.338 10.035 18.837 11.813 18.837 14.127V18.837H18.838Z"
        fill="#0A66C2"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default LinkedInIcon;
