import { LoginResponse } from 'interfaces/Auth';
import { IntercomProps } from 'react-use-intercom';

export const updateIntercomWithAuth = (
  result: LoginResponse,
  update: (props?: Partial<IntercomProps>) => void
) => {
  if (result.user_metadata && result.token) {
    update({
      userId: result.token.user_id,
      name: result.token.displayName,
      email: result.token.email,
      createdAt: Date.now().toString(),
      company: {
        companyId: result.user_metadata.customer_urn
      }
    });
  }
};
