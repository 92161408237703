import { ConnectionsRenderable } from 'hooks/useGroupedUserConnections';
import React from 'react';

export const PersonUserConnectionsList: React.FC<{
  userConnections: ConnectionsRenderable[][];
  dataTestId?: string;
}> = ({ userConnections, dataTestId }) => {
  return (
    <div data-testid={dataTestId} className="typography-label">
      {userConnections.map((group: ConnectionsRenderable[], index) => {
        const connection = group[0];
        return (
          <span key={`userConnectionTooltip_${index}`}>
            <span className="typography-label">{connection.userName}</span>
            {index < userConnections?.length - 2
              ? ', '
              : index == userConnections?.length - 2
              ? ' and '
              : ''}
          </span>
        );
      })}
    </div>
  );
};
