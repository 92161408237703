import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const ToastPositiveIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      fill="none"
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M17.5 16.0455V3.95455C17.5 3.44541 17.5 3.19084 17.4009 2.99637C17.3138 2.82532 17.1747 2.68624 17.0036 2.59908C16.8092 2.5 16.5546 2.5 16.0455 2.5H3.95455C3.44541 2.5 3.19084 2.5 2.99637 2.59908C2.82532 2.68624 2.68624 2.82532 2.59908 2.99637C2.5 3.19084 2.5 3.44541 2.5 3.95455V16.0455C2.5 16.5546 2.5 16.8092 2.59908 17.0036C2.68624 17.1747 2.82532 17.3138 2.99637 17.4009C3.19084 17.5 3.44541 17.5 3.95455 17.5H16.0455C16.5546 17.5 16.8092 17.5 17.0036 17.4009C17.1747 17.3138 17.3138 17.1747 17.4009 17.0036C17.5 16.8092 17.5 16.5546 17.5 16.0455Z"
        fill="#677183"
      />
      <path
        vectorEffect="non-scaling-stroke"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3559 8.70377C13.6 8.45969 13.6 8.06396 13.3559 7.81988C13.1118 7.57581 12.7161 7.57581 12.472 7.81988L9.16398 11.1279L7.93926 9.90322C7.69518 9.65914 7.29945 9.65914 7.05537 9.90322C6.8113 10.1473 6.8113 10.543 7.05537 10.7871L8.72204 12.4538C8.96612 12.6978 9.36185 12.6978 9.60592 12.4538L13.3559 8.70377Z"
        fill="#F9FAFB"
      />
    </svg>
  );
};

export default ToastPositiveIcon;
