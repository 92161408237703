import { Tooltip } from '@material-ui/core';
import { SimilarCompaniesFragment } from '__generated__/graphql';
import { ReactComponent as BoxCalendarStar } from 'assets/company-profile/bx-calendar-star.svg';
import {
  MoneyIcon,
  PersonIcon,
  ThumbDownFillIcon,
  ThumbDownIcon,
  ThumbUpFillIcon,
  ThumbUpIcon
} from 'assets/harmonic-icons';
import { Badge } from 'common/components';
import AddCompanyToWatchlistButton from 'components/common/CompanyActions/AddCompanyToWatchlistButton';
import LikeCompanyButton from 'components/common/CompanyActions/LikeCompanyButton';
import InternalLink from 'components/common/InternalLink';
import Paper from 'components/common/Paper';
import RelevanceScore from 'components/common/RelevanceScore';
import PercentChangeBadge, {
  TractionMetrics
} from 'components/common/TractionMetrics/PercentChangeBadge';
import dayjs from 'dayjs';
import Avatar from 'harmonic-components/Avatar/Avatar';
import IconButton from 'harmonic-components/IconButton/IconButton';
import React from 'react';
import { COMPANY_ROUTE, DASHBOARD_ROUTE } from 'utils/constants';
import {
  getFormattedFundingTotal,
  getFundingStageBadgeColor
} from 'utils/funding';
import {
  addCommasToNumber,
  isSafeHostedAsset,
  parseFundingStage
} from 'utils/utilities';

const FundingStageChip: React.FC<{
  funding: SimilarCompaniesFragment['funding'] | null;
}> = ({ funding }) => {
  const fundingStage = parseFundingStage(funding?.fundingStage);

  return (
    <Badge
      label={fundingStage}
      size="large"
      color={getFundingStageBadgeColor(fundingStage)}
    />
  );
};

const FundingTotalChip: React.FC<{
  funding: SimilarCompaniesFragment['funding'] | null;
  fundingAttributeNullStatus?: string | null;
}> = ({ funding, fundingAttributeNullStatus }) => {
  const formattedFundingTotal = getFormattedFundingTotal(
    funding?.fundingTotal,
    fundingAttributeNullStatus
  );

  return (
    <Badge
      label={formattedFundingTotal}
      size="large"
      leadingIcon={MoneyIcon}
      color="expressive-32"
      dataTestId="SimilarCompany-Funding-Total"
    />
  );
};

const HeadcountChip: React.FC<{
  headcount: number | null;
  tractionMetrics?: TractionMetrics | null;
}> = ({ headcount, tractionMetrics = {} }) => {
  const headcountFormatted =
    headcount !== null ? addCommasToNumber(headcount.toString()) : null;

  return (
    <div className="flex items-center gap-g40">
      <Badge
        label={headcountFormatted ?? ''}
        size="large"
        leadingIcon={() => <PersonIcon applyCurrentColor={false} />}
        color="expressive-42"
      />
      {tractionMetrics !== null && tractionMetrics !== undefined && (
        <div className="flex flex-row">
          <Tooltip
            classes={{ tooltip: 'typography-label' }}
            title={<span>Δ90d</span>}
          >
            <div>
              <PercentChangeBadge tractionMetrics={tractionMetrics} />
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

const FoundingDateChip: React.FC<{
  foundingDate: SimilarCompaniesFragment['foundingDate'] | null;
}> = ({ foundingDate }) => {
  if (!foundingDate || foundingDate?.date) return null;
  const foundingDateFormatted = dayjs(foundingDate.date).format('MMM YYYY');
  return (
    <div className="flex flex-row items-center gap-x-2">
      <div className="w-6 h-6 bg-nav-flatStroke p-1 rounded-md">
        <BoxCalendarStar />
      </div>
      <p className="typography-label text-clickables-buttonHover">
        {foundingDateFormatted}
      </p>
    </div>
  );
};

const Actions: React.FC<{ companyId: number }> = ({ companyId }) => {
  return (
    <div className="flex flex-row gap-x-2">
      <LikeCompanyButton companyId={companyId} />
      <AddCompanyToWatchlistButton companyId={companyId} />
    </div>
  );
};

interface SimilarCompanyProps {
  similarCompany: SimilarCompaniesFragment;
  onQualificationClick: (
    similarCompanyId: number,
    isPositiveQualification: boolean
  ) => void;
}

const SimilarCompany: React.FC<SimilarCompanyProps> = ({
  similarCompany,
  onQualificationClick
}) => {
  const url = `${DASHBOARD_ROUTE}${COMPANY_ROUTE}/${similarCompany.id}`;
  const marketVertical = similarCompany.tagsV2?.find(
    (tag) => tag?.type === 'MARKET_VERTICAL'
  )?.displayValue;
  const technologyType = similarCompany.tagsV2?.find(
    (tag) => tag?.type === 'MARKET_VERTICAL'
  )?.displayValue;

  const [qualification, setQualification] = React.useState<string>('');

  const handleQualificationClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    isPositiveQualification: boolean
  ) => {
    e.stopPropagation();
    e.preventDefault();
    setQualification(isPositiveQualification ? 'POSITIVE' : 'NEGATIVE');
    onQualificationClick(similarCompany.id, isPositiveQualification);
  };
  const logoUrl =
    similarCompany.logoUrl && isSafeHostedAsset(similarCompany.logoUrl)
      ? similarCompany.logoUrl
      : '';

  return (
    <Paper>
      <div data-testid="SimilarCompany" className="flex flex-col gap-g50">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <InternalLink key={similarCompany.id} to={{ pathname: url }}>
              <Avatar src={logoUrl} size="huge" alt="Company image" />
            </InternalLink>

            <div className="pl-p50 flex flex-col">
              <p className="text-content-strong typography-label-large-strong">
                <InternalLink key={similarCompany.id} to={{ pathname: url }}>
                  {similarCompany.name}
                </InternalLink>
              </p>
              <div className="flex flex-row gap-x-2">
                <p className="typography-label text-content-weak">
                  {marketVertical}
                  {marketVertical && technologyType && (
                    <span className="mx-1">&bull;</span>
                  )}
                  {technologyType}
                </p>
              </div>
            </div>
          </div>
          <div className="flex gap-g20">
            <IconButton
              icon={
                qualification === 'POSITIVE' ? ThumbUpFillIcon : ThumbUpIcon
              }
              type="secondary"
              emphasis="high"
              onClick={(e) => handleQualificationClick(e, true)}
              dataTestId="SimilarCompanyPositiveQualification"
            />
            <IconButton
              icon={
                qualification === 'NEGATIVE' ? ThumbDownFillIcon : ThumbDownIcon
              }
              type="secondary"
              emphasis="high"
              dataTestId="SimilarCompanyNegativeQualification"
              onClick={(e) => handleQualificationClick(e, false)}
            />

            <AddCompanyToWatchlistButton
              companyId={similarCompany.id}
              type="icon"
            />
            <LikeCompanyButton companyId={similarCompany.id} />
          </div>
        </div>

        <p className="text-content-default typography-paragraph line-clamp-2">
          {similarCompany.description}
        </p>
        <div className="flex flex-row gap-g40">
          {similarCompany.relevanceScore !== null && (
            <RelevanceScore score={similarCompany.relevanceScore} />
          )}
          <FundingStageChip funding={similarCompany.funding} />
          <FundingTotalChip
            funding={similarCompany.funding}
            fundingAttributeNullStatus={
              similarCompany.fundingAttributeNullStatus
            }
          />
          <HeadcountChip
            headcount={similarCompany.headcount}
            tractionMetrics={similarCompany.tractionMetrics?.headcount?.ago90d}
          />
          <FoundingDateChip foundingDate={similarCompany.foundingDate} />
        </div>
      </div>
    </Paper>
  );
};

export default SimilarCompany;
