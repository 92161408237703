import classNames from 'classnames';
import { HarmonicIcon } from './type';

const CircleMinus: HarmonicIcon = ({ applyCurrentColor = true, className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#1A1D23"
      strokeWidth="1.5"
      strokeLinecap="round"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M16.2426 12.0005H7.75736M21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12Z"
      />
    </svg>
  );
};

export default CircleMinus;
