import { logout } from 'actions/authActions';
import { setDashboardError, setDataLoading } from 'actions/dashboardActions';
import axios, { AxiosError, AxiosResponse, CancelToken } from 'axios';
import { LogoutReason } from 'interfaces/Auth';
import {
  ApiResourceType,
  IApiResource,
  ICapTableEntry,
  IListResponse,
  ISavedSearchDigestConfiguration,
  ISavedSearchDigestConfigurationUpdate
} from 'interfaces/DataModel/ApiResource';
import { ICompany } from 'interfaces/DataModel/Company';
import { IPerson } from 'interfaces/DataModel/Person';
import { Dispatch } from 'redux';

import {
  ApolloCache,
  DefaultContext,
  FetchResult,
  MutationUpdaterFunction,
  OperationVariables,
  QueryOptions
} from '@apollo/client';
import { getFirebaseToken } from 'actions/fetchActions';
import client from 'config/client';
import { config } from 'config/config';
import { DocumentNode, print } from 'graphql';
import { ISearchFieldSpec, ISearchModel } from 'interfaces/SearchModel/Search';
import { PEOPLE_SEARCH_QUERY } from 'queries/peopleSearch';
import { AppDispatch, store } from 'store';
import {
  API_V2_SEARCH_FIELD_SPEC,
  GRID_INITIAL_PAGE_SIZE,
  NOT_FOUND_ERROR_MESSAGE,
  SESSION_EXPIRED_ERROR_MESSAGE
} from './constants';
import { getUser } from './midtierApi';
import { transformSearchModelForApiv2 } from './search';
import { camelize } from './utilities';

import { logger } from './logger';

/**
 * Search Model
 */

export const makeGraphqlMutationWithApolloClient = async <
  Response = unknown,
  Variables extends OperationVariables = OperationVariables
>(
  mutation: DocumentNode,
  variables: Variables,
  refetchQueries?:
    | 'all'
    | 'active'
    | Array<string | DocumentNode | QueryOptions>,
  update?: MutationUpdaterFunction<
    any,
    Variables,
    DefaultContext,
    ApolloCache<any>
  >
): Promise<FetchResult<Response>> => {
  const response = await client.mutate({
    mutation,
    variables,
    refetchQueries,
    update
  });
  return response;
};
/**
 * API Resources
 */

const getApiResource = (
  apiUrl: string,
  dispatch: AppDispatch,
  requestParams?: Record<string, unknown>,
  preFetch?: boolean, // fetch in background, do not show loading in UI
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  requestBody?: any,
  cancelToken?: CancelToken,
  requestHeaders?: Record<string, unknown>
): Promise<IApiResource> => {
  const setLoading = preFetch
    ? () => null
    : (loading: boolean) => dispatch(setDataLoading(loading));
  const setError = (message: string) => dispatch(setDashboardError(message));

  setLoading(true);

  const axiosRequest = requestBody
    ? axios.post(apiUrl, requestBody, {
        params: requestParams,
        cancelToken: cancelToken,
        headers: requestHeaders || {}
      })
    : axios.get(apiUrl, {
        params: requestParams,
        cancelToken: cancelToken,
        headers: requestHeaders || {}
      });

  return axiosRequest.then(
    (value: AxiosResponse<IApiResource>) => {
      if (value.data) {
        setLoading(false);
        return Promise.resolve(value.data);
      }
      // No data in response
      else {
        logger.error(`Data fetch failure: non 200 response or empty data`);
        // setError(DATA_FETCH_ERROR_MESSAGE);
        return Promise.reject('No data in response');
      }
    },
    (error: AxiosError) => {
      setLoading(false);
      if (error.response?.status === 401) {
        // If unauthorized response, then session has expired. Log user out.
        dispatch(logout(LogoutReason.SessionExpired));
        setError(SESSION_EXPIRED_ERROR_MESSAGE);
      } else if (error.response?.status === 404) {
        setError(NOT_FOUND_ERROR_MESSAGE);
      } else if (error.response?.status === 403) {
        // Pass for now
        // TODO: Move all error handling out of API util layer
        null;
      } else if (axios.isCancel(error)) {
        null;
      } else {
        const user = store.getState().auth.user;

        const formattedUser = user
          ? {
              email: user.email,
              id: user.user_id,
              username: user.displayName
            }
          : undefined;

        logger.error(error.message, {
          error,
          user: formattedUser,
          extra: {
            apiUrl,
            requestBody: JSON.stringify(requestBody),
            requestHeaders,
            requestParams
          }
        });
        // setError(DATA_FETCH_ERROR_MESSAGE);
      }

      return Promise.reject(error);
    }
  );
};

export const updateCompanyCustomText = async (
  companyId: number,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  payload: any,
  cancelToken?: CancelToken
): Promise<ICompany> => {
  const { apikey } = await getUser();
  const { custom_text, scope } = payload;

  const apiUrl = `${config.BASE_MIDTIER_API_URL}companies/set_custom_text/${companyId}?custom_text=${custom_text}&scope=${scope}`;
  return axios
    .put(apiUrl, payload, {
      headers: { apikey },
      cancelToken
    })
    .then((res) => {
      const updatedField = scope === 'USER' ? 'userNotes' : 'teamNotes';
      if (res.status === 200) {
        client.cache.modify({
          id: client.cache.identify({
            __typename: 'Company',
            id: companyId
          }),
          fields: {
            [updatedField]: () => custom_text
          },
          broadcast: false // so the grid doesn't re-render and jump to the top
        });
      }
      return res.data;
    });
};

export const getPeopleList = async (
  dispatch: Dispatch,
  searchModel: ISearchModel,
  requestParams?: Record<string, unknown>,
  preFetch = false
): Promise<IListResponse<IPerson>> => {
  const apiUrl = config.BASE_GRAPHQL_API_URL;
  // Remove any empty-value filters
  const transformedSearchModel: ISearchModel =
    transformSearchModelForApiv2(searchModel);
  const firebaseToken = await getFirebaseToken();

  const headers = {
    authorization: firebaseToken
  };

  return getApiResource(
    apiUrl,
    dispatch,
    { ...requestParams },
    preFetch,
    {
      query: print(PEOPLE_SEARCH_QUERY),
      variables: {
        query: camelize(transformedSearchModel),
        page: 0,
        size: GRID_INITIAL_PAGE_SIZE
      }
    },
    undefined,
    headers
  ) as Promise<IListResponse<IPerson>>;
};

export const getPeopleListSearchFieldSpec = async (
  dispatch: Dispatch,
  requestParams?: Record<string, unknown>,
  preFetch = true
): Promise<ISearchFieldSpec[]> => {
  const firebaseToken = await getFirebaseToken();

  const headers = {
    authorization: firebaseToken
  };
  const apiUrl = `${config.BASE_MIDTIER_API_URL}search/searchFieldSpec?search_type=PERSONS`;
  return getApiResource(
    apiUrl,
    dispatch,
    requestParams,
    preFetch,
    null,
    undefined,
    headers
  ) as Promise<ISearchFieldSpec[]>;
};

export const getCompaniesListSearchFieldSpec = async (
  dispatch: Dispatch,
  requestParams?: Record<string, unknown>,
  preFetch = true
): Promise<ISearchFieldSpec[]> => {
  const firebaseToken = await getFirebaseToken();

  const headers = {
    authorization: firebaseToken
  };

  const apiUrl = `${config.BASE_MIDTIER_API_URL}${API_V2_SEARCH_FIELD_SPEC}`;
  return getApiResource(
    apiUrl,
    dispatch,
    { ...requestParams, search_type: ApiResourceType.CompaniesList },
    preFetch,
    undefined,
    undefined,
    headers
  ) as Promise<ISearchFieldSpec[]>;
};

export const getSavedSearchDigestConfigForUser = async (): Promise<
  ISavedSearchDigestConfiguration[]
> => {
  const firebaseToken = await getFirebaseToken();

  const apiUrl = `${config.BASE_MIDTIER_API_URL}saved_search_digest_configs`;
  const res = await axios.get(apiUrl, {
    headers: { authorization: firebaseToken }
  });
  return res.data;
};

export const setSavedSearchDigestConfigForUser = async (
  updateData: ISavedSearchDigestConfigurationUpdate,
  cancelToken?: CancelToken
): Promise<ISavedSearchDigestConfiguration> => {
  const firebaseToken = await getFirebaseToken();

  const apiUrl = `${config.BASE_MIDTIER_API_URL}saved_search_digest_configs`;
  const res = await axios.put(apiUrl, updateData, {
    headers: { authorization: firebaseToken },
    cancelToken
  });
  return res.data;
};

export const getCapTableForCompanyId = async (
  companyId: number
): Promise<ICapTableEntry[]> => {
  const requestUrl = `https://assets.harmonic.ai/captables/${companyId}/captables.json`;
  const res = await fetch(requestUrl);
  const jsonRes = await res.json();
  return JSON.parse(jsonRes.captable);
};
