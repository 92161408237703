import { ApiConnectionIcon } from 'assets/harmonic-icons';
import IntegrateGmailButton from './IntegrateGmailButton';
import IntegrateLinkedinButton from './IntegrateLinkedinButton';

interface IntegrateLinkedinAndGmailProps {
  isLinkedinIntegrated?: boolean;
  isGmailIntegrated?: boolean;
  userConnectionsExist?: boolean;
}

const IntegrateLinkedinAndGmail: React.FC<IntegrateLinkedinAndGmailProps> = ({
  isLinkedinIntegrated,
  isGmailIntegrated,
  userConnectionsExist
}) => {
  const displayIntegrationflow = !isLinkedinIntegrated || !isGmailIntegrated;

  if (displayIntegrationflow)
    return (
      <div
        className="flex flex-row items-center justify-between py-p60 pl-p60 pr-p80 bg-surface-nested rounded-br40"
        data-testid="IntegrateButtonsWithoutCard"
      >
        <div className="flex gap-g60">
          <div className="min-w-[44px] min-h-[44px] flex flex-row items-center justify-center rounded-br30 bg-surface-sentiment-informative">
            <ApiConnectionIcon
              applyCurrentColor={false}
              className="fill-content-sentiment-informative"
            />
          </div>
          <p className="flex items-center">
            See your teammates&apos; connections to discover best paths into
            this company. <br /> Sync your email, calendar, and LinkedIn to take
            full advantage of connections.
          </p>
        </div>
        <div className="flex flex-row gap-g40">
          <IntegrateGmailButton
            type="primary"
            isIntegrated={isGmailIntegrated}
          />
          <IntegrateLinkedinButton
            type="primary"
            isIntegrated={isLinkedinIntegrated}
          />
        </div>
      </div>
    );

  return (
    <div className="flex flex-col items-end">
      <div data-testid="IntegrateButtonsInsideCard" className="mt-4 flex gap-4">
        <IntegrateGmailButton
          type="secondary"
          isIntegrated={isGmailIntegrated}
        />

        <IntegrateLinkedinButton
          type="secondary"
          isIntegrated={isLinkedinIntegrated}
        />
      </div>
    </div>
  );
};

export default IntegrateLinkedinAndGmail;
