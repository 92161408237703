import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const StreamingIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      fill="#7C8598"
    >
      <path
        vectorEffect="non-scaling-stroke"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33333 4.41651C1.33333 3.45001 2.11683 2.6665 3.08333 2.6665H12.9167C13.8832 2.6665 14.6667 3.45001 14.6667 4.4165V11.5832C14.6667 12.5497 13.8832 13.3332 12.9167 13.3332H3.08333C2.11683 13.3332 1.33333 12.5497 1.33333 11.5832V4.41651ZM11.8333 6.33317C12.2936 6.33317 12.6667 5.96007 12.6667 5.49984C12.6667 5.0396 12.2936 4.6665 11.8333 4.6665C11.3731 4.6665 11 5.0396 11 5.49984C11 5.96007 11.3731 6.33317 11.8333 6.33317ZM9.66666 7.33317C9.66666 8.25364 8.92047 8.99984 7.99999 8.99984C7.07952 8.99984 6.33333 8.25364 6.33333 7.33317C6.33333 6.4127 7.07952 5.6665 7.99999 5.6665C8.92047 5.6665 9.66666 6.4127 9.66666 7.33317ZM5.19918 11.6169C5.58174 10.857 6.51534 9.99984 7.99999 9.99984C9.48464 9.99984 10.4182 10.857 10.8008 11.6169C10.9871 11.9869 10.6642 12.3332 10.25 12.3332H5.74999C5.33578 12.3332 5.01293 11.9869 5.19918 11.6169Z"
      />
    </svg>
  );
};

export default StreamingIcon;
