import { PlusIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';

interface SelectAddOptionProps {
  onAddNewOption?: (value?: string) => Promise<void>;
  filterTerm: string;
  borderTop: boolean;
}

const SelectAddOption: React.FC<SelectAddOptionProps> = ({
  onAddNewOption,
  filterTerm,
  borderTop
}) => {
  return (
    <div
      className={classNames(
        'text-content-default gap-g-40 py-p40 px-p50 flex typography-label-default-default items-center w-full',
        {
          'border-t border-solid border-border': borderTop
        }
      )}
      onClick={() => onAddNewOption?.(filterTerm)}
    >
      <PlusIcon />
      <div className="ml-p40" data-testid="create-option-button">
        Create &ldquo;{filterTerm}&rdquo;
      </div>
    </div>
  );
};

export default SelectAddOption;
