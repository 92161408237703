import CompanyColumnsConfig from 'interfaces/CompanyColumnsConfig';
import { ColumnHeaderOrder } from 'interfaces/Grid';
import { SPLITS } from 'utils/constants';

const DefaultCompanySearchColumns: ColumnHeaderOrder[] = [
  {
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.ID.label,
    id: CompanyColumnsConfig.ID.value
  },
  {
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.ADDED_TO_HARMONIC_DATE.label,
    id: CompanyColumnsConfig.ADDED_TO_HARMONIC_DATE.value
  },
  {
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.UPDATED_DATE.label,
    id: CompanyColumnsConfig.UPDATED_DATE.value
  },
  {
    name: CompanyColumnsConfig.NAME.label,
    id: CompanyColumnsConfig.NAME.value,
    isVisibleOnTable: true
  },
  {
    name: CompanyColumnsConfig.LEGAL_NAME.label,
    id: CompanyColumnsConfig.LEGAL_NAME.value,
    isVisibleOnTable: false
  },
  {
    name: CompanyColumnsConfig.RELEVANCE_SCORE.label,
    id: CompanyColumnsConfig.RELEVANCE_SCORE.value,
    isVisibleOnTable: true,
    order: 1
  },
  {
    id: CompanyColumnsConfig.COMPANY_HIGHLIGHTS.value,
    name: CompanyColumnsConfig.COMPANY_HIGHLIGHTS.label,
    isVisibleOnTable: true
  },
  {
    isVisibleOnTable: true,
    name: CompanyColumnsConfig.DESCRIPTION.label,
    id: CompanyColumnsConfig.DESCRIPTION.value
  },
  {
    isVisibleOnTable: true,
    name: CompanyColumnsConfig.EXTERNAL_DESCRIPTION.label,
    id: CompanyColumnsConfig.EXTERNAL_DESCRIPTION.value
  },
  {
    name: CompanyColumnsConfig.TEAM.label,
    id: CompanyColumnsConfig.TEAM.value,
    isVisibleOnTable: true
  },
  {
    name: CompanyColumnsConfig.TEAM_CONNECTIONS.label,
    id: CompanyColumnsConfig.TEAM_CONNECTIONS.value,
    isVisibleOnTable: true
  },
  {
    id: CompanyColumnsConfig.LAST_MEETING_DATE.value,
    name: CompanyColumnsConfig.LAST_MEETING_DATE.label,
    isVisibleOnTable: false,
    splitFlagToEnable: SPLITS.showCorrespondencesInSearch
  },
  {
    id: CompanyColumnsConfig.LAST_MEETING_WITH.value,
    name: CompanyColumnsConfig.LAST_MEETING_WITH.label,
    isVisibleOnTable: false,
    splitFlagToEnable: SPLITS.showCorrespondencesInSearch
  },
  {
    id: CompanyColumnsConfig.LAST_CONTACTED_DATE.value,
    name: CompanyColumnsConfig.LAST_CONTACTED_DATE.label,
    isVisibleOnTable: false,
    splitFlagToEnable: SPLITS.showCorrespondencesInSearch
  },
  {
    id: CompanyColumnsConfig.LAST_CONTACTED_BY.value,
    name: CompanyColumnsConfig.LAST_CONTACTED_BY.label,
    isVisibleOnTable: false,
    splitFlagToEnable: SPLITS.showCorrespondencesInSearch
  },
  {
    name: CompanyColumnsConfig.AFFINITY_LISTS.label,
    id: CompanyColumnsConfig.AFFINITY_LISTS.value,
    isVisibleOnTable: true,
    splitFlagToEnable: SPLITS.enableCrmIntegration,
    order: 2
  },
  {
    id: CompanyColumnsConfig.TEAM_LINKEDIN_PROFILES.value,
    isVisibleOnTable: true,
    name: CompanyColumnsConfig.TEAM_LINKEDIN_PROFILES.label
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT.value,
    isVisibleOnTable: true,
    name: CompanyColumnsConfig.HEADCOUNT.label
  },
  {
    id: CompanyColumnsConfig.EXTERNAL_HEADCOUNT.value,
    isVisibleOnTable: true,
    name: CompanyColumnsConfig.EXTERNAL_HEADCOUNT.label
  },
  {
    isVisibleOnTable: true,
    name: CompanyColumnsConfig.HEADCOUNT_PERCENT_CHANGE_180D_AGO.label,
    id: CompanyColumnsConfig.HEADCOUNT_PERCENT_CHANGE_180D_AGO.value
  },
  {
    id: CompanyColumnsConfig.STATE.value,
    isVisibleOnTable: true,
    name: CompanyColumnsConfig.STATE.label
  },
  {
    isVisibleOnTable: true,
    name: CompanyColumnsConfig.COUNTRY.label,
    id: CompanyColumnsConfig.COUNTRY.value
  },
  {
    id: CompanyColumnsConfig.FOUNDING_DATE.value,
    isVisibleOnTable: true,
    name: CompanyColumnsConfig.FOUNDING_DATE.label
  },
  {
    id: CompanyColumnsConfig.TAGS.value,
    isVisibleOnTable: true,
    name: CompanyColumnsConfig.TAGS.label
  },
  {
    id: CompanyColumnsConfig.COMPANY_TYPE.value,
    name: CompanyColumnsConfig.COMPANY_TYPE.label,
    isVisibleOnTable: true
  },
  {
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.CITY.label,
    id: CompanyColumnsConfig.CITY.value
  },
  {
    isVisibleOnTable: true,
    id: CompanyColumnsConfig.WEBSITE.value,
    name: CompanyColumnsConfig.WEBSITE.label
  },
  {
    id: CompanyColumnsConfig.LINKEDIN.value,
    isVisibleOnTable: true,
    name: CompanyColumnsConfig.LINKEDIN.label
  },
  {
    name: CompanyColumnsConfig.STAGE.label,
    id: CompanyColumnsConfig.STAGE.value,
    isVisibleOnTable: true
  },
  {
    id: CompanyColumnsConfig.NUM_FUNDING_ROUNDS.value,
    isVisibleOnTable: true,
    name: CompanyColumnsConfig.NUM_FUNDING_ROUNDS.label
  },
  {
    name: CompanyColumnsConfig.LAST_FUNDING_DATE.label,
    id: CompanyColumnsConfig.LAST_FUNDING_DATE.value,
    isVisibleOnTable: true
  },
  {
    isVisibleOnTable: true,
    name: CompanyColumnsConfig.LAST_FUNDING_TYPE.label,
    id: CompanyColumnsConfig.LAST_FUNDING_TYPE.value
  },
  {
    name: CompanyColumnsConfig.LAST_FUNDING_TOTAL.label,
    id: CompanyColumnsConfig.LAST_FUNDING_TOTAL.value,
    isVisibleOnTable: true
  },
  {
    isVisibleOnTable: true,
    name: CompanyColumnsConfig.FUNDING_TOTAL.label,
    id: CompanyColumnsConfig.FUNDING_TOTAL.value
  },
  {
    name: CompanyColumnsConfig.FUNDING_PER_EMPLOYEE.label,
    id: CompanyColumnsConfig.FUNDING_PER_EMPLOYEE.value,
    isVisibleOnTable: true
  },
  {
    isVisibleOnTable: true,
    name: CompanyColumnsConfig.INVESTORS.label,
    id: CompanyColumnsConfig.INVESTORS.value
  },
  {
    name: CompanyColumnsConfig.CUSTOMER_TYPE.label,
    isVisibleOnTable: true,
    id: CompanyColumnsConfig.CUSTOMER_TYPE.value
  },
  {
    name: CompanyColumnsConfig.INDUSTRY_TAGS.label,
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.INDUSTRY_TAGS.value
  },
  {
    name: CompanyColumnsConfig.TECHNOLOGY_TAGS.label,
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.TECHNOLOGY_TAGS.value
  },
  {
    name: CompanyColumnsConfig.ACCELERATORS.label,
    isVisibleOnTable: true,
    id: CompanyColumnsConfig.ACCELERATORS.value
  },
  {
    id: CompanyColumnsConfig.COMPANY_EMAILS.value,
    name: CompanyColumnsConfig.COMPANY_EMAILS.label,
    isVisibleOnTable: true
  },
  {
    isVisibleOnTable: true,
    id: CompanyColumnsConfig.TEAM_EMAILS.value,
    name: CompanyColumnsConfig.TEAM_EMAILS.label
  },
  {
    name: CompanyColumnsConfig.PHONE_NUMBER.label,
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.PHONE_NUMBER.value
  },
  {
    id: CompanyColumnsConfig.TWITTER.value,
    name: CompanyColumnsConfig.TWITTER.label,
    isVisibleOnTable: true
  },
  {
    id: CompanyColumnsConfig.TWITTER_HANDLE.value,
    name: CompanyColumnsConfig.TWITTER_HANDLE.label,
    isVisibleOnTable: true
  },
  {
    isVisibleOnTable: true,
    id: CompanyColumnsConfig.CRUNCHBASE.value,
    name: CompanyColumnsConfig.CRUNCHBASE.label
  },
  {
    name: CompanyColumnsConfig.PITCHBOOK.label,
    isVisibleOnTable: true,
    id: CompanyColumnsConfig.PITCHBOOK.value
  },
  {
    isVisibleOnTable: true,
    name: CompanyColumnsConfig.INSTAGRAM.label,
    id: CompanyColumnsConfig.INSTAGRAM.value
  },
  {
    name: CompanyColumnsConfig.FACEBOOK.label,
    id: CompanyColumnsConfig.FACEBOOK.value,
    isVisibleOnTable: true
  },
  {
    name: CompanyColumnsConfig.ANGELLIST.label,
    id: CompanyColumnsConfig.ANGELLIST.value,
    isVisibleOnTable: true
  },
  {
    id: CompanyColumnsConfig.INDEED.value,
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.INDEED.label
  },
  {
    name: CompanyColumnsConfig.YOUTUBE.label,
    id: CompanyColumnsConfig.YOUTUBE.value,
    isVisibleOnTable: false
  },
  {
    name: CompanyColumnsConfig.MONSTER.label,
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.MONSTER.value
  },
  {
    id: CompanyColumnsConfig.STACKOVERFLOW.value,
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.STACKOVERFLOW.label
  },
  {
    id: CompanyColumnsConfig.LINKEDIN_FOLLOWERS.value,
    name: CompanyColumnsConfig.LINKEDIN_FOLLOWERS.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.TWITTER_FOLLOWERS.value,
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.TWITTER_FOLLOWERS.label
  },
  {
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.INSTAGRAM_FOLLOWERS.value,
    name: CompanyColumnsConfig.INSTAGRAM_FOLLOWERS.label
  },
  {
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.HEADCOUNT_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_14D_AGO.label
  },
  {
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.HEADCOUNT_REAL_CHANGE_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_REAL_CHANGE_14D_AGO.label
  },
  {
    name: CompanyColumnsConfig.HEADCOUNT_PERCENT_CHANGE_14D_AGO.label,
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.HEADCOUNT_PERCENT_CHANGE_14D_AGO.value
  },
  {
    name: CompanyColumnsConfig.HEADCOUNT_30D_AGO.label,
    id: CompanyColumnsConfig.HEADCOUNT_30D_AGO.value,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_REAL_CHANGE_30D_AGO.value,
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.HEADCOUNT_REAL_CHANGE_30D_AGO.label
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_PERCENT_CHANGE_30D_AGO.value,
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.HEADCOUNT_PERCENT_CHANGE_30D_AGO.label
  },
  {
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.HEADCOUNT_90D_AGO.label,
    id: CompanyColumnsConfig.HEADCOUNT_90D_AGO.value
  },
  {
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.HEADCOUNT_REAL_CHANGE_90D_AGO.label,
    id: CompanyColumnsConfig.HEADCOUNT_REAL_CHANGE_90D_AGO.value
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_PERCENT_CHANGE_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_PERCENT_CHANGE_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    name: CompanyColumnsConfig.HEADCOUNT_180D_AGO.label,
    id: CompanyColumnsConfig.HEADCOUNT_180D_AGO.value,
    isVisibleOnTable: false
  },
  {
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.HEADCOUNT_REAL_CHANGE_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_REAL_CHANGE_180D_AGO.label
  },
  {
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.HEADCOUNT_PERCENT_CHANGE_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_PERCENT_CHANGE_180D_AGO.label
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_365D_AGO.value,
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.HEADCOUNT_365D_AGO.label
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_REAL_CHANGE_365D_AGO.value,
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.HEADCOUNT_REAL_CHANGE_365D_AGO.label
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_PERCENT_CHANGE_365D_AGO.value,
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.HEADCOUNT_PERCENT_CHANGE_365D_AGO.label
  },
  {
    name: CompanyColumnsConfig.WEB_TRAFFIC.label,
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.WEB_TRAFFIC.value
  },
  {
    name: CompanyColumnsConfig.WEB_TRAFFIC_30D_AGO.label,
    id: CompanyColumnsConfig.WEB_TRAFFIC_30D_AGO.value,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.WEB_TRAFFIC_REAL_CHANGE_30D_AGO.value,
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.WEB_TRAFFIC_REAL_CHANGE_30D_AGO.label
  },
  {
    id: CompanyColumnsConfig.WEB_TRAFFIC_PERCENT_CHANGE_30D_AGO.value,
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.WEB_TRAFFIC_PERCENT_CHANGE_30D_AGO.label
  },
  {
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.WEB_TRAFFIC_90D_AGO.label,
    id: CompanyColumnsConfig.WEB_TRAFFIC_90D_AGO.value
  },
  {
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.WEB_TRAFFIC_REAL_CHANGE_90D_AGO.label,
    id: CompanyColumnsConfig.WEB_TRAFFIC_REAL_CHANGE_90D_AGO.value
  },
  {
    id: CompanyColumnsConfig.WEB_TRAFFIC_PERCENT_CHANGE_90D_AGO.value,
    name: CompanyColumnsConfig.WEB_TRAFFIC_PERCENT_CHANGE_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    name: CompanyColumnsConfig.WEB_TRAFFIC_180D_AGO.label,
    id: CompanyColumnsConfig.WEB_TRAFFIC_180D_AGO.value,
    isVisibleOnTable: false
  },
  {
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.WEB_TRAFFIC_REAL_CHANGE_180D_AGO.value,
    name: CompanyColumnsConfig.WEB_TRAFFIC_REAL_CHANGE_180D_AGO.label
  },
  {
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.WEB_TRAFFIC_PERCENT_CHANGE_180D_AGO.value,
    name: CompanyColumnsConfig.WEB_TRAFFIC_PERCENT_CHANGE_180D_AGO.label
  },
  {
    id: CompanyColumnsConfig.WEB_TRAFFIC_365D_AGO.value,
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.WEB_TRAFFIC_365D_AGO.label
  },
  {
    id: CompanyColumnsConfig.WEB_TRAFFIC_REAL_CHANGE_365D_AGO.value,
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.WEB_TRAFFIC_REAL_CHANGE_365D_AGO.label
  },
  {
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.WEB_TRAFFIC_PERCENT_CHANGE_365D_AGO.value,
    name: CompanyColumnsConfig.WEB_TRAFFIC_PERCENT_CHANGE_365D_AGO.label
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_ADVISOR_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_ADVISOR_14D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_ADVISOR_REAL_CHANGE_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_ADVISOR_REAL_CHANGE_14D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_ADVISOR_PERCENT_CHANGE_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_ADVISOR_PERCENT_CHANGE_14D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_ADVISOR_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_ADVISOR_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_ADVISOR_REAL_CHANGE_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_ADVISOR_REAL_CHANGE_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_ADVISOR_PERCENT_CHANGE_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_ADVISOR_PERCENT_CHANGE_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_ADVISOR_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_ADVISOR_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_ADVISOR_REAL_CHANGE_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_ADVISOR_REAL_CHANGE_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_ADVISOR_PERCENT_CHANGE_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_ADVISOR_PERCENT_CHANGE_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_ADVISOR_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_ADVISOR_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_ADVISOR_REAL_CHANGE_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_ADVISOR_REAL_CHANGE_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_ADVISOR_PERCENT_CHANGE_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_ADVISOR_PERCENT_CHANGE_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_ADVISOR_365D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_ADVISOR_365D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_ADVISOR_REAL_CHANGE_365D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_ADVISOR_REAL_CHANGE_365D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_ADVISOR_PERCENT_CHANGE_365D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_ADVISOR_PERCENT_CHANGE_365D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_CUSTOMER_SUCCESS_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_CUSTOMER_SUCCESS_14D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_CUSTOMER_SUCCESS_REAL_CHANGE_14D_AGO
      .value,
    name: CompanyColumnsConfig.HEADCOUNT_CUSTOMER_SUCCESS_REAL_CHANGE_14D_AGO
      .label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_CUSTOMER_SUCCESS_PERCENT_CHANGE_14D_AGO
      .value,
    name: CompanyColumnsConfig.HEADCOUNT_CUSTOMER_SUCCESS_PERCENT_CHANGE_14D_AGO
      .label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_CUSTOMER_SUCCESS_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_CUSTOMER_SUCCESS_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_CUSTOMER_SUCCESS_REAL_CHANGE_30D_AGO
      .value,
    name: CompanyColumnsConfig.HEADCOUNT_CUSTOMER_SUCCESS_REAL_CHANGE_30D_AGO
      .label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_CUSTOMER_SUCCESS_PERCENT_CHANGE_30D_AGO
      .value,
    name: CompanyColumnsConfig.HEADCOUNT_CUSTOMER_SUCCESS_PERCENT_CHANGE_30D_AGO
      .label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_CUSTOMER_SUCCESS_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_CUSTOMER_SUCCESS_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_CUSTOMER_SUCCESS_REAL_CHANGE_90D_AGO
      .value,
    name: CompanyColumnsConfig.HEADCOUNT_CUSTOMER_SUCCESS_REAL_CHANGE_90D_AGO
      .label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_CUSTOMER_SUCCESS_PERCENT_CHANGE_90D_AGO
      .value,
    name: CompanyColumnsConfig.HEADCOUNT_CUSTOMER_SUCCESS_PERCENT_CHANGE_90D_AGO
      .label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_CUSTOMER_SUCCESS_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_CUSTOMER_SUCCESS_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_CUSTOMER_SUCCESS_REAL_CHANGE_180D_AGO
      .value,
    name: CompanyColumnsConfig.HEADCOUNT_CUSTOMER_SUCCESS_REAL_CHANGE_180D_AGO
      .label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_CUSTOMER_SUCCESS_PERCENT_CHANGE_180D_AGO
      .value,
    name: CompanyColumnsConfig
      .HEADCOUNT_CUSTOMER_SUCCESS_PERCENT_CHANGE_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_CUSTOMER_SUCCESS_365D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_CUSTOMER_SUCCESS_365D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_CUSTOMER_SUCCESS_REAL_CHANGE_365D_AGO
      .value,
    name: CompanyColumnsConfig.HEADCOUNT_CUSTOMER_SUCCESS_REAL_CHANGE_365D_AGO
      .label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_CUSTOMER_SUCCESS_REAL_CHANGE_365D_AGO
      .value,
    name: CompanyColumnsConfig
      .HEADCOUNT_CUSTOMER_SUCCESS_PERCENT_CHANGE_365D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_DATA_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_DATA_14D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_DATA_REAL_CHANGE_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_DATA_REAL_CHANGE_14D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_DATA_PERCENT_CHANGE_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_DATA_PERCENT_CHANGE_14D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_DATA_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_DATA_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_DATA_REAL_CHANGE_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_DATA_REAL_CHANGE_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_DATA_PERCENT_CHANGE_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_DATA_PERCENT_CHANGE_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_DATA_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_DATA_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_DATA_REAL_CHANGE_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_DATA_REAL_CHANGE_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_DATA_PERCENT_CHANGE_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_DATA_PERCENT_CHANGE_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_DATA_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_DATA_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_DATA_REAL_CHANGE_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_DATA_REAL_CHANGE_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_DATA_PERCENT_CHANGE_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_DATA_PERCENT_CHANGE_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_DATA_365D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_DATA_365D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_DATA_REAL_CHANGE_365D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_DATA_REAL_CHANGE_365D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_DATA_PERCENT_CHANGE_365D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_DATA_PERCENT_CHANGE_365D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_DESIGN_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_DESIGN_14D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_DESIGN_REAL_CHANGE_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_DESIGN_REAL_CHANGE_14D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_DESIGN_PERCENT_CHANGE_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_DESIGN_PERCENT_CHANGE_14D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_DESIGN_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_DESIGN_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_DESIGN_REAL_CHANGE_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_DESIGN_REAL_CHANGE_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_DESIGN_PERCENT_CHANGE_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_DESIGN_PERCENT_CHANGE_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_DESIGN_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_DESIGN_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_DESIGN_REAL_CHANGE_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_DESIGN_REAL_CHANGE_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_DESIGN_PERCENT_CHANGE_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_DESIGN_PERCENT_CHANGE_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_DESIGN_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_DESIGN_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_DESIGN_REAL_CHANGE_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_DESIGN_REAL_CHANGE_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_DESIGN_PERCENT_CHANGE_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_DESIGN_PERCENT_CHANGE_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_DESIGN_365D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_DESIGN_365D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_DESIGN_REAL_CHANGE_365D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_DESIGN_REAL_CHANGE_365D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_DESIGN_PERCENT_CHANGE_365D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_DESIGN_PERCENT_CHANGE_365D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_ENGINEERING_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_ENGINEERING_14D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_ENGINEERING_REAL_CHANGE_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_ENGINEERING_REAL_CHANGE_14D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_ENGINEERING_PERCENT_CHANGE_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_ENGINEERING_PERCENT_CHANGE_14D_AGO
      .label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_ENGINEERING_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_ENGINEERING_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_ENGINEERING_REAL_CHANGE_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_ENGINEERING_REAL_CHANGE_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_ENGINEERING_PERCENT_CHANGE_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_ENGINEERING_PERCENT_CHANGE_30D_AGO
      .label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_ENGINEERING_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_ENGINEERING_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_ENGINEERING_REAL_CHANGE_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_ENGINEERING_REAL_CHANGE_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_ENGINEERING_PERCENT_CHANGE_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_ENGINEERING_PERCENT_CHANGE_90D_AGO
      .label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_ENGINEERING_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_ENGINEERING_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_ENGINEERING_REAL_CHANGE_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_ENGINEERING_REAL_CHANGE_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_ENGINEERING_PERCENT_CHANGE_180D_AGO
      .value,
    name: CompanyColumnsConfig.HEADCOUNT_ENGINEERING_PERCENT_CHANGE_180D_AGO
      .label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_ENGINEERING_365D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_ENGINEERING_365D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_ENGINEERING_REAL_CHANGE_365D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_ENGINEERING_REAL_CHANGE_365D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_ENGINEERING_PERCENT_CHANGE_365D_AGO
      .value,
    name: CompanyColumnsConfig.HEADCOUNT_ENGINEERING_PERCENT_CHANGE_365D_AGO
      .label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_FINANCE_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_FINANCE_14D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_FINANCE_REAL_CHANGE_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_FINANCE_REAL_CHANGE_14D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_FINANCE_PERCENT_CHANGE_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_FINANCE_PERCENT_CHANGE_14D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_FINANCE_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_FINANCE_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_FINANCE_REAL_CHANGE_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_FINANCE_REAL_CHANGE_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_FINANCE_PERCENT_CHANGE_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_FINANCE_PERCENT_CHANGE_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_FINANCE_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_FINANCE_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_FINANCE_REAL_CHANGE_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_FINANCE_REAL_CHANGE_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_FINANCE_PERCENT_CHANGE_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_FINANCE_PERCENT_CHANGE_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_FINANCE_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_FINANCE_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_FINANCE_REAL_CHANGE_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_FINANCE_REAL_CHANGE_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_FINANCE_PERCENT_CHANGE_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_FINANCE_PERCENT_CHANGE_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_FINANCE_365D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_FINANCE_365D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_FINANCE_REAL_CHANGE_365D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_FINANCE_REAL_CHANGE_365D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_FINANCE_PERCENT_CHANGE_365D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_FINANCE_PERCENT_CHANGE_365D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_LEGAL_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_LEGAL_14D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_LEGAL_REAL_CHANGE_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_LEGAL_REAL_CHANGE_14D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_LEGAL_PERCENT_CHANGE_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_LEGAL_PERCENT_CHANGE_14D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_LEGAL_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_LEGAL_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_LEGAL_REAL_CHANGE_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_LEGAL_REAL_CHANGE_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_LEGAL_PERCENT_CHANGE_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_LEGAL_PERCENT_CHANGE_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_LEGAL_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_LEGAL_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_LEGAL_REAL_CHANGE_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_LEGAL_REAL_CHANGE_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_LEGAL_PERCENT_CHANGE_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_LEGAL_PERCENT_CHANGE_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_LEGAL_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_LEGAL_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_LEGAL_REAL_CHANGE_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_LEGAL_REAL_CHANGE_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_LEGAL_PERCENT_CHANGE_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_LEGAL_PERCENT_CHANGE_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_LEGAL_365D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_LEGAL_365D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_LEGAL_REAL_CHANGE_365D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_LEGAL_REAL_CHANGE_365D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_LEGAL_PERCENT_CHANGE_365D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_LEGAL_PERCENT_CHANGE_365D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_MARKETING_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_MARKETING_14D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_MARKETING_REAL_CHANGE_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_MARKETING_REAL_CHANGE_14D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_MARKETING_PERCENT_CHANGE_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_MARKETING_PERCENT_CHANGE_14D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_MARKETING_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_MARKETING_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_MARKETING_REAL_CHANGE_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_MARKETING_REAL_CHANGE_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_MARKETING_PERCENT_CHANGE_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_MARKETING_PERCENT_CHANGE_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_MARKETING_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_MARKETING_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_MARKETING_REAL_CHANGE_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_MARKETING_REAL_CHANGE_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_MARKETING_PERCENT_CHANGE_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_MARKETING_PERCENT_CHANGE_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_MARKETING_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_MARKETING_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_MARKETING_REAL_CHANGE_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_MARKETING_REAL_CHANGE_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_MARKETING_PERCENT_CHANGE_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_MARKETING_PERCENT_CHANGE_180D_AGO
      .label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_MARKETING_365D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_MARKETING_365D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_MARKETING_REAL_CHANGE_365D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_MARKETING_REAL_CHANGE_365D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_MARKETING_PERCENT_CHANGE_365D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_MARKETING_PERCENT_CHANGE_365D_AGO
      .label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_OPERATIONS_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_OPERATIONS_14D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_OPERATIONS_REAL_CHANGE_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_OPERATIONS_REAL_CHANGE_14D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_OPERATIONS_PERCENT_CHANGE_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_OPERATIONS_PERCENT_CHANGE_14D_AGO
      .label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_OPERATIONS_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_OPERATIONS_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_OPERATIONS_REAL_CHANGE_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_OPERATIONS_REAL_CHANGE_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_OPERATIONS_PERCENT_CHANGE_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_OPERATIONS_PERCENT_CHANGE_30D_AGO
      .label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_OPERATIONS_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_OPERATIONS_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_OPERATIONS_REAL_CHANGE_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_OPERATIONS_REAL_CHANGE_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_OPERATIONS_PERCENT_CHANGE_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_OPERATIONS_PERCENT_CHANGE_90D_AGO
      .label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_OPERATIONS_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_OPERATIONS_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_OPERATIONS_REAL_CHANGE_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_OPERATIONS_REAL_CHANGE_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_OPERATIONS_PERCENT_CHANGE_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_OPERATIONS_PERCENT_CHANGE_180D_AGO
      .label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_OPERATIONS_365D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_OPERATIONS_365D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_OPERATIONS_REAL_CHANGE_365D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_OPERATIONS_REAL_CHANGE_365D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_OPERATIONS_PERCENT_CHANGE_365D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_OPERATIONS_PERCENT_CHANGE_365D_AGO
      .label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_OTHER_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_OTHER_14D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_OTHER_REAL_CHANGE_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_OTHER_REAL_CHANGE_14D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_OTHER_PERCENT_CHANGE_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_OTHER_PERCENT_CHANGE_14D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_OTHER_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_OTHER_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_OTHER_REAL_CHANGE_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_OTHER_REAL_CHANGE_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_OTHER_PERCENT_CHANGE_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_OTHER_PERCENT_CHANGE_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_OTHER_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_OTHER_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_OTHER_REAL_CHANGE_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_OTHER_REAL_CHANGE_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_OTHER_PERCENT_CHANGE_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_OTHER_PERCENT_CHANGE_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_OTHER_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_OTHER_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_OTHER_REAL_CHANGE_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_OTHER_REAL_CHANGE_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_OTHER_PERCENT_CHANGE_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_OTHER_PERCENT_CHANGE_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_OTHER_365D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_OTHER_365D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_OTHER_REAL_CHANGE_365D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_OTHER_REAL_CHANGE_365D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_OTHER_PERCENT_CHANGE_365D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_OTHER_PERCENT_CHANGE_365D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_PEOPLE_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_PEOPLE_14D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_PEOPLE_REAL_CHANGE_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_PEOPLE_REAL_CHANGE_14D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_PEOPLE_PERCENT_CHANGE_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_PEOPLE_PERCENT_CHANGE_14D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_PEOPLE_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_PEOPLE_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_PEOPLE_REAL_CHANGE_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_PEOPLE_REAL_CHANGE_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_PEOPLE_PERCENT_CHANGE_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_PEOPLE_PERCENT_CHANGE_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_PEOPLE_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_PEOPLE_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_PEOPLE_REAL_CHANGE_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_PEOPLE_REAL_CHANGE_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_PEOPLE_PERCENT_CHANGE_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_PEOPLE_PERCENT_CHANGE_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_PEOPLE_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_PEOPLE_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_PEOPLE_REAL_CHANGE_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_PEOPLE_REAL_CHANGE_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_PEOPLE_PERCENT_CHANGE_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_PEOPLE_PERCENT_CHANGE_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_PEOPLE_365D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_PEOPLE_365D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_PEOPLE_REAL_CHANGE_365D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_PEOPLE_REAL_CHANGE_365D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_PEOPLE_PERCENT_CHANGE_365D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_PEOPLE_PERCENT_CHANGE_365D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_PRODUCT_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_PRODUCT_14D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_PRODUCT_REAL_CHANGE_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_PRODUCT_REAL_CHANGE_14D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_PRODUCT_PERCENT_CHANGE_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_PRODUCT_PERCENT_CHANGE_14D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_PRODUCT_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_PRODUCT_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_PRODUCT_REAL_CHANGE_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_PRODUCT_REAL_CHANGE_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_PRODUCT_PERCENT_CHANGE_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_PRODUCT_PERCENT_CHANGE_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_PRODUCT_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_PRODUCT_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_PRODUCT_REAL_CHANGE_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_PRODUCT_REAL_CHANGE_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_PRODUCT_PERCENT_CHANGE_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_PRODUCT_PERCENT_CHANGE_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_PRODUCT_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_PRODUCT_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_PRODUCT_REAL_CHANGE_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_PRODUCT_REAL_CHANGE_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_PRODUCT_PERCENT_CHANGE_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_PRODUCT_PERCENT_CHANGE_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_PRODUCT_365D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_PRODUCT_365D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_PRODUCT_REAL_CHANGE_365D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_PRODUCT_REAL_CHANGE_365D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_PRODUCT_PERCENT_CHANGE_365D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_PRODUCT_PERCENT_CHANGE_365D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_SALES_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_SALES_14D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_SALES_REAL_CHANGE_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_SALES_REAL_CHANGE_14D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_SALES_PERCENT_CHANGE_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_SALES_PERCENT_CHANGE_14D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_SALES_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_SALES_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_SALES_REAL_CHANGE_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_SALES_REAL_CHANGE_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_SALES_PERCENT_CHANGE_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_SALES_PERCENT_CHANGE_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_SALES_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_SALES_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_SALES_REAL_CHANGE_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_SALES_REAL_CHANGE_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_SALES_PERCENT_CHANGE_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_SALES_PERCENT_CHANGE_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_SALES_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_SALES_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_SALES_REAL_CHANGE_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_SALES_REAL_CHANGE_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_SALES_PERCENT_CHANGE_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_SALES_PERCENT_CHANGE_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_SALES_365D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_SALES_365D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_SALES_REAL_CHANGE_365D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_SALES_REAL_CHANGE_365D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_SALES_PERCENT_CHANGE_365D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_SALES_PERCENT_CHANGE_365D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_SUPPORT_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_SUPPORT_14D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_SUPPORT_REAL_CHANGE_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_SUPPORT_REAL_CHANGE_14D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_SUPPORT_PERCENT_CHANGE_14D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_SUPPORT_PERCENT_CHANGE_14D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_SUPPORT_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_SUPPORT_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_SUPPORT_REAL_CHANGE_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_SUPPORT_REAL_CHANGE_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_SUPPORT_PERCENT_CHANGE_30D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_SUPPORT_PERCENT_CHANGE_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_SUPPORT_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_SUPPORT_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_SUPPORT_REAL_CHANGE_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_SUPPORT_REAL_CHANGE_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_SUPPORT_PERCENT_CHANGE_90D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_SUPPORT_PERCENT_CHANGE_90D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_SUPPORT_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_SUPPORT_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_SUPPORT_REAL_CHANGE_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_SUPPORT_REAL_CHANGE_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_SUPPORT_PERCENT_CHANGE_180D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_SUPPORT_PERCENT_CHANGE_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_SUPPORT_365D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_SUPPORT_365D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_SUPPORT_REAL_CHANGE_365D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_SUPPORT_REAL_CHANGE_365D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.HEADCOUNT_SUPPORT_PERCENT_CHANGE_365D_AGO.value,
    name: CompanyColumnsConfig.HEADCOUNT_SUPPORT_PERCENT_CHANGE_365D_AGO.label,
    isVisibleOnTable: false
  },
  {
    name: CompanyColumnsConfig.FUNDING_TOTAL_14D_AGO.label,
    id: CompanyColumnsConfig.FUNDING_TOTAL_14D_AGO.value,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.FUNDING_TOTAL_REAL_CHANGE_14D_AGO.value,
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.FUNDING_TOTAL_REAL_CHANGE_14D_AGO.label
  },
  {
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.FUNDING_TOTAL_PERCENT_CHANGE_14D_AGO.label,
    id: CompanyColumnsConfig.FUNDING_TOTAL_PERCENT_CHANGE_14D_AGO.value
  },
  {
    id: CompanyColumnsConfig.FUNDING_TOTAL_30D_AGO.value,
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.FUNDING_TOTAL_30D_AGO.label
  },
  {
    id: CompanyColumnsConfig.FUNDING_TOTAL_REAL_CHANGE_30D_AGO.value,
    name: CompanyColumnsConfig.FUNDING_TOTAL_REAL_CHANGE_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    name: CompanyColumnsConfig.FUNDING_TOTAL_PERCENT_CHANGE_30D_AGO.label,
    id: CompanyColumnsConfig.FUNDING_TOTAL_PERCENT_CHANGE_30D_AGO.value,
    isVisibleOnTable: false
  },
  {
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.FUNDING_TOTAL_90D_AGO.value,
    name: CompanyColumnsConfig.FUNDING_TOTAL_90D_AGO.label
  },
  {
    id: CompanyColumnsConfig.FUNDING_TOTAL_REAL_CHANGE_90D_AGO.value,
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.FUNDING_TOTAL_REAL_CHANGE_90D_AGO.label
  },
  {
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.FUNDING_TOTAL_PERCENT_CHANGE_90D_AGO.value,
    name: CompanyColumnsConfig.FUNDING_TOTAL_PERCENT_CHANGE_90D_AGO.label
  },
  {
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.FUNDING_TOTAL_180D_AGO.value,
    name: CompanyColumnsConfig.FUNDING_TOTAL_180D_AGO.label
  },
  {
    id: CompanyColumnsConfig.FUNDING_TOTAL_REAL_CHANGE_180D_AGO.value,
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.FUNDING_TOTAL_REAL_CHANGE_180D_AGO.label
  },
  {
    id: CompanyColumnsConfig.FUNDING_TOTAL_PERCENT_CHANGE_180D_AGO.value,
    name: CompanyColumnsConfig.FUNDING_TOTAL_PERCENT_CHANGE_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    name: CompanyColumnsConfig.FUNDING_TOTAL_365D_AGO.label,
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.FUNDING_TOTAL_365D_AGO.value
  },
  {
    id: CompanyColumnsConfig.FUNDING_TOTAL_REAL_CHANGE_365D_AGO.value,
    name: CompanyColumnsConfig.FUNDING_TOTAL_REAL_CHANGE_365D_AGO.label,
    isVisibleOnTable: false
  },
  {
    name: CompanyColumnsConfig.FUNDING_TOTAL_PERCENT_CHANGE_365D_AGO.label,
    id: CompanyColumnsConfig.FUNDING_TOTAL_PERCENT_CHANGE_365D_AGO.value,
    isVisibleOnTable: false
  },
  {
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.TWITTER_FOLLOWERS_14D_AGO.label,
    id: CompanyColumnsConfig.TWITTER_FOLLOWERS_14D_AGO.value
  },
  {
    id: CompanyColumnsConfig.TWITTER_FOLLOWERS_REAL_CHANGE_14D_AGO.value,
    name: CompanyColumnsConfig.TWITTER_FOLLOWERS_REAL_CHANGE_14D_AGO.label,
    isVisibleOnTable: false
  },
  {
    name: CompanyColumnsConfig.TWITTER_FOLLOWERS_PERCENT_CHANGE_14D_AGO.label,
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.TWITTER_FOLLOWERS_PERCENT_CHANGE_14D_AGO.value
  },
  {
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.TWITTER_FOLLOWERS_30D_AGO.value,
    name: CompanyColumnsConfig.TWITTER_FOLLOWERS_30D_AGO.label
  },
  {
    id: CompanyColumnsConfig.TWITTER_FOLLOWERS_REAL_CHANGE_30D_AGO.value,
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.TWITTER_FOLLOWERS_REAL_CHANGE_30D_AGO.label
  },
  {
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.TWITTER_FOLLOWERS_PERCENT_CHANGE_30D_AGO.label,
    id: CompanyColumnsConfig.TWITTER_FOLLOWERS_PERCENT_CHANGE_30D_AGO.value
  },
  {
    id: CompanyColumnsConfig.TWITTER_FOLLOWERS_90D_AGO.value,
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.TWITTER_FOLLOWERS_90D_AGO.label
  },
  {
    name: CompanyColumnsConfig.TWITTER_FOLLOWERS_REAL_CHANGE_90D_AGO.label,
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.TWITTER_FOLLOWERS_REAL_CHANGE_90D_AGO.value
  },
  {
    name: CompanyColumnsConfig.TWITTER_FOLLOWERS_PERCENT_CHANGE_90D_AGO.label,
    id: CompanyColumnsConfig.TWITTER_FOLLOWERS_PERCENT_CHANGE_90D_AGO.value,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.TWITTER_FOLLOWERS_180D_AGO.value,
    name: CompanyColumnsConfig.TWITTER_FOLLOWERS_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.TWITTER_FOLLOWERS_REAL_CHANGE_180D_AGO.label,
    id: CompanyColumnsConfig.TWITTER_FOLLOWERS_REAL_CHANGE_180D_AGO.value
  },
  {
    id: CompanyColumnsConfig.TWITTER_FOLLOWERS_PERCENT_CHANGE_180D_AGO.value,
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.TWITTER_FOLLOWERS_PERCENT_CHANGE_180D_AGO.label
  },
  {
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.TWITTER_FOLLOWERS_365D_AGO.value,
    name: CompanyColumnsConfig.TWITTER_FOLLOWERS_365D_AGO.label
  },
  {
    name: CompanyColumnsConfig.TWITTER_FOLLOWERS_REAL_CHANGE_365D_AGO.label,
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.TWITTER_FOLLOWERS_REAL_CHANGE_365D_AGO.value
  },
  {
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.TWITTER_FOLLOWERS_PERCENT_CHANGE_365D_AGO.value,
    name: CompanyColumnsConfig.TWITTER_FOLLOWERS_PERCENT_CHANGE_365D_AGO.label
  },
  {
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.LINKEDIN_FOLLOWERS_14D_AGO.value,
    name: CompanyColumnsConfig.LINKEDIN_FOLLOWERS_14D_AGO.label
  },
  {
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.LINKEDIN_FOLLOWERS_REAL_CHANGE_14D_AGO.label,
    id: CompanyColumnsConfig.LINKEDIN_FOLLOWERS_REAL_CHANGE_14D_AGO.value
  },
  {
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.LINKEDIN_FOLLOWERS_PERCENT_CHANGE_14D_AGO.value,
    name: CompanyColumnsConfig.LINKEDIN_FOLLOWERS_PERCENT_CHANGE_14D_AGO.label
  },
  {
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.LINKEDIN_FOLLOWERS_30D_AGO.value,
    name: CompanyColumnsConfig.LINKEDIN_FOLLOWERS_30D_AGO.label
  },
  {
    name: CompanyColumnsConfig.LINKEDIN_FOLLOWERS_REAL_CHANGE_30D_AGO.label,
    id: CompanyColumnsConfig.LINKEDIN_FOLLOWERS_REAL_CHANGE_30D_AGO.value,
    isVisibleOnTable: false
  },
  {
    name: CompanyColumnsConfig.LINKEDIN_FOLLOWERS_PERCENT_CHANGE_30D_AGO.label,
    id: CompanyColumnsConfig.LINKEDIN_FOLLOWERS_PERCENT_CHANGE_30D_AGO.value,
    isVisibleOnTable: false
  },
  {
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.LINKEDIN_FOLLOWERS_90D_AGO.label,
    id: CompanyColumnsConfig.LINKEDIN_FOLLOWERS_90D_AGO.value
  },
  {
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.LINKEDIN_FOLLOWERS_REAL_CHANGE_90D_AGO.label,
    id: CompanyColumnsConfig.LINKEDIN_FOLLOWERS_REAL_CHANGE_90D_AGO.value
  },
  {
    id: CompanyColumnsConfig.LINKEDIN_FOLLOWERS_PERCENT_CHANGE_90D_AGO.value,
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.LINKEDIN_FOLLOWERS_PERCENT_CHANGE_90D_AGO.label
  },
  {
    id: CompanyColumnsConfig.LINKEDIN_FOLLOWERS_180D_AGO.value,
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.LINKEDIN_FOLLOWERS_180D_AGO.label
  },
  {
    name: CompanyColumnsConfig.LINKEDIN_FOLLOWERS_REAL_CHANGE_180D_AGO.label,
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.LINKEDIN_FOLLOWERS_REAL_CHANGE_180D_AGO.value
  },
  {
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.LINKEDIN_FOLLOWERS_PERCENT_CHANGE_180D_AGO.label,
    id: CompanyColumnsConfig.LINKEDIN_FOLLOWERS_PERCENT_CHANGE_180D_AGO.value
  },
  {
    name: CompanyColumnsConfig.LINKEDIN_FOLLOWERS_365D_AGO.label,
    id: CompanyColumnsConfig.LINKEDIN_FOLLOWERS_365D_AGO.value,
    isVisibleOnTable: false
  },
  {
    name: CompanyColumnsConfig.LINKEDIN_FOLLOWERS_REAL_CHANGE_365D_AGO.label,
    id: CompanyColumnsConfig.LINKEDIN_FOLLOWERS_REAL_CHANGE_365D_AGO.value,
    isVisibleOnTable: false
  },
  {
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.LINKEDIN_FOLLOWERS_PERCENT_CHANGE_365D_AGO.value,
    name: CompanyColumnsConfig.LINKEDIN_FOLLOWERS_PERCENT_CHANGE_365D_AGO.label
  },
  {
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.FACEBOOK_LIKES_14D_AGO.value,
    name: CompanyColumnsConfig.FACEBOOK_LIKES_14D_AGO.label
  },
  {
    name: CompanyColumnsConfig.FACEBOOK_LIKES_REAL_CHANGE_14D_AGO.label,
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.FACEBOOK_LIKES_REAL_CHANGE_14D_AGO.value
  },
  {
    name: CompanyColumnsConfig.FACEBOOK_LIKES_PERCENT_CHANGE_14D_AGO.label,
    id: CompanyColumnsConfig.FACEBOOK_LIKES_PERCENT_CHANGE_14D_AGO.value,
    isVisibleOnTable: false
  },
  {
    name: CompanyColumnsConfig.FACEBOOK_LIKES_30D_AGO.label,
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.FACEBOOK_LIKES_30D_AGO.value
  },
  {
    name: CompanyColumnsConfig.FACEBOOK_LIKES_REAL_CHANGE_30D_AGO.label,
    id: CompanyColumnsConfig.FACEBOOK_LIKES_REAL_CHANGE_30D_AGO.value,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.FACEBOOK_LIKES_PERCENT_CHANGE_30D_AGO.value,
    name: CompanyColumnsConfig.FACEBOOK_LIKES_PERCENT_CHANGE_30D_AGO.label,
    isVisibleOnTable: false
  },
  {
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.FACEBOOK_LIKES_90D_AGO.value,
    name: CompanyColumnsConfig.FACEBOOK_LIKES_90D_AGO.label
  },
  {
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.FACEBOOK_LIKES_REAL_CHANGE_90D_AGO.value,
    name: CompanyColumnsConfig.FACEBOOK_LIKES_REAL_CHANGE_90D_AGO.label
  },
  {
    id: CompanyColumnsConfig.FACEBOOK_LIKES_PERCENT_CHANGE_90D_AGO.value,
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.FACEBOOK_LIKES_PERCENT_CHANGE_90D_AGO.label
  },
  {
    id: CompanyColumnsConfig.FACEBOOK_LIKES_180D_AGO.value,
    name: CompanyColumnsConfig.FACEBOOK_LIKES_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.FACEBOOK_LIKES_REAL_CHANGE_180D_AGO.value,
    name: CompanyColumnsConfig.FACEBOOK_LIKES_REAL_CHANGE_180D_AGO.label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.FACEBOOK_LIKES_PERCENT_CHANGE_180D_AGO.value,
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.FACEBOOK_LIKES_PERCENT_CHANGE_180D_AGO.label
  },
  {
    name: CompanyColumnsConfig.FACEBOOK_LIKES_365D_AGO.label,
    id: CompanyColumnsConfig.FACEBOOK_LIKES_365D_AGO.value,
    isVisibleOnTable: false
  },
  {
    name: CompanyColumnsConfig.FACEBOOK_LIKES_REAL_CHANGE_365D_AGO.label,
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.FACEBOOK_LIKES_REAL_CHANGE_365D_AGO.value
  },
  {
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.FACEBOOK_LIKES_PERCENT_CHANGE_365D_AGO.label,
    id: CompanyColumnsConfig.FACEBOOK_LIKES_PERCENT_CHANGE_365D_AGO.value
  },
  {
    name: CompanyColumnsConfig.INSTAGRAM_FOLLOWERS_14D_AGO.label,
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.INSTAGRAM_FOLLOWERS_14D_AGO.value
  },
  {
    name: CompanyColumnsConfig.INSTAGRAM_FOLLOWERS_REAL_CHANGE_14D_AGO.label,
    id: CompanyColumnsConfig.INSTAGRAM_FOLLOWERS_REAL_CHANGE_14D_AGO.value,
    isVisibleOnTable: false
  },
  {
    name: CompanyColumnsConfig.INSTAGRAM_FOLLOWERS_PERCENT_CHANGE_14D_AGO.label,
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.INSTAGRAM_FOLLOWERS_PERCENT_CHANGE_14D_AGO.value
  },
  {
    name: CompanyColumnsConfig.INSTAGRAM_FOLLOWERS_30D_AGO.label,
    id: CompanyColumnsConfig.INSTAGRAM_FOLLOWERS_30D_AGO.value,
    isVisibleOnTable: false
  },
  {
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.INSTAGRAM_FOLLOWERS_REAL_CHANGE_30D_AGO.value,
    name: CompanyColumnsConfig.INSTAGRAM_FOLLOWERS_REAL_CHANGE_30D_AGO.label
  },
  {
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.INSTAGRAM_FOLLOWERS_PERCENT_CHANGE_30D_AGO.label,
    id: CompanyColumnsConfig.INSTAGRAM_FOLLOWERS_PERCENT_CHANGE_30D_AGO.value
  },
  {
    name: CompanyColumnsConfig.INSTAGRAM_FOLLOWERS_90D_AGO.label,
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.INSTAGRAM_FOLLOWERS_90D_AGO.value
  },
  {
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.INSTAGRAM_FOLLOWERS_REAL_CHANGE_90D_AGO.label,
    id: CompanyColumnsConfig.INSTAGRAM_FOLLOWERS_REAL_CHANGE_90D_AGO.value
  },
  {
    id: CompanyColumnsConfig.INSTAGRAM_FOLLOWERS_PERCENT_CHANGE_90D_AGO.value,
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.INSTAGRAM_FOLLOWERS_PERCENT_CHANGE_90D_AGO.label
  },
  {
    name: CompanyColumnsConfig.INSTAGRAM_FOLLOWERS_180D_AGO.label,
    id: CompanyColumnsConfig.INSTAGRAM_FOLLOWERS_180D_AGO.value,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.INSTAGRAM_FOLLOWERS_REAL_CHANGE_180D_AGO.value,
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.INSTAGRAM_FOLLOWERS_REAL_CHANGE_180D_AGO.label
  },
  {
    name: CompanyColumnsConfig.INSTAGRAM_FOLLOWERS_PERCENT_CHANGE_180D_AGO
      .label,
    id: CompanyColumnsConfig.INSTAGRAM_FOLLOWERS_PERCENT_CHANGE_180D_AGO.value,
    isVisibleOnTable: false
  },
  {
    isVisibleOnTable: false,
    name: CompanyColumnsConfig.INSTAGRAM_FOLLOWERS_365D_AGO.label,
    id: CompanyColumnsConfig.INSTAGRAM_FOLLOWERS_365D_AGO.value
  },
  {
    name: CompanyColumnsConfig.INSTAGRAM_FOLLOWERS_REAL_CHANGE_365D_AGO.label,
    isVisibleOnTable: false,
    id: CompanyColumnsConfig.INSTAGRAM_FOLLOWERS_REAL_CHANGE_365D_AGO.value
  },
  {
    id: CompanyColumnsConfig.INSTAGRAM_FOLLOWERS_PERCENT_CHANGE_365D_AGO.value,
    name: CompanyColumnsConfig.INSTAGRAM_FOLLOWERS_PERCENT_CHANGE_365D_AGO
      .label,
    isVisibleOnTable: false
  },
  {
    id: CompanyColumnsConfig.MY_NOTES.value,
    name: CompanyColumnsConfig.MY_NOTES.label,
    isVisibleOnTable: true
  },
  {
    id: CompanyColumnsConfig.TEAM_NOTES.value,
    name: CompanyColumnsConfig.TEAM_NOTES.label,
    isVisibleOnTable: true
  }
].filter((column) => column.isVisibleOnTable);

export default DefaultCompanySearchColumns;
