import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const ChevronGrabberVertical: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
      fill="none"
      stroke="#677183"
    >
      <path
        d="M5.65625 5.99997L7.96936 3.68685C8.16463 3.49159 8.48121 3.49159 8.67647 3.68685L10.9896 5.99997M10.9896 9.99997L8.67647 12.3131C8.48121 12.5083 8.16463 12.5083 7.96936 12.3131L5.65625 9.99997"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default ChevronGrabberVertical;
