import classNames from 'classnames';
import { HarmonicIcon } from './type';

const CircleCheckIcon: HarmonicIcon = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      className={classNames(applyCurrentColor && 'stroke-current', className)}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="#E8EAEE"
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.0002 6.33334L7.00016 10L5.66683 8.66668M14.1668 8.00001C14.1668 11.4058 11.4059 14.1667 8.00016 14.1667C4.59441 14.1667 1.8335 11.4058 1.8335 8.00001C1.8335 4.59425 4.59441 1.83334 8.00016 1.83334C11.4059 1.83334 14.1668 4.59425 14.1668 8.00001Z"
      />
    </svg>
  );
};

export default CircleCheckIcon;
