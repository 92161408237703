import { Badge, Tooltip } from 'common/components';
import { BarChartData } from 'components/Charts/BarLineChart';
import dayjs from 'dayjs';
import Button from 'harmonic-components/Button/Button';
import { ILineChartDataPoint } from 'interfaces/Charts';
import { isNil } from 'lodash';
import { useState } from 'react';
import { CHART_DEFAULT_MINIMUM_START_DATE } from 'utils/chart';
import { getFundingStageBadgeColor } from 'utils/funding';
import { truncateMoneyValue } from 'utils/utilities';

export const formatBarDataPointsForLegends = (
  barChartDataPoints: BarChartData[],
  minimumDate: string
) => {
  const sortedBarChartDataPoints = [...barChartDataPoints].sort((a, b) => {
    const dateA = new Date(a.x);
    const dateB = new Date(b.x);

    if (dateA >= new Date(minimumDate) && dateB >= new Date(minimumDate)) {
      return dateA.getTime() - dateB.getTime();
    } else if (dateA >= new Date(minimumDate)) {
      return -1;
    } else if (dateB >= new Date(minimumDate)) {
      return 1;
    } else {
      return dateA.getTime() - dateB.getTime();
    }
  });

  const filteredBarChartDataPoints = sortedBarChartDataPoints.filter(
    (point) => {
      return !isNil(point.y) && point.y !== 0;
    }
  );
  const formattedPoints = filteredBarChartDataPoints.map((point) => {
    const isPointLessThanLimit = dayjs(point.x).isBefore(minimumDate);
    return {
      ...point,
      isPointLessThanLimit
    };
  });

  return formattedPoints;
};

interface LegendChipProps {
  label: string;
  date: string;
  amount: number;
  fundingTotal?: number;
  isPointLessThanLimit: boolean;
}

const LegendChip: React.FC<LegendChipProps> = ({
  label,
  date,
  amount,
  fundingTotal,
  isPointLessThanLimit
}) => {
  return (
    <Tooltip
      body={
        <>
          <p className="typography-label-small-strong">{label.toUpperCase()}</p>
          <p className="typography-label-small">
            Raised:{' '}
            <span className="font-semibold">
              {truncateMoneyValue(amount, 0)}
            </span>
          </p>

          <p className="typography-label-small">
            {dayjs(date).format('MM/DD/YYYY')}
          </p>
        </>
      }
    >
      <div className="typography-label-small cursor-default flex gap-g30 items-center">
        <Badge
          label={label.trimEnd()}
          color={
            isPointLessThanLimit
              ? 'neutral'
              : getFundingStageBadgeColor(label.trimEnd())
          }
          size="medium"
          intensity="highlight"
        />

        <span className="text-content-default typography-label">
          {truncateMoneyValue(amount, 0)}
        </span>
      </div>
    </Tooltip>
  );
};

interface CompanyTimelineGraphLegendsProps {
  barChartData: BarChartData[];
  lineChartData: ILineChartDataPoint[];
  fundingTotal?: number;
  minimumDate?: string;
}
const CompanyTimelineGraphLegends: React.FC<
  CompanyTimelineGraphLegendsProps
> = ({
  barChartData,
  fundingTotal,
  minimumDate = CHART_DEFAULT_MINIMUM_START_DATE
}) => {
  const [isSeeAllMode, setIsSeeAllMode] = useState(false);

  const formattedBarDataPoints = formatBarDataPointsForLegends(
    barChartData,
    minimumDate
  );

  const pointsWithinLimit = formattedBarDataPoints.filter(
    (point) => !point.isPointLessThanLimit
  );

  const pointsOutsideLimit = formattedBarDataPoints.filter(
    (point) => point.isPointLessThanLimit
  );
  const allPointsAreWithinLimit =
    pointsWithinLimit.length === formattedBarDataPoints.length;

  return (
    <div className="mt-4 flex gap-x-g80 ml-10 flex-wrap items-center">
      <div className="flex gap-x-g80 gap-y-g50 flex-wrap items-center">
        {pointsWithinLimit.map((point) => {
          return (
            <LegendChip
              key={point.x}
              label={point.label}
              amount={point.y}
              date={point.x}
              isPointLessThanLimit={point.isPointLessThanLimit}
              fundingTotal={fundingTotal}
            />
          );
        })}
      </div>

      {isSeeAllMode && (
        <div className="my-4 flex gap-x-g80 gap-y-g50 flex-wrap items-center w-full">
          {pointsOutsideLimit.map((point) => {
            return (
              <LegendChip
                key={point.x}
                label={point.label}
                amount={point.y}
                date={point.x}
                isPointLessThanLimit={point.isPointLessThanLimit}
                fundingTotal={fundingTotal}
              />
            );
          })}
        </div>
      )}
      {!allPointsAreWithinLimit && (
        <Button
          type="secondary"
          emphasis="low"
          onClick={() => setIsSeeAllMode(!isSeeAllMode)}
          label={isSeeAllMode ? 'Collapse' : 'See All'}
          size="compact"
        />
      )}
    </div>
  );
};

export default CompanyTimelineGraphLegends;
