import classNames from 'classnames';
import { HarmonicIcon } from './type';

const TagSaleIcon: HarmonicIcon = ({ applyCurrentColor = true, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#7C8598"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33334 2.50004C1.33334 1.85571 1.85567 1.33337 2.5 1.33337H7.71936C8.02878 1.33337 8.32552 1.45629 8.54431 1.67508L14.3822 7.51292C14.8378 7.96853 14.8378 8.70722 14.3822 9.16284L9.1628 14.3822C8.70719 14.8378 7.96849 14.8378 7.51288 14.3822L1.67504 8.54435C1.45625 8.32556 1.33334 8.02881 1.33334 7.71939V2.50004ZM5 6.00004C5.55229 6.00004 6 5.55233 6 5.00004C6 4.44776 5.55229 4.00004 5 4.00004C4.44772 4.00004 4 4.44776 4 5.00004C4 5.55233 4.44772 6.00004 5 6.00004Z"
      />
    </svg>
  );
};
export default TagSaleIcon;
