import classNames from 'classnames';
import { IconProps } from './type';

const TwoStarsIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="#225DD3"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M6.00033 11.3333C6.00033 11.6094 6.22418 11.8333 6.50033 11.8333C6.77647 11.8333 7.00033 11.6094 7.00033 11.3333C7.00033 9.71609 7.35758 8.66578 8.01186 8.0115C8.66615 7.35722 9.71645 6.99996 11.3337 6.99996C11.6098 6.99996 11.8337 6.7761 11.8337 6.49996C11.8337 6.22382 11.6098 5.99996 11.3337 5.99996C9.71645 5.99996 8.66615 5.6427 8.01186 4.98842C7.35758 4.33414 7.00033 3.28383 7.00033 1.66663C7.00033 1.39048 6.77647 1.16663 6.50033 1.16663C6.22418 1.16663 6.00033 1.39048 6.00033 1.66663C6.00033 3.28383 5.64307 4.33414 4.98879 4.98842C4.3345 5.6427 3.2842 5.99996 1.66699 5.99996C1.39085 5.99996 1.16699 6.22382 1.16699 6.49996C1.16699 6.7761 1.39085 6.99996 1.66699 6.99996C3.2842 6.99996 4.3345 7.35722 4.98879 8.0115C5.64307 8.66578 6.00033 9.71609 6.00033 11.3333Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M11.667 15.3333C11.667 15.6094 11.8908 15.8333 12.167 15.8333C12.4431 15.8333 12.667 15.6094 12.667 15.3333C12.667 14.2948 12.8969 13.6611 13.2792 13.2789C13.6615 12.8966 14.2952 12.6666 15.3337 12.6666C15.6098 12.6666 15.8337 12.4428 15.8337 12.1666C15.8337 11.8905 15.6098 11.6666 15.3337 11.6666C14.2952 11.6666 13.6615 11.4367 13.2792 11.0544C12.8969 10.6721 12.667 10.0385 12.667 8.99996C12.667 8.72382 12.4431 8.49996 12.167 8.49996C11.8908 8.49996 11.667 8.72382 11.667 8.99996C11.667 10.0385 11.437 10.6721 11.0548 11.0544C10.6725 11.4367 10.0388 11.6666 9.00033 11.6666C8.72418 11.6666 8.50033 11.8905 8.50033 12.1666C8.50033 12.4428 8.72418 12.6666 9.00033 12.6666C10.0388 12.6666 10.6725 12.8966 11.0548 13.2789C11.437 13.6611 11.667 14.2948 11.667 15.3333Z"
      />
    </svg>
  );
};

export default TwoStarsIcon;
