import classNames from 'classnames';
import { IconProps } from './type';

const URL: React.FC<IconProps> = ({ applyCurrentColor = true, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill=" #484E5B"
      fillRule="evenodd"
      clipRule="evenodd"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M8.00032 1.23328C8.33169 1.23331 8.6003 1.50196 8.60027 1.83333L8.60007 4.16666C8.60004 4.49803 8.33138 4.76664 8.00001 4.76661C7.66864 4.76658 7.40004 4.49793 7.40007 4.16656L7.40027 1.83322C7.4003 1.50185 7.66895 1.23325 8.00032 1.23328Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M3.21549 3.21501C3.44983 2.98071 3.82972 2.98075 4.06402 3.21508L5.71379 4.86514C5.94808 5.09948 5.94805 5.47937 5.71372 5.71367C5.47938 5.94796 5.09948 5.94793 4.86519 5.71359L3.21542 4.06353C2.98112 3.8292 2.98115 3.4493 3.21549 3.21501Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M12.7852 3.21552C13.0195 3.44983 13.0195 3.82973 12.7852 4.06404L11.1352 5.71396C10.9009 5.94828 10.521 5.94828 10.2867 5.71396C10.0524 5.47965 10.0524 5.09975 10.2867 4.86543L11.9366 3.21552C12.1709 2.9812 12.5508 2.9812 12.7852 3.21552Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M1.2334 8.00041C1.2334 7.66904 1.50203 7.40041 1.8334 7.40041H4.16673C4.4981 7.40041 4.76673 7.66904 4.76673 8.00041C4.76673 8.33178 4.4981 8.60041 4.16673 8.60041H1.8334C1.50203 8.60041 1.2334 8.33178 1.2334 8.00041Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M11.2334 8.00041C11.2334 7.66904 11.502 7.40041 11.8334 7.40041H14.1667C14.4981 7.40041 14.7667 7.66904 14.7667 8.00041C14.7667 8.33178 14.4981 8.60041 14.1667 8.60041H11.8334C11.502 8.60041 11.2334 8.33178 11.2334 8.00041Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.2865 10.2861C10.5208 10.0518 10.9007 10.0518 11.135 10.2861L12.785 11.936C13.0193 12.1703 13.0193 12.5502 12.785 12.7846C12.5507 13.0189 12.1708 13.0189 11.9364 12.7846L10.2865 11.1346C10.0522 10.9003 10.0522 10.5204 10.2865 10.2861Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M5.71408 10.2866C5.9484 10.5209 5.9484 10.9008 5.71408 11.1351L4.06417 12.785C3.82985 13.0193 3.44995 13.0193 3.21564 12.785C2.98132 12.5507 2.98132 12.1708 3.21564 11.9365L4.86555 10.2866C5.09987 10.0523 5.47977 10.0523 5.71408 10.2866Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M8.00027 11.2333C8.33164 11.2333 8.60027 11.5019 8.60027 11.8333V14.1666C8.60027 14.498 8.33164 14.7666 8.00027 14.7666C7.6689 14.7666 7.40027 14.498 7.40027 14.1666V11.8333C7.40027 11.5019 7.6689 11.2333 8.00027 11.2333Z"
      />
    </svg>
  );
};

export default URL;
