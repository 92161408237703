import { gql } from '@apollo/client';
import { FullPeopleSearch } from './peopleSearchFragment';

export const PEOPLE_SEARCH_QUERY = gql`
  query SearchPeople(
    $query: EntitySearchQuery!
    $skipExtended: Boolean = false
    $first: Int
    $after: String
  ) {
    searchPeople(query: $query, first: $first, after: $after) {
      edges {
        cursor
        node {
          ... on Person {
            ...PeopleSearch
          }
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
    }
  }
  ${FullPeopleSearch}
`;
