import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const CalendarCheckIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
      fill="none"
      stroke="#7C8598"
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M5.16667 3.16671V1.83337M10.8333 3.16671V1.83337M6.16667 8.51671L7.26667 9.61671L9.83333 7.05005"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M3.16667 13.5H12.8333C13.2015 13.5 13.5 13.2016 13.5 12.8334V3.83337C13.5 3.46518 13.2015 3.16671 12.8333 3.16671H3.16667C2.79848 3.16671 2.5 3.46518 2.5 3.83337V12.8334C2.5 13.2016 2.79848 13.5 3.16667 13.5Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CalendarCheckIcon;
