import { GetCompanyEmployees_EmployeeEdges_PersonFragment } from '__generated__/graphql';
import Button from 'harmonic-components/Button/Button';
import { FunctionComponent, useEffect, useRef } from 'react';
import { ScaleLoader } from 'react-spinners';
import Employee, { EmployeeProps } from './Employee';

interface EmployeesListProps {
  totalCount: number;
  employees: GetCompanyEmployees_EmployeeEdges_PersonFragment[];
  onLoadMore: () => void;
  loading: boolean;
  infiniteScroll?: boolean;
  currentCompanyId: number;
  disableLoadMore?: boolean;
  employeeCard?: FunctionComponent<EmployeeProps>;
}

interface LoadMoreProps {
  onClick: () => void;
}

const LoadMore: React.FC<LoadMoreProps> = ({ onClick }) => {
  return (
    <Button
      label="Load more"
      onClick={onClick}
      type="secondary"
      emphasis="low"
    />
  );
};

const EmployeesListContentLoader: React.FC = () => {
  const rows = [...Array(8)];
  return (
    <div
      data-testid="EmployeesListContentLoader"
      className="grid grid-cols-2 gap-4"
    >
      {rows.map((_, index) => {
        return (
          <div
            key={index}
            className="flex w-full animated-box-pulse h-24 py-1.5"
          ></div>
        );
      })}
    </div>
  );
};

const EmployeesList: React.FC<EmployeesListProps> = ({
  totalCount,
  employees,
  onLoadMore,
  loading,
  infiniteScroll = false,
  currentCompanyId,
  disableLoadMore,
  employeeCard = Employee
}) => {
  const sentinelRef = useRef<HTMLDivElement>(null);
  const showLoadMore = totalCount > employees.length && !disableLoadMore;
  const showContentLoader = loading && employees.length === 0;
  const isLoadingMore = loading && employees.length > 0;

  // The following useEffect will trigger onLoadMore when the bottom of the page is reached and infiniteScroll is true
  useEffect(() => {
    if ('IntersectionObserver' in window) {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && !disableLoadMore) {
            onLoadMore();
          }
        },
        {
          threshold: 1.0 // Once 100% of the sentinel is visible, the callback is invoked
        }
      );
      if (sentinelRef.current && infiniteScroll) {
        observer.observe(sentinelRef.current);
      }
      return () => {
        observer.disconnect();
      };
    }
  }, [infiniteScroll, onLoadMore, disableLoadMore]);

  if (showContentLoader) {
    return <EmployeesListContentLoader />;
  }

  const EmployeeCard = employeeCard;

  return (
    <div data-testid="EmployeesList">
      <div className="grid sm:grid-cols-2 gap-4 items-start">
        {employees.map((employee) => (
          <EmployeeCard
            key={`employee-${employee.id}`}
            employee={employee}
            currentCompanyId={currentCompanyId}
          />
        ))}
      </div>
      <div className="flex justify-center items-center mt-4">
        {showLoadMore && <LoadMore onClick={onLoadMore} />}
        {isLoadingMore && <ScaleLoader color="#666" width={2} height={20} />}
      </div>
    </div>
  );
};

export default EmployeesList;
