import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const ClockIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
      fill="none"
      stroke="#1A1D23"
    >
      <path
        d="M4.50016 7.16666H2.50016C2.13197 7.16666 1.8335 7.46514 1.8335 7.83333V12.8333C1.8335 13.2015 2.13197 13.5 2.50016 13.5H4.50016M4.50016 13.5V7.33333L7.14734 2.19469C7.26166 1.97278 7.49118 1.83333 7.7408 1.83333C8.55907 1.83333 9.18738 2.56356 9.06133 3.37206L8.67758 5.83333H12.1728C13.3972 5.83333 14.3342 6.9237 14.15 8.13421L13.5921 11.8009C13.4434 12.778 12.6032 13.5 11.6148 13.5H4.50016Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default ClockIcon;
