import { Popover } from '@material-ui/core';
import classNames from 'classnames';
import { useIntersectionObserver } from 'hooks/useIntersectionObserver';
import { useIsClamped } from 'hooks/useIsClamped';
import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useRef,
  useState
} from 'react';
import useFlags from '../../hooks/useFlags';
import { SPLITS } from '../../utils/constants';
import { TruncatedListV2 } from './TruncatedListV2';

const LineclampButton: React.FC<LineclampButtonProps> = ({
  numClamped,
  handleExpand,
  className
}) => {
  return (
    <div className={className}>
      <button
        onClick={handleExpand}
        className={classNames(
          'w-min whitespace-nowrap rounded-br20 flex gap-g10 items-center justify-center',
          'py-p10 px-p30 typography-label-default-default',
          'cursor-pointer',
          'text-content-sentiment-neutral bg-surface-sentiment-neutral'
        )}
      >
        <p className="line-clamp-1">{`+${numClamped}`}</p>
      </button>
    </div>
  );
};

export interface LineclampButtonProps {
  numClamped: number;
  handleExpand: (e: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
}

interface TruncatedListProps {
  height: number;
  divider?: JSX.Element;
  lineclampButton?: (props: LineclampButtonProps) => React.ReactElement;
  wrapperClassName?: string;
  contentClassName?: string;
}
const TruncatedList: FC<PropsWithChildren<TruncatedListProps>> = ({
  children,
  height,
  divider,
  lineclampButton = (props) => <LineclampButton {...props} />,
  wrapperClassName,
  contentClassName
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const lastVisibleChipRef = useRef<HTMLDivElement>(null);
  const DividerElement = divider;
  const { enabled: enabledTruncatedListV2 } = useFlags(
    SPLITS.enableTruncatedListV2
  );

  const [numClamped, setNumClamped] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const { visibilityMap } = useIntersectionObserver({ contentRef });
  const { isClamped } = useIsClamped({ contentRef });

  const handleExpand = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    setAnchorEl(event.currentTarget);
    setExpanded(true);
  };
  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    setExpanded(false);
    setAnchorEl(null);
  };

  // Update the number of clamped chips and the last visible chip whenever the visibility map updates
  useEffect(() => {
    const newNumClamped = Object.keys(visibilityMap).reduce(
      (acc: number, key: string) => {
        if (!visibilityMap[key]) {
          return (acc += 1);
        } else {
          return (acc += 0);
        }
      },
      0
    );
    setNumClamped(newNumClamped);
  }, [visibilityMap]);

  const childrenArray = React.Children.toArray(children);

  return (
    <div
      className={classNames('flex flex-row justify-start', wrapperClassName)}
    >
      {enabledTruncatedListV2 ? (
        <TruncatedListV2
          className="flex items-start flex-wrap gap-g30"
          renderTruncator={({ hiddenItemsCount }) => {
            return lineclampButton({
              numClamped: hiddenItemsCount,
              handleExpand
            });
          }}
          style={{ height: height + 4 }}
        >
          {childrenArray.map((child, index) => {
            return (
              <div
                data-targetid={`truncated-list-item-${index}`}
                key={`truncated-list-item-${index}`}
              >
                {child}
              </div>
            );
          })}
        </TruncatedListV2>
      ) : (
        <>
          <div
            ref={contentRef}
            className={classNames(
              `overflow-hidden flex items-start flex-wrap gap-g30`,
              contentClassName
            )}
            style={{ height }}
          >
            {childrenArray.map((child, index) => {
              const isLastVisibleChip =
                childrenArray.length - index - 1 == numClamped;
              return (
                <div
                  ref={isLastVisibleChip ? lastVisibleChipRef : null}
                  data-targetid={`truncated-list-item-${index}`}
                  key={`truncated-list-item-${index}`}
                  className="flex gap-g30"
                >
                  {child}
                  {DividerElement &&
                    !isLastVisibleChip &&
                    index < childrenArray.length - 1 &&
                    DividerElement}
                </div>
              );
            })}
          </div>
          {isClamped &&
            numClamped > 0 &&
            lineclampButton({
              numClamped,
              handleExpand,
              className:
                'h-[26px] w-[55px] flex shrink-0 flex-col justify-end items-end'
            })}
        </>
      )}
      <Popover
        open={expanded}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        className="ml-p30 overflow-hidden bg-none rounded-br40 visible-scrollbar"
        PaperProps={{
          style: {
            borderRadius: 6
          }
        }}
      >
        <div
          data-testid="TruncatedListPopover"
          className="p-p50 max-h-96 overflow-y-scroll flex flex-col gap-y-g40 whitespace-nowrap bg-surface-default shadow-static-elevation-floating border-[1.5px] border-solid border-border rounded-br30"
        >
          {children}
        </div>
      </Popover>
    </div>
  );
};

export default TruncatedList;
