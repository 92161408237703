import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const EyeOpenIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#E8EAEE"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M1.48736 7.7015L0.825709 7.34834H0.825709L1.48736 7.7015ZM14.5125 7.70157L15.1741 7.34841V7.34841L14.5125 7.70157ZM1.48736 8.29841L0.825709 8.65157H0.825709L1.48736 8.29841ZM14.5125 8.29849L15.1741 8.65165V8.65165L14.5125 8.29849ZM2.149 8.05466C3.66284 5.21849 5.8867 3.91667 7.99991 3.91669C10.1131 3.9167 12.337 5.21856 13.8508 8.05474L15.1741 7.34841C13.4606 4.13812 10.7856 2.41671 7.99992 2.41669C5.21425 2.41667 2.53924 4.13804 0.825709 7.34834L2.149 8.05466ZM0.825709 8.65157C2.53924 11.8619 5.21424 13.5833 7.99991 13.5833C10.7856 13.5833 13.4606 11.8619 15.1741 8.65165L13.8508 7.94533C12.337 10.7815 10.1131 12.0833 7.99992 12.0833C5.88671 12.0833 3.66284 10.7814 2.149 7.94525L0.825709 8.65157ZM0.825709 7.34834C0.609204 7.75396 0.609204 8.24595 0.825709 8.65157L2.149 7.94525C2.15962 7.96514 2.16333 7.98399 2.16333 7.99996C2.16333 8.01592 2.15962 8.03477 2.149 8.05466L0.825709 7.34834ZM13.8508 8.05473C13.8402 8.03485 13.8365 8.016 13.8365 8.00003C13.8365 7.98407 13.8402 7.96522 13.8508 7.94533L15.1741 8.65165C15.3906 8.24603 15.3906 7.75404 15.1741 7.34841L13.8508 8.05473ZM9.41658 8.00002C9.41658 8.78242 8.78232 9.41669 7.99992 9.41669V10.9167C9.61075 10.9167 10.9166 9.61085 10.9166 8.00002H9.41658ZM7.99992 9.41669C7.21751 9.41669 6.58325 8.78242 6.58325 8.00002H5.08325C5.08325 9.61085 6.38908 10.9167 7.99992 10.9167V9.41669ZM6.58325 8.00002C6.58325 7.21762 7.21751 6.58335 7.99992 6.58335V5.08335C6.38908 5.08335 5.08325 6.38919 5.08325 8.00002H6.58325ZM7.99992 6.58335C8.78232 6.58335 9.41658 7.21762 9.41658 8.00002H10.9166C10.9166 6.38919 9.61075 5.08335 7.99992 5.08335V6.58335Z"
      />
    </svg>
  );
};
export default EyeOpenIcon;
