import classNames from 'classnames';
import Avatar from 'harmonic-components/Avatar/Avatar';
import usePermissions, { Permission } from 'hooks/usePermissions';
import useStore from 'stores/zustandStore';
interface PersonAttributeProps {
  src: string;
  name: string;
  href?: string;
  disabled?: boolean;
}
const PersonAttribute: React.FC<PersonAttributeProps> = ({
  src,
  href,
  name,
  disabled = false
}) => {
  const displayUpgradeModal = useStore((state) => state.displayUpgradeModal);

  const { userHasPermission } = usePermissions();

  const classes = classNames(
    'flex items-start gap-g30 px-p20 py-p10 rounded-br30',
    'aria-disabled:bg-int-overlay-secondary-disabled aria-disabled:cursor-default',
    href && 'cursor-pointer hover:bg-int-overlay-secondary-hover',
    !href && 'cursor-default'
  );

  if (!userHasPermission(Permission.PersonProfile))
    return (
      <>
        <button
          onClick={() => {
            displayUpgradeModal(Permission.PersonProfile);
          }}
          className={classes}
        >
          <Avatar
            size="medium"
            kind="face"
            src={src}
            alt="person-profile-picture"
          />
          <p className="text-content-strong typography-label-default-default line-clamp-1">
            {name}
          </p>
        </button>
      </>
    );

  return (
    <a
      className={classes}
      aria-disabled={disabled}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Avatar
        size="medium"
        kind="face"
        src={src}
        alt="person-profile-picture"
      />
      <p className="text-content-strong typography-label-default-default line-clamp-1">
        {name}
      </p>
    </a>
  );
};

export default PersonAttribute;
