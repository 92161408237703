import classNames from 'classnames';
import { IconProps } from './type';

const ArrowRightWallIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      stroke="#98A0AE"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.375 3.875L9.23483 5.73483C9.38128 5.88127 9.38128 6.11871 9.23484 6.26516L7.375 8.125M1.875 5.99999H9.125M10.125 2.375V9.625"
        vectorEffect="non-scaling-stroke"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowRightWallIcon;
