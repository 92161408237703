import classNames from 'classnames';
import { IconProps } from './type';

const BellFilledIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      className={classNames(applyCurrentColor && 'fill-current', className)}
      width="16"
      height="16"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        vectorEffect="non-scaling-stroke"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C8.13655 2 4.94782 5.0198 4.75447 8.87114L4.55873 12.7464C4.55693 12.7819 4.54756 12.8167 4.53125 12.8483L3.19459 15.4406C3.06622 15.6896 3 15.9661 3 16.2441C3 17.2048 3.77775 18 4.75 18H7.30888C7.66846 20.267 9.63185 22 12 22C14.3682 22 16.3315 20.267 16.6911 18H19.25C20.2223 18 21 17.2048 21 16.2441C21 15.9661 20.9338 15.6896 20.8054 15.4406L19.4688 12.8483C19.4524 12.8167 19.4431 12.7819 19.4413 12.7464L19.2455 8.87139C19.0522 5.02005 15.8634 2 12 2ZM12 20.5C10.4632 20.5 9.17555 19.4333 8.83697 18H15.163C14.8245 19.4333 13.5368 20.5 12 20.5Z"
      />
    </svg>
  );
};

export default BellFilledIcon;
