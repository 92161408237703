import classNames from 'classnames';
import { IconProps } from './type';

const ArrowTopBottomIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke="#677183"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M2.16699 5L4.48011 2.68689C4.67537 2.49162 4.99195 2.49162 5.18721 2.68689L7.50033 5M8.50033 11L10.8134 13.3131C11.0087 13.5084 11.3253 13.5084 11.5205 13.3131L13.8337 11M4.83366 3.33333V13.5M11.167 2.5V12.8333"
      />
    </svg>
  );
};

export default ArrowTopBottomIcon;
