import { useQuery } from '@apollo/client';
import { Tooltip } from '@material-ui/core';
import {
  GetCompanyMetricsQuery,
  GetCompanyMetricsQueryVariables
} from '__generated__/graphql';
import { ReactComponent as HeadcountIcon } from 'assets/socials/bxl-headcount.svg';
import MetricCard from 'components/common/CompanyCard/MetricCard';
import PercentChangeBadge from 'components/common/TractionMetrics/PercentChangeBadge';
import { isNil } from 'lodash';
import { getCompanyMetrics } from 'queries/getCompanyMetrics';
import { addCommasToNumber } from 'utils/utilities';

interface HeadcountChangeProps {
  companyId: number;
}
const HeadcountChange: React.FC<HeadcountChangeProps> = ({ companyId }) => {
  const { data } = useQuery<
    GetCompanyMetricsQuery,
    GetCompanyMetricsQueryVariables
  >(getCompanyMetrics, {
    variables: { id: companyId },
    fetchPolicy: 'cache-only'
  });
  const tractionData = data?.getCompanyById;
  const currentHeadcount = tractionData?.headcount;
  const headcountTractionMetrics =
    tractionData?.tractionMetrics?.headcount?.ago90d;
  const bothPercentAndValueExist =
    !isNil(headcountTractionMetrics?.percentChange) &&
    !isNil(headcountTractionMetrics?.value);

  return (
    <MetricCard
      dataTestId="CompanySocialCard"
      value={
        !isNil(currentHeadcount)
          ? addCommasToNumber((currentHeadcount as number)?.toFixed())
          : 'Unknown'
      }
      label="Headcount"
      icon={HeadcountIcon}
      titleSuffixComponent={
        bothPercentAndValueExist && (
          <Tooltip classes={{ tooltip: 'text-xs' }} title={<span>Δ90d</span>}>
            <div data-testid="CompanySocialCardPercentGrowth">
              <PercentChangeBadge tractionMetrics={headcountTractionMetrics} />
            </div>
          </Tooltip>
        )
      }
    />
  );
};

export default HeadcountChange;
