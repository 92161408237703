import classNames from 'classnames';
import { IconProps } from './type';

const ArrowUndoDownIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      stroke="#677183"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.24996 9.5L1.42674 7.67678C1.32911 7.57915 1.32911 7.42085 1.42674 7.32322L3.24996 5.5M1.99996 7.5H9.62496C10.1772 7.5 10.625 7.05228 10.625 6.5V3.875C10.625 3.32272 10.1772 2.875 9.62496 2.875H5.87496"
        vectorEffect="non-scaling-stroke"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowUndoDownIcon;
