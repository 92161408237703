import classNames from 'classnames';
import { HarmonicIcon } from './type';

const CalendarDaysIcon: HarmonicIcon = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      fill="#7C8598"
    >
      <path
        vectorEffect="non-scaling-stroke"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.16667 2C2.52233 2 2 2.52233 2 3.16667V12.8333C2 13.4777 2.52233 14 3.16667 14H12.8333C13.4777 14 14 13.4777 14 12.8333V3.16667C14 2.52233 13.4777 2 12.8333 2H3.16667ZM5.33333 7.33333C4.96514 7.33333 4.66667 7.63181 4.66667 8C4.66667 8.36819 4.96514 8.66667 5.33333 8.66667C5.70152 8.66667 6 8.36819 6 8C6 7.63181 5.70152 7.33333 5.33333 7.33333ZM5.33333 10C4.96514 10 4.66667 10.2985 4.66667 10.6667C4.66667 11.0349 4.96514 11.3333 5.33333 11.3333C5.70152 11.3333 6 11.0349 6 10.6667C6 10.2985 5.70152 10 5.33333 10ZM7.33333 10.6667C7.33333 10.2985 7.63181 10 8 10C8.36819 10 8.66667 10.2985 8.66667 10.6667C8.66667 11.0349 8.36819 11.3333 8 11.3333C7.63181 11.3333 7.33333 11.0349 7.33333 10.6667ZM8 7.33333C7.63181 7.33333 7.33333 7.63181 7.33333 8C7.33333 8.36819 7.63181 8.66667 8 8.66667C8.36819 8.66667 8.66667 8.36819 8.66667 8C8.66667 7.63181 8.36819 7.33333 8 7.33333ZM10 8C10 7.63181 10.2985 7.33333 10.6667 7.33333C11.0349 7.33333 11.3333 7.63181 11.3333 8C11.3333 8.36819 11.0349 8.66667 10.6667 8.66667C10.2985 8.66667 10 8.36819 10 8ZM3.16667 3C3.07462 3 3 3.07462 3 3.16667V4.66667H13V3.16667C13 3.07462 12.9254 3 12.8333 3H3.16667Z"
      />
    </svg>
  );
};

export default CalendarDaysIcon;
