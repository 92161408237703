import { IconProps } from './type';

const EditListIcon: React.FC<IconProps> = ({
  applyCurrentColor = false,
  className
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#7C8598"
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M2.5013 2.5H13.5013M2.5013 5.16667H6.83464M2.5 7.83333H4.8348M12.4351 6.10948L13.2252 6.89953C13.7459 7.42023 13.7459 8.26445 13.2252 8.78515L7.84364 14.1667H5.16797V11.491L10.5495 6.10948C11.0702 5.58878 11.9144 5.58878 12.4351 6.10948Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditListIcon;
