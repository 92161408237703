import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const Lists: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#484E5B"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.71799 2.59202C1.5 3.01984 1.5 3.5799 1.5 4.7V11.3C1.5 12.4201 1.5 12.9802 1.71799 13.408C1.90973 13.7843 2.21569 14.0903 2.59202 14.282C3.01984 14.5 3.5799 14.5 4.7 14.5H11.3C12.4201 14.5 12.9802 14.5 13.408 14.282C13.7843 14.0903 14.0903 13.7843 14.282 13.408C14.5 12.9802 14.5 12.4201 14.5 11.3V4.7C14.5 3.5799 14.5 3.01984 14.282 2.59202C14.0903 2.21569 13.7843 1.90973 13.408 1.71799C12.9802 1.5 12.4201 1.5 11.3 1.5H4.7C3.5799 1.5 3.01984 1.5 2.59202 1.71799C2.21569 1.90973 1.90973 2.21569 1.71799 2.59202ZM4 3.25C3.58579 3.25 3.25 3.58579 3.25 4C3.25 4.41421 3.58579 4.75 4 4.75H12C12.4142 4.75 12.75 4.41421 12.75 4C12.75 3.58579 12.4142 3.25 12 3.25H4ZM3.25 7C3.25 6.58579 3.58579 6.25 4 6.25H12C12.4142 6.25 12.75 6.58579 12.75 7C12.75 7.41421 12.4142 7.75 12 7.75H4C3.58579 7.75 3.25 7.41421 3.25 7ZM4 9.25C3.58579 9.25 3.25 9.58579 3.25 10C3.25 10.4142 3.58579 10.75 4 10.75H7C7.41421 10.75 7.75 10.4142 7.75 10C7.75 9.58579 7.41421 9.25 7 9.25H4Z"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default Lists;
