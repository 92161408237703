import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const SquareArrowTopRightIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke="#677183"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M12.1667 9.33333V11.3667C12.1667 12.1134 12.1667 12.4868 12.0213 12.772C11.8935 13.0229 11.6895 13.2268 11.4387 13.3547C11.1534 13.5 10.7801 13.5 10.0333 13.5H4.63333C3.8866 13.5 3.51323 13.5 3.22801 13.3547C2.97713 13.2268 2.77316 13.0229 2.64532 12.772C2.5 12.4868 2.5 12.1134 2.5 11.3667V5.91667C2.5 5.21706 2.5 4.86726 2.62801 4.59659C2.7599 4.31774 2.9844 4.09324 3.26326 3.96135C3.53392 3.83333 3.88373 3.83333 4.58333 3.83333H6.16667M9.16667 2.5H13.5M13.5 2.5V6.83333M13.5 2.5L7.33333 8.66667"
      />
    </svg>
  );
};

export default SquareArrowTopRightIcon;
