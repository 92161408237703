import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const RelevanceScoreIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
      fill="#186227"
    >
      <path
        vectorEffect="non-scaling-stroke"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.82227 1.25C5.09434 1.25 2.07227 4.27208 2.07227 8C2.07227 11.7279 5.09434 14.75 8.82227 14.75C12.5502 14.75 15.5723 11.7279 15.5723 8C15.5723 4.27208 12.5502 1.25 8.82227 1.25ZM3.57227 8C3.57227 5.10051 5.92277 2.75 8.82227 2.75C11.7218 2.75 14.0723 5.10051 14.0723 8C14.0723 10.8995 11.7218 13.25 8.82227 13.25C5.92277 13.25 3.57227 10.8995 3.57227 8ZM8.82227 4.25C6.7512 4.25 5.07227 5.92893 5.07227 8C5.07227 10.0711 6.7512 11.75 8.82227 11.75C10.8933 11.75 12.5723 10.0711 12.5723 8C12.5723 5.92893 10.8933 4.25 8.82227 4.25ZM6.57227 8C6.57227 6.75736 7.57963 5.75 8.82227 5.75C10.0649 5.75 11.0723 6.75736 11.0723 8C11.0723 9.24264 10.0649 10.25 8.82227 10.25C7.57963 10.25 6.57227 9.24264 6.57227 8Z"
      />
    </svg>
  );
};

export default RelevanceScoreIcon;
