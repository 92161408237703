import classNames from 'classnames';
import { IconProps } from './type';

const GraduationCapIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#493AA6"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M3.33317 9.61294C3.33317 9.23912 3.7284 8.9975 4.06111 9.16791L7.31602 10.8351C7.74538 11.055 8.2543 11.055 8.68365 10.8351L11.9386 9.16791C12.2713 8.9975 12.6665 9.23912 12.6665 9.61294V10.9371C12.6665 11.3645 12.4328 11.7577 12.0573 11.9619L8.55735 13.8659C8.20973 14.055 7.78995 14.055 7.44233 13.8659L3.94233 11.9619C3.56688 11.7577 3.33317 11.3645 3.33317 10.9371V9.61294Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M7.7719 9.94502C7.91502 10.0183 8.08466 10.0183 8.22777 9.94502L13.6052 7.19071C13.9379 7.0203 14.3332 7.26192 14.3332 7.63574V10.1667C14.3332 10.4428 14.557 10.6667 14.8332 10.6667C15.1093 10.6667 15.3332 10.4428 15.3332 10.1667V6C15.3332 5.81236 15.2281 5.64052 15.0611 5.55498L8.22777 2.05498C8.08466 1.98167 7.91502 1.98167 7.7719 2.05498L0.938566 5.55498C0.77156 5.64052 0.666504 5.81236 0.666504 6C0.666504 6.18764 0.77156 6.35948 0.938566 6.44502L7.7719 9.94502Z"
      />
    </svg>
  );
};

export default GraduationCapIcon;
