import { ReactComponent as AccelLogo } from 'assets/customers/logo-accel.svg';
import { ReactComponent as BrexLogo } from 'assets/customers/logo-brex.svg';
import { ReactComponent as NEALogo } from 'assets/customers/logo-nea.svg';
import { ReactComponent as NotionLogo } from 'assets/customers/logo-notion.svg';
import * as React from 'react';

const TrustedByCustomerLogos: React.FC = () => {
  return (
    <div className="w-full">
      <p className="text-center text-content-weak typography-label-default-strong leading-6">
        Trusted by Industry-leading Teams
      </p>
      <div className="flex items-center flex-wrap justify-center  gap-4">
        <NotionLogo className="w-22 h-auto" title="Notion" />
        <NEALogo className="w-16 h-auto" title="New enterprise associates" />
        <AccelLogo className="w-16 h-auto" title="Accel" />
        <BrexLogo className="w-20 h-auto" title="Brex" />
      </div>
    </div>
  );
};

export default TrustedByCustomerLogos;
