import { CalendarIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import { formatFoundingDate } from './utils';

const FoundingDate: React.FC<{
  date: string;
}> = ({ date }) => {
  return (
    <div className="flex flex-row items-center gap-g20">
      <CalendarIcon className="text-content-default" />
      <p className={classNames('typography-label text-content-default')}>
        {formatFoundingDate(date)}
      </p>
    </div>
  );
};

export default FoundingDate;
