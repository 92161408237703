import { useQuery } from '@apollo/client';
import {
  EmployeeGroupType,
  GetCompanyEmployeesQuery,
  GetCompanyEmployeesQueryVariables,
  GetCompanyEmployees_EmployeeEdgesFragment,
  GetCompanyEmployees_EmployeeEdges_PersonFragment
} from '__generated__/graphql';
import { Badge } from 'common/components';
import ReportDataIssue from 'components/common/ReportDataIssue';
import {
  ReportSourceSection,
  ReportSourceView
} from 'interfaces/UserReportedDataIssue';
import { get } from 'lodash';
import { getCompanyEmployees } from 'queries/getCompanyEmployees';
import React, { useEffect } from 'react';
import { EMPLOYEE_LIST_PAGE_SIZE } from 'utils/constants';
import EmployeesList from './EmployeesList';

interface CompanyEmployeesProps {
  companyId: number;
}

const CompanyEmployees: React.FC<CompanyEmployeesProps> = ({ companyId }) => {
  const [page, setPage] = React.useState(0);
  const [employees, setEmployees] = React.useState<
    GetCompanyEmployees_EmployeeEdges_PersonFragment[]
  >([]);

  const { data, loading } = useQuery<
    GetCompanyEmployeesQuery,
    GetCompanyEmployeesQueryVariables
  >(getCompanyEmployees, {
    variables: {
      companyUrn: `urn:harmonic:company:${companyId}`,
      page: page * EMPLOYEE_LIST_PAGE_SIZE,
      size: EMPLOYEE_LIST_PAGE_SIZE,
      employeeGroupType: EmployeeGroupType.NON_LEADERSHIP,
      userConnectionStatus: null
    }
  });

  const totalCount = get(data, 'getEmployeesByCompanyId.totalCount', 0);

  useEffect(() => {
    if (data) {
      const newEmployeesEdges = get(
        data,
        'getEmployeesByCompanyId.edges',
        []
      ) as GetCompanyEmployees_EmployeeEdgesFragment[];
      const newEmployees = newEmployeesEdges.map(
        (edge) => edge.node
      ) as GetCompanyEmployees_EmployeeEdges_PersonFragment[];
      setEmployees([...employees, ...newEmployees]);
    }
  }, [data]);

  if (!loading && totalCount === 0) return null;

  return (
    <>
      <div className="flex justify-between items-center py-p60 border-b border-solid border-border">
        <div className="flex gap-g40 items-center">
          <h1 className="text-content-title typography-title-small">
            Employees
          </h1>
          <Badge label={totalCount?.toString()} color="neutral" size="large" />
        </div>

        <ReportDataIssue
          reportParams={{
            companyUrn: 'urn:company:harmonic:' + companyId,
            reportSourceView: ReportSourceView.COMPANY,
            reportSourceSection: ReportSourceSection.FINANCING
          }}
          placeholderText="The company is showing incorrect employees"
        />
      </div>
      <div className="py-p80">
        <EmployeesList
          employees={employees}
          totalCount={totalCount}
          onLoadMore={() => setPage(page + 1)}
          loading={loading}
          infiniteScroll={true}
          currentCompanyId={companyId}
        />
      </div>
    </>
  );
};

export default CompanyEmployees;
