import React from 'react';
import { IconProps } from './type';

const ColumnAddIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        vectorEffect="non-scaling-stroke"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.23901 3.35547H7.00065H1.83398C0.867483 3.35547 0.0839844 4.13898 0.0839844 5.10547V12.7721C0.0839844 13.7386 0.867493 14.5221 1.83398 14.5221H7.00065H12.1673C13.1338 14.5221 13.9173 13.7386 13.9173 12.7721V8.02991C13.6752 8.07567 13.4253 8.09961 13.1699 8.09961C12.9127 8.09961 12.6611 8.07532 12.4173 8.02891V12.7721C12.4173 12.9102 12.3054 13.0221 12.1673 13.0221H7.75065V4.85547H9.24124C9.19443 4.6107 9.16992 4.35802 9.16992 4.09961C9.16992 3.84532 9.19365 3.59658 9.23901 3.35547ZM1.58398 5.10547C1.58398 4.9674 1.69592 4.85547 1.83398 4.85547H6.25065V13.0221H1.83398C1.69591 13.0221 1.58398 12.9102 1.58398 12.7721V5.10547Z"
        fill="#677183"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M13.917 2C13.917 1.58579 13.5812 1.25 13.167 1.25C12.7528 1.25 12.417 1.58579 12.417 2H13.917ZM12.417 6C12.417 6.41421 12.7528 6.75 13.167 6.75C13.5812 6.75 13.917 6.41421 13.917 6H12.417ZM11.167 3.25C10.7528 3.25 10.417 3.58579 10.417 4C10.417 4.41421 10.7528 4.75 11.167 4.75V3.25ZM15.167 4.75C15.5812 4.75 15.917 4.41421 15.917 4C15.917 3.58579 15.5812 3.25 15.167 3.25V4.75ZM12.417 2V4H13.917V2H12.417ZM12.417 4V6H13.917V4H12.417ZM11.167 4.75H13.167V3.25H11.167V4.75ZM13.167 4.75H15.167V3.25H13.167V4.75Z"
        fill="#677183"
      />
    </svg>
  );
};
export default ColumnAddIcon;
