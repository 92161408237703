import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const SettingsSliderThreeIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      className={classNames(applyCurrentColor && 'fill-current', className)}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#1A1D23"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        vectorEffect="non-scaling-stroke"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.80147 1.74805C4.21569 1.74805 4.55147 2.08383 4.55147 2.49805V6.83332C4.55147 7.24754 4.21569 7.58332 3.80147 7.58332C3.38726 7.58332 3.05147 7.24754 3.05147 6.83332V2.49805C3.05147 2.08383 3.38726 1.74805 3.80147 1.74805ZM12.1996 1.74805C12.6138 1.74805 12.9496 2.08383 12.9496 2.49805V8.16721C12.9496 8.58143 12.6138 8.91721 12.1996 8.91721C11.7854 8.91721 11.4496 8.58143 11.4496 8.16721V2.49805C11.4496 2.08383 11.7854 1.74805 12.1996 1.74805ZM8.00055 1.91471C8.41476 1.91471 8.75055 2.2505 8.75055 2.66471V5.41602H9.33386C9.74808 5.41602 10.0839 5.7518 10.0839 6.16602C10.0839 6.58023 9.74808 6.91602 9.33386 6.91602H6.6672C6.25298 6.91602 5.9172 6.58023 5.9172 6.16602C5.9172 5.7518 6.25298 5.41602 6.6672 5.41602H7.25055V2.66471C7.25055 2.2505 7.58633 1.91471 8.00055 1.91471ZM8.00053 7.75033C8.41474 7.75033 8.75053 8.08611 8.75053 8.50033V13.3359C8.75053 13.7501 8.41474 14.0859 8.00053 14.0859C7.58632 14.0859 7.25053 13.7501 7.25053 13.3359V8.50033C7.25053 8.08611 7.58632 7.75033 8.00053 7.75033ZM1.74805 9.16764C1.74805 8.75343 2.08383 8.41764 2.49805 8.41764H5.1672C5.58141 8.41764 5.9172 8.75343 5.9172 9.16764C5.9172 9.58186 5.58141 9.91764 5.1672 9.91764H4.55147V13.5026C4.55147 13.9169 4.21569 14.2526 3.80147 14.2526C3.38726 14.2526 3.05147 13.9169 3.05147 13.5026V9.91764H2.49805C2.08383 9.91764 1.74805 9.58186 1.74805 9.16764ZM10.0839 10.5013C10.0839 10.0871 10.4196 9.7513 10.8339 9.7513H13.5005C13.9147 9.7513 14.2505 10.0871 14.2505 10.5013C14.2505 10.9155 13.9147 11.2513 13.5005 11.2513H12.9496V13.5024C12.9496 13.9166 12.6138 14.2524 12.1996 14.2524C11.7854 14.2524 11.4496 13.9166 11.4496 13.5024V11.2513H10.8339C10.4196 11.2513 10.0839 10.9155 10.0839 10.5013Z"
      />
    </svg>
  );
};

export default SettingsSliderThreeIcon;
