import classNames from 'classnames';
import { HarmonicIcon } from './type';

const SquareCheckConfirmIcon: HarmonicIcon = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="#25A73F"
      fillRule="evenodd"
      clipRule="evenodd"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M7.09838 4C6.7551 3.99998 6.43974 3.99996 6.17618 4.02149C5.892 4.04471 5.5811 4.09786 5.27403 4.25432C4.83498 4.47803 4.47803 4.83498 4.25432 5.27403C4.09786 5.5811 4.04471 5.892 4.02149 6.17618C3.99996 6.43974 3.99998 6.75502 4 7.09831V24.9016C3.99998 25.2449 3.99996 25.5603 4.02149 25.8238C4.04471 26.108 4.09786 26.4189 4.25432 26.726C4.47803 27.165 4.83498 27.522 5.27403 27.7457C5.5811 27.9022 5.892 27.9553 6.17618 27.9785C6.43973 28.0001 6.75499 28 7.09826 28H24.9016C25.2449 28 25.5603 28.0001 25.8238 27.9785C26.108 27.9553 26.4189 27.9022 26.726 27.7457C27.165 27.522 27.522 27.165 27.7457 26.726C27.9022 26.4189 27.9553 26.108 27.9785 25.8238C28.0001 25.5603 28 25.245 28 24.9016V7.09836C28 6.75506 28.0001 6.43975 27.9785 6.17618C27.9553 5.892 27.9022 5.5811 27.7457 5.27403C27.522 4.83498 27.165 4.47803 26.726 4.25432C26.4189 4.09786 26.108 4.04471 25.8238 4.02149C25.5603 3.99996 25.245 3.99998 24.9017 4H7.09838ZM21.3695 13.926C21.76 13.5355 21.76 12.9023 21.3695 12.5118C20.979 12.1213 20.3458 12.1213 19.9553 12.5118L14.6624 17.8047L12.7028 15.8452C12.3123 15.4546 11.6791 15.4546 11.2886 15.8452C10.8981 16.2357 10.8981 16.8688 11.2886 17.2594L13.9553 19.926C14.3458 20.3166 14.979 20.3166 15.3695 19.926L21.3695 13.926Z"
      />
    </svg>
  );
};

export default SquareCheckConfirmIcon;
