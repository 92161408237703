import { useQuery } from '@apollo/client';
import {
  GetCompanyFinancingV2Query,
  GetCompanyFinancingV2QueryVariables,
  GetCompanyMetricsQuery,
  GetCompanyMetricsQueryVariables
} from '__generated__/graphql';
import { ReactComponent as BoxBarChart } from 'assets/bxs-bar-chart-alt.svg';
import {
  HarmonicIcon,
  LinkedInIcon,
  SquareArrowTopRightIcon
} from 'assets/harmonic-icons';
import { ReactComponent as CalculatorIcon } from 'assets/socials/bxl-calculator.svg';
import classNames from 'classnames';
import BarLineChart from 'components/Charts/BarLineChart';
import MetricCard from 'components/common/CompanyCard/MetricCard';
import SegmentedControl from 'harmonic-components/SegmentedControl/SegmentedControl';
import { HeadcountMetricsType } from 'interfaces/DataModel/CompanyV2';
import { isEqualWith, isNil } from 'lodash';
import { getCompanyFinancing } from 'queries/getCompanyFinancingV2';
import { getCompanyMetrics } from 'queries/getCompanyMetrics';
import { useState } from 'react';
import { formatHeadcountFundingForChart } from 'utils/chart';
import { truncateMoneyValue } from 'utils/utilities';
import FundingTotal from '../Cards/FundingTotal';
import HeadcountChange from '../Cards/HeadcountChange';
import { OnTabChangeType } from '../CompanyV2';
import CompanyTimelineGraphLegends from './CompanyTimelineGraphLegends';

const CompanyTimelineContentLoader: React.FC = () => {
  return (
    <>
      <div className="flex flex-row justify-between gap-10 grid-cols-3 mt-5 mb-4">
        {[...Array(3)].map((_, index) => (
          <div
            key={index}
            className="flex w-full h-20 py-px animated-box-pulse"
          />
        ))}
      </div>

      <div className="mt-5 h-80 py-14 bg-slate-200 rounded-md animate-pulse"></div>
    </>
  );
};

interface CompanyHeadcountFundingGraphProps {
  companyId: number;
  onTabChange: OnTabChangeType;
}
const CompanyHeadcountFundingGraph: React.FC<
  CompanyHeadcountFundingGraphProps
> = ({ companyId }) => {
  const { data: funding } = useQuery<
    GetCompanyFinancingV2Query,
    GetCompanyFinancingV2QueryVariables
  >(getCompanyFinancing, {
    variables: { id: companyId },
    fetchPolicy: 'cache-only'
  });

  const { data: socials } = useQuery<
    GetCompanyMetricsQuery,
    GetCompanyMetricsQueryVariables
  >(getCompanyMetrics, {
    variables: { id: companyId },
    fetchPolicy: 'cache-only'
  });

  const [headcountType, setHeadcountType] = useState<HeadcountMetricsType>(
    HeadcountMetricsType.CORRECTED_HEADCOUNT
  );

  const fundingData = funding?.getCompanyById;
  const tractionData = socials?.getCompanyById;
  const foundingDate = tractionData?.foundingDate?.date ?? '';
  const totalFundingRounds =
    fundingData?.funding?.numFundingRounds || 'Unknown';

  const fundingPerEmployee = funding?.getCompanyById?.fundingPerEmployee;
  const useCorrectedHeadcount =
    headcountType === HeadcountMetricsType.CORRECTED_HEADCOUNT;

  const correctedChartData = formatHeadcountFundingForChart(
    tractionData,
    fundingData,
    true
  );

  const originalChartData = formatHeadcountFundingForChart(
    tractionData,
    fundingData,
    false
  );
  const chartData = useCorrectedHeadcount
    ? correctedChartData
    : originalChartData;

  let barLineChartKeyProps = {};
  // Chart.js does not animate if data is same when toggling between corrected and real.
  // This key trick is to force animate when data are same.
  if (isEqualWith(correctedChartData, originalChartData)) {
    barLineChartKeyProps = { key: headcountType };
  }
  const renderFundingPerEmployee = () => {
    const fundingPerEmployeeValue = !isNil(fundingPerEmployee)
      ? truncateMoneyValue(fundingPerEmployee as number)
      : 'Unknown';
    return (
      <MetricCard
        icon={CalculatorIcon}
        value={fundingPerEmployeeValue}
        label="Funding per employee"
      />
    );
  };

  const fundingTotal = fundingData?.funding?.fundingTotal;

  const showLoader = !fundingData || !tractionData;
  return (
    <div>
      <p
        className={classNames(
          'typography-title-medium text-content-title border-b border-solid border-border pt-p40 pb-p70'
        )}
      >
        Headcount & funding
      </p>
      {showLoader ? (
        <CompanyTimelineContentLoader />
      ) : (
        <>
          <div className="mt-p80 mb-g80 flex grid grid-cols-2 xl:grid-cols-4 gap-g60">
            <FundingTotal companyId={companyId} />
            <HeadcountChange companyId={companyId} />
            <MetricCard
              icon={BoxBarChart}
              value={totalFundingRounds}
              label="Funding rounds"
            />
            {renderFundingPerEmployee()}
          </div>

          <div className="p-p60 flex items-center border border-solid border-border rounded-tl-br30 rounded-tr-br30">
            <div className={classNames('inline-flex')}>
              <SegmentedControl
                options={[
                  {
                    label: 'Harmonic',
                    value: HeadcountMetricsType.CORRECTED_HEADCOUNT,
                    leadingIcon: ({ className }) => (
                      <HarmonicIcon
                        applyCurrentColor={false}
                        className={className}
                      />
                    )
                  },
                  {
                    label: 'Linkedin',
                    value: HeadcountMetricsType.LINKEDIN_HEADCOUNT,
                    leadingIcon: ({ className }) => (
                      <LinkedInIcon
                        applyCurrentColor={false}
                        className={className}
                      />
                    )
                  }
                ]}
                selectedOption={headcountType}
                onChange={(newOption) =>
                  setHeadcountType(newOption as HeadcountMetricsType)
                }
              />
            </div>
            <a
              target="_blank"
              href="https://support.harmonic.ai/en/articles/8567598-corrected-headcount"
              rel="noreferrer"
              className={classNames(
                'ml-g60 typography-paragraph-small cursor-pointer text-content-weak flex gap-g20 items-center'
              )}
            >
              Why our Headcount data is different from LinkedIn’s
              <SquareArrowTopRightIcon className="w-4 h-4" />
            </a>
          </div>

          <div
            className={classNames(
              'p-p60 border border-solid border-border rounded-bl-br30 rounded-br-br30 border-t-0'
            )}
          >
            <div className="flex justify-between typography-label-small text-content-weak">
              <p>Headcount</p>
              <p>Funding</p>
            </div>
            <div className="h-72 mt-g60">
              <BarLineChart
                {...barLineChartKeyProps}
                lineChartData={chartData.lineChartDataPoints}
                barChartData={chartData.barChartDataPoints}
                foundingDate={foundingDate}
              />
            </div>
            <CompanyTimelineGraphLegends
              barChartData={chartData.barChartDataPoints}
              lineChartData={chartData.lineChartDataPoints}
              fundingTotal={fundingTotal}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default CompanyHeadcountFundingGraph;
