import classNames from 'classnames';
import { HarmonicIcon } from './type';

const ChartOutlineIcon: HarmonicIcon = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke="#7C8598"
      strokeWidth="1.5"
      strokeLinecap="square"
      strokeLinejoin="round"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M6.16667 13.5V9.83333H2.83333C2.64924 9.83333 2.5 9.98257 2.5 10.1667V13.1667C2.5 13.3508 2.64924 13.5 2.83333 13.5H6.16667ZM6.16667 13.5H9.83333M6.16667 13.5V6.5C6.16667 6.31591 6.31591 6.16667 6.5 6.16667H9.83333V13.5M9.83333 13.5H13.1667C13.3508 13.5 13.5 13.3508 13.5 13.1667V2.83333C13.5 2.64924 13.3508 2.5 13.1667 2.5H10.1667C9.98257 2.5 9.83333 2.64924 9.83333 2.83333V13.5Z"
      />
    </svg>
  );
};

export default ChartOutlineIcon;
