import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';
const HeartIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke="#E8EAEE"
      strokeWidth="1.5"
      strokeLinejoin="round"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M8.00016 3.71462C12.2222 -0.578433 19.46 7.39439 8.00019 13.8333C-3.45972 7.39442 3.77807 -0.578434 8.00016 3.71462Z"
      />
    </svg>
  );
};
export default HeartIcon;
