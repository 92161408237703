import classNames from 'classnames';
import { HarmonicIcon } from './type';

const TrophyIcon: HarmonicIcon = ({ applyCurrentColor = true, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#7C8598"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M4.49992 1.33333C3.85559 1.33333 3.33325 1.85567 3.33325 2.5V2.66667H2.49992C1.85559 2.66667 1.33325 3.189 1.33325 3.83333V4.83333C1.33325 6.09392 2.26626 7.1366 3.47939 7.30848C3.95787 9.13802 5.55901 10.5135 7.50252 10.6547V11.3333H5.16659C4.52225 11.3333 3.99992 11.8557 3.99992 12.5V13.5C3.99992 14.1443 4.52225 14.6667 5.16659 14.6667H10.8333C11.4776 14.6667 11.9999 14.1443 11.9999 13.5V12.5C11.9999 11.8557 11.4776 11.3333 10.8333 11.3333H8.50252V10.6543C10.4437 10.5111 12.0424 9.13639 12.5205 7.30848C13.7336 7.1366 14.6666 6.09392 14.6666 4.83333V3.83333C14.6666 3.189 14.1443 2.66667 13.4999 2.66667H12.6666V2.5C12.6666 1.85567 12.1443 1.33333 11.4999 1.33333H4.49992ZM2.49992 3.66667H3.33325V6.16667C3.33325 6.19391 3.3335 6.2211 3.33398 6.24824C2.751 6.04252 2.33325 5.48671 2.33325 4.83333V3.83333C2.33325 3.74128 2.40787 3.66667 2.49992 3.66667ZM13.6666 4.83333C13.6666 5.48671 13.2488 6.04252 12.6659 6.24824C12.6663 6.2211 12.6666 6.19391 12.6666 6.16667V3.66667H13.4999C13.592 3.66667 13.6666 3.74128 13.6666 3.83333V4.83333Z"
      />
    </svg>
  );
};

export default TrophyIcon;
