import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const HarmonicIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      fill="#FE5D45"
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M8.33709 2.0658L12.639 4.54944V9.51671L8.33709 12.0004L4.03516 9.51671V4.54944L8.33709 2.0658ZM4.03516 14.484V19.4513L8.33709 21.9341L12.639 19.4513V14.484L8.33709 12.0004L4.03516 14.484ZM12.6452 9.51671V14.484L16.9471 16.9676L21.2499 14.484V9.51671L16.9471 7.03462L12.6452 9.51671Z"
      />
    </svg>
  );
};

export default HarmonicIcon;
