import { IconProps } from './type';

const BxsFundingRoundIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H32C34.2091 0 36 1.79086 36 4V32C36 34.2091 34.2091 36 32 36H4C1.79086 36 0 34.2091 0 32V4Z"
        fill="#F0F4E1"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M21.3333 11.7508C21.3333 11.0604 21.893 10.5008 22.5833 10.5008H24.25C24.9404 10.5008 25.5 11.0604 25.5 11.7508V24.2508C25.5 24.9411 24.9404 25.5008 24.25 25.5008H22.5833C21.893 25.5008 21.3333 24.9411 21.3333 24.2508V11.7508Z"
        fill="#272D10"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M17.1667 15.0841C16.4763 15.0841 15.9167 15.6438 15.9167 16.3341V24.2508C15.9167 24.9411 16.4763 25.5008 17.1667 25.5008H18.8333C19.5237 25.5008 20.0833 24.9411 20.0833 24.2508V16.3341C20.0833 15.6438 19.5237 15.0841 18.8333 15.0841H17.1667Z"
        fill="#272D10"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M11.75 19.6675C11.0596 19.6675 10.5 20.2271 10.5 20.9175V24.2508C10.5 24.9411 11.0596 25.5008 11.75 25.5008H13.4167C14.107 25.5008 14.6667 24.9411 14.6667 24.2508V20.9175C14.6667 20.2271 14.107 19.6675 13.4167 19.6675H11.75Z"
        fill="#272D10"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default BxsFundingRoundIcon;
