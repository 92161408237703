import { CopyIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import { Tooltip } from 'common/components';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { useState } from 'react';
interface EmailProps {
  email: string;
  dataTestId?: string;
  collapsedInward?: boolean;
  className?: string;
}
const Email: React.FC<EmailProps> = ({
  email,
  dataTestId,
  collapsedInward,
  className
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const onCopyClick = () => {
    window.navigator.clipboard.writeText(email);
    setShowTooltip(true);
    setTimeout(() => {
      setShowTooltip(false);
    }, 3000);
  };

  // If the email item is in a stack, ie on a grid cell, it’s width doesn’t change on hover, and the text get’s truncated to allow for the copy action.
  if (collapsedInward) {
    return (
      <div
        className={classNames(
          'pl-p30 pr-p10 inline-flex items-center gap-x-g10 h-[24px]',
          'hover:bg-int-overlay-secondary-hover rounded-br30 relative',
          className
        )}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <span
          className={classNames(
            'text-content-default typography-label border-b border-solid border-border line-clamp-1',
            {
              'w-[calc(100%-24px)]': isHovered
            }
          )}
        >
          {email}
        </span>
        {isHovered && (
          <div className="absolute right-[2px] top-[2px]">
            <Tooltip title="Copied" open={showTooltip}>
              <IconButton
                onClick={onCopyClick}
                icon={CopyIcon}
                type="secondary"
                emphasis="low"
                size="tiny"
              />
            </Tooltip>
          </div>
        )}
      </div>
    );
  }

  // If the email item is singularly displayed, or in a vertical list, the action on hover appears to the right of the email, since there’s enough space to do so without moving the other items on the list
  return (
    <div
      data-testid={dataTestId}
      className={classNames(
        'group pl-p30 pr-p10 inline-flex items-center gap-g10 h-[24px]',
        'hover:bg-int-overlay-secondary-hover rounded-br30',
        className
      )}
      onClick={onCopyClick}
    >
      <span className="text-content-default typography-label border-b border-solid border-border line-clamp-1">
        {email}
      </span>
      <div className="invisible group-hover:visible">
        <Tooltip title="Copied" open={showTooltip}>
          <IconButton
            onClick={onCopyClick}
            icon={CopyIcon}
            type="secondary"
            emphasis="low"
            size="tiny"
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default Email;
