import { InstagramIcon } from 'assets/harmonic-icons';
import { IconProps } from 'assets/harmonic-icons/type';
import { ReactComponent as AngelListIcon } from 'assets/socials/ic-angellist.svg';
import { ReactComponent as CrunchbaseIcon } from 'assets/socials/ic-crunchbase.svg';
import { ReactComponent as FacebookIcon } from 'assets/socials/ic-facebook.svg';
import { ReactComponent as IndeedIcon } from 'assets/socials/ic-indeed.svg';
import { ReactComponent as LinkedinIcon } from 'assets/socials/ic-linkedin.svg';
import { ReactComponent as MonsterIcon } from 'assets/socials/ic-monster.svg';
import { ReactComponent as PitchbookIcon } from 'assets/socials/ic-pitchbook.svg';
import { ReactComponent as StackoverflowIcon } from 'assets/socials/ic-stackoverflow.svg';
import { ReactComponent as TwitterIcon } from 'assets/socials/ic-twitter.svg';
import { ReactComponent as WebsiteIcon } from 'assets/socials/ic-website.svg';
import { ReactComponent as YoutubeIcon } from 'assets/socials/ic-youtube.svg';
import classNames from 'classnames';
import IconButton from 'harmonic-components/IconButton/IconButton';
import analytics, { CustomTrackEvent } from 'utils/analytics';

interface SocialsProps {
  id: number;
  type: 'company' | 'person' | 'investor';
  websiteUrl?: string | null;
  linkedinUrl?: string | null;
  crunchbaseUrl?: string | null;
  pitchbookUrl?: string | null;
  angellistUrl?: string | null;
  twitterUrl?: string | null;
  facebookUrl?: string | null;
  instagramUrl?: string | null;
  indeedUrl?: string | null;
  youtubeUrl?: string | null;
  monsterUrl?: string | null;
  stackoverflowUrl?: string | null;
}

const Socials = (props: SocialsProps) => {
  const {
    id,
    type,
    websiteUrl,
    linkedinUrl,
    crunchbaseUrl,
    pitchbookUrl,
    angellistUrl,
    twitterUrl,
    facebookUrl,
    instagramUrl,
    indeedUrl,
    youtubeUrl,
    monsterUrl,
    stackoverflowUrl
  } = props;

  const openSocialLink = (url: string, linkType: string) => {
    if (type === 'company') {
      analytics.trackCustomEvent({
        event: CustomTrackEvent.COMPANY_PROFILE_OUTLINK_CLICK,
        properties: {
          companyId: id,
          link: url,
          linkType
        }
      });
    }
    if (type === 'person') {
      analytics.trackCustomEvent({
        event: CustomTrackEvent.PERSON_PROFILE_OUTLINK_CLICK,
        properties: {
          personId: id,
          link: url,
          linkType
        }
      });
    }

    window.open(url);
  };

  const emphasis = type == 'investor' ? 'high' : 'low';
  const size = type == 'investor' ? 'default' : 'compact';

  return (
    <div
      className={classNames('flex items-center', {
        'gap-x-1': type !== 'investor',
        'gap-g30': type == 'investor'
      })}
    >
      {websiteUrl && (
        <a href={websiteUrl} onClick={(e) => e.preventDefault()}>
          <IconButton
            onClick={() => openSocialLink(websiteUrl, 'website')}
            icon={WebsiteIcon}
            size={size}
            type="secondary"
            emphasis={emphasis}
            dataTestId="Social"
          />
        </a>
      )}

      {linkedinUrl && (
        <a href={linkedinUrl} onClick={(e) => e.preventDefault()}>
          <IconButton
            onClick={() => openSocialLink(linkedinUrl, 'linkedin')}
            icon={LinkedinIcon}
            size={size}
            type="secondary"
            emphasis={emphasis}
            dataTestId="Social"
          />
        </a>
      )}

      {crunchbaseUrl && (
        <a href={crunchbaseUrl} onClick={(e) => e.preventDefault()}>
          <IconButton
            onClick={() => openSocialLink(crunchbaseUrl, 'crunchbase')}
            icon={CrunchbaseIcon}
            size={size}
            type="secondary"
            emphasis={emphasis}
            dataTestId="Social"
          />
        </a>
      )}

      {pitchbookUrl && (
        <a href={pitchbookUrl} onClick={(e) => e.preventDefault()}>
          <IconButton
            onClick={() => openSocialLink(pitchbookUrl, 'pitchbook')}
            icon={PitchbookIcon}
            size={size}
            type="secondary"
            emphasis={emphasis}
            dataTestId="Social"
          />
        </a>
      )}

      {angellistUrl && (
        <a href={angellistUrl} onClick={(e) => e.preventDefault()}>
          <IconButton
            onClick={() => openSocialLink(angellistUrl, 'angellist')}
            icon={AngelListIcon}
            size={size}
            type="secondary"
            emphasis={emphasis}
            dataTestId="Social"
          />
        </a>
      )}

      {twitterUrl && (
        <a href={twitterUrl} onClick={(e) => e.preventDefault()}>
          <IconButton
            onClick={() => openSocialLink(twitterUrl, 'twitter')}
            icon={TwitterIcon}
            size={size}
            type="secondary"
            emphasis={emphasis}
            dataTestId="Social"
          />
        </a>
      )}

      {facebookUrl && (
        <a href={facebookUrl} onClick={(e) => e.preventDefault()}>
          <IconButton
            onClick={() => openSocialLink(facebookUrl, 'facebook')}
            icon={FacebookIcon}
            size={size}
            type="secondary"
            emphasis={emphasis}
            dataTestId="Social"
          />
        </a>
      )}

      {instagramUrl && (
        <a href={instagramUrl} onClick={(e) => e.preventDefault()}>
          <IconButton
            onClick={() => openSocialLink(instagramUrl, 'instagram')}
            icon={(props: IconProps) => (
              <InstagramIcon {...props} applyCurrentColor={false} />
            )}
            size={size}
            type="secondary"
            emphasis={emphasis}
            dataTestId="Social"
          />
        </a>
      )}

      {indeedUrl && (
        <a href={indeedUrl} onClick={(e) => e.preventDefault()}>
          <IconButton
            onClick={() => openSocialLink(indeedUrl, 'indeed')}
            icon={IndeedIcon}
            size={size}
            type="secondary"
            emphasis={emphasis}
            dataTestId="Social"
          />
        </a>
      )}

      {youtubeUrl && (
        <a href={youtubeUrl} onClick={(e) => e.preventDefault()}>
          <IconButton
            onClick={() => openSocialLink(youtubeUrl, 'youtube')}
            icon={YoutubeIcon}
            size={size}
            type="secondary"
            emphasis={emphasis}
            dataTestId="Social"
          />
        </a>
      )}

      {monsterUrl && (
        <a href={monsterUrl} onClick={(e) => e.preventDefault()}>
          <IconButton
            onClick={() => openSocialLink(monsterUrl, 'monster')}
            icon={MonsterIcon}
            size={size}
            type="secondary"
            emphasis={emphasis}
            dataTestId="Social"
          />
        </a>
      )}

      {stackoverflowUrl && (
        <a href={stackoverflowUrl} onClick={(e) => e.preventDefault()}>
          <IconButton
            onClick={() => openSocialLink(stackoverflowUrl, 'stackoverflow')}
            icon={StackoverflowIcon}
            size={size}
            type="secondary"
            emphasis={emphasis}
            dataTestId="Social"
          />
        </a>
      )}
    </div>
  );
};

export default Socials;
