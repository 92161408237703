import classNames from 'classnames';
import { IconProps } from './type';

const Date: React.FC<IconProps> = ({ applyCurrentColor = true, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="#7C8598"
      fillRule="evenodd"
      clipRule="evenodd"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M6.45833 1.66663C6.80351 1.66663 7.08333 1.94645 7.08333 2.29163V3.33329H12.9167V2.29163C12.9167 1.94645 13.1965 1.66663 13.5417 1.66663C13.8868 1.66663 14.1667 1.94645 14.1667 2.29163V3.33329H16.0417C16.8471 3.33329 17.5 3.98621 17.5 4.79163V7.49996H2.5V4.79163C2.5 3.98621 3.15292 3.33329 3.95833 3.33329H5.83333V2.29163C5.83333 1.94645 6.11316 1.66663 6.45833 1.66663Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M2.5 8.74996V16.0416C2.5 16.847 3.15292 17.5 3.95833 17.5H16.0417C16.8471 17.5 17.5 16.847 17.5 16.0416V8.74996H2.5Z"
      />
    </svg>
  );
};

export default Date;
