import { useQuery } from '@apollo/client';
import {
  GetCompaniesNamesQuery,
  GetCompaniesNamesQueryVariables,
  GetCompanyWatchlistWithCustomFieldsQuery,
  GetCompanyWatchlistWithCustomFieldsQueryVariables
} from '__generated__/graphql';
import Button from 'harmonic-components/Button/Button';
import ModalFooter from 'harmonic-components/ModalFooter/ModalFooter';
import ModalTitle from 'harmonic-components/ModalTitle/ModalTitle';

import { WarningIcon } from 'assets/harmonic-icons';
import { EditFieldTypeOptionIcon } from 'components/Dashboard/Grids/EditField/utils';
import Avatar from 'harmonic-components/Avatar/Avatar';
import useFetchWatchlists from 'hooks/useFetchWatchlists';
import { EntityListType } from 'interfaces/SearchModel/Search';
import { compact } from 'lodash';
import { getCompaniesNames } from 'queries/getCompaniesNames';
import { GET_WATCHLIST_WITH_CUSTOM_FIELDS } from 'queries/getWatchlistsCustomFields';

interface ConfirmRemoveFromListModalProps {
  onConfirmRemoval: () => void;
  listType: EntityListType.COMPANY_WATCHLIST | EntityListType.PEOPLE_WATCHLIST;
  listId: string;
  entityIds: number[];
}

const ConfirmRemoveFromListModal = (props: ConfirmRemoveFromListModalProps) => {
  const { onConfirmRemoval, listType, listId, entityIds } = props;

  const { watchlists } = useFetchWatchlists();

  const { data: listCustomFieldsData } = useQuery<
    GetCompanyWatchlistWithCustomFieldsQuery,
    GetCompanyWatchlistWithCustomFieldsQueryVariables
  >(GET_WATCHLIST_WITH_CUSTOM_FIELDS, {
    variables: {
      idOrUrn: listId
    },
    fetchPolicy: 'cache-first'
  });

  const watchlist = listCustomFieldsData?.getCompanyWatchlistByIdOrUrn;

  const listName =
    watchlists?.find((watchlist) => {
      return watchlist?.id === listId;
    })?.name ?? 'list';
  const customFields = watchlist?.customFields;

  const { data: companyNameData } = useQuery<
    GetCompaniesNamesQuery,
    GetCompaniesNamesQueryVariables
  >(getCompaniesNames, {
    variables: {
      ids: compact(entityIds)
    },
    fetchPolicy: 'cache-first',
    skip: entityIds.length !== 1
  });

  const entityTitle =
    entityIds.length === 1
      ? companyNameData?.getCompaniesByIds?.[0]?.name
      : `${entityIds.length} companies`;

  if (listType === EntityListType.PEOPLE_WATCHLIST) {
    throw new Error(
      'ConfirmRemoveFromListModal: People watchlist is not supported yet.'
    );
  }

  const ListFields = () => {
    return (
      <div className="p-p10 bg-surface-sentiment-neutral rounded-br40 mb-p60">
        <div className="flex px-p30 pt-p20 pb-p40 gap-g30">
          <Avatar
            size="medium"
            kind="logo"
            src={''}
            alt="company-profile-picture"
          />
          <p className="text-content-strong typography-label-default-default line-clamp-1">
            {entityTitle}
          </p>
        </div>
        <div className="px-p40 py-p20 rounded-br40 bg-white">
          {customFields?.map((field) => {
            const FieldTypeIcon = EditFieldTypeOptionIcon[field.type];
            return (
              <li key={field.urn} className="flex gap-g30">
                <FieldTypeIcon />
                {field.name}
              </li>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div>
      <ModalTitle
        titleIcon={WarningIcon}
        sentiment="negative"
        title={`Remove ${entityTitle} from ${listName}`}
      />
      <div className="px-p50 pt-p50 flex flex-col max-h-[400px] overflow-y-auto">
        <p className="typography-paragraph text-content-default mb-p50">
          You will lose any values entered for custom fields associated with
          this list.
        </p>
        {customFields && customFields.length > 0 && <ListFields />}
      </div>
      <ModalFooter>
        <Button
          onClick={onConfirmRemoval}
          type="negative"
          emphasis="high"
          label="Remove from list"
        />
      </ModalFooter>
    </div>
  );
};

export default ConfirmRemoveFromListModal;
