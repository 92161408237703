import classNames from 'classnames';
import { IconProps } from './type';

const Phone: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="#7C8598"
      fillRule="evenodd"
      clipRule="evenodd"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M6.80656 2.43094C6.45882 1.84802 5.65538 1.74808 5.17542 2.22804L2.18982 5.21363C1.82441 5.57905 1.6701 6.12194 1.82373 6.6367C2.21633 7.95219 2.76974 9.23012 3.48399 10.4395C4.20551 11.6611 5.09082 12.8122 6.1394 13.8608C7.18798 14.9094 8.33906 15.7947 9.56073 16.5162C10.7701 17.2305 12.048 17.7839 13.3635 18.1765C13.8783 18.3301 14.4211 18.1758 14.7866 17.8104L17.7722 14.8248C18.2521 14.3448 18.1522 13.5414 17.5693 13.1936L14.6393 11.4457C14.2296 11.2013 13.7063 11.2664 13.369 11.6037L12.2028 12.77C11.1858 12.2094 10.2292 11.4977 9.36585 10.6343C8.50246 9.77094 7.79077 8.81441 7.23021 7.79744L8.39648 6.63117C8.73377 6.29388 8.79887 5.77058 8.55449 5.36093L6.80656 2.43094Z"
      />
    </svg>
  );
};

export default Phone;
