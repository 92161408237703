import { ComponentType, lazy } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function lazyRetry<T extends ComponentType<any>>(
  componentImport: Parameters<typeof lazy<T>>[0],
  chunkIdentifier: string
): Promise<{ default: T }> {
  const sessionStorageKey = `retry-lazy-refreshed-${chunkIdentifier}`;

  return new Promise((resolve, reject) => {
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(sessionStorageKey) || 'false'
    );

    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(sessionStorageKey, 'false');
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          window.sessionStorage.setItem(sessionStorageKey, 'true');
          window.location.reload();
        }

        reject(error);
      });
  });
}
