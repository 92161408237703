const CompanySocialsContentLoader = () => {
  return (
    <div className="w-full py-6" data-testid="CompanySocialsContentLoader">
      <div className="w-full grid grid-cols-3 gap-4">
        {[...Array(6)].map((_, index) => (
          <div
            key={index}
            className="w-full h-20 bg-slate-200 rounded-md animate-pulse"
          />
        ))}
      </div>
      <div className="mt-10 w-full h-60 bg-slate-200 rounded-md animate-pulse"></div>
    </div>
  );
};

export default CompanySocialsContentLoader;
