import classNames from 'classnames';
import { HarmonicIcon } from './type';

const CustomAttributeTextIcon: HarmonicIcon = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#7C8598"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.224 3.19995C1.87937 3.19995 1.6 3.47933 1.6 3.82395C1.6 4.16858 1.87937 4.44795 2.224 4.44795H13.424C13.7686 4.44795 14.048 4.16858 14.048 3.82395C14.048 3.47933 13.7686 3.19995 13.424 3.19995H2.224ZM2.224 6.04795C1.87937 6.04795 1.6 6.32733 1.6 6.67195C1.6 7.01658 1.87937 7.29595 2.224 7.29595H13.424C13.7686 7.29595 14.048 7.01658 14.048 6.67195C14.048 6.32733 13.7686 6.04795 13.424 6.04795H2.224ZM1.6 9.51995C1.6 9.17533 1.87937 8.89595 2.224 8.89595H13.424C13.7686 8.89595 14.048 9.17533 14.048 9.51995C14.048 9.86458 13.7686 10.144 13.424 10.144H2.224C1.87937 10.144 1.6 9.86458 1.6 9.51995ZM2.2969 11.744C1.91201 11.744 1.6 12.0233 1.6 12.368C1.6 12.7126 1.91201 12.992 2.2969 12.992H8.5511C8.93599 12.992 9.248 12.7126 9.248 12.368C9.248 12.0233 8.93599 11.744 8.5511 11.744H2.2969Z"
      />
    </svg>
  );
};
export default CustomAttributeTextIcon;
