import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const PieChartIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      fill="#677183"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.9078 4.0893C10.9193 4.1327 10.9313 4.17788 10.9388 4.22314C10.9395 4.2276 10.9416 4.23173 10.9448 4.23492V4.23492C10.9481 4.23822 10.9502 4.24251 10.9508 4.24713C10.9602 4.32099 10.9639 4.4133 10.9349 4.51501C10.8899 4.66001 10.7899 4.79001 10.6599 4.87001C10.5649 4.93001 10.4649 4.94501 10.3899 4.95501C10.3249 4.96001 10.2499 4.96001 10.1799 4.96001H7.76988H7.58488C7.51988 4.95501 7.43488 4.94001 7.34488 4.89501C7.22488 4.83501 7.12988 4.74001 7.06988 4.62001C7.02488 4.53001 7.00988 4.44501 7.00488 4.38001V4.19501V1.78501C7.00488 1.71501 7.00488 1.64001 7.00988 1.57501C7.01488 1.50001 7.03488 1.40001 7.09488 1.30501C7.17488 1.17501 7.30488 1.07501 7.44988 1.03001C7.55488 1.00001 7.64988 1.00501 7.72488 1.01501C7.77244 1.02232 7.82 1.03499 7.86559 1.04714C7.88071 1.05116 7.8956 1.05513 7.91021 1.05883C7.91331 1.05962 7.9165 1.06001 7.9197 1.06001V1.06001C7.92314 1.06001 7.92658 1.06046 7.9299 1.06136C9.37084 1.45218 10.5027 2.58405 10.8935 4.02499C10.8944 4.02831 10.8949 4.03175 10.8949 4.03519V4.03519C10.8949 4.03839 10.8953 4.04157 10.8961 4.04467C10.8998 4.05929 10.9037 4.07418 10.9078 4.0893ZM6.43 5.95H9.625C9.83 5.95 9.995 6.11 10 6.315V6.45C10 8.935 7.985 10.95 5.5 10.95C3.015 10.95 1 8.935 1 6.45C1 3.965 3.015 1.95 5.5 1.955H5.64C5.845 1.96 6.005 2.125 6.005 2.33V5.525C6.005 5.675 6.005 5.76 6.01 5.825C6.01156 5.83746 6.01312 5.84797 6.01452 5.85655C6.01764 5.87556 6.02349 5.8942 6.03633 5.90855V5.90855C6.05525 5.92969 6.08274 5.94312 6.11107 5.94456C6.11666 5.94484 6.12294 5.945 6.13 5.945C6.19499 5.95 6.28498 5.95 6.42996 5.95H6.43Z"
      />
    </svg>
  );
};

export default PieChartIcon;
