import { RowNode } from 'ag-grid-community';
import { Badge } from 'common/components';
import { get } from 'lodash';
import React from 'react';
import TruncatedList from '../../../../../harmonic-components/TruncatedList/TruncatedList';
import { ICompany } from '../../../../../interfaces/DataModel/Company';
import { getCompanyHighlights } from '../../../../../utils/cellRendererUtils';
import { LightTooltip } from '../../../../common';
import { getHighlightIcon } from '../../../../common/Highlights/HighlightsChip';

export const CompanyHighlights: React.FC<{
  value: any;
  node?: RowNode<any>;
}> = (props) => {
  const company: ICompany = get(props, 'node.data.company');
  const highlights = get(props, 'value');
  const allHighlights = getCompanyHighlights(highlights, company);

  if (allHighlights.length === 0) return null;

  return (
    <div className="inline-block">
      <TruncatedList height={54}>
        {allHighlights.map((highlight, index: number) => {
          if (highlight.tooltip) {
            return (
              <LightTooltip
                key={index}
                enterDelay={1000}
                placement="bottom-end"
                interactive
                title={highlight.tooltip}
              >
                <div>
                  <Badge
                    leadingIcon={getHighlightIcon(highlight.category)}
                    label={highlight.category}
                    color="expressive-32"
                    size="large"
                    suffix={highlight.count > 1 ? `${highlight.count}` : ''}
                  />
                </div>
              </LightTooltip>
            );
          }
          return (
            <Badge
              key={index}
              leadingIcon={getHighlightIcon(highlight.category)}
              label={highlight.category}
              color="expressive-32"
              size="large"
              suffix={highlight.count > 1 ? `${highlight.count}` : ''}
            />
          );
        })}
      </TruncatedList>
    </div>
  );
};
