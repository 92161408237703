import { IconProps } from './type';

const Hamburger: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33325 3.83333C1.33325 3.55719 1.55711 3.33333 1.83325 3.33333H14.1666C14.4427 3.33333 14.6666 3.55719 14.6666 3.83333C14.6666 4.10947 14.4427 4.33333 14.1666 4.33333H1.83325C1.55711 4.33333 1.33325 4.10947 1.33325 3.83333ZM1.33325 8C1.33325 7.72385 1.55711 7.5 1.83325 7.5H14.1666C14.4427 7.5 14.6666 7.72385 14.6666 8C14.6666 8.27614 14.4427 8.5 14.1666 8.5H1.83325C1.55711 8.5 1.33325 8.27614 1.33325 8ZM1.33325 12.1667C1.33325 11.8905 1.55711 11.6667 1.83325 11.6667H14.1666C14.4427 11.6667 14.6666 11.8905 14.6666 12.1667C14.6666 12.4428 14.4427 12.6667 14.1666 12.6667H1.83325C1.55711 12.6667 1.33325 12.4428 1.33325 12.1667Z"
        fill="#484E5B"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};
export default Hamburger;
