import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const SettingsSliderThreePendingIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      className={classNames(applyCurrentColor && 'fill-current', className)}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#1A1D23"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        vectorEffect="non-scaling-stroke"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4486 6.0398V8.16721C11.4486 8.58143 11.7844 8.91721 12.1986 8.91721C12.6128 8.91721 12.9486 8.58143 12.9486 8.16721V6.48806C12.4082 6.44509 11.9002 6.28757 11.4486 6.0398ZM4.5505 2.49805C4.5505 2.08383 4.21471 1.74805 3.8005 1.74805C3.38628 1.74805 3.0505 2.08383 3.0505 2.49805V6.83332C3.0505 7.24754 3.38628 7.58332 3.8005 7.58332C4.21471 7.58332 4.5505 7.24754 4.5505 6.83332V2.49805ZM8.74957 2.66471C8.74957 2.2505 8.41378 1.91471 7.99957 1.91471C7.58536 1.91471 7.24957 2.2505 7.24957 2.66471V5.41602H6.66622C6.25201 5.41602 5.91622 5.7518 5.91622 6.16602C5.91622 6.58023 6.25201 6.91602 6.66622 6.91602H9.33289C9.7471 6.91602 10.0829 6.58023 10.0829 6.16602C10.0829 5.7518 9.7471 5.41602 9.33289 5.41602H8.74957V2.66471ZM8.74955 8.50033C8.74955 8.08611 8.41377 7.75033 7.99955 7.75033C7.58534 7.75033 7.24955 8.08611 7.24955 8.50033V13.3359C7.24955 13.7501 7.58534 14.0859 7.99955 14.0859C8.41377 14.0859 8.74955 13.7501 8.74955 13.3359V8.50033ZM2.49707 8.41764C2.08286 8.41764 1.74707 8.75343 1.74707 9.16764C1.74707 9.58186 2.08286 9.91764 2.49707 9.91764H3.0505V13.5026C3.0505 13.9169 3.38628 14.2526 3.8005 14.2526C4.21471 14.2526 4.5505 13.9169 4.5505 13.5026V9.91764H5.16622C5.58043 9.91764 5.91622 9.58186 5.91622 9.16764C5.91622 8.75343 5.58043 8.41764 5.16622 8.41764H2.49707ZM10.8329 9.7513C10.4187 9.7513 10.0829 10.0871 10.0829 10.5013C10.0829 10.9155 10.4187 11.2513 10.8329 11.2513H11.4486V13.5024C11.4486 13.9166 11.7844 14.2524 12.1986 14.2524C12.6128 14.2524 12.9486 13.9166 12.9486 13.5024V11.2513H13.4996C13.9138 11.2513 14.2496 10.9155 14.2496 10.5013C14.2496 10.0871 13.9138 9.7513 13.4996 9.7513H10.8329Z"
      />
      <circle cx="13.25" cy="2.75" r="2.25" fill="#E17414" />
    </svg>
  );
};

export default SettingsSliderThreePendingIcon;
