import { IconProps } from './type';

const GoogleChromeIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4004_1157)">
        <path
          d="M7.99175 11.9784C10.1817 11.9784 11.9574 10.203 11.9574 8.01275C11.9574 5.82246 10.182 4.04712 7.99175 4.04712C5.80147 4.04712 4.02612 5.82246 4.02612 8.01275C4.02612 10.203 5.80147 11.9784 7.99175 11.9784Z"
          vectorEffect="non-scaling-stroke"
          fill="white"
        />
        <path
          d="M4.55676 9.99627L1.1232 4.04907C0.42696 5.25465 0.0603027 6.62221 0.0603027 8.01439C0.0603027 9.40656 0.426648 10.7741 1.12289 11.9797C1.81914 13.1853 2.82035 14.1862 4.02624 14.8821C5.23213 15.5781 6.5997 15.9441 7.99187 15.9438L11.4254 9.99627V9.99533C11.0778 10.5984 10.5772 11.0994 9.97437 11.4476C9.37158 11.7959 8.6878 11.9794 7.99156 11.9794C7.29532 11.9794 6.61153 11.7962 6.00843 11.4483C5.40533 11.1003 4.90472 10.5994 4.55676 9.99627Z"
          vectorEffect="non-scaling-stroke"
          fill="url(#paint0_linear_4004_1157)"
        />
        <path
          d="M11.4251 9.99629L7.99155 15.9435C9.38372 15.9438 10.7513 15.5775 11.9569 14.8812C13.1624 14.1853 14.1637 13.1841 14.8596 11.9785C15.5555 10.7729 15.9219 9.40503 15.9216 8.01286C15.9212 6.62068 15.5546 5.25312 14.858 4.04785H7.99092L7.98999 4.04847C8.68623 4.04816 9.37033 4.23102 9.97312 4.57899C10.5762 4.92696 11.0768 5.42757 11.4251 6.03035C11.7734 6.63314 11.9566 7.31724 11.9566 8.01348C11.9569 8.70941 11.7734 9.39319 11.4251 9.99629Z"
          vectorEffect="non-scaling-stroke"
          fill="url(#paint1_linear_4004_1157)"
        />
        <path
          d="M7.9908 11.1529C9.72471 11.1529 11.1303 9.74729 11.1303 8.01338C11.1303 6.27947 9.72471 4.8739 7.9908 4.8739C6.25689 4.8739 4.85132 6.27947 4.85132 8.01338C4.85132 9.74729 6.25689 11.1529 7.9908 11.1529Z"
          vectorEffect="non-scaling-stroke"
          fill="#1A73E8"
        />
        <path
          d="M7.99085 4.04815H14.8583C14.1623 2.84226 13.1611 1.84104 11.9555 1.14511C10.75 0.448865 9.3824 0.0825195 7.99022 0.0825195C6.59805 0.0825195 5.23048 0.449177 4.02491 1.14542C2.81933 1.84166 1.81842 2.84319 1.1228 4.04877L4.55636 9.99628L4.55729 9.9969C4.20901 9.39411 4.02522 8.71033 4.02522 8.01409C4.02522 7.31784 4.20808 6.63375 4.55605 6.03096C4.90401 5.42786 5.40462 4.92725 6.00772 4.57929C6.61051 4.23101 7.2946 4.04784 7.99085 4.04815Z"
          vectorEffect="non-scaling-stroke"
          fill="url(#paint2_linear_4004_1157)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_4004_1157"
          x1="8.84964"
          y1="15.4484"
          x2="1.98195"
          y2="3.55322"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1E8E3E" />
          <stop offset="1" stopColor="#34A853" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4004_1157"
          x1="6.83426"
          y1="15.9646"
          x2="13.702"
          y2="4.06936"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCC934" />
          <stop offset="1" stopColor="#FBBC04" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4004_1157"
          x1="1.12276"
          y1="5.0397"
          x2="14.8582"
          y2="5.0397"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D93025" />
          <stop offset="1" stopColor="#EA4335" />
        </linearGradient>
        <clipPath id="clip0_4004_1157">
          <rect
            width="15.9497"
            height="15.9497"
            fill="white"
            transform="translate(0.0251465 0.0251465)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default GoogleChromeIcon;
