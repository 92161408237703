import classNames from 'classnames';
import { HarmonicIcon } from './type';

const CalendarRepeatIcon: HarmonicIcon = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#7C8598"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M5.16667 1.33331C5.44281 1.33331 5.66667 1.55717 5.66667 1.83331V2.66665H10.3333V1.83331C10.3333 1.55717 10.5572 1.33331 10.8333 1.33331C11.1095 1.33331 11.3333 1.55717 11.3333 1.83331V2.66665H12.8333C13.4777 2.66665 14 3.18898 14 3.83331V6.16665C14 6.44279 13.7761 6.66665 13.5 6.66665H3V12.8333C3 12.9254 3.07462 13 3.16667 13H6.83333C7.10948 13 7.33333 13.2238 7.33333 13.5C7.33333 13.7761 7.10948 14 6.83333 14H3.16667C2.52233 14 2 13.4776 2 12.8333V3.83331C2 3.18898 2.52233 2.66665 3.16667 2.66665H4.66667V1.83331C4.66667 1.55717 4.89052 1.33331 5.16667 1.33331Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.4265 9.3848C10.7879 9.16085 11.2151 9.03123 11.6667 9.03123C12.7386 9.03123 13.6407 9.76438 13.8962 10.7574C13.9694 11.0415 14.259 11.2126 14.5431 11.1395C14.8273 11.0663 14.9983 10.7767 14.9252 10.4926C14.5517 9.04151 13.2351 7.96873 11.6667 7.96873C10.9232 7.96873 10.2259 8.21038 9.66129 8.61961L9.08377 8.04208C8.86065 7.81897 8.47917 7.97698 8.47917 8.29251V9.91665C8.47917 10.3078 8.7963 10.625 9.1875 10.625H10.8116C11.1272 10.625 11.2852 10.2435 11.0621 10.0204L10.4265 9.3848Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M9.43707 11.9092C9.36394 11.6251 9.07431 11.454 8.79017 11.5272C8.50603 11.6003 8.33497 11.8899 8.40811 12.1741C8.78158 13.6251 10.0982 14.6979 11.6667 14.6979C12.4103 14.6979 13.1076 14.4561 13.672 14.047L14.2496 14.6245C14.4727 14.8477 14.8542 14.6896 14.8542 14.3741V12.75C14.8542 12.3588 14.537 12.0416 14.1458 12.0416H12.5217C12.2062 12.0416 12.0482 12.4231 12.2713 12.6462L12.9068 13.2818C12.5453 13.5059 12.1181 13.6354 11.6667 13.6354C10.5947 13.6354 9.69266 12.9022 9.43707 11.9092Z"
      />
    </svg>
  );
};

export default CalendarRepeatIcon;
