import Button from 'harmonic-components/Button/Button';
import ModalFooter from 'harmonic-components/ModalFooter/ModalFooter';
import ModalTitle from 'harmonic-components/ModalTitle/ModalTitle';
import TextInput from 'harmonic-components/TextInput/TextInput';
import { useModal } from 'hooks/useModal';
import {
  EntityListModalType,
  EntityListType
} from 'interfaces/SearchModel/Search';
import { capitalize, isString } from 'lodash';
import React, { useState } from 'react';

interface UpsertEntityListModalProps {
  entityName?: string;
  entityType: EntityListType;
  modalType: EntityListModalType;
  onClose: () => void;
  onSubmit: (entityName: string) => Promise<void>;
}

export const UpsertEntityListModal: React.FC<UpsertEntityListModalProps> = ({
  entityName,
  onClose,
  onSubmit,
  modalType,
  entityType
}) => {
  const [isLoading, setIsLoading] = useState(false);

  let defaultEntityNameTextInputValue = '';
  if (modalType === EntityListModalType.DuplicateList) {
    // We are cloning an existing list. Use the previous name as a placeholder.
    defaultEntityNameTextInputValue = `${entityName} (copy)`;
  }

  const [entityNameTextInputValue, setEntityNameTextInputValuedName] =
    React.useState(defaultEntityNameTextInputValue);

  const onEntityNameTextInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (isString(event.target.value)) {
      setEntityNameTextInputValuedName(event.target.value);
    }
  };

  const entityTypeText =
    entityType === EntityListType.COMPANY_SAVED_SEARCH
      ? 'company search'
      : entityType === EntityListType.PEOPLE_SAVED_SEARCH
      ? 'people search'
      : entityType === EntityListType.COMPANY_WATCHLIST
      ? 'company list'
      : entityType === EntityListType.PEOPLE_WATCHLIST
      ? 'people list'
      : '';

  const actionTitleText =
    modalType === EntityListModalType.DuplicateList
      ? 'Duplicate'
      : 'Create new';

  const title = capitalize(
    `${actionTitleText} ${entityName ?? entityTypeText}`
  );

  const actionButtonLabel =
    modalType === EntityListModalType.DuplicateList ? 'Duplicate' : 'Create';

  const onActionButtonClicked = async () => {
    setIsLoading(true);
    await onSubmit(entityNameTextInputValue);
    onClose();
  };

  return (
    <>
      <ModalTitle title={title} />
      <div className="px-p50 pt-p50 pb-p80 flex flex-col">
        <div
          className="typography-paragraph text-content-default"
          id="alert-dialog-description"
        >
          {modalType === EntityListModalType.DuplicateList && (
            <p className="mb-g50">
              A new {entityTypeText} identical to{' '}
              {<strong>{entityName}</strong>} will be created.
            </p>
          )}
        </div>

        <TextInput
          data-testid="UpdateEntityListModal-NameTextField"
          value={entityNameTextInputValue}
          onChange={onEntityNameTextInputChange}
          label="Name"
        />
      </div>
      <ModalFooter>
        <Button
          dataTestId="EntityListModal-UpsertBtn"
          onClick={onActionButtonClicked}
          type="primary"
          emphasis="high"
          loading={isLoading}
          label={actionButtonLabel}
          isDisabled={isLoading}
        />
      </ModalFooter>
    </>
  );
};

export function useUpsertEntityListModal() {
  const { show, isOpen, close } = useModal();

  type ModalOptions = Omit<UpsertEntityListModalProps, 'onClose'>;
  const showUpsertEntityListModal = (props: ModalOptions) => {
    show(<UpsertEntityListModal {...props} onClose={close} />);
  };

  return {
    showUpsertEntityListModal,
    isUpsertEntityListModalOpen: isOpen,
    closeUpsertEntityListModal: close
  };
}
