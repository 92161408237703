import classNames from 'classnames';
import { IconProps } from './type';

const PercentIcon: React.FC<IconProps> = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6663 5.33325L5.33301 10.6666M14.1663 7.99992C14.1663 11.4057 11.4054 14.1666 7.99967 14.1666C4.59392 14.1666 1.83301 11.4057 1.83301 7.99992C1.83301 4.59416 4.59392 1.83325 7.99967 1.83325C11.4054 1.83325 14.1663 4.59416 14.1663 7.99992Z"
        stroke="#7C8598"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M5.41699 6.00008C5.41699 6.32225 5.67816 6.58342 6.00033 6.58342C6.32249 6.58342 6.58366 6.32225 6.58366 6.00008C6.58366 5.67792 6.32249 5.41675 6.00033 5.41675C5.67816 5.41675 5.41699 5.67792 5.41699 6.00008Z"
        fill="#7C8598"
        stroke="#7C8598"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M9.41699 10.0001C9.41699 10.3222 9.67816 10.5834 10.0003 10.5834C10.3225 10.5834 10.5837 10.3222 10.5837 10.0001C10.5837 9.67792 10.3225 9.41675 10.0003 9.41675C9.67816 9.41675 9.41699 9.67792 9.41699 10.0001Z"
        fill="#7C8598"
        stroke="#7C8598"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default PercentIcon;
