import classnames from 'classnames';
import * as React from 'react';

interface IButtonProps extends React.HTMLProps<HTMLButtonElement> {
  dataTestId?: string;
  type?: 'button' | 'submit' | 'reset';
  color?:
    | 'primary'
    | 'secondary'
    | 'danger'
    | 'default'
    | 'text'
    | 'outline'
    | 'black';
  height?: 'tall' | 'default' | 'short';
  fontSize?: 'small' | 'medium' | 'large';
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
}

const Button: React.FC<IButtonProps> = ({
  disabled,
  dataTestId,
  height = 'default',
  fontSize = 'small',
  color = 'primary',
  onClick,
  type = 'button',
  children,
  className,
  startIcon,
  endIcon,
  onFocus,
  onBlur,
  id
}) => {
  return (
    <button
      type={type}
      onFocus={onFocus}
      onBlur={onBlur}
      id={id}
      disabled={disabled}
      data-testid={dataTestId}
      onClick={onClick}
      className={classnames(
        'transition-30 border border-solid rounded-md inline-flex gap-1 justify-center items-center',
        {
          'typography-label': fontSize === 'small',
          'text-base': fontSize === 'medium',
          'text-lg': fontSize === 'large',
          'text-int-fill-negative-onEnabled bg-int-fill-negative-enabled hover:bg-int-fill-negative-hover':
            color === 'danger' && !disabled,

          'text-gray-700 bg-white hover:bg-gray-100':
            color === 'default' && !disabled,
          'text-content-core': color === 'black' && !disabled,
          'text-int-outline-secondary-contentEnabled bg-transparent border border-int-outline-secondary-enabled hover:border-int-outline-secondary-hover':
            color === 'outline' && !disabled,
          'cursor-normal  bg-clickables-mainStroke border-transparent':
            disabled && color === 'secondary',
          'px-4 py-3': height === 'tall',
          'px-5 py-1.5 h-7.5': height === 'default',
          'px-4 py-0 h-6.5': height === 'short',
          'text-int-fill-primary-contentEnabled hover:text-int-fill-primary-hover border-none':
            color === 'text' && !disabled,
          'text-int-fill-primary-onEnabled bg-int-fill-primary-enabled border-transparent hover:bg-int-fill-primary-hover':
            color === 'primary' && !disabled,
          'text-int-fill-secondary-onEnabled bg-int-fill-secondary-enabled hover:bg-int-fill-secondary-hover border-transparent':
            color === 'secondary',
          'bg-int-fill-secondary-disabled text-int-fill-secondary-contentDisabled':
            disabled && color !== 'secondary'
        },
        className
      )}
    >
      {startIcon}
      {children}
      {endIcon}
    </button>
  );
};

export default Button;
