import { create } from 'zustand';

interface IFilterStoreState {
  savedSearchCounts?: {
    [key in number]: number;
  };
  incrementCount(key: number): void;
  decrementCount(key: number): void;
  setSavedSearchCount(key: number, count: number): void;
}

const useSavedSearchStore = create<IFilterStoreState>((set) => ({
  savedSearchCounts: {},
  incrementCount: (key: number) =>
    set((state) => ({
      savedSearchCounts: {
        ...state.savedSearchCounts,
        [key]:
          state.savedSearchCounts && state.savedSearchCounts[key]
            ? state.savedSearchCounts[key] + 1
            : 1
      }
    })),
  decrementCount: (key: number) =>
    set((state) => ({
      savedSearchCounts: {
        ...state.savedSearchCounts,
        [key]:
          state.savedSearchCounts && state.savedSearchCounts[key]
            ? state.savedSearchCounts[key] > 1
              ? state.savedSearchCounts[key] - 1
              : 0
            : 0
      }
    })),
  setSavedSearchCount: (key: number, count: number) => {
    set((state) => ({
      savedSearchCounts: {
        ...state.savedSearchCounts,
        [key]: count
      }
    }));
  }
}));

export default useSavedSearchStore;
