import { RowNode } from 'ag-grid-community';
import { Badge } from 'common/components';
import { isNil, toNumber } from 'lodash';

export const RelevanceScore: React.FC<{
  value: any;
  node?: RowNode<any>;
}> = (props) => {
  if (isNil(props.value)) return null;
  const value = toNumber(props.value);
  const isBelowThreshold = value < 70;
  return (
    <div className="inline-block">
      <Badge
        size="large"
        color={isBelowThreshold ? 'neutral' : 'positive'}
        intensity="subtle"
        label={`${value}%`}
      />
    </div>
  );
};
