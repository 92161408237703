import classNames from 'classnames';
import { IconProps } from './type';

const ToolboxIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#493AA6"
      fillRule="evenodd"
      clipRule="evenodd"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M4.66683 2.33325C4.64547 2.33325 4.62583 2.34498 4.6157 2.36379L3.68675 4.08898C3.67367 4.11327 3.66683 4.14042 3.66683 4.168V6.16659C3.66683 6.44273 3.89069 6.66659 4.16683 6.66659H5.16683C5.44297 6.66659 5.66683 6.44273 5.66683 6.16659V4.168C5.66683 4.14042 5.65998 4.11327 5.64691 4.08898L4.71796 2.36379C4.70783 2.34498 4.68819 2.33325 4.66683 2.33325ZM4.66683 1.33325C5.05608 1.33325 5.41389 1.54697 5.59843 1.88969L6.52738 3.61488C6.61891 3.78487 6.66683 3.97493 6.66683 4.168V6.16659C6.66683 6.44273 6.89069 6.66659 7.16683 6.66659H7.50016C7.77631 6.66659 8.00016 6.44273 8.00016 6.16659V2.49992C8.00016 1.85559 8.5225 1.33325 9.16683 1.33325H12.1668C12.8112 1.33325 13.3335 1.85559 13.3335 2.49992V6.16659C13.3335 6.44273 13.5574 6.66659 13.8335 6.66659H14.1668C14.443 6.66659 14.6668 6.89044 14.6668 7.16659V12.8333C14.6668 13.4776 14.1445 13.9999 13.5002 13.9999H2.50016C1.85583 13.9999 1.3335 13.4776 1.3335 12.8333V7.16659C1.3335 6.89044 1.55735 6.66659 1.8335 6.66659H2.16683C2.44297 6.66659 2.66683 6.44273 2.66683 6.16659V4.168C2.66683 3.97493 2.71475 3.78487 2.80628 3.61488L3.73523 1.88969C3.91977 1.54697 4.27758 1.33325 4.66683 1.33325ZM9.00016 6.16659C9.00016 6.44273 9.22402 6.66659 9.50016 6.66659H11.8335C12.1096 6.66659 12.3335 6.44273 12.3335 6.16659V2.49992C12.3335 2.40787 12.2589 2.33325 12.1668 2.33325H9.16683C9.07478 2.33325 9.00016 2.40787 9.00016 2.49992V3.49992C9.00016 3.77606 9.22402 3.99992 9.50016 3.99992H10.1668C10.443 3.99992 10.6668 4.22378 10.6668 4.49992C10.6668 4.77606 10.443 4.99992 10.1668 4.99992H9.50016C9.22402 4.99992 9.00016 5.22378 9.00016 5.49992V6.16659Z"
      />
    </svg>
  );
};

export default ToolboxIcon;
