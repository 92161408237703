import { datadogRum } from '@datadog/browser-rum';
import { ThemeProvider } from '@material-ui/core';
import { SplitFactoryProvider } from '@splitsoftware/splitio-react';
import AppLayout from 'AppLayout';
import { TeamRole } from '__generated__/graphql';
import {
  logout,
  setUserApiKey,
  setUserRole,
  setUserStatus
} from 'actions/authActions';
import { config } from 'config/config';
import { useMultipleFlags } from 'hooks/useFlags';
import { IAuthState, LogoutReason, UserStatusType } from 'interfaces/Auth';
import { isNil } from 'lodash';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { useIntercom } from 'react-use-intercom';
import { AppState } from 'store';
import { theme } from 'styles/materialUiTheme';
import analytics from 'utils/analytics';
import { IS_PROD_LIKE_ENV, SPLITS } from 'utils/constants';
import { logger } from 'utils/logger';
import { authenticate, getUserApiKey } from 'utils/midtierApi';
import { useNetworkConnectionInfo } from './hooks/useNetworkConnectionInfo';
import './styles/App.css';

const splitSdkConfig: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey: config.SPLIT_API_KEY,
    key: 'HARMONIC_SPLIT_KEY'
  },
  sync: {
    enabled: false
  },
  impressionListener: {
    logImpression(impressionData) {
      datadogRum.addFeatureFlagEvaluation(
        impressionData.impression.feature,
        impressionData.impression.treatment
      );
    }
  }
};

const App: FC = () => {
  const featureFlags = useMultipleFlags(Object.values(SPLITS));
  const dispatch = useDispatch();
  const authState = useSelector<AppState, AppState['auth']>(
    (state) => state.auth
  );
  const intercom = useIntercom();
  const networkConnectionInfo = useNetworkConnectionInfo();

  const getIntercomUserArguments = (authState: IAuthState) => {
    const userMetadata = authState.userMetadata;

    let company = undefined;
    if (userMetadata) {
      company = {
        companyId: userMetadata.customer_urn,
        name: userMetadata.customer_name
      };
    }
    return {
      userId: authState.user?.user_id,
      name: authState.user?.displayName,
      email: authState.user?.email,
      company
    };
  };

  const isUserLoggedIn = !isNil(authState.user);

  const shutdownIntercom = () => intercom.shutdown();
  const bootIntercom = () => {
    intercom.boot(getIntercomUserArguments(authState));
  };

  useEffect(() => {
    analytics.loadHeap();
  }, []);

  useEffect(() => {
    if (authState.user) {
      // Intercom is not supported in CI
      if (IS_PROD_LIKE_ENV && !process.env.CI) {
        bootIntercom();
      }
      intercom.update(getIntercomUserArguments(authState));
      // Required in case the user is paused during their session
      authenticate()
        .then((user) => {
          dispatch(setUserStatus(user.status as UserStatusType));
          dispatch(setUserRole(user.role as TeamRole));
        })
        .catch(() => {
          dispatch(logout(LogoutReason.SessionExpired));
        });
    }

    if (authState.apiKey || !authState.user) return;
    getUserApiKey()
      .then((apiKey) => {
        dispatch(setUserApiKey(apiKey));
      })
      .catch((err) => {
        if (IS_PROD_LIKE_ENV && !process.env.CI) {
          shutdownIntercom();
        }
        dispatch(logout(LogoutReason.SessionExpired));
        logger.error(`Auth state changed error: ${err}`, {
          code_area: 'app',
          err
        });
      });
    // Above hook should be only called when authState.user changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.user]);

  useEffect(() => {
    logger.addGlobalContext({
      networkType: networkConnectionInfo?.type,
      networkDownlink: networkConnectionInfo?.downlink,
      networkDownlinkMax: networkConnectionInfo?.downlinkMax
    });
    // Heap only supports scalar values for event properties, so we need
    // to transform the list into a comma separated string.
    const enabledFlagNames = Object.entries(featureFlags)
      .filter(([, value]) => value.enabled)
      .map(([key]) => key);
    analytics.addEventProperties({ featureFlags: enabledFlagNames.join(',') });
  }, [featureFlags, networkConnectionInfo]);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <SplitFactoryProvider
          config={isUserLoggedIn ? splitSdkConfig : undefined}
        >
          <Routes>
            <Route path="*" element={<AppLayout />} />
          </Routes>
        </SplitFactoryProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
