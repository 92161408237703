import { gql } from '@apollo/client';

export const FullPeopleSearch = gql`
  fragment PeopleSearchExperience on ExperienceMetadata {
    __typename
    company {
      ... on Company {
        id
        entityUrn
        logoUrl
        name
        tags {
          entityUrn
          displayValue
          type
        }
        location {
          city
          state
          zip
          country
        }
      }
    }
    isCurrentPosition
    startDate
    endDate
    location
    title
    department
  }

  fragment ExtendedPeopleSearch on Person {
    about
    profilePictureUrl
    linkedinHeadline
    location {
      location
      state
      city
      country
    }
    highlights {
      category
      text
    }
    socials {
      linkedin {
        followerCount
        url
        status
      }
      twitter {
        url
        followerCount
      }
    }
    experience {
      ...PeopleSearchExperience
    }
    education {
      school {
        name
        websiteUrl
        linkedinUrl
        logoUrl
        entityUrn
      }
      degree
    }
    contact {
      emails
      phoneNumbers
    }
    emailEnrichmentStatus {
      enrichedAt
    }
    updates {
      eventType
      date
      helperText
    }
    userConnections {
      targetPerson {
        id
        fullName
      }
      user {
        email
        name
      }
    }
    watchlists {
      id
      name
      entityUrn
    }
    correspondenceSummary {
      lastEmailAt
      lastEmailContactPersonEmail
      lastEmailContactPerson {
        fullName
      }
      lastMeetingAt
      lastMeetingContactPersonEmail
      lastMeetingContactPerson {
        fullName
      }
    }
  }

  fragment PeopleSearch on Person {
    __typename
    personUrn: entityUrn
    id
    fullName
    firstName
    lastName
    ...ExtendedPeopleSearch @skip(if: $skipExtended)
  }
`;
