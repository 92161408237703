import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const GridLargeIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        d="M2.57379 1.11719e-06C2.31632 -1.60782e-05 2.0798 -3.18661e-05 1.88213 0.0161186C1.669 0.0335322 1.43583 0.073395 1.20552 0.190742C0.876237 0.358521 0.608521 0.626237 0.440742 0.95552C0.323395 1.18583 0.283532 1.419 0.266119 1.63213C0.249968 1.8298 0.249984 2.06627 0.250001 2.32373V15.6762C0.249984 15.9337 0.249968 16.1702 0.266119 16.3679C0.283532 16.581 0.323395 16.8142 0.440742 17.0445C0.608521 17.3738 0.876236 17.6415 1.20552 17.8093C1.43583 17.9266 1.669 17.9665 1.88213 17.9839C2.07981 18 2.31629 18 2.57377 18L8.5 18V9.75H0.250005V8.25H8.5V1.11719e-06H2.57379Z"
        fill="currentColor"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M10 1.11719e-06V8.25H18.25L18.25 2.32377C18.25 2.06629 18.25 1.82981 18.2339 1.63213C18.2165 1.419 18.1766 1.18583 18.0593 0.955522C17.8915 0.626236 17.6238 0.35852 17.2945 0.190742C17.0642 0.073395 16.831 0.0335322 16.6179 0.0161186C16.4202 -3.1868e-05 16.1837 -1.60782e-05 15.9263 1.11719e-06H10Z"
        fill="currentColor"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M18.25 9.75H10V18L15.9262 18C16.1837 18 16.4202 18 16.6179 17.9839C16.831 17.9665 17.0642 17.9266 17.2945 17.8093C17.6238 17.6415 17.8915 17.3738 18.0593 17.0445C18.1766 16.8142 18.2165 16.581 18.2339 16.3679C18.25 16.1702 18.25 15.9337 18.25 15.6762L18.25 9.75Z"
        fill="currentColor"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default GridLargeIcon;
