import { FundingRoundFragment, InvestorFragment } from '__generated__/graphql';
import { SquareArrowTopRightIcon } from 'assets/harmonic-icons';
import { Badge } from 'common/components';
import InvestorCard from 'components/common/CompanyCard/InvestorCard';
import { dayjsExt } from 'config/dayjs';
import Button from 'harmonic-components/Button/Button';
import { getFundingTypeDisplayName } from 'interfaces/SearchModel/Search';
import { useState } from 'react';
import { DATE_FORMAT } from 'utils/constants';
import {
  InvestorFundingRoundMapValue,
  getFundingStageBadgeColor
} from 'utils/funding';
import { truncateMoneyValue } from 'utils/utilities';

interface CompanySingleRbrProps {
  fundingRound?: FundingRoundFragment | null;
  investorsFundingRoundMap: Record<number, InvestorFundingRoundMapValue[]>;
  investorsMap: Record<string, InvestorFragment>;
  limit?: number;
}
const CompanySingleRbr: React.FC<CompanySingleRbrProps> = ({
  fundingRound,
  investorsFundingRoundMap,
  investorsMap,
  limit = 4
}) => {
  const [showAllMode, setShowAllMode] = useState(false);

  const fundingRoundType = getFundingTypeDisplayName(
    fundingRound?.fundingRoundType
  );
  const fundingRoundDate = fundingRound?.announcementDate
    ? dayjsExt.utc(fundingRound?.announcementDate).format(DATE_FORMAT)
    : 'Unknown';
  const fundingAmount =
    fundingRound?.fundingAmount > 0
      ? truncateMoneyValue(fundingRound?.fundingAmount)
      : 'Undisclosed';

  const postMoneyValuationTitle = 'Post-Money';
  const postMoneyValuation =
    fundingRound?.postMoneyValuation > 0
      ? truncateMoneyValue(fundingRound?.postMoneyValuation)
      : undefined;

  const toggleShowAllMode = () => {
    setShowAllMode(!showAllMode);
  };

  const sourceUrl = fundingRound?.sourceUrl;

  // sort function mutates the original array. Hence, used this spread operator approach
  const sortedInvestors = [...(fundingRound?.investors ?? [])].sort((a, b) => {
    if (a?.isLead && !b?.isLead) return -1;
    if (!a?.isLead && b?.isLead) return 1;
    return 0;
  });

  const investors = showAllMode
    ? sortedInvestors
    : sortedInvestors.slice(0, limit);
  const isMoreThanLimit = sortedInvestors.length > limit;

  return (
    <div key={fundingRoundType}>
      <div className="flex justify-between items-end">
        <div className="flex flex-col gap-g30">
          <div className="flex gap-g50 items-center">
            <Badge
              label={fundingRoundType}
              color={getFundingStageBadgeColor(fundingRoundType)}
              size="large"
              intensity="subtle"
            />
            <p className="text-content-weak typography-label">
              {fundingRoundDate}
            </p>
          </div>

          <p className="text-content-strong typography-title-medium">
            {fundingAmount}
          </p>

          {postMoneyValuation && (
            <Badge
              label={`${postMoneyValuation} ${postMoneyValuationTitle}`}
              color="expressive-7"
              size="large"
              dataTestId="CompanySingleRbr-PostMoneyValuation-Value"
            />
          )}
        </div>
        {sourceUrl && (
          <div>
            <Button
              onClick={() => window.open(sourceUrl)}
              label="Read press"
              type="secondary"
              emphasis="high"
              trailingIcon={SquareArrowTopRightIcon}
              dataTestId="FundingSourceUrl"
            />
          </div>
        )}
      </div>
      <div className="my-g80 grid sm:grid-cols-2 gap-g40">
        {investors.map((investor) => {
          const investorName = investor?.investorName;
          const logoUrl = investorName
            ? investorsMap[investorName]?.logoUrl
            : '';
          const isLead = investor?.isLead;
          const entityUrn = investor?.investorUrn
            ? investor.investorUrn
            : investor?.entityUrn;

          return (
            <InvestorCard
              key={investorName}
              currentRound={fundingRoundType}
              investorName={investorName as string}
              logoUrl={logoUrl as string}
              showLeadChip={isLead as boolean}
              entityUrn={entityUrn as string}
              dataTestId="CompanyFinancing-Rbr-Investor"
              rounds={investorsFundingRoundMap?.[investor?.entityUrn] ?? []}
            />
          );
        })}
      </div>
      {isMoreThanLimit && (
        <div className="flex justify-center">
          <Button
            label={showAllMode ? 'Load less' : 'Load more'}
            type="secondary"
            emphasis="low"
            onClick={toggleShowAllMode}
            dataTestId="CompanySingleRbr-Toggle-Btn"
          />
        </div>
      )}
    </div>
  );
};

export default CompanySingleRbr;
