import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const ZapIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
      fill="#3A74A6"
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M9.33245 1.57049C9.33245 0.752743 8.27737 0.424206 7.81311 1.09738L2.11458 9.36025C1.73332 9.91307 2.12905 10.6667 2.80059 10.6667H6.16579C6.44193 10.6667 6.66579 10.8906 6.66579 11.1667V14.4296C6.66579 15.2473 7.72087 15.5759 8.18513 14.9027L13.8837 6.6398C14.2649 6.08699 13.8692 5.33336 13.1977 5.33336H9.83245C9.55631 5.33336 9.33245 5.1095 9.33245 4.83336V1.57049Z"
      />
    </svg>
  );
};

export default ZapIcon;
