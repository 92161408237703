import { IDashboardURNParams } from 'interfaces/Dashboard';
import { EntityListType } from 'interfaces/SearchModel/Search';
import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

export enum DashboardLocation {
  DISCOVER_DASHBOARD = 'discover_dashboard',
  COMPANY_SEARCH = 'company_search',
  PEOPLE_SEARCH = 'people_search',
  COMPANY_LIST = 'company_list',
  PERSON_LIST = 'person_list',
  PERSON_PROFILE = 'person_profile',
  COMPANY_PROFILE = 'company_profile',
  INVESTOR_PROFILE = 'investor_profile',
  LISTS = 'lists',
  SEARCHES = 'searches'
}

export enum EntityType {
  COMPANY = 'company',
  PERSON = 'person',
  INVESTOR = 'investor'
}

export interface UseDashboardLocationResponse {
  location?: DashboardLocation;
  urn?: string;
  entityType?: EntityType;
}

const useDashboardLocation = (): UseDashboardLocationResponse => {
  const params = useParams<IDashboardURNParams>();
  const urn = useMemo(() => params['*']?.match(/urn:(.*)/)?.[0], [params]);

  const { pathname } = useLocation();
  let location: DashboardLocation | undefined;
  if (pathname.includes('/dashboard/companies')) {
    location = DashboardLocation.COMPANY_SEARCH;
  } else if (pathname.includes('/dashboard/people_watchlist')) {
    location = DashboardLocation.PERSON_LIST;
  } else if (pathname.includes('/dashboard/people')) {
    location = DashboardLocation.PEOPLE_SEARCH;
  } else if (pathname.includes('/dashboard/watchlist')) {
    location = DashboardLocation.COMPANY_LIST;
  } else if (pathname.includes('/dashboard/company')) {
    location = DashboardLocation.COMPANY_PROFILE;
  } else if (pathname.includes('/dashboard/person')) {
    location = DashboardLocation.PERSON_PROFILE;
  } else if (pathname.includes('/dashboard/investor')) {
    location = DashboardLocation.INVESTOR_PROFILE;
  } else if (pathname.includes('/dashboard/lists')) {
    location = DashboardLocation.LISTS;
  } else if (pathname.includes('/dashboard/searches')) {
    location = DashboardLocation.SEARCHES;
  }

  return {
    location,
    urn,
    entityType: location?.includes('company')
      ? EntityType.COMPANY
      : EntityType.PERSON
  };
};

const getEntityListTypeFromDashboardLocation = (
  location?: DashboardLocation
): EntityListType => {
  switch (location) {
    case DashboardLocation.COMPANY_LIST:
      return EntityListType.COMPANY_WATCHLIST;
    case DashboardLocation.PERSON_LIST:
      return EntityListType.PEOPLE_WATCHLIST;
    case DashboardLocation.COMPANY_SEARCH:
      return EntityListType.COMPANY_SAVED_SEARCH;
    case DashboardLocation.PEOPLE_SEARCH:
      return EntityListType.PEOPLE_SAVED_SEARCH;
    default:
      throw new Error(`Invalid dashboard location: ${location}`);
  }
};

export default useDashboardLocation;
