export enum SocialMetricsType {
  WEB_TRAFFIC = 'WEB_TRAFFIC',
  FACEBOOK_LIKES = 'FACEBOOK_LIKES',
  TWITTER_FOLLOWERS = 'TWITTER_FOLLOWERS',
  INSTAGRAM_FOLLOWERS = 'INSTAGRAM_FOLLOWERS',
  LINKEDIN_FOLLOWERS = 'LINKEDIN_FOLLOWERS'
}

export enum HeadcountMetricsType {
  CORRECTED_HEADCOUNT = 'CORRECTED_HEADCOUNT',
  LINKEDIN_HEADCOUNT = 'LINKEDIN_HEADCOUNT'
}

export enum InitialScrollToSection {
  SOCIALS_HEADCOUNT = 'SOCIALS_HEADCOUNT'
}
