import { gql, useQuery } from '@apollo/client';
import {
  GetCompanyContactsV2Query,
  GetCompanyContactsV2QueryVariables
} from '__generated__/graphql';
import Paper from 'components/common/Paper';
import Email from 'harmonic-components/Email/Email';
import { compact, uniq } from 'lodash';
import React from 'react';
import { formatPhoneNumber } from 'utils/utilities';

type CompanyContactProps = {
  companyId: number;
};

export const getCompanyContacts = gql`
  query GetCompanyContactsV2($id: Int!) {
    getCompanyById(id: $id) {
      id
      contact {
        emails
        execEmails
        phoneNumbers
      }
    }
  }
`;

const CompanyContactContentLoader: React.FC = () => {
  return (
    <div
      data-testid="CompanyContact-Loading"
      className="grid grid-cols-3 gap-4 w-full py-6"
    >
      <div className="h-20 w-full col-span-2" />
      <div className="h-20 w-full col-span-1" />
    </div>
  );
};

const CompanyContactEmailsPhone: React.FC<CompanyContactProps> = ({
  companyId
}) => {
  const { data } = useQuery<
    GetCompanyContactsV2Query,
    GetCompanyContactsV2QueryVariables
  >(getCompanyContacts, {
    variables: { id: companyId },
    fetchPolicy: 'cache-only'
  });

  const companyContact = data?.getCompanyById?.contact;
  const companyEmails = uniq(compact(companyContact?.emails));
  const phoneNumbers = uniq(
    compact(
      companyContact?.phoneNumbers?.map((number) => formatPhoneNumber(number))
    )
  );
  const execEmails = uniq(compact(companyContact?.execEmails));

  const renderCompanyEmails = (emails: string[]) => {
    if (emails.length === 0) {
      return null;
    }
    return (
      <div
        data-testid="CompanyContact-Company-Emails"
        className="flex flex-col gap-g40"
      >
        <p className="text-content-strong typography-label-large-strong">
          Company emails
        </p>
        {companyEmails.map((email, index) => {
          return (
            <div key={index}>
              <Email email={email} />
            </div>
          );
        })}
      </div>
    );
  };

  const renderExecEmails = (execEmails: string[]) => {
    if (execEmails.length === 0) {
      return null;
    }
    return (
      <div
        data-testid="CompanyContact-Exec-Emails"
        className="flex flex-col gap-g40"
      >
        <p
          data-testid="CompanyContact-Exec-Email-Item"
          className="text-content-strong typography-label-large-strong"
        >
          Executive emails
        </p>

        {execEmails.map((email, index) => {
          return (
            <div key={index}>
              <Email
                dataTestId="CompanyContact-Company-Email-Item"
                email={email}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const renderEmails = (emails: string[], execEmails: string[]) => {
    if (emails.length === 0 && execEmails.length === 0) {
      return null;
    }
    return (
      <div data-testid="CompanyContact-All-Emails" className="col-span-2">
        <Paper displayShadow={false}>
          <div className="flex flex-col gap-g80">
            {renderExecEmails(execEmails)}
            {renderCompanyEmails(emails)}
          </div>
        </Paper>
      </div>
    );
  };

  const renderPhoneNumbers = (phoneNumbers: string[]) => {
    if (phoneNumbers.length === 0) {
      return null;
    }
    return (
      <div>
        <Paper displayShadow={false}>
          <div
            data-testid="CompanyContact-Phone-Numbers"
            className="flex flex-col gap-g40"
          >
            <p
              data-testid="CompanyContact-Phone-Number-Item"
              className="text-content-strong typography-label-large-strong"
            >
              Phone numbers
            </p>
            {phoneNumbers.map((phoneNumber, index) => {
              return (
                <p
                  className="typography-label text-content-default"
                  data-testid={`CompanyContact-phoneNumber-${phoneNumber}`}
                  key={index}
                >
                  {phoneNumber}
                </p>
              );
            })}
          </div>
        </Paper>
      </div>
    );
  };

  return (
    <div
      data-testid="CompanyContact"
      className="flex flex-col justify-start items-start gap-g110 w-full"
    >
      <div className="w-full">
        <h1 className="typography-title-medium text-content-title pb-p70 pt-p20 border-b border-solid border-border">
          Contact
        </h1>
        {!data ? (
          <CompanyContactContentLoader />
        ) : (
          <div className="py-6 w-full">
            {companyEmails.length === 0 &&
            execEmails.length === 0 &&
            phoneNumbers.length === 0 ? (
              <div className="typography-label">No contact information.</div>
            ) : (
              <div
                data-testid="CompanyContact-All-Contact-Info"
                className="grid grid-cols-3 gap-g80 w-full"
              >
                {renderEmails(companyEmails, execEmails)}
                {renderPhoneNumbers(phoneNumbers)}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanyContactEmailsPhone;
