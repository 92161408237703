import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const DragHandleIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={classNames(
        applyCurrentColor && 'fill-current stroke-current',
        className
      )}
      fill="#B8BECB"
      stroke="#B8BECB"
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M5.5 3.83329C5.5 3.4651 5.79848 3.16663 6.16667 3.16663C6.53486 3.16663 6.83333 3.4651 6.83333 3.83329C6.83333 4.20148 6.53486 4.49996 6.16667 4.49996C5.79848 4.49996 5.5 4.20148 5.5 3.83329ZM9.16667 3.83329C9.16667 3.4651 9.46514 3.16663 9.83333 3.16663C10.2015 3.16663 10.5 3.4651 10.5 3.83329C10.5 4.20148 10.2015 4.49996 9.83333 4.49996C9.46514 4.49996 9.16667 4.20148 9.16667 3.83329ZM5.5 12.1666C5.5 11.7984 5.79848 11.5 6.16667 11.5C6.53486 11.5 6.83333 11.7984 6.83333 12.1666C6.83333 12.5348 6.53486 12.8333 6.16667 12.8333C5.79848 12.8333 5.5 12.5348 5.5 12.1666ZM9.16667 12.1666C9.16667 11.7984 9.46514 11.5 9.83333 11.5C10.2015 11.5 10.5 11.7984 10.5 12.1666C10.5 12.5348 10.2015 12.8333 9.83333 12.8333C9.46514 12.8333 9.16667 12.5348 9.16667 12.1666ZM5.5 7.93329C5.5 7.5651 5.79848 7.26663 6.16667 7.26663C6.53486 7.26663 6.83333 7.5651 6.83333 7.93329V7.99996C6.83333 8.36815 6.53486 8.66663 6.16667 8.66663C5.79848 8.66663 5.5 8.36815 5.5 7.99996V7.93329ZM9.16667 7.93329C9.16667 7.5651 9.46514 7.26663 9.83333 7.26663C10.2015 7.26663 10.5 7.5651 10.5 7.93329V7.99996C10.5 8.36815 10.2015 8.66663 9.83333 8.66663C9.46514 8.66663 9.16667 8.36815 9.16667 7.99996V7.93329Z"
      />
    </svg>
  );
};

export default DragHandleIcon;
