import { useQuery } from '@apollo/client';

import {
  ConnectionIntegrationType,
  GetCompanyUserConnectionsWithOverviewAndEventsQuery,
  GetCompanyUserConnectionsWithOverviewAndEventsQueryVariables,
  GetCurrentUserQuery,
  UserConnection
} from '__generated__/graphql';
import UserConnectionsTooltip from 'components/common/UserConnectionsTooltip';
import useFlags from 'hooks/useFlags';
import { getCompanyUserConnectionsWithOverviewAndEvents } from 'queries/getCompanyUserConnections';
import { GET_CURRENT_USER } from 'queries/getCurrentUser';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sortCorrespondencesByDateConnectedPredicate } from 'utils/company';
import { COMPANIES_ROUTE, DASHBOARD_ROUTE, SPLITS } from 'utils/constants';
import { getGroupedCompanyUserConnections } from 'utils/userConnections';
import CompanyContactEmailsPhone from '../CompanyContact/CompanyContactEmailsPhone';
import { PersonWithConnections } from '../CompanyContact/UserConnectionsList';
import IntegrateLinkedinAndGmail from './IntegrateLinkedinAndGmail';
import PersonConnectionCard from './PersonConnectionCard';

const PersonConnectionsLoading = () => {
  return (
    <div
      data-testid="PersonConnectionsLoading"
      className="mt-4 flex flex-col gap-4"
    >
      <div className="h-64 w-full bg-slate-200 animate-pulse rounded-md" />
      <div className="h-64 w-full bg-slate-200 animate-pulse rounded-md" />
      <div className="h-64 w-full bg-slate-200 animate-pulse rounded-md" />
    </div>
  );
};

interface LoadMoreProps {
  onClick: () => void;
}

const LoadMore: React.FC<LoadMoreProps> = ({ onClick }) => {
  return (
    <button className="text-content-headerGrayAlt text-base" onClick={onClick}>
      Load More
    </button>
  );
};
interface CompanyConnectionsProps {
  companyId: number;
}
const CompanyConnections: React.FC<CompanyConnectionsProps> = ({
  companyId
}) => {
  const enableCompanyEngagementTab = useFlags(
    SPLITS.enableCompanyEngagementTab
  );
  const navigate = useNavigate();

  if (!enableCompanyEngagementTab.enabled) {
    navigate(DASHBOARD_ROUTE + COMPANIES_ROUTE);
  }

  const [numVisibleConnections, setNumVisibleConnections] = useState(5);

  const { data, loading: userConnectionsLoading } = useQuery<
    GetCompanyUserConnectionsWithOverviewAndEventsQuery,
    GetCompanyUserConnectionsWithOverviewAndEventsQueryVariables
  >(getCompanyUserConnectionsWithOverviewAndEvents, {
    variables: { id: companyId },
    fetchPolicy: 'cache-first',
    skip: !enableCompanyEngagementTab.enabled
  });

  const { data: userData, loading: currentUserLoading } =
    useQuery<GetCurrentUserQuery>(GET_CURRENT_USER, {
      fetchPolicy: 'cache-first'
    });

  const integrationStatus =
    userData?.getCurrentUser?.connectionsIntegrationStatus;

  const isLinkedinIntegrated =
    integrationStatus?.find(
      (integ) => integ?.type === ConnectionIntegrationType.LINKEDIN
    )?.isEnabled ?? false;
  const isGmailIntegrated =
    integrationStatus?.find(
      (integ) => integ?.type === ConnectionIntegrationType.GOOGLE
    )?.isEnabled ?? false;

  const isLoading = userConnectionsLoading || currentUserLoading;
  const companyUserConnections = data?.getCompanyById?.userConnections;
  const peopleWithConnections: PersonWithConnections[] = useMemo(
    () =>
      getGroupedCompanyUserConnections(
        companyUserConnections as UserConnection[],
        companyId
      ),
    [companyUserConnections, companyId]
  );

  const displayIntegrationflow = !isLinkedinIntegrated || !isGmailIntegrated;
  const userConnectionsExist = peopleWithConnections.length > 0;

  return (
    <div className="flex flex-col gap-g80 pb-p100 mb-p100">
      {displayIntegrationflow && !isLoading && (
        <IntegrateLinkedinAndGmail
          isGmailIntegrated={isGmailIntegrated}
          isLinkedinIntegrated={isLinkedinIntegrated}
          userConnectionsExist={userConnectionsExist}
        />
      )}

      <div className="flex flex-row justify-between border-solid border-b border-border">
        <p className="py-p60 typography-title-medium text-content-title flex gap-g30">
          In network <UserConnectionsTooltip />
        </p>

        {!isLoading && !displayIntegrationflow && (
          <div data-testid="ShowIntegrateButtonWithHeader">
            {/* Both are integrated, show "Connected" indicators */}
            <IntegrateLinkedinAndGmail
              isGmailIntegrated={isGmailIntegrated}
              isLinkedinIntegrated={isLinkedinIntegrated}
              userConnectionsExist={userConnectionsExist}
            />
          </div>
        )}
      </div>

      {!isLoading && !userConnectionsExist ? (
        <p className="typography-label-default-default text-int-fill-secondary-onDisabled mb-g80">
          <span data-testid="No-Connections-Exist-Text">
            Not in team network.
          </span>
        </p>
      ) : !isLoading && userConnectionsExist ? (
        <div className="py-p50 flex flex-col gap-g50">
          {peopleWithConnections
            .sort(sortCorrespondencesByDateConnectedPredicate)
            .slice(0, numVisibleConnections)
            .map((personWithConnections) => {
              return (
                <PersonConnectionCard
                  personWithConnections={personWithConnections}
                  currentCompanyId={companyId}
                  key={personWithConnections?.person?.id}
                />
              );
            })}
          {peopleWithConnections.length > numVisibleConnections && (
            <LoadMore
              onClick={() => setNumVisibleConnections((prev) => prev + 10)}
            />
          )}
        </div>
      ) : (
        <PersonConnectionsLoading />
      )}

      <CompanyContactEmailsPhone companyId={companyId} />
    </div>
  );
};

export default CompanyConnections;
