import classNames from 'classnames';
import { IconProps } from './type';

const SelectionContentIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="#F9FAFB"
      stroke="#F9FAFB"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(
        applyCurrentColor && 'fill-current stroke-current',
        className
      )}
    >
      <g opacity="0.4">
        <circle cx="6" cy="6" r="5.25" strokeWidth="1.5" />
      </g>
      <circle cx="6" cy="6" r="3" fill="" />
    </svg>
  );
};
export default SelectionContentIcon;
