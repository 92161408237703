import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface PersistedZustandStoreState {
  localFlags: SplitIO.TreatmentsWithConfig;
  toggleLocalFlagTreatment: (flagName: string) => void;
  setLocalFlags: (flags: SplitIO.TreatmentsWithConfig) => void;
  globalEnableHotkeys: boolean;
  exploreCompaniesVisibleColumns: string[];
  explorePeopleVisibleColumns: string[];
  editStore<Key extends Exclude<keyof PersistedZustandStoreState, 'editStore'>>(
    key: Key,
    payload: PersistedZustandStoreState[Key]
  ): void;
  reset: () => void;
}

const initialState = {
  localFlags: {},
  globalEnableHotkeys: false,
  exploreCompaniesVisibleColumns: [],
  explorePeopleVisibleColumns: []
};

const usePersistedZustandStore = create(
  persist<PersistedZustandStoreState>(
    (set, get) => ({
      toggleLocalFlagTreatment: (flagName) => {
        set(() => ({
          localFlags: {
            ...get().localFlags,
            [flagName]: {
              treatment:
                get().localFlags[flagName]?.treatment === 'on' ? 'off' : 'on',
              config: get().localFlags[flagName]?.config
            }
          }
        }));
      },
      setLocalFlags: (flags) => {
        set(() => ({
          localFlags: flags
        }));
      },
      editStore: (key, payload) =>
        set(() => ({
          ...get(),
          [key]: payload
        })),
      ...initialState,
      reset: () => set(() => ({ ...get(), ...initialState }))
    }),
    {
      name: `persisted-global-store`
    }
  )
);

export default usePersistedZustandStore;
