import React, { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import {
  GetCompanyUserConnectionsV2Query,
  GetCompanyUserConnectionsV2QueryVariables,
  UserConnection
} from '__generated__/graphql';
import UserConnectionsTooltip from 'components/common/UserConnectionsTooltip';
import { isNil } from 'lodash';
import getCompanyUserConnections from 'queries/getCompanyUserConnections';
import { getGroupedCompanyUserConnections } from 'utils/userConnections';
import UserConnectionsList, {
  PersonWithConnections
} from './UserConnectionsList';

type CompanyInNetworkProps = {
  companyId: number;
};

const CompanyInNetworkContentLoader: React.FC = () => {
  return (
    <div
      data-testid="CompanyInNetwork-Loading"
      className="w-full flex flex-col space-y-2 my-4"
    >
      <div className="h-24 w-full bg-slate-200 animate-pulse" />
      <div className="h-24 w-full bg-slate-200 animate-pulse" />
      <div className="h-24 w-full bg-slate-200 animate-pulse" />
    </div>
  );
};
const CompanyInNetwork: React.FC<CompanyInNetworkProps> = ({ companyId }) => {
  const { data } = useQuery<
    GetCompanyUserConnectionsV2Query,
    GetCompanyUserConnectionsV2QueryVariables
  >(getCompanyUserConnections, {
    variables: { id: companyId },
    fetchPolicy: 'cache-first'
  });

  const companyUserConnections = data?.getCompanyById?.userConnections;
  const peopleWithConnections: PersonWithConnections[] = useMemo(
    () =>
      getGroupedCompanyUserConnections(
        companyUserConnections as UserConnection[],
        companyId
      ).filter((person) => !isNil(person.person?.id)),
    [companyUserConnections, companyId]
  );

  return (
    <div
      data-testid="CompanyInNetwork"
      className="flex flex-col justify-start items-start w-full"
    >
      <div className="flex items-center py-p60 gap-g30 w-full border-b border-solid border-border">
        <h1 className="typography-title-medium text-content-title">
          In network
        </h1>
        <UserConnectionsTooltip />
      </div>
      {!data ? (
        <CompanyInNetworkContentLoader />
      ) : (
        <div className="py-6 w-full">
          <div
            data-testid="CompanyInNetwork-All-Connections"
            className="w-full"
          >
            {companyUserConnections?.length == 0 ? (
              <p className="text-content-default typography-label">
                No people in network.
              </p>
            ) : (
              <UserConnectionsList
                loading={isNil(data)}
                peopleWithConnections={peopleWithConnections}
                currentCompanyId={companyId}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanyInNetwork;
