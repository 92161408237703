import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const CheckIcon: React.FC<IconProps> = ({ className }) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      className={classNames('stroke-current', className)}
      stroke="#1A1D23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M2.33333 10.0625L6.5 13.5L14.6667 2.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckIcon;
