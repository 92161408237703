import classNames from 'classnames';
import { IconProps } from './type';

const BagIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#493AA6"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M4.33496 4.66667C4.6111 4.66667 4.83496 4.44281 4.83496 4.16667V3.16667C4.83496 2.52233 5.3573 2 6.00163 2H10.0016C10.646 2 11.1683 2.52233 11.1683 3.16667V4.16667C11.1683 4.44281 11.3922 4.66667 11.6683 4.66667H13.5002C14.1445 4.66667 14.6668 5.189 14.6668 5.83333V7.5C14.6668 7.77614 14.443 8 14.1668 8H1.8335C1.55735 8 1.3335 7.77614 1.3335 7.5V5.83333C1.3335 5.189 1.85583 4.66667 2.50016 4.66667H4.33496ZM5.83496 3.16667C5.83496 3.07462 5.90958 3 6.00163 3H10.0016C10.0937 3 10.1683 3.07462 10.1683 3.16667V4.16667C10.1683 4.44281 9.94444 4.66667 9.66829 4.66667H6.33496C6.05882 4.66667 5.83496 4.44281 5.83496 4.16667V3.16667Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M1.3335 12.8333V9.5C1.3335 9.22386 1.55735 9 1.8335 9H7.00104C7.27701 9 7.5008 9.22359 7.50104 9.49956L7.50163 10.1671C7.50187 10.4432 7.72592 10.6669 8.00207 10.6667C8.27821 10.6664 8.50187 10.4424 8.50163 10.1662L8.50104 9.50044C8.5008 9.22413 8.72473 9 9.00104 9H14.1668C14.443 9 14.6668 9.22386 14.6668 9.5V12.8333C14.6668 13.4777 14.1445 14 13.5002 14H2.50016C1.85583 14 1.3335 13.4777 1.3335 12.8333Z"
      />
    </svg>
  );
};

export default BagIcon;
