import {
  BagIcon,
  BankIcon,
  CelebrateIcon,
  DollarIcon,
  GlassesIcon,
  GraduationCapIcon,
  ImageAvatarSparklingIcon,
  PeopleCopyIcon,
  ToolboxIcon
} from 'assets/harmonic-icons';
import { Badge, Tooltip } from 'common/components';
import { useState } from 'react';
import { useOutsideClick } from 'utils/hooks';

interface HighlightsChipProps {
  highlights: { category: string; tooltipText?: string }[];
  limit?: number;
}
type IconComponent = React.FC<React.SVGProps<SVGSVGElement>>;
const HighlightTextToIconMap: Record<string, IconComponent> = {
  'Elite Industry Experience': BagIcon,
  'Major Tech Company Experience': BagIcon,
  'Top Company Alum': BagIcon,
  'Deep Technical Background': BagIcon,
  'Legacy Tech Company Experience': BagIcon,
  'Major Research Institution Experience': GraduationCapIcon,
  'Top University': GraduationCapIcon,
  'Top 10 MBAs': GraduationCapIcon,
  'HBCU Alum': GraduationCapIcon,
  'Prior VC Backed Founder': ImageAvatarSparklingIcon,
  'Prior VC Backed Executive': ImageAvatarSparklingIcon,
  'Prior Exit': ImageAvatarSparklingIcon,
  'Seasoned Operator': ImageAvatarSparklingIcon,
  'Seasoned Adviser': ImageAvatarSparklingIcon,
  'Seasoned Founder': ImageAvatarSparklingIcon,
  'Seasoned Executive': ImageAvatarSparklingIcon,
  'Strong Team Growth': ImageAvatarSparklingIcon,
  'Stealth Founder': GlassesIcon,
  'Founder Turned Operator': PeopleCopyIcon,
  'Jack of All Trades': ToolboxIcon,
  'Free Agent': CelebrateIcon,
  'Venture Background': BankIcon,
  'Venture Backed': BankIcon,
  'YC Backed Founder': ImageAvatarSparklingIcon
};

interface SingleHighlightProps {
  highlight: string;
  tooltipText?: string;
  icon?: IconComponent;
}

export const SingleHighlight: React.FC<SingleHighlightProps> = ({
  highlight,
  tooltipText,
  icon
}) => {
  const HighlightIcon = icon;

  const chipComponent = (
    <Badge
      key={highlight}
      label={highlight}
      leadingIcon={HighlightIcon}
      color="expressive-32"
      size="large"
      dataTestId="SingleHighlight-Badge"
    />
  );
  if (!tooltipText) return chipComponent;
  return (
    <Tooltip title={tooltipText ?? ''} interactive>
      {chipComponent}
    </Tooltip>
  );
};

export const getHighlightIcon = (highlight: string) => {
  let HighlightIcon = HighlightTextToIconMap?.[highlight];
  if (highlight.startsWith('$')) {
    HighlightIcon = DollarIcon;
  }
  return HighlightIcon;
};

const HighlightsChip: React.FC<HighlightsChipProps> = ({
  highlights,
  limit = 3
}) => {
  const [isMoreHighlightsDropdownOpen, setIsMoreHighlightsDropdownOpen] =
    useState(false);
  const totalHighlights = highlights.length;
  const highlightsDropdownRef = useOutsideClick(() =>
    setIsMoreHighlightsDropdownOpen(false)
  );

  return (
    <div className="flex gap-1 flex-wrap items-center">
      {highlights.slice(0, limit).map((highlight, index) => {
        const highlightCategory = highlight.category;
        const HighlightIcon = getHighlightIcon(highlightCategory);
        return (
          <Badge
            size="large"
            color="expressive-32"
            leadingIcon={HighlightIcon}
            label={highlightCategory}
            key={index}
          />
        );
      })}
      {totalHighlights > limit && (
        <div className="relative">
          <button
            onClick={() => setIsMoreHighlightsDropdownOpen(true)}
            className="text-content-headerGray typography-label"
          >
            +{totalHighlights - limit}
          </button>
          {isMoreHighlightsDropdownOpen && (
            <div
              ref={highlightsDropdownRef}
              data-testid="MoreHighlights-Dropdown"
              className="absolute bg-white flex-nowrap p-2 min-w-48 border border-solid border-modal-mainStroke shadow-search rounded-md flex flex-col gap-1.5"
            >
              {highlights.slice(limit).map((highlight, index) => {
                const highlightCategory = highlight?.category;
                const HighlightIcon = getHighlightIcon(highlightCategory);
                return (
                  <Badge
                    key={index}
                    size="large"
                    color="expressive-32"
                    leadingIcon={HighlightIcon}
                    label={highlightCategory}
                    intensity="highlight"
                  />
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default HighlightsChip;
