import { TeamRole } from '__generated__/graphql';
import { AppThunk } from 'store';
import {
  LOGOUT_USER,
  NEED_VERIFICATION,
  SET_USER,
  SET_USER_API_KEY,
  SET_USER_ERROR,
  SET_USER_LOADING,
  SET_USER_METADATA,
  SET_USER_ROLE,
  SET_USER_STATUS,
  SET_USER_SUCCESS
} from '../actions/types/actionTypes';

// User metadata from Firebase
interface FirebaseUserToken {
  email: string;
  user_id: string;
  email_verified: boolean;
  createdAt?: number;
  displayName: string;
}

export enum UserStatusType {
  ACTIVE = 'ACTIVE',
  NOT_ASSIGNED_TO_TEAM = 'NOT_ASSIGNED_TO_TEAM',
  PAUSED = 'PAUSED',
  PENDING_PILOT_AGREEMENT_ACCEPTANCE = 'PENDING_PILOT_AGREEMENT_ACCEPTANCE',
  NO_CONSOLE_ACCESS = 'NO_CONSOLE_ACCESS'
}

// User metadata from backend DB
interface HarmonicUser {
  user_id: string;
  user_urn: string;
  customer_urn: string;
  customer_name: string;
  settings: { has_completed_onboarding: boolean };
}

interface User {
  token?: FirebaseUserToken;
  apiKey?: string;
  user_metadata?: HarmonicUser;
}

export interface LoginResponse extends User {
  status: 'ok' | 'error';
  error?: string;
}

export interface UserOutput {
  email: string;
  name: string;
  entity_urn: string;
  company_watchlist: string[];
  saved_searches: string[];
  customer: string;
  customer_name: string;
  firebase_token: string;
  customer_apikey: string;
  apikey: string;
  role: string;
  status: string;
  settings: { has_completed_onboarding: boolean };
  max_user_seats?: number;
  max_lite_user_seats?: number;
}
export interface SigninData {
  email: string;
  password: string;
}

export interface ResetPasswordData {
  password: string;
}

export interface IAuthState {
  user: FirebaseUserToken | null;
  userMetadata?: HarmonicUser;
  isAuthenticated: boolean;
  apiKey: string;
  loading: boolean;
  error: string;
  needVerification: boolean;
  status?: UserStatusType;
  role?: TeamRole;
  success: string;
}

export enum AuthErrorCodes {
  EMAIL_ALREADY_IN_USE = 'auth/email-already-in-use',
  INVALID_EMAIL = 'auth/invalid-email',
  USER_NOT_FOUND = 'auth/user-not-found',
  INVALID_PASSWORD = 'auth/wrong-password'
}

/*******************************************
          REDUX ACTIONS TYPES
*******************************************/

interface SetUserAction {
  type: typeof SET_USER;
  payload: FirebaseUserToken;
}

interface SetUserMetadataAction {
  type: typeof SET_USER_METADATA;
  payload: HarmonicUser;
}

interface SetLoadingAction {
  type: typeof SET_USER_LOADING;
  payload: boolean;
}

interface SetUserStatus {
  type: typeof SET_USER_STATUS;
  payload: UserStatusType;
}

interface SetUserRole {
  type: typeof SET_USER_ROLE;
  payload: TeamRole;
}

interface SignOutAction {
  type: typeof LOGOUT_USER;
}

interface SetErrorAction {
  type: typeof SET_USER_ERROR;
  payload: string;
}

interface NeedVerificationAction {
  type: typeof NEED_VERIFICATION;
}

interface SetSuccessAction {
  type: typeof SET_USER_SUCCESS;
  payload: string;
}

interface SetUserApiKey {
  type: typeof SET_USER_API_KEY;
  payload: string;
}

export type IAuthAction =
  | SetUserAction
  | SetUserMetadataAction
  | SetLoadingAction
  | SignOutAction
  | SetErrorAction
  | NeedVerificationAction
  | SetSuccessAction
  | SetUserStatus
  | SetUserApiKey
  | SetUserRole;

export enum LogoutReason {
  UserLoggedOut,
  SessionExpired
}

export type AuthThunkAction = AppThunk<IAuthAction>;
