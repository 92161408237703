import {
  CheckboxListCustomFieldValueFragment,
  CompanyListCustomFieldValueUpsertInput,
  CompanyListEntryNodeFragment,
  DateListCustomFieldMetadataFragment,
  DateListCustomFieldValueFormat,
  ListCustomFieldType,
  NumberListCustomFieldMetadataFragment,
  PeopleListCustomFieldValueUpsertInput,
  SelectListCustomFieldMetadataFragment,
  SelectListCustomFieldValueOption
} from '__generated__/graphql';
import { ICellEditorParams, ICellRendererParams } from 'ag-grid-community';
import {
  CompanyWatchlistGridContext,
  WatchlistGridTeamMember
} from 'components/common/ResultsWrapper/GridResultsView';
import Checkbox from 'harmonic-components/Checkbox/Checkbox';
import SingleDatePicker, {
  InputDateFormat
} from 'harmonic-components/DatePicker/SingleDatePicker';
import { ListVariant } from 'harmonic-components/ListItem/ListItem';
import Select from 'harmonic-components/Select/Select';
import SelectListItem from 'harmonic-components/Select/SelectListItem';
import Tag from 'harmonic-components/Tag/Tag';
import TruncatedList from 'harmonic-components/TruncatedList/TruncatedList';
import useTeamMembers from 'hooks/useTeamMembers';
import { MetadataValue } from 'interfaces/CustomField';
import { isNil } from 'lodash';
import React from 'react';
import { formatCustomFieldDate } from 'utils/custom-fields';
import { ColorShorthand } from 'utils/design';
import {
  formatNumberListCustomFieldValue,
  isNumberAndNaN,
  parseNumberListCustomFieldValue
} from 'utils/utilities';
import { getNthColor } from '../../../../utils/colors';
import { isValidUrl } from '../../../../utils/utilities';
import { colorOptions } from '../EditField/SelectOptions/EditOptionItem';
import { LoadingCellRenderer } from './CellRenderers';

interface ICellEditorReactComp {
  values: string[];
  getValue: () => string[];
  value: string;
}

export const CustomColumnSingleSelectCellEditor = React.forwardRef(
  (
    props: ICellEditorReactComp &
      ICellEditorParams &
      SelectListCustomFieldMetadataFragment,
    ref
  ) => {
    const [value, setValue] = React.useState(props.value);
    const [options, setOptions] = React.useState(props.options);
    const [searchTerm, setSearchTerm] = React.useState('');

    React.useImperativeHandle(ref, () => {
      return {
        getValue: () => {
          return value;
        }
      };
    });

    const width = props.columnApi.getColumn(props.colDef)?.getActualWidth();
    const filteredOptions = options.filter((option) =>
      option.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const gridContext = props.context as CompanyWatchlistGridContext;

    const handleUpdateOptions = async (newOption?: string) => {
      const newOptions = options.map(({ __typename, ...rest }) => rest);
      const color = getNthColor(colorOptions, newOptions.length);
      if (newOption) {
        newOptions.push({ name: newOption, color: color, urn: undefined });
      }

      if (props.colDef.headerName && gridContext.watchlistUrn) {
        const result = await gridContext.updateCustomField(
          {
            watchlistUrn: gridContext.watchlistUrn as string,
            customFieldUrn: props.colDef.field as string,
            name: props.colDef.headerName,
            fieldType: ListCustomFieldType.SINGLE_SELECT,
            metadata: newOptions as MetadataValue
          },
          {
            updateEditColumnsConfig: false
          }
        );

        const options = (
          result?.data?.updateCompanyWatchlistCustomField
            .metadata as SelectListCustomFieldMetadataFragment
        ).options;
        setOptions(options);
        setValue(options[options.length - 1].urn);
        setSearchTerm('');
      }
    };

    return (
      <div style={{ width }}>
        <div className="flex flex-col gap-g40 bg-surface-default">
          <Select
            onClose={() => {
              props.api.stopEditing();
            }}
            initialFocus
            alwaysOpen
            multiple
            filterable
            filterTerm={searchTerm}
            onFilterTermChange={setSearchTerm}
            selected={value ? [value] : undefined}
            getLabelFromValue={(value) => {
              return options.find((option) => option.urn === value)?.name ?? '';
            }}
            getTagColorFromValue={(value) =>
              (options.find((option) => option.urn === value)
                ?.color as ColorShorthand) || 'neutral'
            }
            dropdownMaxHeight="33vh"
            minHeight={77}
            onAddNewOption={handleUpdateOptions}
            onRemove={() => {
              setValue(null);
            }}
          >
            {filteredOptions.map((option) => {
              return (
                <SelectListItem
                  key={option.urn}
                  onClick={(e) => {
                    setValue(option.urn);
                    setSearchTerm('');
                    setTimeout(() => {
                      props.api.stopEditing();
                    }, 0);
                  }}
                  color={option.color as ColorShorthand}
                  variant={ListVariant.tag}
                  label={option.name}
                  value={option.urn}
                  selected={option.urn === value}
                />
              );
            })}
          </Select>
        </div>
      </div>
    );
  }
);

export const CustomColumnPersonSelectCellEditor = React.forwardRef(
  (props: ICellEditorReactComp & ICellEditorParams, ref) => {
    const { activeTeamMembers } = useTeamMembers({ fetchPolicy: 'cache-only' });
    const options =
      activeTeamMembers?.map((member) => ({
        urn: member?.user?.entityUrn as string,
        name: member?.user?.name as string
      })) ?? [];

    const [value, setValue] = React.useState<string[]>(props.value ?? []);

    const [searchTerm, setSearchTerm] = React.useState('');

    React.useImperativeHandle(ref, () => {
      return {
        getValue: () => {
          return value;
        }
      };
    });

    const width = props.columnApi.getColumn(props.colDef)?.getActualWidth();
    const filteredOptions = options
      .filter((option) =>
        option?.name?.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLocaleLowerCase())
      );

    const setAdditionalValue = (option: WatchlistGridTeamMember) => {
      if (value.includes(option.urn)) {
        setValue(value.filter((v) => v !== option.urn));
      } else {
        setValue([...value, option.urn]);
      }
      setSearchTerm('');
    };

    if (!activeTeamMembers || activeTeamMembers.length === 0) {
      return <LoadingCellRenderer />;
    }

    return (
      <div style={{ width }}>
        <div className="flex flex-col gap-g40 bg-surface-default">
          <Select
            onClose={() => {
              props.api.stopEditing();
            }}
            initialFocus
            alwaysOpen
            multiple
            filterable
            filterTerm={searchTerm}
            onFilterTermChange={setSearchTerm}
            selected={value}
            getLabelFromValue={(value) => {
              return options.find((option) => option.urn === value)?.name ?? '';
            }}
            getTagColorFromValue={(value) => {
              const valueIndex = options.findIndex(
                (option) => option.urn === value
              );
              const color =
                colorOptions[valueIndex % colorOptions.length]?.color;
              return color;
            }}
            dropdownMaxHeight="33vh"
            minHeight={77}
            onRemove={(val) => {
              setValue(value.filter((v) => v !== val));
            }}
          >
            {filteredOptions.map((option) => {
              const color =
                colorOptions[options.indexOf(option) % colorOptions.length]
                  ?.color;
              return (
                <SelectListItem
                  key={option.urn}
                  onClick={(e) => {
                    setAdditionalValue(option);
                  }}
                  color={color}
                  variant={ListVariant.tag}
                  label={option.name}
                  value={option.urn}
                  selected={value.includes(option.urn)}
                />
              );
            })}
          </Select>
        </div>
      </div>
    );
  }
);

export const CustomColumnMultiSelectCellEditor = React.forwardRef(
  (
    props: ICellEditorReactComp &
      ICellEditorParams &
      SelectListCustomFieldMetadataFragment,
    ref
  ) => {
    const [value, setValue] = React.useState<string[]>(props.value ?? []);
    const [options, setOptions] = React.useState(props.options);
    const [searchTerm, setSearchTerm] = React.useState('');
    const gridContext = props.context as CompanyWatchlistGridContext;

    React.useImperativeHandle(ref, () => {
      return {
        getValue: () => {
          return value;
        }
      };
    });

    const width = props.columnApi.getColumn(props.colDef)?.getActualWidth();
    const filteredOptions = options.filter((option) =>
      option.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const setAdditionalValue = (option: SelectListCustomFieldValueOption) => {
      if (value.includes(option.urn)) {
        setValue(value.filter((v) => v !== option.urn));
      } else {
        setValue([...value, option.urn]);
      }
      setSearchTerm('');
    };

    const handleUpdateOptions = async (newOption?: string) => {
      const newOptions = options.map(({ __typename, ...rest }) => rest);
      const color = getNthColor(colorOptions, newOptions.length);
      newOption
        ? newOptions.push({ name: newOption, color: color, urn: undefined })
        : '';

      if (props.colDef.headerName && gridContext.watchlistUrn) {
        const result = await gridContext.updateCustomField(
          {
            watchlistUrn: gridContext.watchlistUrn as string,
            customFieldUrn: props.colDef.field as string,
            name: props.colDef.headerName,
            fieldType: ListCustomFieldType.SINGLE_SELECT,
            metadata: newOptions as MetadataValue
          },
          {
            updateEditColumnsConfig: false
          }
        );

        const options = (
          result?.data?.updateCompanyWatchlistCustomField
            .metadata as SelectListCustomFieldMetadataFragment
        ).options;
        setOptions(options);
        setAdditionalValue(options[options.length - 1]);
        setSearchTerm('');
      }
    };

    return (
      <div style={{ width }}>
        <div className="flex flex-col gap-g40 bg-surface-default">
          <Select
            onClose={() => {
              props.api.stopEditing();
            }}
            initialFocus
            alwaysOpen
            multiple
            filterable
            filterTerm={searchTerm}
            onFilterTermChange={setSearchTerm}
            selected={value}
            getLabelFromValue={(value) => {
              return options.find((option) => option.urn === value)?.name ?? '';
            }}
            getTagColorFromValue={(value) =>
              (options.find((option) => option.urn === value)
                ?.color as ColorShorthand) || 'neutral'
            }
            dropdownMaxHeight="33vh"
            minHeight={77}
            onAddNewOption={handleUpdateOptions}
            onRemove={(val) => {
              setValue(value.filter((v) => v !== val));
            }}
          >
            {filteredOptions.map((option) => {
              return (
                <SelectListItem
                  key={option.urn}
                  onClick={(e) => {
                    setAdditionalValue(option);
                  }}
                  color={option.color as ColorShorthand}
                  variant={ListVariant.tag}
                  label={option.name}
                  value={option.urn}
                  selected={value.includes(option.urn)}
                />
              );
            })}
          </Select>
        </div>
      </div>
    );
  }
);

export const CustomColumnTextCellEditor = React.forwardRef(
  (props: ICellEditorReactComp & ICellEditorParams, ref) => {
    const [value, setValue] = React.useState(props.value);
    const refInput = React.useRef<HTMLTextAreaElement>(null);
    const width = props.columnApi.getColumn(props.colDef)?.getActualWidth();

    React.useEffect(() => {
      if (refInput.current) {
        refInput.current.focus();
        refInput.current.setSelectionRange(
          refInput.current.value.length,
          refInput.current.value.length
        );
      }
    }, [refInput]);

    React.useImperativeHandle(ref, () => {
      return {
        getValue: () => {
          return value;
        }
      };
    });

    return (
      <textarea
        onBlur={() => {
          refInput?.current?.focus();
        }}
        onFocus={(event) => {
          if (event.target) {
            event.target.scrollTop = event.target.scrollHeight;
          }
        }}
        autoFocus
        className="min-h-[77px] bg-surface-default p-p50 rounded-br30 border-2 border-solid border-int-outline-secondary-selected-enabled text-content-default typography-paragraph-default-default"
        onChange={(e) => setValue(e.target.value)}
        ref={refInput}
        value={value}
        style={{ width }}
      />
    );
  }
);

export const CustomColumnNumberCellEditor = React.forwardRef(
  (
    props: ICellEditorReactComp &
      ICellEditorParams &
      NumberListCustomFieldMetadataFragment,
    ref
  ) => {
    const num: number | undefined | null = props.value;
    const [value, setValue] = React.useState<string | undefined>(
      isNil(num) || isNumberAndNaN(num) ? undefined : num?.toString()
    );
    const refInput = React.useRef<HTMLTextAreaElement>(null);
    const width = props.columnApi.getColumn(props.colDef)?.getActualWidth();

    React.useEffect(() => {
      if (refInput.current) {
        refInput.current.focus();
        refInput.current.setSelectionRange(
          refInput.current.value.length,
          refInput.current.value.length
        );
      }
    }, [refInput]);

    React.useImperativeHandle(ref, () => {
      return {
        getValue: () => {
          return value;
        }
      };
    });

    const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setValue(parseNumberListCustomFieldValue(e.target.value));
    };

    const formattedNumber = formatNumberListCustomFieldValue(
      value,
      props.numberFormat,
      false
    );

    return (
      <textarea
        onBlur={() => {
          refInput?.current?.focus();
        }}
        autoFocus
        inputMode="decimal"
        className="min-h-[77px] resize-none bg-surface-default p-p50 rounded-br30 border-2 border-solid border-int-outline-secondary-selected-enabled text-content-default typography-paragraph-default-default"
        onChange={handleOnChange}
        ref={refInput}
        value={formattedNumber}
        style={{ width }}
      />
    );
  }
);

export const CustomColumnNumberCellRenderer = (
  props: ICellRendererParams<CompanyListEntryNodeFragment> &
    NumberListCustomFieldMetadataFragment
) => {
  const value = props.value as number | undefined;
  const formattedNumber = formatNumberListCustomFieldValue(
    value,
    props.numberFormat,
    true
  );

  return (
    <p className="typography-paragraph text-content-default">
      {formattedNumber}
    </p>
  );
};

export const CustomColumnDateCellEditor = React.forwardRef(
  (props: ICellEditorParams & DateListCustomFieldMetadataFragment, ref) => {
    const [value, setValue] = React.useState(props.value);
    const width = props.columnApi.getColumn(props.colDef)?.getActualWidth();

    React.useImperativeHandle(ref, () => {
      return {
        getValue: () => {
          return value;
        }
      };
    });

    const dateFormat = props.dateFormat as unknown as InputDateFormat;

    return (
      <div className="inline-block h-[77px]" style={{ width }}>
        <SingleDatePicker
          minHeight={77}
          selectedDate={value}
          onChange={(newVal) => {
            setValue(newVal);
            setTimeout(() => {
              props.api.stopEditing();
            }, 10);
          }}
          native={false}
          dateFormat={dateFormat}
          alwaysOpen
          fullWidth
          autoFocus
        />
      </div>
    );
  }
);

export const CustomColumnSingleSelectCellRenderer = (
  props: ICellRendererParams<CompanyListEntryNodeFragment> & {
    options: SelectListCustomFieldValueOption[];
  }
) => {
  const value = props.value as string;
  const options = props.options;
  const selectedOption = options.find(
    (option) => option.urn && option.urn === value
  );

  return (
    <div className="inline-block">
      <TruncatedList height={54}>
        {selectedOption && (
          <Tag
            label={selectedOption?.name ?? ''}
            color={(selectedOption?.color ?? 'neutral') as ColorShorthand}
          />
        )}
      </TruncatedList>
    </div>
  );
};

export const CustomColumnMultiSelectCellRenderer = (
  props: ICellRendererParams<CompanyListEntryNodeFragment> & {
    options: SelectListCustomFieldValueOption[];
  }
) => {
  const value = (props.value ?? []) as string[];
  const options = props.options;
  const selectedOptions = options
    .filter((option) => option.urn && value.includes(option.urn))
    .sort((a, b) => {
      return value.indexOf(a.urn) - value.indexOf(b.urn);
    });

  return (
    <div className="inline-block">
      <TruncatedList height={54}>
        {selectedOptions &&
          selectedOptions.length > 0 &&
          selectedOptions.map((option) => (
            <Tag
              key={option.urn}
              label={option.name}
              color={(option.color ?? 'neutral') as ColorShorthand}
            />
          ))}
      </TruncatedList>
    </div>
  );
};

export const CustomColumnPersonSelectCellRenderer = (
  props: ICellRendererParams<CompanyListEntryNodeFragment>
) => {
  const { activeTeamMembers } = useTeamMembers({ fetchPolicy: 'cache-only' });
  const formattedTeamMembers =
    activeTeamMembers?.map((member) => ({
      urn: member?.user?.entityUrn as string,
      name: member?.user?.name as string
    })) ?? [];
  const value = (props.value ?? []) as string[];

  const selectedOptions = formattedTeamMembers
    .filter((option) => option.urn && value.includes(option.urn))
    .sort((a, b) => {
      return value.indexOf(a.urn) - value.indexOf(b.urn);
    });

  if (!activeTeamMembers || activeTeamMembers.length === 0) {
    return <LoadingCellRenderer />;
  }

  return (
    <div className="inline-block">
      <TruncatedList height={54}>
        {selectedOptions &&
          selectedOptions.length > 0 &&
          selectedOptions.map((option) => {
            const color =
              colorOptions[
                formattedTeamMembers.indexOf(option) % colorOptions.length
              ].color;
            return <Tag key={option.urn} label={option.name} color={color} />;
          })}
      </TruncatedList>
    </div>
  );
};

export const CustomColumnDateCellRenderer = (
  props: ICellRendererParams<CompanyListEntryNodeFragment> & {
    dateFormat: DateListCustomFieldValueFormat;
  }
) => {
  const value = props.value;
  const dateFormat = props.dateFormat;

  const formattedValue = formatCustomFieldDate(dateFormat, value);
  return (
    <div className="inline-block">
      <p>{formattedValue}</p>
    </div>
  );
};

export const CustomColumnCheckboxRenderer = (
  props: ICellRendererParams<CompanyListEntryNodeFragment>
) => {
  const [value, setValue] = React.useState(props.value);
  const gridContext = props.context;
  const customFieldUrn = props.colDef?.field;
  const entryUrn = props.node?.data?.entryUrn;
  const isPeopleList = customFieldUrn?.includes('person_list');

  const handleOnChange = async (newValue: boolean) => {
    setValue(newValue);
    let customFieldValueInput:
      | PeopleListCustomFieldValueUpsertInput
      | CompanyListCustomFieldValueUpsertInput;

    if (isPeopleList) {
      customFieldValueInput = {
        checkboxData: {
          value: newValue
        }
      } as PeopleListCustomFieldValueUpsertInput;
    } else {
      customFieldValueInput = {
        checkboxData: {
          value: newValue
        }
      } as CompanyListCustomFieldValueUpsertInput;
    }

    if (customFieldUrn && entryUrn) {
      const result = await gridContext.upsertCustomFieldValue({
        customFieldUrn,
        companyWatchlistEntryUrn: entryUrn,
        peopleWatchlistEntryUrn: entryUrn,
        customFieldValueInput
      });
      setValue(
        (result?.data as CheckboxListCustomFieldValueFragment).checkboxValue
      );
    }
  };

  return (
    <div>
      <Checkbox checked={!!value} onChange={() => handleOnChange(!value)} />
    </div>
  );
};

export const CustomColumnWebsiteCellRenderer = (
  props: ICellRendererParams<CompanyListEntryNodeFragment>
) => {
  const value = props.value || '';
  let url = value;
  if (!value.startsWith('http') && !value.startsWith('https')) {
    url = 'https://' + value;
  }

  if (isValidUrl(url)) {
    return (
      <p className="typography-paragraph text-content line-clamp-2">
        <a
          className="underline"
          href={url}
          rel="noopener noreferrer"
          target="_blank"
          onClick={(e) => e.stopPropagation()}
        >
          {value}
        </a>
      </p>
    );
  } else {
    return (
      <p className="typography-paragraph text-content-default line-clamp-2">
        {value}
      </p>
    );
  }
};

export const CustomColumnStatusCellRenderer = (
  props: ICellRendererParams<CompanyListEntryNodeFragment> & {
    options: SelectListCustomFieldValueOption[];
    default: string;
  }
) => {
  const value = props.value as string;
  const options = props.options;
  const selectedOption = options.find(
    (option) => option.urn && option.urn === value
  );
  const defaultOption = options.find(
    (option) => option.urn && option.urn === props.default
  );

  return (
    <div className="inline-block">
      <TruncatedList height={54}>
        {selectedOption ? (
          <Tag
            label={selectedOption?.name ?? ''}
            color={(selectedOption?.color ?? 'neutral') as ColorShorthand}
          />
        ) : defaultOption ? (
          <Tag
            label={defaultOption?.name ?? ''}
            color={(defaultOption?.color ?? 'neutral') as ColorShorthand}
          />
        ) : null}
      </TruncatedList>
    </div>
  );
};
