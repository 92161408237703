import classNames from 'classnames';
import { HarmonicIcon } from './type';
const ChevronDown: HarmonicIcon = ({ applyCurrentColor = true, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      stroke="#7C8598"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M6.6665 8.33331L9.70521 11.372C9.86793 11.5347 10.1317 11.5347 10.2945 11.372L13.3332 8.33331"
      />
    </svg>
  );
};
export default ChevronDown;
