import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';
const CopyIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
      fill="none"
      stroke="#677183"
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M5.8335 5.83325V3.33325C5.8335 2.50482 6.50507 1.83325 7.3335 1.83325H12.6668C13.4953 1.83325 14.1668 2.50482 14.1668 3.33325V8.66659C14.1668 9.49501 13.4953 10.1666 12.6668 10.1666H10.1668M8.66683 5.83325H3.3335C2.50507 5.83325 1.8335 6.50483 1.8335 7.33325V12.6666C1.8335 13.495 2.50507 14.1666 3.3335 14.1666H8.66683C9.49526 14.1666 10.1668 13.495 10.1668 12.6666V7.33325C10.1668 6.50483 9.49526 5.83325 8.66683 5.83325Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default CopyIcon;
