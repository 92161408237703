import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const SettingsGearIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      fill="#484E5B"
    >
      <path
        vectorEffect="non-scaling-stroke"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.41788 1.59164C7.77823 1.38417 8.22177 1.38417 8.58212 1.59164L13.4155 4.37444C13.7771 4.58266 14 4.96819 14 5.3855V10.6146C14 11.0319 13.7771 11.4175 13.4155 11.6257L8.5821 14.4085C8.22176 14.6159 7.77824 14.6159 7.41789 14.4085L2.58458 11.6259C2.22291 11.4177 2 11.0321 2 10.6148V5.38548C2 4.96817 2.2229 4.58264 2.58455 4.37442L7.41788 1.59164ZM5.58335 8.00016C5.58335 6.66547 6.66533 5.5835 8.00002 5.5835C9.33471 5.5835 10.4167 6.66547 10.4167 8.00016C10.4167 9.33485 9.33471 10.4168 8.00002 10.4168C6.66533 10.4168 5.58335 9.33485 5.58335 8.00016Z"
      />
    </svg>
  );
};

export default SettingsGearIcon;
