import { ColDef } from 'ag-grid-community';
import { SPLITS } from 'utils/constants';
import { EventType } from './DataModel/Event';
import { EntityListType } from './SearchModel/Search';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type IRow = Record<string, any>;

// https://www.ag-grid.com/javascript-grid-column-properties/#reference-columnsOnly
interface ITableHeaderOptions {
  toggleable?: boolean; // if the column can be toggled by Edit View modal
  searchModelIdentifier?: string; // unique identifier in the search model for sorting purposes
  searchModelSortable?: boolean; // if the field is sortable using the search model
  searchModelFilterable?: boolean; // if the field is filterable using the search model
  customTooltip?: string;
  sortType?: 'text' | 'date' | 'number' | 'viewed';
}

export type ITableHeader = ColDef & ITableHeaderOptions;

export interface ColumnHeaderOrder {
  id: string;
  name: string;
  isVisibleOnTable: boolean;
  order?: number;
  splitFlagToEnable?: SPLITS;
}

export interface IEntityListIdentifier {
  type: EntityListType;
  entityListId: string;
}

// Options for a grid view that apply to the entire page, and do not manipulate the grid columns directly
export interface IWrappedGridOptions {
  eventTypes?: EventType[];
  customerIdOverride?: string;
  apiResourceSerializer?: string;
}

export enum GridExportFileType {
  JSON = 'JSON',
  CSV = 'CSV',
  JSONL = 'JSONL'
}

export enum DerivedMetricType {
  Snapshot = 'Snapshot',
  RealChange = 'RealChange',
  PercentChange = 'PercentChange'
}

export interface ResultsToolbarOptions {
  hidePageHeader?: boolean;
  hideFilter?: boolean;
  hideRowHeight?: boolean;
}

export enum EntityAction {
  ADD_TO_WATCHLIST = 'ADD_TO_WATCHLIST',
  REMOVE_FROM_WATCHLIST = 'REMOVE_FROM_WATCHLIST',
  LIKE = 'LIKE',
  UNLIKE = 'UNLIKE',
  HIDE = 'HIDE',
  UNHIDE = 'UNHIDE',
  VIEW = 'VIEW',
  UNVIEW = 'UNVIEW'
}

export interface EntityActionPayload {
  entityAction: EntityAction;
  targetEntityType: 'COMPANY' | 'PERSON';
  targetWatchlistUrn?: string;
}
