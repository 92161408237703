import { useQuery } from '@apollo/client';
import {
  FundingRoundFragment,
  GetCompanyFinancingV2Query,
  GetCompanyFinancingV2QueryVariables,
  InvestorFragment
} from '__generated__/graphql';
import { ReactComponent as BoxBarChart } from 'assets/bxs-bar-chart-alt.svg';
import { ReactComponent as BoxHandHoldingUsd } from 'assets/bxs-hand-holding-usd.svg';
import { ArrowRightIcon } from 'assets/harmonic-icons';
import { Badge } from 'common/components';
import InvestorCard from 'components/common/CompanyCard/InvestorCard';
import MetricCard from 'components/common/CompanyCard/MetricCard';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { isNil } from 'lodash';
import { getCompanyFinancing } from 'queries/getCompanyFinancingV2';
import React, { useMemo } from 'react';
import {
  getInvestorFundingRoundsMap,
  getSortedFundingRoundInvestors
} from 'utils/funding';
import FundingTotal from '../Cards/FundingTotal';
import CompanyFinancingContentLoader from '../CompanyFinancing/CompanyFinancingContentLoader';
import { CompanyNavbarOptions } from '../CompanyHeader/CompanyHeader';

interface CompanyOverviewFinancingProps {
  companyId: number;
  onTabChange?: (newTabOption: CompanyNavbarOptions) => void;
}

const CompanyOverviewFinancing: React.FC<CompanyOverviewFinancingProps> = ({
  companyId,
  onTabChange
}) => {
  const { data } = useQuery<
    GetCompanyFinancingV2Query,
    GetCompanyFinancingV2QueryVariables
  >(getCompanyFinancing, {
    variables: { id: companyId },
    fetchPolicy: 'cache-only'
  });
  const investorsMap = useMemo(() => {
    const investors = data?.getCompanyById?.funding?.investors ?? [];
    return investors.reduce((acc, investor) => {
      if (!investor?.name) return acc;
      acc[investor.name] = investor;
      return acc;
    }, {} as Record<string, InvestorFragment>);
  }, [data?.getCompanyById?.funding?.investors]);

  const loading = isNil(data);
  const companyFunding = data?.getCompanyById?.funding;
  const fundingRounds = companyFunding?.fundingRounds ?? [];
  const investors = companyFunding?.investors ?? [];
  const filteredInvestors = investors?.filter(
    (investor) => investor?.name !== ''
  );
  const rbrExist = fundingRounds.length > 0;
  const noInvestorsExist =
    fundingRounds?.length === 0 && filteredInvestors?.length === 0;

  const investorsFundingRoundMap = useMemo(
    () => getInvestorFundingRoundsMap(fundingRounds),
    [fundingRounds]
  );

  const renderInvestorsWithRbr = () => {
    const sortedFundingRoundInvestors = getSortedFundingRoundInvestors(
      fundingRounds as FundingRoundFragment[]
    );
    return (
      <div className="grid sm:grid-cols-2 gap-g50">
        {sortedFundingRoundInvestors.slice(0, 4).map((investor) => {
          const investorName = investor?.investorName;
          const logoUrl = investorName
            ? investorsMap[investorName]?.logoUrl
            : '';
          const entityUrn = investor?.entityUrn ?? '';
          return (
            <InvestorCard
              key={investorName}
              investorName={investorName as string}
              logoUrl={logoUrl as string}
              entityUrn={entityUrn as string}
              dataTestId="CompanyOverviewFinancing-Rbr-Investor"
              rounds={investorsFundingRoundMap?.[investor?.entityUrn] ?? []}
            />
          );
        })}
      </div>
    );
  };

  const renderInvestorsWithoutRbr = () => {
    return (
      <div
        data-testid="CompanyFinancing-Investors-Without-Rbr"
        className="mt-6 grid grid-cols-2 gap-4"
      >
        {filteredInvestors.slice(0, 4).map((investor) => {
          const investorName = investor?.name;
          const logoUrl = investor?.logoUrl;
          const entityUrn =
            investor?.__typename === 'Company'
              ? investor?.companyUrn
              : investor?.personUrn;
          return (
            <InvestorCard
              key={investorName}
              investorName={investorName as string}
              logoUrl={logoUrl as string}
              entityUrn={entityUrn as string}
              dataTestId="CompanyOverviewFinancing-NoRbr-Investor"
            />
          );
        })}
      </div>
    );
  };

  const renderFundingCards = () => {
    const totalFundingRounds = companyFunding?.numFundingRounds || 'Unknown';

    return (
      <div className="grid sm:grid-cols-3 gap-g60 my-g80">
        <FundingTotal companyId={companyId} />
        <MetricCard
          icon={BoxBarChart}
          value={totalFundingRounds}
          label="Funding rounds"
        />
        <MetricCard
          icon={BoxHandHoldingUsd}
          value={investors?.length}
          label="Investors"
        />
      </div>
    );
  };

  const renderSeeAllButton = () => {
    if (!onTabChange) return null;
    return (
      <IconButton
        onClick={() => onTabChange(CompanyNavbarOptions.FINANCING)}
        icon={ArrowRightIcon}
        size="compact"
      />
    );
  };

  return (
    <div>
      <div className="flex justify-between items-center py-p60 border-b border-solid border-border">
        <div className="flex items-center gap-g40">
          <p className="text-content-title typography-title-small">Investors</p>
          <Badge
            color="neutral"
            label={investors?.length?.toString() ?? ''}
            size="large"
          />
        </div>
        {renderSeeAllButton()}
      </div>
      {loading ? (
        <CompanyFinancingContentLoader />
      ) : (
        <>
          {renderFundingCards()}

          {!noInvestorsExist && (
            <div>
              {rbrExist
                ? renderInvestorsWithRbr()
                : renderInvestorsWithoutRbr()}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CompanyOverviewFinancing;
