import classNames from 'classnames';
import { IconProps } from './type';

const SettingsGear2Icon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke="#484E5B"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M5.70104 3.57973L4.50591 3.30393C4.28194 3.25224 4.04714 3.31958 3.8846 3.48212L3.48215 3.88457C3.31961 4.0471 3.25227 4.28191 3.30396 4.50588L3.57976 5.70101C3.64211 5.97121 3.53069 6.2518 3.29996 6.40562L2.13024 7.18543C1.94477 7.30908 1.83337 7.51723 1.83337 7.74013V8.25989C1.83337 8.48279 1.94477 8.69094 2.13024 8.81459L3.29996 9.5944C3.53069 9.74822 3.64211 10.0288 3.57976 10.299L3.30396 11.4941C3.25227 11.7181 3.31961 11.9529 3.48215 12.1155L3.8846 12.5179C4.04713 12.6804 4.28194 12.7478 4.50591 12.6961L5.70104 12.4203C5.97124 12.3579 6.25183 12.4694 6.40565 12.7001L7.18546 13.8698C7.30911 14.0553 7.51726 14.1667 7.74016 14.1667H8.25992C8.48282 14.1667 8.69097 14.0553 8.81462 13.8698L9.59443 12.7001C9.74825 12.4694 10.0288 12.3579 10.299 12.4203L11.4942 12.6961C11.7181 12.7478 11.9529 12.6804 12.1155 12.5179L12.5179 12.1155C12.6805 11.9529 12.7478 11.7181 12.6961 11.4941L12.4203 10.299C12.358 10.0288 12.4694 9.74822 12.7001 9.5944L13.8698 8.81459C14.0553 8.69094 14.1667 8.48279 14.1667 8.25989V7.74013C14.1667 7.51723 14.0553 7.30908 13.8698 7.18543L12.7001 6.40562C12.4694 6.2518 12.358 5.97121 12.4203 5.70101L12.6961 4.50588C12.7478 4.28191 12.6805 4.0471 12.5179 3.88457L12.1155 3.48212C11.9529 3.31958 11.7181 3.25224 11.4942 3.30393L10.299 3.57973C10.0288 3.64208 9.74825 3.53066 9.59443 3.29993L8.81462 2.13021C8.69097 1.94474 8.48282 1.83334 8.25992 1.83334H7.74016C7.51726 1.83334 7.30911 1.94474 7.18546 2.13021L6.40565 3.29993C6.25183 3.53066 5.97124 3.64208 5.70104 3.57973Z"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M9.83337 8.00001C9.83337 9.01253 9.01256 9.83334 8.00004 9.83334C6.98752 9.83334 6.16671 9.01253 6.16671 8.00001C6.16671 6.98749 6.98752 6.16668 8.00004 6.16668C9.01256 6.16668 9.83337 6.98749 9.83337 8.00001Z"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default SettingsGear2Icon;
