import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const SidebarPeopleIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#484E5B"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.75 6C5.85457 6 6.75 5.10457 6.75 4C6.75 2.89543 5.85457 2 4.75 2C3.64543 2 2.75 2.89543 2.75 4C2.75 5.10457 3.64543 6 4.75 6ZM13.5 6C13.5 7.10457 12.6046 8 11.5 8C10.3954 8 9.5 7.10457 9.5 6C9.5 4.89543 10.3954 4 11.5 4C12.6046 4 13.5 4.89543 13.5 6ZM2.13477 7.69683C2 7.92941 2 8.25865 2 8.91714V12.4C2 12.9601 2 13.2401 2.10899 13.454C2.20487 13.6422 2.35785 13.7951 2.54601 13.891C2.75992 14 3.03995 14 3.6 14H5.9C6.46005 14 6.74008 14 6.95399 13.891C7.14215 13.7951 7.29513 13.6422 7.39101 13.454C7.5 13.2401 7.5 12.9601 7.5 12.4V9.33532C7.5 8.86034 7.5 8.62285 7.41419 8.4293C7.33854 8.25865 7.21664 8.11258 7.06228 8.00763C6.88719 7.88858 6.65354 7.8461 6.18622 7.76113L3.88622 7.34295C3.23835 7.22515 2.91442 7.16626 2.66148 7.25725C2.43957 7.33709 2.25302 7.49278 2.13477 7.69683ZM9.08369 10.4193C9 10.6108 9 10.8444 9 11.3117V12.4C9 12.9601 9 13.2401 9.10899 13.454C9.20487 13.6422 9.35785 13.7951 9.54601 13.891C9.75992 14 10.0399 14 10.6 14H12.4C12.9601 14 13.2401 14 13.454 13.891C13.6422 13.7951 13.7951 13.6422 13.891 13.454C14 13.2401 14 12.9601 14 12.4V10.9517C14 10.2827 14 9.94818 13.8626 9.71388C13.742 9.50837 13.5521 9.35265 13.3269 9.27476C13.0702 9.18595 12.7422 9.25155 12.0862 9.38276L10.2862 9.74276C9.82805 9.83439 9.59897 9.88021 9.42761 9.99982C9.2765 10.1053 9.15748 10.2505 9.08369 10.4193Z"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default SidebarPeopleIcon;
