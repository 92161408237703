import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const CrossSmallIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
      fill="none"
      stroke="#1A1D23"
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M5.16666 5.16663L10.8333 10.8333M10.8333 5.16663L5.16666 10.8333"
        stroke="#1A1D23"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CrossSmallIcon;
