import classNames from 'classnames';
import { IconProps } from './type';

const UserRemoveIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        d="M18 3C14.2721 3 11.25 6.02208 11.25 9.75C11.25 13.4779 14.2721 16.5 18 16.5C21.7279 16.5 24.75 13.4779 24.75 9.75C24.75 6.02208 21.7279 3 18 3Z"
        fill="#7C8598"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M24.6973 20.6974C23.6188 20.4708 22.4509 20.7761 21.6135 21.6135C20.2955 22.9315 20.2955 25.0685 21.6135 26.3865L22.227 27L21.6135 27.6135C20.562 28.665 20.3494 30.2377 20.9756 31.5H8.39821C6.90012 31.5 5.53586 30.1679 5.91031 28.5018C7.1529 22.9729 12.3178 18.75 18.0006 18.75C20.468 18.75 22.7657 19.4548 24.6973 20.6974Z"
        fill="#7C8598"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M30.7955 24.7955C31.2348 24.3562 31.2348 23.6438 30.7955 23.2045C30.3562 22.7652 29.6438 22.7652 29.2045 23.2045L27 25.409L24.7955 23.2045C24.3562 22.7652 23.6438 22.7652 23.2045 23.2045C22.7652 23.6438 22.7652 24.3562 23.2045 24.7955L25.409 27L23.2045 29.2045C22.7652 29.6438 22.7652 30.3562 23.2045 30.7955C23.6438 31.2348 24.3562 31.2348 24.7955 30.7955L27 28.591L29.2045 30.7955C29.6438 31.2348 30.3562 31.2348 30.7955 30.7955C31.2348 30.3562 31.2348 29.6438 30.7955 29.2045L28.591 27L30.7955 24.7955Z"
        fill="#7C8598"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default UserRemoveIcon;
