import CompanyEmployees from './CompanyEmployees';
import CompanyExecutives from './CompanyExecutives';
import CompanyFoundersCeo from './CompanyFoundersCeo';
import CompanyTeamGrowth from './CompanyTeamGrowth';

interface CompanyTeamProps {
  companyId: number;
}

const CompanyTeam: React.FC<CompanyTeamProps> = ({ companyId }) => {
  return (
    <div
      className="min-h-screen pb-4"
      // If the user navigates back and forth between company profiles,
      // the state may not get cleared. Therefore, use a key to force a re-render
      // when the user switches between company profiles.
      key={companyId}
    >
      <CompanyFoundersCeo companyId={companyId} />
      <div className="py-16">
        <CompanyTeamGrowth companyId={companyId} />
      </div>
      <div className="gap-y-6">
        <CompanyExecutives companyId={companyId} />
        <CompanyEmployees companyId={companyId} />
      </div>
    </div>
  );
};

export default CompanyTeam;
