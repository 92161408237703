import classNames from 'classnames';
import { HarmonicIcon } from './type';

const PaperPlaneIcon: HarmonicIcon = ({
  applyCurrentColor = false,
  className
}) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="#677183"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M0.733795 3.02898C0.132446 2.49445 0.510536 1.5 1.31511 1.5H10.6393C11.3108 1.5 11.732 2.22514 11.3994 2.80846L6.82626 10.8277C6.43204 11.519 5.39453 11.3678 5.21398 10.5928L4.18271 6.16578L7.48708 4.32771C7.66808 4.22704 7.73318 3.9987 7.63251 3.81771C7.53183 3.63672 7.30349 3.57161 7.1225 3.67229L3.63804 5.61053L0.733795 3.02898Z"
      />
    </svg>
  );
};

export default PaperPlaneIcon;
