import fetchingDataTextLoadingAnimationData from 'assets/harmonic-fetching-data-text-animation-lottie.json';
import harmonicLoadingAnimationData from 'assets/harmonic-logo-animation-lottie.json';
import loadingIcon from 'assets/loading-icon.gif';
import Lottie from 'lottie-react';
import { FC } from 'react';
import {
  LoadingOverlayContainer,
  LoadingOverlayMessageContainer
} from './resultsWrapperStyles';

interface LoadingOverlayProps {
  message?: string;
}

const LoadingOverlay: FC<LoadingOverlayProps> = ({ message }) => {
  return (
    <LoadingOverlayContainer data-testid="loading-container">
      <img src={loadingIcon} />
      {message && (
        <LoadingOverlayMessageContainer>
          {message}
        </LoadingOverlayMessageContainer>
      )}
    </LoadingOverlayContainer>
  );
};

interface HarmonicLoaderProps {
  showText?: boolean;
}

export const HarmonicLoader: FC<HarmonicLoaderProps> = ({
  showText = true
}) => {
  return (
    <div className="flex flex-col justify-center items-center">
      <Lottie animationData={harmonicLoadingAnimationData} loop={true} />
      {showText && (
        <div className="pt-p60 flex flex-col justify-center items-center">
          <Lottie
            animationData={fetchingDataTextLoadingAnimationData}
            loop={true}
          />
        </div>
      )}
    </div>
  );
};

export const GridLoader: FC = () => {
  return (
    <div
      data-testid="grid-loader"
      className="w-full h-full flex justify-center items-center"
    >
      <HarmonicLoader showText={true} />
    </div>
  );
};

export default LoadingOverlay;
