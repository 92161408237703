import { WarningIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import { SVGProps } from 'react';

interface TextAreaProps {
  errorMessage?: string;
  helperText?: string;
  label?: string;
  placeholder?: string;
  leadingIcon?: React.FC<SVGProps<SVGSVGElement>>;
  trailingIcon?: React.FC<SVGProps<SVGSVGElement>>;
  isDisabled?: boolean;
  dataTestId?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  rows?: number;
  fullWidth?: boolean;
}

const calculateRightPadding = (
  trailingIconExist?: boolean,
  errorIconExist?: boolean
) => {
  let iconsCount = 0;
  if (trailingIconExist) iconsCount += 1;
  if (errorIconExist) iconsCount += 1;
  if (iconsCount === 0) return 'var(--spacing-p50)';
  if (iconsCount === 1)
    return 'calc(var(--spacing-p50) + 16px + var(--gap-g10))';
  return 'calc(var(--spacing-p50) + 32px + var(--gap-g10) + var(--gap-g40))';
};

const TextArea: React.FC<TextAreaProps> = ({
  errorMessage,
  leadingIcon,
  label,
  placeholder,
  trailingIcon,
  dataTestId,
  helperText,
  isDisabled,
  value,
  onChange,
  rows = 5,
  fullWidth = true
}) => {
  const LeadingIcon = leadingIcon;
  const TrailingIcon = trailingIcon;

  const rightPadding = calculateRightPadding(
    Boolean(trailingIcon),
    Boolean(errorMessage)
  );
  return (
    <div
      data-testid={dataTestId}
      className={classNames('flex flex-col gap-g20', fullWidth && 'w-full')}
    >
      {label && <p className="text-content-weak typography-label">{label}</p>}
      <div className={classNames('relative', fullWidth && 'w-full')}>
        <textarea
          style={{
            paddingLeft: leadingIcon
              ? `calc(var(--spacing-p50) + 16px + var(--gap-g10))`
              : 'var(--spacing-p50)',
            paddingRight: rightPadding
          }}
          disabled={isDisabled}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          rows={rows}
          className={classNames(
            fullWidth && 'w-full',
            'py-p40 border border-solid typography-label',
            'bg-int-overlay-secondary-enabled placeholder-content-muted',
            'text-content-default rounded-br20',
            //disabled state
            isDisabled &&
              'bg-int-overlay-secondary-disabled border-int-outline-secondary-disabled placeholder-content-muted',
            //error state
            errorMessage && !isDisabled && 'border-int-fill-negative-enabled',
            //default state
            !errorMessage &&
              !isDisabled &&
              classNames(
                'active:border-int-outline-secondary-pressed hover:border-int-outline-secondary-hover',
                'border-int-outline-secondary-enabled focus:border-int-outline-secondary-selected-enabled focus:shadow-int-focus-outside'
              )
          )}
        />
        {/* Prefix components */}
        {LeadingIcon && (
          <div className="absolute top-0 bottom-0 left-0 pl-p50 pt-p40 gap-g40 pointer-events-none">
            {LeadingIcon && (
              <LeadingIcon className="fill-current text-content-weak w-4 h-4" />
            )}
          </div>
        )}

        {/* Suffix components */}
        {(TrailingIcon || errorMessage) && (
          <div className="absolute top-0 bottom-0 right-0 pr-p50 pt-p40 flex gap-g40 pointer-events-none">
            {TrailingIcon && (
              <TrailingIcon className="fill-current text-content-weak w-4 h-4" />
            )}
            {errorMessage && (
              <WarningIcon className=" text-content-sentiment-negative w-4 h-4" />
            )}
          </div>
        )}
      </div>
      {errorMessage && (
        <p className="typography-label text-int-fill-negative-contentEnabled">
          {errorMessage}
        </p>
      )}

      {helperText && (
        <p className="typography-label text-content-weak">{helperText}</p>
      )}
    </div>
  );
};

export default TextArea;
