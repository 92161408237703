import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const LocationIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
      fill="#484E5B"
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M9.76557 5.39044C9.24491 4.86974 8.40097 4.86994 7.87982 5.39061C7.35947 5.9105 7.35942 6.75513 7.88017 7.27642C8.4 7.7968 9.24451 7.79684 9.76575 7.27608C10.2863 6.75597 10.2866 5.91147 9.76557 5.39044Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.82292 1.33334C5.14102 1.33334 2.15625 4.31811 2.15625 8.00001C2.15625 11.6819 5.14102 14.6667 8.82292 14.6667C12.5048 14.6667 15.4896 11.6819 15.4896 8.00001C15.4896 4.31811 12.5048 1.33334 8.82292 1.33334ZM7.17304 4.68318C8.08419 3.77286 9.56107 3.77166 10.4727 4.68336C11.384 5.59472 11.3843 7.07261 10.4725 7.9835C10.145 8.31073 9.74425 8.52051 9.32292 8.61261V11.5C9.32292 11.7762 9.09906 12 8.82292 12C8.54677 12 8.32292 11.7762 8.32292 11.5V8.61295C7.90136 8.52104 7.50037 8.31118 7.17269 7.98316C6.26255 7.07206 6.2611 5.5943 7.17304 4.68318Z"
      />
    </svg>
  );
};

export default LocationIcon;
