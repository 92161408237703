import classNames from 'classnames';
import { IconProps } from './type';

const HashtagIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="16"
      height="16"
      stroke="#7C8598"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M5.83333 2.5L4.5 13.5M11.5 2.5L10.1667 13.5M2.5 5.16667H13.5M13.5 10.8333H2.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HashtagIcon;
