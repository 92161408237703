import classNames from 'classnames';
import { IconProps } from './type';

const Company: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="white"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 5.22923C2 4.78918 2 4.56916 2.07626 4.38525C2.14355 4.22295 2.25242 4.08124 2.3919 3.9744C2.54996 3.85333 2.76255 3.79664 3.18774 3.68325L7.48774 2.53659C8.17235 2.35402 8.51466 2.26274 8.78482 2.34301C9.02154 2.41335 9.22387 2.56879 9.35283 2.7794C9.5 3.01976 9.5 3.37403 9.5 4.08256V12.4C9.5 12.96 9.5 13.2401 9.39101 13.454C9.29513 13.6421 9.14215 13.7951 8.95399 13.891C8.74008 14 8.46005 14 7.9 14H7.25V11.4999C7.25 11.2238 7.02614 10.9999 6.75 10.9999H4.75C4.47386 10.9999 4.25 11.2238 4.25 11.4999V14H3.6C3.03995 14 2.75992 14 2.54601 13.891C2.35785 13.7951 2.20487 13.6421 2.10899 13.454C2 13.2401 2 12.96 2 12.4V5.22923ZM11 5.80276C11 4.99552 11 4.5919 11.1421 4.35462C11.3317 4.03825 11.6799 3.85186 12.0483 3.86967C12.3246 3.88302 12.6604 4.10691 13.3321 4.55468C13.549 4.6993 13.6574 4.77161 13.7404 4.86303C13.8511 4.98492 13.9299 5.13225 13.97 5.29193C14 5.4117 14 5.54205 14 5.80276V12.5C14 12.9659 14 13.1989 13.9239 13.3827C13.8224 13.6277 13.6277 13.8224 13.3827 13.9239C13.1989 14 12.9659 14 12.5 14C12.0341 14 11.8011 14 11.6173 13.9239C11.3723 13.8224 11.1776 13.6277 11.0761 13.3827C11 13.1989 11 12.9659 11 12.5V5.80276Z"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};
export default Company;
