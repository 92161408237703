import classNames from 'classnames';
import { BadgeProps } from 'common/components';
import React, { ReactElement, SVGProps } from 'react';

interface ModalTitleProps {
  title: string;
  titleIcon?: React.FC<SVGProps<SVGSVGElement>>;
  titleBadge?: ReactElement<BadgeProps>;
  sentiment?: 'neutral' | 'negative';
}

const ModalTitle: React.FC<ModalTitleProps> = ({
  title,
  titleIcon,
  titleBadge,
  sentiment = 'neutral'
}) => {
  const TitleIcon = titleIcon;

  const iconClassName = classNames(
    'w-4 h-4',
    sentiment === 'negative' && 'text-content-sentiment-negative',
    sentiment === 'neutral' && 'text-content-sentiment-neutral'
  );

  return (
    <div className="bg-surface-elevated rounded-t-br40 typography-label-default-strong border-b border-border border-solid flex flex-row items-center gap-2 pl-p50 pr-p100">
      <div className="py-p50 flex flex-1 flex-row items-center gap-g40">
        {TitleIcon && <TitleIcon className={iconClassName} />}
        {title}
        {titleBadge}
      </div>
    </div>
  );
};

export default ModalTitle;
