import { Tooltip } from '@material-ui/core';
import { ReactComponent as FacebookBoxIcon } from 'assets/socials/bxl-facebook.svg';
import { ReactComponent as GlobeIcon } from 'assets/socials/bxl-globe.svg';
import { ReactComponent as InstagramBoxIcon } from 'assets/socials/bxl-instagram.svg';
import { ReactComponent as LinkedinBoxIcon } from 'assets/socials/bxl-linkedin.svg';
import { ReactComponent as TwitterBoxIcon } from 'assets/socials/bxl-twitter.svg';
import MetricCard from 'components/common/CompanyCard/MetricCard';
import PercentChangeBadge, {
  TractionMetrics
} from 'components/common/TractionMetrics/PercentChangeBadge';
import { SocialMetricsType } from 'interfaces/DataModel/CompanyV2';
import { isFinite, isNil } from 'lodash';
import { addCommasToNumber } from 'utils/utilities';

interface CompanySocialCardProps {
  value?: number | null;
  tractionMetrics?: TractionMetrics | null;
  type: SocialMetricsType;
}

const SocialMetricInfoMap = {
  [SocialMetricsType.WEB_TRAFFIC]: {
    label: 'Web traffic',
    icon: GlobeIcon
  },
  [SocialMetricsType.FACEBOOK_LIKES]: {
    label: 'Facebook likes',
    icon: FacebookBoxIcon
  },
  [SocialMetricsType.INSTAGRAM_FOLLOWERS]: {
    label: 'Instagram followers',
    icon: InstagramBoxIcon
  },
  [SocialMetricsType.TWITTER_FOLLOWERS]: {
    label: 'Twitter followers',
    icon: TwitterBoxIcon
  },
  [SocialMetricsType.LINKEDIN_FOLLOWERS]: {
    label: 'Linkedin followers',
    icon: LinkedinBoxIcon
  }
};

const CompanySocialCard: React.FC<CompanySocialCardProps> = ({
  value,
  tractionMetrics = {},
  type
}) => {
  if (!isFinite(value)) return null;
  const Icon = SocialMetricInfoMap[type].icon;
  const label = SocialMetricInfoMap[type].label;
  const bothPercentAndValueExist =
    isFinite(tractionMetrics?.percentChange) &&
    isFinite(tractionMetrics?.value);

  const formattedValue = !isNil(value)
    ? addCommasToNumber(value.toString())
    : null;
  return (
    <MetricCard
      dataTestId="CompanySocialCard"
      value={formattedValue}
      label={label}
      icon={Icon}
      titleSuffixComponent={
        bothPercentAndValueExist && (
          <Tooltip classes={{ tooltip: 'text-xs' }} title={<span>Δ90d</span>}>
            <div data-testid="CompanySocialCardPercentGrowth">
              <PercentChangeBadge tractionMetrics={tractionMetrics} />
            </div>
          </Tooltip>
        )
      }
    />
  );
};

export default CompanySocialCard;
