import classNames from 'classnames';
import { HarmonicIcon } from './type';
const StackSmall: HarmonicIcon = ({ applyCurrentColor = true, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      strokeWidth="0"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99992 3.26018L2.6777 5.90842L5.53171 7.32853L7.99992 8.55667L10.4681 7.32853L13.3221 5.90842L7.99992 3.26018ZM13.4608 5.83943L13.4607 5.83949L13.4608 5.83943ZM7.37027 1.89805C7.76698 1.70065 8.23285 1.70065 8.62957 1.89805L14.129 4.63448C15.1791 5.15699 15.1791 6.65986 14.129 7.18237L12.4858 8L14.129 8.81763C15.1791 9.34014 15.1791 10.843 14.129 11.3655L8.62957 14.102C8.23285 14.2993 7.76698 14.2993 7.37027 14.102L1.87082 11.3655C0.820727 10.843 0.820733 9.34014 1.87082 8.81763L3.51403 8L1.87082 7.18237C0.820731 6.65986 0.820726 5.15699 1.87082 4.63448L7.37027 1.89805ZM5.1976 8.83772L2.6777 10.0916L7.99992 12.7398L13.3221 10.0916L10.8022 8.83772L8.62957 9.9188C8.23285 10.1162 7.76698 10.1162 7.37027 9.9188L5.1976 8.83772Z"
        fill="#677183"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};
export default StackSmall;
