import { SocialMetricsChartMapType } from './CompanySocials';

import {
  GlobeIcon,
  InstagramIcon,
  LinkedInIcon,
  TwitterIcon
} from 'assets/harmonic-icons';
import LineChartV2 from 'components/Charts/LineChartV2';
import SegmentedControl from 'harmonic-components/SegmentedControl/SegmentedControl';
import { SocialMetricsType } from 'interfaces/DataModel/CompanyV2';
import { SVGProps, useState } from 'react';

interface SocialMetricsGraphProps {
  socialMetricsDataPointMap: SocialMetricsChartMapType;
}

const SocialMetricTypeIconMap = {
  [SocialMetricsType.WEB_TRAFFIC]: ({ className }: { className: string }) => (
    <GlobeIcon className={className} applyCurrentColor={false} />
  ),
  [SocialMetricsType.FACEBOOK_LIKES]: ({
    className
  }: {
    className: string;
  }) => <GlobeIcon className={className} applyCurrentColor={false} />,
  [SocialMetricsType.TWITTER_FOLLOWERS]: ({
    className
  }: {
    className: string;
  }) => <TwitterIcon className={className} applyCurrentColor={false} />,
  [SocialMetricsType.INSTAGRAM_FOLLOWERS]: ({
    className
  }: {
    className: string;
  }) => <InstagramIcon className={className} applyCurrentColor={false} />,
  [SocialMetricsType.LINKEDIN_FOLLOWERS]: ({
    className
  }: {
    className: string;
  }) => <LinkedInIcon className={className} applyCurrentColor={false} />
};

const SocialMetricsTypeLabel: Record<SocialMetricsType, string> = {
  [SocialMetricsType.WEB_TRAFFIC]: 'Web traffic',
  [SocialMetricsType.FACEBOOK_LIKES]: 'Facebook',
  [SocialMetricsType.TWITTER_FOLLOWERS]: 'Twitter',
  [SocialMetricsType.INSTAGRAM_FOLLOWERS]: 'Instagram',
  [SocialMetricsType.LINKEDIN_FOLLOWERS]: 'Linkedin'
};

const SocialMetricsGraph: React.FC<SocialMetricsGraphProps> = ({
  socialMetricsDataPointMap
}) => {
  const socialMetricsTypes = Object.keys(
    socialMetricsDataPointMap
  ) as SocialMetricsType[];
  const [selectedSocialMetric, setSelectedSocialMetric] = useState(
    socialMetricsTypes?.[0]
  );
  return (
    <div className="border border-solid border-border rounded-br40 bg-surface-default">
      <div className="p-p60 border-b border-solid border-border">
        <SegmentedControl
          options={socialMetricsTypes.map((metricType) => ({
            label: SocialMetricsTypeLabel?.[metricType] ?? '',
            value: metricType,
            leadingIcon: SocialMetricTypeIconMap?.[metricType] as React.FC<
              SVGProps<SVGSVGElement>
            >
          }))}
          selectedOption={selectedSocialMetric}
          onChange={(value) =>
            setSelectedSocialMetric(value as SocialMetricsType)
          }
        />
      </div>
      <div className="p-p60 h-64 w-full">
        <LineChartV2
          yAxisPrefix=""
          label={SocialMetricsTypeLabel?.[selectedSocialMetric] ?? ''}
          data={socialMetricsDataPointMap?.[selectedSocialMetric] ?? []}
        />
      </div>
    </div>
  );
};

export default SocialMetricsGraph;
