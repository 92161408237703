import { RelevanceScoreIcon } from 'assets/harmonic-icons';
import { Badge } from 'common/components';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useStore from 'stores/zustandStore';
import { RELEVANCE_SETTINGS_ROUTE } from 'utils/constants';

interface RelevanceScoreProps {
  score: number;
}

const RelevanceScore: React.FC<RelevanceScoreProps> = ({ score }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const editStoreData = useStore((state) => state.editStoreData);
  const handleClick = () => {
    editStoreData('settingsCalledFromLocation', location);
    navigate(RELEVANCE_SETTINGS_ROUTE);
  };
  return (
    <div onClick={handleClick} className="cursor-pointer">
      <Badge
        leadingIcon={RelevanceScoreIcon}
        label={`${score}%`}
        color="positive"
        size="large"
      />
    </div>
  );
};

export default RelevanceScore;
