import dayjs from 'dayjs';
import DatePickerHeader from 'harmonic-components/DatePicker/DatePickerHeader';
import DatePickerInput from 'harmonic-components/DatePicker/DatePickerInput';
import { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { DateTypeEnum, TwoSidedRangeValue } from './DateModal';
import { getDateFormat } from './SingleInlineDatepicker';

interface RangeInlineDatePickerProps {
  value: TwoSidedRangeValue;
  onChange: (a: TwoSidedRangeValue) => void;
  dateType: DateTypeEnum;
}

const RangeInlineDatePicker: React.FC<RangeInlineDatePickerProps> = ({
  value,
  onChange,
  dateType
}) => {
  const [startDateOpen, setStartDateOpen] = useState(true);
  const [endDateOpen, setEndDateOpen] = useState(false);

  const isYear = dateType === DateTypeEnum.YEAR;
  const startDate = value?.[0] ? dayjs(value?.[0]).toDate() : null;
  const endDate = value?.[1] ? dayjs(value?.[1]).toDate() : null;

  const onStartDateChange = (date: Date | null) => {
    if (isYear) {
      // first day of the year
      date = date ? dayjs(date).startOf('year').toDate() : null;
    }
    onChange([date ? dayjs(date).format('YYYY-MM-DD') : null, value?.[1]]);
    setStartDateOpen(false);
    setEndDateOpen(true);
  };

  const onEndDateChange = (date: Date | null) => {
    if (isYear) {
      // last day of the year
      date = date ? dayjs(date).endOf('year').toDate() : null;
    }
    onChange([value?.[0], date ? dayjs(date).format('YYYY-MM-DD') : null]);
  };

  return (
    <div>
      <div className="flex flex-col gap-g30">
        <ReactDatePicker
          open={false}
          onInputClick={() => {
            setStartDateOpen(true);
            setEndDateOpen(false);
          }}
          selected={startDate}
          onChange={(date) => onStartDateChange(date)}
          dateFormat={getDateFormat(dateType)}
          selectsStart
          dropdownMode="select"
          showPopperArrow={false}
          startDate={startDate}
          endDate={endDate}
          customInput={
            //eslint-disable-next-line
            //@ts-ignore
            <DatePickerInput
              native={!isYear}
              inputPlaceholder={isYear ? 'Select a year' : undefined}
            />
          }
        />
        <ReactDatePicker
          open={false}
          onInputClick={() => {
            setEndDateOpen(true);
            setStartDateOpen(false);
          }}
          selected={endDate}
          onChange={(date) => onEndDateChange(date)}
          dateFormat={getDateFormat(dateType)}
          selectsEnd
          dropdownMode="select"
          showPopperArrow={false}
          startDate={startDate}
          endDate={endDate}
          customInput={
            //eslint-disable-next-line
            //@ts-ignore
            <DatePickerInput
              native={!isYear}
              inputPlaceholder={isYear ? 'Select a year' : undefined}
            />
          }
        />
        {startDateOpen && (
          <ReactDatePicker
            selected={startDate}
            onChange={(date) => onStartDateChange(date)}
            dateFormat={getDateFormat(dateType)}
            selectsStart
            dropdownMode="select"
            showPopperArrow={false}
            renderCustomHeader={({ ...props }) => {
              return (
                <DatePickerHeader
                  {...props}
                  selectedDate={value?.[0]}
                  dateType={dateType}
                />
              );
            }}
            scrollableMonthYearDropdown={true}
            scrollableYearDropdown={true}
            startDate={startDate}
            endDate={endDate}
            inline
            showYearPicker={isYear}
          />
        )}
        {endDateOpen && (
          <ReactDatePicker
            open={endDateOpen}
            selected={endDate}
            onChange={(date) => onEndDateChange(date)}
            dateFormat={getDateFormat(dateType)}
            selectsEnd
            dropdownMode="select"
            showPopperArrow={false}
            renderCustomHeader={({ ...props }) => {
              return (
                <DatePickerHeader
                  {...props}
                  selectedDate={value?.[1]}
                  dateType={dateType}
                />
              );
            }}
            scrollableMonthYearDropdown={true}
            scrollableYearDropdown={true}
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            inline
            showYearPicker={isYear}
          />
        )}
      </div>
    </div>
  );
};

export default RangeInlineDatePicker;
