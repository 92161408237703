import React from 'react';

const CompanyFinancingContentLoader: React.FC = () => {
  return (
    <div data-testid="CompanyFinancing-Loading">
      <div className="w-full mt-4 grid grid-cols-3 gap-10">
        {[...Array(3)].map((_, index) => (
          <div key={index} className="w-full h-24 animated-box-pulse" />
        ))}
      </div>
      <div className="mt-14 grid grid-cols-2 gap-3">
        {[...Array(6)].map((_, index) => (
          <div key={index} className="w-full h-20 animated-box-pulse" />
        ))}
      </div>
    </div>
  );
};

export default CompanyFinancingContentLoader;
