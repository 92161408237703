import classNames from 'classnames';
import { IconProps } from './type';

const SelectIcon: React.FC<IconProps> = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#7C8598"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM10.4537 6.75L8.43301 10.25C8.24056 10.5833 7.75944 10.5833 7.56699 10.25L5.54626 6.75C5.35381 6.41667 5.59437 6 5.97928 6H10.0207C10.4056 6 10.6462 6.41667 10.4537 6.75Z"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default SelectIcon;
