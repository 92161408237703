import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const CalendarDatePicker: React.FC<IconProps> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={classNames(className)}
      fill="none"
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M2.5 5.16667H13.5M4.5 13.5H11.5C12.6046 13.5 13.5 12.6046 13.5 11.5V4.5C13.5 3.39543 12.6046 2.5 11.5 2.5H4.5C3.39543 2.5 2.5 3.39543 2.5 4.5V11.5C2.5 12.6046 3.39543 13.5 4.5 13.5Z"
        stroke="#7C8598"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M4.66663 8.00004C4.66663 7.63185 4.9651 7.33337 5.33329 7.33337C5.70148 7.33337 5.99996 7.63185 5.99996 8.00004C5.99996 8.36823 5.70148 8.66671 5.33329 8.66671C4.9651 8.66671 4.66663 8.36823 4.66663 8.00004Z"
        fill="#7C8598"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M4.66663 10.6667C4.66663 10.2985 4.9651 10 5.33329 10C5.70148 10 5.99996 10.2985 5.99996 10.6667C5.99996 11.0349 5.70148 11.3334 5.33329 11.3334C4.9651 11.3334 4.66663 11.0349 4.66663 10.6667Z"
        fill="#7C8598"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M7.33329 10.6667C7.33329 10.2985 7.63177 10 7.99996 10C8.36815 10 8.66663 10.2985 8.66663 10.6667C8.66663 11.0349 8.36815 11.3334 7.99996 11.3334C7.63177 11.3334 7.33329 11.0349 7.33329 10.6667Z"
        fill="#7C8598"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M7.33329 8.00004C7.33329 7.63185 7.63177 7.33337 7.99996 7.33337C8.36815 7.33337 8.66663 7.63185 8.66663 8.00004C8.66663 8.36823 8.36815 8.66671 7.99996 8.66671C7.63177 8.66671 7.33329 8.36823 7.33329 8.00004Z"
        fill="#7C8598"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M9.99996 8.00004C9.99996 7.63185 10.2984 7.33337 10.6666 7.33337C11.0348 7.33337 11.3333 7.63185 11.3333 8.00004C11.3333 8.36823 11.0348 8.66671 10.6666 8.66671C10.2984 8.66671 9.99996 8.36823 9.99996 8.00004Z"
        fill="#7C8598"
      />
    </svg>
  );
};

export default CalendarDatePicker;
