import { PlusIcon } from 'assets/harmonic-icons';
import Button from 'harmonic-components/Button/Button';
import IconButton from 'harmonic-components/IconButton/IconButton';
import {
  useAddEntityListModal,
  UseAddEntityListModalOnSuccessProps
} from 'hooks/useAddEntityListModal';
import useFetchWatchlists from 'hooks/useFetchWatchlists';
import {
  EntityListType,
  INITIAL_SEARCH_MODEL
} from 'interfaces/SearchModel/Search';
import React, { useState } from 'react';
import { UserWatchlistType } from 'utils/watchlist';
import { useWatchlistActions } from '../../../hooks/useWatchlistActions';
import ToggleCompanyListsDropdown from '../EntityActions/ToggleCompanyListsDropdown';
import AddCompanytoWatchlistButtonContentLoader from './AddCompanyToWatchlistButtonContentLoader';

interface AddCompanyToWatchlistButtonProps {
  companyId: number;
  type?: 'button' | 'icon' | 'inline' | 'inline-small';
}

const AddCompanyToWatchlistButton: React.FC<
  AddCompanyToWatchlistButtonProps
> = ({ companyId, type = 'button' }) => {
  const [createWatchlistModalOpen, setCreateWatchlistModalOpen] =
    useState(false);
  const [watchlistActionsPopoverAnchorEl, setWatchlistActionsPopoverAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const WatchlistActionsPopoverOpen = Boolean(watchlistActionsPopoverAnchorEl);
  const handleWatchlistActionsPopoverClose = () => {
    setWatchlistActionsPopoverAnchorEl(null);
  };
  const handleWatchlistActionPopoverOpen = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    setWatchlistActionsPopoverAnchorEl(event.currentTarget);
  };
  const { watchlists: companyWatchlists, loading: companyWatchlistLoading } =
    useFetchWatchlists();
  const { addCompaniesToWatchlist, getWatchlistContainingCompanies } =
    useWatchlistActions();

  const onCreateWatchlistSuccess = async ({
    entityListId
  }: UseAddEntityListModalOnSuccessProps) => {
    await addCompaniesToWatchlist({
      watchlistId: entityListId,
      shouldNavigate: true,
      companyIds: [companyId]
    });
    handleWatchlistActionsPopoverClose();
  };
  const { showAddEntityListModal } = useAddEntityListModal({
    entityType: EntityListType.COMPANY_WATCHLIST,
    searchQuery: INITIAL_SEARCH_MODEL,
    onSuccess: onCreateWatchlistSuccess
  });

  const filteredCompanyWatchlists = companyWatchlists?.filter(
    (watchlist) =>
      watchlist?.userWatchlistType === UserWatchlistType.USER_MANAGED_WATCHLIST
  );

  if (companyWatchlistLoading)
    return <AddCompanytoWatchlistButtonContentLoader />;

  return (
    <>
      {type === 'button' && (
        <Button
          leadingIcon={PlusIcon}
          onClick={handleWatchlistActionPopoverOpen}
          type="primary"
          emphasis="high"
          label="Add to..."
          dataTestId="AddCompanyToWatchlistButton-Plus"
        />
      )}

      {type === 'icon' && (
        <IconButton
          icon={PlusIcon}
          onClick={handleWatchlistActionPopoverOpen}
          type="secondary"
          emphasis="high"
          dataTestId="AddCompanyToWatchlistButton-Plus"
        />
      )}
      {type === 'inline' && (
        <IconButton
          icon={PlusIcon}
          onClick={handleWatchlistActionPopoverOpen}
          type="secondary"
          emphasis="low"
          dataTestId="AddCompanyToWatchlistButton-Plus"
        />
      )}
      {type === 'inline-small' && (
        <IconButton
          icon={PlusIcon}
          onClick={handleWatchlistActionPopoverOpen}
          type="secondary"
          emphasis="low"
          dataTestId="AddCompanyToWatchlistButton-Plus"
          size="compact"
        />
      )}
      <ToggleCompanyListsDropdown
        open={WatchlistActionsPopoverOpen}
        anchorEl={watchlistActionsPopoverAnchorEl}
        onClose={handleWatchlistActionsPopoverClose}
        companyIds={[companyId]}
      />
    </>
  );
};

export default AddCompanyToWatchlistButton;
