import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const InvestorIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      fill="#98A0AE"
    >
      <path
        vectorEffect="non-scaling-stroke"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25 21.3C8.25 14.0098 14.1598 8.1 21.45 8.1C28.7402 8.1 34.65 14.0098 34.65 21.3C34.65 28.5902 28.7402 34.5 21.45 34.5C14.1598 34.5 8.25 28.5902 8.25 21.3ZM22.44 13.05C22.44 12.5032 21.9968 12.06 21.45 12.06C20.9032 12.06 20.46 12.5032 20.46 13.05V14.4432C19.7239 14.5574 19.0356 14.8126 18.4701 15.2584C17.6201 15.9286 17.1773 16.927 17.1773 18.1331C17.1773 19.5373 17.748 20.4909 18.6175 21.1313C19.3173 21.6467 20.2047 21.9434 20.9149 22.1809C20.9894 22.2058 21.062 22.2301 21.1322 22.2539L21.2959 22.3092C22.0845 22.5758 22.6726 22.7746 23.1084 23.0955C23.4774 23.3674 23.7427 23.7323 23.7427 24.4994C23.7427 25.1887 23.5116 25.5767 23.2041 25.8192C22.8601 26.0904 22.2863 26.2824 21.45 26.2824C20.3889 26.2824 19.7099 25.7981 19.3716 25.1909C19.1055 24.7133 18.5026 24.5418 18.025 24.8079C17.5473 25.074 17.3758 25.6769 17.6419 26.1545C18.2082 27.1709 19.195 27.9119 20.46 28.1662V29.55C20.46 30.0968 20.9032 30.54 21.45 30.54C21.9968 30.54 22.44 30.0968 22.44 29.55V28.1893C23.1762 28.0751 23.8644 27.8199 24.4299 27.3741C25.2799 26.7039 25.7227 25.7055 25.7227 24.4994C25.7227 23.0953 25.1521 22.1416 24.2825 21.5012C23.5827 20.9858 22.6953 20.6891 21.9851 20.4516C21.9106 20.4267 21.838 20.4025 21.7678 20.3787L21.6041 20.3233C20.8155 20.0567 20.2274 19.8579 19.7916 19.537C19.4226 19.2652 19.1573 18.9003 19.1573 18.1331C19.1573 17.4438 19.3884 17.0558 19.696 16.8133C20.04 16.5421 20.6137 16.3502 21.45 16.3502C22.5111 16.3502 23.1901 16.8344 23.5284 17.4416C23.7945 17.9193 24.3974 18.0908 24.875 17.8247C25.3527 17.5586 25.5242 16.9557 25.2581 16.478C24.6918 15.4616 23.705 14.7206 22.44 14.4663V13.05Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M11.55 1.5C5.17111 1.5 0 6.67111 0 13.05C0 16.9957 1.97853 20.4793 4.99761 22.5628C4.96606 22.146 4.95 21.7249 4.95 21.3C4.95 12.7704 11.4222 5.75244 19.7234 4.88925C17.6328 2.79539 14.7427 1.5 11.55 1.5Z"
      />
    </svg>
  );
};

export default InvestorIcon;
