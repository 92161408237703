import { useQuery } from '@apollo/client';
import defaultCompanyLogo from 'assets/company-profile/default-logo.svg';
import stealthLogo from 'assets/company-profile/stealth-logo.svg';
import classNames from 'classnames';
import AddCompanyToWatchlistButton from 'components/common/CompanyActions/AddCompanyToWatchlistButton';
import CompanyNotesButton from 'components/common/CompanyActions/CompanyNotesButton';
import LikeCompanyButton from 'components/common/CompanyActions/LikeCompanyButton';
import FoundingDate from 'components/common/FoundingDate';
import Location from 'components/common/Location';
import PersonUserConnections from 'components/common/PersonUserConnections';
import RelevanceScore from 'components/common/RelevanceScore';
import ReportDataIssue from 'components/common/ReportDataIssue';
import Socials from 'components/common/Social/Socials';
import {
  ReportSourceSection,
  ReportSourceView
} from 'interfaces/UserReportedDataIssue';
import { getCompanyProfileHeader } from 'queries/getCompanyProfileHeader';
import { SPLITS } from 'utils/constants';
import { isSafeHostedAsset } from 'utils/utilities';
import { DRAWER_WIDTH } from '../../DashboardLayout/styles';
import { MAX_WIDTH_CLASS, OnTabChangeType } from '../CompanyV2';

import TopNavigationBar from 'components/common/TopNavigationBar';
import UserConnectionsTooltip from 'components/common/UserConnectionsTooltip';
import useFlags from 'hooks/useFlags';
import { SearchValueToDisplayNameMap } from 'interfaces/SearchModel/Search';
import { compact, get } from 'lodash';
import getCompanyUserConnections from 'queries/getCompanyUserConnections';

import {
  GetCompanyProfileHeaderQuery,
  GetCompanyProfileHeaderQueryVariables,
  GetCompanyUserConnectionsV2Query,
  GetCompanyUserConnectionsV2QueryVariables,
  GetCompanyUserConnectionsV2_UserFragment
} from '__generated__/graphql';
import { ReactComponent as LeftArrowIcon } from 'assets/bx-arrow-back.svg';
import { Badge } from 'common/components';
import AddCompanyToAffinityButton from 'components/common/CompanyActions/AddCompanyToAffinityButton';
import InvestorViewToggle from 'components/common/InvestorViewToggle';
import Avatar from 'harmonic-components/Avatar/Avatar';
import Tabs from 'harmonic-components/Tabs/Tabs';
import { EntityType } from 'hooks/useDashboardLocation';
import useStore from 'stores/zustandStore';
import { getFundingStageBadgeColor } from 'utils/funding';
import { urnFrom } from 'utils/urn';
import CompanyAbout from '../CompanyOverview/CompanyAbout';

export enum CompanyNavbarOptions {
  OVERVIEW = 'OVERVIEW',
  TEAM = 'TEAM',
  FINANCING = 'FINANCING',
  SIMILAR_COMPANIES = 'SIMILAR_COMPANIES',
  TRACTION_METRICS = 'TRACTION_METRICS',
  CONTACT = 'CONTACT',
  CONNECTIONS = 'CONNECTIONS',
  LINEAGE = 'LINEAGE'
}

const nonStealthNavbarOptions = [
  {
    value: CompanyNavbarOptions.OVERVIEW,
    label: 'Overview'
  },
  {
    value: CompanyNavbarOptions.TEAM,
    label: 'Team '
  },

  {
    value: CompanyNavbarOptions.FINANCING,
    label: 'Financing'
  },
  {
    value: CompanyNavbarOptions.TRACTION_METRICS,
    label: 'Traction Metrics'
  },
  {
    value: CompanyNavbarOptions.CONTACT,
    label: 'Contact'
  },
  {
    value: CompanyNavbarOptions.LINEAGE,
    label: 'Lineage'
  },
  {
    value: CompanyNavbarOptions.SIMILAR_COMPANIES,
    label: 'Similar Companies',
    inBeta: true
  },
  {
    value: CompanyNavbarOptions.CONNECTIONS,
    label: 'Network',
    inBeta: true
  }
];

export const stealthNavbarOptions = [
  {
    value: CompanyNavbarOptions.OVERVIEW,
    label: 'Overview'
  },
  {
    value: CompanyNavbarOptions.CONTACT,
    label: 'Contact'
  },
  {
    value: CompanyNavbarOptions.CONNECTIONS,
    label: 'Network',
    inBeta: true
  }
];

interface CompanyHeaderProps {
  companyId: number;
  selectedTab: string;
  isStealthCompany?: boolean;
  onTabChange: OnTabChangeType;
  isSticky?: boolean;
}
const CompanyHeader: React.FC<CompanyHeaderProps> = ({
  companyId,
  isSticky,
  isStealthCompany,
  selectedTab,
  onTabChange
}) => {
  const { enabled: enableCompanyEngagementTab } = useFlags(
    SPLITS.enableCompanyEngagementTab
  );
  const { enabled: enableCompanyLineageTab } = useFlags(
    SPLITS.showLineageOnCompanyPage
  );
  const { enabled: enablePushToAffinity } = useFlags(
    SPLITS.enablePushToAffinity
  );
  const previousPageTitle = useStore((state) => state.previousPageTitle);

  const { data } = useQuery<
    GetCompanyProfileHeaderQuery,
    GetCompanyProfileHeaderQueryVariables
  >(getCompanyProfileHeader, {
    variables: {
      id: companyId
    },
    fetchPolicy: 'cache-only'
  });

  const { data: userConnectionsData } = useQuery<
    GetCompanyUserConnectionsV2Query,
    GetCompanyUserConnectionsV2QueryVariables
  >(getCompanyUserConnections, {
    variables: { id: companyId },
    fetchPolicy: 'cache-only'
  });
  const userConnections: GetCompanyUserConnectionsV2_UserFragment[] =
    compact(
      compact(userConnectionsData?.getCompanyById?.userConnections).map(
        (connection) => connection.user
      )
    ) ?? [];

  if (!data?.getCompanyById) return null;

  const logo = data.getCompanyById.logoUrl;
  const companyName = data.getCompanyById.name;
  const location = data.getCompanyById.location;
  const foundingDate = data.getCompanyById.foundingDate?.date;
  const companyStage = data.getCompanyById.stage;
  const convertedFundingStage =
    (companyStage && get(SearchValueToDisplayNameMap, companyStage)) ?? '';
  const website = data.getCompanyById.website;
  const linkedin = data.getCompanyById.socials?.linkedin;
  const crunchbase = data.getCompanyById.socials?.crunchbase;
  const pitchbook = data.getCompanyById.socials?.pitchbook;
  const angellist = data.getCompanyById.socials?.angellist;
  const twitter = data.getCompanyById.socials?.twitter;
  const facebook = data.getCompanyById.socials?.facebook;
  const relevanceScore = data.getCompanyById.relevanceScore;
  const instagram = data.getCompanyById.socials?.instagram;
  const investorUrn = data.getCompanyById.investorUrn;

  const navbarOptions = isStealthCompany
    ? stealthNavbarOptions
    : nonStealthNavbarOptions;

  const currentOption = navbarOptions.find(
    (option) => option.value === selectedTab
  )?.value as CompanyNavbarOptions;

  const companyLogoUrl = isStealthCompany
    ? stealthLogo
    : isSafeHostedAsset(logo ?? '')
    ? logo
    : defaultCompanyLogo;

  let filteredNavbarOptions = navbarOptions;
  if (!enableCompanyEngagementTab) {
    filteredNavbarOptions = filteredNavbarOptions.filter(
      (option) => option.value !== CompanyNavbarOptions.CONNECTIONS
    );
  }
  if (enableCompanyEngagementTab) {
    filteredNavbarOptions = filteredNavbarOptions.filter(
      (option) => option.value !== CompanyNavbarOptions.CONTACT
    );
  }
  if (!enableCompanyLineageTab) {
    filteredNavbarOptions = filteredNavbarOptions.filter(
      (option) => option.value !== CompanyNavbarOptions.LINEAGE
    );
  }

  const navigationTitle = previousPageTitle
    ? `${previousPageTitle} / ${companyName}  /`
    : companyName;

  const mainCompanyActions = (
    <div className="flex flex-row items-center gap-g40">
      <AddCompanyToWatchlistButton companyId={companyId} />
      {enablePushToAffinity && (
        <AddCompanyToAffinityButton
          companyId={companyId}
          isDisabled={isStealthCompany ?? false}
          tooltipText={
            isStealthCompany
              ? 'Only companies with a website URL can be added'
              : undefined
          }
        />
      )}
      <LikeCompanyButton companyId={companyId} />

      <CompanyNotesButton companyId={companyId} />
      <ReportDataIssue
        showCircularBorder
        reportParams={{
          companyUrn: `urn:company:harmonic:${companyId}`,
          reportSourceView: ReportSourceView.COMPANY,
          reportSourceSection: ReportSourceSection[currentOption]
        }}
        placeholderText="This company shows 2 funding rounds, but there are actually 3"
      />
    </div>
  );

  return (
    <div>
      <TopNavigationBar
        logoUrl={companyLogoUrl as string}
        name={navigationTitle as string}
      />
      <div
        style={{ paddingTop: '53px' }}
        data-testid="CompanyHeader"
        className={`${MAX_WIDTH_CLASS}`}
      >
        <div className="flex flex-col pb-p60 gap-g50 pt-p80">
          <div className="flex flex-row items-start justify-between">
            <div className="flex gap-g40 items-center">
              <Avatar src={companyLogoUrl ?? ''} alt="Company Logo" />
              <p
                className={classNames(
                  'typography-title-medium text-content-title'
                )}
              >
                {companyName}
              </p>
              {investorUrn && (
                <InvestorViewToggle
                  baseEntityUrn={urnFrom(EntityType.COMPANY, companyId)}
                  investorUrn={investorUrn}
                />
              )}
            </div>
            {mainCompanyActions}
          </div>
          <div>
            <div className="flex items-center space-x-2"></div>
            <div className="flex flex-row items-center gap-x-g40">
              {relevanceScore && <RelevanceScore score={relevanceScore} />}
              {convertedFundingStage && (
                <Badge
                  label={convertedFundingStage}
                  color={getFundingStageBadgeColor(convertedFundingStage)}
                  size="large"
                  intensity="subtle"
                  dataTestId="CompanyHeader-FundingStage"
                />
              )}
            </div>
          </div>

          <div className="flex gap-x-g50 gap-y-g30 flex-col xl:flex-row">
            <div className="flex gap-g50">
              <Socials
                id={companyId}
                type="company"
                websiteUrl={website?.url}
                linkedinUrl={linkedin?.url}
                crunchbaseUrl={crunchbase?.url}
                pitchbookUrl={pitchbook?.url}
                angellistUrl={angellist?.url}
                twitterUrl={twitter?.url}
                facebookUrl={facebook?.url}
                instagramUrl={instagram?.url}
              />
              <div className="flex items-center">
                <Location
                  city={location?.city}
                  state={location?.state}
                  country={location?.country}
                  fontSize="small"
                />
                {foundingDate && (
                  <div className="ml-p50">
                    <FoundingDate date={foundingDate} />
                  </div>
                )}
              </div>
            </div>
            {userConnectionsData && userConnections.length > 0 && (
              <div className="flex items-center gap-g20">
                <button
                  onClick={() => onTabChange(CompanyNavbarOptions.CONNECTIONS)}
                >
                  <PersonUserConnections userConnections={userConnections} />
                </button>
                <div>
                  <UserConnectionsTooltip />
                </div>
              </div>
            )}
          </div>
          <CompanyAbout companyId={companyId} />
        </div>

        {/* MOBILE NAV */}
        {currentOption != CompanyNavbarOptions.OVERVIEW && (
          <button
            onClick={() => onTabChange(CompanyNavbarOptions.OVERVIEW)}
            className="flex sm:hidden text-content-strong typography-label-large-strong border-content-strong items-center"
          >
            <LeftArrowIcon className="w-4 h-4 mr-2" />
            Back to Overview
          </button>
        )}
        {/* NAVBAR */}
        <div
          className={classNames('hidden sm:flex flex-col pointer-events-none', {
            'fixed z-10 mb-20 left-0 right-0 bg-transparent': isSticky
          })}
          style={
            isSticky
              ? {
                  paddingLeft: DRAWER_WIDTH,
                  top: '53px'
                }
              : undefined
          }
        >
          <div
            className={classNames('bg-white pointer-events-auto', {
              'shadow-search': isSticky
            })}
          >
            {isSticky && (
              <div className="flex justify-between pt-p50 px-p70 pb-p20">
                <div className="flex gap-g50 items-center">
                  <Avatar
                    src={companyLogoUrl ?? ''}
                    alt="Company Logo"
                    size="large"
                  />
                  <p
                    className={classNames(
                      'typography-label text-content-title'
                    )}
                  >
                    {companyName}
                  </p>
                </div>
                {mainCompanyActions}
              </div>
            )}

            <div
              className={classNames('flex w-full', {
                'w-full flex': isSticky,
                [MAX_WIDTH_CLASS]: isSticky
              })}
            >
              <div className="-ml-p70 pl-p70 border-b border-solid border-border-weak h-inherit" />
              <Tabs
                tabs={filteredNavbarOptions.map((navbar) => ({
                  ...navbar,
                  variation: 'label'
                }))}
                selectedTabValue={selectedTab}
                onTabChange={(newTabValue) =>
                  onTabChange(newTabValue as CompanyNavbarOptions)
                }
              />
              <div className="-mr-p70 pr-p70 border-b border-solid border-border-weak h-inherit" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyHeader;
