import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const BusinessIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      fill="#484E5B"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        vectorEffect="non-scaling-stroke"
        d="M3 4.75C3 3.7835 3.7835 3 4.75 3H13.25C14.2165 3 15 3.7835 15 4.75V7.25H19.25C20.2165 7.25 21 8.0335 21 9V18.5H22.25C22.6642 18.5 23 18.8358 23 19.25C23 19.6642 22.6642 20 22.25 20H1.75C1.33579 20 1 19.6642 1 19.25C1 18.8358 1.33579 18.5 1.75 18.5H3V4.75ZM15 18.5H19.5V9C19.5 8.86193 19.3881 8.75 19.25 8.75H15V18.5ZM7 8.75C7 8.33579 7.33579 8 7.75 8H10.25C10.6642 8 11 8.33579 11 8.75C11 9.16421 10.6642 9.5 10.25 9.5H7.75C7.33579 9.5 7 9.16421 7 8.75ZM7 12.75C7 12.3358 7.33579 12 7.75 12H10.25C10.6642 12 11 12.3358 11 12.75C11 13.1642 10.6642 13.5 10.25 13.5H7.75C7.33579 13.5 7 13.1642 7 12.75Z"
      />
    </svg>
  );
};

export default BusinessIcon;
