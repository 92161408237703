import classNames from 'classnames';
import { HarmonicIcon } from './type';
const ChevronUp: HarmonicIcon = ({ applyCurrentColor = true, className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#7C8598"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M5.33333 9.33334L7.76429 6.90238C7.89447 6.7722 8.10552 6.7722 8.2357 6.90238L10.6667 9.33334"
      />
    </svg>
  );
};
export default ChevronUp;
