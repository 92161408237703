import { ApolloCache, useMutation, useQuery } from '@apollo/client';
import {
  ClearCompanyNetNewMutation,
  ClearCompanyNetNewMutationVariables,
  ClearNetNewBySavedSearchIdOrUrnMutation,
  ClearNetNewBySavedSearchIdOrUrnMutationVariables,
  ClearPersonNetNewMutation,
  ClearPersonNetNewMutationVariables,
  GetNetNewCompaniesQueryVariables,
  GetNetNewCountsQuery,
  SavedSearchResultsConnection,
  SearchType
} from '__generated__/graphql';
import client from 'config/client';
import {
  CLEAR_COMPANY_NET_NEW,
  CLEAR_NET_NEW_FOR_SEARCH,
  CLEAR_PERSON_NET_NEW,
  GET_NET_NEW_COUNTS
} from 'queries/netNew';
import { useEffect, useState } from 'react';
import { getIdFromUrn } from 'utils/urn';

const updateCacheAfterClear = (
  existingCache: ApolloCache<any>,
  savedSearchUrn: string,
  id: number
) => {
  // Update the results in the SavedSearch
  existingCache.modify({
    id: client.cache.identify({
      __typename: 'SavedSearch',
      id: getIdFromUrn(savedSearchUrn)
    }),
    fields: {
      results(current) {
        const currentEdges: SavedSearchResultsConnection['edges'] =
          current.edges;

        const updatedEdges = currentEdges.map((edge) =>
          edge.node.netNewId === id
            ? {
                ...edge,
                node: {
                  ...edge.node,
                  netNewId: null
                }
              }
            : edge
        );

        return { ...current, edges: updatedEdges };
      }
    }
  });

  // Update the NetNewCount
  existingCache.modify({
    id: client.cache.identify({
      __typename: 'NetNewCount',
      savedSearchUrn
    }),
    fields: {
      count(current) {
        return current - 1;
      }
    },
    broadcast: false
  });
};

const useNetNewCounts = () => {
  const { data } = useQuery<
    GetNetNewCountsQuery,
    GetNetNewCompaniesQueryVariables
  >(GET_NET_NEW_COUNTS);

  const [clearNetNewForSearch, { loading: clearSearchLoading }] = useMutation<
    ClearNetNewBySavedSearchIdOrUrnMutation,
    ClearNetNewBySavedSearchIdOrUrnMutationVariables
  >(CLEAR_NET_NEW_FOR_SEARCH);

  const [clearCompanyNetNew, { loading: companyLoading }] = useMutation<
    ClearCompanyNetNewMutation,
    ClearCompanyNetNewMutationVariables
  >(CLEAR_COMPANY_NET_NEW);

  const [clearPersonNetNew, { loading: personLoading }] = useMutation<
    ClearPersonNetNewMutation,
    ClearPersonNetNewMutationVariables
  >(CLEAR_PERSON_NET_NEW);

  const onClearNetNewForSearch = (searchUrn: string) => {
    clearNetNewForSearch({
      variables: { idOrUrn: searchUrn },
      update: (existingCache) => {
        existingCache.modify({
          id: client.cache.identify({
            __typename: 'NetNewCount',
            savedSearchUrn: searchUrn
          }),
          fields: {
            count() {
              return 0;
            }
          },
          broadcast: false
        });
      }
    });
  };

  const onClearNetNewById = ({
    id,
    savedSearchUrn,
    type
  }: {
    id: number;
    savedSearchUrn: string;
    type: SearchType;
  }) => {
    if (!savedSearchUrn) {
      return;
    }

    const clearNetNew =
      type === SearchType.COMPANIES_LIST
        ? clearCompanyNetNew
        : clearPersonNetNew;

    clearNetNew({
      variables: { netNewIds: [id] },
      update: (existingCache) =>
        updateCacheAfterClear(existingCache, savedSearchUrn, id)
    });
  };

  const [searchMap, setSearchMap] = useState<Map<string, number>>(new Map());

  useEffect(() => {
    setSearchMap(
      new Map(
        data?.getNetNewCounts.map(({ savedSearchUrn, count }) => [
          savedSearchUrn,
          count
        ])
      )
    );
  }, [data]);

  return {
    getNetNewCountForSearch: (searchUrn: string) => searchMap.get(searchUrn),
    onClearNetNewForSearch,
    onClearNetNewById,
    loading: clearSearchLoading || companyLoading || personLoading
  };
};

export default useNetNewCounts;
