import classNames from 'classnames';
import { isNil } from 'lodash';
import { SVGProps } from 'react';

export interface SegmentControlOption {
  label: string;
  value: string;
  leadingIcon?: React.FC<SVGProps<SVGSVGElement>>;
  badgeCount?: number;
  disabled?: boolean;
}

interface SegmentedControlProps {
  selectedOption: string;
  onChange: (newOption: string) => void;
  options: SegmentControlOption[];
  variant?: 'default' | 'compact';
}
const SegmentedControl: React.FC<SegmentedControlProps> = ({
  selectedOption,
  options,
  onChange,
  variant = 'default'
}) => {
  return (
    <div
      className={classNames(
        'inline-flex p-p10 bg-surface-raised',
        variant === 'default' && 'rounded-br90',
        variant === 'compact' && 'rounded-br20'
      )}
    >
      {options.map((option) => {
        const LeadingIcon = option.leadingIcon;
        const badgeCount = option.badgeCount;

        return (
          <button
            key={option.value}
            disabled={option.disabled}
            onClick={() => onChange(option.value)}
            aria-selected={option.value === selectedOption}
            data-testid={`segmented-control-${option.value}`}
            className={classNames(
              'text-content-default typography-label bg-segmentedControl-enabled flex gap-g10 items-center',
              'hover:bg-segmentedControl-hover active:bg-segmentedControl-pressed focus:shadow-int-focus-outside',
              'aria-selected:bg-segmentedControl-selected aria-selected:shadow-int-elevation-enabled aria-selected:hover:shadow-int-elevation-hover',
              'disabled:text-content-muted disabled:aria-selected:shadow-int-elevation-disabled disabled:aria-selected:text-content-muted',
              variant === 'default' && 'rounded-br90 px-p50 py-p30',
              variant === 'compact' && 'rounded-br20 px-p40 py-p20'
            )}
          >
            {LeadingIcon && <LeadingIcon className="w-4 h-4" />}
            <span className="px-p20">{option.label}</span>
            {!isNil(badgeCount) && (
              <span
                className="px-p20 bg-surface-sentiment-neutral typography-label-small rounded-br20"
                data-testid={`badge-count-${option.label}`}
              >
                {badgeCount}
              </span>
            )}
          </button>
        );
      })}
    </div>
  );
};

export default SegmentedControl;
