import { Tooltip as MaterialTooltip } from '@material-ui/core';
import classNames from 'classnames';
import { isBoolean } from 'lodash';

export interface TooltipProps {
  title?: string;
  body?: string | React.ReactElement;
  shortcutChipText?: string;
  interactive?: boolean;
  alignment?: 'left' | 'right' | 'top' | 'bottom';
  children: React.ReactNode;
  open?: boolean;
  enterDelayMs?: number;
}
/**
 * @param children - The element that will trigger the tooltip
 * @param body - what the tooltip will display
 */
export const Tooltip: React.FC<TooltipProps> = ({
  title,
  body,
  shortcutChipText,
  children,
  alignment,
  interactive,
  open,
  enterDelayMs = 0
}) => {
  let openProps = {};
  if (isBoolean(open)) openProps = { open };
  return (
    <MaterialTooltip
      {...openProps}
      placement={alignment}
      arrow
      interactive={interactive}
      enterDelay={enterDelayMs}
      enterNextDelay={enterDelayMs}
      classes={{
        tooltip: 'bg-surface-inverted-elevated p-p40 rounded-br40',
        arrow: 'text-surface-inverted-elevated'
      }}
      title={
        <div
          className={classNames('flex justify-between gap-g40', {
            'items-center': !body
          })}
        >
          <div>
            {title && (
              <p className="text-content-inverted-title typography-paragraph-small-strong">
                {title}
              </p>
            )}
            {body && (
              <div className="text-content-inverted-default typography-paragraph-small">
                {body}
              </div>
            )}
          </div>
          {shortcutChipText && (
            <div>
              <button className="px-p40 py-p10 rounded-br20 bg-surface-inverted-raised">
                <span className="px-p10 text-content-inverted-default typography-label-small">
                  {shortcutChipText}
                </span>
              </button>
            </div>
          )}
        </div>
      }
    >
      <div>{children}</div>
    </MaterialTooltip>
  );
};
