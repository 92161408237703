import classNames from 'classnames';
import { IconProps } from './type';

const ArrowRightIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke="#1A1D23"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M9.55479 3.88818L13.2501 7.58343C13.5104 7.84378 13.5104 8.26589 13.2501 8.52624L9.55479 12.2215M12.8881 8.05484H2.55479"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowRightIcon;
