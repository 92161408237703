import { gql } from '@apollo/client';

export const getCompaniesNames = gql`
  query GetCompaniesNames($ids: [Int!]!) {
    getCompaniesByIds(ids: $ids, extended: false) {
      id
      name
    }
  }
`;
