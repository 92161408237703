import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const CalendarIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#7C8598"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.16667 1.3335C5.44281 1.3335 5.66667 1.55735 5.66667 1.8335V2.66683H10.3333V1.8335C10.3333 1.55735 10.5572 1.3335 10.8333 1.3335C11.1095 1.3335 11.3333 1.55735 11.3333 1.8335V2.66683H12.8333C13.4777 2.66683 14 3.18916 14 3.8335V12.8335C14 13.4778 13.4777 14.0002 12.8333 14.0002H3.16667C2.52233 14.0002 2 13.4778 2 12.8335V3.8335C2 3.18916 2.52233 2.66683 3.16667 2.66683H4.66667V1.8335C4.66667 1.55735 4.89052 1.3335 5.16667 1.3335ZM4.66667 5.8335C4.66667 5.55735 4.89052 5.3335 5.16667 5.3335H10.8333C11.1095 5.3335 11.3333 5.55735 11.3333 5.8335C11.3333 6.10964 11.1095 6.3335 10.8333 6.3335H5.16667C4.89052 6.3335 4.66667 6.10964 4.66667 5.8335Z"
      />
    </svg>
  );
};

export default CalendarIcon;
