import { Permission } from 'hooks/usePermissions';
import { UserSettings } from 'interfaces/DataModel/MidtierResource';
import { ColumnHeaderOrder } from 'interfaces/Grid';
import {
  INITIAL_COMPANY_SEARCH_SORT_QUERY,
  ISearchModel,
  ISearchSort
} from 'interfaces/SearchModel/Search';
import { Location } from 'react-router-dom';
import { HideAllSearchesType } from 'utils/utilities';
import { create } from 'zustand';
import { useShallowStoreGeneric } from './util';

interface LoggingFields {
  didLogGridReady?: boolean;
  didLogGridFirstDataRendered?: boolean;
  didLogExtendedGridFirstDataRendered?: boolean;
  didLogGridDataLoadingStarted?: boolean;
  didLogGridDataLoadingCompleted?: boolean;
  didLogGridExtendedDataLoadingStarted?: boolean;
  didLogGridExtendedDataLoadingCompleted?: boolean;
}

interface IZustandStoreState {
  hiddenSearches: HideAllSearchesType;
  shouldRefreshHeaderSavedSearch: boolean;
  isCustomizeColumnModalOpen: boolean;
  isSearchQueryBuilderOpen: boolean;
  logging?: LoggingFields;
  didChangeRoute: boolean;
  originalColumns: ColumnHeaderOrder[]; // TODO: Remove duplicated tableColumns here, use the table store
  pageTitle: string;
  //When we navigate from grid to profiles, we want to show the tile of saved search/watchlist from where we are navigating
  previousPageTitle: string;
  // We are fetching the saved search model from cache or api.
  // Grid should not load before this
  savedSearchModelLoading: boolean;
  disableHotkeys: boolean;
  tableColumns: ColumnHeaderOrder[]; // TODO: Remove duplicated tableColumns here, use the table store
  userUrn: string;
  customerUrn: string;
  userSettings?: Partial<UserSettings>;
  isUpgradeToProModalOpen: boolean;
  upgradeModalMode?: Permission;
  // To keep track of sort field for company dashboard page.
  // For other page, things are cached on local storage as well as saved on backend.
  companyDashboardPageSearchSort: ISearchSort[];
  // It stores the version of search query primarily from CACHE. Fallbacks to API. If CACHE and API are different, it chooes CACHE version.
  initialSearchModel: Partial<ISearchModel>;
  // Stores the version of current tab search query only from API.
  activeTabSearchModelFromApi: Partial<ISearchModel>;
  isAffinityFieldsDrawerOpen: boolean;
  affinityFieldsDrawerCompanyId: number;
  editStoreData<Key extends keyof IZustandStoreState>(
    key: Key,
    payload: IZustandStoreState[Key]
  ): void;
  displayUpgradeModal(permissionMode?: Permission): void;
  displayAffinityFieldsDrawer(companyId: number): void;
  setLogging(logging: LoggingFields): void;
  resetLogging(): void;
  settingsCalledFromLocation?: Location;
  netNewFilterSearchId?: number;
}

const useStore = create<IZustandStoreState>((set) => ({
  hiddenSearches: [],
  isCustomizeColumnModalOpen: false,
  isSearchQueryBuilderOpen: false,
  shouldRefreshHeaderSavedSearch: false,
  logging: {},
  didChangeRoute: false,
  disableHotkeys: false,
  originalColumns: [],
  pageTitle: '',
  previousPageTitle: '',
  tableColumns: [],
  isUpgradeToProModalOpen: false,
  userUrn: '',
  customerUrn: '',
  companyDashboardPageSearchSort: INITIAL_COMPANY_SEARCH_SORT_QUERY,
  initialSearchModel: {},
  activeTabSearchModelFromApi: {},
  savedSearchModelLoading: true,
  userSettings: {},
  isAffinityFieldsDrawerOpen: false,
  affinityFieldsDrawerCompanyId: -1,
  netNewTab: undefined,
  editStoreData: (key, payload) =>
    set((state) => ({
      ...state,
      [key]: payload
    })),
  displayUpgradeModal: (permission: Permission) =>
    set((state) => ({
      ...state,
      isUpgradeToProModalOpen: true,
      upgradeModalMode: permission
    })),
  displayAffinityFieldsDrawer: (companyId: number) => {
    set((state) => ({
      ...state,
      isAffinityFieldsDrawerOpen: true,
      affinityFieldsDrawerCompanyId: companyId
    }));
  },
  setLogging: (logging: LoggingFields) => {
    set((state) => ({
      ...state,
      logging: {
        ...state.logging,
        ...logging
      }
    }));
  },
  resetLogging: () => {
    set((state) => ({
      ...state,
      logging: undefined
    }));
  }
}));

// Only re-render the component when the selected keys change
export const useShallowStore = (keys: (keyof IZustandStoreState)[]) =>
  useShallowStoreGeneric(useStore, keys);

export default useStore;
