import classNames from 'classnames';
import { IconProps } from './type';

const Number: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      stroke="#7C8598"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M7.29167 3.125L5.625 16.875M14.375 3.125L12.7083 16.875M3.125 6.45833H16.875M16.875 13.5417H3.125"
      />
    </svg>
  );
};

export default Number;
