/* eslint-disable react/no-unescaped-entities */
import React from 'react';

const AgreementText: React.FC = () => {
  return (
    <>
      <p>
        This is a contract between you (“Customer,” “you,” “You,” or “User”) and
        Harmonic AI, Inc. (“Company”, "we," or "us").
      </p>
      <br />
      <p>
        By clicking the button below or by otherwise accessing or using
        harmonic.ai or any of Company’s related services (collectively, the
        "Services"), you hereby acknowledge and expressly agree to be bound by
        these Terms and Conditions and any other applicable policies,
        guidelines, or requirements that may be posted on the harmonic.ai
        website or provided in connection with the Services ("Terms").
      </p>
      <br />
      <p>
        If You are entering into these Terms on behalf of a company,
        organization, or other legal entity (collectively, "Entity"), you
        represent and warrant that you have the authority to bind the Entity to
        these Terms, in which case the terms “you”, “You”, or “User” shall refer
        to the Entity. If you do not have such authority, or if you do not agree
        with these Terms, you must not click the "Accept agreement" button or
        checkbox, and you may not access or use the Services.
      </p>

      <div className="mt-4">
        <h2 className="font-semibold -ml-5 ">
          <span className="mr-2">1.</span>Term and Termination Term
        </h2>
        <div className="mt-3 flex">
          <p className="min-w-10">1.1</p>
          <p>
            <span className="underline">Term.</span> This Agreement starts upon
            acceptance of this Agreement and, unless extended by us in our sole
            discretion, will end in 14 days. Either party may end the term early
            upon written notice.
          </p>
        </div>
        <div className="mt-1.5 flex">
          <p className="min-w-10">1.2</p>
          <p>
            <span className="underline">Effect of Termination. </span> Upon
            termination: You will no longer have a right to use the Services.
          </p>
        </div>
        <div className="mt-1.5 flex">
          <p className="min-w-10">1.3</p>
          <p>
            <span className="underline">Survival.</span> All sections of this
            Agreement which by their nature should survive termination will
            survive termination, including, without limitation, accrued rights
            to payment, confidentiality obligations, warranty disclaimers, and
            limitations of liability.
          </p>
        </div>
      </div>
      <div className="mt-4">
        <h2 className="font-semibold -ml-5 ">
          <span className="mr-1">2.</span> Restrictions
        </h2>
        <div className="mt-2 flex">
          <p className="min-w-10">2.1</p>
          <p>
            <span className="underline">Data Use.</span> Use of data provided by
            Harmonic to You is for private use only. You shall not use,
            transfer, distribute, publish, or display data provided by Harmonic
            for anything other than Your personal use. You may not directly or
            indirectly (a) license, sublicense, sell, offer to sell, distribute
            or provide data to any third parties or (b) display, publish, or
            otherwise provide any data provided by Company without Company’s
            prior written approval.
          </p>
        </div>
        <div className="mt-1.5 flex">
          <p className="min-w-10">2.2</p>
          <p>
            <span className="underline">Algorithm Input. </span> Results from an
            Algorithm (machine learning, artificial intelligence, or other
            model) that used Company data for training or as input shall be
            subject to the same restrictions as data provided by Company in
            Section 2.1.
          </p>
        </div>
      </div>
      <div className="mt-4">
        <h2 className="font-semibold -ml-5 ">
          <span className="mr-1">3.</span> Account Usage
        </h2>

        <div className="mt-2 flex">
          <p>
            <span className="underline">Accounts.</span> You are responsible for
            actions on user accounts and for user compliance with this
            Agreement. You must protect confidentiality of passwords and logins.
            You will promptly notify us if You suspect or know of fraudulent
            activity with accounts, passwords, or credentials, or if they become
            compromised.
          </p>
        </div>
      </div>
      <div className="mt-4">
        <h2 className="font-semibold -ml-5 ">
          <span className="mr-1">4.</span> General
        </h2>
        <div className="mt-2 flex">
          <p className="min-w-10">4.1</p>
          <p>
            <span className="underline">Terms of Service. </span> Harmonic AI,
            Inc. reserves the right to modify, amend, or update the Terms of
            Service at any time, at its sole discretion, without prior notice.
            Such changes will be effective upon posting on the harmonic.ai
            website or otherwise notifying you, and your continued access or use
            of the Services after such posting or notification constitutes your
            acceptance of the revised Terms of Service.
          </p>
        </div>
        <div className="mt-1.5 flex">
          <p className="min-w-10">4.2</p>
          <p>
            <span className="underline">Entire Agreement. </span> This Agreement
            contains the full understanding between the parties and supersedes
            prior agreements, oral or written. Any modification or amendment to
            this Agreement shall be in writing and signed by the parties. This
            Agreement creates no third-party beneficiary rights.
          </p>
        </div>
        <div className="mt-1.5 flex">
          <p className="min-w-10">4.3</p>
          <p>
            <span className="underline">Law.</span> This Agreement is governed
            by and construed in accordance with the laws of California without
            regard to its choice or conflict of laws provisions.
          </p>
        </div>
      </div>
    </>
  );
};

export default AgreementText;
