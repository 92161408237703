import classNames from 'classnames';
import { IconProps } from './type';

const ArrowOutOfBoxIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke="#677183"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M7.99999 2.5V10M7.99999 2.5L11 5.5M7.99999 2.5L5 5.5M13.5 8.5V12.8333C13.5 13.2015 13.2015 13.5 12.8333 13.5H3.16667C2.79848 13.5 2.5 13.2015 2.5 12.8333V8.5"
      />
    </svg>
  );
};

export default ArrowOutOfBoxIcon;
