import { Dialog, Transition } from '@headlessui/react';
import { CrossLargeIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import IconButton from 'harmonic-components/IconButton/IconButton';
import React, { Fragment } from 'react';

export enum ModalType {
  default = 'default',
  inverted = 'inverted'
}

export interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  preventCloseOnOutsideClick?: boolean;
  showCloseBtn?: boolean;
  type?: ModalType;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  showCloseBtn = true,
  preventCloseOnOutsideClick,
  type = ModalType.default
}) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative"
        onClose={
          !preventCloseOnOutsideClick ? onClose : () => Function.prototype
        }
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            data-testid="Modal-Background"
            className="fixed inset-0 bg-black bg-opacity-25 z-1400"
          />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto z-1400">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  'relative rounded-br40 text-left align-middle shadow-static-elevation-floating transition-all min-w-96 max-h-full',
                  type === 'default' && 'bg-surface-default',
                  type === 'inverted' && 'bg-surface-inverted-elevated'
                )}
              >
                {children}
                {showCloseBtn && onClose && (
                  <div className="absolute right-0 top-0 pr-p40 pt-p40">
                    <IconButton
                      dataTestId="Modal-Close-Button"
                      icon={CrossLargeIcon}
                      onClick={onClose}
                      type="secondary"
                      size="compact"
                      emphasis="low"
                    />
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
