import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const ToastNegativeIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      fill="none"
    >
      <path
        vectorEffect="non-scaling-stroke"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.66666 10C1.66666 5.39763 5.39762 1.66667 9.99999 1.66667C14.6024 1.66667 18.3333 5.39763 18.3333 10C18.3333 14.6024 14.6024 18.3333 9.99999 18.3333C5.39762 18.3333 1.66666 14.6024 1.66666 10Z"
        fill="#DD2619"
      />
      <path
        vectorEffect="non-scaling-stroke"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.94194 7.05807C7.69786 6.81399 7.30214 6.81399 7.05806 7.05807C6.81398 7.30214 6.81398 7.69787 7.05806 7.94195L9.11612 10L7.05806 12.0581C6.81398 12.3021 6.81398 12.6979 7.05806 12.942C7.30214 13.186 7.69786 13.186 7.94194 12.942L10 10.8839L12.0581 12.942C12.3021 13.186 12.6979 13.186 12.9419 12.942C13.186 12.6979 13.186 12.3021 12.9419 12.0581L10.8839 10L12.9419 7.94195C13.186 7.69787 13.186 7.30214 12.9419 7.05807C12.6979 6.81399 12.3021 6.81399 12.0581 7.05807L10 9.11612L7.94194 7.05807Z"
        fill="#F9FAFB"
      />
    </svg>
  );
};

export default ToastNegativeIcon;
