import {
  ApolloCache,
  ApolloError,
  FetchResult,
  NormalizedCacheObject,
  Reference,
  useMutation
} from '@apollo/client';
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common';
import {
  PeopleWatchlistEntryEdge,
  PeopleWatchlistFragment,
  RemovePeopleFromWatchlistWithIdsMutation,
  RemovePeopleFromWatchlistWithIdsMutationVariables
} from '__generated__/graphql';
import client from 'config/client';
import { REMOVE_PEOPLE_FROM_WATCHLIST } from 'queries/removePeopleFromWatchlist';
import { useCallback } from 'react';
import { useShallowTableStore } from 'stores/tableStore';
import { displayToast } from 'utils/toasts';
import { getIdFromUrn } from 'utils/urn';
import { GET_PEOPLE_WATCHLIST_FRAGMENT } from '../../queries/getPeopleWatchlists';

interface UseRemovePeopleFromListResult {
  removePeopleFromList: (
    watchlist: string,
    people: number[]
  ) => Promise<FetchResult<RemovePeopleFromWatchlistWithIdsMutation>>;
  loading: boolean;
  error: ApolloError | undefined;
}

export const clearPeopleFromWatchlistPeopleCache = (
  cache: ApolloCache<NormalizedCacheObject>,
  filterPeopleFromEdges: (
    edges: Readonly<PeopleWatchlistEntryEdge[]>,
    readField: ReadFieldFunction
  ) => PeopleWatchlistEntryEdge[],
  watchlistId: string
) => {
  cache.modify({
    id: `PeopleWatchlist:${watchlistId}`,
    fields: {
      personEntries: (cachedPeopleEntries, { readField }) => {
        const edges =
          readField<PeopleWatchlistEntryEdge[]>('edges', cachedPeopleEntries) ||
          [];
        const totalCount =
          readField<number>('totalCount', cachedPeopleEntries) || 0;
        const newEdges = filterPeopleFromEdges(edges, readField);
        const netEdgesRemoved = edges.length - newEdges.length;
        const updatedTotalCount = totalCount - netEdgesRemoved;
        return {
          ...cachedPeopleEntries,
          totalCount: updatedTotalCount,
          edges: newEdges
        };
      }
    },
    broadcast: true,
    optimistic: true
  });
};

export const filterPeopleWatchlistEntriesByPeopleIds =
  (peopleIds: number[]) =>
  (
    edges: Readonly<PeopleWatchlistEntryEdge[]>,
    readField: ReadFieldFunction
  ): PeopleWatchlistEntryEdge[] => {
    return edges.filter((edge) => {
      const personId = readField<number>(
        'id',
        readField('person', readField('node', edge))
      );
      if (personId === undefined) return false;
      return !peopleIds.includes(personId);
    });
  };

export const filterPeopleWatchlistEntriesByEntryUrn =
  (entryUrns: string[]) =>
  (
    edges: Readonly<PeopleWatchlistEntryEdge[]>,
    readField: ReadFieldFunction
  ): PeopleWatchlistEntryEdge[] => {
    const newEdges = edges.filter((edge) => {
      const entryUrn = readField<string>('entryUrn', readField('node', edge));
      if (entryUrn === undefined) return false;
      return !entryUrns.includes(entryUrn);
    });

    return newEdges;
  };

interface UseRemovePeopleFromListOptions {
  disableToast?: boolean;
}

const defaultOptions: UseRemovePeopleFromListOptions = {
  disableToast: false
};

const useRemovePeopleFromList = ({
  disableToast
}: UseRemovePeopleFromListOptions = defaultOptions): UseRemovePeopleFromListResult => {
  const [removePeople, { loading, error }] = useMutation<
    RemovePeopleFromWatchlistWithIdsMutation,
    RemovePeopleFromWatchlistWithIdsMutationVariables
  >(REMOVE_PEOPLE_FROM_WATCHLIST);
  const { editTableStoreData } = useShallowTableStore(['editTableStoreData']);

  const _deselectRows = useCallback(() => {
    editTableStoreData('selectedRowIds', []);
  }, [editTableStoreData]);

  const removePeopleFromList = async (
    watchlistUrn: string,
    people: number[]
  ) => {
    const payload: RemovePeopleFromWatchlistWithIdsMutationVariables = {
      watchlist: watchlistUrn,
      people: people.map((id) => `${id}`)
    };
    const isBulkAction = people.length > 1;

    const watchlistId = getIdFromUrn(watchlistUrn);
    if (!watchlistId) {
      throw new Error(`Failed to parse id from urn ${watchlistUrn}`);
    }
    const watchlist = client.readFragment<PeopleWatchlistFragment>({
      id: `PeopleWatchlist:${watchlistId}`,
      fragment: GET_PEOPLE_WATCHLIST_FRAGMENT,
      fragmentName: 'PeopleWatchlist'
    });

    return removePeople({
      variables: payload,

      // Update the Person refs in the cache to reflect being removed from the watchlist
      update: (cache) => {
        people.forEach((person) => {
          cache.modify({
            id: cache.identify({
              __typename: 'Person',
              id: person
            }),
            fields: {
              watchlists(existingWatchlists = [], { readField }) {
                const newWatchlists = existingWatchlists.filter(
                  (w: Reference) => readField('id', w) !== watchlist?.id
                );
                return newWatchlists;
              }
            },
            optimistic: true,
            broadcast: true
          });
        });
        clearPeopleFromWatchlistPeopleCache(
          client.cache,
          filterPeopleWatchlistEntriesByPeopleIds(people),
          watchlistId
        );
      },
      onError: (error) => {
        if (error.graphQLErrors?.[0].extensions?.response?.status === 403) {
          if (!disableToast) {
            displayToast({
              mode: 'error',
              primaryText:
                error.graphQLErrors?.[0].extensions?.response?.body?.detail
            });
          }
        }
      },
      onCompleted: () => {
        if (!disableToast) {
          displayToast({
            primaryText: isBulkAction
              ? `Removed ${people.length} people from list${
                  watchlist ? ` ${watchlist?.name}` : ''
                }`
              : `Removed person from list${
                  watchlist ? ` ${watchlist?.name}` : ''
                }`,
            link: `/dashboard/people_watchlist/urn:harmonic:people_watchlist:${watchlistId}`
          });
        }
        _deselectRows();
      }
    });
  };

  return { removePeopleFromList, loading, error };
};

export default useRemovePeopleFromList;
