import classNames from 'classnames';
import React from 'react';

type PaperProps = {
  children: React.ReactNode;
  displayShadow?: boolean;
  isInteractive?: boolean;
  fullHeight?: boolean;
};

const Paper: React.FC<PaperProps> = ({
  children,
  displayShadow = true,
  isInteractive = false,
  fullHeight = false
}) => {
  return (
    <div
      className={classNames(
        'p-p60 rounded-br40 bg-surface-default border border-border border-solid',
        displayShadow && 'shadow-int-elevation-enabled',
        isInteractive &&
          'hover:shadow-int-elevation-hover active:shadow-int-elevation-pressed focus:shadow-int-elevation-focus-outside',
        fullHeight && 'h-full'
      )}
    >
      {children}
    </div>
  );
};

export default Paper;
