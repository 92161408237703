import { useQuery } from '@apollo/client';
import {
  GetPersonContactInformationQuery,
  GetPersonContactInformationQueryVariables
} from '__generated__/graphql';
import EmailIcon from 'assets/harmonic-icons/email-icon';
import Button, { ButtonSize } from 'harmonic-components/Button/Button';
import { useModal } from 'hooks/useModal';
import getPersonContactInformation from 'queries/getPersonContactInformation';
import React from 'react';
import PersonEmailUnlockModal from './PersonEmailUnlockModal';

const GetPersonEmailButtonContentLoader: React.FC = () => {
  return <div className="w-8 h-8 rounded-full animated-box-pulse" />;
};
interface GetPersonEmailButtonProps {
  personId: number;
  size?: ButtonSize;
}

const GetPersonEmailButton: React.FC<GetPersonEmailButtonProps> = ({
  personId,
  size = 'default'
}) => {
  const { data: personContactInformation } = useQuery<
    GetPersonContactInformationQuery,
    GetPersonContactInformationQueryVariables
  >(getPersonContactInformation, {
    variables: {
      id: personId
    },
    fetchPolicy: 'cache-only'
  });

  const isUnlocked = Boolean(
    personContactInformation?.getPersonById?.contact?.emails?.length ||
      personContactInformation?.getPersonById?.emailEnrichmentStatus
        ?.enrichedAt !== null
  );

  const { show } = useModal();

  const handleOnUnlock = () => {
    show(<PersonEmailUnlockModal personId={personId} />);
  };

  if (!personContactInformation) {
    return <GetPersonEmailButtonContentLoader />;
  }

  if (isUnlocked) {
    return (
      <Button
        leadingIcon={EmailIcon}
        onClick={handleOnUnlock}
        label="Contact"
        type="primary"
        emphasis="low"
        size={size}
        dataTestId="GetEmailText"
      />
    );
  }

  return (
    <Button
      leadingIcon={EmailIcon}
      onClick={handleOnUnlock}
      label="Get email"
      emphasis="high"
      size={size}
      dataTestId="GetEmailText"
    />
  );
};

export default GetPersonEmailButton;
