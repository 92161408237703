import { ICellRendererParams } from 'ag-grid-community';
import { EntityType } from '../../../../../hooks/useDashboardLocation';
import { ResultsView } from '../../../../../interfaces/SearchModel/Search';
import { useShallowTableStore } from '../../../../../stores/tableStore';
import { LOADING_ROW_ID } from '../../../../../utils/constants';

export const useIsExtendedLoading = (props: ICellRendererParams) => {
  const { loadedExtendedUrns, view } = useShallowTableStore([
    'loadedExtendedUrns',
    'view'
  ]);

  const entityType = props?.data?.person?.id
    ? EntityType.PERSON
    : props?.data?.company?.id
    ? EntityType.COMPANY
    : undefined;
  const id: number =
    entityType === EntityType.PERSON
      ? props?.data?.person?.id
      : entityType === EntityType.COMPANY
      ? props?.data?.company?.id
      : undefined;

  const urn = entityType ? `urn:harmonic:${entityType}:${id}` : undefined;
  const shouldShowLoading = urn ? !loadedExtendedUrns[urn] : false;

  if (
    (shouldShowLoading || props.data?.id === LOADING_ROW_ID) &&
    view !== ResultsView.KANBAN
  ) {
    return true;
  }

  return false;
};
