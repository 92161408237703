import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const ArrowLeftIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
      fill="none"
    >
      <path
        d="M10.1678 18.332L4.62492 12.7892C4.2344 12.3986 4.2344 11.7655 4.62492 11.3749L10.1678 5.83203M5.16782 12.082H20.6678"
        vectorEffect="non-scaling-stroke"
        stroke="#484E5B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowLeftIcon;
