import classNames from 'classnames';
import { IconProps } from './type';

const MultiSelectIcon: React.FC<IconProps> = ({
  applyCurrentColor,
  className
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(className, applyCurrentColor && 'fill-current')}
    >
      <path
        d="M3.83333 3C2.82081 3 2 3.82081 2 4.83333C2 5.84586 2.82081 6.66667 3.83333 6.66667C4.84586 6.66667 5.66667 5.84586 5.66667 4.83333C5.66667 3.82081 4.84586 3 3.83333 3Z"
        vectorEffect="non-scaling-stroke"
        fill="#7C8598"
      />
      <path
        d="M7.83333 4.33333C7.55719 4.33333 7.33333 4.55719 7.33333 4.83333C7.33333 5.10948 7.55719 5.33333 7.83333 5.33333H13.5C13.7761 5.33333 14 5.10948 14 4.83333C14 4.55719 13.7761 4.33333 13.5 4.33333H7.83333Z"
        fill="#7C8598"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M3.83333 9.33333C2.82081 9.33333 2 10.1541 2 11.1667C2 12.1792 2.82081 13 3.83333 13C4.84586 13 5.66667 12.1792 5.66667 11.1667C5.66667 10.1541 4.84586 9.33333 3.83333 9.33333Z"
        fill="#7C8598"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M7.83333 10.6667C7.55719 10.6667 7.33333 10.8905 7.33333 11.1667C7.33333 11.4428 7.55719 11.6667 7.83333 11.6667H13.5C13.7761 11.6667 14 11.4428 14 11.1667C14 10.8905 13.7761 10.6667 13.5 10.6667H7.83333Z"
        fill="#7C8598"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default MultiSelectIcon;
