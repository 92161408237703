import classNames from 'classnames';
import {
  DEFAULT_DATE_SELECTOR_OPTIONS,
  TimeUnit,
  YEAR_ONLY_DATE_SELECTOR_OPTIONS
} from 'interfaces/SearchV2';
import { useMemo } from 'react';
import { formatCustomRange, parseCustomRange } from 'utils/searchV2/searchV2';
import SelectorWithValue from '../SelectorWithValue';
import { DateTypeEnum, TwoSidedRangeValue } from './DateModal';

interface RollingRangeProps {
  value: TwoSidedRangeValue;
  onChange: (value: TwoSidedRangeValue) => void;
  dateType?: DateTypeEnum;
}

const RollingRange: React.FC<RollingRangeProps> = ({
  value,
  onChange,
  dateType
}) => {
  const customRange = useMemo(() => {
    return parseCustomRange(value as [string, string]);
  }, [value]);

  const isYear = dateType === DateTypeEnum.YEAR;

  return (
    <div data-testid="RollingRange">
      <div
        className={classNames(
          'mt-2 flex rounded-md py-3 px-2 bg-surface-raised typography-label w-full'
        )}
      >
        <div>
          <div className="typography-label">Between</div>
          <div className="mt-g40 flex flex-row items-center">
            {customRange?.start.timeUnit !== undefined && (
              <SelectorWithValue
                selector={{
                  options: !isYear
                    ? DEFAULT_DATE_SELECTOR_OPTIONS
                    : YEAR_ONLY_DATE_SELECTOR_OPTIONS,
                  value: customRange?.start.timeUnit,
                  onChange: (value) => {
                    const updatedRange = {
                      ...customRange,
                      start: {
                        timeUnit: value as TimeUnit,
                        value: 0
                      }
                    };
                    onChange(
                      formatCustomRange(updatedRange) as [string, string]
                    );
                  }
                }}
                value={customRange?.start.value}
                hideValueInput={customRange?.start.timeUnit === TimeUnit.TODAY}
                onValueChange={(e) => {
                  const newValue = e.target.value.replace(/[^0-9]/g, '');
                  const updatedRange = {
                    ...customRange,
                    start: {
                      timeUnit: customRange?.start.timeUnit,
                      value: newValue ? parseInt(newValue) : undefined
                    }
                  };
                  onChange(formatCustomRange(updatedRange) as [string, string]);
                }}
              />
            )}
            <span className="mx-g50 typography-label">-</span>
          </div>
        </div>
        {customRange?.end.timeUnit !== undefined && (
          <div>
            <div className="typography-label">and</div>
            <div className="mt-g40">
              <SelectorWithValue
                selector={{
                  options: !isYear
                    ? DEFAULT_DATE_SELECTOR_OPTIONS
                    : YEAR_ONLY_DATE_SELECTOR_OPTIONS,
                  value: customRange?.end.timeUnit,
                  onChange: (value) => {
                    const updatedRange = {
                      ...customRange,
                      end: {
                        timeUnit: value as TimeUnit,
                        value: 0
                      }
                    };

                    onChange(
                      formatCustomRange(updatedRange) as [string, string]
                    );
                  }
                }}
                value={customRange?.end.value}
                hideValueInput={customRange?.end.timeUnit === TimeUnit.TODAY}
                onValueChange={(e) => {
                  const newValue = e.target.value.replace(/[^0-9]/g, '');
                  const updatedRange = {
                    ...customRange,
                    end: {
                      timeUnit: customRange?.end.timeUnit,
                      value: newValue ? parseInt(newValue) : undefined
                    }
                  };
                  onChange(formatCustomRange(updatedRange) as [string, string]);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RollingRange;
