import classNames from 'classnames';
import { HarmonicIcon } from './type';

const ArrowRotateIcon: HarmonicIcon = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke="#7C8598"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M12.8412 13.5V11.1667C12.8412 10.9826 12.692 10.8333 12.5079 10.8333H10.1746M3.16667 2.5V4.83333C3.16667 5.01743 3.31591 5.16667 3.5 5.16667H5.83333M2.54255 7.3125C2.51447 7.53772 2.5 7.76717 2.5 8C2.5 11.0376 4.96243 13.5 8 13.5C9.78439 13.5 11.4074 12.6502 12.4214 11.3333M13.4574 8.6875C13.4855 8.46228 13.5 8.23283 13.5 8C13.5 4.96243 11.0376 2.5 8 2.5C6.21561 2.5 4.59258 3.34975 3.57856 4.66667"
      />
    </svg>
  );
};

export default ArrowRotateIcon;
