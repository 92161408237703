import classNames from 'classnames';
import { IconProps } from './type';

const GlobeFilled: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="#7C8598"
      fillRule="evenodd"
      clipRule="evenodd"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M9.99999 18.3334C14.6024 18.3334 18.3333 14.6025 18.3333 10.0001C18.3333 5.39771 14.6024 1.66675 9.99999 1.66675C5.39762 1.66675 1.66666 5.39771 1.66666 10.0001C1.66666 14.6025 5.39762 18.3334 9.99999 18.3334ZM6.89229 8.72228C6.77558 8.95396 6.47781 9.02382 6.27025 8.86821L3.90946 7.0984C3.7494 6.97841 3.69608 6.76086 3.79264 6.58568C4.99824 4.39848 7.32619 2.91675 10.0002 2.91675C10.4483 2.91675 10.8867 2.95835 11.3116 3.03791C11.5379 3.08026 11.6736 3.30777 11.6179 3.5311L10.8956 6.42446C10.8583 6.5736 10.742 6.69012 10.5929 6.72762L7.69067 7.45773C7.57334 7.48724 7.47463 7.5663 7.4202 7.67435L6.89229 8.72228ZM12.617 13.9364C12.5464 14.0776 12.4022 14.1668 12.2443 14.1668H10.2248C10.0855 14.1668 9.95543 14.0971 9.87816 13.9812L8.52501 11.9515C8.41468 11.786 8.43671 11.5656 8.5776 11.4252L9.12624 10.8786C9.21088 10.7942 9.32764 10.7503 9.44687 10.7579L10.7661 10.8421C10.8397 10.8468 10.9108 10.8709 10.972 10.9121L13.031 12.2957C13.2049 12.4126 13.265 12.6404 13.1713 12.8279L12.617 13.9364Z"
      />
    </svg>
  );
};

export default GlobeFilled;
