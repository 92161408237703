import classNames from 'classnames';
import { HarmonicIcon } from './type';

const CustomAttributeNumberIcon: HarmonicIcon = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#7C8598"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.2 1.80005C5.53137 1.80005 5.8 2.06868 5.8 2.40005V4.60005H10.2V2.40005C10.2 2.06868 10.4686 1.80005 10.8 1.80005C11.1314 1.80005 11.4 2.06868 11.4 2.40005V4.60005H13.6C13.9314 4.60005 14.2 4.86868 14.2 5.20005C14.2 5.53142 13.9314 5.80005 13.6 5.80005H11.4V10.2H13.6C13.9314 10.2 14.2 10.4687 14.2 10.8C14.2 11.1314 13.9314 11.4 13.6 11.4H11.4V13.6C11.4 13.9314 11.1314 14.2 10.8 14.2C10.4686 14.2 10.2 13.9314 10.2 13.6V11.4H5.8V13.6C5.8 13.9314 5.53137 14.2 5.2 14.2C4.86863 14.2 4.6 13.9314 4.6 13.6V11.4H2.4C2.06863 11.4 1.8 11.1314 1.8 10.8C1.8 10.4687 2.06863 10.2 2.4 10.2H4.6V5.80005H2.4C2.06863 5.80005 1.8 5.53142 1.8 5.20005C1.8 4.86868 2.06863 4.60005 2.4 4.60005H4.6V2.40005C4.6 2.06868 4.86863 1.80005 5.2 1.80005ZM5.8 5.80005V10.2H10.2V5.80005H5.8Z"
      />
    </svg>
  );
};
export default CustomAttributeNumberIcon;
