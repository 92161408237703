import { ENVIRONMENT, FrontendEnvironments } from 'utils/constants';
import { getURLParamValue } from 'utils/utilities';

interface IConfig {
  BASE_CRM_INTEGRATION_API_URL: string;
  BASE_GRAPHQL_API_URL: string;
  BASE_GRAPHQL_WEBSOCKET_URL: string;
  BASE_MIDTIER_API_URL: string;
  DISABLE_PREFETCH: boolean;
  INTERCOM_APP_ID: string;
  SPLIT_API_KEY: string;
  EXTENSION_IDS: string[];
}
const flagDisablePrefetch = getURLParamValue('disablePrefetch') === 'true';

const local: IConfig = {
  BASE_CRM_INTEGRATION_API_URL: 'http://localhost:6972',
  BASE_GRAPHQL_API_URL: 'http://localhost:4000/graphql',
  BASE_GRAPHQL_WEBSOCKET_URL: 'ws://localhost:4000/graphql',
  BASE_MIDTIER_API_URL: 'http://localhost:80/',
  DISABLE_PREFETCH: true,
  INTERCOM_APP_ID: 'go7ahz14',
  SPLIT_API_KEY: '6lpmuqblee4lbttqu7lmftg4h39rieql40d9',
  EXTENSION_IDS: [
    'pmnechipnndlblhloienjjdafcloodap',
    'ikkpacjgdcmnnlkponeejjkhkbfmcplk',
    'lpeokfegmaajjedpinemghjbloboglii'
  ]
};

const stage: IConfig = {
  BASE_CRM_INTEGRATION_API_URL:
    'https://crm-integration-py-production.up.railway.app',
  BASE_GRAPHQL_API_URL: 'https://api.staging.harmonic.ai/graphql',
  BASE_GRAPHQL_WEBSOCKET_URL: 'ws://api.staging.harmonic.ai/graphql',
  BASE_MIDTIER_API_URL: 'https://api.staging.harmonic.ai/',
  DISABLE_PREFETCH: flagDisablePrefetch,
  INTERCOM_APP_ID: 'go7ahz14',
  SPLIT_API_KEY: '6lpmuqblee4lbttqu7lmftg4h39rieql40d9',
  EXTENSION_IDS: [
    'pmnechipnndlblhloienjjdafcloodap',
    'ikkpacjgdcmnnlkponeejjkhkbfmcplk',
    'lpeokfegmaajjedpinemghjbloboglii' // harmonic-linkedin-web-extension
  ]
};

const canary: IConfig = {
  BASE_CRM_INTEGRATION_API_URL:
    'https://crm-integration-py-production.up.railway.app',
  BASE_GRAPHQL_API_URL: 'https://api.canary.harmonic.ai/graphql',
  BASE_GRAPHQL_WEBSOCKET_URL: 'wss://api.canary.harmonic.ai/graphql',
  BASE_MIDTIER_API_URL: 'https://api.canary.harmonic.ai/',
  DISABLE_PREFETCH: flagDisablePrefetch,
  INTERCOM_APP_ID: 'zbddx3lp',
  SPLIT_API_KEY: '6lpmuqblee4lbttqu7lmftg4h39rieql40d9',
  EXTENSION_IDS: [
    'pmnechipnndlblhloienjjdafcloodap',
    'ikkpacjgdcmnnlkponeejjkhkbfmcplk',
    'lpeokfegmaajjedpinemghjbloboglii' // harmonic-linkedin-web-extension
  ]
};

const prod: IConfig = {
  BASE_CRM_INTEGRATION_API_URL:
    'https://crm-integration-py-production.up.railway.app',
  BASE_GRAPHQL_API_URL: 'https://api.harmonic.ai/graphql',
  BASE_GRAPHQL_WEBSOCKET_URL: 'wss://api.harmonic.ai/graphql/subscriptions',
  BASE_MIDTIER_API_URL: 'https://api.harmonic.ai/',
  DISABLE_PREFETCH: flagDisablePrefetch,
  INTERCOM_APP_ID: 'zbddx3lp',
  SPLIT_API_KEY: '5n2tm96qk3o5njtb72doscq2atk7q1kifd5g',
  EXTENSION_IDS: [
    'pmnechipnndlblhloienjjdafcloodap',
    'ikkpacjgdcmnnlkponeejjkhkbfmcplk',
    'lpeokfegmaajjedpinemghjbloboglii' // harmonic-linkedin-web-extension
  ]
};

export const config: IConfig = {
  ...(ENVIRONMENT === FrontendEnvironments.Production
    ? prod
    : ENVIRONMENT === FrontendEnvironments.Canary
    ? canary
    : ENVIRONMENT === FrontendEnvironments.Staging
    ? stage
    : local)
};
