import { Theme, Tooltip, withStyles } from '@material-ui/core';

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: 'var(--color-surface-default)',
    color: 'var(--color-content-default)',
    boxShadow: theme.shadows[1],
    padding: theme.spacing(1),
    fontWeight: 300,
    fontSize: 15,
    maxWidth: 360,
    maxHeight: 420,
    overflow: 'auto',
    whiteSpace: 'pre-line'
  }
}))(Tooltip);

export default LightTooltip;
