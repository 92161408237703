import classNames from 'classnames';
import { Tooltip } from 'common/components';
import { ConnectionsRenderable } from 'hooks/useGroupedUserConnections';
import React from 'react';
import { PersonUserConnectionsList } from './PersonUserConnectionsList';

export const PersonUserConnnectionsTooltip: React.FC<{
  userConnections: ConnectionsRenderable[][];
  emphasizedStyle?: boolean;
}> = ({ userConnections, emphasizedStyle }) => {
  return (
    <Tooltip
      body={
        <PersonUserConnectionsList
          userConnections={userConnections}
          dataTestId="UserConnectionTooltipContent"
        />
      }
    >
      <span>
        and{' '}
        <span
          className={classNames(
            emphasizedStyle ? 'text-content-strong' : 'text-content-weak'
          )}
        >
          {userConnections.length} other
          {userConnections.length > 1 && 's'}
        </span>
      </span>
    </Tooltip>
  );
};
