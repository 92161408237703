import { GetCompanyEmployees_EmployeeEdges_PersonFragment } from '__generated__/graphql';
import { compact } from 'lodash';
import React from 'react';

export const TeamMemberConnections: React.FC<{
  employee: Pick<
    GetCompanyEmployees_EmployeeEdges_PersonFragment,
    'firstName' | 'userConnections' | 'fullName'
  >;
}> = ({ employee }) => {
  const userConnections = compact(
    employee.userConnections?.map((userConnection) => userConnection?.user)
  );

  const userConnectionNames = userConnections.map((user) => user?.name);
  const numConnectionToShow = 3;
  const firstThreeConnections = userConnectionNames.slice(
    0,
    numConnectionToShow
  );
  const otherConnections = userConnectionNames.length - numConnectionToShow;
  return (
    <div className="p-p20">
      {firstThreeConnections.length ? (
        <>
          {firstThreeConnections.map((connection, index) => (
            <span key={index}>
              <span className="typography-label-default-strong">
                {connection}
              </span>
              {index < firstThreeConnections.length - 1 && (
                <span className="text-content-weak typography-label-default-default">
                  ,{' '}
                </span>
              )}
            </span>
          ))}
          {otherConnections > 0 && (
            <span className="text-content-weak typography-label-default-default">
              {' '}
              and {otherConnections} {otherConnections > 1 ? 'others' : 'other'}{' '}
            </span>
          )}{' '}
          <span className="text-content-weak typography-label-default-default">
            {userConnectionNames.length > 1 ? 'are' : 'is'} in network with
          </span>
        </>
      ) : (
        <span className="text-content-weak typography-label-default-default">
          Not in network with
        </span>
      )}{' '}
      <span className="typography-label-default-strong">
        {employee.firstName ?? employee.fullName}
      </span>
    </div>
  );
};
