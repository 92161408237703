import { getApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

export const getFirebaseToken = (): Promise<string> => {
  const auth = getAuth(getApp());
  return new Promise((resolve, reject) => {
    if (!auth.currentUser) reject('Firebase user does not exist in state');
    auth.currentUser
      ?.getIdToken()
      .then((token) => {
        resolve(token);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
