import classNames from 'classnames';
import { IconProps } from './type';

const HeartFilledIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      fill="#F9FAFB"
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M8.2448 14.2693C14.0579 11.0031 15.3907 7.1781 14.3448 4.56385C13.8334 3.28547 12.7616 2.37281 11.5016 2.0912C10.356 1.83514 9.09309 2.10875 7.99985 3.03304C6.90659 2.10875 5.64366 1.83514 4.49804 2.0912C3.23813 2.37282 2.16632 3.28548 1.65489 4.56387C0.609024 7.17811 1.94178 11.0031 7.75496 14.2693C7.90706 14.3548 8.0927 14.3548 8.2448 14.2693Z"
      />
    </svg>
  );
};
export default HeartFilledIcon;
