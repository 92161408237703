import { PersonWithConnections } from 'components/Dashboard/CompanyV2/CompanyContact/UserConnectionsList';
import dayjs from 'dayjs';
import { ICompany } from 'interfaces/DataModel/Company';
import { IPersonRelationship } from 'interfaces/DataModel/Relationship';
import { max, uniq } from 'lodash';
import { getIdFromUrn } from './urn';

const INTEGER_STRING_PATTERN = /^-?\d+$/;

export const getCompanyExecEmails = (
  company: ICompany | undefined
): string[] => {
  let emails: string[] = [];

  // Concat work emails first, then personal emails
  company?.person_relationships?.forEach(
    (relationship: IPersonRelationship) => {
      emails = emails.concat(relationship?.person_company_emails);
    }
  );
  company?.person_relationships?.forEach(
    (relationship: IPersonRelationship) => {
      emails = emails.concat(relationship?.person?.contact_emails);
      emails = emails.concat(relationship?.person?.personal_emails);
    }
  );

  return uniq(emails.filter((email) => email));
};

export const shouldLinkCompanyId = (
  companyId: number | string | undefined | null
): boolean => {
  let id: number | null = null;
  if (typeof companyId === 'string') {
    const idFromUrn = getIdFromUrn(companyId) ?? companyId;
    const match = INTEGER_STRING_PATTERN.exec(idFromUrn.trim());
    if (match) {
      id = Number(match[0]);
    }
  } else if (typeof companyId === 'number') {
    id = companyId;
  }
  return id !== null && id >= 1;
};

// Sort by date connected in desc order
export const sortCorrespondencesByDateConnectedPredicate = (
  a: PersonWithConnections,
  b: PersonWithConnections
) => {
  const aDate =
    max([
      dayjs(a?.latestCalendarEvent?.startTime ?? 0).unix(),
      dayjs(a?.latestEmail?.timestamp ?? 0).unix()
    ]) ?? 0;
  const bDate =
    max([
      dayjs(b?.latestCalendarEvent?.startTime ?? 0).unix(),
      dayjs(b?.latestEmail?.timestamp ?? 0).unix()
    ]) ?? 0;
  return bDate - aDate;
};
