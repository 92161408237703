import EmailIcon from 'assets/harmonic-icons/email-icon';
import Button from 'harmonic-components/Button/Button';
import { useModal } from 'hooks/useModal';
import React from 'react';
import PersonEmailStaticModal from './PersonEmailStaticModal';

interface ContactButtonProps {
  name: string;
  emailAddress: string;
}

const ContactButton: React.FC<ContactButtonProps> = ({
  name,
  emailAddress
}) => {
  const { show } = useModal();

  const handleOnUnlock = () => {
    show(<PersonEmailStaticModal name={name} emailAddress={emailAddress} />);
  };

  return (
    <Button
      leadingIcon={EmailIcon}
      onClick={handleOnUnlock}
      label="Contact"
      type="primary"
      emphasis="high"
      size="compact"
      dataTestId="ContactStaticEmail"
    />
  );
};

export default ContactButton;
