import classNames from 'classnames';
import { IconProps } from './type';

const CelebrateIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#493AA6"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M9.30408 0.67397C9.57516 0.627556 9.83254 0.809717 9.87895 1.08084L9.87925 1.08259L9.87971 1.08539L9.88113 1.09406L9.88562 1.12317C9.88927 1.14763 9.89411 1.18202 9.8994 1.22483C9.90996 1.31025 9.92245 1.43019 9.93077 1.57225C9.94714 1.85189 9.94866 2.23728 9.87774 2.61974C9.79639 3.05844 9.59073 3.45957 9.4226 3.73494C9.3363 3.87629 9.2546 3.99297 9.1939 4.07509C9.16345 4.11628 9.138 4.14914 9.11955 4.17244C9.11032 4.18411 9.10281 4.19341 9.09728 4.20019L9.09048 4.20847L9.08824 4.21117L9.08742 4.21215L9.08694 4.21273C8.91027 4.42353 8.59604 4.45135 8.38527 4.27465C8.17473 4.09816 8.1469 3.78454 8.3229 3.57374L8.32339 3.57316L8.32551 3.57056L8.33873 3.55408C8.35099 3.5386 8.36971 3.51447 8.39306 3.48288C8.43998 3.41942 8.5045 3.32731 8.57259 3.2158C8.71324 2.98544 8.84906 2.70456 8.89847 2.43809C8.94896 2.16582 8.95048 1.8692 8.93651 1.63049C8.92965 1.51339 8.91937 1.41509 8.91096 1.34705C8.90677 1.31313 8.90307 1.28699 8.90055 1.2701L8.89775 1.25196L8.89726 1.24892C8.85095 0.977852 9.03304 0.720376 9.30408 0.67397Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M12.801 1.71938C13.0597 1.81267 13.1939 2.09807 13.1006 2.35683L12.7932 3.20935C12.7 3.46811 12.4146 3.60225 12.1559 3.50896C11.8972 3.41566 11.763 3.13027 11.8563 2.8715L12.1636 2.01898C12.2569 1.76022 12.5423 1.62608 12.801 1.71938Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M4.46509 4.70114C4.75572 3.90275 5.77679 3.676 6.37795 4.27635L11.732 9.62306C12.3339 10.2242 12.1072 11.2472 11.3077 11.5377L2.89465 14.5945C1.96724 14.9314 1.06849 14.0319 1.40605 13.1046L4.46509 4.70114Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M14.6143 4.69351C14.7371 4.93961 14.6372 5.23871 14.3912 5.36158L13.7082 5.70259C13.4622 5.82545 13.1631 5.72555 13.0402 5.47945C12.9174 5.23335 13.0173 4.93424 13.2634 4.81138L13.9463 4.47037C14.1924 4.3475 14.4914 4.44741 14.6143 4.69351Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M11.4479 4.56406C11.6422 4.75871 11.642 5.07406 11.4473 5.26841L10.7644 5.95043C10.5698 6.14479 10.2545 6.14455 10.0601 5.9499C9.86581 5.75525 9.86604 5.43991 10.0607 5.24555L10.7436 4.56353C10.9382 4.36918 11.2535 4.36942 11.4479 4.56406Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M11.6224 7.28204C11.634 7.00721 11.8661 6.79383 12.1409 6.80542L12.1423 6.80549L12.1445 6.80559L12.1512 6.80591L12.1731 6.80714C12.1914 6.80825 12.217 6.80997 12.2489 6.81256C12.3124 6.81773 12.4015 6.82639 12.5076 6.84056C12.7173 6.8686 13.0045 6.91963 13.2959 7.01276C13.5684 7.09984 13.8561 7.24128 14.0628 7.35163C14.169 7.40836 14.2599 7.46013 14.3244 7.49789C14.3568 7.51682 14.3828 7.53234 14.401 7.54335L14.4224 7.55636L14.4285 7.5601L14.4309 7.5616C14.6646 7.70662 14.7369 8.0139 14.5919 8.24764C14.4469 8.48132 14.14 8.55325 13.9063 8.40835L13.9057 8.40798L13.9021 8.40577L13.886 8.39598C13.8715 8.38719 13.8496 8.3741 13.8217 8.35778C13.7657 8.32506 13.6863 8.2798 13.5937 8.23036C13.4027 8.12835 13.1782 8.02086 12.9928 7.9616C12.7761 7.89237 12.5511 7.85134 12.3756 7.82789C12.2891 7.81632 12.2173 7.80938 12.1682 7.80539C12.1437 7.8034 12.125 7.80215 12.113 7.80143L12.1005 7.80072L12.0989 7.80064C11.8242 7.78898 11.6108 7.55681 11.6224 7.28204Z"
      />
    </svg>
  );
};

export default CelebrateIcon;
