import classNames from 'classnames';
import { IconProps } from './type';

const ArrowInboxIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke="#677183"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M13.5 9.83333V12.8333C13.5 13.2015 13.2015 13.5 12.8333 13.5H3.16667C2.79848 13.5 2.5 13.2015 2.5 12.8333V9.83333M7.99999 10V2.5M7.99999 10L5.66667 7.66667M7.99999 10L10.3333 7.66667"
      />
    </svg>
  );
};

export default ArrowInboxIcon;
