import React from 'react';

const SimilarCompaniesContentLoader: React.FC = () => {
  return (
    <div
      data-testid="SimilarCompaniesContentLoader"
      className="w-full gap-y-4 flex flex-col"
    >
      {[...Array(10)].map((_, index) => (
        <div
          key={index}
          className="w-full h-34 bg-slate-200 rounded-md animate-pulse"
        />
      ))}
    </div>
  );
};

export default SimilarCompaniesContentLoader;
