import classNames from 'classnames';
import { IconProps } from './type';

const ImageAvatarSparklingIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#493AA6"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M12.6315 1.26288C12.5086 1.01719 12.158 1.01719 12.0352 1.26288L11.383 2.56721C11.3508 2.63172 11.2985 2.68403 11.234 2.71628L9.92962 3.36845C9.68394 3.49129 9.68394 3.84189 9.92962 3.96473L11.234 4.6169C11.2985 4.64916 11.3508 4.70146 11.383 4.76597L12.0352 6.07031C12.158 6.31599 12.5086 6.31599 12.6315 6.07031L13.2836 4.76597C13.3159 4.70146 13.3682 4.64916 13.4327 4.6169L14.737 3.96473C14.9827 3.84189 14.9827 3.49129 14.737 3.36845L13.4327 2.71628C13.3682 2.68403 13.3159 2.63172 13.2836 2.56721L12.6315 1.26288Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M3.33333 3.99992C3.33333 3.63173 3.63181 3.33326 4 3.33326H7.33333C7.70152 3.33326 8 3.03478 8 2.66659C8 2.2984 7.70152 1.99992 7.33333 1.99992H4C2.89543 1.99992 2 2.89535 2 3.99992V11.9999C2 13.1045 2.89543 13.9999 4 13.9999H12C13.1046 13.9999 14 13.1045 14 11.9999V8.66659C14 8.2984 13.7015 7.99992 13.3333 7.99992C12.9651 7.99992 12.6667 8.2984 12.6667 8.66659V11.9999C12.6667 12.3681 12.3682 12.6666 12 12.6666H11.2667C10.9578 11.1451 9.61264 9.99992 8 9.99992C6.38736 9.99992 5.04219 11.1451 4.73335 12.6666H4C3.63181 12.6666 3.33333 12.3681 3.33333 11.9999V3.99992Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M8 4.99984C6.89543 4.99984 6 5.89527 6 6.99984C6 8.10441 6.89543 8.99984 8 8.99984C9.10457 8.99984 10 8.10441 10 6.99984C10 5.89527 9.10457 4.99984 8 4.99984Z"
      />
    </svg>
  );
};

export default ImageAvatarSparklingIcon;
