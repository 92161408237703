import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const InstagramIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      fill="#7638FA"
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M20.9471 8.30496C20.9361 7.54796 20.7961 6.79696 20.5281 6.08896C20.0591 4.87996 19.1041 3.92396 17.8951 3.45596C17.1961 3.19296 16.4571 3.05196 15.7091 3.03596C14.7471 2.99296 14.4421 2.98096 12.0001 2.98096C9.55807 2.98096 9.24507 2.98096 8.29007 3.03596C7.54307 3.05196 6.80407 3.19296 6.10507 3.45596C4.89607 3.92396 3.94007 4.87996 3.47207 6.08896C3.20907 6.78796 3.06707 7.52696 3.05307 8.27396C3.01007 9.23696 2.99707 9.54196 2.99707 11.984C2.99707 14.426 2.99707 14.738 3.05307 15.694C3.06807 16.442 3.20907 17.18 3.47207 17.881C3.94107 19.089 4.89607 20.045 6.10607 20.513C6.80207 20.785 7.54107 20.939 8.29107 20.963C9.25407 21.006 9.55907 21.019 12.0011 21.019C14.4431 21.019 14.7561 21.019 15.7111 20.963C16.4581 20.948 17.1971 20.807 17.8971 20.544C19.1061 20.075 20.0611 19.119 20.5301 17.911C20.7931 17.211 20.9341 16.473 20.9491 15.724C20.9921 14.762 21.0051 14.457 21.0051 12.014C21.0031 9.57196 21.0031 9.26196 20.9471 8.30496ZM11.9941 16.602C9.44007 16.602 7.37107 14.533 7.37107 11.979C7.37107 9.42496 9.44007 7.35596 11.9941 7.35596C14.5461 7.35596 16.6171 9.42496 16.6171 11.979C16.6171 14.533 14.5461 16.602 11.9941 16.602ZM16.8011 8.26296C16.2041 8.26296 15.7231 7.78096 15.7231 7.18496C15.7231 6.58896 16.2041 6.10696 16.8011 6.10696C17.3961 6.10696 17.8781 6.58896 17.8781 7.18496C17.8781 7.78096 17.3961 8.26296 16.8011 8.26296Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M11.9941 14.982C13.6526 14.982 14.9971 13.6375 14.9971 11.979C14.9971 10.3204 13.6526 8.97596 11.9941 8.97596C10.3356 8.97596 8.99107 10.3204 8.99107 11.979C8.99107 13.6375 10.3356 14.982 11.9941 14.982Z"
      />
    </svg>
  );
};

export default InstagramIcon;
