import classNames from 'classnames';
import { IconProps } from './type';

const SquareLinesIcon: React.FC<IconProps> = ({
  applyCurrentColor,
  className
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#7C8598"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(className, applyCurrentColor && 'fill-current')}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 3.16667C2 2.52233 2.52233 2 3.16667 2H12.8333C13.4777 2 14 2.52233 14 3.16667V12.8333C14 13.4777 13.4777 14 12.8333 14H3.16667C2.52233 14 2 13.4777 2 12.8333V3.16667ZM5.33333 9.33333C5.33333 9.05719 5.55719 8.83333 5.83333 8.83333H8.83333C9.10948 8.83333 9.33333 9.05719 9.33333 9.33333C9.33333 9.60948 9.10948 9.83333 8.83333 9.83333H5.83333C5.55719 9.83333 5.33333 9.60948 5.33333 9.33333ZM5.83333 6.16667C5.55719 6.16667 5.33333 6.39052 5.33333 6.66667C5.33333 6.94281 5.55719 7.16667 5.83333 7.16667H10.1667C10.4428 7.16667 10.6667 6.94281 10.6667 6.66667C10.6667 6.39052 10.4428 6.16667 10.1667 6.16667H5.83333Z"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default SquareLinesIcon;
