import classNames from 'classnames';
import { IconProps } from './type';

const BankIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#493AA6"
      fillRule="evenodd"
      clipRule="evenodd"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M13.5002 6.66658C13.4081 6.66658 13.3335 6.7412 13.3335 6.83325V10.6633C13.3335 10.8877 13.4907 11.0759 13.6761 11.2021C13.8825 11.3426 14.0436 11.549 14.1265 11.7976L14.3487 12.4643C14.6005 13.2198 14.0382 13.9999 13.2419 13.9999H2.75845C1.96213 13.9999 1.39983 13.2198 1.65165 12.4643L1.87387 11.7976C1.95674 11.549 2.11779 11.3426 2.32421 11.2021C2.50967 11.0759 2.66683 10.8877 2.66683 10.6633V6.83325C2.66683 6.7412 2.59221 6.66658 2.50016 6.66658C1.85583 6.66658 1.3335 6.14425 1.3335 5.49991V5.19208C1.3335 4.75192 1.58122 4.34923 1.97409 4.15075L7.47409 1.37217C7.80489 1.20505 8.19543 1.20505 8.52624 1.37217L14.0262 4.15075C14.4191 4.34923 14.6668 4.75192 14.6668 5.19208V5.49991C14.6668 6.14425 14.1445 6.66658 13.5002 6.66658ZM10.6668 7.16658C10.6668 6.89044 10.8907 6.66658 11.1668 6.66658H11.8335C12.1096 6.66658 12.3335 6.89044 12.3335 7.16658V10.4999C12.3335 10.7761 12.1096 10.9999 11.8335 10.9999H11.1668C10.8907 10.9999 10.6668 10.7761 10.6668 10.4999V7.16658ZM5.3335 10.4999C5.3335 10.7761 5.10964 10.9999 4.8335 10.9999H4.16683C3.89069 10.9999 3.66683 10.7761 3.66683 10.4999V7.16658C3.66683 6.89044 3.89069 6.66658 4.16683 6.66658H4.8335C5.10964 6.66658 5.3335 6.89044 5.3335 7.16658V10.4999ZM6.8335 10.9999C6.55735 10.9999 6.3335 10.7761 6.3335 10.4999V7.16658C6.3335 6.89044 6.55735 6.66658 6.8335 6.66658H9.16683C9.44297 6.66658 9.66683 6.89044 9.66683 7.16658V10.4999C9.66683 10.7761 9.44297 10.9999 9.16683 10.9999H6.8335Z"
      />
    </svg>
  );
};

export default BankIcon;
