import { gql } from '@apollo/client';

export const UPSERT_USER_VIEW_RECORD_FOR_COMPANY = gql`
  mutation UpsertUserViewRecordsForCompanyUrns($urns: [CompanyUrn]!) {
    upsertUserViewRecordsForCompanyUrns(urns: $urns) {
      viewRecords {
        entityUrn
        viewRecord {
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const UPSERT_USER_VIEW_RECORD_FOR_COMPANY_MOCKED_RESPONSE = {
  data: {
    upsertUserViewRecordsForCompanyUrns: {
      viewRecords: [
        {
          entityUrn: 'urn:harmonic:company:4297387',
          viewRecord: {
            createdAt: '2023-08-25T16:02:44.073Z',
            updatedAt: '2023-08-25T16:02:44.073Z',
            __typename: 'CompanyViewRecord'
          },
          __typename: 'CompanyViewRecordTuple'
        }
      ],
      __typename: 'CompaniesViewRecordsResponse'
    }
  }
};
