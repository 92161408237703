import { gql } from '@apollo/client';
import { GET_PEOPLE_WATCHLIST_FRAGMENT } from './getPeopleWatchlists';

export const ADD_PEOPLE_TO_WATCHLIST = gql`
  mutation AddPeopleToWatchlistWithIds(
    $watchlist: String!
    $people: [String]!
  ) {
    addPeopleToWatchlistWithIds(id: $watchlist, people: $people) {
      ...PeopleWatchlist
    }
  }
  ${GET_PEOPLE_WATCHLIST_FRAGMENT}
`;

export const ADD_PEOPLE_TO_WATCHLIST_MOCKED_RESPONSE = {
  data: {
    addPeopleToWatchlistWithIds: {
      creator: {
        entityUrn: 'urn:harmonic:user:357',
        email: 'austin@harmonic.ai',
        __typename: 'User'
      },
      id: '44ac2b7d-0fdd-42e5-b4da-bb5af30427a3',
      entityUrn:
        'urn:harmonic:people_watchlist:44ac2b7d-0fdd-42e5-b4da-bb5af30427a3',
      name: 'New list',
      sharedWithTeam: false,
      __typename: 'PeopleWatchlist'
    }
  }
};
