import { Column, RowNode } from 'ag-grid-community';
import { get } from 'lodash';
import React from 'react';
import PersonAttribute from '../../../../../harmonic-components/PersonAttribute/PersonAttribute';
import TruncatedList from '../../../../../harmonic-components/TruncatedList/TruncatedList';
import { ICompany } from '../../../../../interfaces/DataModel/Company';
import { IPersonRelationship } from '../../../../../interfaces/DataModel/Relationship';
import { EventLocations } from '../../../../../utils/analytics';
import { NoDataSubRenderer } from '../CellRenderers';
export const PeopleList: React.FC<{
  value: any;
  column?: Column;
  node?: RowNode<any>;
}> = (props) => {
  const headerName = props.column?.getDefinition().headerName ?? '';
  const shouldLinkToLinkedin = headerName.includes('LinkedIn');
  const company: ICompany = get(props, 'node.data.company');
  const founders = React.useMemo(
    () =>
      company?.person_relationships?.filter(
        (relationship: IPersonRelationship) => relationship.person?.full_name
      ) || [],
    [company?.person_relationships]
  );

  const team = founders
    .map((person) => {
      const teamMemberHarmonicProfileLink = get(person, 'person.id', null)
        ? `/dashboard/person/${get(person, 'person.id')}`
        : undefined;
      const useLinkToLinkedIn =
        shouldLinkToLinkedin && Boolean(get(person, 'person.linkedin_url'));
      return {
        text: get(person, 'person.full_name'),
        href: useLinkToLinkedIn
          ? get(person, 'person.linkedin_url')
          : teamMemberHarmonicProfileLink,
        openedFromLocation: !useLinkToLinkedIn
          ? EventLocations.GRID_TABLE_TEAM
          : undefined,
        profilePicSrc: get(person, 'profilePictureUrl')
      };
    })
    .filter((listItem) => Boolean(listItem.text));

  if (team.length === 0) {
    return <NoDataSubRenderer />;
  }
  return (
    <div className="inline-block">
      <TruncatedList height={54}>
        {team.map((person, index) => {
          return (
            <PersonAttribute
              key={index}
              src={person.profilePicSrc ?? ''}
              href={person.href ?? ''}
              name={person.text}
            />
          );
        })}
      </TruncatedList>
    </div>
  );
};
