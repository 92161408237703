import React from 'react';

interface ModalFooterProps {
  children: React.ReactNode;
}

const ModalFooter: React.FC<ModalFooterProps> = ({ children }) => {
  return (
    <div className="p-p50 bg-surface-elevated rounded-b-br40 border-t border-border border-solid flex flex-row gap-g50 justify-end items-center">
      {children}
    </div>
  );
};

export default ModalFooter;
